import { BsThreeDots, BsArrowRight } from "react-icons/bs";
import useTitanApp from "../../../../../hooks/useTitanApp";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import LoadingComponentSession from "../../../../../components/LoadingComponentOrbit/LoadingComponentSession";
import { changeOrbitServiceStatus } from "../../../../../dispatchers/orbitDispatchers";
export default function OrbitEarningPotential() {
  let navigate = useNavigate();
  const { orbitState, appState } = useTitanApp();
  const { darkMode } = appState || {};
  const {
    potentialEarnings,
    isPotentialEarningLoading,
    parkedServices,
    isParkedServicesLoading,
  } = orbitState || {};

  const gridStyle = { height: "100%", width: "100%" };

  const gridOptions = {
    getRowClass: (params) => (params.node.isSelected ? "selected-row" : ""),
  };

  const Columns = [
    {
      headerName: "Name",
      field: "patientName",
      sortable: false,
      editable: false,
    },
    {
      headerName: "Type",
      field: "serviceType",
      sortable: false,
      editable: false,
      valueGetter: (params) => {
        switch (params.data.serviceType) {
          case 0:
            return "NMS";
          case 2:
            return "Private";
          default:
            return "Checks";
        }
      },
    },
  ];
  const handleRowClick = (event) => {
    const { clinicalServiceID } = event.data || "";

    changeOrbitServiceStatus(clinicalServiceID).then((data) => {
      if (data === true) {
        navigate(`/nmsintervention/${clinicalServiceID}`, {
          state: {
            serviceType: "Kanban",
            currentStage: 3,
          },
        });
      }
    });
  };
  return (
    <div className="orbit_box orbit_grid_last orbit_earning_potential">
      {isPotentialEarningLoading ? (
        <LoadingComponentSession />
      ) : (
        <div className="orbit_box-container">
          <div className="orbit_label_alt">
            <p>Earning Potential</p>
            <BsThreeDots />
          </div>
          <div
            style={gridStyle}
            className={
              darkMode === true ? "ag-theme-alpine-dark" : "ag-theme-alpine"
            }
          >
            <div>
              <p className="">
                There is over {potentialEarnings?.totalNms} Nms available
                {potentialEarnings?.totalChecks !== "0"
                  ? "and over 9000 clinical checks"
                  : ""}
                . Giving you a potential earning of over
              </p>
              <p className="orbit_value potential-earning">
                £{potentialEarnings?.rates}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
