import React from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function LifetimeValue({ dataSetOne }) {
  return (
    <div className="lifetimeValue_container">
      <div className="tasks_header header_appointment">
        <h3 className="pharmacy_header_name">Lifetime Value</h3>
      </div>

      <div className="lifetimeValue_wrapper">
        <div className="lifetimeValue_attributes">
          <div className="box">
            <p className="label">24 months</p>
            <p>Customer Lifetime</p>
          </div>
          <div className="box">
            <p className="label">£14.82</p>
            <p>Average spend</p>
          </div>
          <div className="box">
            <p className="label">£1,832.71</p>
            <p>Lifetime value</p>
          </div>
        </div>

        <div className="lifetimeValue_chart">
          <div className="tasks_header header_appointment">
            <h3 className="pharmacy_header_name">Customer Lifetime Value</h3>
          </div>
          <div className="lifetimeValue_content">
            <p>
              This year Jessica visited Kings Pharmacy 20 times, purchasing a
              total of 32 items and processing 18 prescriptions, also she had
              been diagnosed of a disease for which she is being currently
              treated.
            </p>
          </div>

          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart data={dataSetOne}>
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="name" scale="band" />

              <Tooltip />
              <Legend />
              <Bar dataKey="uv" barSize={20} fill="#aaa" />
              <Line type="monotone" dataKey="uv" stroke="#000" />
            </ComposedChart>
          </ResponsiveContainer>

          <div className="lifetimeValue_bottom">
            <div className="box-label">
              <p className="count">£14</p>
              <div>
                <p className="name">Average Spend</p>
                <p>per visit</p>
              </div>
            </div>
            <div className="box-label">
              <p className="count">£1.8k</p>
              <div>
                <p className="name">Total Spend</p>
                <p>Over 134 visits</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
