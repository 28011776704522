import React from "react";
import FamilyHistory from "./components/FamilyHistory";
import Problems from "./components/Problems";
import InStorePurchases from "./components/InStorePurchases";
import Medication from "./components/Medication";
import MedicalHistory from "./components/MedicalHistory";
import Subscriptions from "./components/Subscriptions";

export default function Summary() {
  return (
    <div className="summary_container">
      <h3>Summary</h3>
      <div className="summary_details">
        <div className="left">
          <Problems />
          <FamilyHistory />
          <InStorePurchases />
        </div>
        <div className="right">
          <Medication />
          <MedicalHistory />
          <Subscriptions />
        </div>
      </div>
    </div>
  );
}
