import React from "react";
import "./../PatientDashboard.css";
import { ReactComponent as AddNewSvg } from "./../PatientDashboardIcons/addNew.svg";
import { ReactComponent as CalenderSvg } from "./../../../pages/Dashboard/OverviewTab/OverviewIcons/calendar.svg";
import { FiPhone, FiMail } from "react-icons/fi";

export default function NominatedPharmacy() {
  return (
    <React.Fragment>
      <div className="tasks_header">
        <h3 className="pharmacy_header_name">Nominated Pharmacy</h3>
        <AddNewSvg />
      </div>
      <div className="overview-boxes">
        <div className="overview-headings">
          <div className="overview-iconBox">
            <h5>Kings Pharmacy - Travel & Vaccination Clinic</h5>
          </div>
        </div>
        <div className="overview-content">
          <p>6 Chester Cl S, Albany St, London NW1 4BU, United Kingdom</p>
        </div>

        <div>
          <div className="cal-date">
            <FiPhone size={20} />
            <p>01225 427887</p>
          </div>
          <div className="cal-date">
            <FiMail size={20} />
            <p>kings.pharmacy@kp .com</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
