import { getObject } from "../getObjectValue";
import prescriptionItemLabelWarnings from "./prescriptionItemLabelWarnings";
import prescriptionItemCheckActions from "./prescriptionItemCheckActions";
import additionalEndorsements from "./additionalEndorsements";
import prescriptionItemSchedules from "./prescriptionItemSchedules";
import notesprescriptionItems from "./notesprescriptionItems";
import prescriptionItemEmergency from "./prescriptionItemEmergency";
import { v4 as uuidv4 } from "uuid";
class prescriptionItems {
  constructor(
    response,
    qty,
    dose,
    prescribedItemId,
    isUpdate,
    PrescriptionItemCheckActionsObj
  ) {
    this.obj = response?.map((value, index) => {
      const PrescriptionItemCheckActions = {
        clinicalCheckId: PrescriptionItemCheckActionsObj.clinicalCheckId,
        clinicalCheckOptionId:
          PrescriptionItemCheckActionsObj.clinicalCheckOptionId,
        prescriptionItemId: value.prescriptionItemId,
        fullCheckText: PrescriptionItemCheckActionsObj.message,
        patientMedicationCheckLearningId: "",
        botDetails:
          PrescriptionItemCheckActionsObj.botDetails === null
            ? ""
            : PrescriptionItemCheckActionsObj.botDetails,
        IsClinicalBotDecision: false,
      };
      return {
        prescriptionItemId: value.prescriptionItemId
          ? getObject(value, "prescriptionItemId")
          : "",
        parentPrescriptionItemId: value.parentPrescriptionItemId
          ? getObject(value, "parentPrescriptionItemId")
          : "",
        quantity:
          isUpdate === true
            ? value.prescribedItemId === prescribedItemId
              ? qty
              : 0
            : value.quantity
            ? getObject(value, "quantity")
            : 0,
        prescribedQuantity: value.prescribedQuantity
          ? getObject(value, "prescribedQuantity")
          : null,
        unitOfMeasureCode: value.unitOfMeasureCode
          ? getObject(value, "unitOfMeasureCode")
          : "",
        dosageText: isUpdate
          ? value.prescribedItemId === prescribedItemId
            ? dose
            : ""
          : value.dosageText
          ? getObject(value, "dosageText")
          : "",
        additionalDosage: value.additionalDosage
          ? getObject(value, "additionalDosage")
          : "",
        runningTotal: value.runningTotal ? getObject(value, "runningTotal") : 0,
        allocationType: value.allocationType
          ? getObject(value, "allocationType")
          : 0,
        positionId: value.positionId ? getObject(value, "positionId") : "",
        itemCrossedOut: value.itemCrossedOut
          ? getObject(value, "itemCrossedOut")
          : false,
        notDispensedReasonCode: value.notDispensedReasonCode
          ? getObject(value, "notDispensedReasonCode")
          : "",
        prescriptionItemLabelWarnings:
          value.prescriptionItemLabelWarnings == null
            ? []
            : new prescriptionItemLabelWarnings(
                value.prescriptionItemLabelWarnings
              ).obj,
        epsLineItem: value.epsLineItem ? getObject(value, "epsLineItem") : "",
        epsLineItemStatus: value.epsLineItemStatus
          ? getObject(value, "epsLineItemStatus")
          : "",
        drugWrittenAs: value.drugWrittenAs
          ? getObject(value, "drugWrittenAs")
          : value.originalPrescribedDrugName,
        prescriptionId: value.prescriptionId
          ? getObject(value, "prescriptionId")
          : "",
        prescribedItemId: value.prescribedItemId
          ? getObject(value, "prescribedItemId")
          : "",
        prescribedItemSspId: value.prescribedItemSspId
          ? getObject(value, "prescribedItemSspId")
          : "",
        cdSchedule: value.cdSchedule ? getObject(value, "cdSchedule") : 0,
        numberOfCharges: value.numberOfCharges
          ? getObject(value, "numberOfCharges")
          : 0,
        chargesAmount: value.chargesAmount
          ? getObject(value, "chargesAmount")
          : 0,
        drugTariffPrice: value.drugTariffPrice
          ? getObject(value, "drugTariffPrice")
          : null,
        dispensingFee: value.dispensingFee
          ? getObject(value, "dispensingFee")
          : null,
        markupPercent: value.markupPercent
          ? getObject(value, "markupPercent")
          : null,
        finalFee: value.finalFee ? getObject(value, "finalFee") : null,
        prescriptionMedicationId: value.prescriptionMedicationId
          ? getObject(value, "prescriptionMedicationId")
          : null,
        prescriptionItemCheckActions:
          value.prescriptionItemCheckActions != []
            ? PrescriptionItemCheckActions === ""
              ? []
              : new prescriptionItemCheckActions(
                  PrescriptionItemCheckActions,
                  value.prescribedItemId,
                  prescribedItemId
                ).obj
            : value.prescriptionItemCheckActions,
        qualifiedService: value.qualifiedService
          ? getObject(value, "qualifiedService")
          : 0,
        originalDosageText: value.originalDosageText
          ? getObject(value, "originalDosageText")
          : "",
        aiDoseText: value.aiDoseText ? getObject(value, "aiDoseText") : "",
        isParent: value.isParent ? getObject(value, "isParent") : false,
        additionalEndorsements:
          value.additionalEndorsements == null
            ? []
            : new additionalEndorsements(value.additionalEndorsements).obj,
        prescriptionItemEmergency:
          value.prescriptionItemEmergency === null
            ? {
                prescriptionItemEmergencyId: uuidv4(),
                emergencyReason: "",
              }
            : new prescriptionItemEmergency(value.prescriptionItemEmergency)
                .obj,
        prescriptionItemSchedules:
          value.prescriptionItemSchedules == null
            ? []
            : new prescriptionItemSchedules(value.prescriptionItemSchedules)
                .obj,
        notes:
          value.notes == null
            ? []
            : new notesprescriptionItems(value.notes).obj,
        needCheck: value.needCheck ? getObject(value, "needCheck") : null,
        isDirty: value.isDirty ? getObject(value, "isDirty") : false,
      };
    });
  }
}

export default prescriptionItems;
