import * as React from "react";

import { useEffect, useState } from "react";
import "../assets/darkthemebutton.css";
import { HiOutlineSun } from "react-icons/hi";
import { HiOutlineMoon } from "react-icons/hi";
import useTitanApp from "../hooks/useTitanApp";
import { toggleTheme } from "../dispatchers/appDispatchers";

function DarkThemeButton() {
  const { appState } = useTitanApp();
  const { theme } = appState || {};

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
    <div className="header-icons">
      <div className="icon-only label-btn" onClick={toggleTheme}>
        {theme === "dark-theme" ? (
          <HiOutlineSun
            color={theme === "dark-theme" ? "#fff" : "#000"}
            size={20}
          />
        ) : (
          <HiOutlineMoon
            color={theme === "dark-theme" ? "#fff" : "#000"}
            size={20}
          />
        )}
      </div>
      <div className="icon-wtext">
        <p>Toggle {theme === "dark-theme" ? "Light" : "Night"} mode</p>
      </div>
    </div>
  );
}

export default DarkThemeButton;
