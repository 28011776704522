import { v4 as uuidv4 } from "uuid";

let _id = uuidv4();

class setAddSession {
  constructor(userFullName, userEmail) {
    this.obj = {
      userId: userEmail,
      userFullName: userFullName,
      userEmailAddress: userEmail,
      startTime: new Date().toISOString(),
      endTime: null,
      actions: [],
      totalProcessed: 0,
      totalEarning: 0.0,
      totalSessionTime: "",
    };
  }
}

export default setAddSession;
