import React from "react";
import "./CustomTemplate.css";
import { useLoaderData } from "react-router-dom";
import SettingsSidebar from "../../../../SettingsSidebar/SettingsSidebar";
import TemplateList from "./components/TemplateList";
import CustomLoader from "../../../../controls/CustomLoader";
import AsyncRendererRouter from "../../../../AsyncRendererRouter/AsyncRendererRouter";
import useTitanTemplate from "../../../../../hooks/useTitanTemplate";
import ConsultationLoadingComponent from "../../../../SettingsLoadingComponents/ConsultationLoadingComponent";

function CustomTemplate() {
  const { templateState } = useTitanTemplate();
  const { isConsultationLoading } = templateState || {};
  const { appData } = useLoaderData() || {};

  return (
    <AsyncRendererRouter
      resolve={appData}
      isLoading={isConsultationLoading}
      loadingElement={<ConsultationLoadingComponent />}
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <SettingsSidebar />
          <div className="settings_content_wrapper">
            <div className="settings_heading">
              <h1>Consultation</h1>
            </div>
            <div className={"settings_content_area members_wrapper"}>
              <div>
                <TemplateList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}

export default CustomTemplate;
