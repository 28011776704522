import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import CustomButton from "../../../controls/CustomButton";
function SetPassword() {
  const [addedPassword, setAddedPassword] = useState({
    currentpassword: "",
    newpassword: "",
  });
  function handleChangeDetail(event) {
    const { name, value } = event?.target || {};
    setAddedPassword({
      ...addedPassword,
      [name]: value,
    });
  }
  return (
    <div className="pass_container">
      <div className="pass_fields">
        <TextField
          id="outlined-basic"
          label="Current Password"
          variant="outlined"
          fullwidth
          name="currentpassword"
          value={addedPassword?.currentpassword || ""}
          onChange={handleChangeDetail}
        />
        <TextField
          id="outlined-basic"
          label="New Password"
          variant="outlined"
          fullwidth
          name="newpassword"
          value={addedPassword?.newpassword || ""}
          onChange={handleChangeDetail}
        />
      </div>
      <div className="footer_btn">
        <CustomButton label="Save" />
      </div>
    </div>
  );
}

export default SetPassword;
