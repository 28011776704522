import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useTitanApp from "../../../../hooks/useTitanApp";
import { BsX } from "react-icons/bs";
import Slide from "@mui/material/Slide";
import {
  addTestModeService,
  getOrbitActiveServices,
  setIsTestMode,
  setshowTestModeDialog,
} from "../../../../dispatchers/orbitDispatchers";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomLoader from "../../../../components/controls/CustomLoader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const validationSchema = Yup.object({
  testmode_telephone: Yup.string()
    .required("Phone Number is required")
    .matches(
      /^(07\d{8,12}|447\d{7,11}|01\d{9,12}|44\d{10,13})$/,
      "Invalid phone number format"
    ),
  testmode_numberscript: Yup.number()
    .typeError("Must be a number")
    .positive("Must be a positive number")
    .integer("Must be an integer")
    .required("Number of scripts is required"),
});

const handleInput = (event) => {
  event.target.value = event.target.value.replace(/[^0-9]/g, "");
};

export default function OrbitTestMode() {
  const { appState, orbitState } = useTitanApp();
  const { darkMode } = appState || {};
  const { showTestModeDialog, isActiveServicesLoading } = orbitState || {};

  const handleCloseTestModeDialog = () => {
    setshowTestModeDialog(false);
  };
  const currentSessionId = localStorage.getItem("currentSessionId");

  const formik = useFormik({
    initialValues: {
      testmode_telephone: "",
      testmode_numberscript: 1,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const { testmode_telephone, testmode_numberscript } = values;
      const telephoneNumber = String(testmode_telephone);
      const numberOfScripts = Number(testmode_numberscript);

      if (telephoneNumber && numberOfScripts) {
        addTestModeService({ telephoneNumber, numberOfScripts }).then(() => {
          setIsTestMode(true);
          resetForm();
          handleCloseTestModeDialog();
          getOrbitActiveServices({ currentSessionId, isTestMode: true });
        });
      }
    },
  });

  return (
    <Dialog
      open={showTestModeDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseTestModeDialog}
      aria-describedby="alert-dialog-slide-description"
      className="orbit_successDialog orbit_testMode_dialog"
      hideBackdrop={true}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",
          },
        },
      }}
    >
      <DialogTitle className="title">
        <div>
          <div className="title-box">
            <div>
              <h2>Test Mode</h2>
            </div>
            <div className="closeIcon" onClick={handleCloseTestModeDialog}>
              <BsX size={22} color={darkMode ? "white" : "black"} />
            </div>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <div className="input_wrapper">
            <div className="input_field">
              <input
                id="testmode_telephone"
                name="testmode_telephone"
                placeholder="Telephone number"
                value={formik.values.testmode_telephone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onInput={handleInput}
                pattern="[0-9]*"
              />
              {formik.touched.testmode_telephone &&
              formik.errors.testmode_telephone ? (
                <p className="error">{formik.errors.testmode_telephone}</p>
              ) : null}
            </div>
            <div className="input_field">
              <input
                id="testmode_numberscript"
                name="testmode_numberscript"
                placeholder="Number of scripts"
                value={formik.values.testmode_numberscript}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onInput={handleInput}
                pattern="[0-9]*"
                disabled
              />
              {formik.touched.testmode_numberscript &&
              formik.errors.testmode_numberscript ? (
                <p className="error">{formik.errors.testmode_numberscript}</p>
              ) : null}
            </div>
          </div>
          <DialogActions>
            <button
              type="submit"
              className="orbit_actions withdraw_btn generate_btn"
            >
              {isActiveServicesLoading ? <CustomLoader /> : "Generate"}
            </button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
