import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentTimer,
  setCurrentTimeActive,
} from "../dispatchers/orbitDispatchers";
import useTitanApp from "../hooks/useTitanApp";

const useTimer = () => {
  const { orbitState } = useTitanApp();
  const { timer, isActive } = useSelector((state) => orbitState) || {};
  const [isPaused, setIsPaused] = useState(false);
  const countRef = useRef(null);

  useEffect(() => {
    let interval;
    if (isActive) {
      interval = setInterval(() => {
        setCurrentTimer(timer);
        localStorage.setItem("time", timer);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, timer]);

  const handleStart = () => {
    setIsPaused(true);
    localStorage.setItem("timerOn", true);
  };
  const handleReset = () => {
    setCurrentTimeActive(false);
    setIsPaused(false);
    setCurrentTimer(0);
    localStorage.setItem("timerOn", false);
    localStorage.setItem("time", 0);
  };

  return { timer, isActive, isPaused, handleStart, handleReset };
};

export default useTimer;
