import React, { useState } from "react";
import CustomLabel from "../controls/CustomLabel";
import TherapiesAccordion from "./TherapiesAccordion";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { getFormattedDate } from "../../utils/FormattedDate";
import MapDropdown from "./../Header/MapDropdown";
import { calculateDaysAgo } from "../../data/calulateDays";
import HistoryAccordion from "./HistoryAccordion";
import { ethnicityTypes } from "../../enum/ethnicityType";
import useTitanApp from "../../hooks/useTitanApp";
import CustomLoader from "../controls/CustomLoader";
import LoadingComponentNMS from "../LoadingComponentNMS/LoadingComponentNMS";
import LoadingComponentNMSPatient from "../LoadingComponentNMS/LoadingComponentNMSPatient";
import CustomButton from "../controls/CustomButton";
import {
  getTitanPatientPds,
  setCurrentIsNmsPatientLoading,
} from "../../dispatchers/nmsDispatchers";
import { v4 as uuidv4 } from "uuid";
import { getAge } from "../../data/getStartDateForStages";
import { formatPhoneNumber } from "../../data/formatPhone";
import { MdOutlineModeEditOutline } from "react-icons/md";
import {
  setCurrentContactServiceId,
  setCurrentPatientContact,
} from "../../dispatchers/pharmacyServiceDispatchers";
import PatientContactModal from "../PatientContactModal/PatientContactModal";
function TabPanel(props) {
  const { children, value, index, ...other } = props || {};

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function NmsPatientInfo({ Nmsdetails, darkModeChange }) {
  const { appState, nmsServiceState, pharmacyServiceState } = useTitanApp();
  const { isOrbitUser } = appState || {};
  const { isPatientContact } = pharmacyServiceState || {};
  const {
    service,
    isNmsPatientLoading,
    pharmacyID,
    clinicalServiceId,
    editedPreferredContact,
  } = nmsServiceState || {};
  const { patientGetModel, patientMedicationCheck } = Nmsdetails || {};
  let sortedTheraphies = [];
  const contactNumber = patientGetModel?.preferredContact?.value;
  const isNumeric = !isNaN(contactNumber) && !isNaN(parseFloat(contactNumber));
  const patientName = `${patientGetModel?.title ?? ""} ${
      patientGetModel?.forename ?? ""
    } ${patientGetModel?.middleName ?? ""} ${patientGetModel?.surname ?? ""}`,
    dob = patientGetModel?.dob ? getFormattedDate(patientGetModel?.dob) : "",
    gender = patientGetModel?.sex
      ? patientGetModel?.sex === 1
        ? "Male"
        : "Female"
      : "",
    nhsNumber = patientGetModel?.nhsNumber ?? "",
    patientAddress = `${
      patientGetModel?.preferredAddress?.address?.houseName ?? ""
    } ${patientGetModel?.preferredAddress?.address?.streetName ?? ""} ${
      patientGetModel?.preferredAddress?.address?.locality ?? ""
    } ${patientGetModel?.preferredAddress?.address?.town ?? ""}`,
    patientAllergy = "Unknown",
    patientPhoneNumber = contactNumber,
    patientEthnicity = ethnicityTypes[patientGetModel?.ethnicity ?? ""],
    multipleContacts = patientGetModel?.contacts
      ?.map((contact) => {
        if (contact?.contactType === 2 || contact?.contactType === 4) {
          return {
            value: contact?.value,
            type:
              contact?.contactType === 2
                ? "Telephone"
                : contact?.contactType === 4
                ? "Mobile"
                : "",
          };
        }
        return null;
      })
      .filter(Boolean);
  const [selectedDay, setselectedDay] = useState(0);
  function changeHistory(day) {
    setselectedDay(day);
  }
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const sortTherapies = () => {
    if (patientMedicationCheck?.patientMedications !== null) {
      sortedTheraphies = [...patientMedicationCheck?.patientMedications].sort(
        (a, b) =>
          a?.prescribedItem?.writtenAs.localeCompare(
            b?.prescribedItem?.writtenAs
          )
      );
    }
  };
  function syncPds() {
    setCurrentIsNmsPatientLoading(true);
    getTitanPatientPds({ pharmacyID, clinicalServiceId }).then(() => {
      setCurrentIsNmsPatientLoading(false);
    });
  }
  const handleEditContact = () => {
    setCurrentPatientContact(true);
    setCurrentContactServiceId(clinicalServiceId);
  };
  function getPhoneNumber() {
    if (editedPreferredContact === null || editedPreferredContact === "") {
      return contactNumber;
    } else {
      return editedPreferredContact
        ? isOrbitUser
          ? formatPhoneNumber(editedPreferredContact)
          : editedPreferredContact
        : "";
    }
  }

  return isNmsPatientLoading ? (
    <LoadingComponentNMSPatient />
  ) : (
    <div className="content-div patient-info-container">
      <div className="general-inner-boxes">
        {patientGetModel !== null ? (
          <>
            <div className="genaral_pds">
              <h2 className="card-heading">General</h2>
              <div className="pds_button">
                <CustomButton action={syncPds} label="PDS Sync" />
              </div>
            </div>
            <div className="general-info">
              <div className="info-box">
                <span>
                  <CustomLabel name="Name" />
                  <p className="card-inner-value">{patientName}</p>
                </span>
              </div>
              <div className="info-box">
                <span>
                  <CustomLabel name="Date of birth" />
                  <p className="card-inner-value">{`${dob}${
                    patientGetModel?.dob !== null
                      ? ` (${getAge(patientGetModel?.dob)})`
                      : ""
                  }`}</p>
                </span>
              </div>
            </div>
            <div className="general-info">
              <div className="info-box">
                <span>
                  <CustomLabel name="Gender" />
                  <p className="card-inner-value">{gender}</p>
                </span>
              </div>
              <div className="info-box">
                <span>
                  <CustomLabel name="NHS No." />
                  <p className="card-inner-value">
                    {nhsNumber ? nhsNumber : ""}
                  </p>
                </span>
              </div>
            </div>
            <div className="general-info">
              <div className="info-box">
                <span>
                  <div className="d-flex edit-contact">
                    <CustomLabel name="Preferred Contact" />
                    <MdOutlineModeEditOutline
                      onClick={handleEditContact}
                      className="edit-pencil"
                    />
                  </div>
                  <p className="card-inner-value phone-field">
                    {editedPreferredContact
                      ? isOrbitUser
                        ? formatPhoneNumber(editedPreferredContact)
                        : editedPreferredContact
                      : ""}
                  </p>
                </span>
              </div>
              <div className="info-box">
                <span>
                  <CustomLabel name="Ethnicity" />
                  <p className="card-inner-value">{patientEthnicity}</p>
                </span>
              </div>
            </div>
            {patientGetModel?.contacts?.length > 0 ? (
              <div className="general-info grids-2">
                {multipleContacts?.map((contact, index) => (
                  <div className="info-box" key={index}>
                    <span>
                      <CustomLabel name={contact?.type} />
                      <p className="card-inner-value">{contact?.value}</p>
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
            {contactNumber !== editedPreferredContact ? (
              <div className="general-info grids-2">
                <div className="info-box">
                  <span>
                    <CustomLabel name="Preferred Number" />
                    <p className="card-inner-value">{contactNumber}</p>
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="general-info">
              <div className="info-box">
                <span>
                  <CustomLabel name="Address" />
                  <p className="card-inner-value">{patientAddress}</p>
                </span>
              </div>
            </div>
            <h2 className="card-heading">Allergies</h2>
            <div className="general-info">
              <div className="allergies-box">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 2.31306V5.99487C0 8.7563 2.23858 10.9949 5 10.9949C5.1971 10.9949 5.39154 10.9835 5.58268 10.9613C5.77267 11.3393 6.00496 11.6924 6.27335 12.0144L4.13604 14.1517C3.94078 14.3469 3.94078 14.6635 4.13604 14.8588C4.3313 15.054 4.64788 15.0541 4.84314 14.8588L6.98045 12.7215C7.9342 13.5166 9.16124 13.9949 10.5 13.9949C13.5376 13.9949 16 11.5324 16 8.49487V4.40396C16 3.62574 15.3691 2.99487 14.5909 2.99487L10.5 2.99487C10.0252 2.99487 9.56438 3.05505 9.12487 3.16818C8.22371 1.85568 6.71238 0.994873 5 0.994873H1.31818C0.59017 0.994873 0 1.58504 0 2.31306ZM7.69114 12.0108L10.8536 8.8484C11.0488 8.65314 11.0488 8.33656 10.8536 8.14129C10.6583 7.94603 10.3417 7.94603 10.1465 8.14129L6.98404 11.3037C6.36823 10.5339 6 9.55737 6 8.49487C6 6.00959 8.01472 3.99487 10.5 3.99487L14.5909 3.99487C14.8168 3.99487 15 4.17803 15 4.40396V8.49487C15 10.9802 12.9853 12.9949 10.5 12.9949C9.43749 12.9949 8.46098 12.6266 7.69114 12.0108ZM5 1.99487C6.27614 1.99487 7.4127 2.59247 8.1451 3.52309C7.07236 4.0321 6.19056 4.87807 5.63645 5.92427L3.85351 4.14132C3.65824 3.94606 3.34166 3.94606 3.1464 4.14132C2.95114 4.33658 2.95114 4.65316 3.1464 4.84843L5.22631 6.92833C5.07906 7.42481 5 7.95061 5 8.49487C5 9.013 5.07165 9.51439 5.20557 9.98968C5.13748 9.99313 5.06894 9.99487 5 9.99487C2.79086 9.99487 1 8.20401 1 5.99487L1 2.31306C1 2.13733 1.14246 1.99487 1.31818 1.99487L5 1.99487Z"
                    className="icons-color"
                  />
                </svg>
                <p className="allergies-para">{patientAllergy}</p>
              </div>
            </div>
          </>
        ) : (
          <div>
            <p>Patient Details are not available!</p>
          </div>
        )}
        {patientMedicationCheck !== null ? (
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label=""
                centered={true}
              >
                <Tab
                  label={<span className={`tabs_text_alt`}>Therapies</span>}
                  {...a11yProps(0)}
                  className="para-color"
                />
                <Tab
                  label={<span className={`tabs_text_alt`}>History</span>}
                  {...a11yProps(1)}
                  className="para-color"
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0} className="t_h_panel">
              <>
                <div className="therapies-data">
                  {sortTherapies()}
                  {sortedTheraphies?.map((item) => {
                    return (
                      <TherapiesAccordion
                        Label="Unknown"
                        data={item}
                        darkModeChange={darkModeChange}
                      />
                    );
                  })}
                </div>
              </>
            </TabPanel>

            <TabPanel value={value} index={1} className="t_h_panel">
              <>
                <div className="general-info">
                  <MapDropdown handleCalback={changeHistory} />
                </div>
                {patientMedicationCheck?.patientMedications?.map((item) => {
                  if (selectedDay >= calculateDaysAgo(item.lastDispenseDate)) {
                    return <HistoryAccordion item={item} />;
                  } else if (selectedDay === 0) {
                    return <HistoryAccordion item={item} />;
                  }
                })}
              </>
            </TabPanel>
          </Box>
        ) : (
          <></>
        )}
      </div>
      {isPatientContact && <PatientContactModal view="nms" />}
    </div>
  );
}

export default NmsPatientInfo;
