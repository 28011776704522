import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useTitanApp from "../../../../../hooks/useTitanApp";
import { ReactComponent as CalendarSVG } from "./../../../../../assets/images/orbitCalendar.svg";
import { ReactComponent as CreditCardSVG } from "./../../../../../assets/images/orbitCreditcard.svg";
import LoadingComponentSession from "../../../../../components/LoadingComponentOrbit/LoadingComponentSession";

export default function OrbitSessions() {
  const { orbitState } = useTitanApp();
  const { orbitUserSessions, isUserSessionLoading } = orbitState || {};
  return (
    <div className="sessions-box">
      {isUserSessionLoading ? (
        <LoadingComponentSession />
      ) : (
        <div className="orbit_box-container">
          {orbitUserSessions.length > 0 ? (
            <div className="accordions_wrapper">
              <p>Todays Sessions</p>
              {orbitUserSessions?.map((item) => {
                return (
                  <Accordion className="accordion">
                    <AccordionSummary
                      className="title"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className="session">
                        <p className="first">{item?.totalTime}</p>
                        <div className="d-flex sessions-flex">
                          <div className="d-flex-a-center cal-label">
                            <CalendarSVG />
                            <p>
                              {item?.today} {item?.sessionTime}
                            </p>
                          </div>
                          <div className="d-flex-a-center cal-label">
                            <CreditCardSVG />
                            <p>£{item?.earning}</p>
                          </div>
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className="data-box-container">
                      <div className="monthlyReport_boxes">
                        <div className="orbit_box">
                          <p>Nms Interventions</p>
                          <p>{item?.totalNmsIntervention}</p>
                        </div>
                        <div className="orbit_box">
                          <p>Nms Follow Up</p>
                          <p>{item?.totalNmsFollowUp}</p>
                        </div>
                        <div className="orbit_box">
                          <p>Clinical Checks</p>
                          <p>{item?.totalClinicalChecks}</p>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          ) : (
            <div>
              <p className="orbit_bottomNote t-center">
                No user sessions for now.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
