import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Lottie from "lottie-react";
import useTitanApp from "../../../../hooks/useTitanApp";
import { ReactComponent as SuccessTickSVG } from "./../../../../assets/images/successTick.svg";
import { BsArrowRight, BsX } from "react-icons/bs";
import Confetti from "./../../../../assets/Confetti.json";
import Slide from "@mui/material/Slide";

import {
  setCurrentOpenSuccessDialog,
  setCurrentAnimationPlaying,
} from "./../../../../dispatchers/orbitDispatchers";
import { setCurrentSkipCountData } from "../../../../dispatchers/nmsDispatchers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function OrbitEndDialog({}) {
  const { appState, orbitState, orbitDashboardLoader } = useTitanApp();
  const { darkMode } = appState || {};
  const { openSuccessDialog, isAnimationPlaying, totalCount, totalPrice } =
    orbitState || {};
  const currentSessionId = localStorage.getItem("currentSessionId");
  const session = localStorage.getItem("sessionData");
  const sessionData = JSON.parse(session);
  const handleCloseSuccessDialog = () => {
    setCurrentOpenSuccessDialog(false);
    setCurrentAnimationPlaying(false);
    localStorage.removeItem("sessionData");
    localStorage.removeItem("currentSessionId");
    setCurrentSkipCountData(3);
    orbitDashboardLoader();
  };

  return (
    <Dialog
      open={openSuccessDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseSuccessDialog}
      aria-describedby="alert-dialog-slide-description"
      className="orbit_successDialog"
      hideBackdrop={true}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",
          },
        },
      }}
    >
      <DialogTitle className="title">
        <div>
          {isAnimationPlaying && (
            <div className="confettiAnimation">
              <Lottie animationData={Confetti} loop={0} />
            </div>
          )}
          <div className="title-box">
            <div>
              <SuccessTickSVG />
              <h2>Session Complete</h2>
            </div>
            <div className="closeIcon" onClick={handleCloseSuccessDialog}>
              <BsX size={22} color={darkMode ? "white" : "black"} />
            </div>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="content_wrapper">
          {/* <div className="content_box">
            <p className="content_label">Processed RX's</p>
            <p className="content_value">128</p>
          </div>
          <div className="content_box">
            <p className="content_label">Av. Price</p>
            <p className="content_value">£0.72</p>
          </div> */}
          <div className="content_box">
            <p className="content_label">Session ID</p>
            <p className="content_value">
              {currentSessionId !== null ? currentSessionId : sessionData?._id}
            </p>
          </div>
          <div className="content_box">
            <p className="content_label">Processed Nms</p>
            <p className="content_value">{sessionData?.totalProcessed}</p>
          </div>
          <div className="content_box colspan-2">
            <p className="content_label">Total credit earned</p>
            <p className="content_value_total">£{sessionData?.totalEarning}</p>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <button
          className="orbit_actions withdraw_btn"
          onClick={handleCloseSuccessDialog}
        >
          Finish
        </button>
      </DialogActions>
    </Dialog>
  );
}
