import { connect } from "react-redux";
import UserControls from "./userControls";
class setTemplateModel {
  constructor(response, title, userControlObj, active) {
    this.obj = response?.map((value, index) => {
      return {
        groupId: value.groupId,
        order: value.order,
        sectionTitle: index === active ? title : value.sectionTitle,
        isParent: value.isParent,
        userControls:
          title === value.sectionTitle
            ? new UserControls(value.userControls, userControlObj, true).obj
            : value.userControls
            ? new UserControls(value.userControls, null, false).obj
            : [],
        templateSectionType: value.templateSectionType,
        isRequired: value.isRequired,
      };
    });
  }
}

export default setTemplateModel;
