import { getObject } from "../getObjectValue";

class setPrescriptionChecks {
  constructor(response) {
    this.patientId = response.patientId ? response.patientId : null;
    this.prescriptionDate = response.prescriptionDate
      ? response.prescriptionDate
      : "";
    this.patientDob = response.patient.dob ? response.patient.dob : "";
    this.prescriptionType = response.prescriptionType
      ? response.prescriptionType
      : 0;
    this.patientMatchStatus = response.prescriptionPatientMatchStatusType
      ? response.prescriptionPatientMatchStatusType
      : 0;
    this.prescriptionId = response.prescriptionId
      ? response.prescriptionId
      : "";
    this.incomingPrescriptionId = response.prescriptionIncomingId
      ? response.prescriptionIncomingId
      : "";
    this.prescriptionCode = response.prescriberCode
      ? response.prescriberCode
      : "";
  }
}

export default setPrescriptionChecks;
