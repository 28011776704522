import React from "react";
import "./../../../../assets/css/Dashboard/ServicesKanban.scss";
import { getSmsDate } from "../../../../data/getKanbanAndListViewData";
import { Tag } from "primereact/tag";
export default function ServiceMedication({ medications }) {
  return (
    <div className="medicines">
      <div className="med-box">
        <p>{medications?.drugName}</p>
      </div>
    </div>
  );
}
