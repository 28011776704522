import { dispatch, getDataFromStore } from "../store/store";
import {
  addServiceData,
  getServicesData,
  updateServiceData,
  getServiceTypeByIdData,
  setIsTypeLoading,
  setCurrentTypeData,
  setCurrentServiceModelData,
  setCurrentClinicalServiceBuilderData,
  setCurrentStagesData,
  setCurrentPharmacyIdsData,
} from "../features/typeSlice/typeSlice";
import { logError } from "../services/logService";

const reducerName = "type";

// #region State Setters
export const setTypeLoading = (isLoading) => {
  dispatch(setIsTypeLoading(isLoading));
};

export const setCurrentType = (typeObject) => {
  dispatch(setCurrentTypeData(typeObject));
};

export const setCurrentServiceModel = (modelObject) => {
  dispatch(setCurrentServiceModelData(modelObject));
};

export const setCurrentClinicalServiceBuilder = (
  clinicalServiceBuilderObject
) => {
  dispatch(setCurrentClinicalServiceBuilderData(clinicalServiceBuilderObject));
};

export const setCurrentStages = (stagesArray) => {
  dispatch(setCurrentStagesData(stagesArray));
};

export const setCurrentPharmacyIds = (pharmacyIdsArray) => {
  dispatch(setCurrentPharmacyIdsData(pharmacyIdsArray));
};
// #endregion

// #region API getter/caller functions for components usage to dispatch REDUX extra reducers
export const getServicesTypes = async () => {
  let result = null;
  try {
    result = await dispatch(getServicesData()).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const addServiceType = async (data) => {
  let result = null;
  try {
    result = await dispatch(addServiceData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const getServiceTypeById = async (serviceTypeId) => {
  let result = null;
  try {
    result = await dispatch(getServiceTypeByIdData(serviceTypeId)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const updateServiceType = async (data) => {
  let result = null;
  try {
    result = await dispatch(updateServiceData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};
// #endregion
