import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import CustomButton from "../../../controls/CustomButton";
import CustomLoader from "../../../controls/CustomLoader";
import { useNavigate, useLoaderData, useParams } from "react-router-dom";
import SettingsSidebar from "../../../SettingsSidebar/SettingsSidebar";
import useTitanApp from "../../../../hooks/useTitanApp";
import AsyncRendererRouter from "../../../AsyncRendererRouter/AsyncRendererRouter";
import { GoArrowLeft } from "react-icons/go";
import { addRoom } from "../../../../dispatchers/locationDispatchers";
import { showError } from "../../../ToastedMessage/ToastedMessage";
import LocationsAddNewLoadingComponent from "../../../SettingsLoadingComponents/LocationsAddNewLoadingComponent";

const getCheckAll = (services, checkedItems) => {
  return checkedItems?.length === services?.length
    ? "Uncheck all"
    : "Check all";
};

export default function LocationsAddNew() {
  const { appState, locationState } = useTitanApp();
  const { darkMode } = appState || {};
  const { isLocationLoading, locationPharmacyData } = locationState || {};
  const [roomName, setRoomName] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const navigate = useNavigate();
  const { pharmacyId } = useParams() || {
    pharmacyId: "00000000-0000-0000-0000-000000000000",
  };

  const handleToggle = (item) => () => {
    const currentIndex = checkedItems.indexOf(item);
    const newChecked = [...checkedItems];

    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedItems(newChecked);
  };

  const handleSelectAll = (services) => {
    const ifAllChecked = checkedItems?.length === services?.length;
    let newCheckedItems = [...checkedItems];
    if (ifAllChecked === true) {
      newCheckedItems = [];
    } else {
      newCheckedItems = [...services];
    }

    setCheckedItems(newCheckedItems);
  };

  const handleRoomNameChange = (event) => {
    setRoomName(event?.target?.value);
  };

  const handleAddRoom = () => {
    const dataModel = {
      pharmacyID: pharmacyId,
      pharmacyRoom: {
        roomName: roomName,
        services: checkedItems,
      },
    };

    const savedRoom = addRoom(dataModel);
    savedRoom.then((result) => {
      if (result.success === true || result.success === "true") {
        navigate("/settings/locations");
      } else {
        showError("Room could not be added.", true, false);
      }
    });
  };

  const checkboxTheme = createTheme({
    overrides: {
      MuiCheckbox: {
        root: {
          "&:hover": {
            backgroundColor: "transparent", // Disable hover background effect
          },
        },
        checked: {
          "&:hover": {
            backgroundColor: "transparent", // Disable hover background effect when checked
          },
        },
      },
    },
  });

  const { appData } = useLoaderData() || {};

  return (
    <AsyncRendererRouter
      isLoading={isLocationLoading}
      resolve={appData}
      loadingElement={<LocationsAddNewLoadingComponent />}
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <SettingsSidebar onDarkModeChange={darkMode} />
          <div className="settings_content_wrapper">
            <div
              className="back_to_SettingsListView"
              onClick={() => {
                navigate("/settings/locations");
              }}
            >
              <GoArrowLeft className="icons-color" />
              <p>Back</p>
            </div>
            <div className="locations_addNew_wrapper">
              <div className="locations_general_info AddNew_sectionbox">
                <div>
                  <h6>General Information</h6>
                </div>
                <TextField
                  id="outlined-basic-add-room"
                  label="Room Name"
                  variant="outlined"
                  onChange={handleRoomNameChange}
                  fullwidth={true}
                  autoComplete={"off"}
                />
              </div>
              <div className="locations_select_services AddNew_sectionbox">
                <div>
                  <h6>Services Provided</h6>
                </div>
                <div className="settings_box">
                  <ThemeProvider theme={checkboxTheme}>
                    {locationPharmacyData?.length > 0 ? (
                      <div className="d-flex">
                        <List className="services_list">
                          {locationPharmacyData?.map((item, index) => (
                            <ListItem
                              key={index}
                              button
                              onClick={handleToggle(item)}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={checkedItems?.indexOf(item) !== -1}
                                  tabIndex={-1}
                                  disableRipple
                                />
                              </ListItemIcon>
                              <ListItemText primary={item?.serviceName} />
                            </ListItem>
                          ))}
                        </List>
                        <div className="checkAll_btnBox">
                          <Button
                            onClick={() =>
                              handleSelectAll(locationPharmacyData)
                            }
                          >
                            {getCheckAll(locationPharmacyData, checkedItems)}
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <span>No service found in this pharmacy</span>
                      </div>
                    )}
                  </ThemeProvider>
                </div>
              </div>
              <div className="footer_btn">
                <CustomButton label="Save Changes" action={handleAddRoom} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
