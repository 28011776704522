import React from "react";
import "./../../assets/css/BreadCrumb.scss";
import { useNavigate, useParams } from "react-router-dom";
import useTitanApp from "../../hooks/useTitanApp";
import Header from "../Header/Header";
import CallerControl from "../../components/CallerControl/CallerControl";
import LoadingComponentNMS from "../LoadingComponentNMS/LoadingComponentNMS";
import { Skeleton } from "@mui/material";
import { backOrbitByClinicalServiceId } from "../../dispatchers/nmsDispatchers";
import WarningCountdown from "../../pages/NmsIntervention/components/CallingWidget/WarningCountdown";

export default function BreadCrumbNMS({
  type,
  serviceType,
  stageNo,
  stageName,
  handleHangUpCall,
  handleClickOpenOptionsDialog,
  handleConsultationStopClick,
  handleClickCallScreenDialog,
  nexmoCallStatus,
  minutes,
  seconds,
  isConsultation,
  formattedConsultationMinutes,
  formattedConsultationSeconds,
  openInPersonConsultationDialog,
  handleOpenInPersonConsultation,
  handleTopbarIconConsultationClick,
  warningCountdownStatus,
  showWarningCounter,
  isStart,
}) {
  const navigate = useNavigate();
  const params = useParams();
  const { appState, nmsServiceState, clincicalChecksState } = useTitanApp();
  const { isOrbitUser, isOrbitSwitch, darkMode } = appState || {};
  const { pharmacyID, isNmsLoading, clinicalServiceId } = nmsServiceState || {
    pharmacyID: "00000000-0000-0000-0000-000000000000",
  };
  const { pharmacyId } = params || {
    pharmacyId: "00000000-0000-0000-0000-000000000000",
  };
  const handleGoBack = () => {
    isOrbitUser || isOrbitSwitch
      ? navigate(`/orbit`)
      : type === "Checks"
      ? navigate(`/pharmacyservices/${pharmacyId}`, {
          state: {
            onDarkModeChange: darkMode,
            showModal: serviceType === null ? false : true,
            serviceType: serviceType,
          },
        })
      : serviceType !== null
      ? navigate(`/pharmacyservices/${pharmacyID}`, {
          state: {
            onDarkModeChange: darkMode,
            showModal: serviceType === null ? false : true,
            serviceType: serviceType,
          },
        })
      : navigate("/", {
          state: { onDarkModeChange: darkMode },
        });
  };

  const handleSettingsClick = () => {
    navigate(`/settings/general`);
  };

  return (
    <div className="tabs_box">
      {isNmsLoading === true ? (
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={30}
          width={100}
          animation="wave"
          variant="rounded"
          style={{ marginLeft: "20px", marginTop: "10px" }}
        />
      ) : (
        <div className="breadCrumb_container">
          <div className="back-to-home-icon" onClick={handleGoBack}>
            <svg
              width="12"
              height="15"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.26034 0.200408C5.56387 0.48226 5.58145 0.956809 5.29959 1.26034L1.77348 5L5.29959 8.73967C5.58145 9.0432 5.56387 9.51775 5.26034 9.7996C4.9568 10.0815 4.48226 10.0639 4.2004 9.76034L0.200405 5.51034C-0.0668016 5.22258 -0.0668016 4.77743 0.200405 4.48966L4.20041 0.239665C4.48226 -0.0638681 4.95681 -0.081444 5.26034 0.200408Z"
                className="icons-color"
              />
            </svg>
          </div>
          {type === "NMS" ? (
            <div className="breadcrumbs" onClick={handleGoBack}>
              <span>
                <p className="breadCrumb_title">{`Nms ${stageName}`}</p>
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
      <div className="pharmacy_header_details">
        <div className="pharmacy_header_iconbox">
          {stageNo > 1 && (
            <CallerControl
              handleHangUpCall={handleHangUpCall}
              handleClickOpenOptionsDialog={handleClickOpenOptionsDialog}
              handleClickCallScreenDialog={handleClickCallScreenDialog}
              nexmoCallStatus={nexmoCallStatus}
              minutes={minutes}
              seconds={seconds}
              isConsultation={isConsultation}
              formattedConsultationMinutes={formattedConsultationMinutes}
              formattedConsultationSeconds={formattedConsultationSeconds}
              openInPersonConsultationDialog={openInPersonConsultationDialog}
              handleOpenInPersonConsultation={handleOpenInPersonConsultation}
              handleConsultationStopClick={handleConsultationStopClick}
              handleTopbarIconConsultationClick={
                handleTopbarIconConsultationClick
              }
            />
          )}
        </div>

        {!isStart && showWarningCounter && (
          <WarningCountdown warningCountdownStatus={warningCountdownStatus} />
        )}
      </div>

      <Header
        onDarkModeChange={darkMode}
        handleSettingsClick={handleSettingsClick}
        isPharmacyMergeEnabled={false}
        isToggleBottombarEnabled={false}
        isSettingsEnabled={true}
        isChatEnabled={true}
        isListViewEnabled={false}
      />
    </div>
  );
}
