export const PrescriptionItemEndorseType = Object.freeze({
  0: "UNKNOWN",
  1: "BB",
  2: "MF",
  3: "XP",
  4: "ED",
  5: "RC",
  6: "SP",
  8: "NCSO",
  9: "Prescriber contacted (PC)",
  10: "Prescriber not contacted (PNC)",
  11: "NDEC",
  12: "IP",
  13: "Contraceptive (CC)",
  14: "PD",
  15: "IP",
  16: "Selected list scheme (SLS)",
  17: "Advisory Committee on Borderline Substances (ACBS)",
  18: "Assorted Flavours (AF)",
  19: "Free supply of sexual health treatment (FS)",
  20: "SSP",
  21: "DM",
  22: "EP",
  23: "IP",
  24: "ND",
  25: "SP",
  26: "WR",
});
