import React from "react";
import SettingsSidebar from "../SettingsSidebar/SettingsSidebar";
import { Skeleton } from "@mui/material";
import useTitanApp from "../../hooks/useTitanApp";

export default function BillingLoadingComponent({
  selectedMenuItem,
  handleMenuItemClick,
}) {
  const { appState } = useTitanApp();
  const { features, darkMode } = appState;
  return (
    <div className="settings_page">
      <div className="settings_wrapper">
        <SettingsSidebar
          selectedMenuItem={selectedMenuItem}
          handleMenuItemClick={handleMenuItemClick}
          features={features}
        />
        <div className="settings_content_wrapper">
          <div className="settings_heading">
            <Skeleton
              sx={{
                bgcolor: darkMode ? "grey.800" : "",
              }}
              height={60}
              width={100}
              animation="wave"
            />
          </div>
          <div className=""></div>
          <div className="AddNew_sectionbox">
            <div className="width-20">
              <Skeleton
                sx={{
                  bgcolor: darkMode ? "grey.800" : "",
                }}
                height={80}
                animation="wave"
              />
            </div>
            <div className="billing-fields">
              <div className="width-100">
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={80}
                  animation="wave"
                />
              </div>
              <div className="width-100">
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={80}
                  animation="wave"
                />
              </div>
              <div className="width-100">
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={80}
                  animation="wave"
                />
              </div>
              <div className="width-100">
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={80}
                  animation="wave"
                />
              </div>
              <div className="width-100">
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={80}
                  animation="wave"
                />
              </div>
              <div className="width-100">
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={80}
                  animation="wave"
                />
              </div>
            </div>
          </div>
          <div className="AddNew_sectionbox billing-mt-30">
            <div className="width-20">
              <Skeleton
                sx={{
                  bgcolor: darkMode ? "grey.800" : "",
                }}
                height={80}
                animation="wave"
              />
            </div>
            <div className="billing-fields">
              <div className="width-100">
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={80}
                  animation="wave"
                />
              </div>
              <div className="width-100">
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={80}
                  animation="wave"
                />
              </div>
              <div className="width-100">
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={80}
                  animation="wave"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
