import React from "react";
import { BsChevronRight } from "react-icons/bs";
import "./../../assets/css/CreateReport.scss";
import { useNavigate } from "react-router-dom";

export default function CreateReport() {
  const navigate = useNavigate();
  const routeChangechecks = () => {
    // navigate("/createReport");
  };
  return (
    <div className="createReport_container">
      <button className="createReport_button" onClick={routeChangechecks}>
        <p>Create report</p>
        <BsChevronRight size={14} color="#fff" />
      </button>
    </div>
  );
}
