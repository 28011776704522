import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { RxCaretRight } from "react-icons/rx";
import { ReactComponent as NotCheckedSVG } from "./../../../assets/images/signupnotChecked.svg";
import { ReactComponent as CheckedSVG } from "./../../../assets/images/signupChecked.svg";
import * as Yup from "yup";
import useTitanApp from "../../../hooks/useTitanApp";
import {
  updateUserByUserEmail,
  setUser,
  setSignupForm,
  setEmailVerification,
} from "../../../dispatchers/onboardingVerificationDsipatchers";
import { useLocation } from "react-router-dom";
import { showError } from "../../ToastedMessage/ToastedMessage";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  // emailAddress: Yup.string().required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .test("password", "Password must meet the requirements", (value) => {
      const hasUppercase = /[A-Z]/.test(value);
      const hasLowercase = /[a-z]/.test(value);
      const hasNumber = /\d/.test(value);
      const hasSymbol = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value);
      const hasMinLength = value.length >= 8;

      return (
        hasUppercase && hasLowercase && hasNumber && hasSymbol && hasMinLength
      );
    }),
  phoneNumber: Yup.string()
    .required("Phone Number is required")
    .matches(
      /^(07\d{8,12}|447\d{7,11}|01\d{9,12}|44\d{10,13})$/,
      "Invalid phone number format"
    ),
});

export default function SignupForm() {
  const location = useLocation();
  const isOrbit =
    location.pathname && location?.pathname === "/usersignup/orbit"
      ? true
      : false;
  const { onboardingVerificationState } = useTitanApp();
  const { userData } = onboardingVerificationState || {};
  const [validationErrors, setValidationErrors] = useState({});
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState("");
  const [checkedError, setCheckedError] = useState("");
  const hasUppercase = /[A-Z]/.test(userData?.password);
  const hasLowercase = /[a-z]/.test(userData?.password);
  const hasNumber = /\d/.test(userData?.password);
  const hasSymbol = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
    userData?.password
  );
  const hasMinLength = userData?.password.length >= 8;
  let navigate = useNavigate();

  function handleChangeDetails(event) {
    const { name, value } = event.target;
    setUser({
      ...userData,
      [name]: value,
    });
  }

  const handleCheckedChange = (e) => {
    setChecked(e.target.checked);
    if (checked) {
      setCheckedError("");
    }
  };
  const handleEmailVerification = () => {
    setSignupForm(false);
    setEmailVerification(true);
  };
  const handleSignupFormBtn = async () => {
    if (checked === true) {
      try {
        await validationSchema.validate(userData, { abortEarly: false });
        if (userData?.newCompanyID !== null) {
          navigate(`/neworganization`);
        } else {
          var user = await updateUserByUserEmail(
            userData,
            userData?.emailAddress
          );
          if (user !== null) {
            handleEmailVerification();
          } else {
            showError(
              "Oops there was an issue creating the user, please contact support desk",
              true,
              false
            );
          }
        }
      } catch (errors) {
        const newValidationErrors = {};

        errors.inner.forEach((error) => {
          newValidationErrors[error.path] = error.message;
        });
        setValidationErrors(newValidationErrors);
      }
    } else {
      setCheckedError("You must agree to the Terms & Conditions");
    }
  };

  return (
    <div className="user_signup signup_form">
      <h4>Sign up for an account</h4>
      <div className="form_fields">
        <div>
          <label htmlFor="firstName">First Name</label>
          <InputText
            id="firstName"
            name="firstName"
            value={userData?.firstName || ""}
            onChange={handleChangeDetails}
          />
          {validationErrors.firstName && (
            <div className="validation">{validationErrors.firstName}</div>
          )}
        </div>
        <div>
          <label htmlFor="lastName">Last Name</label>
          <InputText
            id="lastName"
            name="lastName"
            value={userData?.lastName || ""}
            onChange={handleChangeDetails}
          />
          {validationErrors.lastName && (
            <div className="validation">{validationErrors.lastName}</div>
          )}
        </div>
        <div className="email-field">
          <label htmlFor="email">Email</label>
          {!isOrbit && (
            <label htmlFor="emailAddress">{userData?.emailAddress || ""}</label>
          )}
          {isOrbit && (
            <>
              <InputText
                id="email"
                name="emailAddress"
                type="email"
                value={userData?.emailAddress || ""}
                onChange={handleChangeDetails}
              />
              {validationErrors.lastName && (
                <div className="validation">
                  {validationErrors.emailAddress}
                </div>
              )}
            </>
          )}
        </div>
        <div>
          <label htmlFor="phoneNumber">Phone number</label>
          <InputText
            id="phoneNumber"
            name="phoneNumber"
            value={userData?.phoneNumber || ""}
            onChange={handleChangeDetails}
          />
          {validationErrors.phoneNumber && (
            <div className="validation">{validationErrors.phoneNumber}</div>
          )}
        </div>
        <div>
          <label htmlFor="password">Create Password</label>
          <InputText
            id="password"
            name="password"
            type="password"
            value={userData?.password || ""}
            onChange={handleChangeDetails}
          />
          {validationErrors.password && (
            <div className="validation">{validationErrors.password}</div>
          )}
        </div>
        <div>
          <div className="d-flex">
            <Checkbox
              checked={checked}
              onChange={handleCheckedChange}
              className="consent-check"
              id="consent"
            ></Checkbox>
            <label htmlFor="consent">I consent and agree</label>
          </div>
          <p className="f-weight">
            I agree with <span className="color">Terms & Conditions</span> and{" "}
            <span className="color">Privacy Policy</span>
          </p>
          {checkedError && <div className="error">{checkedError}</div>}
        </div>
      </div>
      <div className="validations">
        <div className="d-flex">
          {hasUppercase ? <CheckedSVG /> : <NotCheckedSVG />}
          <p>One uppercase character</p>
        </div>
        <div className="d-flex">
          {hasLowercase ? <CheckedSVG /> : <NotCheckedSVG />}
          <p>One lowercase character</p>
        </div>
        <div className="d-flex">
          {hasNumber ? <CheckedSVG /> : <NotCheckedSVG />}
          <p>One number</p>
        </div>
        <div className="d-flex">
          {hasSymbol ? <CheckedSVG /> : <NotCheckedSVG />}
          <p>One symbol</p>
        </div>
        <div className="d-flex">
          {hasMinLength ? <CheckedSVG /> : <NotCheckedSVG />}
          <p>Eight characters minimum</p>
        </div>
      </div>

      <div className="signup-footer">
        {error && <p className="error">{error}</p>}
        <button className="btn_success" onClick={handleSignupFormBtn}>
          <div></div>
          <p>Let's get started</p>
          <RxCaretRight color="#fff" size={25} />
        </button>
      </div>
    </div>
  );
}
