import React from "react";
import { Oval } from "react-loader-spinner";
import useTitanApp from "../../hooks/useTitanApp";

export default function CustomLoader() {
  const { appState } = useTitanApp();
  const { darkMode } = appState || {};
  return (
    <div className="oval-loading">
      <Oval
        height={150}
        width={150}
        color={`${darkMode ? "#fff" : "#333"}`}
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor={`${darkMode ? "#fff" : "#333"}`}
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );
}
