class listItems {
  constructor(response) {
    this.obj = response?.map((item) => {
      return {
        label: item.label,
        value: item.value,
        selected: item.selected,
      };
    });
  }
}

export default listItems;
