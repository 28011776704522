import React from "react";

export default function ECG() {
  return (
    <div className="card-shaped-box">
      <div className="tasks_header">
        <h3 className="pharmacy_header_name">ECG</h3>
      </div>
      <div className="card-shaped-content">
        <p className="description">Jessicas ECG reports show Sinus Rhythm</p>

        <div className="ecg-boxes">
          <div className="ecg_box">
            <div className="ecg_title">
              <p>Sinus Rhythm</p>
              <p>89 BPM Average</p>
            </div>
            <div className="ecg_desc">
              <p>05/07/23, 17:53</p>
              <svg
                width="12"
                height="9"
                viewBox="0 0 12 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.31802 0.164513L11.8536 3.70005C12.0488 3.89531 12.0488 4.21189 11.8536 4.40715L8.31802 7.94269C8.12276 8.13795 7.80617 8.13795 7.61091 7.94269C7.41565 7.74743 7.41565 7.43084 7.61091 7.23558L10.292 4.55307L0.5 4.5536C0.25454 4.5536 0.0503916 4.37673 0.00805569 4.14348L0 4.0536C0 3.80814 0.176875 3.60399 0.410124 3.56166L0.5 3.5536L10.292 3.55307L7.61091 0.87162C7.41565 0.676357 7.41565 0.359775 7.61091 0.164513C7.80617 -0.0307491 8.12276 -0.0307491 8.31802 0.164513Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
          <div className="ecg_box">
            <div className="ecg_title">
              <p>Sinus Rhythm</p>
              <p>74 BPM Average</p>
            </div>
            <div className="ecg_desc">
              <p>24/06/22, 17:53</p>
              <svg
                width="12"
                height="9"
                viewBox="0 0 12 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.31802 0.164513L11.8536 3.70005C12.0488 3.89531 12.0488 4.21189 11.8536 4.40715L8.31802 7.94269C8.12276 8.13795 7.80617 8.13795 7.61091 7.94269C7.41565 7.74743 7.41565 7.43084 7.61091 7.23558L10.292 4.55307L0.5 4.5536C0.25454 4.5536 0.0503916 4.37673 0.00805569 4.14348L0 4.0536C0 3.80814 0.176875 3.60399 0.410124 3.56166L0.5 3.5536L10.292 3.55307L7.61091 0.87162C7.41565 0.676357 7.41565 0.359775 7.61091 0.164513C7.80617 -0.0307491 8.12276 -0.0307491 8.31802 0.164513Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
