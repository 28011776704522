import * as React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import CustomButton from "../controls/CustomButton";
import "./../../assets/css/ConsentModal.scss";
import { ImWarning } from "react-icons/im";

export default function ConsentModal(props) {
  const { isOpen, handleCloseConsent, handleAcceptConsent } = props || {};

  const navigate = useNavigate();
  const [show, setShow] = useState(isOpen);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(isOpen);

  return (
    <>
      <Modal
        show={isOpen}
        onHide={handleClose}
        backdrop="static"
        centered
        className="NMS-modal"
      >
        <Modal.Header>
          <ImWarning size={18} color={"red"} />
          <Modal.Title>Consent declined</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Completing this form will remove the Nms from the board, are you
            sure you want complete it?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton label="No" action={handleCloseConsent} />
          <CustomButton label="Yes" action={handleAcceptConsent} />
        </Modal.Footer>
      </Modal>
    </>
  );
}
