import React, { memo, useMemo, useEffect } from "react";
import { Checkbox } from "@mui/material";
import useTitanApp from "../../../../hooks/useTitanApp";
import moment from "moment";

const ServicesCheckbox = memo(
  ({
    view,
    data,
    stageType,
    service,
    smsQueue,
    reSmsQueue,
    interventionQueue,
    declineQueue,
    rescheduleQueue,
    pdsQueue,
    orbitQueue,
    setCurrentSmsQueue,
    setCurrentReSmsQueue,
    setCurrentInterventionQueue,
    setCurrentDeclineQueue,
    setCurrentRescheduleQueue,
    setCurrentPdsQueue,
    setCurrentOrbitQueue,
    checksCount,
    setChecksCount,
  }) => {
    const { appState } = useTitanApp();
    const { currentPharmacy, currentUser } = appState || {};

    const isChecked = useMemo(() => {
      let checked = false;
      const currentService = view === "kanban" ? service : data?.service;
      const currentStageType = view === "kanban" ? stageType : data?.stageType;

      switch (currentStageType) {
        case 1:
          checked =
            smsQueue.some(
              (obj) => obj?.clinicalServiceID === currentService?._id
            ) ||
            declineQueue.some((obj) => obj === currentService?._id) ||
            rescheduleQueue.some((obj) => obj === currentService?._id) ||
            interventionQueue.some((obj) => obj === currentService?._id);
          break;
        case 2:
          checked =
            declineQueue.some((obj) => obj === currentService?._id) ||
            reSmsQueue.some(
              (obj) => obj?.clinicalServiceID === currentService?._id
            ) ||
            rescheduleQueue.some((obj) => obj === currentService?._id) ||
            interventionQueue.some((obj) => obj === currentService?._id);
          break;
        case 3:
          checked =
            orbitQueue.some(
              (obj) => obj?.clinicalServiceID === currentService?._id
            ) ||
            declineQueue.some((obj) => obj === currentService?._id) ||
            rescheduleQueue.some((obj) => obj === currentService?._id);
          break;
        case 4:
          checked =
            declineQueue.some((obj) => obj === currentService?._id) ||
            rescheduleQueue.some((obj) => obj === currentService?._id);
          break;
        default:
          checked = false;
          break;
      }
      return checked;
    }, [
      view,
      stageType,
      service,
      data,
      smsQueue,
      declineQueue,
      rescheduleQueue,
      interventionQueue,
      orbitQueue,
      reSmsQueue,
    ]);

    const addServiceInQueues = (event) => {
      const { checked } = event.target;
      const currentService = view === "kanban" ? service : data?.service;
      const currentStageType = view === "kanban" ? stageType : data?.stageType;

      switch (currentStageType) {
        case 1:
          if (checked) {
            const obj = {
              patientID: currentService?.patientId,
              patientName: currentService?.patientName,
              pharmacyID: currentService?.pharmacyID,
              message: "",
              clinicalServiceID: currentService?._id,
              pharmacyName: currentPharmacy?.teamPharmacyName,
              ods: "",
            };

            if (currentService?.telephoneNumber) {
              setCurrentSmsQueue([...smsQueue, obj]);
            }

            setCurrentInterventionQueue([
              ...interventionQueue,
              currentService?._id,
            ]);
            setCurrentRescheduleQueue([
              ...rescheduleQueue,
              currentService?._id,
            ]);
            setCurrentPdsQueue([...pdsQueue, currentService?._id]);
            setCurrentDeclineQueue([...declineQueue, currentService?._id]);
          } else {
            const newArray = smsQueue?.filter(
              (obj) => obj?.clinicalServiceID !== currentService?._id
            );
            const newArrayIntervention = interventionQueue?.filter(
              (obj) => obj !== currentService?._id
            );
            const newArrayDecline = declineQueue?.filter(
              (obj) => obj !== currentService?._id
            );
            const newArrayReschedule = rescheduleQueue?.filter(
              (obj) => obj !== currentService?._id
            );
            const newArrayPds = pdsQueue?.filter(
              (obj) => obj !== currentService?._id
            );
            setCurrentSmsQueue(newArray);
            setCurrentInterventionQueue(newArrayIntervention);
            setCurrentDeclineQueue(newArrayDecline);
            setCurrentRescheduleQueue(newArrayReschedule);
            setCurrentPdsQueue(newArrayPds);
          }
          break;
        case 2:
          if (checked) {
            setCurrentRescheduleQueue([
              ...rescheduleQueue,
              currentService?._id,
            ]);
            setCurrentInterventionQueue([
              ...interventionQueue,
              currentService?._id,
            ]);
            setCurrentDeclineQueue([...declineQueue, currentService?._id]);
            const obj = {
              patientID: currentService?.patientId,
              patientName: currentService?.patientName,
              pharmacyID: currentService?.pharmacyID,
              message: "",
              clinicalServiceID: currentService?._id,
              pharmacyName: currentPharmacy?.teamPharmacyName,
              ods: "",
            };

            setCurrentReSmsQueue([...reSmsQueue, obj]);
            setCurrentPdsQueue([...pdsQueue, currentService?._id]);
          } else {
            const newArray = reSmsQueue?.filter(
              (obj) => obj?.clinicalServiceID !== currentService?._id
            );
            const newArrayIntervention = interventionQueue?.filter(
              (obj) => obj !== currentService?._id
            );
            const newArrayDecline = declineQueue?.filter(
              (obj) => obj !== currentService?._id
            );
            const newArrayReschedule = rescheduleQueue?.filter(
              (obj) => obj !== currentService?._id
            );
            const newArrayPds = pdsQueue?.filter(
              (obj) => obj !== currentService?._id
            );
            setCurrentReSmsQueue(newArray);
            setCurrentDeclineQueue(newArrayDecline);
            setCurrentInterventionQueue(newArrayIntervention);
            setCurrentRescheduleQueue(newArrayReschedule);
            setCurrentPdsQueue(newArrayPds);
          }
          break;
        case 3:
          if (checked) {
            const serviceDueDateTime = moment(
              currentService?.stages.find((stage) => stage?.stageType === 3)
                ?.startDate
            ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
            const obj = {
              pharmacyId: currentService?.pharmacyID,
              serviceId: currentService?.serviceID,
              clinicalServiceID: currentService?._id,
              serviceType: 1,
              serviceCode: 1,
              patientId: currentService?.patientId,
              patientTelNumber: currentService?.telephoneNumber,
              patientName: currentService?.patientName,
              priorityWeighting: "0",
              rate: "",
              serviceDueDateTime: serviceDueDateTime,
              addedToQueueDateTime: new Date(),
              status: 1,
              bookingType: 1,
              sentToOrbitUser: currentUser?.fullName,
              userPickedBy: "",
              stageType: 3,
            };

            if (!currentService.sendToOrbit) {
              setCurrentOrbitQueue([...orbitQueue, obj]);
            }

            setCurrentRescheduleQueue([
              ...rescheduleQueue,
              currentService?._id,
            ]);
            setCurrentPdsQueue([...pdsQueue, currentService?._id]);
            setCurrentDeclineQueue([...declineQueue, currentService?._id]);
          } else {
            const newArray = orbitQueue?.filter(
              (obj) => obj?.clinicalServiceID !== currentService?._id
            );
            const newArrayDecline = declineQueue?.filter(
              (obj) => obj !== currentService?._id
            );
            const newArrayReschedule = rescheduleQueue?.filter(
              (obj) => obj !== currentService?._id
            );
            const newArrayPds = pdsQueue?.filter(
              (obj) => obj !== currentService?._id
            );
            setCurrentDeclineQueue(newArrayDecline);
            setCurrentRescheduleQueue(newArrayReschedule);
            setCurrentOrbitQueue(newArray);
            setCurrentPdsQueue(newArrayPds);
          }
          break;
        case 4:
          if (checked) {
            setCurrentRescheduleQueue([
              ...rescheduleQueue,
              currentService?._id,
            ]);
            setCurrentDeclineQueue([...declineQueue, currentService?._id]);
            setCurrentPdsQueue([...pdsQueue, currentService?._id]);
          } else {
            const newArrayDecline = declineQueue?.filter(
              (obj) => obj !== currentService?._id
            );
            const newArrayReschedule = rescheduleQueue?.filter(
              (obj) => obj !== currentService?._id
            );
            const newArrayPds = pdsQueue?.filter(
              (obj) => obj !== currentService?._id
            );
            setCurrentDeclineQueue(newArrayDecline);
            setCurrentRescheduleQueue(newArrayReschedule);
            setCurrentPdsQueue(newArrayPds);
          }
          break;
        default:
          break;
      }
    };

    const handleClick = (e) => {
      e.stopPropagation();
      addServiceInQueues(e, data);

      if (e?.target?.checked) {
        setChecksCount(checksCount + 1);
      } else {
        setChecksCount(checksCount - 1);
      }
    };

    return (
      <div>
        <Checkbox
          edge="start"
          tabIndex={-1}
          checked={isChecked}
          onClick={handleClick}
          size="small"
          disableRipple
        />
      </div>
    );
  }
);

export default ServicesCheckbox;
