import { getObject } from "../getObjectValue";
import selectedUnit from "./selectedUnit";
import unitsOfMeasure from "./unitsOfMeasure";

class prescriptionItemSchedules {
  constructor(response) {
    this.obj = response?.map((value) => {
      return {
        prescriptionItemScheduleId: getObject(
          value,
          "prescriptionItemScheduleId"
        ),
        prescriptionItemId: getObject(value, "prescriptionItemId"),
        dispenseDate: getObject(value, "dispenseDate"),
        supervised: getObject(value, "supervised"),
        quantity: getObject(value, "quantity"),
        unitOfMeasureCode: getObject(value, "unitOfMeasureCode"),
        isAllocated: getObject(value, "isAllocated"),
        unitsOfMeasure:
          value.unitsOfMeasure == null
            ? null
            : new unitsOfMeasure(value.unitsOfMeasure).obj,
        selectedUnit:
          value.selectedUnit == null
            ? null
            : new selectedUnit(value.selectedUnit).obj,
        multipleUnitsOfMeasure: getObject(value, "multipleUnitsOfMeasure"),
        unitValid: getObject(value, "unitValid"),
      };
    });
  }
}

export default prescriptionItemSchedules;
