import React from "react";

export default function TeamControl(props) {
  return (
    <div className="actionIconGroup_iconBox">
      <svg
        width="15"
        height="15"
        viewBox="0 0 14 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="icons-color"
          d="M5.00195 1.998C5.00195 0.894536 5.89649 0 6.99995 0C8.10342 0 8.99796 0.894536 8.99796 1.998C8.99796 3.10147 8.10342 3.996 6.99995 3.996C5.89649 3.996 5.00195 3.10147 5.00195 1.998ZM6.99995 1C6.44877 1 6.00195 1.44682 6.00195 1.998C6.00195 2.54918 6.44877 2.996 6.99995 2.996C7.55114 2.996 7.99796 2.54918 7.99796 1.998C7.99796 1.44682 7.55114 1 6.99995 1Z"

        />
        <path
          className="icons-color"
          d="M10 2.49799C10 1.66956 10.6716 0.997986 11.5 0.997986C12.3284 0.997986 13 1.66956 13 2.49799C13 3.32641 12.3284 3.99799 11.5 3.99799C10.6716 3.99799 10 3.32641 10 2.49799ZM11.5 1.99799C11.2239 1.99799 11 2.22184 11 2.49799C11 2.77413 11.2239 2.99799 11.5 2.99799C11.7761 2.99799 12 2.77413 12 2.49799C12 2.22184 11.7761 1.99799 11.5 1.99799Z"

        />
        <path
          className="icons-color"
          d="M2.5 0.997986C1.67157 0.997986 1 1.66956 1 2.49799C1 3.32641 1.67157 3.99799 2.5 3.99799C3.32843 3.99799 4 3.32641 4 2.49799C4 1.66956 3.32843 0.997986 2.5 0.997986ZM2 2.49799C2 2.22184 2.22386 1.99799 2.5 1.99799C2.77614 1.99799 3 2.22184 3 2.49799C3 2.77413 2.77614 2.99799 2.5 2.99799C2.22386 2.99799 2 2.77413 2 2.49799Z"

        />
        <path
          className="icons-color"
          d="M3.26756 4.99768C3.09739 5.29185 3 5.63339 3 5.99768L1 5.99768V8.49801C1 9.32644 1.67157 9.99801 2.5 9.99801C2.71194 9.99801 2.91361 9.95406 3.09639 9.87477C3.1705 10.2062 3.28572 10.522 3.43643 10.8167C3.14721 10.9336 2.83112 10.998 2.5 10.998C1.11929 10.998 0 9.87872 0 8.49801V5.99768C0 5.44539 0.447716 4.99768 1 4.99768H3.26756Z"

        />
        <path
          className="icons-color"
          d="M10.5636 10.8167C10.8528 10.9336 11.1689 10.998 11.5 10.998C12.8807 10.998 14 9.87872 14 8.49801V5.99768C14 5.4454 13.5523 4.99768 13 4.99768H10.7324C10.9026 5.29185 11 5.63339 11 5.99768L13 5.99768V8.49801C13 9.32644 12.3284 9.99801 11.5 9.99801C11.2881 9.99801 11.0864 9.95406 10.9036 9.87477C10.8295 10.2062 10.7143 10.522 10.5636 10.8167Z"

        />
        <path
          className="icons-color"
          d="M5 4.99768C4.44772 4.99768 4 5.44539 4 5.99768V8.99801C4 10.6549 5.34315 11.998 7 11.998C8.65685 11.998 10 10.6549 10 8.99801V5.99768C10 5.4454 9.55228 4.99768 9 4.99768H5ZM5 5.99768L9 5.99768V8.99801C9 10.1026 8.10457 10.998 7 10.998C5.89543 10.998 5 10.1026 5 8.99801V5.99768Z"

        />
      </svg>
      <div className="actionIconGroup_iconName">
        <p>Team Control</p>
      </div>
    </div>
  );
}
