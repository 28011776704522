import React, { useEffect, useState } from "react";
import CustomButton from "../../controls/CustomButton";
import useTitanApp from "../../../hooks/useTitanApp";
import { getIdentityVerificationDataBySessionId } from "../../../dispatchers/pharmacyServiceDispatchers";
import config from "../../../config";
import { showError } from "../../ToastedMessage/ToastedMessage";
import { setUser } from "../../../dispatchers/onboardingVerificationDsipatchers";
import { initApp } from "../../../index";
import { useNavigate } from "react-router-dom";

export default function OnboardingYoti({
  handleVerificationSectionNext,
  root,
}) {
  let navigate = useNavigate();

  const { pharmacyServiceState, onboardingVerificationState } = useTitanApp();
  const { userData } = onboardingVerificationState || {};
  const { yotiSessionDetail } = pharmacyServiceState || {};
  const { clientSessionToken, sessionId } = yotiSessionDetail || {};
  const { yotiUrl } = config;
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const iframeUrl =
    sessionId !== ""
      ? `${yotiUrl}sessionID=${sessionId}&sessionToken=${clientSessionToken}`
      : "";
  useEffect(() => {
    window.addEventListener("message", function (event) {
      if (event.data.eventType === "SUCCESS") {
        getIdentityVerificationDataBySessionId(
          sessionId,
          userData?.gphcNumber
        ).then((data) => {
          if (data?.length === 0) {
            setUser({
              ...userData,
              isVerified: true,
            });
            setIsSuccess(true);
          } else {
            setIsError(true);
            showError(
              `Something went wrong in "${data[0]?.key}". Please try again.`,
              true,
              false
            );
          }
          return data;
        });
      } else if (event.data.eventType === "ERROR") {
        const errorCode = event?.data?.eventCode;
        showError(
          `Something went wrong in "${errorCode}" Please try again`,
          true,
          false
        );
      }
    });
  }, []);

  const handleTryAgain = async () => {
    try {
      setTimeout(
        (initApp) => {
          initApp();
        },
        100,
        initApp
      );
      root.unmount();
      navigate("/");
    } catch (errors) {
      showError("User Details could not be updated.", true, false);
    }
  };

  return (
    <div className="ov_main_content_container">
      <iframe
        title="Yoti Identity Verification"
        src={iframeUrl}
        width="100%"
        height="650px"
        allow="camera"
      />
      <div className="ov_footer">
        {isSuccess && (
          <CustomButton label="Next" action={handleVerificationSectionNext} />
        )}
        {isError && <CustomButton label="Try Again" action={handleTryAgain} />}
      </div>
    </div>
  );
}
