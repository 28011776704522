import React, { useState } from "react";
import SettingsSidebar from "../../SettingsSidebar/SettingsSidebar";
import TextField from "@mui/material/TextField";
import { CirclePicker } from "react-color";
import CustomButton from "../../controls/CustomButton";
import setUpdateGeneralDetails from "../../../Model/GeneralsDetails/GeneralsDetailsModel";
import useTitanApp from "../../../hooks/useTitanApp";
import CustomLoader from "../../controls/CustomLoader";
import AsyncRendererRouter from "../../AsyncRendererRouter/AsyncRendererRouter";
import { useLoaderData } from "react-router-dom";
import {
  updateGeneral,
  setCompanyEmail,
  setCompanyContact,
  setCompanyThemeColor,
} from "../../../dispatchers/generalDispatchers";
import { showError } from "../../ToastedMessage/ToastedMessage";
import { Skeleton } from "@mui/material";
import GeneralLoadingComponent from "../../SettingsLoadingComponents/GeneralLoadingComponent";

export default function General() {
  const { appState, generalsState } = useTitanApp();
  const {
    isGeneralLoading,
    generalData,
    companyEmail,
    companyContact,
    companyThemeColor,
  } = generalsState || {};
  const { darkMode, features, currentPharmacyId } = appState || {};
  const { ifSettingMinimal } = features || {};
  const [selectedMenuItem, setSelectedMenuItem] = useState("General");

  async function addDetailsResponse() {
    const dataModel = new setUpdateGeneralDetails(
      generalData?.companyID,
      generalData?.companyName,
      generalData?.companyAddress,
      generalData?.companyOwnerUserId,
      companyEmail,
      companyThemeColor,
      companyContact
    ).obj;

    const response = updateGeneral(dataModel);
    response.then((result) => {
      if (result.success === true || result.success === "true") {
      } else {
        showError("Could not be updated.", true, false);
      }
    });
  }

  const handleCompanyEmailAddressChange = (event) => {
    setCompanyEmail(event?.target?.value);
  };

  const handleCompanyContactChange = (event) => {
    setCompanyContact(event?.target?.value);
  };

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };

  const { appData } = useLoaderData() || {};

  return (
    <AsyncRendererRouter
      resolve={appData}
      isLoading={isGeneralLoading}
      loadingElement={
        <GeneralLoadingComponent
          selectedMenuItem={selectedMenuItem}
          handleMenuItemClick={handleMenuItemClick}
        />
      }
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <SettingsSidebar
            selectedMenuItem={selectedMenuItem}
            handleMenuItemClick={handleMenuItemClick}
            onDarkModeChange={darkMode}
            features={features}
            pharmacyID={currentPharmacyId}
          />

          <div className="settings_content_wrapper">
            <div className="settings_heading">
              {isGeneralLoading ? (
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.900" : "",
                  }}
                  height={15}
                  width={100}
                  animation="wave"
                />
              ) : (
                <h5>General</h5>
              )}
            </div>
            <div className="">
              <div className="settings_formFields company_info_details AddNew_sectionbox">
                <div className="inline-not company_info_left_panel">
                  <div>
                    <p className="label_tag">Company Name</p>
                  </div>
                  <div>
                    <p className="label_tag_data">
                      {generalData?.companyName
                        ? generalData?.companyName
                        : "Company Name"}
                    </p>
                  </div>
                </div>
                <div className="inline-not company_info_right_panel">
                  <div>
                    <p className="label_tag">Company Address</p>
                  </div>
                  <div>
                    <p className="label_tag_data">
                      {generalData?.companyAddress
                        ? generalData?.companyAddress
                        : "Company Address"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="settings_formFields AddNew_sectionbox">
                <TextField
                  id="outlined-basic"
                  label="Contact Number"
                  variant="outlined"
                  value={companyContact}
                  fullwidth
                  onChange={handleCompanyContactChange}
                />

                <TextField
                  id="outlined-basic"
                  label="Email Address"
                  variant="outlined"
                  value={companyEmail}
                  fullwidth
                  onChange={handleCompanyEmailAddressChange}
                />
                {!ifSettingMinimal && (
                  <div className="color_pick_section">
                    <div>
                      <h6>External booking</h6>
                      <p>
                        Please select a color for the theme of your external
                        booking.
                      </p>
                      {companyThemeColor && (
                        <div className="selected-color-p">
                          Selected color is:{" "}
                          <span
                            className="selected-color"
                            style={{ background: `${companyThemeColor}` }}
                          >
                            {companyThemeColor}
                          </span>
                        </div>
                      )}
                    </div>
                    <div>
                      <CirclePicker
                        color={companyThemeColor}
                        onChange={(updatedColor) =>
                          setCompanyThemeColor(updatedColor.hex)
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="footer_btn">
                <CustomButton
                  label="Save Changes"
                  action={addDetailsResponse}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
