import React from "react";
import { HiOutlineMail } from "react-icons/hi";
import { FaRegEnvelope, FaRegCommentDots } from "react-icons/fa6";
import useTitanApp from "../../../hooks/useTitanApp";

export default function NMSEmailTemplate({ element }) {
  const { user, appState } = useTitanApp();
  const { darkMode } = appState || {};
  return (
    <div className="nms_email_template">
      <div className="email_content">
        <p>{element?.value}</p>
      </div>
    </div>
  );
}
