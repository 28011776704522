import React from "react";
import { MdAdd, MdOutlineModeEditOutline } from "react-icons/md";
import useTitanType from "../../../../../../../hooks/useTitanType";
import { useNavigate } from "react-router";

export default function TypesList() {
  const { typeState } = useTitanType();
  const { servicesTypeData } = typeState || {};
  const navigate = useNavigate();

  const handleAddNewServiceType = () => {
    navigate("/settings/services/types/new");
  };

  const handleEditServiceType = (typeObj) => {
    navigate(`/settings/services/types/${typeObj?._id}`);
  };

  return (
    <div>
      <div>
        <div className="allView_list_header">
          <h3></h3>
          <div className="addNew_cta" onClick={handleAddNewServiceType}>
            <span>Add New Type</span>
            <MdAdd />
          </div>
        </div>
      </div>
      <div className="allView_listview">
        {servicesTypeData?.map((service, index) => (
          <div className="allView_container" key={index}>
            <div className="allView_title">
              <div
                className="service-color"
                style={{ backgroundColor: `${service.serviceColor}` }}
              ></div>
              <p className="allView_name">{service.serviceName}</p>
            </div>
            <MdOutlineModeEditOutline
              size={20}
              className="icons-color member_edit_icon"
              onClick={() => handleEditServiceType(service)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
