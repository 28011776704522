import { Item } from "@radix-ui/react-select";
import listItems from "./listItems";
class UserControls {
  constructor(response, updatedresponse, IsUpdated, isCompleted) {
    IsUpdated === true
      ? (this.obj = updatedresponse?.map((updatedItem) => {
          return {
            userControlId: updatedItem.userControlId,
            name: updatedItem.name,
            type: updatedItem.type,
            label: updatedItem.label,
            inline: updatedItem.inline,
            required: updatedItem.required,
            value:
              isCompleted === true
                ? updatedItem.type === 8
                  ? new Date()
                  : updatedItem.value
                : updatedItem.value,
            listItems: updatedItem.listItems
              ? new listItems(updatedItem.listItems).obj
              : [],
            order: updatedItem.order,
            parentUserControlId: updatedItem.parentUserControlId,
            isChildControl: updatedItem.isChildControl,
          };
        }))
      : (this.obj = response?.map((item) => {
          return {
            userControlId: item.userControlId,
            name: item.name,
            type: item.type,
            label: item.label,
            inline: item.inline,
            required: item.required,
            value:
              isCompleted === true
                ? item.type === 8
                  ? new Date()
                  : item.value
                : item.value,
            listItems: item.listItems ? new listItems(item.listItems).obj : [],
            order: item.order,
            parentUserControlId: item.parentUserControlId,
            isChildControl: item.isChildControl,
          };
        }));
  }
}

export default UserControls;
