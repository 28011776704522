export const timelineEventTypes = Object.freeze({
  1: "Other ",
  2: "Medication",
  3: "Email",
  4: "SMS",
  5: "Eligible",
  6: "Intervention",
  7: "Note",
  8: "Nms declined",
  9: "made",
  10: "Declines",
  11: "Requests",
  12: "Phone call",
  13: "Missed",
  14: "Added ",
  15: "Awaiting consent",
  16: "Followup",
  17: "Answered ",
  18: "Recorded",
  19: "Busy",
  20: "Timeout",
  21: "Service",
  22: "Rescheduled",
  23: "Consent signature",
  24: "Follow up declined",
});

export const timelineEventTypeDescription = Object.freeze({
  1: "",
  2: "handed out by",
  3: "sent by",
  4: "sent by",
  5: "recorded by",
  6: "completed by",
  7: "by",
  8: "by",
  9: "a phone call to patient",
  10: "phone call on",
  11: "phone call on",
  12: "call request from",
  13: "phone call on",
  14: "phone call transcription",
  15: "completed by",
  16: "completed by",
  17: "phone call on",
  18: "phone call on",
  19: "phone call on",
  20: "phone call on",
  21: "attempts by",
  22: "Intervention by",
  23: "by",
  24: "by",
});
