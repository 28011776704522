import React, { useState, useRef } from "react";
import { TfiUpload } from "react-icons/tfi";
import { setAddTeamImage } from "../../../dispatchers/teamDispatchers";
import useTitanTeam from "../../../hooks/useTitanTeam";

export default function AddNewUploadPhoto({ props }) {
  const [open, setOpen] = useState(false);
  let teamImageSelected;
  const [errorMessage, setErrorMessage] = useState("");
  const fileInputRef = useRef(null);
  const { teamState } = useTitanTeam();
  const { addTeamImage } = teamState || "";

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // const handleFileSelect = async (event) => {
  //   teamImageSelected = await toBase64(event.target.files[0]);
  // };

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const image = new Image();

      image.onload = () => {
        if (image.width !== 120 || image.height !== 120) {
          setErrorMessage("Image must be exactly 120x120 pixels.");
          return;
        }

        setAddTeamImage(event.target.result);
      };

      image.src = event?.target?.result;
    };

    reader.readAsDataURL(file);
    teamImageSelected = await toBase64(event.target.files[0]);
    if (props) {
      props.handleCallback(teamImageSelected);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <div className="addNew-uploadBox">
      {addTeamImage ? (
        <div>
          <button
            type="button"
            onClick={() => setAddTeamImage(null)}
            className="profile-picture-close-icon"
          >
            <i className="fa fa-times"></i>
          </button>
          <img src={addTeamImage} alt="Selected" className="profile-picture" />
        </div>
      ) : (
        <div>
          <TfiUpload size={15} className="icons-box" />
          <button
            type="button"
            onClick={handleButtonClick}
            className="titan-verse-add-team-upload-profile-picture-button"
          >
            <span>
              {/* <img
                src={profilePhotoIcon}
                alt="profilePhotoIcon"
                className="upload-photo-text"
              /> */}
              Upload profile picture
            </span>
            <br />
            <p className="upload-file-btn-description">
              120px by 120px (Max 3mb)
            </p>
            <input
              type="file"
              // accept='image/*'
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileSelect}
            />
          </button>
          {errorMessage && (
            <div style={{ color: "red", fontSize: "12px", marginLeft: "15px" }}>
              {errorMessage}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
