import React from "react";
import PrescriptionHeader from "../../components/PrescriptionHeader";
import PatientProfile from "../../components/PatientProfile";
import PrescribedMedicinesTheraphy from "../../components/PrescribedMedicines/PrescribedMedicines";
import ClinicalWarning from "../../components/PrescriptionClinicalWarning";
import "../../assets/clinicalcheck.css";
import ClinicalActions from "../../components/ClinicalActions";
import ImageSlider from "../../components/ImageSlider";
import NopatientPopup from "../../components/NopatientPopup";
import SeverityModal from "../../components/SeverityModal/SeverityModal";
import PatientModal from "../../components/PatientModal/PatientModal";
import DrugMatchModal from "../../components/DrugMatchModal/DrugMatchModal";
import CheckfoundModal from "../../components/CheckfoundModal/CheckfoundModal";
import { useParams, useLocation } from "react-router-dom";
import BreadCrumbNMS from "../../components/BreadCrumbs/BreadCrumb";
import useTitanApp from "../../hooks/useTitanApp";
import AsyncRendererRouter from "../../components/AsyncRendererRouter/AsyncRendererRouter";

import {
  setItemChecks,
  setSeverityCount,
  setMedicationId,
  setPatientImage,
  setNotes,
  setCurrentClinicalChecksNotes,
} from "../../dispatchers/clinicalChecksDispatchers";
import LoadingComponentNMS from "../../components/LoadingComponentNMS/LoadingComponentNMS";
import LoadingComponentCC from "../../components/LoadingComponentCC/LoadingComponentCC";
export default function ClinicalChecks() {
  const location = useLocation();
  const serviceType = location.state && location?.state?.serviceType;
  const { appState, clincicalChecksState, getClinicalChecksLoader } =
    useTitanApp();
  const { isAppLoading, darkMode } = appState || {};
  const {
    isPatient,
    patientName,
    prescriptions,
    clinicalChecksPharmacy,
    patientMedication,
    prescriptionChecks,
  } = clincicalChecksState || {};
  const params = useParams();
  const { pharmacyId } = params || {
    pharmacyId: "00000000-0000-0000-0000-000000000000",
  };
  function resetControl() {
    setSeverityCount(0);
    setItemChecks([]);
    setPatientImage([]);
    setNotes([]);
    setCurrentClinicalChecksNotes([]);
  }

  const next = async () => {
    try {
      resetControl();
      getClinicalChecksLoader({ params });
    } catch {}
  };

  return (
    <div>
      {isAppLoading == false ? (
        isPatient === true ? (
          <div>
            <div>
              <div className="page-content clinicalchecks">
                <BreadCrumbNMS serviceType={serviceType} type="Checks" />
                <div className="main-div">
                  <h2 className="main-div-heading">Data Entry</h2>
                  <div className="main-div-inner">
                    <DrugMatchModal submit={next} />
                    <PatientModal submit={next} />
                    <CheckfoundModal submit={next} />
                    <SeverityModal />
                    <div className="image-div">
                      <h2 className="img-heading">
                        <svg
                          width="14"
                          height="16"
                          viewBox="0 0 14 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7 0C4.79086 0 3 1.79086 3 4C3 6.20914 4.79086 8 7 8C9.20914 8 11 6.20914 11 4C11 1.79086 9.20914 0 7 0ZM4 4C4 2.34315 5.34315 1 7 1C8.65685 1 10 2.34315 10 4C10 5.65685 8.65685 7 7 7C5.34315 7 4 5.65685 4 4Z"
                            fill={darkMode ? "#fff" : "#222"}
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.00873 9C0.903151 9 0 9.88687 0 11C0 12.6912 0.83281 13.9663 2.13499 14.7966C3.41697 15.614 5.14526 16 7 16C8.85474 16 10.583 15.614 11.865 14.7966C13.1672 13.9663 14 12.6912 14 11C14 9.89557 13.1045 9.00001 12 9.00001L2.00873 9ZM1 11C1 10.4467 1.44786 10 2.00873 10L12 10C12.5522 10 13 10.4478 13 11C13 12.3088 12.3777 13.2837 11.3274 13.9534C10.2568 14.636 8.73511 15 7 15C5.26489 15 3.74318 14.636 2.67262 13.9534C1.62226 13.2837 1 12.3088 1 11Z"
                            fill={darkMode ? "#fff" : "#222"}
                          />
                        </svg>
                        {patientName}
                      </h2>
                      <ImageSlider />
                    </div>
                    <div className="vertical-stepper"></div>
                    <div className="content-div">
                      <PrescriptionHeader />
                      {prescriptionChecks?.map((items) => {
                        if (items.checkSection === 1) {
                          return <ClinicalWarning item={items} />;
                        }
                      })}
                      <PatientProfile />
                      {prescriptionChecks?.map((items) => {
                        if (items.checkSection === 2) {
                          return <ClinicalWarning item={items} />;
                        }
                      })}
                      {prescriptions?.prescriptionItems?.map((item, index) => {
                        if (patientMedication.length > 0) {
                          if (
                            patientMedication?.patientMedications?.length !== 0
                          ) {
                            if (
                              item?.prescribedItemId ===
                              patientMedication?.patientMedications[index]
                                ?.prescribedItemId
                            ) {
                              setMedicationId(true);
                            }
                          } else {
                            setMedicationId(false);
                          }
                        } else {
                          setMedicationId(false);
                        }

                        return (
                          <PrescribedMedicinesTheraphy
                            key={index}
                            itemInfo={
                              patientMedication.length > 0
                                ? patientMedication?.patientMedications
                                    ?.length !== 0
                                  ? patientMedication?.patientMedications[index]
                                  : null
                                : null
                            }
                            medicineValues={item}
                            number={index + 3}
                            PrescribedItemId={item?.prescribedItemId}
                            PrescriptionItemId={item?.prescriptionItemId}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="action-footer">
                  <ClinicalActions submit={next} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <NopatientPopup screen="Clinical checks" />
        )
      ) : (
        <LoadingComponentCC />
      )}
    </div>
  );
}
