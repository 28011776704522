import React from "react";
import { ReactComponent as SignDark } from "./../../../assets/images/Sign_Dark.svg";
import { ReactComponent as SignLight } from "./../../../assets/images/Sign_Light.svg";
import useTitanApp from "../../../hooks/useTitanApp";
import {
  isStringBase64,
  convertBase64ToText,
} from "../../../utils/isStringBase64";
export default function NMSSignatureTemplate({ element, date }) {
  const { appState } = useTitanApp();
  const { darkMode } = appState || {};

  return (
    <div className="nms_signature_template">
      <div className="header">
        <p>Signature</p>
        {darkMode ? <SignLight /> : <SignDark />}
      </div>
      <div className="sign_content">
        {isStringBase64(element?.value) ? (
          <img src={`data:image/jpeg;base64,${element?.value}`} />
        ) : (
          element?.value
        )}
      </div>
      <div className="sign_footer">
        <p>{element?.title}</p>
        <p>{date}</p>
      </div>
    </div>
  );
}
