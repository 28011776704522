import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../assets/customdropdown.css";
function CustomDropdown({
  selected,
  items,
  label,
  handleChange,
  isEdit,
  darkModeChange,
}) {
  const [selectedvalue, setSelectedvalue] = useState(selected ? selected : 0);
  const handleChangeValue = (event) => {
    setSelectedvalue(event?.target?.value);
    handleChange(event?.target?.value);
  };

  return (
    <div className="custom-dropdown">
      <FormControl variant="filled" style={{ minWidth: 120 }} disabled={isEdit}>
        <InputLabel id="demo-simple-select-filled-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={selectedvalue}
          onChange={handleChangeValue}
          className="custom-select"
          IconComponent={() => <ExpandMoreIcon />}
        >
          {items?.map((item, index) => {
            return (
              <MenuItem key={index} value={item?.value}>
                {item?.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}

export default CustomDropdown;
