import template from "./template";
import metaDataList from "./metaDataList";
import { HiAdjustments } from "react-icons/hi";
class stages {
  constructor(
    response,
    stageNo,
    title,
    userControls,
    isCompleted,
    startDate,
    userFullName,
    groupId,
    userEmail
  ) {
    this.obj = response?.map((value, index) => {
      return {
        stageName: value.stageName,

        template:
          stageNo === index
            ? new template(
                value.template,
                title,
                userControls,
                isCompleted,
                groupId
              ).getObject()
            : value.template
            ? new template(
                value.template,
                null,
                null,
                isCompleted,
                groupId
              ).getObject()
            : null,
        order: value.order,
        stageType: value.stageType,
        metaDataList: response.metaDataList
          ? new metaDataList(response.metaDataList).obj
          : [],
        clinicalServiceStageStatus:
          isCompleted === true
            ? stageNo === index
              ? 2
              : value.clinicalServiceStageStatus === 2
              ? value.clinicalServiceStageStatus
              : value.clinicalServiceStageStatus === null
              ? 0
              : value.clinicalServiceStageStatus
            : value.clinicalServiceStageStatus === null
            ? 0
            : value.clinicalServiceStageStatus,
        completedBy:
          isCompleted === true
            ? stageNo === index
              ? userFullName
              : value.completedBy
            : value.completedBy,
        completedAt:
          isCompleted === true
            ? stageNo === index
              ? new Date()
              : value.completedAt
            : value.completedAt,
        startDate:
          isCompleted === true
            ? startDate !== ""
              ? stageNo === 0
                ? stageNo + 2 === index
                  ? startDate
                  : value.startDate
                : stageNo + 1 === index
                ? startDate
                : value.startDate
              : value.startDate
            : value.startDate,
        totalAttempts: value.totalAttempts,
        completedByEmail:
          isCompleted === true
            ? stageNo === index
              ? userEmail
              : value.completedByEmail
            : value.completedByEmail,
      };
    });
  }
}

export default stages;
