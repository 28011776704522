const currentDate = new Date();
export const filterData = (dueDateType, activeServices) => {
  return activeServices.filter((item) => {
    const dueDate = new Date(item?.serviceDueDateTime);
    if (dueDateType === "overdue") {
      return dueDate < currentDate;
    } else if (dueDateType === "today") {
      return dueDate.toDateString() === currentDate.toDateString();
    } else if (dueDateType === "dueLater") {
      return dueDate > currentDate;
    }
    return false;
  });
};
