import { dispatch, getDataFromStore } from "../store/store";
import { logError } from "../services/logService";
import {
  getGeneralsData,
  updateGeneralData,
  setGeneralData,
  setIsGeneralLoading,
  setCompanyEmailData,
  setCompanyContactData,
  setCompanyThemeColorData,
} from "../features/generalsSlice/generalsSlice";

const reducerName = "general";

// #region State Setters

export const setCurrentGeneralData = (generalData) => {
  dispatch(setGeneralData(generalData));
};

export const setGeneralLoading = (isLoading) => {
  dispatch(setIsGeneralLoading(isLoading));
};

export const setCompanyEmail = (email) => {
  dispatch(setCompanyEmailData(email));
};

export const setCompanyContact = (isLoading) => {
  dispatch(setCompanyContactData(isLoading));
};

export const setCompanyThemeColor = (color) => {
  dispatch(setCompanyThemeColorData(color));
};
// #endregion

// #region API getter/caller functions for components usage to dispatch REDUX extra reducers
export const getAllGenerals = async () => {
  let result = [];
  try {
    result = await dispatch(getGeneralsData()).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const updateGeneral = async (data) => {
  let result = null;

  try {
    result = await dispatch(updateGeneralData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};
// #endregion
