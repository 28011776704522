import { getObject } from "../getObjectValue";
import stages from "./stages";
import templateSections from "./templateSections";
class template {
  constructor(response, title, userControls, isCompleted, groupId) {
    this._id = response._id ? response._id : "";
    this.templateName = response.templateName ? response.templateName : "";
    this.templateSections = response.templateSections
      ? new templateSections(
          response.templateSections,
          title,
          userControls,
          isCompleted,
          groupId
        ).obj
      : [];
  }

  getObject() {
    return {
      _id: this._id,
      templateName: this.templateName,
      templateSections: this.templateSections,
    };
  }
}

export default template;
