import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

function RegisterationDetails({
  professionalDetails,
  handleChangeDetails,
  handleSwitchChange,
}) {
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  return (
    <div className="AddNew_sectionbox">
      <div className="prof_details">
        <p className="orbit_card-title">Registration Details</p>
        <div className="gp_ps_no">
          <TextField
            id="outlined-basic"
            label="GPHC (England/Scotland/Wales)"
            variant="outlined"
            fullwidth
            name="gphc"
            value={professionalDetails?.gphc || ""}
            onChange={handleChangeDetails}
          />
          <TextField
            id="outlined-basic"
            label="PSNI Number (Northern Ireland)"
            variant="outlined"
            fullwidth
            name="psniNumber"
            value={professionalDetails?.psniNumber || ""}
            onChange={handleChangeDetails}
          />
          <TextField
            id="outlined-basic"
            label="PSI Number (Republic of Ireland)"
            variant="outlined"
            fullwidth
            name="psiNumber"
            value={professionalDetails?.psiNumber || ""}
            onChange={handleChangeDetails}
          />
          <div className="user_clinician">
            <p>I'm Pre-Registration</p>
            <AntSwitch
              name="isPreRegistered"
              checked={professionalDetails?.isPreRegistered}
              onChange={handleSwitchChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterationDetails;
