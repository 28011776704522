import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { httpGet, httpPut } from "../../services/httpService";
import {
  getXeroContact,
  upsertXeroContactUrl,
  getXeroInvoiceUrl,
} from "../../apiUrls/apiUrls";
import { logError } from "../../services/logService";

const formatDate = (paramDate) => {
  let resultDate;

  let momentdate = moment(paramDate);
  let isValidDate = momentdate?.isValid();
  if (isValidDate === true) {
    resultDate = momentdate?.format("DD/MM/YYYY");
  } else {
    resultDate = "Invalid date";
  }

  return resultDate;
};

const formatStatus = (paramStatus) => {
  let resultStatus;

  switch (paramStatus) {
    case 2:
      resultStatus = "Submitted";
      break;
    case 3:
      resultStatus = "Deleted";
      break;
    case 4:
      resultStatus = "Authorised";
      break;
    case 5:
      resultStatus = "Paid";
      break;
    case 6:
      resultStatus = "Voided";
      break;
    case 1:
    default:
      resultStatus = "Draft";
      break;
  }

  return resultStatus;
};

function formatInvoiceData(invoiceData) {
  if (invoiceData && Array.isArray(invoiceData) === true) {
    invoiceData.forEach((eachInvoice) => {
      eachInvoice.dateFormatted = formatDate(eachInvoice.date);
      eachInvoice.statusFormatted = formatStatus(eachInvoice.status);
    });
  }
}

const initialState = {
  isBillingLoading: false,
  billingData: {},
  companyName: "",
  companyNumber: "",
  companyAddress: "",
  bankName: "",
  accountNumber: "",
  sortCode: "",
  invoiceData: [],
};

export const getBillingData = createAsyncThunk(
  "billing/getBillingData",
  async () => {
    const urlToGet = getXeroContact();
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const upsertBillingData = createAsyncThunk(
  "billing/upsertBillingData",
  async (data, thunkAPI) => {
    const respData = await httpPut(upsertXeroContactUrl(), data);
    return respData;
  }
);

export const getInvoiceUrlData = createAsyncThunk(
  "billing/getInvoiceUrlData",
  async (invoiceId) => {
    const respData = await httpGet(getXeroInvoiceUrl(invoiceId));
    return respData;
  }
);

export const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    setIsBillingLoading: (state, action) => {
      state.isBillingLoading = action?.payload || false;
    },
    setBillingData: (state, action) => {
      state.billingData = action?.payload;
    },
    setCompanyNameData: (state, action) => {
      state.companyName = action?.payload || "";
    },
    setCompanyNumberData: (state, action) => {
      state.companyNumber = action?.payload || "";
    },
    setCompanyAddressData: (state, action) => {
      state.companyAddress = action?.payload || "";
    },
    setBankNameData: (state, action) => {
      state.bankName = action?.payload || "";
    },
    setAccountNumberData: (state, action) => {
      state.accountNumber = action?.payload || "";
    },
    setSortCodeData: (state, action) => {
      state.sortCode = action?.payload || "";
    },
    resetBillingState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBillingData.pending, (state) => {
        state.isBillingLoading = true;
      })
      .addCase(getBillingData.fulfilled, (state, action) => {
        state.isBillingLoading = false;
        state.billingData = action?.payload || {};
        if (state?.billingData) {
          const {
            name,
            companyNumber,
            addresses,
            bankAccountDetails,
            accountNumber,
          } = state?.billingData?.contact || {};
          state.companyName = name || "";
          state.companyNumber = companyNumber || "";
          state.companyAddress = "";

          if (addresses && addresses.length > 0) {
            const foundAddress = addresses?.find(
              (address) => address.addressType === 1
            );
            state.companyAddress = `${foundAddress?.addressLine1 || ""}`;
          }

          state.accountNumber = bankAccountDetails || "";
          state.sortCode = accountNumber || "";
          state.bankName = "";
        }
        state.invoiceData = state?.billingData?.invoices || [];
        formatInvoiceData(state?.billingData?.invoices);
      })
      .addCase(getBillingData.rejected, (state, action) => {
        logError(action?.error);
        state.isBillingLoading = false;
        state.billingData = {};
        state.invoiceData = [];
      })
      .addCase(upsertBillingData.pending, (state) => {
        state.isBillingLoading = true;
      })
      .addCase(upsertBillingData.fulfilled, (state, action) => {
        state.isBillingLoading = false;
        state.billingData = action?.payload || {};
        if (state?.billingData) {
          const {
            name,
            companyNumber,
            addresses,
            bankAccountDetails,
            accountNumber,
          } = state?.billingData?.contact || {};
          state.companyName = name || "";
          state.companyNumber = companyNumber || "";
          state.companyAddress = "";

          if (addresses && addresses.length > 0) {
            const foundAddress = addresses?.find(
              (address) => address.addressType === 1
            );
            state.companyAddress = `${foundAddress?.addressLine1 || ""}`;
          }

          state.accountNumber = bankAccountDetails || "";
          state.sortCode = accountNumber || "";
          state.bankName = "";
        }

        state.invoiceData = state?.billingData?.invoices || [];
        formatInvoiceData(state?.billingData?.invoices);
      })
      .addCase(upsertBillingData.rejected, (state, action) => {
        logError(action?.error);
        state.isBillingLoading = false;
      })
      .addCase(getInvoiceUrlData.pending, (state) => {
        state.isBillingLoading = true;
      })
      .addCase(getInvoiceUrlData.fulfilled, (state) => {
        state.isBillingLoading = false;
      })
      .addCase(getInvoiceUrlData.rejected, (state, action) => {
        logError(action?.error);
        state.isBillingLoading = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsBillingLoading,
  setBillingData,
  resetBillingState,
  setCompanyNameData,
  setCompanyNumberData,
  setCompanyAddressData,
  setBankNameData,
  setAccountNumberData,
  setSortCodeData,
} = billingSlice.actions;

export default billingSlice.reducer;
