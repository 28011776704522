import React from "react";
import ReactPlayer from "react-player";

export default function LiveFeed({ videoFile, onDarkModeChange }) {
  return (
    <div className="live-feed">
      <h3 className="live-feed-text">Live Feed</h3>
      <div className="wrapper">
        <ReactPlayer
          className="video-player"
          url={videoFile}
          playing={true}
          controls={true}
          loop={true}
          width="100%"
          height="100%"
          muted={true}
        />
      </div>
    </div>
  );
}
