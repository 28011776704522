import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpGet, httpPost, httpPut } from "../../services/httpService";
import {
  addOrbitSessionUrl,
  updateOrbitSessionUrl,
  getOrbitProfileUrl,
  saveOrbitProfileUrl,
  addOrbitAuditUrl,
  getOrbitAuditBySessionIdUrl,
  getOrbitDashboardUrl,
  getOrbitMonthlyReportUrl,
  getOrbitEarningsUrl,
  getOrbitUserSessionsUrl,
  getOrbitFollowUpServicesUrl,
  getOrbitPotentialEarningsUrl,
  getOrbitOtherDetailsUrl,
  getOrbitParkedServicesUrl,
  changeOrbitServiceStatusUrl,
  getOrbitActiveServicesUrl,
  addTestModeServiceUrl,
  getPaymentDetailsUrl,
} from "../../apiUrls/apiUrls";
import { logError } from "../../services/logService";

const initialState = {
  isOrbitLoading: false,
  currentSessionId: null,
  isStart: false,
  orbitAnimation: false,
  isAnimationPlaying: false,
  openSuccessDialog: false,
  totalCount: 0,
  totalPrice: 0,
  orbitSessionTimer: 0,
  timer: 0,
  isActive: false,
  orbitSessionMinutes: "0",
  orbitSessionSeconds: "0",
  isStopTimer: false,
  startTimeObj: null,
  orbitAudit: null,
  lastOrbitSessionAddedUpdated: null,
  orbitProfile: [],
  profDetails: [],
  payDetails: [],
  avbDetails: [],
  accredationsDetails: [],
  compDocDetails: [],
  orbitAudit: [],
  orbitDashboardData: [],
  monthlyReport: null,
  orbitEarnings: [],
  orbitUserSessions: [],
  potentialEarnings: null,
  otherDetails: null,
  parkedServices: [],
  activeServices: [],
  followupServices: [],
  isMonthlyReportLoading: false,
  isOrbitEarningLoading: false,
  isUserSessionLoading: false,
  isPotentialEarningLoading: false,
  isFollowupLoading: false,
  isOtherDetailsLoading: false,
  isParkedServicesLoading: false,
  isActiveServicesLoading: false,
  showTestModeDialog: false,
  isTestMode: false,
  bankPaymentDetails:false,
};
export const addOrbitSessionData = createAsyncThunk(
  "orbit/addOrbitSessionData",
  async (data, thunkAPI) => {
    const respData = await httpPost(addOrbitSessionUrl(), data);
    return respData;
  }
);

export const updateOrbitSessionData = createAsyncThunk(
  "orbit/updateOrbitSessionData",
  async (data, thunkAPI) => {
    const respData = await httpPost(updateOrbitSessionUrl(), data);
    return respData;
  }
);
export const getOrbitProfileData = createAsyncThunk(
  "orbit/getOrbitProfileData",
  async () => {
    const respData = await httpGet(getOrbitProfileUrl());
    return respData;
  }
);
export const saveOrbitProfileData = createAsyncThunk(
  "orbit/saveOrbitProfileData",
  async (data, thunkAPI) => {
    const respData = await httpPost(saveOrbitProfileUrl(), data);
    return respData;
  }
);

export const addOrbitAuditData = createAsyncThunk(
  "orbit/addOrbitAuditData",
  async (body, thunkAPI) => {
    const { data, actionType, actionDate } = body;
    const respData = await httpPost(
      addOrbitAuditUrl(actionType, actionDate),
      data
    );
    return respData;
  }
);

export const getOrbitAuditBySessionIdData = createAsyncThunk(
  "orbit/getOrbitAuditBySessionIdData",
  async (param) => {
    const { searchType, sessionId } = param || {};
    const respData = await httpGet(
      getOrbitAuditBySessionIdUrl(searchType, sessionId)
    );
    return respData;
  }
);

export const getOrbitDashboardData = createAsyncThunk(
  "orbit/getOrbitDashboardData",
  async () => {
    const respData = await httpGet(getOrbitDashboardUrl());
    return respData;
  }
);

export const getOrbitMonthlyReportData = createAsyncThunk(
  "orbit/getOrbitMonthlyReportData",
  async (param) => {
    const { startDate, endDate } = param || {};
    const respData = await httpGet(
      getOrbitMonthlyReportUrl(startDate, endDate)
    );
    return respData;
  }
);

export const getOrbitEarningsData = createAsyncThunk(
  "orbit/getOrbitEarningsData",
  async (param) => {
    const { startDate, endDate } = param || {};
    const respData = await httpGet(getOrbitEarningsUrl(startDate, endDate));
    return respData;
  }
);

export const getOrbitUserSessionsData = createAsyncThunk(
  "orbit/getOrbitUserSessionsData",
  async () => {
    const respData = await httpGet(getOrbitUserSessionsUrl());
    return respData;
  }
);

export const getOrbitParkedServicesData = createAsyncThunk(
  "orbit/getOrbitParkedServicesData",
  async (params) => {
    const respData = await httpGet(getOrbitParkedServicesUrl(params));
    return respData;
  }
);
export const getOrbitActiveServicesData = createAsyncThunk(
  "orbit/getOrbitActiveServicesData",
  async (params) => {
    const { currentSessionId, isTestMode } = params || {};
    const respData = await httpGet(
      getOrbitActiveServicesUrl(currentSessionId, isTestMode)
    );
    return respData;
  }
);
export const changeOrbitServiceStatusData = createAsyncThunk(
  "orbit/changeOrbitServiceStatusData",
  async (params) => {
    const respData = await httpGet(changeOrbitServiceStatusUrl(params));
    return respData;
  }
);

export const getOrbitFollowUpServicesData = createAsyncThunk(
  "orbit/getOrbitFollowUpServicesData",
  async () => {
    const respData = await httpGet(getOrbitFollowUpServicesUrl());
    return respData;
  }
);

export const getOrbitPotentialEarningsData = createAsyncThunk(
  "orbit/getOrbitPotentialEarningsData",
  async () => {
    const respData = await httpGet(getOrbitPotentialEarningsUrl());
    return respData;
  }
);

export const getOrbitOtherDetailsData = createAsyncThunk(
  "orbit/getOrbitOtherDetailsData",
  async () => {
    const respData = await httpGet(getOrbitOtherDetailsUrl());
    return respData;
  }
);

export const addTestModeServiceData = createAsyncThunk(
  "kpi/addTestModeServiceData",
  async (params) => {
    const { telephoneNumber, numberOfScripts } = params || {};
    const respData = await httpPost(
      addTestModeServiceUrl(telephoneNumber, numberOfScripts)
    );
    return respData;
  }
);

export const getPaymentDetailsData = createAsyncThunk(
  "orbit/getPaymentDetailsData",
  async (thunkAPI) => {
    const respData = await httpGet(getPaymentDetailsUrl());
    return respData;
  }
);

export const orbitSessionSlice = createSlice({
  name: "orbit",
  initialState,
  reducers: {
    setIsOrbitLoading: (state, action) => {
      state.isOrbitLoading = action?.payload || false;
    },
    setOrbitData: (state, action) => {
      state.startTimeObj = action?.payload || null;
    },
    setOrbitProfileData: (state, action) => {
      state.orbitProfile = action?.payload || null;
    },
    setProfData: (state, action) => {
      state.profDetails = action?.payload || null;
    },
    setPayData: (state, action) => {
      state.payDetails = action?.payload || null;
    },
    setAccredationsData: (state, action) => {
      state.accredationsDetails = action?.payload || null;
    },
    setCompDocData: (state, action) => {
      state.compDocDetails = action?.payload || null;
    },
    setAvbData: (state, action) => {
      state.avbDetails = action?.payload || null;
    },
    setIsStart: (state, action) => {
      state.isStart = action?.payload || false;
    },
    setIsStopTimer: (state, action) => {
      state.isStopTimer = action?.payload || false;
    },
    setIsOrbitSessionMinutes: (state, action) => {
      state.orbitSessionMinutes = action?.payload || "0";
    },
    setIsOrbitSessionSeconds: (state, action) => {
      state.orbitSessionSeconds = action?.payload || "0";
    },
    setTimer: (state, action) => {
      state.timer = action.payload || 0;
    },
    setOrbitAnimation: (state, action) => {
      state.orbitAnimation = action?.payload || false;
    },
    setIsAnimationPlaying: (state, action) => {
      state.isAnimationPlaying = action?.payload || false;
    },
    setOpenSuccessDialog: (state, action) => {
      state.openSuccessDialog = action?.payload || false;
    },
    setTotalCount: (state, action) => {
      state.totalCount = action?.payload || 0;
    },
    setTotalPrice: (state, action) => {
      state.totalPrice = action?.payload || 0;
    },
    setIsActive: (state, action) => {
      state.isActive = action?.payload || false;
    },
    setIsMonthlyReportLoadingData: (state, action) => {
      state.isMonthlyReportLoading = action?.payload || false;
    },
    setIsOrbitEarningLoadingData: (state, action) => {
      state.isOrbitEarningLoading = action?.payload || false;
    },
    setIsUserSessionLoadingData: (state, action) => {
      state.isUserSessionLoading = action?.payload || false;
    },
    setIsPotentialEarningLoadingData: (state, action) => {
      state.isPotentialEarningLoading = action?.payload || false;
    },
    setIsFollowupLoadingData: (state, action) => {
      state.isFollowupLoading = action?.payload || false;
    },
    setIsOtherDetailsLoadingData: (state, action) => {
      state.isOtherDetailsLoading = action?.payload || false;
    },
    setIsActiveServicesLoadingData: (state, action) => {
      state.isActiveServicesLoading = action?.payload || false;
    },
    setshowTestModeDialogData: (state, action) => {
      state.showTestModeDialog = action?.payload || false;
    },
    setisTestModeData: (state, action) => {
      state.isTestMode = action?.payload;
    },
    resetOrbitState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrbitSessionData.pending, (state) => {
        state.isOrbitLoading = true;
        state.startTimeObj = null;
      })
      .addCase(addOrbitSessionData.fulfilled, (state, action) => {
        state.isOrbitLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.currentSessionId = appDataFromResponse?._id;
            localStorage.setItem("currentSessionId", appDataFromResponse?._id);
            state.startTimeObj = appDataFromResponse || null;
          } catch (error) { }
        }
      })
      .addCase(addOrbitSessionData.rejected, (state, action) => {
        state.isOrbitLoading = false;
        state.startTimeObj = null;
      })
      .addCase(updateOrbitSessionData.pending, (state) => {
        state.isOrbitLoading = true;
        state.startTimeObj = null;
      })
      .addCase(updateOrbitSessionData.fulfilled, (state, action) => {
        state.isOrbitLoading = false;
        state.startTimeObj = action?.payload;
      })
      .addCase(updateOrbitSessionData.rejected, (state, action) => {
        logError(action?.error);
        state.isOrbitLoading = false;
        state.startTimeObj = null;
      })
      .addCase(getOrbitProfileData.pending, (state) => {
        state.isOrbitLoading = true;
        state.orbitProfile = [];
      })
      .addCase(getOrbitProfileData.fulfilled, (state, action) => {
        state.isOrbitLoading = false;
        state.orbitProfile = action?.payload;
        const {
          professionalDetails,
          paymentDetails,
          availability,
          complianceDocuments,
          accreditations,
        } = action?.payload || {};
        state.profDetails =
          professionalDetails != null
            ? professionalDetails
            : {
              gphc: "",
              psniNumber: "",
              psiNumber: "",
              isPreRegistered: false,
              isDBSUpdate: false,
              educationalOrganization: "",
              yearOfGraduation: "",
              nationality: "",
              isCriminalRecord: false,
              isInvestigation: false,
              yearOfExperience: "",
            };

        state.payDetails =
          paymentDetails != null
            ? paymentDetails
            : {
              accountNumber: "",
              sortCode: "",
              accountHolderName: "",
              isLimitedCompany: false,
              isVatRegistered: false,
            };

        state.accredationsDetails =
          accreditations != null
            ? accreditations
            : {
              documentType: "",
              documentDetails: {
                document: "",
                issueDate: null,
                expiryDate: null,
                requiredBy: "",
                notes: "",
                isUploaded: false,
                fileType: "",
              },
            };
        state.compDocDetails =
          complianceDocuments != null
            ? complianceDocuments
            : {
              enhancedDB: {
                document: "",
                issueDate: null,
                expiryDate: null,
                requiredBy: "",
                notes: "",
                isUploaded: false,
                fileType: "",
              },
              indemnityEnsurance: {
                document: "",
                issueDate: null,
                expiryDate: null,
                requiredBy: "",
                notes: "",
                isUploaded: false,
                fileType: "",
              },
              photographicId: {
                document: "",
                issueDate: null,
                expiryDate: null,
                requiredBy: "",
                notes: "",
                isUploaded: false,
                fileType: "",
              },
            };
        state.avbDetails = availability != null ? availability : [];
      })
      .addCase(getOrbitProfileData.rejected, (state, action) => {
        logError(action?.error);
        state.isOrbitLoading = false;
        state.orbitProfile = [];
      })
      .addCase(saveOrbitProfileData.pending, (state) => {
        state.isOrbitLoading = true;
      })
      .addCase(saveOrbitProfileData.fulfilled, (state, action) => {
        state.isOrbitLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.orbitProfile = appDataFromResponse || null;
          } catch (error) { }
        }
      })
      .addCase(saveOrbitProfileData.rejected, (state, action) => {
        state.isOrbitLoading = false;
        state.orbitProfile = [];
      })
      .addCase(getOrbitAuditBySessionIdData.pending, (state) => {
        state.isOrbitLoading = true;
        state.orbitAudit = [];
      })
      .addCase(getOrbitAuditBySessionIdData.fulfilled, (state, action) => {
        state.isOrbitLoading = false;
        state.orbitAudit = action?.payload;
      })
      .addCase(getOrbitAuditBySessionIdData.rejected, (state, action) => {
        state.isOrbitLoading = false;
        state.orbitAudit = [];
      })
      .addCase(getOrbitDashboardData.pending, (state) => {
        state.isOrbitLoading = true;
        state.orbitDashboardData = [];
      })
      .addCase(getOrbitDashboardData.fulfilled, (state, action) => {
        state.isOrbitLoading = false;
        state.orbitDashboardData = action?.payload;
      })
      .addCase(getOrbitDashboardData.rejected, (state, action) => {
        state.isOrbitLoading = false;
        state.orbitDashboardData = [];
      })
      .addCase(getOrbitMonthlyReportData.pending, (state) => {
        state.isMonthlyReportLoading = true;
        state.monthlyReport = null;
      })
      .addCase(getOrbitMonthlyReportData.fulfilled, (state, action) => {
        state.isMonthlyReportLoading = false;
        state.monthlyReport = action?.payload;
      })
      .addCase(getOrbitMonthlyReportData.rejected, (state, action) => {
        state.isMonthlyReportLoading = false;
        state.monthlyReport = null;
      })
      .addCase(getOrbitEarningsData.pending, (state) => {
        state.isOrbitEarningLoading = true;
        state.orbitEarnings = [];
      })
      .addCase(getOrbitEarningsData.fulfilled, (state, action) => {
        state.isOrbitEarningLoading = false;
        state.orbitEarnings = action?.payload;
      })
      .addCase(getOrbitEarningsData.rejected, (state, action) => {
        state.isOrbitEarningLoading = false;
        state.orbitEarnings = [];
      })
      .addCase(getOrbitUserSessionsData.pending, (state) => {
        state.isUserSessionLoading = true;
        state.orbitUserSessions = [];
      })
      .addCase(getOrbitUserSessionsData.fulfilled, (state, action) => {
        state.isUserSessionLoading = false;
        state.orbitUserSessions = action?.payload;
      })
      .addCase(getOrbitUserSessionsData.rejected, (state, action) => {
        state.isUserSessionLoading = false;
        state.orbitUserSessions = [];
      })
      .addCase(getOrbitParkedServicesData.pending, (state) => {
        state.isParkedServicesLoading = true;
        state.parkedServices = [];
      })
      .addCase(getOrbitParkedServicesData.fulfilled, (state, action) => {
        state.isParkedServicesLoading = false;
        state.parkedServices = action?.payload;
      })
      .addCase(getOrbitParkedServicesData.rejected, (state, action) => {
        state.isParkedServicesLoading = false;
        state.parkedServices = [];
      })
      .addCase(getOrbitActiveServicesData.pending, (state) => {
        state.isActiveServicesLoading = true;
        state.activeServices = [];
      })
      .addCase(getOrbitActiveServicesData.fulfilled, (state, action) => {
        state.isActiveServicesLoading = false;
        state.activeServices = action?.payload;
      })
      .addCase(getOrbitActiveServicesData.rejected, (state, action) => {
        state.isActiveServicesLoading = false;
        state.activeServices = [];
      })
      .addCase(changeOrbitServiceStatusData.pending, (state) => {
        state.isParkedServicesLoading = true;
      })
      .addCase(changeOrbitServiceStatusData.fulfilled, (state, action) => {
        state.isParkedServicesLoading = false;
      })
      .addCase(changeOrbitServiceStatusData.rejected, (state, action) => {
        state.isParkedServicesLoading = false;
      })
      .addCase(getOrbitFollowUpServicesData.pending, (state) => {
        state.isFollowupLoading = true;
        state.followupServices = [];
      })
      .addCase(getOrbitFollowUpServicesData.fulfilled, (state, action) => {
        state.isFollowupLoading = false;
        state.followupServices = action?.payload;
      })
      .addCase(getOrbitFollowUpServicesData.rejected, (state, action) => {
        state.isFollowupLoading = false;
        state.followupServices = [];
      })
      .addCase(getOrbitPotentialEarningsData.pending, (state) => {
        state.isPotentialEarningLoading = true;
        state.potentialEarnings = null;
      })
      .addCase(getOrbitPotentialEarningsData.fulfilled, (state, action) => {
        state.isPotentialEarningLoading = false;
        state.potentialEarnings = action?.payload;
      })
      .addCase(getOrbitPotentialEarningsData.rejected, (state, action) => {
        state.isPotentialEarningLoading = false;
        state.potentialEarnings = null;
      })
      .addCase(getOrbitOtherDetailsData.pending, (state) => {
        state.isOtherDetailsLoading = true;
        state.otherDetails = null;
      })
      .addCase(getOrbitOtherDetailsData.fulfilled, (state, action) => {
        state.isOtherDetailsLoading = false;
        state.otherDetails = action?.payload;
      })
      .addCase(getOrbitOtherDetailsData.rejected, (state, action) => {
        state.isOtherDetailsLoading = false;
        state.otherDetails = null;
      })
      .addCase(addTestModeServiceData.pending, (state) => {
        state.isActiveServicesLoading = true;
      })
      .addCase(addTestModeServiceData.fulfilled, (state, action) => {
        state.isActiveServicesLoading = false;
      })
      .addCase(addTestModeServiceData.rejected, (state, action) => {
        state.isActiveServicesLoading = false;
      })
      .addCase(getPaymentDetailsData.pending, (state) => {
        state.isActiveServicesLoading = true;
      })
      .addCase(getPaymentDetailsData.fulfilled, (state, action) => {
        state.isActiveServicesLoading = false;
        state.bankPaymentDetails = action?.payload;
      })
      .addCase(getPaymentDetailsData.rejected, (state, action) => {
        state.isActiveServicesLoading = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsMonthlyReportLoadingData,
  setIsOrbitEarningLoadingData,
  setIsUserSessionLoadingData,
  setIsPotentialEarningLoadingData,
  setIsFollowupLoadingData,
  setIsOtherDetailsLoadingData,
  setIsActiveServicesLoadingData,
  setIsOrbitLoading,
  setOrbitData,
  resetOrbitState,
  setProfData,
  setPayData,
  setAccredationsData,
  setCompDocData,
  setAvbData,
  setIsStart,
  setIsStopTimer,
  setOrbitProfileData,
  setIsOrbitSessionMinutes,
  setIsOrbitSessionSeconds,
  setTimer,
  setOrbitAnimation,
  setIsAnimationPlaying,
  setOpenSuccessDialog,
  setTotalCount,
  setTotalPrice,
  setIsActive,
  setshowTestModeDialogData,
  setisTestModeData,
} = orbitSessionSlice.actions;

export default orbitSessionSlice.reducer;
