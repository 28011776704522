import React from "react";
import SettingsSidebar from "../SettingsSidebar/SettingsSidebar";
import { Skeleton } from "@mui/material";
import useTitanApp from "../../hooks/useTitanApp";

export default function MembersLoadingComponent({
  selectedMenuItem,
  handleMenuItemClick,
  showSubMenuNav,
  onDarkModeChange,
  pharmacyID,
}) {
  const { appState } = useTitanApp();
  const { features, darkMode, currentPharmacyId } = appState;
  return (
    <div className="settings_page">
      <div className="settings_wrapper">
        <SettingsSidebar
          selectedMenuItem={selectedMenuItem}
          handleMenuItemClick={handleMenuItemClick}
          showSubMenuNav={showSubMenuNav}
          onDarkModeChange={darkMode}
          features={features}
          pharmacyID={currentPharmacyId}
        />

        <div className="settings_content_wrapper">
          <div className="settings_heading">
            <Skeleton
              sx={{
                bgcolor: darkMode ? "grey.800" : "",
              }}
              height={60}
              width={100}
              animation="wave"
            />
          </div>

          <div className="settings_content_area">
            <div className="add-new-member-skeleton">
              <Skeleton
                sx={{
                  bgcolor: darkMode ? "grey.800" : "",
                }}
                height={60}
                width={250}
                animation="wave"
              />
            </div>
            <div>
              <div className="locations_list_area accordion-group skeleton-loading-boxes">
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={60}
                  animation="wave"
                />
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={60}
                  animation="wave"
                />
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={60}
                  animation="wave"
                />
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={60}
                  animation="wave"
                />
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={60}
                  animation="wave"
                />
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={60}
                  animation="wave"
                />
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={60}
                  animation="wave"
                />
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={60}
                  animation="wave"
                />
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={60}
                  animation="wave"
                />
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={60}
                  animation="wave"
                />
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={60}
                  animation="wave"
                />
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={60}
                  animation="wave"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
