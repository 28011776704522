import React from "react";
import "./../../../../assets/css/Dashboard/ServicesKanban.scss";
import useTitanApp from "../../../../hooks/useTitanApp";
import Popover from "@mui/material/Popover";
import { setCurrentPharmacyServices } from "../../../../dispatchers/pharmacyServiceDispatchers";
import { stageNumbers } from "../../../../enum/stageType";

export default function SortingServices({ stageType }) {
  const { pharmacyServiceState, appState } = useTitanApp();
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const { pharmacyServices } = pharmacyServiceState;

  const isOpen = (popoverIndex) => Boolean(getAnchorEl(popoverIndex));

  const getAnchorEl = (popoverIndex) => {
    switch (popoverIndex) {
      case 1:
        return anchorEl1;
      case 2:
        return anchorEl2;
      case 3:
        return anchorEl3;
      case 4:
        return anchorEl4;
      default:
        return null;
    }
  };
  const generatePopoverId = (index) => `simple-popover-${index}`;

  const handleClickOption = (event, popoverIndex) => {
    const setAnchorEl = getSetAnchorElFunction(popoverIndex);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (popoverIndex) => () => {
    const setAnchorEl = getSetAnchorElFunction(popoverIndex);
    setAnchorEl(null);
  };

  const getSetAnchorElFunction = (popoverIndex) => {
    switch (popoverIndex) {
      case 1:
        return setAnchorEl1;
      case 2:
        return setAnchorEl2;
      case 3:
        return setAnchorEl3;
      case 4:
        return setAnchorEl4;
      default:
        return null;
    }
  };

  const sortStage = (stageNo, action) => {
    const sortClinicalServices = (clinicalServices) => {
      const number = stageNumbers[stageNo];
      return [...clinicalServices].sort((a, b) => {
        const stageA = a.stages?.[number];
        const stageB = b.stages?.[number];

        const dateA = stageA?.startDate
          ? new Date(stageA.startDate)
          : new Date(a.clinicalServiceDate);
        const dateB = stageB?.startDate
          ? new Date(stageB.startDate)
          : new Date(b.clinicalServiceDate);

        return action ? dateA - dateB : dateB - dateA;
      });
    };

    const sortedFilterPharmacyService = pharmacyServices?.map((item) =>
      item?.stageType === stageNo
        ? {
            ...item,
            sortedServices: item?.sortedServices?.map((service) => ({
              ...service,
              clinicalServices: sortClinicalServices(service?.clinicalServices),
            })),
          }
        : {
            ...item,
          }
    );
    setCurrentPharmacyServices(sortedFilterPharmacyService);
  };
  return (
    <div>
      <button
        onClick={(event) => {
          handleClickOption(event, stageType);
        }}
      >
        <p>...</p>
      </button>

      <Popover
        id={generatePopoverId(stageType)}
        open={isOpen(stageType)}
        anchorEl={getAnchorEl(stageType)}
        onClose={handleClose(stageType)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        className="sorting-popover"
      >
        <p
          onClick={() => {
            sortStage(stageType, true);
          }}
        >
          Sort by Date Ascending
        </p>
        <p
          onClick={() => {
            sortStage(stageType, false);
          }}
        >
          {" "}
          Sort by Date Descending
        </p>
      </Popover>
    </div>
  );
}
