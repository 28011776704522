import React from "react";

function WarningCountdown({ warningCountdownStatus }) {
  return (
    <div className="calling-warning-counter">
      <p>{warningCountdownStatus}</p>
    </div>
  );
}

export default WarningCountdown;
