import React from "react";
import SettingsSidebar from "../SettingsSidebar/SettingsSidebar";
import { Skeleton } from "@mui/material";
import useTitanApp from "../../hooks/useTitanApp";

export default function TypesAddNewLoadingComponent() {
  const { appState } = useTitanApp();
  const { features, darkMode, currentPharmacyId } = appState;
  return (
    <div className="settings_page members-loader">
      <div className="settings_wrapper">
        <SettingsSidebar onDarkModeChange={darkMode} />
        <div className="settings_content_wrapper">
          <div className="settings_heading">
            <div>
              <div className="back_to_SettingsListView">
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={60}
                  width={100}
                  animation="wave"
                />
              </div>
              <div className="addMember_formFields">
                <div className="inner-panels">
                  <div className="AddNew_sectionbox">
                    <Skeleton
                      sx={{
                        bgcolor: darkMode ? "grey.800" : "",
                      }}
                      height={60}
                      width={100}
                      animation="wave"
                    />
                    <div className="members-first-section">
                      <div className="width-100">
                        <Skeleton
                          sx={{
                            bgcolor: darkMode ? "grey.800" : "",
                          }}
                          height={90}
                          animation="wave"
                        />
                      </div>
                      <div className="d-flex">
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="AddNew_sectionbox">
                    <Skeleton
                      sx={{
                        bgcolor: darkMode ? "grey.800" : "",
                      }}
                      height={60}
                      width={100}
                      animation="wave"
                    />
                    <div className="members-first-section">
                      <div className="width-100">
                        <Skeleton
                          sx={{
                            bgcolor: darkMode ? "grey.800" : "",
                          }}
                          height={90}
                          animation="wave"
                        />
                      </div>
                      <div className="d-flex">
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="AddNew_sectionbox">
                    <Skeleton
                      sx={{
                        bgcolor: darkMode ? "grey.800" : "",
                      }}
                      height={60}
                      width={100}
                      animation="wave"
                    />
                    <div className="members-first-section">
                      <div className="width-100">
                        <Skeleton
                          sx={{
                            bgcolor: darkMode ? "grey.800" : "",
                          }}
                          height={90}
                          animation="wave"
                        />
                      </div>
                      <div className="d-flex">
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="AddNew_sectionbox">
                    <Skeleton
                      sx={{
                        bgcolor: darkMode ? "grey.800" : "",
                      }}
                      height={60}
                      width={100}
                      animation="wave"
                    />
                    <div className="members-first-section">
                      <div className="width-100">
                        <Skeleton
                          sx={{
                            bgcolor: darkMode ? "grey.800" : "",
                          }}
                          height={90}
                          animation="wave"
                        />
                      </div>
                      <div className="d-flex">
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                        <div className="width-100">
                          <Skeleton
                            sx={{
                              bgcolor: darkMode ? "grey.800" : "",
                            }}
                            height={90}
                            animation="wave"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
