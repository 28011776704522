import React from "react";
import "./../../assets/css/Dashboard/ServicesTab.scss";
import "./../../assets/css/Dashboard/ServicesSidebar.scss";
import "./../../assets/css/Dashboard/ServicesContainer.scss";
import "./../../assets/css/Dashboard/ServicesKanban.scss";
import "./../../assets/css/Dashboard/ServicesAccordion.scss";
import { Skeleton } from "@mui/material";
import useTitanApp from "../../hooks/useTitanApp";
import ServicesSidebar from "../../pages/Dashboard/ServicesTab/ServicesSidebar/ServicesSidebar";

export default function LoadingComponentKanban() {
  const { appState } = useTitanApp();
  const { darkMode } = appState || {};

  return (
    <div className="services_tab">
      <ServicesSidebar />
      <div className="services_container">
        <div className="services_container-content-area">
          <div className="pharmacy_header_modal">
            <div className="services_content-bg services_kanban loading-kanban">
              <div className="service_filter_skeleton">
                <Skeleton
                  height={40}
                  sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                  animation="wave"
                />
              </div>
              <div className="loading-kanban-columns">
                <div>
                  <p className="service_title">
                    <Skeleton
                      width={180}
                      height={35}
                      animation="wave"
                      sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    />
                  </p>
                  <p className="service_title">
                    <Skeleton
                      width={65}
                      height={20}
                      animation="wave"
                      sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    />
                  </p>
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    height={80}
                    animation="wave"
                    variant="rounded"
                  />
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    width={400}
                    height={80}
                    animation="wave"
                    variant="rounded"
                  />
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    width={400}
                    height={80}
                    animation="wave"
                    variant="rounded"
                  />
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    width={400}
                    height={80}
                    animation="wave"
                    variant="rounded"
                  />
                </div>
                <div>
                  <p className="service_title">
                    <Skeleton
                      width={80}
                      height={35}
                      animation="wave"
                      sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    />
                  </p>
                  <p className="service_title">
                    <Skeleton
                      width={65}
                      height={20}
                      animation="wave"
                      sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    />
                  </p>
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    height={80}
                    width={400}
                    animation="wave"
                    variant="rounded"
                  />
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    height={80}
                    width={400}
                    animation="wave"
                    variant="rounded"
                  />
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    height={80}
                    width={400}
                    animation="wave"
                    variant="rounded"
                  />
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    height={80}
                    width={400}
                    animation="wave"
                    variant="rounded"
                  />
                </div>
                <div>
                  <p className="service_title">
                    <Skeleton
                      width={80}
                      height={35}
                      animation="wave"
                      sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    />
                  </p>
                  <p className="service_title">
                    <Skeleton
                      width={65}
                      height={20}
                      animation="wave"
                      sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    />
                  </p>
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    height={80}
                    width={400}
                    animation="wave"
                    variant="rounded"
                  />
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    height={80}
                    width={400}
                    animation="wave"
                    variant="rounded"
                  />
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    height={80}
                    width={400}
                    animation="wave"
                    variant="rounded"
                  />
                </div>
                <div>
                  <p className="service_title">
                    <Skeleton
                      width={80}
                      height={35}
                      animation="wave"
                      sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    />
                  </p>
                  <p className="service_title">
                    <Skeleton
                      width={65}
                      height={20}
                      animation="wave"
                      sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    />
                  </p>
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    height={80}
                    width={400}
                    animation="wave"
                    variant="rounded"
                  />
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    height={80}
                    width={400}
                    animation="wave"
                    variant="rounded"
                  />
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    height={80}
                    width={400}
                    animation="wave"
                    variant="rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
