import React, { useEffect } from "react";
import { FaHandshake } from "react-icons/fa";
import { ReactComponent as MenuSvg } from "./../OverviewIcons/menu.svg";
import { ReactComponent as CalendarSvg } from "./../OverviewIcons/calendar.svg";

export default function ResponsiblePharmacist(onDarkModeChange) {
  useEffect(() => {}, []);

  return (
    <div className="responsible-pharmacist">
      <h3 className="para_color">Responsible Pharmacist</h3>
      <div className="wrapper">
        <div className="header">
          <div className="label">
            <div className="icon-box">
              <FaHandshake size={25} />
            </div>
            <div className="pharmacist">
              <p>Jennie Lowie</p>
              <p className="desig">Responsible Pharmacist</p>
            </div>
          </div>
          <MenuSvg />
        </div>
        <div className="cal-date">
          <CalendarSvg />
          <p>7th June 2023 (11:02am)</p>
        </div>
      </div>
    </div>
  );
}
