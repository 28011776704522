import React, { useState } from "react";
import "./../../../../assets/css/Dashboard/PatientListView.scss";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import PatientTags from "../PatientTags";
import PatientPricingTags from "../PatientPricingTags";
import CustomCheckBox from "../../ServicesTab/ServicesContainer/ServicesAnalytics/components/ServiceClaimStatus/components/CustomCheckBox";
import { useNavigate } from "react-router-dom";
import CampaignPopup from "../CreateCompaign/CreateCompaign";
import useTitanApp from "../../../../hooks/useTitanApp";

const PatientListView = ({ onDarkModeChange }) => {
  const { appState } = useTitanApp();
  const { isAppLoading, currentPharmacy, currentPharmacyId } = appState || {};
  const { patients } = currentPharmacy || {};
  const [pharmacyAvgVal] = useState(currentPharmacy?.totalKPIPercentage || 0);
  const [totalPatients] = useState(patients?.length || 0);

  const navigateFromDashboard = true;
  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const handleCreateCampaignClick = () => {
    setPopupVisible(true);
  };

  const navigate = useNavigate();

  const handleRowClick = (event) => {
    const selectedPatientName = event?.data?.patientName;
    const selectedPatientAddress = event?.data?.postcode;
    const { latitude, logitude } = event?.data || {};
    navigate(`/patientDashboard/${currentPharmacyId}`, {
      replace: true,
      state: {
        onDarkModeChange: onDarkModeChange,
        moveFromDashboard: navigateFromDashboard,
        selectedPatientName: selectedPatientName,
        selectedPatientAddress: selectedPatientAddress,
        pharmacyAvgVal: pharmacyAvgVal,
        latitude: latitude,
        logitude: logitude,
      },
    });
  };

  const PatientColumns = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      field: "selected",
      width: 30,
    },

    {
      headerName: "Name",
      field: "patientName",
      sortable: true,
      filter: "agTextColumnFilter",
      editable: false,
    },

    {
      headerName: "Pricing",
      sortable: true,
      filter: "agTextColumnFilter",
      editable: false,
      field: "patientPricing",
      cellRenderer: PatientPricingTags,
    },

    {
      headerName: "Nominated",
      sortable: true,
      filter: true,
      editable: false,
      suppressSizeToFit: true,
      field: "isNominated",
      cellRenderer: PatientTags,
    },

    {
      headerName: "Address",
      field: "postCode",
      sortable: true,
      filter: "agTextColumnFilter",
      editable: false,
    },

    {
      headerName: "Age",
      field: "age",
      sortable: true,
      filter: "agNumberColumnFilter",
      editable: false,
    },
    {
      headerName: "NHS Number",
      field: "nhsNumber",
      sortable: true,
      filter: true,
      editable: false,
    },
  ];

  const defaultColDef = {
    editable: false,
    resizable: true,
  };

  const searchIcon = (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0.984848C9.03757 0.984848 11.5 3.44728 11.5 6.48485C11.5 7.82364 11.0217 9.0507 10.2266 10.0045L14.3536 14.1313C14.5488 14.3266 14.5488 14.6431 14.3536 14.8384C14.18 15.012 13.9106 15.0313 13.7157 14.8963L13.6464 14.8384L9.51962 10.7114C8.56586 11.5065 7.33879 11.9848 6 11.9848C2.96243 11.9848 0.5 9.52241 0.5 6.48485C0.5 3.44728 2.96243 0.984848 6 0.984848ZM6 1.98485C3.51472 1.98485 1.5 3.99957 1.5 6.48485C1.5 8.97013 3.51472 10.9848 6 10.9848C8.48528 10.9848 10.5 8.97013 10.5 6.48485C10.5 3.99957 8.48528 1.98485 6 1.98485Z"
        className="icons-color"
      />
    </svg>
  );

  const newParamIcon = (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.41012 0.992919L5.5 0.984863C5.74546 0.984863 5.94961 1.16174 5.99194 1.39499L6 1.48486V5.48486H10C10.2455 5.48486 10.4496 5.66174 10.4919 5.89499L10.5 5.98486C10.5 6.23032 10.3231 6.43447 10.0899 6.47681L10 6.48486H6V10.4849C6 10.7303 5.82312 10.9345 5.58988 10.9768L5.5 10.9849C5.25454 10.9849 5.05039 10.808 5.00806 10.5747L5 10.4849V6.48486H1C0.75454 6.48486 0.550392 6.30799 0.508056 6.07474L0.5 5.98486C0.5 5.7394 0.676875 5.53525 0.910124 5.49292L1 5.48486H5V1.48486C5 1.2394 5.17688 1.03525 5.41012 0.992919L5.5 0.984863L5.41012 0.992919Z"
        className="icons-color"
      />
    </svg>
  );

  return (
    isAppLoading === false && (
      <div className="patient_tab">
        <div className="patient_tab_patient_list">
          {totalPatients > 0 ? (
            <>
              <div className="patient_tab_patient_header">
                <div className="patient_tab_patient_count">
                  <span className="patient_tab_patient_count_span patientListHeading">
                    {totalPatients > 0 ? totalPatients : 0} Patients
                  </span>
                </div>
                <div className="patient_tab_patient_controls"></div>
              </div>
              <div className="patient_list_grid_container">
                <div
                  className={
                    onDarkModeChange === true
                      ? "ag-theme-alpine-dark"
                      : "ag-theme-alpine"
                  }
                >
                  <AgGridReact
                    rowData={patients || []}
                    columnDefs={PatientColumns}
                    defaultColDef={defaultColDef}
                    onRowClicked={handleRowClick}
                    frameworkComponents={{
                      PatientTags: PatientTags,
                      CustomCheckBox: CustomCheckBox,
                    }}
                    rowSelection="multiple"
                    pagination={true}
                    paginationPageSize={15}
                  />
                </div>
              </div>
              <div className="patient_tab_list_footer">
                <button onClick={handleCreateCampaignClick}>
                  Create Campaign
                </button>
              </div>

              {isPopupVisible && <CampaignPopup onClose={handleClosePopup} />}
            </>
          ) : (
            <div className="no_patient_found_message">
              <h5>No patients found</h5>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default PatientListView;
