import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./../../../../../../assets/css/Dashboard/Scheduler.scss";
import DeleteEventPopup from "./DeleteEventPopup";
import AddEventPopup from "./AddEventPopup";
import { v4 as uuidv4 } from "uuid";
import useTitanApp, { store } from "../../../../../../hooks/useTitanApp";
import CustomLoader from "../../../../../../components/controls/CustomLoader";
import { setCurrentEvent } from "../../../../../../dispatchers/pharmacyServiceDispatchers";
import moment from "moment";
const CustomScheduler = () => {
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [selectInfo, setSelectInfo] = useState(null);
  const [errMessage, setErrorMessage] = useState(null);
  const [isError, setError] = useState(false);
  const { pharmacyServiceState, appState } = useTitanApp();
  const { isAppLoading } = appState || {};
  const { currentEvents, isPharmacyServiceLoading } =
    pharmacyServiceState || {};

  const handleAddCancel = () => {
    setShowAddPopup(false);
  };
  const handleAddEvent = (newEvent) => {
    let updatedEvents;
    if (selectedEvent) {
      updatedEvents = currentEvents?.map((event) =>
        event.id === selectedEvent.id ? { ...event, ...newEvent } : event
      );
    } else {
      updatedEvents = [...currentEvents, newEvent];
    }
    setCurrentEvent(updatedEvents);
    setShowAddPopup(false);
  };

  const handleSelect = (selectInfo) => {
    setSelectedEvent(selectInfo.event);
    setShowAddPopup(true);
    setSelectInfo(selectInfo);
    let calendarApi = selectInfo?.view?.calendar;
    calendarApi.unselect();
  };

  const handleEventClick = (clickInfo) => {
    const event = clickInfo?.event;
    if (
      event.extendedProps &&
      event.extendedProps.calendarEventStatus &&
      event.extendedProps.calendarEventStatus === 2
    ) {
      return;
    }
    const eventDetails = {
      title: event?.title,
      start: event?.start,
      end: event?.end,
      ...event?.extendedProps,
    };
    setSelectedEvent(eventDetails);
    if (event) {
      setShowAddPopup(true);
    } else {
      setShowAddPopup(false);
    }
    setSelectInfo(eventDetails);
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false);
  };

  const handleDeleteEvent = () => {
    const updatedEvents = currentEvents.filter(
      (event) => event.id != selectedEvent.id
    );
    setCurrentEvent(updatedEvents);
    setShowDeletePopup(false);
  };

  const eventContent = (eventInfo) => {
    if (
      eventInfo.event.extendedProps &&
      eventInfo.event.extendedProps.calendarEventStatus &&
      eventInfo.event.extendedProps.calendarEventStatus === 2
    ) {
      return (
        <div
          style={{
            backgroundColor: "green",
            color: "white",
            borderRadius: "5px",
            padding: "2px",
            cursor: "pointer",
          }}
        >
          {eventInfo.event.title}
        </div>
      );
    } else {
      return (
        <div
          className="event-content"
          onClick={() => handleEventClick(eventInfo)}
        >
          <span className="event_start_time">
            {eventInfo.event.start.toLocaleTimeString([], {
              hour: "numeric",
              minute: "2-digit",
            })}
          </span>
          {eventInfo.event.extendedProps.icon === 0 ? (
            <span className="event_icon">💬</span>
          ) : eventInfo.event.extendedProps.icon === 1 ? (
            <span className="event_icon">🩸</span>
          ) : (
            <span className="event_icon">🚬</span>
          )}
          <span className="event_title">{eventInfo.event.title}</span>
        </div>
      );
    }
  };

  return isPharmacyServiceLoading ? (
    <CustomLoader />
  ) : (
    <div className="calender_div">
      <div className="calender_div_main">
        <FullCalendar
          initialView="timeGridWeek"
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "prev,today,next",
            right: "timeGridDay,timeGridWeek,dayGridMonth,dayGridYear",
            center: "title",
          }}
          views={{
            timeGridWeek: {
              type: "timeGrid",
              slotDuration: "01:00",
              slotLabelInterval: "01:00",
              allDaySlot: false,
            },
            dayGridMonth: {
              dayHeaderFormat: { weekday: "short" }, // Customize day headers for month view
            },
            dayGridYear: {
              dayHeaderFormat: { weekday: "short" },
            },
            timeGridDay: {
              allDaySlot: false,
            },
          }}
          dayHeaderFormat={(param) => {
            return moment(param?.date?.marker).format("ddd DD/MM");
          }}
          slotMinTime="05:00:00"
          slotMaxTime="22:00:00"
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={weekendsVisible}
          events={currentEvents}
          select={handleEventClick}
          eventClick={handleEventClick}
          eventTimeFormat={{
            hour: "numeric",
            minute: "2-digit",
            meridiem: "short",
          }}
          eventContent={eventContent}
          themeSystem="bootstrap5"
        />
      </div>
      {showDeletePopup && (
        <div className="delete-event-popup-overlay">
          <DeleteEventPopup
            event={selectedEvent}
            onCancel={handleDeleteCancel}
            onDelete={handleDeleteEvent}
          />
        </div>
      )}

      {showAddPopup && (
        <div className="add-event-popup-overlay">
          <AddEventPopup
            onCancel={handleAddCancel}
            onAdd={handleAddEvent}
            selectInfo={selectInfo}
            selectedEvent={selectedEvent}
          />
        </div>
      )}
    </div>
  );
};

export default CustomScheduler;
