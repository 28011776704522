export const stageTypes = Object.freeze({
  0: 1,
  1: 2,
  2: 3,
  3: 4,
});

export const stageNumbers = Object.freeze({
  1: 0,
  2: 1,
  3: 2,
  4: 3,
});

export const stageTypeName = Object.freeze({
  1: "Eligible",
  2: "Awaiting consent",
  3: "Intervention",
  4: "Follow up",
});
