import React, { useState } from "react";
import { IoMdFunnel } from "react-icons/io";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import "../../assets/css/ServicesFilter.css";
import ListItemText from "@mui/material/ListItemText";
import { HiMiniXMark } from "react-icons/hi2";
import { Checkbox } from "@mui/material";
import useTitanApp from "../../hooks/useTitanApp";
import {
  setCurrentFilterModal,
  setCurrentPharmacyServices,
  setFilterNmsData,
  setFilterTagsData,
  setOpen,
  setPatientNameData,
  setFilterTagsConsentedData,
} from "../../dispatchers/pharmacyServiceDispatchers";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import CustomButton from "../controls/CustomButton";
import { render } from "@testing-library/react";
function ServicesFilter() {
  const { pharmacyServiceState } = useTitanApp();
  const {
    filterTagValues,
    isFilterModal,
    filterPharmacyService,
    filterNmsValue,
    patientTags,
    patientTagsColor,
    open,
    patientName,
    filterTagValuesConsented,
  } = pharmacyServiceState || {};
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event || {};
    setFilterTagsData(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeConsented = (event) => {
    const {
      target: { value },
    } = event || {};
    setFilterTagsConsentedData(
      typeof value === "string" ? value.split(",") : value
    );
  };
  function getChipColor(nameOfChip) {
    const tag = patientTagsColor.find((tag) => tag?.tagName === nameOfChip);
    return tag ? tag?.tagColour : null;
  }
  function handleSearchtag() {
    const flag = filterTagValuesConsented[0] === "Yes" ? true : false;
    if (patientName === "Consented") {
      const updatedPharmacyService = filterPharmacyService?.map((stage) => ({
        ...stage,
        sortedServices: stage?.sortedServices?.map((service) => ({
          ...service,
          clinicalServices: service?.clinicalServices?.filter(
            (patient) => patient?.isConsent === flag
          ),
        })),
      }));
      setCurrentPharmacyServices(updatedPharmacyService);
    } else {
      if (filterTagValues?.length > 0) {
        if (filterNmsValue === "Is") {
          const updatedPharmacyService = filterPharmacyService?.map(
            (stage) => ({
              ...stage,
              sortedServices: stage?.sortedServices?.map((service) => ({
                ...service,
                clinicalServices: service?.clinicalServices?.filter((patient) =>
                  patient?.patientTags?.some((tag) =>
                    filterTagValues.includes(tag?.tagName)
                  )
                ),
              })),
            })
          );

          setCurrentPharmacyServices(updatedPharmacyService);
        } else {
          const updatedPharmacyService = filterPharmacyService?.map(
            (stage) => ({
              ...stage,
              sortedServices: stage?.sortedServices?.map((service) => ({
                ...service,
                clinicalServices: service?.clinicalServices?.filter(
                  (patient) =>
                    !patient?.patientTags?.some((tag) =>
                      filterTagValues.includes(tag?.tagName)
                    )
                ),
              })),
            })
          );

          setCurrentPharmacyServices(updatedPharmacyService);
        }
      } else {
        setCurrentPharmacyServices(filterPharmacyService);
      }
      setCurrentFilterModal(false);
    }
  }
  const handleButtonClick = () => {
    setCurrentFilterModal(!isFilterModal);
  };
  const handleClearSearch = () => {
    setFilterTagsData([]);
    setCurrentPharmacyServices(filterPharmacyService);
    setCurrentFilterModal(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <div className="filter_button_container">
      <button onClick={handleButtonClick} className="filter_button">
        <IoMdFunnel className="icons-color" size={14} />
        <span>Filters</span>
      </button>

      {isFilterModal && (
        <div className="modal-overlay">
          <div className="modal-content-container">
            <div className="filter_heading">
              <span>Filters</span>
              <HiMiniXMark
                className="x_mark"
                size={25}
                onClick={() => setCurrentFilterModal(false)}
              />
            </div>

            <div className="filters_fields">
              <FormControl size="small" sx={{ m: 0.5, width: 300 }}>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={patientName}
                  onChange={(e) => setPatientNameData(e?.target?.value)}
                  className="role-select"
                  disabled={false}
                >
                  <MenuItem value="Tag">
                    <span>Tag</span>
                  </MenuItem>
                  <MenuItem value="Consented">
                    <span>Consented</span>
                  </MenuItem>
                </Select>
              </FormControl>

              <FormControl size="small" sx={{ m: 0.5, width: 250 }}>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={filterNmsValue}
                  onChange={(e) => setFilterNmsData(e?.target?.value)}
                  className="role-select"
                >
                  <MenuItem value="Is">
                    <span>Is</span>
                  </MenuItem>
                  {patientName === "Tag" ? (
                    <MenuItem value="IsNot">
                      <span>Is Not</span>
                    </MenuItem>
                  ) : null}
                </Select>
              </FormControl>
              {patientName === "Tag" ? (
                <FormControl fullWidth size="small">
                  <Select
                    className="filter-select"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={filterTagValues}
                    onChange={handleChange}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected?.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            style={{ backgroundColor: getChipColor(value) }}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    open={open}
                  >
                    {patientTags?.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={filterTagValues.includes(name)} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <FormControl fullWidth size="small">
                  <Select
                    className="filter-select"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={filterTagValuesConsented}
                    onChange={handleChangeConsented}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected?.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            style={{ backgroundColor: getChipColor(value) }}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    open={open}
                  >
                    <MenuItem value="Yes">
                      <span>Yes</span>
                    </MenuItem>

                    <MenuItem value="No">
                      <span>No</span>
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </div>
            <div className="filter_actions">
              <button onClick={handleClearSearch} className="filter_action">
                Clear
              </button>
              <button onClick={handleSearchtag} className="filter_action">
                Apply
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ServicesFilter;
