import React from "react";
import { inputTypes } from "../../enum/inputTypes";
import CustomLabel from "../controls/CustomLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FormControlLabel from "@mui/material/FormControlLabel";
function SubItem({
  item,
  subItem,
  stageStatus,
  handleFormChange,
  index,
  addedTexts,
  isCompleted,
  addText,
}) {
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  if (subItem.type === 9) {
    return (
      <>
        <div
          key={index}
          style={{
            display: item.value === "true" ? "flex" : "none",
            margin: "10px 0px 10px 0px",
          }}
        >
          <div className="radio-text-box">
            <div className="switch-content-reports">
              <CustomLabel name={subItem.label} />
              <div>
                <AntSwitch
                  key={index}
                  disabled={stageStatus}
                  inputProps={{ "aria-label": "ant design" }}
                  onChange={(event) => addText(index)}
                  checked={addedTexts.includes(index)}
                />
              </div>
            </div>
            {addedTexts.includes(index) ? (
              <div key={index} className="template_control">
                <TextField
                  className="template_input_inner"
                  error={
                    subItem.required
                      ? subItem === true
                        ? subItem.value === ""
                          ? true
                          : false
                        : false
                      : false
                  }
                  required
                  id="outlined-required"
                  disabled={stageStatus}
                  label="Required"
                  defaultValue={subItem.value}
                  onChange={(event) =>
                    handleFormChange(
                      index,
                      event,
                      inputTypes[subItem.type],
                      subItem.label,
                      subItem.value
                    )
                  }
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
  }
  if (subItem.type === 5) {
    const handleClick = () => {
      handleFormChange(
        index,
        { target: { checked: !JSON.parse(subItem.value) } },
        inputTypes[subItem.type],
        subItem.label,
        subItem.value
      );
    };

    return (
      <div
        className="switch-content-reports toggle-only"
        onClick={handleClick}
        style={{
          display: item.value === "true" ? "flex" : "none",
          margin: "10px 0px 10px 0px",
        }}
      >
        <CustomLabel name={subItem.label} />
        <div>
          <AntSwitch
            disabled={stageStatus}
            inputProps={{
              "aria-label": "ant design",
            }}
            onChange={(event) =>
              handleFormChange(
                index,
                event,
                inputTypes[subItem.type],
                subItem.label,
                subItem.value
              )
            }
            checked={subItem.value === "" ? "false" : JSON.parse(subItem.value)}
          />
        </div>
      </div>
    );
  }
}

export default SubItem;
