import React, { useState, useRef, useEffect } from "react";
import { FaRegHospital } from "react-icons/fa";
import { BsFillCheckCircleFill } from "react-icons/bs";
import AddNewButtons from "./AddNewButtons";
import { FaChevronLeft } from "react-icons/fa";
import setAddTeam from "../../../Model/AddTeam/setAddTeam";
import setUpdateTeam from "../../../Model/UpdateTeam/setUpdateTeam";
import CustomLoader from "./../../controls/CustomLoader";
import { HiOutlineSearch } from "react-icons/hi";
import useTitanApp from "../../../hooks/useTitanApp";
import { getPharmaciesByCompanyId } from "../../../dispatchers/pharmacyServiceDispatchers";
import {
  addTeam,
  setAddTeamPharmacyName,
  updateTeam,
} from "../../../dispatchers/teamDispatchers";
import {
  setAppLoading,
  getApplicationData,
  setIsTeamUpdated,
  setCurrentTeam,
} from "../../../dispatchers/appDispatchers";
import { logError } from "../../../services/logService";
import { showError } from "../../../components/ToastedMessage/ToastedMessage";
import useTitanTeam from "../../../hooks/useTitanTeam";
import { v4 as uuidv4 } from "uuid";

export default function SelectPharmacies(props) {
  const myRefpharmacy = useRef(null);
  const [selectedDivs, setSelectedDivs] = useState([]);
  const [pharmacyErrorMessage, setpharmacyErrorMessage] = useState(null);
  const [pharmacyObj, setPharmacyObj] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const { handleCloseCallBack, handleClosePharmaciesCallBack, handleCallback } = props || {};
  const { appState, pharmacyServiceState, auth } = useTitanApp();
  const { teamState } = useTitanTeam();
  const { addTeamPharmacyName } = teamState || "";
  const { teamFormMode } = teamState || "";
  const { user } = auth || {};
  const userFullName = user?.name;
  const userEmail = user?.email;
  const { companyId, currentTeamId, currentTeam, teams } = appState || {
    companyId: "00000000-0000-0000-0000-000000000000",
  };
  let teamID = uuidv4();
  let pharmacyArray = [];
  const [tempUpdatedSelectedPharmacy, setTempUpdatedSelectedPharmacy] =
    useState(currentTeam?.pharmacies);

  useEffect(() => {
    const fetchData = async () => {
      let pharmaciesObj = [];
      try {
        getPharmaciesByCompanyId(companyId).then((data) => {
          pharmaciesObj = data?.map((pharmacy) => {
            return {
              teamPharmacyID: pharmacy.pharmacyID,
              pharmacyID: pharmacy.pharmacyID,
              teamPharmacyName: pharmacy.pharmacyName,
              teamPharmacyAddress: pharmacy.pharmacyAddress,
              teamPharmacyLongitude: pharmacy.longitude.toString(),
              teamPharmacyLatitude: pharmacy.latitude.toString(),
              clinicalKPI: pharmacy.clinicalKPI,
              financeKPI: pharmacy.financeKPI,
              operationsKPI: pharmacy.operationsKPI,
              efficiencyKPI: pharmacy.efficiencyKPI,
              pharmacyKPIs: pharmacy.pharmacyKPIs,
              pharmacyImage: pharmacy.pharmacyImage,
              isHelpRequired: pharmacy.isHelpRequired,
              patients: pharmacy.patients,
            };
          });

          setPharmacyObj(pharmaciesObj);
          setIsLoading(false);
          return data;
        });
      } catch (error) {
        console.error("Error retrieving pharmacies", error);
      }
    };

    fetchData();

    return () => { };
  }, []);

  const handleDivClick = (pharmacy) => {
    // If the clicked div is already selected, remove it from the selectedDivs array
    if (teamFormMode === "add") {
      if (
        selectedDivs.some(
          (p) => p.teamPharmacyName === pharmacy.teamPharmacyName
        )
      ) {
        setSelectedDivs(
          selectedDivs.filter(
            (p) => p.teamPharmacyName !== pharmacy.teamPharmacyName
          )
        );
      } else {
        setSelectedDivs([...selectedDivs, pharmacy]);
      }

      pharmacyArray = selectedDivs;
    } else {
      setpharmacyErrorMessage("");
      if (addTeamPharmacyName.includes(pharmacy.teamPharmacyName)) {
        setAddTeamPharmacyName(
          addTeamPharmacyName.filter((p) => p !== pharmacy.teamPharmacyName)
        );
      } else {
        setAddTeamPharmacyName([
          ...addTeamPharmacyName,
          pharmacy.teamPharmacyName,
        ]);
      }
      if (
        tempUpdatedSelectedPharmacy.some(
          (p) => p.teamPharmacyName === pharmacy.teamPharmacyName
        )
      ) {
        setTempUpdatedSelectedPharmacy(
          tempUpdatedSelectedPharmacy.filter(
            (p) => p.teamPharmacyName !== pharmacy.teamPharmacyName
          )
        );
      } else {
        setTempUpdatedSelectedPharmacy([
          ...tempUpdatedSelectedPharmacy,
          pharmacy,
        ]);
      }
    }
  };

  const createTeamMem = async () => {
    if (teamFormMode === "add") {
      if (selectedDivs?.length === 0) {
        setpharmacyErrorMessage("At least one pharmacy should be selected.");
      } else {
        setIsLoading(true);
        const selectedPharmacy = selectedDivs[0];
        handleDivClick(selectedPharmacy);
        const obj = new setAddTeam(
          teamID,
          props.teamName,
          companyId,
          props.teamName,
          pharmacyArray,
          props.teamImage,
          userFullName,
          userFullName,
          userFullName,
          userEmail
        ).obj;
        pharmacyArray = [];
        setSelectedDivs([]);
        try {
          addTeam(obj)
            .then((data) => {
              setIsLoading(false);
              handleClickClosePharmacies();
              createTeamCallback();
            })
            .catch((data) => {
              setIsLoading(false);
              showError("Unable to add team.");
              return data;
            });
        } catch (error) { }
      }
    } else {
      if (addTeamPharmacyName?.length === 0) {
        setpharmacyErrorMessage("At least one pharmacy should be selected.");
      } else {
        const obj = new setUpdateTeam(
          currentTeamId,
          props.teamName,
          companyId,
          props.teamName,
          tempUpdatedSelectedPharmacy,
          props.teamImage,
          userFullName,
          userFullName,
          userFullName,
          userEmail
        ).obj;
        setTempUpdatedSelectedPharmacy([]);
        setIsTeamUpdated(true);
        try {
          updateTeam(obj)
            .then((data) => {
              setIsLoading(false);
              handleClickClosePharmacies();
              createTeamCallback();
            })
            .catch((data) => {
              setIsLoading(false);
              showError("Unable to add team.");
              return data;
            });
        } catch (error) { }
      }
    }
  };
  async function createTeamCallback() {
    const team = teams[props.clickedTeamIndex];
    if (getApplicationData) {
      const errorHandler = (error) => {
        logError(error);
        setAppLoading(false);
        return error;
      };
      const successHandler = (data) => {
        if (team && data) {
          handleCallback(tempUpdatedSelectedPharmacy);
          setIsTeamUpdated(false);
        }
        setAppLoading(false);
        return data;
      };
      getApplicationData(0).then(successHandler).catch(errorHandler);
    }
  }

  const handleClickClosePharmacies = () => {
    handleClosePharmaciesCallBack(false);
  };
  const handleClick = () => {
    handleCloseCallBack(true);
  };

  return (
    <div className="pharmacies-list">
      {isLoading ? (
        <div className="pharmacy-loader">
          <CustomLoader />
        </div>
      ) : pharmacyObj?.length > 0 ? (
        <div>
          <div className="addNewPharmacy-heading">
            <h4>
              <button onClick={handleClick}>
                <FaChevronLeft size={20} className="icons-box" />
              </button>
              Select Pharmacies (
              {selectedDivs?.length || addTeamPharmacyName?.length})
            </h4>
            <div className="pharmacy-search-wrapper">
              <div className="pharmacy-search-box">
                <HiOutlineSearch />
                <input
                  type="text"
                  placeholder="Search pharmacies..."
                  onChange={(e) => setSearch(e?.target?.value)}
                />
              </div>
            </div>
          </div>
          <div className="pharmacy-list-wrapper">
            {pharmacyObj
              .filter((item) => {
                return search.toLowerCase() === ""
                  ? item
                  : item.teamPharmacyName.toLowerCase().includes(search);
              })
              ?.map((pharmacy, index) => (
                <div
                  ref={myRefpharmacy}
                  className={`pharmacy-container ${selectedDivs.some(
                    (p) => p.teamPharmacyName === pharmacy.teamPharmacyName
                  ) ||
                    addTeamPharmacyName?.includes(pharmacy.teamPharmacyName)
                    ? "pharmacy-selected"
                    : ""
                    }`}
                  key={index}
                  onClick={() => handleDivClick(pharmacy)}
                >
                  <div>
                    <div className="pharmacy-title">
                      <FaRegHospital />
                      <p>{pharmacy.teamPharmacyName}</p>
                    </div>
                    <div className="pharmacy-address">
                      <p>{pharmacy.teamPharmacyAddress}</p>
                    </div>
                  </div>
                  <div>
                    <div className="pharmacy-check-icon">
                      {selectedDivs.some(
                        (p) => p.teamPharmacyName === pharmacy.teamPharmacyName
                      ) ||
                        addTeamPharmacyName?.includes(
                          pharmacy.teamPharmacyName
                        ) ? (
                        <BsFillCheckCircleFill className="icons-box" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {pharmacyErrorMessage && (
            <div
              style={{
                color: "red",
                fontSize: "12px",
                fontWeight: "bold",
                marginLeft: "15px",
                marginTop: "15px",
              }}
            >
              {pharmacyErrorMessage}
            </div>
          )}
          <div className="pharmacies-list-footer">
            <AddNewButtons
              text={teamFormMode === "add" ? "Create Team" : "Update Team"}
              onClick={createTeamMem}
            />
          </div>
        </div>
      ) : (
        <div className="pharmacy-error-message">
          <p>Data not available!</p>
        </div>
      )}
    </div>
  );
}
