import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpGet, httpPut } from "../../services/httpService";
import {
  getGeneralsUrl,
  updateGeneralsDetailsUrl,
} from "../../apiUrls/apiUrls";
import { logError } from "../../services/logService";

const initialState = {
  isGeneralLoading: false,
  generalData: null,
  companyEmail: "",
  companyContact: "",
  companyThemeColor: "",
};

export const getGeneralsData = createAsyncThunk(
  "general/getGeneralsData",
  async () => {
    const urlToGet = getGeneralsUrl();
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const updateGeneralData = createAsyncThunk(
  "general/updateGeneralData",
  async (data, thunkAPI) => {
    const respData = await httpPut(updateGeneralsDetailsUrl(), data);
    return respData;
  }
);

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setIsGeneralLoading: (state, action) => {
      state.isGeneralLoading = action?.payload || false;
    },
    setGeneralData: (state, action) => {
      state.generalData = action?.payload;
    },
    setCompanyEmailData: (state, action) => {
      state.companyEmail = action?.payload || "";
    },
    setCompanyContactData: (state, action) => {
      state.companyContact = action?.payload || "";
    },
    setCompanyThemeColorData: (state, action) => {
      state.companyThemeColor = action?.payload || "";
    },
    resetGeneralState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGeneralsData.pending, (state) => {
        state.isGeneralLoading = true;
      })
      .addCase(getGeneralsData.fulfilled, (state, action) => {
        state.isGeneralLoading = false;
        state.generalData = action?.payload;
        if (state?.generalData) {
          const { companyOwnerEmailAddress, contactNumber, companyTheme } =
            state?.generalData || {};
          state.companyEmail = companyOwnerEmailAddress || "";
          state.companyContact = contactNumber || "";
          state.companyThemeColor = companyTheme || "";
        }
      })
      .addCase(getGeneralsData.rejected, (state, action) => {
        state.isGeneralLoading = false;
        state.generalData = [];
      })
      .addCase(updateGeneralData.pending, (state) => {
        state.isGeneralLoading = true;
      })
      .addCase(updateGeneralData.fulfilled, (state, action) => {
        state.isGeneralLoading = false;
        state.generalData = action?.payload?.company || action?.payload;
        if (state?.generalData) {
          const { companyOwnerEmailAddress, contactNumber, companyTheme } =
            state?.generalData || {};
          state.companyEmail = companyOwnerEmailAddress || "";
          state.companyContact = contactNumber || "";
          state.companyThemeColor = companyTheme || "";
        }
      })
      .addCase(updateGeneralData.rejected, (state, action) => {
        logError(action?.error);
        state.isGeneralLoading = false;
        state.generalData = null;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsGeneralLoading,
  setGeneralData,
  resetGeneralState,
  setCompanyEmailData,
  setCompanyContactData,
  setCompanyThemeColorData,
} = generalSlice.actions;

export default generalSlice.reducer;
