import React, { useState } from "react";
import Invite from "../Invite/Invite";
import Avatar from "react-initials-avatar";
import { FaPlus } from "react-icons/fa";

export default function TeamMembers({ headerTeamMembers, onDarkModeChange }) {
  const [show, setShow] = useState(false);
  let teamMemberstoShow, teamMemberstoHide;
  if (headerTeamMembers) {
    teamMemberstoShow = headerTeamMembers.slice(0, 4);
    teamMemberstoHide = headerTeamMembers.slice(4);
  }

  return (
    <div className="team-members">
      {teamMemberstoShow?.length > 0 ? (
        teamMemberstoShow?.map((teamMember, index) => (
          <div key={index} className="team-img-text">
            <div className="team-img label-btn">
              {teamMember.teamMemberImage ? (
                <img src={teamMember.teamMemberImage} />
              ) : (
                <Avatar
                  name={teamMember.userFirstName}
                  size={40}
                  fontSize={18}
                />
              )}
            </div>
            <div className="team-text">
              <p>{teamMember.userFirstName}</p>
            </div>
          </div>
        ))
      ) : (
        <div className="team-img-text">
          <div className="team-img label-btn">
            {headerTeamMembers.teamMemberImage ? (
              <img src={headerTeamMembers.teamMemberImage} />
            ) : (
              <Avatar
                name={headerTeamMembers.userFirstName}
                size={40}
                fontSize={18}
              />
            )}
          </div>
          <div className="team-text">
            <p>{headerTeamMembers.userFirstName}</p>
          </div>
        </div>
      )}

      {teamMemberstoHide?.length > 0 ? (
        <div className="team-member-count">
          <div className="team-length label-btn">
            <p>{teamMemberstoHide?.length}</p>
          </div>

          <div className="team-text">
            <p>{teamMemberstoHide?.length + "+ others"}</p>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="team-member-add" onClick={() => setShow(!show)}>
        <div className="team-add label-btn">
          <FaPlus className="para_color" size={12} />
        </div>

        <div className="team-text">
          <p>Invite team member</p>
        </div>
      </div>

      <Invite
        show={show}
        setShow={setShow}
        onDarkModeChange={onDarkModeChange}
      />
    </div>
  );
}
