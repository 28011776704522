import React from "react";
import { ReactComponent as MenuSvg } from "./../OverviewIcons/menu.svg";
import { FaHandshake } from "react-icons/fa";

export default function ServicesThisWeek({
  tabsServices,
  activeTab,
  onDarkModeChange,
}) {
  return (
    <React.Fragment>
      <h6 className="para_color">Services</h6>
      {tabsServices?.map(
        (tab, index) =>
          activeTab === tab.day && (
            <div className="tab-content" key={index}>
              {tab?.content?.map((content, index) => (
                <div className="content-item" key={index}>
                  <div>
                    <FaHandshake size={20} className="icons-color" />
                    <p>{content.name}</p>
                  </div>

                  <div>
                    <p className="services">{content.service}</p>
                    <p className="date">{content.time}</p>
                    <MenuSvg />
                  </div>
                </div>
              ))}
            </div>
          )
      )}
    </React.Fragment>
  );
}
