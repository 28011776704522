import React, { useEffect } from "react";
import { RxCaretRight } from "react-icons/rx";

export default function PrescriptionsAndServicesToday({
  prescriptionsToday,
  servicesToday,
  onDarkModeChange,
}) {
  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <h3 className="para_color">Prescriptions Today</h3>
      <div className="wrapper">
        {prescriptionsToday?.map((prescription, index) => (
          <div className="col-box" key={index}>
            <div className="box-label">
              <p className="count">{prescription.count}</p>
              <p className="name">{prescription.prescriptions}</p>
            </div>
            <RxCaretRight size={25} className="icons-box" />
          </div>
        ))}
      </div>
      <h3 className="para_color">Services Today</h3>
      <div className="wrapper">
        {servicesToday?.map((service, index) => (
          <div className="col-box" key={index}>
            <div className="box-label">
              <p className="count">{service.count}</p>
              <p className="name">{service.services}</p>
            </div>
            <RxCaretRight size={25} className="icons-color" />
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}
