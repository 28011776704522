import React from "react";
import { useState } from "react";
import useTitanApp from "../../hooks/useTitanApp";
import { Dropdown } from "react-bootstrap";
import { setCurrentPharmacy } from "../../dispatchers/appDispatchers";
import {
  useLocation,
  useNavigate,
  useParams,
  useRevalidator,
} from "react-router";

export default function PharmacyHeaderDetails() {
  const navigate = useNavigate();
  const loc = useLocation();
  const { pharmacyId } = useParams() || {
    pharmacyId: "00000000-0000-0000-0000-000000000000",
  };
  const revalidator = useRevalidator();
  const { appState } = useTitanApp();

  const { currentPharmacy, pharmacies, isAppLoading, isTabLoading } =
    appState || {};
  const [defaultPharmacy] = useState({
    teamPharmacyName: "",
    teamPharmacyAddress: "",
    teamPharmacyLatitude: 0.0,
    teamPharmacyLongitude: 0.0,
  });
  const { teamPharmacyName, teamPharmacyAddress } =
    currentPharmacy || defaultPharmacy;

  return (
    <>
      <div className="pharmacy_header_details">
        {pharmacies?.length > 1 ? (
          <div>
            <Dropdown className="pharmacy_select">
              <Dropdown.Toggle
                variant="success"
                className="pharmacy_select_button"
                id="dropdown-basic"
              >
                <div className="pharmacy_header_iconbox">
                  <svg
                    id="Layer_1"
                    data-name="Layer 1"
                    width="20"
                    height="20"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 34 29.1354"
                  >
                    <path
                      d="M10,21.1354V14.0075a2,2,0,0,1,.6391-1.4656l5.68-5.2747a1,1,0,0,1,1.361,0l5.68,5.2747A2,2,0,0,1,24,14.0075v7.1279a1,1,0,0,1-1,1H20a1,1,0,0,1-1-1v-4a1,1,0,0,0-1-1H16a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H11A1,1,0,0,1,10,21.1354Z"
                      className="icons-color"
                    />
                  </svg>
                  <p className="pharmacy_header_name">{teamPharmacyName}</p>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="pharmacy_select_menu">
                {pharmacies?.map((pharmacy) => (
                  <Dropdown.Item
                    key={pharmacy?.teamPharmacyID}
                    className="pharmacy_select_item"
                    disabled={isAppLoading || isTabLoading}
                    onClick={() => {
                      const toPath = loc.pathname.replace(
                        pharmacyId,
                        pharmacy?.teamPharmacyID
                      );
                      navigate(toPath, {
                        replace: true,
                      });
                    }}
                  >
                    <svg
                      id="Layer_1"
                      data-name="Layer 1"
                      width="20"
                      height="20"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 34 29.1354"
                    >
                      <path
                        d="M10,21.1354V14.0075a2,2,0,0,1,.6391-1.4656l5.68-5.2747a1,1,0,0,1,1.361,0l5.68,5.2747A2,2,0,0,1,24,14.0075v7.1279a1,1,0,0,1-1,1H20a1,1,0,0,1-1-1v-4a1,1,0,0,0-1-1H16a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H11A1,1,0,0,1,10,21.1354Z"
                        className="icons-color"
                      />
                    </svg>
                    <p>{pharmacy?.teamPharmacyName}</p>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : (
          <div>
            <div className="pharmacy_header_iconbox">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                width="20"
                height="20"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 34 29.1354"
              >
                <path
                  d="M10,21.1354V14.0075a2,2,0,0,1,.6391-1.4656l5.68-5.2747a1,1,0,0,1,1.361,0l5.68,5.2747A2,2,0,0,1,24,14.0075v7.1279a1,1,0,0,1-1,1H20a1,1,0,0,1-1-1v-4a1,1,0,0,0-1-1H16a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H11A1,1,0,0,1,10,21.1354Z"
                  className="icons-color"
                />
              </svg>
              <p className="pharmacy_header_name">{teamPharmacyName || ""}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
