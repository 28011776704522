import React from "react";
import { LiaCopy } from "react-icons/lia";
import { ReactComponent as AddNewSvg } from "./../PatientDashboardIcons/addNew.svg";

export default function Contact({ contactDetails }) {
  return (
    <React.Fragment>
      <div className="tasks_header">
        <h3 className="pharmacy_header_name">Contact Details</h3>
        <AddNewSvg />
      </div>
      <div className="contact-details-wrapper">
        {contactDetails?.map((contact) => (
          <div className="contact-detail-box">
            <div>
              <p className="contact-label">{contact.label}</p>
              <p className="contact-value">{contact.value}</p>
            </div>
            <LiaCopy
              size={22}
              // color={
              //   onDarkModeChange.onDarkModeChange === false ? "#999" : "#fff"
              // }
            />
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}
