import React from "react";
import { ReactComponent as WarningExclamation } from "./../../../../../assets/images/warning-exec.svg";
import { useNavigate, useLocation, Link } from "react-router-dom";

export default function OrbitMissingBankInfo() {
  const navigate = useNavigate();

  const handlePaymentDetails = () => {
    navigate("/orbitsettings/paymentdetails");
  };

  return (
    <div className="orbit_box missing_bank_info">
      <div className="orbit_label">
        <WarningExclamation />
        <p>Missing Info</p>
      </div>
      <p className="text">
        Missing bank information. <a className="missing_info_link" onClick={handlePaymentDetails}>Click here to enter</a>
      </p>
    </div>
  );
}
