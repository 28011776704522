import React from "react";
import Dialog from "@mui/material/Dialog";
import useTitanApp from "../../hooks/useTitanApp";
import {
  setCurrentGroupedPatientWarning,
  setCurrentShowNMSNotComplete,
} from "../../dispatchers/nmsDispatchers";
import { TiWarning } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";
import CustomButton from "./../controls/CustomButton";

export default function GroupedPatientsPopup({ getUpdatedDetailForNMS }) {
  const { nmsServiceState, appState } = useTitanApp();
  const { darkMode } = appState;
  const { groupedPatientWarning, tempClinicalServiceId } =
    nmsServiceState || {};

  const handleYesClick = () => {
    getUpdatedDetailForNMS(tempClinicalServiceId);
    handleClose();
  };
  const handleClose = () => {
    setCurrentGroupedPatientWarning(false);
  };

  const handleActionClick = () => {
    setCurrentShowNMSNotComplete(true);
    handleClose();
  };

  return (
    <Dialog
      open={groupedPatientWarning}
      onClose={handleClose}
      aria-labelledby="grouped-patients-dialog"
      aria-describedby="grouped-patients-dialog"
      className="grouped-patients-dialog"
    >
      <div>
        <div className="cross" onClick={handleClose}>
          <RxCross2 color={darkMode ? "#fff" : "#0a0a0a"} />
        </div>

        <div className="flex">
          <div className="header">
            <TiWarning color="#D60000" size={50} />
            <h4>WARNING</h4>
          </div>
          <div className="body">
            <p>
              You are about to navigate away from this patient's record. Any
              unsaved changes will be lost.
            </p>
          </div>
          <div className="lower">
            <div className="button-group">
              <CustomButton label="Yes" action={handleYesClick} />

              <p>Press Yes to continue without saving</p>
            </div>
            <div className="button-group">
              <CustomButton label="Action" action={handleActionClick} />

              <p>Press Action to decline or reschedule.</p>
            </div>
            <div className="button-group">
              <CustomButton label="Cancel" action={handleClose} />

              <p>Press Cancel to return and save your changes</p>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
