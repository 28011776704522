import * as React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useRevalidator } from "react-router-dom";
import CustomButton from "../controls/CustomButton";
import "./../../assets/css/PatientModal.css";
import { ImWarning } from "react-icons/im";
import {
  changeSentToOrbit,
  setSentToOrbit,
} from "../../dispatchers/pharmacyServiceDispatchers";
import useTitanApp from "../../hooks/useTitanApp";
export default function OrbitQueuePopup({
  view,
  handleChange,
  setShowOrbitQueuePopup,
  listviewOrbitClinicalServiceId,
}) {
  const { pharmacyServiceState } = useTitanApp();
  const revalidator = useRevalidator();
  const { orbitClinicalServiceId } = pharmacyServiceState || {};
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  function proceedNms() {
    if (view === "listview") {
      setShowOrbitQueuePopup(false);
      changeSentToOrbit(listviewOrbitClinicalServiceId).then((data) => {
        if (data) {
          handleChange(null, 1);
        }
      });
    } else {
      setSentToOrbit(false);
      changeSentToOrbit(orbitClinicalServiceId).then((data) => {
        if (data) {
          revalidator.revalidate();
        }
      });
    }
  }

  const handleOrbitQueuePopupClose = () => {
    if (view === "listview") {
      setShowOrbitQueuePopup(false);
    } else {
      setSentToOrbit(false);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <div className="clinical_modal">
          <Modal.Header>
            <ImWarning size={12} color={"red"} />
            <Modal.Title>Prescription in progress</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="progressmodal_body">
              <p>{`This patient is already sent to orbit. Do you want to remove?`}</p>
            </div>
          </Modal.Body>
          <div className="progressmodal_footer">
            <CustomButton label="No" action={handleOrbitQueuePopupClose} />
            <CustomButton label="Yes" action={proceedNms} />
          </div>
        </div>
      </Modal>
    </>
  );
}
