import React, { useState, useEffect } from "react";
import { GoArrowLeft } from "react-icons/go";
import CustomButton from "../../../../../../controls/CustomButton";
import CustomLoader from "../../../../../../controls/CustomLoader";
import TextField from "@mui/material/TextField";
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import useTitanTeam from "../../../../../../../hooks/useTitanTeam";
import useTitanApp from "../../../../../../../hooks/useTitanApp";
import {
  setCurrentRole,
  updateRole,
} from "../../../../../../../dispatchers/teamDispatchers";
import { useLoaderData, useNavigate } from "react-router";
import { showError } from "../../../../../../ToastedMessage/ToastedMessage";
import SettingsSidebar from "../../../../../../SettingsSidebar/SettingsSidebar";
import AsyncRendererRouter from "../../../../../../AsyncRendererRouter/AsyncRendererRouter";

const getCheckAll = (permissions, checkedItems) => {
  return checkedItems?.length === permissions?.length
    ? "Uncheck all"
    : "Check all";
};

export default function UpdateRole() {
  const navigate = useNavigate();
  const { appState } = useTitanApp();
  const { teamState } = useTitanTeam();
  const { teamPermissions, currentRole, isTeamLoading } = teamState || {};
  const { companyId } = appState || {
    companyId: "00000000-0000-0000-0000-000000000000",
  };
  const [checkedItems, setCheckedItems] = useState([]);

  const handleToggle = (permission) => () => {
    const currentIndex = checkedItems.findIndex(
      (item) => item._id === permission._id
    );
    const newCheckedItems = [...checkedItems];

    if (currentIndex === -1) {
      newCheckedItems.push(permission);
    } else {
      newCheckedItems.splice(currentIndex, 1);
    }
    setCheckedItems(newCheckedItems);
  };

  function handleSelectAll() {
    if (getCheckAll(teamPermissions, checkedItems) === "Check all") {
      setCheckedItems(teamPermissions);
    } else {
      setCheckedItems([]);
    }
  }

  const checkboxTheme = createTheme({
    overrides: {
      MuiCheckbox: {
        root: {
          "&:hover": {
            backgroundColor: "transparent", // Disable hover background effect
          },
        },
        checked: {
          "&:hover": {
            backgroundColor: "transparent", // Disable hover background effect when checked
          },
        },
      },
    },
  });

  useEffect(() => {
    if (
      Array.isArray(currentRole?.permissions) &&
      currentRole?.permissions?.length > 0
    ) {
      setCheckedItems(currentRole?.permissions);
    }
  }, [currentRole?.permissions]);

  const handleEditRole = () => {
    if (checkedItems?.length === 0) {
      showError("Please select atleast one permission", true, false);
    } else {
      const dataModel = {
        _id: currentRole?._id,
        roleName: currentRole?.roleName,
        permissions: checkedItems,
        companyID: companyId,
      };
      const savedRole = updateRole(dataModel);
      savedRole.then((result) => {
        if (result._id) {
          navigate("/settings/teams/roles");
        } else {
          showError("Role could not be added.", true, false);
        }
      });
    }
  };

  const handleRoleNameChange = (event) => {
    setCurrentRole({ ...currentRole, roleName: event?.target?.value });
  };

  const { appData } = useLoaderData() || {};

  return (
    <AsyncRendererRouter
      isLoading={isTeamLoading}
      resolve={appData}
      loadingElement={
        <div className="settings_page">
          <div className="settings_wrapper">
            <SettingsSidebar />
            <div className="settings_content_wrapper">
              <CustomLoader />
            </div>
          </div>
        </div>
      }
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <SettingsSidebar />
          <div className="settings_content_wrapper">
            <div className="settings_heading">
              <h1>Roles</h1>
            </div>
            <div>
              <div>
                <div
                  className="back_to_SettingsListView"
                  onClick={() => {
                    navigate("/settings/teams/roles");
                  }}
                >
                  <GoArrowLeft className="icons-color" />
                  <p>Back</p>
                </div>
                {currentRole ? (
                  <div className="addMember_formFields">
                    <div className="inner-panels">
                      <div className="AddNew_sectionbox">
                        <h4>General Information</h4>
                        <div className="rolesGeneralInfo_wrapper">
                          <TextField
                            id="outlined-basic-edit-role"
                            label="Role Name"
                            variant="outlined"
                            value={currentRole?.roleName}
                            onChange={handleRoleNameChange}
                            fullwidth={true}
                            autoComplete={"off"}
                          />
                        </div>
                      </div>

                      <div className="AddNew_sectionbox">
                        {teamPermissions?.length !== 0 ? (
                          <div>
                            <h4>Permissions</h4>
                            <div className="settings_box">
                              <div>
                                {teamPermissions?.map((permission, index) => {
                                  return (
                                    <div key={index}>
                                      <ThemeProvider theme={checkboxTheme}>
                                        <div>
                                          <List className="services_list">
                                            <ListItem
                                              button
                                              onClick={handleToggle(permission)}
                                            >
                                              <ListItemIcon>
                                                <Checkbox
                                                  edge="start"
                                                  checked={
                                                    checkedItems?.findIndex(
                                                      (item) =>
                                                        item._id ===
                                                        permission._id
                                                    ) !== -1
                                                  }
                                                  tabIndex={-1}
                                                  disableRipple
                                                />
                                              </ListItemIcon>
                                              <ListItemText
                                                primary={
                                                  permission?.permissionName
                                                }
                                              />
                                            </ListItem>
                                          </List>
                                        </div>
                                      </ThemeProvider>
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="checkAll_btnBox">
                                <button onClick={handleSelectAll}>
                                  {getCheckAll(teamPermissions, checkedItems)}
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>No permission found</div>
                        )}
                      </div>

                      <div className="footer_btn">
                        <CustomButton
                          label="Save Changes"
                          action={handleEditRole}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>No role existing with this Id</>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
