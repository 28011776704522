import { getObject } from "../getObjectValue";
class notes {
  constructor(response) {
    this.obj = response?.map((value) => {
      return {
        prescriptionIncomingNoteId: value.prescriptionIncomingNoteId
          ? getObject(value, "prescriptionIncomingNoteId")
          : "",
        prescriptionIncomingId: value.prescriptionIncomingId
          ? getObject(value, "prescriptionIncomingId")
          : "",
        note: value.note ? getObject(value, "note") : "",
        archiveDate: value.archiveDate ? getObject(value, "archiveDate") : "",
        pinNote: value.pinNote ? getObject(value, "pinNote") : "",
        awaitingQuery: value.awaitingQuery
          ? getObject(value, "awaitingQuery")
          : "",
        showOnEntry: value.showOnEntry ? getObject(value, "showOnEntry") : "",
        showOnHandout: value.showOnHandout
          ? getObject(value, "showOnHandout")
          : "",
        createdOn: value.createdOn ? getObject(value, "createdOn") : "",
      };
    });
  }
}

export default notes;
