import React, { useState } from "react";
import SettingsSidebar from "../../SettingsSidebar/SettingsSidebar";
import TextField from "@mui/material/TextField";
import CustomButton from "../../controls/CustomButton";
import useTitanApp from "../../../hooks/useTitanApp";
import AsyncRendererRouter from "../../AsyncRendererRouter/AsyncRendererRouter";
import { useLoaderData } from "react-router-dom";
import {
  upsertBillingContact,
  setCompanyName,
  setCompanyNumber,
  setCompanyAddress,
  setBankName,
  setAccountNumber,
  setSortCode,
} from "../../../dispatchers/billingDispatchers";
import { showError, showSuccess } from "../../ToastedMessage/ToastedMessage";
import { Skeleton } from "@mui/material";
import BillingLoadingComponent from "../../SettingsLoadingComponents/BillingLoadingComponent";
import Invoices from "./components/Invoices";

export default function Billing() {
  const { appState, billingState } = useTitanApp();
  const {
    isBillingLoading,
    companyName,
    companyNumber,
    companyAddress,
    bankName,
    accountNumber,
    sortCode,
  } = billingState || {};
  const { currentUser } = appState || {};
  const { billingContactID, firstName, lastName, emailAddress } =
    currentUser || {};
  const { darkMode, features, currentPharmacyId } = appState || {};
  const [selectedMenuItem, setSelectedMenuItem] = useState("Billing");
  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };

  const handleCompanyChange = (event) => {
    setCompanyName(event?.target?.value);
  };
  const handleCompanyNumberChange = (event) => {
    setCompanyNumber(event?.target?.value);
  };

  const handleCompanyAddressChange = (event) => {
    setCompanyAddress(event?.target?.value);
  };

  const handleBankChange = (event) => {
    setBankName(event?.target?.value);
  };

  const handleAccountNumberChange = (event) => {
    setAccountNumber(event?.target?.value);
  };
  const handleSortCodeChange = (event) => {
    setSortCode(event?.target?.value);
  };

  async function saveBilling() {
    const dataModel = {
      contactID: billingContactID || null,
      name: companyName,
      firstName: firstName || null,
      lastName: lastName || null,
      emailAddress: emailAddress,
      bankAccountDetails: accountNumber,
      companyNumber: companyNumber,
      accountNumber: sortCode,
      isSupplier: true,
      isCustomer: false,
      addresses: [
        {
          addressType: 1,
          addressLine1: companyAddress,
          addressLine2: null,
          addressLine3: null,
          addressLine4: null,
          city: "",
          region: "",
          postalCode: "",
          country: "",
          attentionTo: null,
        },
      ],
    };

    const response = upsertBillingContact(dataModel);
    response.then((result) => {
      if (result?.contact.contactID) {
        showSuccess("Billing updated successfully.");
      } else {
        showError("Billing could not be updated.", true, false);
      }
    });
  }

  const { appData } = useLoaderData() || {};

  return (
    <AsyncRendererRouter
      resolve={appData}
      isLoading={isBillingLoading}
      loadingElement={
        <BillingLoadingComponent
          selectedMenuItem={selectedMenuItem}
          handleMenuItemClick={handleMenuItemClick}
        />
      }
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <SettingsSidebar
            selectedMenuItem={selectedMenuItem}
            handleMenuItemClick={handleMenuItemClick}
            onDarkModeChange={darkMode}
            features={features}
            pharmacyID={currentPharmacyId}
          />

          <div className="settings_content_wrapper">
            <div className="settings_heading">
              {isBillingLoading ? (
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.900" : "",
                  }}
                  height={15}
                  width={100}
                  animation="wave"
                />
              ) : (
                <h5>Billing</h5>
              )}
            </div>
            <div className="">
              <div className="settings_formFields AddNew_sectionbox billing-container">
                <div>
                  <h6>Bank Information</h6>
                </div>
                <div className="billing-fields">
                  <TextField
                    id="outlined-basic"
                    label="Company Name"
                    variant="outlined"
                    value={companyName}
                    fullwidth
                    onChange={handleCompanyChange}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Company Number"
                    variant="outlined"
                    value={companyNumber}
                    fullwidth
                    onChange={handleCompanyNumberChange}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Company Address"
                    variant="outlined"
                    value={companyAddress}
                    fullwidth
                    onChange={handleCompanyAddressChange}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Bank Name"
                    variant="outlined"
                    value={bankName}
                    fullwidth
                    onChange={handleBankChange}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Account Number"
                    variant="outlined"
                    value={accountNumber}
                    fullwidth
                    onChange={handleAccountNumberChange}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Sort Code"
                    variant="outlined"
                    value={sortCode}
                    fullwidth
                    onChange={handleSortCodeChange}
                  />
                </div>
                <div className="footer_btn">
                  <CustomButton label="Update" action={saveBilling} />
                </div>
              </div>

              <div className="settings_formFields AddNew_sectionbox billing-container">
                <div>
                  <h6>Invoices</h6>
                </div>
                <Invoices />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
