import { getObject } from "../getObjectValue";

class prescriptionItemLabelWarnings {
  constructor(response) {
    this.obj = response?.map((value) => {
      return {
        prescriptionItemLabelWarningId: getObject(
          value,
          "prescriptionItemLabelWarningId"
        ),
        prescriptionItemId: getObject(value, "prescriptionItemId"),
        warningText: getObject(value, "warningText"),
        createdOn: getObject(value, "createdOn"),
        createdBy: getObject(value, "createdBy"),
        updatedOn: getObject(value, "updatedOn"),
        prescriptionId: getObject(value, "updatedBy"),
      };
    });
  }
}

export default prescriptionItemLabelWarnings;
