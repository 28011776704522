import { useGridFilter } from "ag-grid-react";
import React, { useCallback } from "react";

export default ({model, onModelChange}) => {

    const valueChanged = useCallback( p => {
        const newValue = p.target.value;
        onModelChange(newValue == '' ? null : newValue);
    });

    const doesFilterPass = useCallback(
        ({ data }) => {
        const tagNames = data?.patientTags?.map((tag) => tag.tagName.toLowerCase());
        const modelText = model.toLowerCase();
        return tagNames?.some(tagName => tagName.includes(modelText));
    });

    const getModelAsString = useCallback(() => {
        return model;
    }, [model]);
 

    useGridFilter({doesFilterPass, getModelAsString});

    return <><input
    type="text"
    value={model || ''}
    onChange={valueChanged}
    /></>;
};