import React, { useCallback } from "react";

export default ({ model, onModelChange }) => {
  const valueChanged = useCallback(p => {
    const newValue = p.target.value;
    onModelChange(newValue === '' ? null : newValue);     
  });

  return (
    <div>
      <input
        type="text"
        className="tagFilter"
        id="tagFilterField"
        value={model || ""}
        onChange={valueChanged}
      />
    </div>
  );
};
