import React, { useState, useEffect } from "react";
import CustomLabel from "../../components/controls/CustomLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { builderObj } from "../../data/testChecks";
import {
  getClinicalServiceBuilderById,
  postClinicalServiceBuilder,
} from "../../services/ClinicalcheckService";
import "./../../assets/css/PatientForm/PatientForm.scss";
import setPatientModel from "../../Model/PatientForm/setPatientModel";
import { useLocation } from "react-router-dom";
import CustomLoader from "../../components/controls/CustomLoader";
import FormSection from "./FormSection";
import useTitanApp from "../../hooks/useTitanApp";
let ServicesBuilder;
let completed = false;

function PatientForm() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const clinicalServiceIdParam = searchParams.get("ServiceBuilderId");
  const [isLoading, setIsLoading] = useState(true);
  const [ServiceBuilderModel, setServiceBuilderModel] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const { appState } = useTitanApp();
  const { currentPharmacyId } = appState || {};
  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      try {
        ServicesBuilder =
          await getServicesByClinicalServicesBuilderTypeResponse(
            clinicalServiceIdParam
          );
        const res = new setPatientModel(
          ServicesBuilder,
          0,
          null,
          null,
          false,
          "",
          currentPharmacyId
        );
        setServiceBuilderModel(res);

        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    };
    fetch();
  }, []);
  async function SubmitForm() {
    setIsCompleted(false);
    completed = false;
    await CheckCompleted();
    if (completed === false) {
      const resModel = await CallpostClinicalServiceBuilder(
        ServiceBuilderModel
      );
    }
  }
  async function CheckCompleted() {
    ServiceBuilderModel?.stages[0]?.template?.templateSections?.map(
      (template) => {
        template?.userControls?.map((item) => {
          if (item.required === true) {
            if (item.value === "" || item.value === null) {
              if (item.type === 0 || item.type === 10 || item.type === 3) {
                setIsCompleted(true);
                completed = true;
              }
            }
          }
        });
      }
    );
  }
  async function CallpostClinicalServiceBuilder(model) {
    const response = await postClinicalServiceBuilder(model);
    return await getResponseMessage(response);
  }

  async function getServicesByClinicalServicesBuilderTypeResponse(Id) {
    const response = await getClinicalServiceBuilderById(Id);
    return await getResponseMessage(response);
  }
  const getResponseMessage = async (response) => {
    if (!response.ok) {
      // setErrorAndMessage();
    } else {
      return response.json().then((data) => {
        return data;
      });
    }
  };
  function ChangeModel(title, userControls, patientName) {
    const res = new setPatientModel(
      ServiceBuilderModel,
      0,
      title,
      userControls,
      false,
      patientName,
      currentPharmacyId
    );
    setServiceBuilderModel(res);
  }

  return (
    <>
      {isLoading === true ? (
        <CustomLoader />
      ) : (
        <div className="patient_form">
          <div className="main-block">
            <h1>Travel Health Risk Assessment Form</h1>

            {ServicesBuilder?.stages[0]?.template?.templateSections?.map(
              (item, index) => {
                return (
                  <FormSection
                    SectionTitle={item.sectionTitle}
                    UserControls={item.userControls}
                    handleChangeModel={ChangeModel}
                    stageStatus={false}
                    isCompleted={isCompleted}
                  />
                );
              }
            )}
            <div className="btn-div">
              <button className="btn-class" onClick={SubmitForm}>
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PatientForm;
