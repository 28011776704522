import React from "react";
import "./../../../../assets/css/Dashboard/PatientListView.scss";

const PatientSideFilter = (props) => {
  return (
    <div className="patient_tab_side_filters_container">
      <div className="patient_tab_side_filters">
        <span className="patient_tab_patient_count_span patientListHeading">
          Quick filters
        </span>
      </div>
      <div className="patient_tab_side_filters_box">
        <p className="patient_tab_side_filter_box_text patientListHeading">
          Moved away
        </p>
        <span className="itemCount">7</span>
      </div>

      <div className="patient_tab_side_filters_box">
        <p className="patient_tab_side_filter_box_text patientListHeading">
          Loss making
        </p>
        <span className="itemCount">16</span>
      </div>

      <div className="patient_tab_side_filters_box">
        <p className="patient_tab_side_filter_box_text patientListHeading">
          Patients App
        </p>
        <span className="itemCount">22</span>
      </div>
      <div className="patient_tab_side_filters_box" onClick={props.filterData}>
        <p className="patient_tab_side_filter_box_text patientListHeading">
          Active
        </p>
        <span className="itemCount">10</span>
      </div>
      <div className="patient_tab_side_filters_box">
        <p className="patient_tab_side_filter_box_text patientListHeading">
          Flu Eligible
        </p>
        <span className="itemCount">40</span>
      </div>
      <div className="patient_tab_side_filters_box">
        <p className="patient_tab_side_filter_box_text patientListHeading">
          Hypotension Eligible
        </p>
        <span className="itemCount">40</span>
      </div>
    </div>
  );
};

export default PatientSideFilter;
