import React, { useEffect, useState } from "react";
import config from "../../config";
import useTitanApp from "../../hooks/useTitanApp";
import SBProvider from "@sendbird/uikit-react/SendbirdProvider";
import SBConversation from "@sendbird/uikit-react/Channel";
import SBChannelList from "@sendbird/uikit-react/ChannelList";
import SBChannelSettings from "@sendbird/uikit-react/ChannelSettings";
import { sendBirdSelectors } from "@sendbird/uikit-react";
const { chatAppId } = config;

const Chat = () => {
  const { auth, appState } = useTitanApp();
  const { darkMode, isChatVisible } = appState || {};
  const { user } = auth || {};
  const [currentChannels, setCurrentChannels] = useState(false);
  const [currentChannelUrl, setCurrentChannelUrl] = useState("");
  const [showSettings, setShowSettings] = useState(false);
  const backToChanelsIcon = (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5.40234C12 5.48438 11.9844 5.5625 11.9531 5.63672C11.9219 5.70703 11.8789 5.76953 11.8242 5.82422C11.7695 5.87891 11.7051 5.92188 11.6309 5.95312C11.5605 5.98438 11.4844 6 11.4023 6H2.04492L5.82422 9.77344C5.94141 9.89062 6 10.0312 6 10.1953C6 10.2773 5.98438 10.3555 5.95312 10.4297C5.92188 10.5039 5.87891 10.5684 5.82422 10.623C5.77344 10.6777 5.71094 10.7207 5.63672 10.752C5.5625 10.7832 5.48438 10.7988 5.40234 10.7988C5.23438 10.7988 5.0918 10.7402 4.97461 10.623L0.175781 5.82422L0.158203 5.80664V5.80078C0.0527344 5.69531 0 5.5625 0 5.40234C0 5.23438 0.0585938 5.0918 0.175781 4.97461L4.97461 0.175781C5.0918 0.0585938 5.23438 0 5.40234 0C5.48438 0 5.56055 0.015625 5.63086 0.046875C5.70508 0.078125 5.76953 0.121094 5.82422 0.175781C5.87891 0.230469 5.92188 0.294922 5.95312 0.369141C5.98438 0.439453 6 0.515625 6 0.597656C6 0.765625 5.94141 0.908203 5.82422 1.02539L2.05078 4.79883H11.4023C11.4844 4.79883 11.5605 4.81445 11.6309 4.8457C11.7051 4.87695 11.7695 4.92188 11.8242 4.98047C11.8789 5.03516 11.9219 5.09961 11.9531 5.17383C11.9844 5.24414 12 5.32031 12 5.40234Z"
        className="icons-color"
        fill-opacity="0.8956"
      />
    </svg>
  );

  const goBackToChanels = () => {
    setCurrentChannels(false);
  };

  const handleBeforeUnload = (event) => {
    event.stopImmediatePropagation();

    if (isChatVisible === true) {
      sendBirdSelectors.getDisconnect(user?.email, null);
    }
  };

  useEffect(() => {    
    if (isChatVisible === true) {
      sendBirdSelectors.getConnect(user?.email, null);
      window.addEventListener("beforeunload", handleBeforeUnload);
    } else {
      sendBirdSelectors.getDisconnect(user?.email, null);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isChatVisible, user?.email]);

  return isChatVisible === true ? (
    <div className="ChatAppContainer">
      <SBProvider
        appId={chatAppId}
        userId={user?.email}
        theme={darkMode === true ? "dark" : "light"}
      >
        <div className="mobile-view__wrap">
          {!currentChannels ? (
            <div className="sendbird-app__channellist-wrap">
              <SBChannelList
                onChannelSelect={(channel) => {
                  if (channel && channel.url) {
                    setCurrentChannelUrl(channel.url);
                    setCurrentChannels(true);
                  }
                }}
                disableAutoSelect
              />
            </div>
          ) : (
            <div className="sendbird-app__conversation-wrap">
              <div className="back-button">
                <span onClick={goBackToChanels}>{backToChanelsIcon}</span>
              </div>
              <SBConversation
                channelUrl={currentChannelUrl}
                onChatHeaderActionClick={() => {
                  setShowSettings(true);
                }}
              />
              {showSettings && (
                <div className="sendbird-app__settingspanel-wrap">
                  <SBChannelSettings
                    channelUrl={currentChannelUrl}
                    onCloseClick={() => {
                      setShowSettings(false);
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </SBProvider>
    </div>
  ) : (
    <></>
  );
};

export default Chat;
