import moment from "moment";
export const calculateDaysAgo = (startDate) => {
  const minDate = moment("0001-12-12");
  const parsedInputDate = moment(startDate);
  const isDateBeforeMin = parsedInputDate.isSameOrBefore(minDate);
  let momentdate = moment(startDate);
  let isValidDate = momentdate?.isValid();
  let totalDays;
  if (isValidDate && !isDateBeforeMin) {
    totalDays = moment().diff(momentdate, "days");
  } else {
    totalDays = 0;
  }
  return totalDays;
};
