import React from "react";
import OrbitSettingsSidebar from "../../OrbitSettingsSidebar/OrbitSettingsSidebar";
import SetPassword from "./components/SetPassword";
import useTimer from "../../../hooks/useTimer";
function ChangePassword() {
  const { timer, isActive, handleStart, handleReset } = useTimer();

  return (
    <div className="settings_page">
      <div className="settings_wrapper">
        <OrbitSettingsSidebar />
        <div className="settings_content_wrapper">
          <div className="settings_heading">
            <h5>Change Password</h5>
          </div>
          <div className="AddNew_sectionbox">
            <SetPassword />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
