import React from "react";
import { ReactComponent as AddNewSvg } from "./../PatientDashboardIcons/addNew.svg";
import { ReactComponent as NHSIcon } from "./../PatientDashboardIcons/nhs.svg";
import { ReactComponent as BeforeIcon } from "./../PatientDashboardIcons/before.svg";
import { ReactComponent as DotIcon } from "./../PatientDashboardIcons/dot.svg";
import { ReactComponent as MenuSvg } from "./../../../pages/Dashboard/OverviewTab/OverviewIcons/menu.svg";
import { RxCheckCircled } from "react-icons/rx";

export default function Appointments({ appointments }) {
  return (
    <React.Fragment>
      <div className="tasks_header header_appointment">
        <h3 className="pharmacy_header_name">Appointments</h3>
        <AddNewSvg />
      </div>
      <div className="patient-detail-appointments">
        <div className="wrapper">
          {appointments?.map((appointment) => (
            <div className="appointment">
              <div className="left">
                <BeforeIcon className="beforeIcon" />
                <NHSIcon />
                <div className="appointment-info">
                  <p className="name">{appointment.name}</p>
                  <div className="dateandtime">
                    <p className="date">{appointment.date}</p>
                    <DotIcon />
                    <p className="time">{appointment.time}</p>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="status">
                  <RxCheckCircled className="icons-color" />
                  <p>{appointment.status}</p>
                </div>
                <MenuSvg className="icons-color" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}
