import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { MdOutlineUploadFile } from "react-icons/md";
import moment from "moment";

export default function AccreditationsSkillsFiles({
  Details,
  handleDocuments,
}) {
  const formatDate = (dateString) => {
    let date = "";
    if (dateString !== "") {
      let momentdate = moment(dateString);
      date = momentdate?.format("DD/MM/YYYY");
    }
    return date;
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function handleRemoveDocuments(docType) {
    handleDocuments(docType);
  }

  const FileViewer = ({ file }) => {
    if (!file) {
      return null;
    }
    const fileType = file?.fileType;

    if (fileType === "image") {
      return (
        <div onClick={() => handleCardClick(file?.document)}>
          {renderDocumentIcon(2)}
        </div>
      );
    } else if (fileType === "pdf") {
      return (
        <div onClick={() => handleCardClick(file?.document)}>
          {renderDocumentIcon(0)}
        </div>
      );
    } else if (fileType === "word") {
      return (
        <div onClick={() => handleCardClick(file?.document)}>
          {renderDocumentIcon(1)}
        </div>
      );
    } else {
      return <p>Unsupported file type</p>;
    }
  };
  const handleCardClick = async (base64Data) => {
    const response = await fetch(base64Data);
    const blob = await response?.blob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
    URL.revokeObjectURL(url);
  };

  const renderDocumentIcon = (type) => {
    switch (type) {
      case 0:
        return (
          <img
            src="https://www.svgrepo.com/download/144578/pdf.svg"
            className="pdf-icon"
            width={40}
            height={40}
          />
        );
      case 1:
        return (
          <img
            src="https://img.freepik.com/free-icon/word_318-674258.jpg?w=360"
            className="pdf-icon"
            width={40}
            height={40}
          />
        );
      case 2:
        return (
          <img
            src="https://winaero.com/blog/wp-content/uploads/2019/11/Photos-new-icon.png"
            className="pdf-icon"
            width={40}
            height={40}
          />
        );
      case 3:
        return (
          <img
            src="https://cdn-icons-png.flaticon.com/512/9159/9159105.png"
            className="pdf-icon"
            width={40}
            height={40}
          />
        );
      default:
        return (
          <img
            src="https://www.freeiconspng.com/uploads/text-file-icon-clip-art--royalty--32.png"
            className="pdf-icon"
            width={40}
            height={40}
          />
        );
    }
  };
  return (
    <div className="AddNew_sectionbox_Accreditations">
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        className="orbitSettings_accordion"
      >
        <AccordionSummary
          expandIcon={
            <IconButton>{expanded ? <RemoveIcon /> : <AddIcon />}</IconButton>
          }
        >
          <div className="accordion-titleBox">
            <div>
              <MdOutlineUploadFile size={30} />
              <p className="orbitSettings_accordionTitle">
                {Details?.documentType}
              </p>
            </div>
            <div className="title_meta">
              <p>
                <strong
                  className={
                    Details?.documentDetails?.isUploaded
                      ? "uploadedLabel"
                      : "notUploadedLabel"
                  }
                >
                  Status:{" "}
                  {Details?.documentDetails?.isUploaded
                    ? "Uploaded"
                    : "Not uploaded"}
                </strong>
              </p>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="settings_formFields">
            <div className="orbitSettings_calendarBox">
              <p>
                Issue Date:{" "}
                {formatDate(Details?.documentDetails?.issueDate || "")}
              </p>
            </div>

            <div className="orbitSettings_calendarBox">
              <p>
                Expiry Date:{" "}
                {formatDate(Details?.documentDetails?.expiryDate || "")}
              </p>
            </div>
          </div>
          <div className="orbitSettings_uploadBox">
            <p>Document</p>
            <div className="upload__image-wrapper upload_zone">
              {Details?.documentDetails != null ? (
                <div className="file_view_doc">
                  <FileViewer file={Details?.documentDetails} />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="add_button_doc_box">
            <button
              className="add_document"
              onClick={() => {
                handleRemoveDocuments(Details?.documentType);
              }}
            >
              Delete
            </button>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
