import { React, useState } from "react";

import "./../../assets/css/Team.css";

import Avatar from "react-initials-avatar";
import useTitanApp from "../../hooks/useTitanApp";
import { setCurrentTeam, setSelectedTeamIndex } from "../../dispatchers/appDispatchers";

export default function Team({
  handleCallback,
  handleCallbackTeamMembers,
  handleCallbackTeamNames,
  setClickedTeamIndex,
}) {
  const { appState } = useTitanApp();
  const { teams, currentTeamId } = appState || {};
  const [activeIndex, setActiveIndex] = useState(0);
  const handleTeam = (index) => {
    setClickedTeamIndex(index);
    setSelectedTeamIndex(index);
    setActiveIndex(index);
    const team = teams[index];
    if (team) {
      setCurrentTeam(team);
      handleCallback(team?.pharmacies);
      handleCallbackTeamMembers(team?.teamMembers);
      handleCallbackTeamNames(team);
    }
  };

  return (
    <div className="team_container">
      {teams?.map((team, index) => {
        return (
          <div
            className="team_wrapper"
            onClick={() => handleTeam(index)}
            key={index}
          >
            {
              <div
                className={`team_icon-box label-btn ${
                  currentTeamId === team?.teamID ? "selected_team" : ""
                } `}
              >
                {team.teamImage ? (
                  <img src={team.teamImage} />
                ) : (
                  <Avatar
                    name={team.teamName}
                    size={40}
                    fontSize={18}
                    className="para_color"
                  />
                )}
              </div>
            }

            <div className="team_icon-label label-btn">
              <div className="hover_team-img">
                {team?.teamImage ? (
                  <img src={team?.teamImage} />
                ) : (
                  <Avatar name={team?.teamName} size={40} fontSize={18} />
                )}
              </div>

              <p>{team?.teamName}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
