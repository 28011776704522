import React from "react";

export default function PatientControl({
  toggleSidebar,
  isSidebarOpen,
  onDarkModeChange,
  setIsListViewVisible,
}) {
  const handleClick = () => {
    toggleSidebar();
    setIsListViewVisible(false);
  };

  return (
    <div
      className={
        isSidebarOpen
          ? "actionIconGroup_iconBox_active"
          : "actionIconGroup_iconBox"
      }
      onClick={handleClick}
    >
      <svg
        width="15"
        height="15"
        viewBox="0 0 10 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.5 6.5C9.32843 6.5 10 7.17157 10 8V8.5C10 10.4714 8.14049 12.5 5 12.5C1.85951 12.5 0 10.4714 0 8.5V8C0 7.17157 0.671573 6.5 1.5 6.5H8.5ZM8.5 7.5H1.5C1.22386 7.5 1 7.72386 1 8V8.5C1 9.93765 2.43216 11.5 5 11.5C7.56784 11.5 9 9.93765 9 8.5V8C9 7.72386 8.77614 7.5 8.5 7.5ZM5 0C6.51878 0 7.75 1.23122 7.75 2.75C7.75 4.26878 6.51878 5.5 5 5.5C3.48122 5.5 2.25 4.26878 2.25 2.75C2.25 1.23122 3.48122 0 5 0ZM5 1C4.0335 1 3.25 1.7835 3.25 2.75C3.25 3.7165 4.0335 4.5 5 4.5C5.9665 4.5 6.75 3.7165 6.75 2.75C6.75 1.7835 5.9665 1 5 1Z"
          fill={
            isSidebarOpen || onDarkModeChange === true ? "#FFFFFF" : "#212121"
          }
        />
      </svg>
      <div className="actionIconGroup_iconName">
        <p>Patient Control</p>
      </div>
    </div>
  );
}
