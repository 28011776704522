import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

export default function TypesConfirmCancellation() {
  const [emailConfirmation, setEmailConfirmation] = React.useState("");
  const [emailCancellation, setEmailCancellation] = React.useState("");
  const [SMSConfirmation, setSMSConfirmation] = React.useState("");
  const [SMSCancellation, setSMSCancellation] = React.useState("");

  const handleEmailConfirmationChange = (event) => {
    setEmailConfirmation(event?.target?.value);
  };

  const handleEmailCancellationChange = (event) => {
    setEmailCancellation(event?.target?.value);
  };

  const handleSMSConfirmationChange = (event) => {
    setSMSConfirmation(event?.target?.value);
  };

  const handleSMSCancellationChange = (event) => {
    setSMSCancellation(event?.target?.value);
  };

  return (
    <>
      <div className="settings_formFields types_confirm_cancel">
        <div className="title_field">
          <FormControl fullwidth>
            <InputLabel id="demo-simple-select-filled-label">
              Email Confirmation
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={emailConfirmation}
              onChange={handleEmailConfirmationChange}
              className="title-select"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"Type 1"}>Type 1</MenuItem>
              <MenuItem value={"Type 2"}>Type 2</MenuItem>
              <MenuItem value={"Type 3"}>Type 3</MenuItem>
              <MenuItem value={"Type 4"}>Type 4</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="title_field">
          <FormControl fullwidth>
            <InputLabel id="demo-simple-select-filled-label">
              Email Cancellation
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={emailCancellation}
              onChange={handleEmailCancellationChange}
              className="title-select"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"Type 1"}>Type 1</MenuItem>
              <MenuItem value={"Type 2"}>Type 2</MenuItem>
              <MenuItem value={"Type 3"}>Type 3</MenuItem>
              <MenuItem value={"Type 4"}>Type 4</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="title_field">
          <FormControl fullwidth>
            <InputLabel id="demo-simple-select-filled-label">
              SMS Confirmation
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={SMSConfirmation}
              onChange={handleSMSConfirmationChange}
              className="title-select"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"1"}>1</MenuItem>
              <MenuItem value={"2"}>2</MenuItem>
              <MenuItem value={"3"}>3</MenuItem>
              <MenuItem value={"4"}>4</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="title_field">
          <FormControl fullwidth>
            <InputLabel id="demo-simple-select-filled-label">
              SMS Cancellation
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={SMSCancellation}
              onChange={handleSMSCancellationChange}
              className="title-select"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"1"}>1</MenuItem>
              <MenuItem value={"2"}>2</MenuItem>
              <MenuItem value={"3"}>3</MenuItem>
              <MenuItem value={"4"}>4</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </>
  );
}
