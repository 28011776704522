import axios from "axios";
import { logError } from "./logService";
import {
  showError,
  clearAll,
} from "../components/ToastedMessage/ToastedMessage";
import config from "../config";
const { localurl } = config;

const instance = axios.create();

instance.interceptors.request.use((config) => {
  const authToken = localStorage.getItem("authToken");
  config.baseURL = localurl;

  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }

  clearAll();
  return config;
});

instance.interceptors.response.use(
  (res) => {
    let result = res && res.data ? res.data : res;
    if (result?.success === false) {
      showError(
        result?.message || "Response result failed due to unsuccessful result."
      );
      return Promise.reject(result);
    } else {
      result = result?.response || null;
    }
    return Promise.resolve(result);
  },
  (error) => {
    logError(error);
    const respStatus = error.response?.status || -1;
    let errorMessage = "";
    switch (respStatus) {
      case 400:
        errorMessage =
          error.response?.data?.detail ||
          error.response?.data?.message ||
          error.response?.data?.title ||
          "validation errors occurred.";
        break;
      case 401:
        break;
      case 403:
        break;
      case 404:
        errorMessage = error.response?.data?.message || "Endpoint not found.";
        break;
      default:
        if (error?.code === "ERR_NETWORK") {
          errorMessage = "Network error or no internet connectivity.";
        } else {
          errorMessage = error?.message || "Unknown error occurred.";
        }
        break;
    }

    showError(errorMessage);
    return Promise.reject(error);
  }
);

const verbs = {
  httpGet: instance.get,
  httpPost: instance.post,
  httpPut: instance.put,
  httpDelete: instance.delete,
};

export const { httpGet, httpPost, httpPut, httpDelete } = verbs || {};
export default verbs;
