import React from "react";
import useTitanApp from "../../../../../hooks/useTitanApp";
import { getDocumentById } from "../../../../../dispatchers/documentDispatchers";

const CardItem = ({ documentType, title, DocumentId }) => {
  const { documentState } = useTitanApp();
  const { documentById } = documentState || {};

  const fetchData = async () => {
    try {
      await getDocumentDataById(DocumentId);

    } catch { }
  };

  async function getDocumentDataById(DocumentId) {
    const response = await getDocumentById(DocumentId);
    return await getResponseMessage(response);
  }

  const getResponseMessage = async (response) => {
    if (!response.ok) {
    } else {
      return response.json().then((body) => {
        return body;
      });
    }
  };

  const renderDocumentIcon = (type) => {
    switch (type) {
      case 0:
        return (
          <img
            src="https://www.svgrepo.com/download/144578/pdf.svg"
            className="pdf-icon"
            width={130}
            height={120}
          />
        );
      case 1:
        return (
          <img
            src="https://img.freepik.com/free-icon/word_318-674258.jpg?w=360"
            className="pdf-icon"
            width={130}
            height={120}
          />
        );
      case 2:
        return (
          <img
            src="https://winaero.com/blog/wp-content/uploads/2019/11/Photos-new-icon.png"
            className="pdf-icon"
            width={130}
            height={120}
          />
        );
      case 3:
        return (
          <img
            src="https://cdn-icons-png.flaticon.com/512/9159/9159105.png"
            className="pdf-icon"
            width={130}
            height={120}
          />
        );
      default:
        return (
          <img
            src="https://www.freeiconspng.com/uploads/text-file-icon-clip-art--royalty--32.png"
            className="pdf-icon"
            width={130}
            height={120}
          />
        );
    }
  };



  const handleCardClick = async () => {
    fetchData();
  
    if (documentById) {
      const response = await fetch(`data:application/octet-stream;base64,${documentById.docuemnt}`);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.download = documentById.documentName;
      link.click();
      URL.revokeObjectURL(url);
    }
  };
  

  return (
    <div className="document-container">
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="card"
        onClick={handleCardClick}
      >
        <div className="card-content">
          <div className="icon-container">
            {renderDocumentIcon(documentType)}
          </div>
          <div className="title-container">
            <span className="card-title icons-box">{title}</span>
          </div>
        </div>
      </a>
    </div>
  );
};

export default CardItem;
