import React from "react";
import "./../../../../assets/css/BottomActionBar.scss";
import HandleCount from "./../HandleCount";
import Lottie from "lottie-react";
import Sparkles from "./../../../../assets/Sparkles.json";
import { icons } from "react-icons/lib";
import clinicalImg from "./../../../../assets/images/clinical.png";

export default function Clinical({
  onMouseEnter,
  onMouseLeave,
  active,
  toggleClinical,
  disabled,
  totalClinicalCount,
  onDarkModeChange,
  setIsListViewVisible,
  isActive,
  onClick,
}) {
  const handleClinicalClick = () => {
    setIsListViewVisible(false);
  };
  return (
    <div
      className={`bottomBar_image-box ${active ? "active" : ""} ${
        isActive ? "fadeBottomActionIcons" : ""
      }`}
      onMouseEnter={() => onMouseEnter("Clinical")}
      onMouseLeave={() => onMouseLeave("")}
      onClick={onClick}
      style={{ pointerEvents: disabled ? "none" : "auto" }}
    >
      <HandleCount
        countValue={totalClinicalCount >= 0 ? totalClinicalCount : 0}
        active={active}
      />
      <div
        className={`bottomBar_image-container ${
          active ? "overflow-visible" : ""
        }`}
      >
        <img
          className={`bottomBar_img clinicalImg ${active ? "rotate" : ""}`}
          src={clinicalImg}
        />
        {active ? (
          <div className="sparklesAnimation">
            <Lottie animationData={Sparkles} />
          </div>
        ) : (
          ""
        )}
      </div>

      <p className="bottomBar_image-box-title">Clinical</p>
    </div>
  );
}
