import * as React from "react";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import CustomButton from "../controls/CustomButton";
import "./../../assets/css/SeverityModal.css";
import { ImWarning } from "react-icons/im";
import useTitanApp from "../../hooks/useTitanApp";
import { setMandatory } from "../../dispatchers/clinicalChecksDispatchers";
export default function SeverityModal() {
  const { clincicalChecksState } = useTitanApp();

  const { isMandatory } = clincicalChecksState || {};

  const navigate = useNavigate();
  const [show, setShow] = useState(isMandatory);
  const handleClose = () => {
    setMandatory(false);
  };

  const routeChangedashboard = () => {
    handleClose();
    navigate("/");
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <div className="clinical_modal">
          <Modal.Header>
            <ImWarning size={18} color={"red"} />
            <Modal.Title>Mandatory Checks</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Please check all mandatory checks for prescription
          </Modal.Body>
          <Modal.Footer>
            <CustomButton label="Close" action={handleClose} />
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}
