import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import "./../../../../../../assets/css/Dashboard/NewServiceStepsModal.scss";
import Modall from "react-bootstrap/Modal";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { BsCheckLg } from "react-icons/bs";
import { IoLinkOutline } from "react-icons/io5";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Switch, { SwitchProps } from "@mui/material/Switch";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { FaCopy } from "react-icons/fa";
import { BsPlusCircle } from "react-icons/bs";
import { BsTrash3 } from "react-icons/bs";
import QRCode from "qrcode.react";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/primereact.min.css";
import { addpharmacyService } from "../../../../../../dispatchers/pharmacyServiceDispatchers";
import useTitanApp from "../../../../../../hooks/useTitanApp";
// import { Switch } from "antd";

const steps = ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5", "Step 6"];

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "black",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function NewServiceStepsModal({
  open,
  handleClose,
  service,
  handleUpdateServices,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const qrCodeLink = "www.google.com";
  const { appState } = useTitanApp();
  const { currentPharmacyId } = appState || {};

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(qrCodeLink)
      .then(() => {
        console.log("Link copied successfully:", qrCodeLink);
      })
      .catch((error) => {
        console.error("Copying failed:", error);
      });
  };
  const [serviceChargeType, setServiceChargeType] = useState(1);
  const [serviceCost, setServiceCost] = useState("45.00");
  const [weeklySlots, setWeeklySlots] = useState([]);
  function resetControls() {
    setWeeklySlots([]);
    setServiceCost("45.00");
    setServiceChargeType(1);
    setActiveStep(0);
  }
  const schedulesArray = [
    { name: "09:00", code: "09:00" },
    { name: "10:00", code: "10:00" },
    { name: "11:00", code: "11:00" },
    { name: "12:00", code: "12:00" },
    { name: "13:00", code: "13:00" },
  ];

  const [durations, setDurations] = useState([
    {
      selectedSchedule1: null,
      selectedSchedule2: null,
    },
  ]);

  const addDuration = () => {
    setDurations([
      ...durations,
      { selectedSchedule1: null, selectedSchedule2: null },
    ]);
  };

  const deleteDuration = (index) => {
    const updatedDurations = [...durations];
    updatedDurations.splice(index, 1);
    setDurations(updatedDurations);
  };

  const handleDropdownChange = (
    e,
    index,
    dropdownNumber,
    selectedDayOfWeek
  ) => {
    const { value } = e || {};
    const updatedDurations = [...durations];

    if (dropdownNumber === 1) {
      updatedDurations[index].selectedSchedule1 = value.code;
    } else if (dropdownNumber === 2) {
      updatedDurations[index].selectedSchedule2 = value.code;
    }
    setDurations(updatedDurations);
    const [hours, minutes] = updatedDurations[index]?.selectedSchedule1
      ?.split(":")
      ?.map(Number);
    const startTime = new Date(0); // Date object with default epoch time
    startTime.setUTCHours(hours);
    startTime.setUTCMinutes(minutes);

    const [hours1, minutes1] = updatedDurations[index]?.selectedSchedule2
      ?.split(":")
      ?.map(Number);
    const endTime = new Date(0); // Date object with default epoch time
    endTime.setUTCHours(hours1);
    endTime.setUTCMinutes(minutes1);

    setWeeklySlots((prevSlots) =>
      prevSlots?.map((slot) =>
        slot?.dayOfWeek === selectedDayOfWeek
          ? {
              ...slot,
              slots: slot?.slots?.map((s, sIndex) =>
                sIndex === index
                  ? {
                      isAllDay: false,
                      startTime: startTime,
                      endTime: endTime,
                    }
                  : s
              ),
            }
          : slot
      )
    );
  };

  const ChangeWeeklySlots = (e, dayOfWeek, isAllDay) => {
    if (e.target.checked) {
      // Switch is turned on, add a new entry to weeklySlots
      setWeeklySlots((prevSlots) => [
        ...prevSlots,
        {
          dayOfWeek,
          slots: [
            {
              isAllDay,
              startTime: new Date(),
              endTime: new Date(),
            },
          ],
        },
      ]);
    } else {
      setWeeklySlots((prevSlots) =>
        prevSlots.filter((slot) => slot.dayOfWeek !== dayOfWeek)
      );
    }
  };
  const getResponseMessage = async (response) => {
    if (!response.ok) {
      console.log("error");
    } else {
      return response.json().then((body) => {
        return body;
      });
    }
  };
  async function CallPharmacyService(model) {
    const response = await addpharmacyService(currentPharmacyId, model);
    return response;
  }
  async function savePharmacyService() {
    const pharmacyObj = {
      serviceChargeType: serviceChargeType,
      serviceCost: serviceCost,
      qrurl: "none333",
      serviceID: service?.serviceID,
      serviceType: service?.serviceType,
      isEnabled: true,
      weeklySlots: weeklySlots,
    };

    const res = await CallPharmacyService(pharmacyObj);
    resetControls();
    handleClose();
    handleUpdateServices();
  }
  const stepContent = [
    <div className="stepContentOne">
      <div className="stepOneQuestion">
        <p className="Questionheading">
          How would you like to charge for this service?
        </p>
      </div>
      <div className="payment-choice">
        <div className="choiceBox" onClick={() => setServiceChargeType(1)}>
          <p>Subscription</p>
        </div>
        <div className="choiceBox" onClick={() => setServiceChargeType(2)}>
          <p>One off payment</p>
        </div>
      </div>
    </div>,
    <div className="stepContentTwo">
      <div className="stepTwoQuestion">
        <p className="Questionheading">How much will this service cost?</p>
      </div>
      <div className="costField">
        <input
          type="text"
          className="costInput"
          value={serviceCost}
          onChange={(e) => setServiceCost(e?.target?.value)}
        />
      </div>
    </div>,
    <div className="stepContentThree">
      <div className="stepThreeQuestion">
        <p className="Questionheading">When will this service be available?</p>
      </div>
      <div className="schedulesContainer">
        <div className="schedule">
          <Accordion
            className="schedule-accordian"
            expanded={weeklySlots.some((slot) =>
              slot.dayOfWeek === 1 ? true : false
            )}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="accordianHeaderContainer">
                <div className="switch">
                  <AntSwitch
                    defaultChecked={weeklySlots.some((slot) =>
                      slot.dayOfWeek === 1 ? true : false
                    )}
                    onChange={(e) => ChangeWeeklySlots(e, 1, false)}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </div>

                <div>
                  <p className="day">Monday</p>
                </div>

                <div className="range">
                  <div className="from">
                    <div className="time">
                      <p>09:00</p>
                    </div>
                    <div className="rangeIndicator">-</div>
                    <div className="time">
                      <p>13:00</p>
                    </div>
                  </div>
                  <div className="until">
                    <div className="time">
                      <p>15:00</p>
                    </div>
                    <div className="rangeIndicator">-</div>
                    <div className="time">
                      <p>18:00</p>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="scheduledetails">
                {durations?.map((duration, index) => (
                  <div className="duration" key={index}>
                    <div>
                      <Dropdown
                        value={duration.selectedSchedule1}
                        onChange={(e) => handleDropdownChange(e, index, 1, 1)}
                        options={schedulesArray}
                        optionLabel="name"
                        placeholder="Select time"
                        className="scheduleDropdown"
                      />
                    </div>
                    <div className="until-text">
                      <p>until</p>
                    </div>
                    <Dropdown
                      value={duration.selectedSchedule2}
                      onChange={(e) => handleDropdownChange(e, index, 2, 1)}
                      options={schedulesArray}
                      optionLabel="name"
                      placeholder="Select time"
                      className="scheduleDropdown"
                    />
                    <div className="icons-container">
                      {index === durations?.length - 1 ? (
                        <>
                          {durations?.length < 3 && (
                            <BsPlusCircle
                              className="addIcon"
                              onClick={addDuration}
                            />
                          )}
                          <FaCopy className="copyIcon" />
                        </>
                      ) : (
                        <BsTrash3
                          className="trashIcon"
                          onClick={() => deleteDuration(index)}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="schedule-accordian"
            expanded={weeklySlots.some((slot) =>
              slot.dayOfWeek === 2 ? true : false
            )}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="accordianHeaderContainer">
                <div className="switch">
                  <AntSwitch
                    defaultChecked={weeklySlots.some((slot) =>
                      slot.dayOfWeek === 2 ? true : false
                    )}
                    onChange={(e) => ChangeWeeklySlots(e, 2, false)}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </div>

                <div>
                  <p className="day">Tuesday</p>
                </div>

                <div className="range">
                  <div className="from">
                    <div className="time">
                      <p>09:00</p>
                    </div>
                    <div className="rangeIndicator">-</div>
                    <div className="time">
                      <p>13:00</p>
                    </div>
                  </div>
                  <div className="until">
                    <div className="time">
                      <p>09:00</p>
                    </div>
                    <div className="rangeIndicator">-</div>
                    <div className="time">
                      <p>13:00</p>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="scheduledetails">
                {durations?.map((duration, index) => (
                  <div className="duration" key={index}>
                    <div>
                      <Dropdown
                        value={duration.selectedSchedule1}
                        onChange={(e) => handleDropdownChange(e, index, 1, 2)}
                        options={schedulesArray}
                        optionLabel="name"
                        placeholder="Select time"
                        className="scheduleDropdown"
                      />
                    </div>
                    <div className="until-text">
                      <p>until</p>
                    </div>
                    <Dropdown
                      value={duration.selectedSchedule2}
                      onChange={(e) => handleDropdownChange(e, index, 2, 2)}
                      options={schedulesArray}
                      optionLabel="name"
                      placeholder="Select time"
                      className="scheduleDropdown"
                    />
                    <div className="icons-container">
                      {index === durations?.length - 1 ? (
                        <>
                          {durations?.length < 3 && (
                            <BsPlusCircle
                              className="addIcon"
                              onClick={addDuration}
                            />
                          )}
                          <FaCopy className="copyIcon" />
                        </>
                      ) : (
                        <BsTrash3
                          className="trashIcon"
                          onClick={() => deleteDuration(index)}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="schedule-accordian"
            expanded={weeklySlots.some((slot) =>
              slot.dayOfWeek === 3 ? true : false
            )}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="accordianHeaderContainer">
                <div className="switch">
                  <AntSwitch
                    defaultChecked={weeklySlots.some((slot) =>
                      slot.dayOfWeek === 3 ? true : false
                    )}
                    onChange={(e) => ChangeWeeklySlots(e, 3, true)}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </div>

                <div>
                  <p className="day">Wednesday</p>
                </div>

                <div className="range">
                  <div className="noSchedule">
                    <div className="time">
                      <p>All Day - Opening hours</p>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="scheduledetails">
                {durations?.map((duration, index) => (
                  <div className="duration" key={index}>
                    <div>
                      <Dropdown
                        value={duration.selectedSchedule1}
                        onChange={(e) => handleDropdownChange(e, index, 1, 3)}
                        options={schedulesArray}
                        optionLabel="name"
                        placeholder="Select time"
                        className="scheduleDropdown"
                      />
                    </div>
                    <div className="until-text">
                      <p>until</p>
                    </div>
                    <Dropdown
                      value={duration.selectedSchedule2}
                      onChange={(e) => handleDropdownChange(e, index, 2, 3)}
                      options={schedulesArray}
                      optionLabel="name"
                      placeholder="Select time"
                      className="scheduleDropdown"
                    />
                    <div className="icons-container">
                      {index === durations?.length - 1 ? (
                        <>
                          {durations?.length < 3 && (
                            <BsPlusCircle
                              className="addIcon"
                              onClick={addDuration}
                            />
                          )}
                          <FaCopy className="copyIcon" />
                        </>
                      ) : (
                        <BsTrash3
                          className="trashIcon"
                          onClick={() => deleteDuration(index)}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="schedule-accordian"
            expanded={weeklySlots.some((slot) =>
              slot.dayOfWeek === 4 ? true : false
            )}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="accordianHeaderContainer">
                <div className="switch">
                  <AntSwitch
                    defaultChecked={weeklySlots.some((slot) =>
                      slot.dayOfWeek === 4 ? true : false
                    )}
                    onChange={(e) => ChangeWeeklySlots(e, 4, true)}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </div>

                <div>
                  <p className="day">Thursday</p>
                </div>

                <div className="range">
                  <div className="noSchedule">
                    <div className="time">
                      <p>All Day - Opening hours</p>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="scheduledetails">
                {durations?.map((duration, index) => (
                  <div className="duration" key={index}>
                    <div>
                      <Dropdown
                        value={duration.selectedSchedule1}
                        onChange={(e) => handleDropdownChange(e, index, 1, 4)}
                        options={schedulesArray}
                        optionLabel="name"
                        placeholder="Select time"
                        className="scheduleDropdown"
                      />
                    </div>
                    <div className="until-text">
                      <p>until</p>
                    </div>
                    <Dropdown
                      value={duration.selectedSchedule2}
                      onChange={(e) => handleDropdownChange(e, index, 2, 4)}
                      options={schedulesArray}
                      optionLabel="name"
                      placeholder="Select time"
                      className="scheduleDropdown"
                    />
                    <div className="icons-container">
                      {index === durations?.length - 1 ? (
                        <>
                          {durations?.length < 3 && (
                            <BsPlusCircle
                              className="addIcon"
                              onClick={addDuration}
                            />
                          )}
                          <FaCopy className="copyIcon" />
                        </>
                      ) : (
                        <BsTrash3
                          className="trashIcon"
                          onClick={() => deleteDuration(index)}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="schedule-accordian"
            expanded={weeklySlots.some((slot) =>
              slot.dayOfWeek === 5 ? true : false
            )}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="accordianHeaderContainer">
                <div className="switch">
                  <AntSwitch
                    defaultChecked={weeklySlots.some((slot) =>
                      slot.dayOfWeek === 5 ? true : false
                    )}
                    onChange={(e) => ChangeWeeklySlots(e, 5, true)}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </div>

                <div>
                  <p className="day">Friday</p>
                </div>

                <div className="range">
                  <div className="noSchedule">
                    <div className="time">
                      <p>All Day - Opening hours</p>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="scheduledetails">
                {durations?.map((duration, index) => (
                  <div className="duration" key={index}>
                    <div>
                      <Dropdown
                        value={duration.selectedSchedule1}
                        onChange={(e) => handleDropdownChange(e, index, 1, 5)}
                        options={schedulesArray}
                        optionLabel="name"
                        placeholder="Select time"
                        className="scheduleDropdown"
                      />
                    </div>
                    <div className="until-text">
                      <p>until</p>
                    </div>
                    <Dropdown
                      value={duration.selectedSchedule2}
                      onChange={(e) => handleDropdownChange(e, index, 2, 5)}
                      options={schedulesArray}
                      optionLabel="name"
                      placeholder="Select time"
                      className="scheduleDropdown"
                    />
                    <div className="icons-container">
                      {index === durations?.length - 1 ? (
                        <>
                          {durations?.length < 3 && (
                            <BsPlusCircle
                              className="addIcon"
                              onClick={addDuration}
                            />
                          )}
                          <FaCopy className="copyIcon" />
                        </>
                      ) : (
                        <BsTrash3
                          className="trashIcon"
                          onClick={() => deleteDuration(index)}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disabled={true}
            className="schedule-accordian holiday-accordian"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="accordianHeaderContainer">
                <div className="switch">
                  <AntSwitch
                    onChange={(e) => ChangeWeeklySlots(e, 6)}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </div>

                <div>
                  <p className="day">
                    <s>Saturday</s>
                  </p>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails></AccordionDetails>
          </Accordion>
          <Accordion
            disabled={true}
            className="schedule-accordian holiday-accordian"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="accordianHeaderContainer">
                <div className="switch">
                  <AntSwitch
                    onChange={(e) => ChangeWeeklySlots(e, 0)}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </div>

                <div>
                  <p className="day">
                    <s>Sunday</s>
                  </p>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails></AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>,
    <div className="stepContentFour">
      <div className="stepFourQuestion">
        <p className="Questionheading">Does this apply to all sites?</p>
      </div>
      <div className="payment-choice">
        <div className="choiceBox">
          <p>Yes</p>
        </div>
        <div className="choiceBox">
          <p>No</p>
        </div>
      </div>
    </div>,
    <div className="stepContentFive">
      <div className="stepFiveQuestion">
        <p className="Questionheading">
          Please select all sites this new service will be applicable
        </p>
      </div>
      <div className="pharmacyList">
        <div className="pharmacy">
          <BsCheckLg className="checkIcon" />
          <p className="pharmacyName">Invatech Pharmacy</p>
          <p className="location">Bristol, UK</p>
        </div>
        <div className="pharmacy">
          <BsCheckLg className="checkIcon" />
          <p className="pharmacyName">Invatech Pharmacy</p>
          <p className="location">Bristol, UK</p>
        </div>
        <div className="pharmacy">
          <BsCheckLg className="checkIcon" />
          <p className="pharmacyName">Invatech Pharmacy</p>
          <p className="location">Bristol, UK</p>
        </div>
        <div className="pharmacy">
          <BsCheckLg className="checkIcon" />
          <p className="pharmacyName">Invatech Pharmacy</p>
          <p className="location">Bristol, UK</p>
        </div>
      </div>
    </div>,
    <div className="stepContentSix">
      <div className="stepSixQuestion">
        <p className="Questionheading">
          You’ve created your new service “Travel Vaccine”
        </p>
      </div>
      <div className="qrContainer">
        <div className="qrBox">
          {" "}
          <QRCode value={qrCodeLink} size={140} />
        </div>
      </div>
    </div>,
  ];
  const handleNext = () => {
    if (activeStep === steps?.length - 1) {
      handleClose();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Modall
      show={open}
      onHide={handleClose}
      backdrop="true"
      centered
      className="steps-Modal"
      dialogClassName="custom-modal-dialog"
    >
      <div className="modalContainer">
        <div className="modalHeadings">
          <p className="serviceName">{service?.clinicalServiceName}</p>
          <p className="userName">Titan PMR</p>
        </div>
        <div className="indicatorsContainer">
          {steps?.map((_, index) => (
            <div
              className={`stepIndicators ${
                index <= activeStep ? "active" : "inactive"
              }`}
              key={index}
            />
          ))}
        </div>
        <div className="stepContent">{stepContent[activeStep]}</div>
        <div
          className={`navButtons ${
            activeStep === 0 ? "firstStep" : "notFirstStep"
          }`}
        >
          {activeStep !== 0 && activeStep !== 5 && (
            <button className="navBtnbwd" onClick={handleBack}>
              <span>
                <IoIosArrowBack className="backwardIcon" />
              </span>
              <span className="btntext">Back</span>
            </button>
          )}
          {activeStep === 5 && (
            <button className="navBtnbwd" onClick={handleBack}>
              <span>
                <IoIosArrowBack className="backwardIcon" />
              </span>
              <span className="btntext">Edit</span>
            </button>
          )}
          {activeStep === steps?.length - 1 && (
            <button className="navBtnbwd" onClick={handleCopyLink}>
              <span>
                <IoLinkOutline className="backwardIcon" />
              </span>
              <span className="btntext">Copy Link</span>
            </button>
          )}
          <button
            className="navBtnfwd"
            variant="contained"
            color="primary"
            onClick={() =>
              activeStep === steps?.length - 1
                ? savePharmacyService()
                : handleNext()
            }
          >
            <span className="btntext">
              {activeStep === steps?.length - 1 ? "Done" : "Next"}
            </span>
            {activeStep !== 6 && (
              <span>
                <IoIosArrowForward className="forwardIcon" />
              </span>
            )}
          </button>
        </div>
      </div>
    </Modall>
  );
}
