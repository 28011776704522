import React, { useState } from "react";
import SettingsSidebar from "../../../../SettingsSidebar/SettingsSidebar";
import useTitanApp from "../../../../../hooks/useTitanApp";
import CustomLoader from "../../../../controls/CustomLoader";
import { MdAdd, MdOutlineModeEditOutline } from "react-icons/md";
import { useNavigate, useLoaderData } from "react-router-dom";
import AsyncRendererRouter from "../../../../AsyncRendererRouter/AsyncRendererRouter";
import MembersLoadingComponent from "../../../../SettingsLoadingComponents/MembersLoadingComponent";

export default function Members() {
  const { appState, memberState } = useTitanApp();
  const { membersData, isMemberLoading } = memberState || {};
  const { darkMode, features, currentPharmacyId } = appState || {};
  const navigate = useNavigate();

  const [selectedMenuItem, setSelectedMenuItem] = useState("Members");
  const [showSubMenuNav, setShowSubMenuNav] = useState(false);
  const [addNewSection, setAddNewSection] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };

  const handleAddNewMember = () => {
    navigate("/settings/teams/members/new");
  };
  const handleUpdateMember = async (value, member) => {
    navigate(`/settings/teams/members/${member?.emailAddress}`);
  };

  const { appData } = useLoaderData() || {};

  return (
    <AsyncRendererRouter
      isLoading={isMemberLoading}
      resolve={appData}
      loadingElement={
        <MembersLoadingComponent
          selectedMenuItem={selectedMenuItem}
          handleMenuItemClick={handleMenuItemClick}
          showSubMenuNav={showSubMenuNav}
          onDarkModeChange={darkMode}
          features={features}
          pharmacyID={currentPharmacyId}
        />
      }
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <SettingsSidebar
            selectedMenuItem={selectedMenuItem}
            handleMenuItemClick={handleMenuItemClick}
            showSubMenuNav={showSubMenuNav}
            onDarkModeChange={darkMode}
            features={features}
            pharmacyID={currentPharmacyId}
          />

          <div className="settings_content_wrapper">
            <div className="settings_heading">
              <h1>Members</h1>
            </div>
            <div
              className={
                addNewSection || isEdit
                  ? "members_wrapper"
                  : "settings_content_area members_wrapper"
              }
            >
              <div>
                <div>
                  <div className="allView_list_header">
                    <h3></h3>
                    <div className="addNew_cta" onClick={handleAddNewMember}>
                      <span>Add New Member</span>
                      <MdAdd />
                    </div>
                  </div>
                  <p></p>
                </div>
                <div className="allView_listview">
                  {membersData?.map((member, index) => (
                    <div className="allView_container" key={index}>
                      <div className="allView_title members_list_title">
                        <p className="allView_name">{`${member?.firstName} ${member?.lastName}`}</p>
                      </div>
                      <MdOutlineModeEditOutline
                        size={20}
                        className="icons-color member_edit_icon"
                        onClick={() => handleUpdateMember(true, member)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
