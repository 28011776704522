import { generateObjectId } from "../../utils/generateObjectId";
class FollowUp {
  constructor(
    StartDay,
    EndDay,
    title,
    Description,
    pharmacyId,
    ClinicalpatientId,
    clinicalServiceId,
    stageNo,
    serviceID
  ) {
    this._id = generateObjectId();
    this.title = title;

    this.Description = Description;
    this.Start = StartDay;
    this.End = EndDay;
    this.Icon = 0;
    this.CalendarEventStatus = 0;
    this.EntityId = clinicalServiceId;
    this.EntityType = 1;
    this.serviceID = serviceID;
    this.ClinicalServiceID = clinicalServiceId;
    this.PharmacyID = pharmacyId;
    this.PatientID = ClinicalpatientId;
    this.StageType = stageNo;
  }
}

export default FollowUp;
