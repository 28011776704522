import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";
import "./../../../../../assets/css/Dashboard/ServicesNewClinic.scss";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { icons } from "../../ServicesIcons/serviceIconsEnum";
import { ReactComponent as NHSIcon } from "../../ServicesIcons/nhs.svg";
import { ReactComponent as MessageIcon } from "../../ServicesIcons/message.svg";
import { ReactComponent as StethoIcon } from "../../ServicesIcons/stethoscope.svg";
import { ReactComponent as Titan } from "../../ServicesIcons/titan.svg";
import NewServiceStepsModal from "./Modals/NewServiceStepsModal";
import useTitanApp from "../../../../../hooks/useTitanApp";

import { TfiRuler } from "react-icons/tfi";

export default function ServicesNewClinic({ handleUpdateServices }) {
  const { pharmacyServiceState } = useTitanApp();
  const { currentServiceData } = pharmacyServiceState || {};
  const [value, setValue] = useState(0);
  const [selectedService, setSelectedService] = useState();
  const [openModal, setOpenModal] = useState(false);
  const profileImageUrl =
    "https://img.freepik.com/free-icon/man_318-157507.jpg";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleModalOpen = (clinic) => {
    setSelectedService(clinic);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const renderTabContent = () => {
    switch (value) {
      case 0:
        return (
          <div className="services_content-bg services_kanban">
            <NewServiceStepsModal
              open={openModal}
              handleClose={handleModalClose}
              service={selectedService}
              handleUpdateServices={handleUpdateServices}
            />
            <div className="popularServices">
              <p className="popularServicesHeading">Popular templates</p>
              <div className="innerServices">
                <div>
                  <div className="serviceCard">
                    <div>
                      <Card className="myCard" sx={{ minWidth: 438 }}>
                        <div className="cardHeading">New Medicine Service</div>
                        <div className="serviceIcon"></div>

                        <NHSIcon className="nhsserviceIcon" />
                      </Card>
                    </div>
                  </div>
                  <div className="lowerIconBar">
                    <div class="rounded-image">
                      <img src={profileImageUrl} alt="Profile Image" />
                    </div>
                    <div class="details">
                      <p className="createdBy">Created by</p>
                      <p className="createdByName">Titan PMR</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="serviceCard">
                    <div>
                      <Card className="myCard" sx={{ minWidth: 438 }}>
                        <div className="cardHeading">Earwax Removal</div>
                        <div className="serviceIcon"> </div>
                        <NHSIcon className="nhsserviceIcon" />
                      </Card>
                    </div>
                  </div>
                  <div className="lowerIconBar">
                    <div class="rounded-image">
                      <img src={profileImageUrl} alt="Profile Image" />
                    </div>
                    <div class="details">
                      <p className="createdBy">Created by</p>
                      <p className="createdByName">Titan PMR</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="serviceCard">
                    <div>
                      <Card className="myCard" sx={{ minWidth: 438 }}>
                        <div className="cardHeading">Viagra</div>
                        <div className="serviceIcon"></div>
                        <NHSIcon className="nhsserviceIcon" />
                      </Card>
                    </div>
                  </div>
                  <div className="lowerIconBar">
                    <div class="rounded-image">
                      <img src={profileImageUrl} alt="Profile Image" />
                    </div>
                    <div class="details">
                      <p className="createdBy">Created by</p>
                      <p className="createdByName">Titan PMR</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="popularServices nhs-section">
              <p className="popularServicesHeading">NHS Services</p>

              <div className="innerServices">
                {currentServiceData?.map((clinic, index) => {
                  switch (clinic.serviceType) {
                    case 1:
                      return (
                        <div key={index}>
                          <div
                            className={
                              clinic?.isEnabled
                                ? "serviceCard serviceCard_enabled"
                                : "serviceCard"
                            }
                            onClick={() => handleModalOpen(clinic)}
                          >
                            <div>
                              <Card className="myCard" sx={{ minWidth: 438 }}>
                                <div className="cardHeading">
                                  {clinic.clinicalServiceName}
                                </div>
                                <div className="serviceIcon">
                                  <img
                                    src={icons[clinic.clinicaServiceTypeId]}
                                  />
                                </div>
                                <div>
                                  <NHSIcon className="nhsserviceIcon" />
                                </div>
                                {clinic?.isEnabled ? (
                                  <div className="service_status">
                                    <span className="status_tag">
                                      installed
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </Card>
                            </div>
                          </div>
                          <div className="lowerIconBar">
                            <div class="rounded-image">
                              <img src={profileImageUrl} alt="Profile Image" />
                            </div>
                            <div class="details">
                              <p className="createdBy">Created by</p>
                              <p className="createdByName">Titan PMR</p>
                            </div>
                          </div>
                        </div>
                      );
                  }
                })}
              </div>
            </div>

            <div className="popularService privateMargin privateServices">
              <p className="popularServicesHeading">Private Services</p>
              <div className="innerServices">
                {currentServiceData?.map((clinic, index) => {
                  const backImage = icons[clinic.clinicaServiceTypeId];

                  switch (clinic.serviceType) {
                    case 2:
                      return (
                        <div key={index}>
                          <div
                            className="serviceCard"
                            onClick={() => handleModalOpen(clinic)}
                            style={{
                              backgroundImage: backImage
                                ? `url(${backImage})`
                                : "linear-gradient(140deg,rgba(0, 94, 184, 0.3),rgba(0, 94, 184, 0.1)",
                            }}
                          >
                            <div>
                              <Card className="myCard" sx={{ minWidth: 225 }}>
                                <div className="cardHeading">
                                  {clinic.clinicalServiceName}
                                </div>
                                <div className="serviceIconP">
                                  {/* <img
                                    src={icons[clinic.clinicaServiceTypeId]}
                                  /> */}
                                </div>
                                {clinic?.isEnabled ? (
                                  <div className="service_status">
                                    <span className="status_tag">
                                      installed
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </Card>
                            </div>
                          </div>
                          <div className="lowerIconBar">
                            <div class="rounded-image">
                              <img src={profileImageUrl} alt="Profile Image" />
                            </div>
                            <div class="details">
                              <p className="createdBy">Created by</p>
                              <p className="createdByName">Titan PMR</p>
                            </div>
                          </div>
                        </div>
                      );
                  }
                })}
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="services_content-bg services_kanban">
            <NewServiceStepsModal
              open={openModal}
              handleClose={handleModalClose}
              service={selectedService}
              handleUpdateServices={handleUpdateServices}
            />
            <div className="popularServices">
              <p className="popularServicesHeading">NHS Services</p>

              <div className="innerServices">
                {currentServiceData?.map((clinic, index) => {
                  switch (clinic.serviceType) {
                    case 1:
                      return (
                        <div key={index}>
                          <div
                            className={
                              clinic?.isEnabled
                                ? "serviceCard serviceCard_enabled"
                                : "serviceCard"
                            }
                            onClick={() => handleModalOpen(clinic)}
                          >
                            <div>
                              <Card className="myCard" sx={{ minWidth: 438 }}>
                                <div className="cardHeading">
                                  {clinic.clinicalServiceName}
                                </div>
                                <div className="serviceIcon">
                                  <img
                                    src={icons[clinic.clinicaServiceTypeId]}
                                  />
                                </div>
                                <NHSIcon className="nhsserviceIcon" />
                              </Card>
                            </div>
                          </div>
                          <div className="lowerIconBar">
                            <div class="rounded-image">
                              <img src={profileImageUrl} alt="Profile Image" />
                            </div>
                            <div class="details">
                              <p className="createdBy">Created by</p>
                              <p className="createdByName">Titan PMR</p>
                            </div>
                          </div>
                        </div>
                      );
                  }
                })}
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="services_content-bg services_kanban privateServices">
            <NewServiceStepsModal
              open={openModal}
              handleClose={handleModalClose}
              service={selectedService}
              handleUpdateServices={handleUpdateServices}
            />
            <div className="popularService">
              <p className="popularServicesHeading">Private Services</p>
              <div className="innerServices">
                {currentServiceData?.map((clinic, index) => {
                  const backImage = icons[clinic.clinicaServiceTypeId];
                  switch (clinic.serviceType) {
                    case 2:
                      return (
                        <div key={index}>
                          <div
                            className="serviceCard"
                            onClick={() => handleModalOpen(clinic)}
                            style={{
                              backgroundImage: backImage
                                ? `url(${backImage})`
                                : "linear-gradient(140deg,rgba(0, 94, 184, 0.3),rgba(0, 94, 184, 0.1)",
                            }}
                          >
                            <div>
                              <Card className="myCard" sx={{ minWidth: 225 }}>
                                <div className="cardHeading">
                                  {clinic.clinicalServiceName}
                                </div>
                                <div className="serviceIconP">
                                  {/* <img
                                    src={icons[clinic.clinicaServiceTypeId]}
                                  /> */}
                                </div>
                                {clinic?.isEnabled ? (
                                  <div className="service_status">
                                    <span className="status_tag">
                                      installed
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </Card>
                            </div>
                          </div>
                          <div className="lowerIconBar">
                            <div class="rounded-image">
                              <img src={profileImageUrl} alt="Profile Image" />
                            </div>
                            <div class="details">
                              <p className="createdBy">Created by</p>
                              <p className="createdByName">Titan PMR</p>
                            </div>
                          </div>
                        </div>
                      );
                  }
                })}
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };
  return (
    <>
      <div className="newClinicsContainer">
        <div className="newClinicsContainerWrapper">
          <div className="tabs_box services_container-tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Tabs"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "black",
                },
              }}
            >
              <Tab
                // icon={kanbanIcon}
                iconPosition="start"
                label={<span className={`tabs_text`}>All</span>}
              />
              <Tab
                // icon={listviewIcon}
                iconPosition="start"
                label={<span className={`tabs_text`}>NHS</span>}
              />

              <Tab
                // icon={calendarIcon}
                iconPosition="start"
                label={<span className={`tabs_text`}>Private</span>}
              />
            </Tabs>
          </div>
        </div>
      </div>
      <div></div>
      <div className="services_container-content-area">
        {renderTabContent()}
      </div>
    </>
  );
}
