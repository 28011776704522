import React, { useState, useEffect } from "react";
import ServicesSidebar from "./ServicesSidebar/ServicesSidebar";
import ServicesContainer from "./ServicesContainer/ServicesContainer";
import "./../../../assets/css/Dashboard/ServicesTab.scss";
import useTitanApp from "../../../hooks/useTitanApp";
import { useParams } from "react-router-dom";
import {
  setCurrentServiceId,
  setServiceTab,
  setShowCalender,
  setServicesNameAndIcons,
  getServiceProgress,
} from "../../../dispatchers/pharmacyServiceDispatchers";
import commonData from "../../../data/commonData";
import ServiceDetails from "./ServicesContainer/ServicesAnalytics/components/ServiceClaimStatus/components/ServiceDetails";

export default function ServicesSection({ serviceType }) {
  //Sidebar Work
  const { serviceIcons } = commonData || {};

  const params = useParams();
  const { pharmacyServicesTabLoader, pharmacyServiceState } = useTitanApp();
  const { currentServiceData, sidebarVisible } = pharmacyServiceState || {};
  useEffect(() => {
    if (currentServiceData?.length === 1) {
      setUITabs(currentServiceData[0]?.serviceID);
      getServiceProgress(currentServiceData[0]?.serviceID, params.pharmacyId);
      const serviceIcon = serviceIcons[currentServiceData[0]?.name];
      setServicesNameAndIcons(
        currentServiceData[0]?.name,
        serviceIcon,
        currentServiceData[0]?.serviceID
      );
    }
    setServiceTab(
      serviceType === "ListView" ? 1 : serviceType === "Calendar" ? 2 : 0
    );
  }, [serviceType]);

  async function setUITabs(id) {
    setServiceTab(0);
    setCurrentServiceId(id);
    setShowCalender(true);
  }

  async function handleUpdateServices() {
    pharmacyServicesTabLoader({ params });
  }

  return (
    <>
      <div className="services_tab">
        <ServicesSidebar />
        <ServicesContainer handleUpdateServices={handleUpdateServices} />
      </div>
      {sidebarVisible && <ServiceDetails />}
    </>
  );
}
