import React from "react";
import PharmacyControl from "./components/PharmacyControl";
import TeamControl from "./components/TeamControl";
import DeliveryControl from "./components/DeliveryControl";
import PatientControl from "./components/PatientControl";
import PatientsFilter from "../../../PatientsFilter/PatientsFilter";
import { useState } from "react";

export default function ActionIconGroup({
  toggleSidebar,
  isSidebarOpen,
  onDarkModeChange,
  setIsListViewVisible,
}) {
  return (
    <div className="actionIconGroup">
      <PharmacyControl onDarkModeChange={onDarkModeChange} />
      <TeamControl onDarkModeChange={onDarkModeChange} />
      <DeliveryControl onDarkModeChange={onDarkModeChange} />
      <PatientControl
        toggleSidebar={toggleSidebar}
        isSidebarOpen={isSidebarOpen}
        onDarkModeChange={onDarkModeChange}
        setIsListViewVisible={setIsListViewVisible}
      />
    </div>
  );
}
