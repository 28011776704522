import React, { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "./../../assets/css/Settings/SettingsSidebar.scss";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { GoArrowLeft } from "react-icons/go";
import {
  TbBell,
  TbLocation,
  TbUsers,
  TbUserCog,
  TbSettings,
  TbUser,
  TbTemplate,
  TbCurrencyPound,
} from "react-icons/tb";
import { FaAlignJustify, FaTimes } from "react-icons/fa";
import useTitanApp from "../../hooks/useTitanApp";

export default function SettingsSidebar() {
  const { appState } = useTitanApp();
  const { features, currentPharmacyId, isOrbitUser } = appState || {};
  const {
    ifSettingMinimal,
    ifMapEnabled,
    ifPharmacyOverviewEnabled,
    ifPatientListEnabled,
  } = features || {};

  const location = useLocation();

  const isMenuItemActive = (link) => {
    return location.pathname === link || location.pathname.startsWith(link);
  };

  const navigate = useNavigate();

  const [showResponsiveSidebar, setShowResponsiveSidebar] = useState(false);

  const handleShowSidebar = () => {
    if (window.innerWidth < 800) {
      setShowResponsiveSidebar(!showResponsiveSidebar);
    }
  };

  const handleGoBack = () => {
    if (ifMapEnabled === false) {
      if (ifPharmacyOverviewEnabled === true) {
        navigate(`/pharmacyoverview/${currentPharmacyId}`, {
          replace: true,
        });
      } else if (ifPatientListEnabled === true) {
        navigate(`/pharmacypatient/${currentPharmacyId}`, {
          replace: true,
        });
      } else {
        navigate(`/pharmacyservices/${currentPharmacyId}`, {
          replace: true,
        });
      }
    } else {
      navigate("/", {
        replace: true,
      });
    }
  };

  return (
    <div>
      <div
        className={`sidebarSettings_wrapper ${
          showResponsiveSidebar ? "show-sidebar" : ""
        }`}
      >
        <div className="settings_breadcrumb" onClick={handleGoBack}>
          <GoArrowLeft className="icons-color" />

          <p className="breadCrumb_title">Back to Dashboard</p>
        </div>

        <Sidebar className="settings_sidebar">
          <Menu>
            <MenuItem
              component={<Link to="/settings/general"></Link>}
              className={`no-submenu ${
                isMenuItemActive("/settings/general")
                  ? "settings-sidebar-selected"
                  : ""
              }`}
              icon={<TbBell size={20} />}
            >
              General
            </MenuItem>
            {!ifSettingMinimal && (
              <MenuItem
                component={<Link to="/settings/locations"></Link>}
                className={`no-submenu ${
                  isMenuItemActive("/settings/locations")
                    ? "settings-sidebar-selected"
                    : ""
                }`}
                icon={<TbLocation size={20} />}
              >
                Locations
              </MenuItem>
            )}
            {isOrbitUser && (
              <MenuItem
                component={<Link to="/settings/billing"></Link>}
                className={`no-submenu ${
                  isMenuItemActive("/settings/billing")
                    ? "settings-sidebar-selected"
                    : ""
                }`}
                icon={<TbCurrencyPound size={20} />}
              >
                Billing
              </MenuItem>
            )}
            <SubMenu label="Teams" defaultOpen={true} className="submenu-title">
              <MenuItem
                component={<Link to="/settings/teams/members"></Link>}
                icon={<TbUsers size={20} />}
                className={
                  isMenuItemActive("/settings/teams/members")
                    ? "settings-sidebar-selected"
                    : ""
                }
              >
                Members
              </MenuItem>
              {!ifSettingMinimal && (
                <MenuItem
                  component={<Link to="/settings/teams/roles"></Link>}
                  icon={<TbUserCog size={22} />}
                  className={
                    isMenuItemActive("/settings/teams/roles")
                      ? "settings-sidebar-selected"
                      : ""
                  }
                >
                  Roles
                </MenuItem>
              )}
            </SubMenu>
            {!ifSettingMinimal && (
              <SubMenu
                label="Services"
                defaultOpen={true}
                className="submenu-title"
              >
                <MenuItem
                  component={<Link to="/settings/services/types"></Link>}
                  icon={<TbSettings size={20} />}
                  className={
                    isMenuItemActive("/settings/services/types")
                      ? "settings-sidebar-selected"
                      : ""
                  }
                >
                  Types
                </MenuItem>
              </SubMenu>
            )}
            {!ifSettingMinimal && (
              <SubMenu
                label="Template Forms"
                defaultOpen={true}
                className="submenu-title"
              >
                <MenuItem
                  component={
                    <Link to="/settings/templates/consultation"></Link>
                  }
                  icon={<TbUser size={22} />}
                  className={
                    isMenuItemActive("/settings/templates/consultation")
                      ? "settings-sidebar-selected"
                      : ""
                  }
                >
                  Consultation
                </MenuItem>
              </SubMenu>
            )}
            {!ifSettingMinimal && (
              <SubMenu
                label="Communication"
                defaultOpen={true}
                className="submenu-title"
              >
                <MenuItem icon={<TbTemplate size={20} />}>
                  SMS & Email Settings
                </MenuItem>
              </SubMenu>
            )}
          </Menu>
        </Sidebar>
      </div>
      <div className="sidebar-hamburger" onClick={handleShowSidebar}>
        {showResponsiveSidebar ? <FaTimes /> : <FaAlignJustify />}
      </div>
    </div>
  );
}
