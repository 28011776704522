import React from "react";

function CustomServiceToolbarButton({ action, label, iconComponent }) {
  return (
    <div className="service-toolbar-button" onClick={action}>
      {iconComponent}
      {label}
    </div>
  );
}

export default CustomServiceToolbarButton;
