import React, { useCallback, useState } from "react";
import { useGridFilter } from "ag-grid-react";

export default ({ model, onModelChange  }) => {
    
    const handleCheckboxChange = (event) => {
      onModelChange(event?.target?.checked ? true : null);
    };

    const doesFilterPass = useCallback(
        (node) => {
          const rowData = node?.data;
          return model ? rowData?.sendToOrbit : true; 
        },
        [model]
      );


  useGridFilter({ doesFilterPass});

  return (
    <div>
      <label>
        <input
            type="checkbox"
            checked={model}
            onChange={handleCheckboxChange}
        />
        Orbit Flag
      </label>
    </div>
  );
};
