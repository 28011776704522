import { queryByAltText } from "@testing-library/react";

class PrescriptionNotes {
  constructor(response, Type, PrescriptionIncomingNoteId, TypeValue) {
    this.obj = response?.map((value) => {
      return {
        ProfessionalName: "Titan Admin",
        PrescriptionIncomingNoteId: value.PrescriptionIncomingNoteId,
        PrescriptionIncomingId: value.PrescriptionIncomingId,
        Note: value.Note,
        AwaitingQuery:
          Type === "AwaitingQuery"
            ? value.PrescriptionIncomingNoteId === PrescriptionIncomingNoteId
              ? TypeValue
              : value.AwaitingQuery
            : value.AwaitingQuery,
        ArchiveDate: value.ArchiveDate,
        PinNote:
          Type === "PinNote"
            ? value.PrescriptionIncomingNoteId === PrescriptionIncomingNoteId
              ? TypeValue
              : value.PinNote
            : value.PinNote,
        ShowOnHandout:
          Type === "ShowOnHandout"
            ? value.PrescriptionIncomingNoteId === PrescriptionIncomingNoteId
              ? TypeValue
              : value.ShowOnHandout
            : value.ShowOnHandout,
        CreatedOn: value.CreatedOn,
      };
    });
  }
}

export default PrescriptionNotes;
