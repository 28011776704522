import { dispatch, getDataFromStore } from "../store/store";
import { logError } from "../services/logService";

import {
  getServicesByPharmacyIdData,
  addRoomtData,
  setIsLocationLoading,
} from "../features/locationSlice/locationSlice";

const reducerName = "location";

// #region State Setters
export const setLocationLoading = (isLoading) => {
  dispatch(setIsLocationLoading(isLoading));
};


// #endregion

// #region API getter/caller functions for components usage to dispatch REDUX extra reducers
export const getServicesByPharmacyId = async (pharmacyId) => {
  let result = [];
  try {
    result = await dispatch(getServicesByPharmacyIdData(pharmacyId)).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const addRoom = async (data) => {
  let result = null;

  try {
    result = await dispatch(addRoomtData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};
// #endregion
