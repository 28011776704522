import { getObject } from "../getObjectValue";
class audit {
  constructor(response) {
    this.obj = response?.map((value) => {
      return {
        prescriptionAuditId: getObject(value, "prescriptionAuditId"),
        prescriptionId: getObject(value, "prescriptionId"),
        prescriptionAuditType: getObject(value, "prescriptionAuditType"),
        value: getObject(value, "value"),
      };
    });
  }
}

export default audit;
