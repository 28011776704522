import { dispatch, getDataFromStore } from "../store/store";
import {
  setCurrentTotalCount,
  setCurrentTotalPrice,
  setCurrentOpenSuccessDialog,
  updateOrbitSession,
  setCurrentAnimationPlaying,
  setCurrentOrbitAnimation,
} from "../dispatchers/orbitDispatchers";
import { showError } from "../components/ToastedMessage/ToastedMessage";
const reducerName = "orbit";

export async function handleUpdateSession() {
  var result = false;
  setCurrentOrbitAnimation(false);
  const formattedDateEndDate = new Date().toISOString();
  const session = localStorage.getItem("sessionData");
  const sessionData = JSON.parse(session);
  const startTimeObj = getDataFromStore(reducerName, "startTimeObj");
  const endTimeObj = {
    _id: startTimeObj !== null ? startTimeObj?._id : sessionData?._id,
    userId: startTimeObj !== null ? startTimeObj?.userId : sessionData?.userId,
    userFullName:
      startTimeObj !== null
        ? startTimeObj?.userFullName
        : sessionData?.userFullName,
    userEmailAddress:
      startTimeObj !== null
        ? startTimeObj?.userEmailAddress
        : sessionData?.userEmailAddress,
    totalNMS:
      startTimeObj !== null ? startTimeObj?.totalNMS : sessionData?.totalNMS,
    price: startTimeObj !== null ? startTimeObj?.price : sessionData?.price,
    startTime:
      startTimeObj !== null ? startTimeObj?.startTime : sessionData?.startTime,
    endTime: formattedDateEndDate,
    actions: [],
    totalSkips: sessionData?.totalSkips ? sessionData?.totalSkips : 0,
  };
  if (endTimeObj) {
    var data = await updateOrbitSession(endTimeObj);
    setCurrentAnimationPlaying(true);
    setCurrentAnimationPlaying(false);
    localStorage.removeItem("sessionData");
    result = true;
  } else {
    showError("Something went wrong!", false, true);
  }
  return result;
}
export function closeOrbitEndDialog() {}
