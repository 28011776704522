import React, { useState } from "react";
import useTitanApp from "../../../hooks/useTitanApp";
import { IoClose } from "react-icons/io5";
import { PiPlayBold, PiStopBold } from "react-icons/pi";
import { motion } from "framer-motion";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import { useStopwatch } from "react-timer-hook";
import { setIsOrbitSession } from "../../../dispatchers/appDispatchers";
import { saveOrbitSessionDetail } from "../../../dispatchers/pharmacyServiceDispatchers";
import { v4 as uuidv4 } from "uuid";
const OrbitSession = () => {
  const { orbitState, pharmacyServiceState, appState } = useTitanApp();
  const { currentPharmacyId } = appState || {};
  const { orbitSessionDetail } = pharmacyServiceState || {};
  const { seconds, minutes, start, reset } = useStopwatch();
  const [selectedTime, setSelectedTime] = useState(null);
  const [isStart, setStart] = useState(false);

  const handleTimeChange = (event) => {
    setSelectedTime(event?.target?.value);
  };
  const closeWindow = () => {
    setIsOrbitSession(false);
    setStart(false);
  };
  const addOrbitSessionData = () => {
    setStart(true);
    const todayDate = new Date();
    todayDate.setHours(todayDate.getHours() + selectedTime);
    const endTime = todayDate.toISOString();
    const addOrbitSession = {
      pharmacyId: currentPharmacyId,
      sessionStartTime: new Date().toISOString(),
      sessionEndTime: endTime,
    };
    saveOrbitSessionDetail(addOrbitSession).then((data) => {
      return data;
    });
  };

  const updateSessionsDetail = () => {
    setStart(false);
    const updateOrbitSession = {
      _id: orbitSessionDetail?.orbitSession?._id,
      pharmacyId: orbitSessionDetail?.orbitSession?.pharmacyID,
      sessionStartTime: orbitSessionDetail?.orbitSession?.sessionStartTime,
      sessionEndTime: orbitSessionDetail?.orbitSession?.sessionEndTime,
      isActive: false,
    };

    saveOrbitSessionDetail(updateOrbitSession).then((data) => {
      return data;
    });
    closeWindow();
  };

  return (
    <Modal open={true}>
      <div className="orbit_session_contaier">
        <div className="overlay">
          <div className="popup">
            <div
              className="close-icon"
              onClick={() => {
                closeWindow();
              }}
            >
              <IoClose className="icons-color" size={24} />
            </div>
            <div className="session_heading_container">
              <h2>Start Session</h2>
            </div>
            <div className="orbit_session_main_container">
              <div className="orbit_info_container">
                <div className="orbit_info">
                  <p>Rx Rate</p>
                  <span>{orbitSessionDetail?.orbitSessionRate?.rxRate}</span>
                </div>
                <div className="orbit_info">
                  <p>Nms Rate</p>
                  <span>{orbitSessionDetail?.orbitSessionRate?.nmsRate}</span>
                </div>
              </div>
              <div className="orbit_end_time_container">
                <FormControl fullwidth className="template_input">
                  <InputLabel id="demo-simple-select-filled-label">
                    End Time
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={selectedTime}
                    onChange={handleTimeChange}
                    className="title-select"
                    disabled={isStart}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={1}>1 hour</MenuItem>
                    <MenuItem value={2}>2 hours</MenuItem>
                    <MenuItem value={3}>3 hours</MenuItem>
                    <MenuItem value={4}>4 hours</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="orbit_action_btn">
              <motion.div
                className="orbit_playStartPause"
                initial={{ borderRadius: "50%" }}
                animate={{ borderRadius: !isStart ? "30px" : "50%" }}
                whileHover={{ scale: 1.2, borderRadius: "30px" }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                onClick={(e) => {
                  setStart(!isStart);
                }}
              >
                {isStart || orbitSessionDetail?.orbitSession != null ? (
                  <div
                    className="orbit_btn orbit_stopBtn"
                    onClick={() => {
                      updateSessionsDetail();
                      reset();
                    }}
                  >
                    <PiStopBold color="#fff" size={18} />
                    {orbitSessionDetail?.orbitSession == null ? (
                      <p className="orbit_timer">
                        {String(minutes).padStart(2, "0")}:
                        {String(seconds).padStart(2, "0")}
                      </p>
                    ) : (
                      <p>Stop</p>
                    )}
                  </div>
                ) : (
                  <div
                    className="orbit_btn orbit_startBtn"
                    onClick={() => {
                      addOrbitSessionData();
                      start();
                    }}
                  >
                    <PiPlayBold color="#fff" size={18} />
                    {!isStart && <p>Start</p>}
                  </div>
                )}
                {/* ------------------------------------------------------------------------- */}
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OrbitSession;
