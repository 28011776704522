import moment from "moment";

export const getStartDateForStages = (service, stageName) => {
  let startDate = null;

  service?.stages?.map((currentStage, index) => {
    const currentStageName = stageName;
    if (currentStage?.stageName === currentStageName) {
      startDate = currentStage?.startDate;
      return;
    }
  });
  let momentdate = moment(
    startDate === null ? service?.clinicalServiceDate : startDate
  );
  let isValidDate = momentdate?.isValid();
  let date;
  if (isValidDate) {
    date = momentdate?.format("YYYY-MM-DD HH:mm Z");
  } else {
    date = "Invalid date";
  }
  return date;
};

export const getTotalAttemptsForStages = (service, stageName) => {
  let totalAttempts = 0;

  service?.stages?.map((currentStage, index) => {
    const currentStageName = stageName;
    if (currentStage?.stageName === currentStageName) {
      totalAttempts = currentStage?.totalAttempts;
      return;
    }
  });
  return totalAttempts;
};

export const getTotalDaysLeft = (service, stageName) => {
  let startDate = null;

  service?.stages?.map((currentStage, index) => {
    const currentStageName = stageName;
    if (currentStage?.stageName === currentStageName) {
      startDate = currentStage?.startDate;
      return;
    }
  });
  let momentdate = moment(
    startDate === null ? service?.clinicalServiceDate : startDate
  );
  let isValidDate = momentdate?.isValid();
  let totalDays;
  if (isValidDate) {
    const today = moment();
    const daysDifference = momentdate?.diff(today, "days");
    if (daysDifference < 0) {
      totalDays = "Overdue";
    } else {
      totalDays = `${daysDifference} days left`;
    }
  } else {
    totalDays = "";
  }
  return totalDays;
};

export const getDueName = (dueType) => {
  let dueName = "";
  switch (dueType) {
    case 1:
      dueName = "Overdue";
      break;
    case 2:
      dueName = "Due today";
      break;
    case 3:
      dueName = "Due later";
      break;
    default:
      dueName = "Due today";
  }
  return dueName;
};

export const getAge = (birthDate) => {
  const momentbirthDate = moment.utc(birthDate);
  const currentDate = moment.utc();
  const age = currentDate.diff(momentbirthDate, "years");
  return age;
};
export const isDateBeforeMin = (inputDate) => {
  const minDate = moment("0001-12-12");
  const parsedInputDate = moment(inputDate);
  const isDateBefore = parsedInputDate.isSameOrBefore(minDate);
  return !isDateBefore;
};
export const getTotalServicesDueCount = (services, stageName, dueType) => {
  let totalServices = 0;
  let serviceDueCount = "";

  services?.clinicalServices?.forEach((service) => {
    service?.stages?.forEach((currentStage) => {
      if (
        currentStage?.stageName === stageName &&
        services?.dueType === dueType
      ) {
        totalServices++;
      }
    });
  });

  if (totalServices > 0) {
    serviceDueCount = ` (${totalServices})`;
  }
  return serviceDueCount;
};

export const getTotalServicesCount = (services, stageName) => {
  let totalServices = 0;
  let serviceDueCount = "";

  services?.forEach((service) => {
    service?.clinicalServices?.forEach((currentService) => {
      totalServices++;
    });
  });

  if (totalServices > 0) {
    serviceDueCount = ` (${totalServices})`;
  }
  return serviceDueCount;
};

export const getTotalDaysLeftForOrbit = (serviceDate) => {
  let momentdate = moment(serviceDate);
  let isValidDate = momentdate?.isValid();
  let totalDays;
  if (isValidDate) {
    const today = moment();
    const daysDifference = momentdate?.diff(today, "days");
    if (daysDifference < 0) {
      totalDays = "Overdue";
    } else {
      totalDays = `${daysDifference} days left`;
    }
  } else {
    totalDays = "";
  }
  return totalDays;
};
