import React from "react";
import Heart from "./components/Heart";
import ECG from "./components/ECG";
import PhysicalActivity from "./components/PhysicalActivity";
import Hearing from "./components/Hearing";

export default function AppleHealth() {
  return (
    <div className="appleHealth_container">
      <div className="tasks_header appleHealth_header">
        <h3 className="pharmacy_header_name">Apple Health</h3>
        <p className="last_updated">Last updated 2 days ago</p>
      </div>
      <div className="appleHealth_details">
        <div className="left">
          <Heart />
          <ECG />
        </div>
        <div className="right">
          <PhysicalActivity />
          <Hearing />
        </div>
      </div>
    </div>
  );
}
