import { dispatch, getDataFromStore } from "../store/store";
import { logError } from "../services/logService";
import {
  addOrbitSessionData,
  updateOrbitSessionData,
  getOrbitProfileData,
  getOrbitAuditBySessionIdData,
  setIsOrbitLoading,
  saveOrbitProfileData,
  getOrbitDashboardData,
  setProfData,
  setPayData,
  setCompDocData,
  setAccredationsData,
  setAvbData,
  setIsStart,
  setIsStopTimer,
  setOrbitProfileData,
  addOrbitAuditData,
  setIsOrbitSessionMinutes,
  setIsOrbitSessionSeconds,
  setTimer,
  setOrbitAnimation,
  setIsAnimationPlaying,
  setOpenSuccessDialog,
  setTotalCount,
  setTotalPrice,
  setIsActive,
  getOrbitMonthlyReportData,
  getOrbitEarningsData,
  getOrbitUserSessionsData,
  getOrbitParkedServicesData,
  getOrbitActiveServicesData,
  changeOrbitServiceStatusData,
  getOrbitFollowUpServicesData,
  getOrbitPotentialEarningsData,
  getOrbitOtherDetailsData,
  setIsMonthlyReportLoadingData,
  setIsOrbitEarningLoadingData,
  setIsUserSessionLoadingData,
  setIsPotentialEarningLoadingData,
  setIsFollowupLoadingData,
  setIsOtherDetailsLoadingData,
  setIsActiveServicesLoadingData,
  setshowTestModeDialogData,
  setisTestModeData,
  addTestModeServiceData,
  getPaymentDetailsData,
} from "../features/orbitSlice/orbitSlice";

const reducerName = "template";

// #region State Setters
export const setCurrentOrbitLoading = (privateCount) => {
  dispatch(setIsOrbitLoading(privateCount));
};
export const setCurrentOrbitProfile = (data) => {
  dispatch(setOrbitProfileData(data));
};
export const setCurrentProfData = (data) => {
  dispatch(setProfData(data));
};
export const setCurrentPayData = (data) => {
  dispatch(setPayData(data));
};
export const setCurrentCompDocData = (data) => {
  dispatch(setCompDocData(data));
};
export const setCurrentAccredationsData = (data) => {
  dispatch(setAccredationsData(data));
};
export const setCurrentAvbData = (data) => {
  dispatch(setAvbData(data));
};
export const setIsMonthlyReportLoading = (data) => {
  dispatch(setIsMonthlyReportLoadingData(data));
};
export const setIsOrbitEarningLoading = (data) => {
  dispatch(setIsOrbitEarningLoadingData(data));
};
export const setIsUserSessionLoading = (data) => {
  dispatch(setIsUserSessionLoadingData(data));
};
export const setIsPotentialEarningLoading = (data) => {
  dispatch(setIsPotentialEarningLoadingData(data));
};
export const setIsFollowupLoading = (data) => {
  dispatch(setIsFollowupLoadingData(data));
};
export const setIsOtherDetailsLoading = (data) => {
  dispatch(setIsOtherDetailsLoadingData(data));
};
export const setIsActiveServicesLoading = (data) => {
  dispatch(setIsActiveServicesLoadingData(data));
};
export const setCurrentStart = (isStart) => {
  dispatch(setIsStart(isStart));
};
export const setCurrentStopTimer = (isStopTimer) => {
  dispatch(setIsStopTimer(isStopTimer));
};
export const setCurrentOrbitSessionMinutes = (minutes) => {
  dispatch(setIsOrbitSessionMinutes(minutes));
};
export const setCurrentOrbitSessionSeconds = (seconds) => {
  dispatch(setIsOrbitSessionSeconds(seconds));
};
export const setCurrentTimer = (timerValue) => {
  dispatch(setTimer(timerValue + 1));
};
export const setCurrentTimeActive = (isActive) => {
  dispatch(setIsActive(isActive));
};
export const setCurrentOrbitAnimation = (isOrbitAnimation) => {
  dispatch(setOrbitAnimation(isOrbitAnimation));
};
export const setCurrentAnimationPlaying = (isAnimationPlaying) => {
  dispatch(setIsAnimationPlaying(isAnimationPlaying));
};
export const setCurrentOpenSuccessDialog = (isOpen) => {
  dispatch(setOpenSuccessDialog(isOpen));
};
export const setCurrentTotalCount = (totaCount) => {
  dispatch(setTotalCount(totaCount));
};
export const setCurrentTotalPrice = (totalPrice) => {
  dispatch(setTotalPrice(totalPrice));
};
export const setshowTestModeDialog = (show) => {
  dispatch(setshowTestModeDialogData(show));
};
export const setIsTestMode = (testMode) => {
  dispatch(setisTestModeData(testMode));
};

// #endregion

// #region API getter/caller functions for components usage to dispatch REDUX extra reducers
export const addOrbitSession = async (data) => {
  let result = null;

  try {
    result = await dispatch(addOrbitSessionData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};
export const updateOrbitSession = async (data) => {
  let result = null;

  try {
    result = await dispatch(updateOrbitSessionData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};
export const getOrbitProfile = async () => {
  let result = null;

  try {
    result = await dispatch(getOrbitProfileData()).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};
export const saveOrbitProfile = async (data) => {
  let result = null;
  try {
    result = await dispatch(saveOrbitProfileData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const addOrbitAudit = async (data) => {
  let result = null;

  try {
    result = await dispatch(addOrbitAuditData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const getOrbitAuditBySessionId = async (params) => {
  let result = null;

  try {
    result = await dispatch(getOrbitAuditBySessionIdData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const getOrbitDashboard = async () => {
  let result = null;
  try {
    result = await dispatch(getOrbitDashboardData()).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};
export const getOrbitMonthlyReport = async (params) => {
  let result = null;
  try {
    result = await dispatch(getOrbitMonthlyReportData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};
export const getOrbitEarnings = async (params) => {
  let result = null;
  try {
    result = await dispatch(getOrbitEarningsData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};
export const getOrbitUserSessions = async () => {
  let result = null;
  try {
    result = await dispatch(getOrbitUserSessionsData()).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};
export const getOrbitParkedServices = async (params) => {
  let result = null;
  try {
    result = await dispatch(getOrbitParkedServicesData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};
export const getOrbitActiveServices = async (params) => {
  let result = null;
  try {
    result = await dispatch(getOrbitActiveServicesData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};
export const changeOrbitServiceStatus = async (params) => {
  let result = null;
  try {
    result = await dispatch(changeOrbitServiceStatusData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};
export const getOrbitFollowUpServices = async () => {
  let result = null;
  try {
    result = await dispatch(getOrbitFollowUpServicesData()).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};
export const getOrbitPotentialEarnings = async () => {
  let result = null;
  try {
    result = await dispatch(getOrbitPotentialEarningsData()).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};
export const getOrbitOtherDetails = async () => {
  let result = null;
  try {
    result = await dispatch(getOrbitOtherDetailsData()).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const addTestModeService = async (params) => {
  let result = null;
  try {
    result = await dispatch(addTestModeServiceData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const getPaymentDetails = async () => {
  let result = null;

  try {
    result = await dispatch(getPaymentDetailsData()).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};
// #endregion
