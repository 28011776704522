import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./../../assets/css/PatientSearch.css";
import { LiaSearchSolid, LiaFilePrescriptionSolid } from "react-icons/lia";

import { SlClose } from "react-icons/sl";

import { IoChevronForwardCircleOutline } from "react-icons/io5";

import CustomButton from "../controls/CustomButton";
import { useNavigate, useLocation } from "react-router-dom";

export default function PatientSearch(props) {
  const navigate = useNavigate();
  const {
    show,
    setShow,
    pharmacyAvgVal,
    navigateFromDashboard,
    selectedPatientName,
    selectedPatientAddress,
    pharmacyId,
  } = props || { pharmacyId: "00000000-0000-0000-0000-000000000000" };
  const [searchText, setSearchText] = useState("");
  const [patientList, setPatientList] = useState([]);

  const [selectedPatient, setSelectedPatient] = useState(null);

  const handleRowClick = (event) => {
    handleClose();
    navigate(`/patientDashboard/${pharmacyId}`, {
      replace: true,
      state: {
        onDarkModeChange: props.onDarkModeChange,
        moveFromDashboard: navigateFromDashboard,
        selectedPatientName: selectedPatientName,
        selectedPatientAddress: selectedPatientAddress,
        pharmacyAvgVal: pharmacyAvgVal,
        latitude: 54.7023545,
        logitude: -3.2765753,
      },
    });
  };

  const handlePatientClick = (patient) => {
    const firstNameInitial = patient.name.charAt(0).toUpperCase();
    const lastNameInitial = patient.name.split(" ")[1]?.charAt(0).toUpperCase();
    const initials = lastNameInitial
      ? firstNameInitial + lastNameInitial
      : firstNameInitial;

    setSelectedPatient({
      ...patient,
      initials,
    });
  };

  const handleClose = () => {
    props.handleCrossIconClick();
  };

  const samplePatientsData = [
    {
      id: 1,
      name: "Pamala Baldwin",
      date: "25/10/2019",
    },
    {
      id: 2,
      name: "Pastell Wats",
      date: "25/10/2019",
    },
    {
      id: 3,
      name: "Pam Alexis",
      date: "25/10/2019",
    },
    {
      id: 4,
      name: "Peter Parker",
      date: "01/05/2020",
    },
    {
      id: 5,
      name: "Paul Johnson",
      date: "15/03/2018",
    },
    {
      id: 6,
      name: "Philip Smith",
      date: "10/11/2021",
    },
  ];

  const handleInputChange = (e) => {
    setSearchText(e?.target?.value);
    if (e?.target?.value.trim() === "") {
      setPatientList("");
      setSelectedPatient(null);
    } else {
      const filteredPatients = samplePatientsData.filter((patient) =>
        patient.name.toLowerCase().includes(e?.target?.value.toLowerCase())
      );
      setPatientList(filteredPatients);
    }
  };

  const clearSearch = () => {
    setSearchText("");
    setPatientList("");
    setSelectedPatient(null);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={props.setShow}
        backdrop="true"
        centered
        className="patient-searchModal"
        contentClassName="modal-height"
        dialogClassName="custom-modal-content custom-modal-width"
      >
        <Modal.Header style={{ padding: "10px 10px 0" }}>
          <div className="inputDiv">
            <span className="searchIcon">
              <LiaSearchSolid size={20} className="icons-color" />
            </span>
            <input
              value={searchText}
              onChange={handleInputChange}
              className="searchPatient"
              type="text"
            />
            {searchText?.length > 0 && (
              <div className="cross-icon">
                <SlClose
                  className="cross icons-color"
                  onClick={clearSearch}
                  size={25}
                />
              </div>
            )}
            <button onClick={handleClose} className="suffix-button">
              Close
            </button>
          </div>
        </Modal.Header>
        {patientList?.length === 0 ? (
          <div className="noRecordFound searchPatient">No record found</div>
        ) : (
          <div className="parent">
            <div className="patients">
              <div className="addPatient">
                {patientList?.length > 0 && (
                  <div className="patientListHeading">Patient(s)</div>
                )}
              </div>

              {patientList?.length > 0 ? (
                <div className="patientList">
                  {patientList?.map((patient) => (
                    <div
                      className="rowPatient"
                      key={patient.id}
                      onClick={() => handlePatientClick(patient)}
                    >
                      <div>
                        <div className="patientName">{patient?.name || ""}</div>
                      </div>
                      <div>
                        <div className="date">{patient?.date || ""}</div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}

              {selectedPatient &&
                searchText?.trim() !== "" &&
                patientList?.length !== 0 && (
                  <div className="prescriptions">
                    <div className="prescriptionsHeading">Prescriptions</div>
                    <div className="addPrescriptions"></div>
                  </div>
                )}
              {selectedPatient &&
                searchText.trim() !== "" &&
                patientList?.length !== 0 && (
                  <div className="prescriptionList">
                    <div className="rowPrescription">
                      <div>
                        <LiaFilePrescriptionSolid
                          size={21}
                          className="icons-color"
                        />

                        <div className="prescriptionName">
                          RX ID 123-456-789
                        </div>
                      </div>

                      <div className="date">11/13/1982</div>
                    </div>
                    <div className="rowPrescription">
                      <div>
                        <LiaFilePrescriptionSolid
                          size={21}
                          className="icons-color"
                        />

                        <div className="prescriptionName">
                          RX ID 123-456-789
                        </div>
                      </div>

                      <div className="date">11/13/1982</div>
                    </div>
                    <div className="rowPrescription">
                      <div>
                        <LiaFilePrescriptionSolid
                          size={21}
                          className="icons-color"
                        />

                        <div className="prescriptionName">
                          RX ID 123-456-789
                        </div>
                      </div>

                      <div className="date">11/13/1982</div>
                    </div>
                  </div>
                )}
            </div>
            {selectedPatient &&
              searchText.trim() !== "" &&
              patientList?.length !== 0 && (
                <div className="patientDetails">
                  <div className="patientContact">
                    {selectedPatient && (
                      <div className="circle">
                        <span className="initials">
                          {selectedPatient ? selectedPatient.initials : ""}
                        </span>
                      </div>
                    )}
                    <div className="patientDetailsName">
                      {" "}
                      {selectedPatient ? selectedPatient.name : ""}
                    </div>
                    <div className="patientDetailsDate">11/13/1982 - (37)</div>
                    <div className="detailDigits">
                      <div className="digitSectionOne">
                        <div className="rectangle">
                          <span className="digit">9</span>
                        </div>
                        <div className="rectangle">
                          <span className="digit">4</span>
                        </div>
                        <div className="rectangle">
                          <span className="digit">3</span>
                        </div>
                      </div>
                      <div className="digitSectionTwo">
                        <div className="rectangle">
                          <span className="digit">4</span>
                        </div>
                        <div className="rectangle">
                          <span className="digit">7</span>
                        </div>
                        <div className="rectangle">
                          <span className="digit">6</span>
                        </div>
                      </div>
                      <div className="digitSectionThree">
                        <div className="rectangle">
                          <span className="digit">5</span>
                        </div>
                        <div className="rectangle">
                          <span className="digit">9</span>
                        </div>
                        <div className="rectangle">
                          <span className="digit">1</span>
                        </div>
                        <div className="rectangle">
                          <span className="digit">9</span>
                        </div>
                      </div>
                    </div>
                    <div className="address">
                      <p>Flat 1, 50 Stanhope street, Newton TE22</p>
                    </div>
                  </div>
                  <div className="drugsAndNominations">
                    <div className="nominations">
                      <div className="nominationChild">
                        <div className="nominationHeading searchPatient">
                          Nominations
                        </div>
                        <div>
                          <IoChevronForwardCircleOutline
                            size={30}
                            color="#000"
                          />
                        </div>
                      </div>
                      <div className="nominationAddress">
                        <p>
                          Invatech Health 442-450 Stapleton Rd, Bristol BS5 6NR
                          UK
                        </p>
                      </div>
                    </div>
                    <div className="nominations">
                      <div className="nominationChild">
                        <div className="nominationHeading searchPatient">
                          Nominations
                        </div>
                        <div>
                          <IoChevronForwardCircleOutline
                            size={30}
                            color="#000"
                          />
                        </div>
                      </div>
                      <div className="nominationAddress">
                        <p>
                          Invatech Health 442-450 Stapleton Rd, Bristol BS5 6NR
                          UK
                        </p>
                      </div>
                    </div>
                    <div className="tags">
                      <div className="tagsChild">
                        <div className="nominationHeading searchPatient">
                          Patient tags
                        </div>
                      </div>
                      <div className="tagSection">
                        <button className="singleTag">#VisuallyImpaired</button>
                        <button className="singleTag">#DeliveryPatient</button>
                        <button className="singleTag">#BespokeLabelling</button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        )}

        {selectedPatient && patientList?.length !== 0 && (
          <div className="footer">
            {" "}
            <CustomButton label="Go To Dashboard" action={handleRowClick} />
          </div>
        )}
      </Modal>
    </>
  );
}
