import React, { useState, useEffect } from "react";
import CustomLabel from "../controls/CustomLabel";
import "./../../assets/css/FollowUpControl.scss";
import FollowUp from "../../Model/FollowUp/FollowUp";
import CustomDropdown from "../controls/CustomDropdown";
import useTitanApp from "../../hooks/useTitanApp";
import {
  getFollowUpByServiceIdAndServiceType,
  setCurrentTimelineElements,
  setFollowupDescription,
  setFollowupDate,
} from "../../dispatchers/nmsDispatchers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { stageTypes } from "../../enum/stageType";

function FollowUpControl({
  Title,
  pharmacyId,
  ClinicalpatientId,
  clinicalServiceId,
  darkModeChange,
  stageStatus,
  stageNo,
  handleFollowUpModel,
  patientName,
  serviceID,
  SectionTitle,
}) {
  const { appState, nmsServiceState } = useTitanApp();
  const { followUpData, followupDescription, followupDate, isNmsLoading } =
    nmsServiceState || {};
  const [ReturnSdate, setReturnSdate] = useState(new Date());
  const [ReturnEdate, setReturnEdate] = useState(new Date());
  const currentHour = new Date().getHours();
  const currentMinute = new Date().getMinutes();
  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];
  const [followupModel, setFollowupModel] = useState(null);
  const [selectedOption, setSelectedOption] = useState(options[0].value);

  const handleSelectChange = (event) => {
    setSelectedOption(event?.target?.value);
  };
  const followUpArray = [
    {
      label: "Follow up face to face",
      value: "Follow up face to face",
    },
    {
      label: "Follow up Telephone",
      value: "Follow up Telephone",
    },
    {
      label: "Follow up decline",
      value: "Follow up decline",
    },
  ];
  const interventionArray = [
    {
      label: "Intervention face to face",
      value: "Intervention face to face",
    },
    {
      label: "Intervention Telephone",
      value: "Intervention Telephone",
    },
    {
      label: "Intervention decline",
      value: "Intervention decline",
    },
  ];

  function changeDecision(value, labelName) {
    const stageType = stageTypes[stageNo];

    const model = new FollowUp(
      ReturnSdate,
      ReturnEdate,
      `${Title}${patientName ? " - (" + patientName + ")" : ""}`,
      value,
      pharmacyId,
      ClinicalpatientId,
      clinicalServiceId,
      stageType,
      serviceID
    );
    setFollowupModel(model);
    handleFollowUpModel(model);
    setFollowupDescription(value);
  }

  const addOneHourToDateString = (dateString) => {
    const date = new Date(dateString);
    date.setHours(date.getHours() + 1);

    const formattedDate = date.toISOString().slice(0, 16);

    return formattedDate;
  };
  function changeAppDate(startDate, labelName) {
    const endDate = addOneHourToDateString(startDate);
    const stageType = stageTypes[stageNo];
    setReturnSdate(startDate);
    setReturnEdate(endDate);
    const model = new FollowUp(
      startDate,
      endDate,
      `${Title}${patientName ? " - (" + patientName + ")" : ""}`,
      followupDescription,
      pharmacyId,
      ClinicalpatientId,
      clinicalServiceId,
      stageType,
      serviceID
    );
    setFollowupDate(startDate);
    setFollowupModel(model);
    handleFollowUpModel(model);
  }

  return (
    <>
      {isNmsLoading ? (
        <></>
      ) : (
        <div>
          <div className="outer-boxes">
            <div className="outer-item decision">
              <CustomLabel name="Decision" />
              <CustomDropdown
                handleChange={(selectedValue) =>
                  changeDecision(selectedValue, "Decision")
                }
                label=""
                items={Title === "Follow Up Booking" ? followUpArray : interventionArray}
                selected={
                  Title === "Follow Up Booking" ? followUpArray[0]?.value : followupDescription !== "" ? followupDescription : interventionArray[0]?.value
                }
                isEdit={stageStatus}
                darkModeChange={darkModeChange}
              />
            </div>

            <div className="outer-item">
              <CustomLabel name="Appointment date" />
              <div className="calendar_flex">
                <DatePicker
                  className="card-inner-value quantity-box input-field"
                  selected={followupDate}
                  onChange={(date) => changeAppDate(date, "Appointment date")}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="dd/MM/yyyy h:mm aa"
                  disabled={
                    stageStatus
                      ? stageStatus
                      : followupDescription === "Follow up decline"
                        ? true
                        : false
                  }
                // minDate={new Date()}
                // minTime={
                //   followupDate &&
                //   followupDate.toDateString() === new Date().toDateString()
                //     ? new Date().setHours(
                //         currentHour,
                //         currentMinute + 15,
                //         0,
                //         0
                //       )
                //     : new Date().setHours(0, 0, 0, 0)
                // }
                // maxTime={new Date(new Date().setHours(23, 59, 59, 999))}
                />
                <span className="calender_icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.57143 9.14286C5.20261 9.14286 5.71429 8.63118 5.71429 8C5.71429 7.36882 5.20261 6.85714 4.57143 6.85714C3.94025 6.85714 3.42857 7.36882 3.42857 8C3.42857 8.63118 3.94025 9.14286 4.57143 9.14286Z"
                      fill="#999999"
                    />
                    <path
                      d="M5.71429 11.4286C5.71429 12.0598 5.20261 12.5714 4.57143 12.5714C3.94025 12.5714 3.42857 12.0598 3.42857 11.4286C3.42857 10.7974 3.94025 10.2857 4.57143 10.2857C5.20261 10.2857 5.71429 10.7974 5.71429 11.4286Z"
                      fill="#999999"
                    />
                    <path
                      d="M8 9.14286C8.63118 9.14286 9.14286 8.63118 9.14286 8C9.14286 7.36882 8.63118 6.85714 8 6.85714C7.36882 6.85714 6.85714 7.36882 6.85714 8C6.85714 8.63118 7.36882 9.14286 8 9.14286Z"
                      fill="#999999"
                    />
                    <path
                      d="M9.14286 11.4286C9.14286 12.0598 8.63118 12.5714 8 12.5714C7.36882 12.5714 6.85714 12.0598 6.85714 11.4286C6.85714 10.7974 7.36882 10.2857 8 10.2857C8.63118 10.2857 9.14286 10.7974 9.14286 11.4286Z"
                      fill="#999999"
                    />
                    <path
                      d="M11.4286 9.14286C12.0598 9.14286 12.5714 8.63118 12.5714 8C12.5714 7.36882 12.0598 6.85714 11.4286 6.85714C10.7974 6.85714 10.2857 7.36882 10.2857 8C10.2857 8.63118 10.7974 9.14286 11.4286 9.14286Z"
                      fill="#999999"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16 2.85714C16 1.27919 14.7208 0 13.1429 0H2.85714C1.27919 0 0 1.27919 0 2.85714V13.1429C0 14.7208 1.27919 16 2.85714 16H13.1429C14.7208 16 16 14.7208 16 13.1429V2.85714ZM1.14286 4.57143H14.8571V13.1429C14.8571 14.0896 14.0896 14.8571 13.1429 14.8571H2.85714C1.91037 14.8571 1.14286 14.0896 1.14286 13.1429V4.57143ZM2.85714 1.14286H13.1429C14.0896 1.14286 14.8571 1.91037 14.8571 2.85714V3.42857H1.14286V2.85714C1.14286 1.91037 1.91037 1.14286 2.85714 1.14286Z"
                      fill="#999999"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          {stageStatus ? <></> : <></>}
        </div>
      )}
    </>
  );
}

export default FollowUpControl;
