import * as React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import CustomButton from "../controls/CustomButton";
import "./../../assets/css/PatientModal.css";
import { ImWarning } from "react-icons/im";
import TextField from "@mui/material/TextField";
import {
  savePatientContact,
  setCurrentPatientContact,
} from "../../dispatchers/pharmacyServiceDispatchers";
import useTitanApp from "../../hooks/useTitanApp";
import { isMobileNumberValid } from "../../utils/validMobile";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setCurrentEditedPreferredContact } from "../../dispatchers/nmsDispatchers";
import { showError } from "../ToastedMessage/ToastedMessage";

export default function PatientContactModal({ view }) {
  const { appState, pharmacyServiceState } = useTitanApp();
  const { darkMode } = appState || {};
  const { contactServiceId } = pharmacyServiceState || {};
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const [telephoneNo, setTelephoneNo] = useState("");

  const showToast = (message) => {
    toast.success(`${message}`, {
      className: "services-toast",
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
      theme: darkMode ? "dark" : "light",
    });
  };

  function handleSave() {
    savePatientContact(contactServiceId, telephoneNo)
      .then((data) => {
        setCurrentPatientContact(false);
        setCurrentEditedPreferredContact(data?.telephoneNumber);
        showToast("Contact has been updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating contact:", error);
        showError(
          "An error occurred while updating the contact. Please try again."
        );
      });
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        className="max-z"
      >
        <div className="clinical_modal">
          <Modal.Header>
            <ImWarning size={18} color={"red"} />
            <Modal.Title>{view === "nms" ? "Edit" : "Add"} Contact</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="progressmodal_body">
              <TextField
                className="telephone_text"
                error={!isMobileNumberValid(telephoneNo)}
                required
                id="outlined-required"
                label="Telephone"
                defaultValue={telephoneNo}
                onChange={(e) => setTelephoneNo(e?.target?.value)}
              />
            </div>
          </Modal.Body>
          <div className="progressmodal_footer">
            <CustomButton
              label="Cancel"
              action={() => setCurrentPatientContact(false)}
            />
            <CustomButton label="Save" action={handleSave} />
          </div>
        </div>
      </Modal>
    </>
  );
}
