import React, { useState, useRef, useEffect } from "react";
import "./../../../assets/css/Dashboard/PatientListView.scss";
import "./PatientMapView.css";
import { Map, Marker, NavigationControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Supercluster from "supercluster";
import mapboxgl from "mapbox-gl";
import useTitanApp from "../../../hooks/useTitanApp";

export default function PatientMapView({ onDarkModeChange }) {
  const { appState } = useTitanApp();
  const { isAppLoading, currentPharmacy } = appState || {};
  const { patients } = currentPharmacy || {};
  const [totalPatients] = useState(patients?.length || 0);
  const mapRef = useRef(null);
  const [viewport, setViewport] = useState({
    latitude: 52.7023545,
    longitude: -2.2765753,
    zoom: 6,
  });

  const [clusters, setClusters] = useState([]);
  const [bounds, setBounds] = useState([-180, -85, 180, 85]);
  const [zoom, setZoom] = useState(0);

  const [points, setPoints] = useState([]);

  const DefaultState = [{ logitude: -2.542753, latitude: 51.461583 }];
  const displayedPatients = patients?.length > 0 ? patients : DefaultState;

  useEffect(() => {
    const points = displayedPatients?.map((patient) => ({
      type: "Feature",
      properties: {
        cluster: false,
        patientAge: patient.age,
      },
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(patient.logitude),
          parseFloat(patient.latitude),
        ],
      },
    }));

    if (points) {
      setPoints(points);
    }

    const bounds = new mapboxgl.LngLatBounds();
    displayedPatients?.forEach((obj) => {
      bounds.extend([obj.logitude, obj.latitude]);
    });

    const zoomLevel = patients?.length > 0 ? 16 : 6;

    if (mapRef.current) {
      mapRef?.current.fitBounds(bounds, {
        duration: 1000,
        maxZoom: zoomLevel,
        minZoom: zoomLevel,
      });
    }
  }, [patients, bounds]);

  const supercluster = new Supercluster({
    radius: 75,
    maxZoom: 15,
  });

  useEffect(() => {
    if (mapRef.current) {
      setBounds(mapRef.current.getMap().getBounds().toArray().flat());
    }
  }, [mapRef?.current]);

  useEffect(() => {
    if (supercluster) {
      supercluster?.load(points);
      setClusters(supercluster?.getClusters(bounds, zoom));
    }
  }, [points, zoom, bounds]);

  useEffect(() => {
    if (mapRef.current) {
      setBounds(mapRef.current.getMap().getBounds().toArray().flat());
    }
  }, [mapRef?.current]);

  return (
    isAppLoading === false && (
      <div className="patient_mapview_container">
        <p className="patient_mapview_count">
          <strong>{totalPatients}</strong> patients
        </p>
        {displayedPatients?.length > 0 ? (
          <Map
            initialViewState={viewport}
            scrollZoom={true}
            mapStyle={
              onDarkModeChange
                ? "mapbox://styles/mapbox/dark-v11"
                : "mapbox://styles/mapbox/light-v11"
            }
            style={{
              width: "100%",
              height: "90vh",
            }}
            ref={mapRef}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            onZoomEnd={(e) => setZoom(Math.round(e.viewState.zoom))}
            attributionControl={false}
          >
            <NavigationControl position="bottom-right" />
            {clusters?.map((cluster, index) => {
              const [longitude, latitude] = cluster?.geometry?.coordinates;
              const { cluster: isCluster, point_count: pointCount } =
                cluster?.properties || {};

              if (isCluster) {
                return (
                  <Marker
                    key={`patient-clusters patient-cluster-${cluster.id}`}
                    longitude={longitude}
                    latitude={latitude}
                  >
                    <div className="cluster-marker">{pointCount}</div>
                  </Marker>
                );
              }

              return patients?.length > 0 ? (
                <Marker key={index} longitude={longitude} latitude={latitude}>
                  <div className="patient-marker">
                    <svg
                      width="35"
                      height="35"
                      viewBox="0 0 32 32"
                      fill="none"
                      stroke="#000"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_d_1469_20100)">
                        <rect
                          width="17"
                          height="17"
                          rx="8.5"
                          transform="matrix(-1 0 0 1 21 0)"
                          fill="white"
                        />
                        <rect
                          x="11"
                          y="7"
                          width="3"
                          height="3"
                          rx="1.5"
                          fill="black"
                        />
                        <rect
                          x="-0.5"
                          y="0.5"
                          width="16"
                          height="16"
                          rx="8"
                          transform="matrix(-1 0 0 1 20 0)"
                          stroke="black"
                          stroke-opacity="0.5"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_1469_20100"
                          x="0"
                          y="0"
                          width="25"
                          height="25"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_1469_20100"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_1469_20100"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                </Marker>
              ) : (
                <></>
              );
            })}
          </Map>
        ) : (
          <p>No patients to display.</p>
        )}
      </div>
    )
  );
}
