import React from "react";
import { useNavigate, useLoaderData } from "react-router-dom";
import SettingsSidebar from "../../../../SettingsSidebar/SettingsSidebar";
import { MdAdd, MdOutlineModeEditOutline } from "react-icons/md";
import useTitanTeam from "../../../../../hooks/useTitanTeam";
import CustomLoader from "../../../../controls/CustomLoader";
import AsyncRendererRouter from "../../../../AsyncRendererRouter/AsyncRendererRouter";
import RolesLoadingComponent from "../../../../SettingsLoadingComponents/RolesLoadingComponent";

export default function Roles() {
  const { teamState } = useTitanTeam();
  const { teamRolesAll, isTeamLoading } = teamState || {};
  const navigate = useNavigate();

  const handleAddNewRole = () => {
    navigate("/settings/teams/roles/new");
  };

  const handleEditRole = async (value, role) => {
    navigate(`/settings/teams/roles/${role?._id}`);
  };

  const { appData } = useLoaderData() || {};

  return (
    <AsyncRendererRouter
      resolve={appData}
      isLoading={isTeamLoading}
      loadingElement={<RolesLoadingComponent />}
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <SettingsSidebar />
          <div className="settings_content_wrapper">
            <div className="settings_heading">
              <h1>Roles</h1>
            </div>
            <div className={"settings_content_area roles_wrapper"}>
              <div className="allRoles_wrapper">
                <div className="allView_list_header">
                  <h3></h3>
                  <div className="addNew_cta" onClick={handleAddNewRole}>
                    <span>Add New Role</span>
                    <MdAdd />
                  </div>
                </div>
                <div className="allView_listview">
                  {teamRolesAll?.length > 0 ? (
                    teamRolesAll?.map((role) => (
                      <div className="allView_container" key={role._id}>
                        <div className="allView_title">
                          <p className="allView_name">{role.roleName}</p>
                        </div>
                        <MdOutlineModeEditOutline
                          size={20}
                          className="icons-color member_edit_icon"
                          onClick={() => handleEditRole(true, role)}
                        />
                      </div>
                    ))
                  ) : (
                    <p>No Roles Found</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
