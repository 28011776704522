import React from "react";
import { BsChevronRight } from "react-icons/bs";
import "./../../assets/css/StartChecks.css";
import { useNavigate } from "react-router-dom";
import useTitanApp from "../../hooks/useTitanApp";

export default function StartChecks({ totalChecksCount }) {
  const { appState } = useTitanApp();
  const { darkMode } = appState || {};
  const navigate = useNavigate();
  const routeChangechecks = () => {
    navigate("/clinicalchecks/00000000-0000-0000-0000-000000000000");
  };
  return (
    <div className="startchecks_container">
      <button className="startchecks_button" onClick={routeChangechecks}>
        <p>Start checks</p>
        <div className="startChecksCount">
          {totalChecksCount > 0 ? totalChecksCount : 0}
        </div>
        <BsChevronRight size={14} color="#fff" />
      </button>
    </div>
  );
}
