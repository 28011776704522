import React from "react";
import { MdAdd } from "react-icons/md";
import useTitanTemplate from "../../../../../../hooks/useTitanTemplate";
import { useNavigate } from "react-router";

export default function TemplateList() {
  const { templateState } = useTitanTemplate();
  const { currentTemplates } = templateState || {};
  const navigate = useNavigate();

  const handleAddNewTemplate = () => {
    navigate("/settings/templates/consultation/new");
  };

  return (
    <div>
      <div>
        <div className="allView_list_header">
          <h3></h3>
          <div className="addNew_cta" onClick={handleAddNewTemplate}>
            <span>Add New Template</span>
            <MdAdd />
          </div>
        </div>
        <p></p>
      </div>
      <div className="allView_listview">
        {currentTemplates?.map((temp, index) => (
          <div className="allView_container" key={index}>
            <div className="allView_title members_list_title">
              <p className="allView_name">{temp?.templateName}</p>
            </div>
            {/* <MdOutlineModeEditOutline
              size={20}
              className="icons-color member_edit_icon"
              onClick={() => handleEditNewMember(true)}
            /> */}
          </div>
        ))}
      </div>
    </div>
  );
}
