import * as React from "react";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomLabel from "../controls/CustomLabel";
import { getFormattedDate } from "../../utils/FormattedDate";
import { calculateDaysAgo } from "../../data/calulateDays";
import { therapyType } from "../../enum/therapyTypes";
import moment from "moment";
function HistoryAccordion({ item }) {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const date = moment(item?.lastDispenseDate);
  const formattedDate = date.format("DD/MM");
  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        className="therapy-accordion"
        style={{
          // background: "#F2F2F2",
          marginTop: "15px",
          marginBottom: "15px",
          borderRadius: "12px",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className="card-accordion-cont"
        >
          <div className="d-flex therapy_name_date">
            <div className="therapy_dispensed_date">
              <svg
                width="15"
                height="15"
                viewBox="0 0 12 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.2497 15.97H1.52274C1.03261 15.97 0.631728 15.6187 0.571922 15.1669H2.52215V7.16827H0.579398C0.621449 6.80097 0.745266 6.44606 0.953184 6.13003L1.65684 5.05821C1.85635 4.75457 1.96147 4.40539 1.96147 4.04738V3.43125H9.81146V4.04738C9.81146 4.40539 9.91612 4.75457 10.1156 5.05821L10.8198 6.13003C11.0272 6.44606 11.1515 6.80097 11.1935 7.16827H6.72772V15.1669H11.2005C11.1407 15.6187 10.7399 15.97 10.2497 15.97ZM0.562547 14.6359H1.96471V7.69844H0.562547V14.6359ZM1.6813 2.90052H2.24198V1.03047H1.6813V2.90052ZM2.80317 2.90052H3.36432V1.03047H2.80317V2.90052ZM3.92505 2.90052H4.48572V1.03047H3.92505V2.90052ZM5.0438 2.90052H5.60495V1.03047H5.0438V2.90052ZM6.1688 2.90052H6.72994V1.03047H6.1688V2.90052ZM7.28755 2.90052H7.84823V1.03047H7.28755V2.90052ZM8.40942 2.90052H8.97057V1.03047H8.40942V2.90052ZM9.5313 2.90052H10.092V1.03047H9.5313V2.90052ZM7.28755 14.6359V7.69844H11.2128V8.76805H8.40937V9.29844H11.2128V9.83501H8.40937V10.3654H11.2128V10.902H8.40937V11.4324H11.2128V11.9685H8.40937V12.4989H11.2128V13.0355H8.40937V13.5663H11.2128V14.6359H7.28755ZM11.2963 5.84983V5.84939L10.5922 4.77757C10.4483 4.55878 10.3721 4.30597 10.3721 4.04696V3.43083C10.5268 3.43083 10.652 3.31238 10.652 3.16564V0.765193C10.652 0.618895 10.5268 0.5 10.3721 0.5H1.4017C1.24658 0.5 1.12136 0.618895 1.12136 0.765193V3.16564C1.12136 3.31238 1.24658 3.43083 1.4017 3.43083V4.04696C1.4017 4.30597 1.32507 4.55878 1.18117 4.77757L0.477045 5.84983C0.164466 6.32541 0 6.87304 0 7.43348V7.51083V14.8235V15.0516C0 15.8503 0.683095 16.5 1.52318 16.5H10.2502C11.0903 16.5 11.7738 15.8503 11.7738 15.0516V14.8235V7.51083V7.43348C11.7738 6.87304 11.6089 6.32541 11.2963 5.84983Z"
                  // fill="black"
                  className="icons-color"
                />
              </svg>
              <p className="acc_heading">{item.prescribedItem.writtenAs}</p>
            </div>
            <p className="acc_heading">{formattedDate}</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="therpay-boxes">
            <p className="card-inner-value">
              {`Dispensed on ${getFormattedDate(item.lastDispenseDate)}`}
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default HistoryAccordion;
