import React, { useState } from "react";
import useTitanApp from "../../../../../hooks/useTitanApp";
import {
  declineServices,
  moveIntervention,
  sendSMS,
  sendToOrbit,
  setCurrentPharmacyServices,
  setPharmacyServiceLoading,
  setServiceTab,
  getServiceTitanPatientPds,
  setScrollPosition,
  setEligibilityStageScrollPosition,
  setInterventionStageScrollPosition,
  setFilteredPharmacyServices,
  setFilterNameData,
  setOriginalPharmacyServices,
} from "../../../../../dispatchers/pharmacyServiceDispatchers";
import CustomServiceToolbarButton from "../../../../../components/controls/CustomServiceToolbarButton";
import { AnimatePresence, motion } from "framer-motion";
import { getTitanPatientPds } from "../../../../../dispatchers/nmsDispatchers";
import { PiEnvelope } from "react-icons/pi";
import { TiDeleteOutline } from "react-icons/ti";
import { FaArrowRightToBracket, FaEnvelopesBulk } from "react-icons/fa6";
import { FaRegCalendarAlt, FaSync } from "react-icons/fa";
import { LuOrbit } from "react-icons/lu";
import { RxCross1 } from "react-icons/rx";
import {
  showError,
  showSuccess,
} from "../../../../../components/ToastedMessage/ToastedMessage";
import { ReactComponent as SpinnerAnim } from "./../../../../../assets/images/spinner-anim.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ServicesContainerFooter({
  setIsPatientFormModal,
  handleChange,
  smsQueue,
  interventionQueue,
  declineQueue,
  rescheduleQueue,
  pdsQueue,
  orbitQueue,
  setCurrentSmsQueue,
  setCurrentReSmsQueue,
  setCurrentInterventionQueue,
  setCurrentDeclineQueue,
  setCurrentRescheduleQueue,
  setCurrentPdsQueue,
  setCurrentOrbitQueue,
  setShowReschedule,
  setCheckedAllEligible,
  setCheckedAllConsent,
  setCheckedAllIntervention,
  setCheckedAllFollowUp,
  reSmsQueue,
  checksCount,
  setChecksCount,
  view,
  rowData,
  setRowData,
  setServicesUpdatedData,
  setRescheduleTargetStageType,
  rescheduleTargetStageType,
}) {
  const { appState, pharmacyServiceState } = useTitanApp();
  const { darkMode } = appState || {};
  const {
    serviceName,
    pharmacyServices,
    filterNameData,
    originalPharmacyServices,
  } = pharmacyServiceState || {};
  const { currentPharmacyId, features } = appState || {};
  const { ifSendToOrbit } = features || {};

  const [sendSMSServiceLoading, setSendSMSServiceLoading] = useState(false);
  const [resendSMSServiceLoading, setReSendSMSServiceLoading] = useState(false);
  const [sendToOrbitServiceLoading, setSendToOrbitServiceLoading] =
    useState(false);
  const [interventionServiceLoading, setInterventionServiceLoading] =
    useState(false);
  const [declineServiceLoading, setDeclineServiceLoading] = useState(false);
  const [rescheduleServiceLoading, setRescheduleServiceLoading] =
    useState(false);
  const [pdsSyncServiceLoading, setPDSSyncServiceLoading] = useState(false);

  const showToast = (message) => {
    toast.success(`${message}`, {
      className: "services-toast",
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
      theme: darkMode ? "dark" : "light",
    });
  };

  const handleActions = async (actionFor, queue) => {
    switch (actionFor) {
      case "SMS":
        setSendSMSServiceLoading(true);
        sendSMS(queue).then(() => {
          try {
            if (view === "kanban") {
              const newData = pharmacyServices?.map((stage) => ({
                ...stage,
                sortedServices: stage?.sortedServices?.map((service) => ({
                  ...service,
                  clinicalServices: [...service?.clinicalServices],
                })),
              }));

              queue?.forEach((sms) => {
                // Find the patient in stageType: 1
                const stage1 = newData?.find((stage) => stage.stageType === 1);
                const stage2 = newData?.find((stage) => stage.stageType === 2);

                if (stage1 && stage2) {
                  // Iterate through each sortedService in stage1 to find the matching dueType in stage2
                  stage1?.sortedServices?.forEach((serviceGroup1) => {
                    const dueType = serviceGroup1?.dueType;

                    // Find the corresponding serviceGroup in stage2 with the same dueType
                    const serviceGroup2 = stage2?.sortedServices?.find(
                      (service) => service?.dueType === dueType
                    );

                    if (serviceGroup2) {
                      // Filter out the patient from stageType: 1
                      const filteredServices =
                        serviceGroup1?.clinicalServices?.filter(
                          (patient) => patient?._id !== sms?.clinicalServiceID
                        );

                      // Find the patient object in stageType: 1
                      const patient = serviceGroup1?.clinicalServices?.find(
                        (patient) => patient?._id === sms?.clinicalServiceID
                      );

                      if (patient) {
                        // Add the patient to the corresponding serviceGroup in stageType: 2
                        serviceGroup2?.clinicalServices?.push(patient);

                        if (serviceGroup1 && serviceGroup1.clinicalServices) {
                          // Update the clinicalServices array for stageType: 1
                          serviceGroup1.clinicalServices = filteredServices;
                        }
                      }
                    }
                  });
                }
              });

              setCurrentPharmacyServices(newData);
            } else {
              let mutableData = JSON.parse(JSON.stringify(rowData));
              queue?.forEach((queueItem) => {
                // Find patients in transformedPharmacyData matching the clinicalServiceId
                mutableData?.forEach((patient) => {
                  if (patient) {
                    if (
                      patient.clinicalServiceId ===
                      queueItem.clinicalServiceID &&
                      patient.stageType === 1
                    ) {
                      patient.stageName = "Awaiting consent";
                      patient.stageType = 2;
                    }
                  }
                });
              });

              const stageOrder = {
                Eligible: 1,
                "Awaiting consent": 2,
                Intervention: 3,
                Followup: 4,
              };

              mutableData?.sort((a, b) => {
                return stageOrder[a.stageName] - stageOrder[b.stageName];
              });

              setRowData(mutableData);
            }

            showToast(
              `Message${queue.length > 1 ? "s" : ""} sent successfully!`
            );
            setSendSMSServiceLoading(false);
          } catch (error) {
            showError("Something went wrong");
            setSendSMSServiceLoading(false);
          }
        });

        const stagesWrapper = document.querySelector(".stages-wrapper");
        if (stagesWrapper) {
          setScrollPosition(stagesWrapper.scrollLeft);
        }
        const stageEligible = document.getElementById("stage_Eligible");
        if (stageEligible) {
          setEligibilityStageScrollPosition(stageEligible.scrollTop);
        }

        flushAllQueues();
        break;

      case "Resend SMS":
        setReSendSMSServiceLoading(true);
        sendSMS(queue).then(() => {
          try {
            if (view === "kanban") {
              const newData = pharmacyServices?.map((stage) => ({
                ...stage,
                sortedServices: stage.sortedServices.map((service) => ({
                  ...service,
                  clinicalServices: [...service.clinicalServices],
                })),
              }));

              queue?.forEach((sms) => {
                // Find the patient in stageType: 1
                const stage1 = newData?.find((stage) => stage.stageType === 1);
                const stage2 = newData?.find((stage) => stage.stageType === 2);

                if (stage1 && stage2) {
                  // Iterate through each sortedService in stage1 to find the matching dueType in stage2
                  stage1?.sortedServices?.forEach((serviceGroup1) => {
                    const dueType = serviceGroup1?.dueType;

                    // Find the corresponding serviceGroup in stage2 with the same dueType
                    const serviceGroup2 = stage2?.sortedServices?.find(
                      (service) => service?.dueType === dueType
                    );

                    if (serviceGroup2) {
                      // Filter out the patient from stageType: 1
                      const filteredServices =
                        serviceGroup1?.clinicalServices?.filter(
                          (patient) => patient?._id !== sms?.clinicalServiceID
                        );

                      // Find the patient object in stageType: 1
                      const patient = serviceGroup1?.clinicalServices?.find(
                        (patient) => patient?._id === sms?.clinicalServiceID
                      );

                      if (patient) {
                        // Add the patient to the corresponding serviceGroup in stageType: 2
                        serviceGroup2?.clinicalServices?.push(patient);

                        if (serviceGroup1 && serviceGroup1.clinicalServices) {
                          // Update the clinicalServices array for stageType: 1
                          serviceGroup1.clinicalServices = filteredServices;
                        }
                      }
                    }
                  });
                }
              });

              setCurrentPharmacyServices(newData);
            } else {
              let mutableData = JSON.parse(JSON.stringify(rowData));
              queue?.forEach((queueItem) => {
                // Find patients in transformedPharmacyData matching the clinicalServiceId
                mutableData?.forEach((patient) => {
                  if (patient) {
                    if (
                      patient.clinicalServiceId ===
                      queueItem.clinicalServiceID &&
                      patient.stageType === 1
                    ) {
                      patient.stageName = "Awaiting consent";
                      patient.stageType = 2;
                    }
                  }
                });
              });

              const stageOrder = {
                Eligible: 1,
                "Awaiting consent": 2,
                Intervention: 3,
                Followup: 4,
              };

              mutableData?.sort((a, b) => {
                return stageOrder[a.stageName] - stageOrder[b.stageName];
              });

              setRowData(mutableData);
            }

            showToast(
              `Message${queue.length > 1 ? "s" : ""} re-sent successfully!`
            );
            setReSendSMSServiceLoading(false);
          } catch (error) {
            showError("Something went wrong");
            setReSendSMSServiceLoading(false);
          }
        });

        flushAllQueues();
        break;

      case "Intervention":
        setInterventionServiceLoading(true);
        moveIntervention(queue).then(() => {
          try {
            if (view === "kanban") {
              const newData = pharmacyServices?.map((stage) => ({
                ...stage,
                sortedServices: stage?.sortedServices?.map((service) => ({
                  ...service,
                  clinicalServices: [...service?.clinicalServices],
                })),
              }));
              queue?.forEach((clinicalServiceID) => {
                const stagesToConsider = [1, 2];

                stagesToConsider.forEach((stageType) => {
                  // Find the patient in the current stage
                  const currentStage = newData?.find(
                    (stage) => stage.stageType === stageType
                  );
                  const stage3 = newData?.find(
                    (stage) => stage.stageType === 3
                  );

                  if (currentStage && stage3) {
                    // Iterate through each sortedService in the current stage to find the matching dueType in stage3
                    currentStage?.sortedServices?.forEach((serviceGroup) => {
                      const dueType = serviceGroup?.dueType;

                      // Find the corresponding serviceGroup in stage3 with the same dueType
                      const serviceGroup3 = stage3?.sortedServices?.find(
                        (service) => service?.dueType === dueType
                      );

                      if (serviceGroup3) {
                        // Filter out the patient from the current stage
                        const filteredServices =
                          serviceGroup?.clinicalServices?.filter(
                            (patient) => patient?._id !== clinicalServiceID
                          );

                        // Find the patient object in the current stage
                        const patient = serviceGroup?.clinicalServices?.find(
                          (patient) => patient?._id === clinicalServiceID
                        );

                        if (patient) {
                          // Add the patient to the corresponding serviceGroup in stage3
                          serviceGroup3?.clinicalServices?.push(patient);

                          if (serviceGroup && serviceGroup.clinicalServices) {
                            // Update the clinicalServices array for the current stage
                            serviceGroup.clinicalServices = filteredServices;
                          }
                        }
                      }
                    });
                  }
                });
              });

              setCurrentPharmacyServices(newData);
            } else {
              let mutableData = JSON.parse(JSON.stringify(rowData));

              queue?.forEach((clinicalServiceID) => {
                const stagesToConsider = [1, 2];

                mutableData?.forEach((patient) => {
                  if (patient) {
                    // Check if the patient's stageType is in the stagesToConsider array
                    if (
                      patient.clinicalServiceId === clinicalServiceID &&
                      stagesToConsider.includes(patient.stageType)
                    ) {
                      patient.stageName = "Intervention";
                      patient.stageType = 3;
                    }
                  }
                });
              });

              const stageOrder = {
                Eligible: 1,
                "Awaiting consent": 2,
                Intervention: 3,
                Followup: 4,
              };

              mutableData?.sort((a, b) => {
                return stageOrder[a.stageName] - stageOrder[b.stageName];
              });

              setRowData(mutableData);
            }

            showToast(
              `Record${queue.length > 1 ? "s" : ""
              } moved to Intervention successfully!`
            );
            setInterventionServiceLoading(false);
          } catch (error) {
            showError("Something went wrong");
            setInterventionServiceLoading(false);
          }
        });

        flushAllQueues();
        break;

      case "Orbit":
        setSendToOrbitServiceLoading(true);
        sendToOrbit(queue).then((data) => {
          try {
            if (view === "kanban") {
              const newData = pharmacyServices?.map((stage) => ({
                ...stage,
                sortedServices: stage?.sortedServices?.map((service) => ({
                  ...service,
                  clinicalServices: [...service?.clinicalServices],
                })),
              }));

              const updatedNewData = newData?.map((stage) => {
                if (stage.stageType === 3) {
                  return {
                    ...stage,
                    sortedServices: stage?.sortedServices?.map(
                      (serviceGroup) => ({
                        ...serviceGroup,
                        clinicalServices: serviceGroup?.clinicalServices?.map(
                          (clinicalService) => {
                            const queueItem = queue?.find(
                              (item) =>
                                item.clinicalServiceID === clinicalService._id
                            );
                            if (queueItem) {
                              return {
                                ...clinicalService,
                                sendToOrbit: true,
                              };
                            }
                            return clinicalService;
                          }
                        ),
                      })
                    ),
                  };
                }
                return stage;
              });
              setCurrentPharmacyServices(updatedNewData);

              const stagesWrapperOrbit =
                document.querySelector(".stages-wrapper");
              if (stagesWrapperOrbit) {
                setScrollPosition(stagesWrapperOrbit.scrollLeft);
              }

              const stageIntervention =
                document.getElementById("stage_Intervention");

              if (stageIntervention) {
                setInterventionStageScrollPosition(stageIntervention.scrollTop);
              }
            } else {
              let mutableData = JSON.parse(JSON.stringify(rowData));

              queue?.forEach((queueItem) => {
                // Find patients in transformedPharmacyData matching the clinicalServiceId
                mutableData?.forEach((patient) => {
                  if (patient) {
                    if (
                      patient.clinicalServiceId ===
                      queueItem.clinicalServiceID &&
                      patient.stageType === 3
                    ) {
                      patient.sendToOrbit = true;
                    }
                  }
                });
              });

              const stageOrder = {
                Eligible: 1,
                "Awaiting consent": 2,
                Intervention: 3,
                Followup: 4,
              };

              mutableData?.sort((a, b) => {
                return stageOrder[a.stageName] - stageOrder[b.stageName];
              });

              setRowData(mutableData);
            }

            showToast(
              `Record${queue.length > 1 ? "s" : ""
              } moved to Orbit successfully!`
            );
            setSendToOrbitServiceLoading(false);
          } catch (error) {
            showError("Something went wrong");
            setSendToOrbitServiceLoading(false);
          }
        });

        flushAllQueues();
        break;

      case "Decline":
        setDeclineServiceLoading(true);
        declineServices(queue).then((data) => {
          try {
            if (view === "kanban") {
              const updatedPharmacyServices = originalPharmacyServices?.map(
                (stage) => ({
                  ...stage,
                  sortedServices: stage?.sortedServices?.map(
                    (serviceGroup) => ({
                      ...serviceGroup,
                      clinicalServices: serviceGroup?.clinicalServices.filter(
                        (clinicalService) =>
                          !queue.some(
                            (queueItem) => queueItem === clinicalService._id
                          )
                      ),
                    })
                  ),
                })
              );

              setOriginalPharmacyServices(updatedPharmacyServices);
              setCurrentPharmacyServices(updatedPharmacyServices);
              setFilterNameData("");

              const stagesWrapperOrbit =
                document.querySelector(".stages-wrapper");
              if (stagesWrapperOrbit) {
                setScrollPosition(stagesWrapperOrbit.scrollLeft);
              }

              const stageIntervention =
                document.getElementById("stage_Intervention");

              if (stageIntervention) {
                setInterventionStageScrollPosition(stageIntervention.scrollTop);
              }
            } else {
              let mutableData = JSON.parse(JSON.stringify(rowData));
              if (queue) {
                queue.forEach((queueItem) => {
                  mutableData = mutableData?.filter(
                    (item) => item.clinicalServiceId !== queueItem
                  );
                });
              }

              const stageOrder = {
                Eligible: 1,
                "Awaiting consent": 2,
                Intervention: 3,
                Followup: 4,
              };

              mutableData?.sort((a, b) => {
                return stageOrder[a.stageName] - stageOrder[b.stageName];
              });
              setRowData(mutableData);
            }

            showToast(
              `Record${queue.length > 1 ? "s" : ""} declined successfully!`
            );
            setDeclineServiceLoading(false);
          } catch (error) {
            showError("Something went wrong.");
            setDeclineServiceLoading(false);
          }
        });

        flushAllQueues();
        break;

      case "PDS Sync":
        setPDSSyncServiceLoading(true);
        try {
          let newArr = [];
          for (const clinicalServiceId of queue) {
            const pharmacyID = currentPharmacyId;
            if (pharmacyID && clinicalServiceId) {
              const response = await getPdsData(pharmacyID, clinicalServiceId);
              const { patientGetModel } = response;
              if (patientGetModel !== null) {
                newArr.push(clinicalServiceId);
              }
            }
            setServicesUpdatedData([...newArr]);
          }

          setTimeout(() => {
            setServicesUpdatedData([]);
          }, 2500);
        } catch (error) {
          showError("Something went wrong.");
          setPDSSyncServiceLoading(false);
        }

        setPDSSyncServiceLoading(false);
        flushAllQueues();
        break;

      case "Rescheduling":
        setShowReschedule(true);
        break;
    }
  };

  function flushAllQueues() {
    setCurrentSmsQueue([]);
    setCurrentReSmsQueue([]);
    setCurrentInterventionQueue([]);
    setCurrentOrbitQueue([]);
    setCurrentDeclineQueue([]);
    setCurrentRescheduleQueue([]);
    setCurrentPdsQueue([]);
    setChecksCount(0);
    setRescheduleTargetStageType([]);

    //flush check all listview data
    if (view !== "kanban") {
      setCheckedAllEligible(false);
      setCheckedAllConsent(false);
      setCheckedAllIntervention(false);
      setCheckedAllFollowUp(false);
    }
  }

  function showBottomBar() {
    let show = false;
    show =
      smsQueue?.length > 0 ||
      orbitQueue?.length > 0 ||
      interventionQueue?.length > 0 ||
      declineQueue?.length > 0 ||
      rescheduleQueue?.length > 0 ||
      pdsQueue?.length > 0 ||
      reSmsQueue?.length > 0;
    return show;
  }

  const routeChangePatientForm = async () => {
    setIsPatientFormModal(true);
  };

  async function getPdsData(pharmacyID, clinicalServiceId) {
    var data = await getServiceTitanPatientPds({
      pharmacyID,
      clinicalServiceId,
    });
    return data;
  }

  return (
    <div>
      <AnimatePresence>
        {showBottomBar() === true && (
          <motion.div
            className="sms_sent_action bottom-bar services-container-footer"
            initial={{
              y: 100,
              transition: {
                type: "spring",
                ease: "linear",
                duration: 0.8,
              },
            }}
            animate={{
              y: 0,
              transition: {
                type: "spring",
                ease: "linear",
                duration: 0.8,
              },
            }}
            exit={{
              y: 100,
              transition: { type: "spring", ease: "linear", duration: 0.8 },
            }}
          >
            <div className="task-selected" onClick={flushAllQueues}>
              {checksCount} Task{checksCount > 1 ? "s" : ""} selected
              <RxCross1 />
            </div>
            <div className="d-flex">
              {smsQueue?.length > 0 ? (
                <CustomServiceToolbarButton
                  label="Send SMS"
                  action={() => handleActions("SMS", smsQueue)}
                  iconComponent={
                    sendSMSServiceLoading ? (
                      <SpinnerAnim />
                    ) : (
                      <PiEnvelope size={16} />
                    )
                  }
                />
              ) : null}
              {reSmsQueue?.length > 0 ? (
                <CustomServiceToolbarButton
                  label="Re-Send SMS"
                  action={() => handleActions("Resend SMS", reSmsQueue)}
                  iconComponent={
                    resendSMSServiceLoading ? (
                      <SpinnerAnim />
                    ) : (
                      <FaEnvelopesBulk size={16} />
                    )
                  }
                />
              ) : null}
              {interventionQueue?.length > 0 ? (
                <CustomServiceToolbarButton
                  className={"move_to_intervention"}
                  label="Move to Intervention"
                  action={() =>
                    handleActions("Intervention", interventionQueue)
                  }
                  iconComponent={
                    interventionServiceLoading ? (
                      <SpinnerAnim />
                    ) : (
                      <FaArrowRightToBracket size={11} />
                    )
                  }
                />
              ) : null}
              {orbitQueue?.length > 0 && ifSendToOrbit ? (
                <CustomServiceToolbarButton
                  label={`Send to orbit`}
                  action={() => handleActions("Orbit", orbitQueue)}
                  iconComponent={
                    sendToOrbitServiceLoading ? (
                      <SpinnerAnim />
                    ) : (
                      <LuOrbit size={16} />
                    )
                  }
                />
              ) : null}
              {declineQueue?.length > 0 ? (
                <CustomServiceToolbarButton
                  label="Decline"
                  action={() => handleActions("Decline", declineQueue)}
                  iconComponent={
                    declineServiceLoading ? (
                      <SpinnerAnim />
                    ) : (
                      <TiDeleteOutline size={16} />
                    )
                  }
                />
              ) : null}
              {rescheduleQueue?.length > 0 && (
                <CustomServiceToolbarButton
                  label="Reschedule"
                  action={() => {
                    handleActions("Rescheduling", rescheduleQueue);
                  }}
                  iconComponent={
                    rescheduleServiceLoading ? (
                      <SpinnerAnim />
                    ) : (
                      <FaRegCalendarAlt size={11} />
                    )
                  }
                />
              )}
              {pdsQueue?.length > 0 ? (
                <CustomServiceToolbarButton
                  label="PDS sync"
                  action={() => handleActions("PDS Sync", pdsQueue)}
                  iconComponent={
                    pdsSyncServiceLoading ? (
                      <SpinnerAnim />
                    ) : (
                      <FaSync size={11} />
                    )
                  }
                />
              ) : null}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {serviceName === "Travel Vaccinee" && (
        <motion.div
          className="bottom-bar"
          initial={{
            opacity: 0,
            height: 0,
            transition: {
              duration: 0.8,
            },
          }}
          animate={{
            opacity: 1,
            height: 60,
            transition: {
              duration: 0.8,
            },
          }}
          exit={{
            opacity: 0,
            height: 0,
            transition: {
              duration: 0.8,
            },
          }}
        >
          <button className="btn-class" onClick={routeChangePatientForm}>
            Patient form
          </button>
        </motion.div>
      )}
    </div>
  );
}
