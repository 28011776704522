import React from "react";
import { RxArrowLeft, RxCross2, RxOpenInNewWindow } from "react-icons/rx";
import DPIcon from "./../../../../assets/images/dp-icon.png";
import { formatPhoneNumber } from "../../../../data/formatPhone";
import useTitanApp from "../../../../hooks/useTitanApp";
import { MdCall } from "react-icons/md";
import { FaCircle } from "react-icons/fa";
import DatePicker from "react-datepicker";

export default function Rescheduling({
  handleRescheduleGoBack,
  selectedRescheduleOption,
  handleRescheduleOptionClick,
  handleCloseCallScreenDialog,
  setStartDate,
  startDate,
  followupDate,
  currentHour,
  currentMinute,
  setAdditionalComments,
  additionalComments,
  handleRescheduleFollowup,
  phoneNumber,
}) {
  const { appState } = useTitanApp();
  const { darkMode } = appState;

  return (
    <div className="callScreen_dialog_wrapper" id="callScreen-dialog-title">
      <div className="rescheduling">
        <div className="top-bar">
          <div>
            <RxArrowLeft
              color={darkMode ? "#fff" : "#0a0a0a"}
              size={15}
              className="go-back"
              onClick={handleRescheduleGoBack}
            />
          </div>
          <div className="patient-info">
            <div className="d-flex">
              <p>Call ended</p>
              <FaCircle color="#D0021B" size={6} />
            </div>

            <p className="phone-number">{formatPhoneNumber(phoneNumber)}</p>
          </div>

          <RxCross2
            color={darkMode ? "#fff" : "#0a0a0a"}
            size={15}
            className="collapse-icon"
            onClick={handleCloseCallScreenDialog}
          />
        </div>
        <div className="body">
          <div className="desc">
            <p>Nms not completed.</p>
          </div>
          <div className="options-wrapper">
            <div
              className={`option rescheduling ${
                selectedRescheduleOption === "reschedulePharmacist"
                  ? "selected"
                  : ""
              }`}
              onClick={() =>
                handleRescheduleOptionClick("reschedulePharmacist")
              }
            >
              <div className="flex">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="24" height="24" rx="12" fill="black" />
                  <path
                    d="M9.85714 12.7143C10.2516 12.7143 10.5714 12.3945 10.5714 12C10.5714 11.6055 10.2516 11.2857 9.85714 11.2857C9.46265 11.2857 9.14286 11.6055 9.14286 12C9.14286 12.3945 9.46265 12.7143 9.85714 12.7143Z"
                    fill="white"
                  />
                  <path
                    d="M10.5714 14.1429C10.5714 14.5373 10.2516 14.8571 9.85714 14.8571C9.46265 14.8571 9.14286 14.5373 9.14286 14.1429C9.14286 13.7484 9.46265 13.4286 9.85714 13.4286C10.2516 13.4286 10.5714 13.7484 10.5714 14.1429Z"
                    fill="white"
                  />
                  <path
                    d="M12 12.7143C12.3945 12.7143 12.7143 12.3945 12.7143 12C12.7143 11.6055 12.3945 11.2857 12 11.2857C11.6055 11.2857 11.2857 11.6055 11.2857 12C11.2857 12.3945 11.6055 12.7143 12 12.7143Z"
                    fill="white"
                  />
                  <path
                    d="M12.7143 14.1429C12.7143 14.5373 12.3945 14.8571 12 14.8571C11.6055 14.8571 11.2857 14.5373 11.2857 14.1429C11.2857 13.7484 11.6055 13.4286 12 13.4286C12.3945 13.4286 12.7143 13.7484 12.7143 14.1429Z"
                    fill="white"
                  />
                  <path
                    d="M14.1429 12.7143C14.5373 12.7143 14.8571 12.3945 14.8571 12C14.8571 11.6055 14.5373 11.2857 14.1429 11.2857C13.7484 11.2857 13.4286 11.6055 13.4286 12C13.4286 12.3945 13.7484 12.7143 14.1429 12.7143Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17 8.78571C17 7.79949 16.2005 7 15.2143 7H8.78571C7.79949 7 7 7.79949 7 8.78571V15.2143C7 16.2005 7.79949 17 8.78571 17H15.2143C16.2005 17 17 16.2005 17 15.2143V8.78571ZM7.71429 9.85714H16.2857V15.2143C16.2857 15.806 15.806 16.2857 15.2143 16.2857H8.78571C8.19398 16.2857 7.71429 15.806 7.71429 15.2143V9.85714ZM8.78571 7.71429H15.2143C15.806 7.71429 16.2857 8.19398 16.2857 8.78571V9.14286H7.71429V8.78571C7.71429 8.19398 8.19398 7.71429 8.78571 7.71429Z"
                    fill="white"
                  />
                </svg>

                <p>Reschedule for another pharmacist</p>
              </div>
            </div>
            <div
              className={`option rescheduling ${
                selectedRescheduleOption === "rescheduleMyself"
                  ? "selected"
                  : ""
              }`}
              onClick={() => handleRescheduleOptionClick("rescheduleMyself")}
            >
              <div className="flex">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="24" height="24" rx="12" fill="black" />
                  <path
                    d="M9.85714 12.7143C10.2516 12.7143 10.5714 12.3945 10.5714 12C10.5714 11.6055 10.2516 11.2857 9.85714 11.2857C9.46265 11.2857 9.14286 11.6055 9.14286 12C9.14286 12.3945 9.46265 12.7143 9.85714 12.7143Z"
                    fill="white"
                  />
                  <path
                    d="M10.5714 14.1429C10.5714 14.5373 10.2516 14.8571 9.85714 14.8571C9.46265 14.8571 9.14286 14.5373 9.14286 14.1429C9.14286 13.7484 9.46265 13.4286 9.85714 13.4286C10.2516 13.4286 10.5714 13.7484 10.5714 14.1429Z"
                    fill="white"
                  />
                  <path
                    d="M12 12.7143C12.3945 12.7143 12.7143 12.3945 12.7143 12C12.7143 11.6055 12.3945 11.2857 12 11.2857C11.6055 11.2857 11.2857 11.6055 11.2857 12C11.2857 12.3945 11.6055 12.7143 12 12.7143Z"
                    fill="white"
                  />
                  <path
                    d="M12.7143 14.1429C12.7143 14.5373 12.3945 14.8571 12 14.8571C11.6055 14.8571 11.2857 14.5373 11.2857 14.1429C11.2857 13.7484 11.6055 13.4286 12 13.4286C12.3945 13.4286 12.7143 13.7484 12.7143 14.1429Z"
                    fill="white"
                  />
                  <path
                    d="M14.1429 12.7143C14.5373 12.7143 14.8571 12.3945 14.8571 12C14.8571 11.6055 14.5373 11.2857 14.1429 11.2857C13.7484 11.2857 13.4286 11.6055 13.4286 12C13.4286 12.3945 13.7484 12.7143 14.1429 12.7143Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17 8.78571C17 7.79949 16.2005 7 15.2143 7H8.78571C7.79949 7 7 7.79949 7 8.78571V15.2143C7 16.2005 7.79949 17 8.78571 17H15.2143C16.2005 17 17 16.2005 17 15.2143V8.78571ZM7.71429 9.85714H16.2857V15.2143C16.2857 15.806 15.806 16.2857 15.2143 16.2857H8.78571C8.19398 16.2857 7.71429 15.806 7.71429 15.2143V9.85714ZM8.78571 7.71429H15.2143C15.806 7.71429 16.2857 8.19398 16.2857 8.78571V9.14286H7.71429V8.78571C7.71429 8.19398 8.19398 7.71429 8.78571 7.71429Z"
                    fill="white"
                  />
                </svg>

                <p>Reschedule for myself</p>
              </div>
            </div>
            <div className="option">
              <div className="flex">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="24" height="24" rx="12" fill="black" />
                  <path
                    d="M9.85714 12.7143C10.2516 12.7143 10.5714 12.3945 10.5714 12C10.5714 11.6055 10.2516 11.2857 9.85714 11.2857C9.46265 11.2857 9.14286 11.6055 9.14286 12C9.14286 12.3945 9.46265 12.7143 9.85714 12.7143Z"
                    fill="white"
                  />
                  <path
                    d="M10.5714 14.1429C10.5714 14.5373 10.2516 14.8571 9.85714 14.8571C9.46265 14.8571 9.14286 14.5373 9.14286 14.1429C9.14286 13.7484 9.46265 13.4286 9.85714 13.4286C10.2516 13.4286 10.5714 13.7484 10.5714 14.1429Z"
                    fill="white"
                  />
                  <path
                    d="M12 12.7143C12.3945 12.7143 12.7143 12.3945 12.7143 12C12.7143 11.6055 12.3945 11.2857 12 11.2857C11.6055 11.2857 11.2857 11.6055 11.2857 12C11.2857 12.3945 11.6055 12.7143 12 12.7143Z"
                    fill="white"
                  />
                  <path
                    d="M12.7143 14.1429C12.7143 14.5373 12.3945 14.8571 12 14.8571C11.6055 14.8571 11.2857 14.5373 11.2857 14.1429C11.2857 13.7484 11.6055 13.4286 12 13.4286C12.3945 13.4286 12.7143 13.7484 12.7143 14.1429Z"
                    fill="white"
                  />
                  <path
                    d="M14.1429 12.7143C14.5373 12.7143 14.8571 12.3945 14.8571 12C14.8571 11.6055 14.5373 11.2857 14.1429 11.2857C13.7484 11.2857 13.4286 11.6055 13.4286 12C13.4286 12.3945 13.7484 12.7143 14.1429 12.7143Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17 8.78571C17 7.79949 16.2005 7 15.2143 7H8.78571C7.79949 7 7 7.79949 7 8.78571V15.2143C7 16.2005 7.79949 17 8.78571 17H15.2143C16.2005 17 17 16.2005 17 15.2143V8.78571ZM7.71429 9.85714H16.2857V15.2143C16.2857 15.806 15.806 16.2857 15.2143 16.2857H8.78571C8.19398 16.2857 7.71429 15.806 7.71429 15.2143V9.85714ZM8.78571 7.71429H15.2143C15.806 7.71429 16.2857 8.19398 16.2857 8.78571V9.14286H7.71429V8.78571C7.71429 8.19398 8.19398 7.71429 8.78571 7.71429Z"
                    fill="white"
                  />
                </svg>

                <p>Date/Time</p>
              </div>

              <DatePicker
                className="widgetDatePicker"
                onChange={(date) => {
                  setStartDate(date);
                }}
                showTimeSelect
                selected={startDate}
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="dd/MM/yyyy h:mm aa"
                minDate={new Date()}
                minTime={
                  followupDate &&
                  followupDate.toDateString() === new Date().toDateString()
                    ? new Date().setHours(currentHour, currentMinute + 15, 0, 0)
                    : new Date().setHours(0, 0, 0, 0)
                }
                maxTime={new Date(new Date().setHours(23, 59, 59, 999))}
              />
            </div>
          </div>

          <div className="additional-comments">
            <p>Additional Comments</p>
            <input
              onChange={(e) => {
                setAdditionalComments(e.target.value);
              }}
              type="text"
              value={additionalComments}
              placeholder="Note any patient feedback or concerns"
            />
          </div>

          <div className="calling-footer">
            <button className="done" onClick={handleRescheduleFollowup}>
              <p>Done</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
