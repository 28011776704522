import React from "react";
import { BsChevronRight } from "react-icons/bs";
import "./../../assets/css/CreateCompaign.scss";
import { useNavigate } from "react-router-dom";

export default function CreateCompaign() {
  const navigate = useNavigate();
  const routeChangechecks = () => {};
  return (
    <div className="startchecks_container">
      <button className="startchecks_button">
        <p>Create Compaign</p>
        <BsChevronRight size={14} color="#fff" />
      </button>
    </div>
  );
}
