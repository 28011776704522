import React from "react";
import { BsChevronRight } from "react-icons/bs";
import "./../../assets/css/StartNMS.css";
import { useNavigate } from "react-router-dom";
import useTitanApp from "../../hooks/useTitanApp";

export default function StartNMS({ headerTeamMembers, totalNMSCount }) {
  const { appState } = useTitanApp();
  const { darkMode } = appState || {};
  const navigate = useNavigate();
  const routeChangechecks = () => {
    navigate(`/nmsintervention/0`, {
      state: {
        onDarkModeChange: darkMode,
        serviceType: "Kanban",
        headerTeamMembers: headerTeamMembers,
        clincalServiceType: 1,
      },
    });
  };
  return <div className="startchecks_container"></div>;
}
