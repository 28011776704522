export const PrescriptionSourceTypes = Object.freeze({
  0: "",
  1: "Man",
  2: "R1",
  3: "2DRX",
  4: "2DRX",
  5: "ETP",
  6: "Man",
  7: "Man",
  8: "",
  9: "R2",
  10: "Private",
  11: "Power Mail",
});
