import React from "react";
import "./../../assets/css/NmsIntervention/NmsIntervention.scss";
import "./../../assets/clinicalcheck.css";
import { Skeleton } from "@mui/material";
import useTitanApp from "../../hooks/useTitanApp";

export default function LoadingComponentNMS() {
  const { appState } = useTitanApp();
  const { darkMode } = appState;
  return (
    <div className="">
      <div className="medicine-card customcard loading-card-one">
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={30}
          width={100}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={30}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={30}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={30}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={30}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={30}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={30}
          animation="wave"
          variant="rounded"
        />
      </div>

      <div className="medicine-card customcard loading-card-one">
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={35}
          width={100}
          animation="wave"
          variant="rounded"
        />
        <div className="flex">
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.800" : "", width: "100%" }}
            height={65}
            animation="wave"
            variant="rounded"
          />
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.800" : "", width: "100%" }}
            height={65}
            animation="wave"
            variant="rounded"
          />
        </div>
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={65}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={65}
          animation="wave"
          variant="rounded"
        />
      </div>
      <div className="medicine-card customcard loading-card-one">
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={35}
          width={100}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={35}
          animation="wave"
          variant="rounded"
        />
      </div>
      <div className="medicine-card customcard loading-card-one">
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={30}
          width={100}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={30}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={30}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={30}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={30}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={30}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={30}
          animation="wave"
          variant="rounded"
        />
      </div>
    </div>
  );
}
