export const CampaignServicesData =
    [
        {
            "serviceName": "New Medicine Service",
            "totalPatients": 234,
            "percentageValue": 90
        },
        {
            "serviceName": "Vaccination Service",
            "totalPatients": 150,
            "percentageValue": 80
        },
        {
            "serviceName": "Travel Vaccine",
            "totalPatients": 320,
            "percentageValue": 45
        },
        {
            "serviceName": "Flu Vaccination",
            "totalPatients": 320,
            "percentageValue": 95
        },
        {
            "serviceName": "Health Checkup",
            "totalPatients": 320,
            "percentageValue": 30
        },
        {
            "serviceName": "Allergy testing",
            "totalPatients": 320,
            "percentageValue": 59
        }

    ]
