import React from "react";
import "./../../../../../assets/css/Dashboard/ServicesCalendar.scss";
import CustomScheduler from "./Calender/CustomScheduler";

export default function ServicesCalendar() {
  return (
    <div className="services_content-bg services_calendar">
      <CustomScheduler />
    </div>
  );
}
