import { getObject } from "../getObjectValue";
import prescriptionCheckActions from "./prescriptionCheckActions";
import prescriptionItems from "./prescriptionItems";
import audit from "./audit";
import exemption from "./exemption";
import spineApiRequest from "./spineApiRequest";
import notes from "./notes";
import { v4 as uuidv4 } from "uuid";

class setClinicalChecks {
  constructor(
    response,
    qty,
    dose,
    prescribedItemId,
    isUpdate,
    prescriptionCheckActionsObj,
    PrescriptionItemCheckActionsObj
  ) {
    this.prescriptionId = response.prescriptionId
      ? getObject(response, "prescriptionId")
      : "";
    this.prescriptionIncomingId = response.prescriptionIncomingId
      ? getObject(response, "prescriptionIncomingId")
      : "";
    this.organisationId = response.organisationId
      ? getObject(response, "organisationId")
      : 0;
    this.patientId = response.patientId
      ? getObject(response, "patientId")
      : null;
    this.prescriptionDate = response.prescriptionDate
      ? getObject(response, "prescriptionDate")
      : "";
    this.prescriptionType = response.prescriptionType
      ? getObject(response, "prescriptionType")
      : "";
    this.prescriptionSourceType = response.prescriptionSourceType
      ? getObject(response, "prescriptionSourceType")
      : "";
    this.isDeleted = response.isDeleted
      ? getObject(response, "isDeleted")
      : false;
    this.activeStatusType = 3;
    this.incomingStatusType = response.incomingStatusType
      ? getObject(response, "incomingStatusType")
      : 0;
    this.clinicalCheckDate = response.clinicalCheckDate
      ? getObject(response, "clinicalCheckDate")
      : "";
    this.numberofItems = response.numberofItems
      ? getObject(response, "numberofItems")
      : "";
    this.isHandWritten = response.pisHandWrittenrescriptionId
      ? getObject(response, "isHandWritten")
      : false;
    this.gpnhsNumber = response.gpnhsNumber
      ? getObject(response, "gpnhsNumber")
      : "";
    this.externalSourceBarcode = response.externalSourceBarcode
      ? getObject(response, "externalSourceBarcode")
      : "";
    this.prescriberId = response.prescriberId
      ? getObject(response, "prescriberId")
      : null;
    this.prescriberName = response.prescriberName
      ? getObject(response, "prescriberName")
      : "";
    this.isEmergencyPrescription = response.isEmergencyPrescription
      ? getObject(response, "isEmergencyPrescription")
      : false;
    this.emergencySupplyReason = response.emergencySupplyReason
      ? getObject(response, "emergencySupplyReason")
      : "";
    this.requestedBy = response.requestedBy
      ? getObject(response, "requestedBy")
      : null;
    this.bucketType = response.bucketType
      ? getObject(response, "bucketType")
      : 0;
    this.referenceNumber = response.referenceNumber
      ? getObject(response, "prescriptionId")
      : "";
    this.AccountNumber = response.AccountNumber
      ? getObject(response, "referenceNumber")
      : "";

    this.prescriptionCheckActions =
      prescriptionCheckActionsObj === ""
        ? []
        : new prescriptionCheckActions(prescriptionCheckActionsObj).obj;

    this.prescriptionItems = new prescriptionItems(
      response.prescriptionItems,
      qty,
      dose,
      prescribedItemId,
      isUpdate,
      PrescriptionItemCheckActionsObj
    ).obj;
    this.erdType = response.erdType ? getObject(response, "erdType") : 0;
    // this.audit = new audits(response).obj; //not in res
    this.audit = response.audit
      ? response.audit == null
        ? [
            {
              prescriptionAuditId: "3fa85f64-5717-4562-b3fc-2c963f66afa7",
              prescriptionId: response.prescriptionId,
              prescriptionAuditType: 0,
              value: "Testing",
            },
          ]
        : new audit(response.audit).obj
      : [
          {
            prescriptionAuditId: uuidv4(),
            prescriptionId: response.prescriptionId,
            prescriptionAuditType: 0,
            value: "Testing",
          },
        ];
    this.exemption =
      response.exemption === null
        ? null
        : new exemption(response?.exemption).obj;
    this.doNotPark = response.doNotPark ? response.doNotPark : false; //not in res
    this.sendToParked = response.sendToParked ? response.sendToParked : false; //not in res
    this.spineApiRequest = response.spineApiRequest
      ? response.spineApiRequest == null
        ? { iaToken: "" }
        : new spineApiRequest(response.spineApiRequest).obj
      : { iaToken: "" };

    this.notes = response.notes
      ? response.notes == null
        ? []
        : new notes(response.notes).obj
      : [];
  }
}

export default setClinicalChecks;
