import { getObject } from "../getObjectValue";

class additionalEndorsements {
  constructor(response) {
    this.obj = response?.map((value) => {
      return {
        prescriptionItemEndorseAdditionalId: getObject(
          value,
          "prescriptionItemEndorseAdditionalId"
        ),
        prescriptionItemId: getObject(value, "prescriptionItemId"),
        additionEndorseType: getObject(value, "additionEndorseType"),
        value: getObject(value, "value"),
        quantity: getObject(value, "quantity"),
        vpId: getObject(value, "vpId"),
        expiryDate: getObject(value, "expiryDate"),
        mhraNumber: getObject(value, "mhraNumber")
          ? getObject(value, "mhraNumber")
          : "",
        batchNumber: getObject(value, "batchNumber")
          ? getObject(value, "batchNumber")
          : "",
        details: getObject(value, "details") ? getObject(value, "details") : "",
        isDeleted: getObject(value, "isDeleted"),
        price: getObject(value, "price"),
        packSize: getObject(value, "packSize"),
        supplier: getObject(value, "supplier")
          ? getObject(value, "supplier")
          : "",
        pharmacistInitials: getObject(value, "pharmacistInitials")
          ? getObject(value, "pharmacistInitials")
          : "",
        brand: getObject(value, "brand") ? getObject(value, "brand") : "",
        dispenseDate: getObject(value, "dispenseDate"),
        packagedDoses: getObject(value, "packagedDoses"),
      };
    });
  }
}

export default additionalEndorsements;
