export const setColorTypeClass = (color) => {
  return lightOrDark(color) === "light" ? "tag-light-text" : "tag-dark-text";
};
export const getClassName = (color) => {
  return setColorTypeClass(color);
};
export const lightOrDark = (color) => {
  if (color?.match(/^rgb/)) {
    color = color?.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    var r = color[1];
    var g = color[2];
    var b = color[3];
  } else {
    color = +(
      "0x" + color?.slice(1)?.replace(color.length < 5 && /./g, "$&$&")
    );

    var r = color >> 16;
    var g = (color >> 8) & 255;
    var b = color & 255;
  }

  var hsp = Math.sqrt(0.2126 * (r * r) + 0.7125 * (g * g) + 0.0722 * (b * b));
  if (hsp > 140) {
    return "light";
  } else {
    return "dark";
  }
};
