import { getObject } from "../getObjectValue";
import additionalEndorsements from "./additionalEndorsements";
import dispensingWindow from "./dispensingWindow";
import { gendertypes } from "../GenderTypes/gendertypes";
class setPrescriptionItemChecks {
  constructor(response, qty, dose, prescribedItemId, isUpdate) {
    this.obj = response?.prescriptionItems?.map((value) => {
      return {
        patientId: getObject(response, "patientId"),
        isErd: getObject(response, "isErd"),
        patientGender: response.patient.sex, //not in res
        PrescriptionDate: getObject(response, "prescriptionDate"),
        prescriptionType: getObject(response, "prescriptionType"),
        prescriptionId: getObject(response, "prescriptionId"),
        incomingPrescriptionId: getObject(response, "prescriptionIncomingId"),
        spinePrescriptionTypeCode: response.prescriberCode
          ? getObject(response, "prescriberCode")
          : "", //not in res
        prescriptionTreatmentType:
          response.prescriptionTreatmentType === "Acute"
            ? 0
            : getObject(response, "prescriptionTreatmentType"),
        isEmergencyPrescription: getObject(response, "isEmergencyPrescription"),
        prescriptionSource: getObject(response, "prescriptionSourceType"),

        prescribedItemId: getObject(value, "prescribedItemId"), //more  than one prescribes item
        epsLineItemId: getObject(value, "epsLineItem"), //more  than one prescribes item
        drugMatchType: value.drugMatchType
          ? getObject(value, "drugMatchType")
          : 0, //more  than one prescribes item
        dispensingWindow: value.dispensingWindow
          ? new dispensingWindow(value.dispensingWindow).obj
          : {}, //object //more  than one prescribes item
        isInvalid: value.prescribedItem
          ? getObject(value.prescribedItem, "isInvalid")
          : false, //more  than one prescribes item
        prescriptionItemId: value.prescriptionItemId
          ? getObject(value, "prescriptionItemId")
          : null, //more  than one prescribes item
        quantity:
          isUpdate === true
            ? value.prescriptionItemId === prescribedItemId
              ? qty
              : 0
            : value.quantity
            ? getObject(value, "quantity")
            : 0,
        //more  than one prescribes item
        isExpired: value.isExpired ? getObject(value, "isExpired") : false, //more  than one prescribes item
        additionalEndorsements:
          value.additionalEndorsements == null
            ? []
            : new additionalEndorsements(value.additionalEndorsements).obj, //array of objects//more  than one prescribes item
        doseText:
          isUpdate === true
            ? value.prescriptionItemId === prescribedItemId
              ? dose
              : ""
            : value.doseText
            ? getObject(value, "dosageText")
            : "", //more  than one prescribes item
        performManualCheck: value.performManualCheck
          ? getObject(value, "dosageAutoCheckInfo")
          : false, //more  than one prescribes item
      };
    });
  }
}

export default setPrescriptionItemChecks;
