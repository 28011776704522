import React from "react";

export default function DeliveryControl(props) {
  return (
    <div className="actionIconGroup_iconBox">
      <svg
        width="15"
        height="15"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.33333 7.58327C4.33333 8.03139 3.97006 8.39466 3.52194 8.39466C3.07382 8.39466 2.71054 8.03139 2.71054 7.58327C2.71054 7.13515 3.07382 6.77188 3.52194 6.77188C3.97006 6.77188 4.33333 7.13515 4.33333 7.58327Z"
          className="icons-color"
        />
        <path
          d="M9.47806 8.39466C9.92618 8.39466 10.2895 8.03139 10.2895 7.58327C10.2895 7.13515 9.92618 6.77188 9.47806 6.77188C9.02994 6.77188 8.66667 7.13515 8.66667 7.58327C8.66667 8.03139 9.02994 8.39466 9.47806 8.39466Z"
          fill={props.onDarkModeChange === true ? "#FFFFFF" : "#212121"}
        />
        <path
          d="M1.1206 4.41313L1.13091 4.33332H0.54172C0.242566 4.33332 5.37237e-05 4.09081 5.37237e-05 3.79166C5.37237e-05 3.4925 0.242566 3.24999 0.54172 3.24999H1.27079L1.4465 1.8892C1.586 0.808821 2.50597 0 3.59533 0H9.40273C10.4932 0 11.4137 0.810421 11.5519 1.89209L11.7254 3.24999H12.4583C12.7575 3.24999 13 3.4925 13 3.79166C13 4.09081 12.7575 4.33332 12.4583 4.33332H11.8638L11.8738 4.4113C12.5272 4.62182 13 5.23486 13 5.95832V9.20831C13 10.1058 12.2725 10.8333 11.375 10.8333H11.3717V12.1875C11.3717 12.6362 11.0079 13 10.5592 13C10.1105 13 9.74669 12.6362 9.74669 12.1875V10.8333H3.25V12.1875C3.25 12.6362 2.88623 13 2.4375 13C1.98877 13 1.625 12.6362 1.625 12.1875L1.625 10.8333C0.727537 10.8333 0 10.1058 0 9.20831V5.95832C0 5.23694 0.470065 4.62534 1.1206 4.41313ZM2.52091 2.02793L2.22323 4.33332H10.7717L10.4773 2.02937C10.4082 1.48854 9.94796 1.08333 9.40273 1.08333H3.59533C3.05065 1.08333 2.59066 1.48774 2.52091 2.02793ZM11.375 9.74998C11.6742 9.74998 11.9167 9.50747 11.9167 9.20831V5.95832C11.9167 5.65917 11.6742 5.41665 11.375 5.41665H1.625C1.32585 5.41665 1.08333 5.65917 1.08333 5.95832V9.20831C1.08333 9.50747 1.32585 9.74998 1.625 9.74998H11.375Z"
          className="icons-color"
        />
      </svg>
      <div className="actionIconGroup_iconName">
        <p>Delivery Control</p>
      </div>
    </div>
  );
}
