import React from "react";
import SettingsSidebar from "../SettingsSidebar/SettingsSidebar";
import { Skeleton } from "@mui/material";
import useTitanApp from "../../hooks/useTitanApp";

export default function LocationsLoadingComponent({
  addNewSection,
  setAddNewSection,
  handleGoBack,
  selectedMenuItem,
  handleMenuItemClick,
}) {
  const { appState } = useTitanApp();
  const { features, darkMode } = appState;
  return (
    <div className="settings_page">
      <div className="settings_wrapper">
        <SettingsSidebar
          handleGoBack={handleGoBack}
          selectedMenuItem={selectedMenuItem}
          handleMenuItemClick={handleMenuItemClick}
          features={features}
        />

        <div className="settings_content_wrapper">
          <div className="settings_heading">
            <Skeleton
              sx={{
                bgcolor: darkMode ? "grey.800" : "",
              }}
              height={60}
              width={100}
              animation="wave"
            />
          </div>
          {addNewSection ? (
            <div
              className="back_to_SettingsListView"
              onClick={() => setAddNewSection(false)}
            >
              <Skeleton
                sx={{
                  bgcolor: darkMode ? "grey.800" : "",
                }}
                height={60}
                width={100}
                animation="wave"
              />
            </div>
          ) : (
            <></>
          )}

          <div className={addNewSection ? "" : "settings_content_area"}>
            <div>
              <div className="locations_list_area accordion-group skeleton-loading-boxes">
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={100}
                  animation="wave"
                />
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={100}
                  animation="wave"
                />
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={100}
                  animation="wave"
                />
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={100}
                  animation="wave"
                />
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={100}
                  animation="wave"
                />
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={100}
                  animation="wave"
                />
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={100}
                  animation="wave"
                />
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={100}
                  animation="wave"
                />
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={100}
                  animation="wave"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
