import { getObject } from "../getObjectValue";

class prescriptionItemEmergency {
  constructor(response) {
    this.obj = {
      prescriptionItemEmergencyId: response.prescriptionItemEmergencyId
        ? getObject(response, "prescriptionItemEmergencyId")
        : "3fa85f64-5717-4562-b3fc-2c963f66afa9",
      status: response.status ? getObject(response, "status") : 0,
      emergencyReason: response.emergencyReason
        ? getObject(response, "emergencyReason")
        : "",
      issuedByType: response.issuedByType
        ? getObject(response, "issuedByType")
        : 0,
      quantity: response.quantity ? getObject(response, "quantity") : 0,
    };
  }
}

export default prescriptionItemEmergency;
