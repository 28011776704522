import React from "react";
import OrbitSettingsSidebar from "../../OrbitSettingsSidebar/OrbitSettingsSidebar";
import RegisterationDetails from "./components/RegisterationDetails";
import DbsUpdate from "./components/DbsUpdate";
import Education from "./components/Education";
import RightToWork from "./components/RightToWork";
import Legal from "./components/Legal";
import YearsExperience from "./components/YearsExperience";
import CustomButton from "../../controls/CustomButton";
import AsyncRendererRouter from "../../AsyncRendererRouter/AsyncRendererRouter";
import { useLoaderData } from "react-router-dom";
import useTitanApp from "../../../hooks/useTitanApp";
import CustomLoader from "../../controls/CustomLoader";
import {
  saveOrbitProfile,
  setCurrentProfData,
  setCurrentOrbitProfile,
} from "../../../dispatchers/orbitDispatchers";
import useTimer from "../../../hooks/useTimer";
import { showError } from "../../ToastedMessage/ToastedMessage";
export default function ProfessionalDetails() {
  const { timer, isActive, handleStart, handleReset } = useTimer();

  const { orbitState, appState } = useTitanApp();
  const { isOrbitLoading, profDetails, orbitProfile } = orbitState || {};
  const { darkMode } = appState || {};
  const { appData } = useLoaderData();

  function handleChangeDetails(event) {
    const { name, value } = event?.target || {};
    setCurrentProfData({
      ...profDetails,
      [name]: value,
    });
  }
  function handleChangeDropdown(event) {
    const { target } = event || {};
    const { name, value } = target || {};

    setCurrentProfData({
      ...profDetails,
      [name]: value,
    });
  }
  const handleSwitchChange = (event) => {
    const { name, checked } = event?.target || {};
    setCurrentProfData({
      ...profDetails,
      [name]: checked,
    });
  };
  function handleSave() {
    const obj = {
      ...orbitProfile,
      professionalDetails: profDetails,
    };
    saveOrbitProfile(obj)
      .then((result) => {
        setCurrentOrbitProfile(result);
      })
      .catch(() =>
        showError("Professional Details could not be added.", true, false)
      );
  }
  return (
    <AsyncRendererRouter
      isLoading={isOrbitLoading}
      resolve={appData}
      loadingElement={
        <div className="settings_page">
          <div className="settings_wrapper">
            <OrbitSettingsSidebar />
            <div className="settings_content_wrapper orbit_settings_content_wrapper">
              <CustomLoader />
            </div>
          </div>
        </div>
      }
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <OrbitSettingsSidebar />

          <div className="settings_content_wrapper orbit_settings_content_wrapper">
            <div className="settings_heading">
              <h5>Professional Details</h5>
            </div>
            <div className="proff_details">
              <RegisterationDetails
                professionalDetails={profDetails}
                handleChangeDetails={handleChangeDetails}
                handleSwitchChange={handleSwitchChange}
              />
              <DbsUpdate
                professionalDetails={profDetails}
                handleSwitchChange={handleSwitchChange}
              />
              <Education
                professionalDetails={profDetails}
                handleChangeDetails={handleChangeDetails}
              />
              <RightToWork
                professionalDetails={profDetails}
                handleChangeDropdown={handleChangeDropdown}
                darkMode={darkMode}
              />
              <Legal
                professionalDetails={profDetails}
                handleSwitchChange={handleSwitchChange}
              />
              <YearsExperience
                professionalDetails={profDetails}
                handleChangeDetails={handleChangeDetails}
              />
            </div>
            <div className="footer_btn">
              <CustomButton label="Save" action={handleSave} />
            </div>
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
