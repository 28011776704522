import React, { useState } from "react";
import {
  ComposedChart,
  Line,
  LineChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Tabs, Tab, Skeleton } from "@mui/material";
import NMSProcessed from "./AnalyticsCharts/NMSProcessedChart";
import NMSEarningsChart from "./AnalyticsCharts/NMSEarningsChart";
import StagesChart from "./AnalyticsCharts/StagesChart";
import UserListChart from "./AnalyticsCharts/UserListChart";
import PendingEngagementChart from "./AnalyticsCharts/PendingEngagementChart";
import EngagementChart from "./AnalyticsCharts/EngagementChart";
import FollowUpChart from "./AnalyticsCharts/FollowUpChart";
import InterventionChart from "./AnalyticsCharts/InterventionChart";
import useTitanApp from "../../../../../../hooks/useTitanApp";

export default function ServiceAnalyticsCharts({
  dataSetOne,
  ServicesData,
  servicesDataAPI,
}) {
  const { appState, pharmacyServiceState } = useTitanApp();
  const { darkMode } = appState || {};
  const { isPharmacyAnalyticsLoading } = pharmacyServiceState || {};
  const servicesProcessed = ServicesData
    ? ServicesData?.servicesProcessed
    : servicesDataAPI?.servicesProcessed;
  const totalServicesCompleted = servicesProcessed?.reduce(
    (sum, entry) => sum + entry.servicesCompleted,
    0
  );
  const totalServicesDeclined = servicesProcessed?.reduce(
    (sum, entry) => sum + entry.servicesDeclined,
    0
  );

  const servicesEarning = ServicesData
    ? ServicesData?.serviceEarnings
    : servicesDataAPI?.serviceEarnings;
  const totalEarning = servicesEarning?.reduce(
    (sum, entry) => sum + entry.earning,
    0
  );
  const totalPotentialEarning = servicesEarning?.reduce(
    (sum, entry) => sum + entry.potentialEarning,
    0
  );

  const servicesStages = ServicesData
    ? ServicesData?.serviceStages
    : servicesDataAPI?.serviceStages || [];

  const pendingEngagement = servicesStages?.filter((item) => item.stage === 1);
  const pendingEngagementCounts = pendingEngagement?.reduce(
    (sum, entry) => sum + entry.servicesCount,
    0
  );

  const Engagement = servicesStages?.filter((item) => item.stage === 2);
  const EngagementCounts = Engagement?.reduce(
    (sum, entry) => sum + entry.servicesCount,
    0
  );

  const FollowUp = servicesStages?.filter((item) => item.stage === 4);
  const FollowUpCounts = FollowUp?.reduce(
    (sum, entry) => sum + entry.servicesCount,
    0
  );

  const Intervention = servicesStages?.filter((item) => item.stage === 3);
  const InterventionCounts = Intervention?.reduce(
    (sum, entry) => sum + entry.servicesCount,
    0
  );

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTabContent = () => {
    switch (value) {
      case 0:
        return (
          <div className="service_analytics">
            <PendingEngagementChart
              dataSetOne={dataSetOne}
              ServicesData={ServicesData}
              servicesDataAPI={servicesDataAPI}
            />

            <div className="chart-stages">
              <div>
                <h6>{pendingEngagementCounts}</h6>
                <span className="title-heading">Eligible</span>
              </div>

              <div className="analytic-button">
                <span className="title-heading">
                  {pendingEngagementCounts}%
                </span>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="service_analytics">
            <EngagementChart
              dataSetOne={dataSetOne}
              ServicesData={ServicesData}
              servicesDataAPI={servicesDataAPI}
            />

            <div className="chart-stages">
              <div>
                <h6>{EngagementCounts}</h6>
                <span className="title-heading">Awaiting consent</span>
              </div>

              <div className="analytic-button">
                <span className="title-heading">{EngagementCounts}%</span>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="service_analytics">
            <InterventionChart
              dataSetOne={dataSetOne}
              ServicesData={ServicesData}
              servicesDataAPI={servicesDataAPI}
            />

            <div className="chart-stages">
              <div>
                <h6>{InterventionCounts}</h6>
                <span className="title-heading">Intervention</span>
              </div>

              <div className="analytic-button">
                <span className="title-heading">{InterventionCounts}%</span>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="service_analytics">
            <FollowUpChart
              dataSetOne={dataSetOne}
              ServicesData={ServicesData}
              servicesDataAPI={servicesDataAPI}
            />

            <div className="chart-stages">
              <div>
                <h6>{FollowUpCounts}</h6>
                <span className="title-heading">Follow Up</span>
              </div>

              <div className="analytic-button">
                <span className="title-heading">{FollowUpCounts}%</span>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="analytics_charts_wrapper">
      <div className="service_analytics">
        {isPharmacyAnalyticsLoading ? (
          <Skeleton
            sx={{
              bgcolor: darkMode ? "grey.900" : "",
            }}
            height={50}
            width={120}
            animation="wave"
          />
        ) : (
          <div className="chart-heading">
            <span className="title-heading">NMS Processed</span>
          </div>
        )}

        <br></br>
        {isPharmacyAnalyticsLoading ? (
          <Skeleton
            sx={{
              bgcolor: darkMode ? "grey.900" : "",
            }}
            animation="wave"
            height={450}
          />
        ) : (
          <>
            <div className="analytics_chart">
              <NMSProcessed
                dataSetOne={dataSetOne}
                ServicesData={ServicesData}
                servicesDataAPI={servicesDataAPI}
              />
            </div>
            <div className="chart-buttons">
              <div className="analytic-desc">
                <div className="d-flex">
                  <h6>{totalServicesCompleted}</h6>
                  <span className="title-heading">Completed</span>
                </div>
              </div>
              <div className="analytic-desc">
                <div className="d-flex">
                  <h6 className="declined-count">{totalServicesDeclined}</h6>
                  <span className="chart_description">Declined</span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="service_analytics">
        <div>
          {isPharmacyAnalyticsLoading ? (
            <Skeleton
              sx={{
                bgcolor: darkMode ? "grey.900" : "",
              }}
              height={50}
              width={120}
              animation="wave"
            />
          ) : (
            <div className="chart-heading">
              <span className="title-heading">NMS Earnings</span>
            </div>
          )}

          <br></br>
          {isPharmacyAnalyticsLoading ? (
            <Skeleton
              sx={{
                bgcolor: darkMode ? "grey.900" : "",
              }}
              animation="wave"
              height={450}
            />
          ) : (
            <>
              <div className="analytics_chart">
                <NMSEarningsChart
                  dataSetOne={dataSetOne}
                  ServicesData={ServicesData}
                  servicesDataAPI={servicesDataAPI}
                />
              </div>
              <div className="chart-buttons">
                <div className="analytic-desc">
                  <div className="d-flex">
                    <h6>£{totalEarning}</h6>
                    <span className="chart_description">Earnings</span>
                  </div>
                  <span className="title-heading">{totalEarning}%</span>
                </div>
                <div className="analytic-desc">
                  <div className="d-flex">
                    <h6 className="declined-count">£{totalPotentialEarning}</h6>
                    <span className="chart_description">
                      Potential Earnings
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="service_analytics">
        <div className="analytics_chart">
          {isPharmacyAnalyticsLoading ? (
            <Skeleton
              sx={{
                bgcolor: darkMode ? "grey.900" : "",
              }}
              height={50}
              width={120}
              animation="wave"
            />
          ) : (
            <div className="chart-heading">
              <span className="title-heading">Users</span>
            </div>
          )}
        </div>
        {isPharmacyAnalyticsLoading ? (
          <Skeleton
            sx={{
              bgcolor: darkMode ? "grey.900" : "",
            }}
            animation="wave"
            height={450}
          />
        ) : (
          <UserListChart
            ServicesData={ServicesData}
            servicesDataAPI={servicesDataAPI}
          />
        )}
      </div>

      <div className="service_analytics">
        <div className="analytics_chart">
          {isPharmacyAnalyticsLoading ? (
            <Skeleton
              sx={{
                bgcolor: darkMode ? "grey.900" : "",
              }}
              height={50}
              width={120}
              animation="wave"
            />
          ) : (
            <div className="chart-heading">
              <span className="title-heading">Stages</span>
            </div>
          )}

          {isPharmacyAnalyticsLoading ? (
            <Skeleton
              sx={{
                bgcolor: darkMode ? "grey.900" : "",
              }}
              animation="wave"
              height={450}
            />
          ) : (
            <div className="stages_tabs_container">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Tabs"
                TabIndicatorProps={{
                  className: "tab_indicator_line",
                  style: {
                    backgroundColor: "#000",
                    marginTop: "20",
                    marginLeft: "20",
                  },
                  tabbartabstyle: {
                    width: "auto",
                    padding: 0,
                    marginRight: 32,
                  },
                }}
              >
                <Tab
                  label={
                    <span
                      // className={`tabs_text notification-serv`}
                      className={`tabs_text notification-serv ${
                        value === 0 ? "selected" : ""
                      }`}
                    >
                      Eligible
                      <div className="notification-count">
                        <p>{pendingEngagementCounts}</p>
                      </div>
                    </span>
                  }
                />

                <Tab
                  label={
                    <span
                      // className={`tabs_text notification-serv`}
                      className={`tabs_text notification-serv ${
                        value === 1 ? "selected" : ""
                      }`}
                    >
                      Awaiting consent
                      <div className="notification-count">
                        <p>{EngagementCounts}</p>
                      </div>
                    </span>
                  }
                />
                <Tab
                  label={
                    <span
                      className={`tabs_text notification-serv ${
                        value === 2 ? "selected" : ""
                      }`}
                    >
                      Intervention
                      <div className="notification-count">
                        <p>{InterventionCounts}</p>
                      </div>
                    </span>
                  }
                />
                <Tab
                  label={
                    <span
                      className={`tabs_text notification-serv ${
                        value === 3 ? "selected" : ""
                      }`}
                    >
                      Follow Up
                      <div className="notification-count">
                        <p>{FollowUpCounts}</p>
                      </div>
                    </span>
                  }
                />
              </Tabs>

              {renderTabContent()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
