import React from "react";

export default function PhysicalActivity() {
  return (
    <div className="card-shaped-box">
      <div className="tasks_header">
        <h3 className="pharmacy_header_name">Physical Activity</h3>
      </div>
      <div className="card-shaped-content">
        <p className="description">
          This week, on average, Jessica did 12,031 steps per day. This is down
          1.3% from last week
        </p>

        <div className="heart-boxes">
          <div className="heart_box">
            <div className="heart_count">
              <p>12k</p>
            </div>
            <div className="heart_desc">
              <p>Steps per day</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
