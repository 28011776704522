import React, { useEffect } from "react";
import "./../../assets/css/Orbit.css";
import { useLoaderData } from "react-router-dom";
import OrbitHeader from "./components/OrbitHeader";
import OrbitUserImage from "./components/OrbitUserImage";
import OrbitGrid from "./components/OrbitGrid/OrbitGrid";
import OrbitActionGroup from "./components/OrbitActionGroup";
import Lottie from "lottie-react";
import OrbitLightBG from "./../../assets/OrbitLightBG.json";
import OrbitDarkBGNew from "./../../assets/OrbitDarkBGNew.json";
import useTitanApp from "../../hooks/useTitanApp";
import OrbitEndDialog from "./components/OrbitGrid/OrbitEndDialog";
import CustomLoader from "../../components/controls/CustomLoader";
import { setDarkMode } from "../../dispatchers/appDispatchers";
import useTimer from "../../hooks/useTimer";
import {
  addOrbitSession,
  setCurrentOrbitAnimation,
  setCurrentTimeActive,
  setCurrentTotalPrice,
} from "../../dispatchers/orbitDispatchers";
import setAddSession from "../../Model/Orbit/setAddSession";
import OrbitTestMode from "./components/OrbitGrid/OrbitTestMode";
import AsyncRendererRouter from "../../components/AsyncRendererRouter/AsyncRendererRouter";
export default function Orbit() {
  const { appState, orbitState, onboardingVerificationState } = useTitanApp();
  const { handleStart } = useTimer();

  const { darkMode, isOrbitUser } = appState || {};
  const { isAnimationPlaying, isOrbitLoading, orbitDashboardData, isActive } =
    orbitState || {};
  const { userData } = onboardingVerificationState || {};
  const userFullName = `${userData?.firstName} ${userData?.lastName}`;
  const userEmail = userData?.emailAddress;
  useEffect(() => {
    const storedOption = localStorage.getItem("selectedOption");
    const sessionData = localStorage.getItem("sessionData");
    if (storedOption === "orbit" || isOrbitUser === true) {
      if (sessionData === null) {
        setCurrentTimeActive(true);
        const startTimeModel = new setAddSession(userFullName, userEmail).obj;
        setCurrentTotalPrice(0);
        setCurrentOrbitAnimation(true);
        handleStart();
        addOrbitSession(startTimeModel).then((data) => {
          const sessionDataString = JSON.stringify(data);
          localStorage.setItem("sessionData", sessionDataString);
          const session = localStorage.getItem("sessionData");
          return data;
        });
      }
    }
  }, []);

  useEffect(() => {
    setDarkMode(true);
  }, []);

  const loaderAppData = useLoaderData();
  return (
    <AsyncRendererRouter
      isLoading={isOrbitLoading}
      resolve={loaderAppData?.appData}
    >
      <div>
        <div className="orbit_page">
          {isActive && (
            <div className="orbit_bg">
              <Lottie
                animationData={darkMode ? OrbitDarkBGNew : OrbitLightBG}
              />
            </div>
          )}
          <div className="orbit_page_wrapper">
            <OrbitHeader />
            <OrbitUserImage />
            <OrbitGrid />

            {isAnimationPlaying && <OrbitEndDialog />}
            <OrbitTestMode />
          </div>
          <div className="orbit_footer">{isActive && <OrbitActionGroup />}</div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
