import React from "react";
import "./../../../assets/css/OnboardingVerification.css";
import { ReactComponent as WaveSVG } from "./../../../assets/images/wave.svg";
import CustomButton from "../../controls/CustomButton";

export default function OnboardingWelcome({ handleWelcomeButtonClick }) {
  return (
    <div className="ov_main_content_container">
      <div className="ov_inner_wrapper">
        <WaveSVG />
        <h1>Welcome!</h1>
        <p>
          Welcome to Titan Teams. Before we get you up and running, we need to
          check a few items on our list. We will save your progress, so you can
          return back and complete later.
        </p>
      </div>
      <div className="ov_footer">
        <CustomButton
          label="Let's get started"
          action={handleWelcomeButtonClick}
        />
      </div>
    </div>
  );
}
