import React from "react";
import SettingsSidebar from "../../../../SettingsSidebar/SettingsSidebar";
import TypesList from "./components/TypesList/TypesList";
import CustomLoader from "../../../../controls/CustomLoader";
import AsyncRendererRouter from "../../../../AsyncRendererRouter/AsyncRendererRouter";
import { useLoaderData } from "react-router";
import useTitanType from "../../../../../hooks/useTitanType";
import TypesLoadingComponent from "../../../../SettingsLoadingComponents/TypesLoadingComponent";

export default function Types() {
  const { typeState } = useTitanType();
  const { isTypeLoading } = typeState || {};

  const { appData } = useLoaderData() || {};

  return (
    <AsyncRendererRouter
      resolve={appData}
      isLoading={isTypeLoading}
      loadingElement={<TypesLoadingComponent />}
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <SettingsSidebar />
          <div className="settings_content_wrapper">
            <div className="settings_heading">
              <h1>Services</h1>
            </div>
            <div className="settings_content_area members_wrapper">
              <TypesList />
            </div>
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
