import React, { useState } from "react";
import "./../../assets/css/OnboardingVerification.css";
import useTitanApp from "../../hooks/useTitanApp";
import OnboardingWelcome from "./components/OnboardingWelcome";
import OnboardingYourDetails from "./components/OnboardingYourDetails";
import OnboardingYoti from "./components/OnboardingYoti";
import OnboardingTermsConditions from "./components/OnboardingTermsConditions";
import { getIdentityVerification } from "../../dispatchers/pharmacyServiceDispatchers";
export default function OnboardingVerification({ root }) {
  const { appState, onboardingVerificationState } = useTitanApp();
  const { darkMode } = appState || {};
  const { userData } = onboardingVerificationState || {};
  const [detailSection, setDetailSection] = useState(false);
  const [verificationSection, setVerificationSection] = useState(false);
  const [termsSection, setTermsSection] = useState(false);

  const handleWelcomeButtonClick = () => {
    if (userData?.emailAddress === "") {
      setDetailSection(true);
    } else {
      getIdentityVerification().then((data) => {
        setVerificationSection(true);
        return data;
      });
    }
  };

  const handleDetailSectionNext = () => {
    setDetailSection(false);
    getIdentityVerification().then((data) => {
      setVerificationSection(true);
      return data;
    });
  };
  const handleVerificationSectionNext = () => {
    setVerificationSection(false);
    setTermsSection(true);
  };

  return (
    <div className="ov_page_wrapper">
      <div className="ov_sidebar">
        <svg
          width="72"
          height="19"
          viewBox="0 0 72 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M66.9502 16.562C66.9502 16.2267 67.012 15.9087 67.1359 15.6081C67.2597 15.308 67.4322 15.0476 67.6536 14.8265C67.8747 14.6057 68.1356 14.4336 68.4367 14.3097C68.7374 14.1863 69.056 14.1241 69.3925 14.1241C69.7284 14.1241 70.047 14.1863 70.3481 14.3097C70.6488 14.4336 70.9098 14.6057 71.1312 14.8265C71.3524 15.0476 71.5249 15.308 71.6489 15.6081C71.7725 15.9087 71.8347 16.2267 71.8347 16.562C71.8347 16.8979 71.7725 17.2159 71.6489 17.516C71.5249 17.8167 71.3524 18.0771 71.1312 18.2978C70.9098 18.5188 70.6488 18.6911 70.3481 18.8146C70.047 18.9379 69.7284 19 69.3925 19C69.056 19 68.7374 18.9379 68.4367 18.8146C68.1356 18.6911 67.8747 18.5188 67.6536 18.2978C67.4322 18.0771 67.2597 17.8167 67.1359 17.516C67.012 17.2159 66.9502 16.8979 66.9502 16.562Z"
            fill={darkMode ? "white" : "black"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.50414 18.8409V4.37238H0V0H11.7071V4.37238H8.12335V18.8409H3.50414Z"
            fill={darkMode ? "white" : "black"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1138 18.8409H17.7329V0H13.1138V18.8409Z"
            fill={darkMode ? "white" : "black"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.8293 18.8409V4.37238H19.3252V0H31.0323V4.37238H27.4485V18.8409H22.8293Z"
            fill={darkMode ? "white" : "black"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38.279 6.17435L36.3941 12.2162H40.1374L38.279 6.17435ZM34.1909 18.8409H29.147L36.3942 0H40.164L47.4112 18.8409H42.3142L41.3851 16.1115H35.1467L34.1909 18.8409Z"
            fill={darkMode ? "white" : "black"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.2336 18.8409V0H52.4015L59.3302 10.4672V0H63.9493V18.8409H59.8878L52.8527 8.34725V18.8409H48.2336Z"
            fill={darkMode ? "white" : "black"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M66.9502 16.562C66.9502 16.2267 67.012 15.9087 67.1359 15.6081C67.2597 15.308 67.4322 15.0476 67.6536 14.8265C67.8747 14.6057 68.1356 14.4336 68.4367 14.3097C68.7374 14.1863 69.056 14.1241 69.3925 14.1241C69.7284 14.1241 70.047 14.1863 70.3481 14.3097C70.6488 14.4336 70.9098 14.6057 71.1312 14.8265C71.3524 15.0476 71.5249 15.308 71.6489 15.6081C71.7725 15.9087 71.8347 16.2267 71.8347 16.562C71.8347 16.8979 71.7725 17.2159 71.6489 17.516C71.5249 17.8167 71.3524 18.0771 71.1312 18.2978C70.9098 18.5188 70.6488 18.6911 70.3481 18.8146C70.047 18.9379 69.7284 19 69.3925 19C69.056 19 68.7374 18.9379 68.4367 18.8146C68.1356 18.6911 67.8747 18.5188 67.6536 18.2978C67.4322 18.0771 67.2597 17.8167 67.1359 17.516C67.012 17.2159 66.9502 16.8979 66.9502 16.562Z"
            fill={darkMode ? "white" : "black"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0V4.37238H3.50425V18.8409H8.12335V4.37238H11.7072V0H0Z"
            fill={darkMode ? "white" : "black"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1138 18.8409H17.7329V0H13.1138V18.8409Z"
            fill={darkMode ? "white" : "black"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.3252 0V4.37238H22.8294V18.8409H27.4485V4.37238H31.0324V0H19.3252Z"
            fill={darkMode ? "white" : "black"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.3942 0L29.147 18.8409H34.1909L35.1466 16.1115H41.385L42.3141 18.8409H47.4112L40.1639 0H36.3942ZM38.2789 6.17435L40.1373 12.2163H36.3941L38.2789 6.17435Z"
            fill={darkMode ? "white" : "black"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M59.3302 0V10.4672L52.4015 0H48.2336V18.8409H52.8527V8.34725L59.8877 18.8409H63.9494V0H59.3302Z"
            fill={darkMode ? "white" : "black"}
          />
        </svg>
        <div className="steps">
          <div
            className={`step_wrapper ${
              detailSection === true &&
              verificationSection === false &&
              termsSection === false
                ? "active"
                : "disabled"
            }`}
          >
            <div className="heading">
              <p className="number">1</p>
              <p className="title">Your details</p>
            </div>
            <p>
              We will need your name, GPHC number and a few other contact
              details.
            </p>
          </div>
          <div
            className={`step_wrapper ${
              detailSection === false &&
              verificationSection === true &&
              termsSection === false
                ? "active"
                : "disabled"
            }`}
          >
            <div className="heading">
              <p className="number">2</p>
              <p className="title">Your verification</p>
            </div>
            <p>
              We will need your name, GPHC number and a few other contact
              details.
            </p>
          </div>
          <div
            className={`step_wrapper ${
              detailSection === false &&
              verificationSection === false &&
              termsSection === true
                ? "active"
                : "disabled"
            }`}
          >
            <div className="heading">
              <p className="number">3</p>
              <p className="title">Terms</p>
            </div>
            <p>
              We will need your name, GPHC number and a few other contact
              details.
            </p>
          </div>
        </div>
      </div>
      <div className="main_content">
        {detailSection === false &&
        verificationSection === false &&
        termsSection === false ? (
          <OnboardingWelcome
            handleWelcomeButtonClick={handleWelcomeButtonClick}
          />
        ) : detailSection === true &&
          verificationSection === false &&
          termsSection === false ? (
          <OnboardingYourDetails
            handleDetailSectionNext={handleDetailSectionNext}
          />
        ) : detailSection === false &&
          verificationSection === true &&
          termsSection === false ? (
          <OnboardingYoti
            handleVerificationSectionNext={handleVerificationSectionNext}
            root={root}
          />
        ) : detailSection === false &&
          verificationSection === false &&
          termsSection === true ? (
          <OnboardingTermsConditions root={root} />
        ) : null}
      </div>
    </div>
  );
}
