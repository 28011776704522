import { dispatch, getDataFromStore } from "../store/store";
import { logError } from "../services/logService";
import {
  setIsBillingLoading,
  setBillingData,
  setCompanyNameData,
  setCompanyNumberData,
  setCompanyAddressData,
  setBankNameData,
  setAccountNumberData,
  setSortCodeData,
  getBillingData,
  upsertBillingData,
  getInvoiceUrlData
} from "../features/billingSlice/billingSlice";
import { getApplicationData } from "./appDispatchers";
const reducerName = "billing";

// #region State Setters

export const setCurrentBillingData = (billingData) => {
  dispatch(setBillingData(billingData));
};

export const setBillingLoading = (isLoading) => {
  dispatch(setIsBillingLoading(isLoading));
};

export const setCompanyName = (email) => {
  dispatch(setCompanyNameData(email));
};

export const setCompanyNumber = (email) => {
  dispatch(setCompanyNumberData(email));
};

export const setCompanyAddress = (email) => {
  dispatch(setCompanyAddressData(email));
};

export const setBankName = (email) => {
  dispatch(setBankNameData(email));
};

export const setAccountNumber = (email) => {
  dispatch(setAccountNumberData(email));
};

export const setSortCode = (email) => {
  dispatch(setSortCodeData(email));
};

// #region API getter/caller functions for components usage to dispatch REDUX extra reducers

export const getBillingContact = async () => {
  let result = {};
  try {
    result = await dispatch(getBillingData()).unwrap();
  } catch (error) {
    result = {};
  }
  return result;
};

export const upsertBillingContact = async (data) => {
  let result = null;

  try {
    result = await dispatch(upsertBillingData(data)).unwrap();
    await getApplicationData();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const getInvoiceUrl = async (invoiceId) => {
  let result = {};
  try {
    result = await dispatch(getInvoiceUrlData(invoiceId)).unwrap();
  } catch (error) {
    result = {};
  }
  return result;
};

// #endregion
