import React from "react";
import { useNavigate } from "react-router-dom";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import useTitanApp from "../../../hooks/useTitanApp";
import { setHealthyAdvice } from "../../../dispatchers/consentDispatchers";

function PersonalInfo() {
  const navigate = useNavigate();
  const { consentState } = useTitanApp();
  const {
    patientWeight,
    patientHeight,
    doPatientSmoke,
    doPatientDrink,
    doPatientDoExercise,
    healthyAdvice,
  } = consentState || {};

  const handleOptionChange = (event) => {
    const { name, value } = event?.target || {};
    if (value === "") {
      const newArray = healthyAdvice?.filter((item) => item !== name);
      setHealthyAdvice(newArray);
    } else {
      if (!healthyAdvice?.includes(name)) {
        setHealthyAdvice([...healthyAdvice, name]);
      }
    }
  };
  function handleGoBack() {
    navigate("/NewMedicineService");
  }
  function handleStepNo() {
    navigate("/makeBooking");
  }

  return (
    <>
      <div className="consent_page_header">
        <svg
          className="nhsuk-logo"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 40 16"
          height="40"
          width="100"
        >
          <path
            className="nhsuk-logo__background"
            fill="#005eb8"
            d="M0 0h40v16H0z"
          ></path>
          <path
            className="nhsuk-logo__text"
            fill="#fff"
            d="M3.9 1.5h4.4l2.6 9h.1l1.8-9h3.3l-2.8 13H9l-2.7-9h-.1l-1.8 9H1.1M17.3 1.5h3.6l-1 4.9h4L25 1.5h3.5l-2.7 13h-3.5l1.1-5.6h-4.1l-1.2 5.6h-3.4M37.7 4.4c-.7-.3-1.6-.6-2.9-.6-1.4 0-2.5.2-2.5 1.3 0 1.8 5.1 1.2 5.1 5.1 0 3.6-3.3 4.5-6.4 4.5-1.3 0-2.9-.3-4-.7l.8-2.7c.7.4 2.1.7 3.2.7s2.8-.2 2.8-1.5c0-2.1-5.1-1.3-5.1-5 0-3.4 2.9-4.4 5.8-4.4 1.6 0 3.1.2 4 .6"
          ></path>
        </svg>
      </div>
      <div className="nhsuk_container">
        <div className="nhsuk-width-container ">
          <div className="nhsuk-back-link nhsuk-u-margin-top-4 nhsuk-u-margin-bottom-0">
            <a className="nhsuk-back-link__link" onClick={handleGoBack}>
              <svg
                className="nhsuk-icon nhsuk-icon__chevron-left"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="24"
                width="24"
              >
                <path d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"></path>
              </svg>
              Back
            </a>
          </div>
          <main
            className="nhsuk-main-wrapper nhsuk-main-wrapper--s"
            id="maincontent"
            role="main"
          >
            <div className="nhsuk-grid-row">
              <div className="nhsuk-grid-column-two-thirds">
                <div className="nhsuk-form-group">
                  <fieldset className="nhsuk-fieldset">
                    <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--l">
                      <h1 className="nhsuk-fieldset__heading">
                        NEW MEDICINE SERVICE
                      </h1>
                    </legend>
                    <h4>Fantastic</h4>
                    <p>
                      Thank you for accepting our offer to get the most out of your medicine.
                      To help us make sure we give the best advice, please could tell us a little about yourself.
                    </p>

                    <div className="nhsuk-radios">
                      <h6>What is your weight ?</h6>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="WeightManagment"
                          value={patientWeight}
                          onChange={handleOptionChange}
                        >
                          <FormControlLabel value="50_to_70_kg" control={<Radio />} label="50 to 70 kg" />
                          <FormControlLabel value="71_to_100_kg" control={<Radio />} label="71 to 100 kg" />
                          <FormControlLabel value="" control={<Radio />} label="Over 100 kg" />
                        </RadioGroup>
                      </FormControl>
                    </div>

                    <div className="nhsuk-radios">
                      <h6>What is your height ?</h6>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="patientHeight"
                          value={patientHeight}
                        // onChange={handleOptionChange}
                        >
                          <FormControlLabel value="4 to 5ft" control={<Radio />} label="4 to 5ft" />
                          <FormControlLabel value="5 to 6ft" control={<Radio />} label="5 to 6ft" />
                          <FormControlLabel value="6 to 7ft" control={<Radio />} label="6 to 7ft" />
                        </RadioGroup>
                      </FormControl>
                    </div>

                    <div className="nhsuk-radios">
                      <h6>Do you smoke ?</h6>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="Smoking"
                          value={doPatientSmoke}
                          onChange={handleOptionChange}
                        >
                          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                          <FormControlLabel value="Sometime" control={<Radio />} label="Sometime" />
                          <FormControlLabel value="" control={<Radio />} label="No" />
                        </RadioGroup>
                      </FormControl>
                    </div>

                    <div className="nhsuk-radios">
                      <h6>Do you drink alcohol ?</h6>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="Alcohol"
                          value={doPatientDrink}
                          onChange={handleOptionChange}
                        >
                          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                          <FormControlLabel value="Sometime" control={<Radio />} label="Sometime" />
                          <FormControlLabel value="" control={<Radio />} label="No" />
                        </RadioGroup>
                      </FormControl>
                    </div>

                    <div className="nhsuk-radios">
                      <h6>How much exercise do you do per week ?</h6>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="PhysicalActivity"
                          value={doPatientDoExercise}
                          onChange={handleOptionChange}
                        >
                          <FormControlLabel value="" control={<Radio />} label="30 - 60 mins per week" />
                          <FormControlLabel value="1-2 hours per week" control={<Radio />} label="1 - 2 hours per week" />
                          <FormControlLabel value="2-5 hours per week" control={<Radio />} label="2 - 5 hours per week" />
                        </RadioGroup>
                      </FormControl>
                    </div>

                  </fieldset>
                </div>

                <button onClick={handleStepNo} className="nhsuk-button">
                  Continue
                </button>
                <div
                  onClick={handleStepNo}
                  className="nhsuk-link">
                  <a>Skip this step</a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default PersonalInfo;
