import React, { useState } from "react";
import ImageUploading from "react-images-uploading";
import { IoCloudUploadSharp } from "react-icons/io5";
import { GrUpdate, GrTrash } from "react-icons/gr";
import TextField from "@mui/material/TextField";
import useTitanApp from "../../../hooks/useTitanApp";
import CustomButton from "../../controls/CustomButton";
import CustomLoader from "../../../components/controls/CustomLoader";
import * as Yup from "yup";
import {
  updateUserByUserEmail,
  setUser,
} from "../../../dispatchers/onboardingVerificationDsipatchers";
import { showError } from "../../ToastedMessage/ToastedMessage";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  emailAddress: Yup.string().required("Email is required"),
  password: Yup.string().required("Passowrd is required"),
  phoneNumber: Yup.string()
    .required("Mobile Number is required")
    .matches(/^(07\d{8,12}|447\d{7,11})$/, "Invalid phone number format"),
});

export default function OnboardingYourDetails({ handleDetailSectionNext }) {
  const navigate = useNavigate();

  const { appState, onboardingVerificationState, auth } = useTitanApp();
  const { darkMode } = appState || {} || {};
  const { isonboardingVerificationLoading, userData } =
    onboardingVerificationState || {};
  const { user } = auth || {};
  const userEmail = user?.email;
  const [validationErrors, setValidationErrors] = useState({});
  function handleChangeDetails(event) {
    const { name, value } = event.target;
    setUser({
      ...userData,
      [name]: value,
    });

    // if (userData?.emailAddress === "") {
    //   setUser({
    //     ...userData,
    //     emailAddress: userEmail,
    //   });
    // }
  }

  const [profileImage, setProfileImage] = React.useState(null);

  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    if (imageList?.length > 0) {
      setProfileImage(imageList[0]);
      setUser({
        ...userData,
        userImage: imageList[0]?.data_url,
      });
    } else {
      setProfileImage(null);
    }
  };

  const handleImageRemove = () => {
    setProfileImage(null);
  };

  const handleDetailData = async () => {
    try {
      await validationSchema.validate(userData, { abortEarly: false });
      updateUserByUserEmail(userData, userEmail ?? userData?.emailAddress)
        .then((data) => {
          navigate("/");
        })
        .catch(() =>
          showError("User Details could not be updated.", true, false)
        );
    } catch (errors) {
      const newValidationErrors = {};
      errors.inner.forEach((error) => {
        newValidationErrors[error.path] = error.message;
      });
      setValidationErrors(newValidationErrors);
    }
  };

  return isonboardingVerificationLoading ? (
    <CustomLoader />
  ) : (
    <div className="ov_main_content_container">
      <div className="ov_your_details_wrapper ov_inner_wrapper">
        <div className="uploaded-img">
          <img
            src={
              userData?.userImage !== ""
                ? userData?.userImage
                : profileImage?.data_url
            }
            alt=""
          />{" "}
        </div>
        <ImageUploading
          value={profileImage ? [profileImage] : []}
          onChange={onChange}
          maxNumber={maxNumber}
          dataURLKey="data_url"
        >
          {({ onImageUpload, onImageUpdate }) => (
            <div className="upload__image-wrapper">
              {profileImage ? (
                <div className="image-item">
                  <div className="image-item__btn-wrapper">
                    <button
                      onClick={() => onImageUpdate(profileImage.data_url)}
                    >
                      <GrUpdate size={20} color={darkMode ? "#fff" : "#000"} />
                      <p>Update Profile Picture</p>
                    </button>
                    <button onClick={handleImageRemove}>
                      <GrTrash size={20} className="icons-color" />
                      <p>Delete Profile Picture</p>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="controls" onClick={onImageUpload}>
                  <button className="upload-btn">
                    <IoCloudUploadSharp size={20} className="icons-color" />
                    <p>Upload your profile picture here</p>
                  </button>
                </div>
              )}
            </div>
          )}
        </ImageUploading>
        <TextField
          id="outlined-basic"
          label="Forename"
          variant="outlined"
          fullwidth
          name="firstName"
          required
          value={userData?.firstName || ""}
          onChange={handleChangeDetails}
        />
        {validationErrors.firstName && <div>{validationErrors.firstName}</div>}

        <TextField
          id="outlined-basic"
          label="Surname"
          variant="outlined"
          fullwidth
          required
          name="lastName"
          value={userData?.lastName || ""}
          onChange={handleChangeDetails}
        />
        {validationErrors.lastName && <div>{validationErrors.lastName}</div>}

        <TextField
          id="outlined-basic"
          label="Email"
          variant="outlined"
          fullwidth
          name="emailAddress"
          required
          value={userData?.emailAddress || ""}
          onChange={handleChangeDetails}
        />
        {validationErrors.emailAddress && (
          <div>{validationErrors.emailAddress}</div>
        )}

        <TextField
          id="outlined-basic"
          label="GPHC Registration Number"
          variant="outlined"
          fullwidth
          name="gPHCNumber"
          value={userData?.gPHCNumber || ""}
          onChange={handleChangeDetails}
        />
        <TextField
          id="outlined-basic"
          label="Mobile Number"
          variant="outlined"
          fullwidth
          required
          name="phoneNumber"
          value={userData?.phoneNumber || ""}
          onChange={handleChangeDetails}
        />

        {validationErrors.phoneNumber && (
          <div>{validationErrors.phoneNumber}</div>
        )}

        <TextField
          id="outlined-basic"
          label="Create new password"
          variant="outlined"
          fullwidth
          type="password"
          name="password"
          required
          value={userData?.password || ""}
          onChange={handleChangeDetails}
        />
        {validationErrors.password && <div>{validationErrors.password}</div>}

        <div className="ov_footer">
          <CustomButton label="Next" action={handleDetailData} />
        </div>
      </div>
    </div>
  );
}
