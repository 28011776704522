import { dispatch } from "../store/store";
import { logError } from "../services/logService";
import {
  setNhsFormData,
  getConsentData,
  setIsConsentLoading,
  saveConsentFormData,
  setHealthyAdviceData,
  setSmsQueue,
} from "../features/consentSlice/consentSlice";

const reducerName = "consent";

export const setNhsForm = (data) => {
  dispatch(setNhsFormData(data));
};

export const setHealthyAdvice = (data) => {
  dispatch(setHealthyAdviceData(data));
};

export const setConsentLoading = (isLoading) => {
  dispatch(setIsConsentLoading(isLoading));
};
export const setCurrentSmsQueue = (sms) => {
  dispatch(setSmsQueue([...sms]));
};
export const getAllConsentData = async (serviceId) => {
  let result = [];
  try {
    result = await dispatch(getConsentData(serviceId)).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const saveConsentData = async (serviceId, data) => {
  let result = null;

  try {
    result = await dispatch(saveConsentFormData({ serviceId, data })).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};
