import React from "react";
import "./../../../../assets/css/Dashboard/ServicesKanban.scss";
import { getSmsDate } from "../../../../data/getKanbanAndListViewData";
export default function SentSmsText({ id }) {
  return (
    <div className="sms_sent_text">
      <p>{`(${getSmsDate(id)}) SMS sent `}</p>
    </div>
  );
}
