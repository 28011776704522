import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./../../assets/css/ConsentModal.scss";
import "./../../assets/css/TemplateSection.css";
import CustomLabel from "../controls/CustomLabel";
import { inputTypes } from "../../enum/inputTypes";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import useTitanApp from "../../hooks/useTitanApp";
import { RxCross2 } from "react-icons/rx";
import CustomButton from "../controls/CustomButton";
import {
  setCurrentFollowUpcomments,
  setIsFollowUpControl,
} from "../../dispatchers/nmsDispatchers";
export default function FollowUpModal({ isOpen, handleAcceptConsent }) {
  const followUpControls = [
    {
      userControlId: "11cab78a-483a-468a-91ea-dbb5b003933c",
      name: "patientdidnotparttakeinfollowup",
      type: 5,
      label: "Patient did not part take in follow up",
      inline: false,
      required: false,
      value: "false",
      listItems: null,
      order: 1,
      parentUserControlId: null,
      isChildControl: true,
    },
    {
      userControlId: "00000000-0000-0000-0000-000000000000",
      name: "prescriberhasstoppednewmedicine",
      type: 5,
      label: "Prescriber has stopped new medicine",
      inline: false,
      required: false,
      value: "false",
      listItems: null,
      order: 1,
      parentUserControlId: "11cab78a-483a-468a-91ea-dbb5b003933c",
      isChildControl: true,
    },
    {
      userControlId: "00000000-0000-0000-0000-000000000000",
      name: "patienthaswithdrawnconsentforinformationsharing",
      type: 5,
      label: "Patient has withdrawn consent for information sharing",
      inline: false,
      required: false,
      value: "false",
      listItems: null,
      order: 2,
      parentUserControlId: "11cab78a-483a-468a-91ea-dbb5b003933c",
      isChildControl: true,
    },
    {
      userControlId: "00000000-0000-0000-0000-000000000000",
      name: "patienthaswithdrawnconsenttoreceivetheservice",
      type: 5,
      label: "Patient has withdrawn consent to receive the service",
      inline: false,
      required: false,
      value: "false",
      listItems: null,
      order: 3,
      parentUserControlId: "11cab78a-483a-468a-91ea-dbb5b003933c",
      isChildControl: true,
    },
    {
      userControlId: "00000000-0000-0000-0000-000000000000",
      name: "patientcouldnotbecontacted",
      type: 5,
      label: "Patient could not be contacted",
      inline: false,
      required: false,
      value: "false",
      listItems: null,
      order: 4,
      parentUserControlId: "11cab78a-483a-468a-91ea-dbb5b003933c",
      isChildControl: true,
    },
    {
      userControlId: "00000000-0000-0000-0000-000000000000",
      name: "catch-upnmsandpharmacistdeemedfollowupnotrequired",
      type: 5,
      label: "Catch-up NMS and pharmacist deemed follow up not required",
      inline: false,
      required: false,
      value: "false",
      listItems: null,
      order: 5,
      parentUserControlId: "11cab78a-483a-468a-91ea-dbb5b003933c",
      isChildControl: true,
    },
  ];
  const [show, setShow] = useState(isOpen);
  const handleClose = () => {
    setIsFollowUpControl(false);
  };
  const handleShow = () => setShow(isOpen);
  const { appState, nmsServiceState } = useTitanApp();
  const { followUpcomments } = nmsServiceState;
  const { darkMode } = appState;

  let rowCount = 0;
  const [inputValues, setInputValues] = useState(followUpControls);
  const handleFormChange = async (index, event, type, labelName, itemValue) => {
    let value;
    if (type === "Radio") {
      value = event.target.checked ? "true" : "false";
    }
    const UserControlObj = await setUserControl(index, value);
    setInputValues(UserControlObj);
    updateComments(UserControlObj);
  };
  const updateComments = (UserControlObj) => {
    const trueControl = UserControlObj?.find(
      (control) => control?.value === "true"
    );
    if (trueControl) {
      setCurrentFollowUpcomments(trueControl?.label);
    } else {
      setCurrentFollowUpcomments("");
    }
  };
  const setUserControl = async (index, value) => {
    let updatedValues;

    await setInputValues((prevInputValues) => {
      const newInputValues = prevInputValues.map((item, i) => ({
        ...item,
        value: i === index ? value : "false",
      }));
      updatedValues = [...newInputValues];
      return newInputValues;
    });

    return updatedValues;
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  return (
    <>
      <Modal
        show={isOpen}
        onHide={handleClose}
        backdrop="static"
        centered
        className="NMS-modal followup-modal"
      >
        <Modal.Body>
          <div className="followup_modal_header">
            <RxCross2
              color={darkMode ? "#fff" : "#0a0a0a"}
              size={20}
              className="collapse-icon"
              onClick={() => {
                setCurrentFollowUpcomments("");
                setIsFollowUpControl(false);
              }}
            />
          </div>
          <div>
            <div className="medicine-card customcard">
              <div className="card-content">
                <div className="card-actions nms-title">
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.49652 13.8028L14.7408 7.49941C16.4252 5.78856 16.4199 3.02549 14.7239 1.31249C13.0611 -0.367079 10.3961 -0.410504 8.69784 1.19086C8.66122 1.22539 8.62506 1.26068 8.58936 1.29673L7.99299 1.90026L7.38843 1.28963C7.35529 1.25616 7.32175 1.22333 7.28783 1.19116C5.58595 -0.422744 2.91654 -0.398067 1.26122 1.2739C-0.427101 2.9792 -0.418861 5.75004 1.27679 7.46275L7.55368 13.8028C7.81404 14.0657 8.23617 14.0657 8.49652 13.8028ZM9.3 2.00029C10.5964 0.691352 12.7025 0.692036 14.0133 2.01604C15.3253 3.34123 15.3272 5.47734 14.0292 6.79681L14.0282 6.79783L8.02517 12.8577L1.98743 6.75919C0.674081 5.43263 0.672858 3.28953 1.97185 1.97746C3.26525 0.671064 5.36984 0.672083 6.6778 1.99319L7.63801 2.96306C7.8338 3.16082 8.15338 3.16067 8.34898 2.96272L9.3 2.00029Z"
                      className="icons-color"
                    />
                  </svg>

                  <h2 className="medicine-card-heading">Follow Up</h2>
                </div>
                {
                  <div className="accordion-boxes">
                    {inputValues?.map((item, index) => {
                      if (item.inline === true) {
                        rowCount > 2 ? (rowCount = 0) : rowCount++;
                      }
                      return (
                        <React.Fragment key={index}>
                          <div
                            className={
                              item.inline === true ? "inline-not" : "inline"
                            }
                          >
                            <div
                              style={{
                                border: "#00ca69",
                              }}
                              className="switch-content-reports-outer toggle-only"
                              onClick={(event) =>
                                handleFormChange(
                                  index,
                                  {
                                    target: {
                                      checked: !JSON.parse(item?.value),
                                    },
                                  },
                                  inputTypes[item?.type],
                                  item?.label,
                                  item?.value
                                )
                              }
                            >
                              <CustomLabel name={item.label} />
                              <div>
                                <AntSwitch
                                  inputProps={{
                                    "aria-label": "ant design",
                                  }}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      inputTypes[item.type],
                                      item.label,
                                      item.value
                                    )
                                  }
                                  checked={
                                    item.value === ""
                                      ? "false"
                                      : JSON.parse(item.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {rowCount > 2 ? <br /> : <></>}
                        </React.Fragment>
                      );
                    })}
                  </div>
                }
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            label="Cancel"
            action={() => {
              setCurrentFollowUpcomments("");
              setIsFollowUpControl(false);
            }}
          />
          <CustomButton
            label="Confirm"
            action={() => {
              setIsFollowUpControl(false);
              handleAcceptConsent();
            }}
            isdisable={followUpcomments === "" ? true : false}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}
