import React from "react";

export default function PharmacyControl(props) {
  return (
    <div className="actionIconGroup_iconBox">
      <svg
        width="15"
        height="15"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="icons-color"
          d="M6.35504 0.315313L10.7146 4.33955C10.9585 4.56464 11.0971 4.88138 11.0971 5.21322V11.2074C11.0971 11.6452 10.7423 12.0001 10.3045 12.0001H7.53021C7.09244 12.0001 6.73755 11.6452 6.73755 11.2074V7.64046H4.35959V11.2074C4.35959 11.6452 4.00471 12.0001 3.56694 12.0001H0.792654C0.354883 12.0001 0 11.6452 0 11.2074V5.21322C0 4.88138 0.13868 4.56464 0.382519 4.33955L4.74211 0.315313C5.19757 -0.105104 5.89958 -0.105104 6.35504 0.315313ZM5.81739 0.897758C5.66558 0.757619 5.43157 0.757619 5.27975 0.897758L0.92016 4.922C0.83888 4.99703 0.792654 5.10261 0.792654 5.21322V11.2074H3.56694V7.64046C3.56694 7.20269 3.92182 6.84781 4.35959 6.84781H6.73755C7.17533 6.84781 7.53021 7.20269 7.53021 7.64046V11.2074H10.3045V5.21322C10.3045 5.10261 10.2583 4.99703 10.177 4.922L5.81739 0.897758Z"
        />
      </svg>
      <div className="actionIconGroup_iconName">
        <p>Pharmacy Control</p>
      </div>
    </div>
  );
}
