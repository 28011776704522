import React from "react";
import "./../../assets/css/TeamName.css";
import { ReactComponent as EditPencilSVG } from "./../../assets/images/edit-pencil.svg";
import useTitanTeam from "../../hooks/useTitanTeam";
import useTitanApp from "../../hooks/useTitanApp";
import { checkUserPermissions } from "../../utils/checkUserPermissions";
import { accessAddTeams } from "../../data/constantStrings";

export default function TeamName({
  teamName,
  toggleTeamsDialog,
  clickedTeamIndex,
}) {
  const { appState } = useTitanApp();
  const { teams } = appState;
  const { teamState } = useTitanTeam();
  const { addTeamImage } = teamState || "";
  return (
    <div className="teamName_container">
      <h5>
        {teams[clickedTeamIndex]?.teamName
          ? teams[clickedTeamIndex]?.teamName
          : teamName}
      </h5>
      {checkUserPermissions(accessAddTeams) && (
        <EditPencilSVG
          className="edit-pencil"
          onClick={() =>
            toggleTeamsDialog("edit", {
              teamName: teams[clickedTeamIndex]?.teamName
                ? teams[clickedTeamIndex]?.teamName
                : teamName,
              displayName: teams[clickedTeamIndex]?.teamName
                ? teams[clickedTeamIndex]?.teamName
                : teamName,
              teamImage: addTeamImage,
              teamPharmacyName: teams[clickedTeamIndex]?.pharmacies.map(
                (pharmacy) => pharmacy.teamPharmacyName
              ),
            })
          }
        />
      )}
    </div>
  );
}
