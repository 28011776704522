import React from "react";
import { ReactComponent as LeaderboardSVG } from "./../../../../../assets/images/orbitLeader.svg";
import useTitanApp from "../../../../../hooks/useTitanApp";
import LoadingComponentRank from "../../../../../components/LoadingComponentOrbit/LoadingComponentRank";
export default function OrbitLeaderboardRank() {
  const { orbitState } = useTitanApp();
  const { isOtherDetailsLoading, otherDetails } = orbitState || {};
  const { leaderboardRank } = otherDetails || {};
  return (
    <div className="orbit_box">
      {isOtherDetailsLoading ? (
        <LoadingComponentRank />
      ) : (
        <>
          <div className="orbit_label">
            <LeaderboardSVG />
            <p>Your rating</p>
          </div>
          <div className="orbit_label">
            <span className="orbit_value">{leaderboardRank}</span>
            <p>{`(3 reviews)`}</p>
          </div>
        </>
      )}
    </div>
  );
}
