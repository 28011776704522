import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpGet, httpPut, httpPost } from "../../services/httpService";
import {
  getMembersUrl,
  getServicesUrl,
  addMemberUrl,
  updateMemberUrl,
  getMemberByIdUrl,
} from "../../apiUrls/apiUrls";
import { logError } from "../../services/logService";

const initialState = {
  isMemberLoading: false,
  membersData: null,
  rolesData: null,
  servicesData: null,
  currentMember: null,
  lastMemberAddedUpdated: null,
  memberById: [],
};

export const getMemberData = createAsyncThunk(
  "member/getMemberData",
  async (companyId, thunkAPI) => {
    const urlToGet = getMembersUrl(companyId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getServicesData = createAsyncThunk(
  "member/getServicesData",
  async () => {
    const urlToGet = getServicesUrl();
    const respData = await httpGet(urlToGet);
    return respData?.services;
  }
);

export const getMemberByIdData = createAsyncThunk(
  "member/getMemberByIdData",
  async (memberId, thunkAPI) => {
    const respData = await httpGet(getMemberByIdUrl(memberId));
    return respData;
  }
);

export const addMemberData = createAsyncThunk(
  "member/addMemberData",
  async (data, thunkAPI) => {
    const respData = await httpPost(addMemberUrl(), data);
    return respData;
  }
);

export const updateMemberData = createAsyncThunk(
  "member/updateMemberData",
  async (data, thunkAPI) => {
    const respData = await httpPut(updateMemberUrl(), data);
    return respData;
  }
);

export const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    setIsMemberLoading: (state, action) => {
      state.isMemberLoading = action?.payload || false;
    },
    setMembersData: (state, action) => {
      state.membersData = action?.payload;
    },
    setRolesData: (state, action) => {
      state.rolesData = action?.payload;
    },
    setServicesData: (state, action) => {
      state.servicesData = action?.payload;
    },
    setCurrentMemberData: (state, action) => {
      state.currentMember = action?.payload || null;
    },

    resetMemberState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMemberData.pending, (state) => {
        state.isMemberLoading = true;
      })
      .addCase(getMemberData.fulfilled, (state, action) => {
        state.isMemberLoading = false;
        state.membersData = action?.payload;
      })
      .addCase(getMemberData.rejected, (state, action) => {
        state.isMemberLoading = false;
        state.membersData = [];
      })

      .addCase(getServicesData.pending, (state) => {
        state.isMemberLoading = true;
      })
      .addCase(getServicesData.fulfilled, (state, action) => {
        state.isMemberLoading = false;
        state.servicesData = action?.payload;
      })
      .addCase(getServicesData.rejected, (state, action) => {
        logError(action?.error);
        state.isMemberLoading = false;
        state.servicesData = null;
      })

      .addCase(getMemberByIdData.pending, (state) => {
        state.isMemberLoading = true;
      })
      .addCase(getMemberByIdData.fulfilled, (state, action) => {
        state.isMemberLoading = false;
        state.currentMember = action?.payload;
      })
      .addCase(getMemberByIdData.rejected, (state, action) => {
        logError(action?.error);
        state.isMemberLoading = false;
        state.currentMember = null;
      })

      .addCase(addMemberData.pending, (state) => {
        state.isMemberLoading = true;
      })
      .addCase(addMemberData.fulfilled, (state, action) => {
        state.isMemberLoading = false;
        state.lastMemberAddedUpdated = action?.payload;
      })
      .addCase(addMemberData.rejected, (state, action) => {
        logError(action?.error);
        state.isMemberLoading = false;
        state.lastMemberAddedUpdated = null;
      })

      .addCase(updateMemberData.pending, (state) => {
        state.isMemberLoading = true;
      })
      .addCase(updateMemberData.fulfilled, (state, action) => {
        state.isMemberLoading = false;
        state.lastMemberAddedUpdated = action?.payload;
      })
      .addCase(updateMemberData.rejected, (state, action) => {
        logError(action?.error);
        state.isMemberLoading = false;
        state.lastMemberAddedUpdated = null;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsMemberLoading,
  setMembersData,
  setRolesData,
  setServicesData,
  setCurrentMemberData,
} = memberSlice.actions;

export default memberSlice.reducer;
