import { connect } from "react-redux";
import UserControls from "./userControls";
class templateSections {
  constructor(
    response,
    title,
    userControlObj,
    isCompleted,
    groupId = "00000000-0000-0000-0000-000000000000"
  ) {
    this.obj = response?.map((value) => {
      return {
        groupId: value.groupId,
        order: value.order,
        sectionTitle: value.sectionTitle,
        isParent: value.isParent,
        userControls:
          title === value?.sectionTitle && value?.groupId === groupId
            ? new UserControls(
                value.userControls,
                userControlObj,
                true,
                isCompleted
              ).obj
            : value.userControls !== null
            ? value.userControls
            : []
            ? new UserControls(value.userControls, null, false, isCompleted).obj
            : value.userControls !== null
            ? value.userControls
            : [],
        templateSectionType: value.templateSectionType,
        isRequired: value.isRequired,
      };
    });
  }
}

export default templateSections;
