import React, { useState } from "react";
import useTitanApp from "../../../hooks/useTitanApp";
import { FiPhoneIncoming } from "react-icons/fi";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import { IoIosCheckmarkCircle } from "react-icons/io";
import Waveform from "./Waveform";
import NMSFollowupTemplate from "./NMSFollowupTemplate";
import NMSPhoneCall from "./NMSPhoneCall";
import { timelineEventTypes } from "../../../enum/timelineEventTypes";

function NMSGroupedPhoneEvents({ data, stageName, createdbyName }) {
  const { appState, nmsServiceState } = useTitanApp();
  const { darkMode } = appState;
  const { timelineData } = nmsServiceState || {};
  const groupedPhoneEvents = timelineData[0].items.filter((obj) => {
    const event = obj.event;
    return [9, 10, 11, 12, 13, 14, 18].includes(event);
  });

  const getFormatDate24Hrs = (dateString) => {
    let momentdate = moment(dateString);
    let date = momentdate?.format("DD/MM/yyyy HH:mm");
    return date;
  };
  const createdOn = data?.createdOn;

  const [expanded, setExpanded] = useState({});

  const handleChange = (index) => {
    setExpanded((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const [expandedParent, setExpandedParent] = useState(false);

  const handleChangeParent = () => {
    setExpandedParent(!expandedParent);
  };

  return (
    <TimelineItem>
      <TimelineSeparator>
        <FiPhoneIncoming size={25} color={darkMode ? "#f2f2f2" : "#0a0a0a"} />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <h6>
          <span className="typeHeading">Patient Called</span>{" "}
          <span className="type">by</span>{" "}
          <span className="caller_name">{createdbyName}</span>
          <span className="type">
            {" on "} {getFormatDate24Hrs(createdOn)}
          </span>
        </h6>
        <Accordion
          expanded={expanded["outer"] || false}
          onChange={() => handleChange("outer")}
          className="timeline_accordion"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="heading view-content-heading"
          >
            <p className="view-contents">View contents</p>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <Accordion
                expanded={expandedParent}
                onChange={handleChangeParent}
                className="nms_info_accordion top_parent"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className="heading"
                >
                  <div className="header">
                    <IoIosCheckmarkCircle color="#00CC6A" size={20} />
                    <p>Phone Call Answered</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="top_parent_details">
                  {groupedPhoneEvents?.map((event, eventIndex) => (
                    <div key={eventIndex}>
                      {event.elements
                        ?.filter((element) => element?.elementType !== 6)
                        .map((element, elementIndex) => {
                          let templateComponent = null;

                          if (element.elementType === 9) {
                            templateComponent = (
                              <NMSPhoneCall
                                key={elementIndex}
                                element={element}
                                createdbyName={event.createdByName} // Assuming 'createdByName' is accessible from the event object
                                isExpanded={
                                  timelineEventTypes[event.event] === stageName
                                    ? true
                                    : false
                                }
                              />
                            );
                          } else if (element.elementType === 5) {
                            templateComponent = (
                              <Waveform
                                key={elementIndex}
                                element={element.value}
                                darkMode={darkMode}
                              />
                            );
                          } else if (element.elementType === 10) {
                            templateComponent = (
                              <NMSFollowupTemplate
                                key={elementIndex}
                                createdbyName={event.createdByName} // Assuming 'createdByName' is accessible from the event object
                                element={element}
                                isExpanded={
                                  timelineEventTypes[event.event] === stageName
                                    ? true
                                    : false
                                }
                              />
                            );
                          }

                          return templateComponent;
                        })}
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            </div>
          </AccordionDetails>
        </Accordion>
      </TimelineContent>
    </TimelineItem>
  );
}

export default NMSGroupedPhoneEvents;
