import React from "react";
import { RxOpenInNewWindow } from "react-icons/rx";
import DPIcon from "./../../../../assets/images/dp-icon.png";
import { formatPhoneNumber } from "../../../../data/formatPhone";
import useTitanApp from "../../../../hooks/useTitanApp";
import { MdCall } from "react-icons/md";

export default function Ringing({
  handleCloseCallScreenDialog,
  service,
  phoneNumber,
  handleHangUpCall,
}) {
  const { appState } = useTitanApp();
  const { darkMode } = appState;
  return (
    <div className="callScreen_dialog_wrapper" id="callScreen-dialog-title">
      <div className="ringing">
        <div className="top-bar">
          <RxOpenInNewWindow
            color={darkMode ? "#fff" : "#0a0a0a"}
            size={20}
            className="collapse-icon"
            onClick={handleCloseCallScreenDialog}
          />
        </div>
        <div className="body">
          <div className="profile-picture">
            <div className="img-box">
              <img src={DPIcon} alt="profile-picture" />
            </div>
          </div>
          <h4>{service?.patientName}</h4>
          <p className="phone-number">{formatPhoneNumber(phoneNumber)}</p>
          <div className="status-box">
            <p>Calling</p>
          </div>
          <div className="d-flex">
            <button className="cancel" onClick={handleHangUpCall}>
              <p>Cancel</p>
            </button>
            <button className="end-call" onClick={handleHangUpCall}>
              <MdCall size={16} />
              <p>End Call</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
