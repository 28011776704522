export const successMessageText = "Record added successfully!",
  errorMessageText = "Something went wrong!",
  responseErrorMessage = "Could not fetch the data for the resource",
  noPrescriptionFound = "No prescription found",
  DefaultNotDispenseOutOfScopeCode = "0007",
  DefaultNotDispenseExpiredCode = "0008",
  DefaultNotDispenseCancelledCode = "0099",
  DefaultNotDispensePatientDeceasedCode = "0005",
  DefaultClinicallyNotSuitableCode = "0002",
  accessInviteUsers = "access:invite-users",
  accessAddTeams = "access:add-team";
