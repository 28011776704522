import React from "react";
import "./CustomTemplate.css";
import CustomTemplateSection from "./components/CustomTemplateSection";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { GoArrowLeft } from "react-icons/go";
import { useLoaderData, useNavigate } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import customTemplate from "../../../../../Model/AddCustomTemplate/AddCustomTemplate";
import CustomButton from "../../../../controls/CustomButton";
import SettingsSidebar from "../../../../SettingsSidebar/SettingsSidebar";
import CustomLoader from "../../../../controls/CustomLoader";
import { showError } from "../../../../ToastedMessage/ToastedMessage";
import useTitanApp from "../../../../../hooks/useTitanApp";
import AsyncRendererRouter from "../../../../AsyncRendererRouter/AsyncRendererRouter";
import useTitanTemplate from "../../../../../hooks/useTitanTemplate";
import { addTemplate } from "../../../../../dispatchers/templateDispatchers";
import ConsultationAddNewLoadingComponent from "../../../../SettingsLoadingComponents/ConsultationAddNewLoadingComponent";

function AddNewCustomTemplate() {
  const { templateState } = useTitanTemplate();
  const { isConsultationLoading } = templateState || {};
  const navigate = useNavigate();
  const [customTemplates, setCustomTemplates] = useState([]);
  const [templateName, setTemplateName] = useState("");
  const { appState } = useTitanApp();
  const { companyId } = appState || {
    companyId: "00000000-0000-0000-0000-000000000000",
  };

  const AddTemplateSection = async (isDuplcate, Dupsection) => {
    const newSection = new customTemplate(isDuplcate, Dupsection).obj;

    setCustomTemplates((prevSections) => [...prevSections, newSection]);
    //add in nms
  };
  function handleUpdateSection(updatedSection) {
    const updatedData = customTemplates?.map((item) => {
      if (item.groupId === updatedSection.groupId) {
        return updatedSection;
      }
      return item;
    });
    setCustomTemplates(updatedData);
  }

  const handleSave = () => {
    const dataModel = {
      companyId: companyId,
      templateName: templateName,
      templateSections: customTemplates,
      isDefault: false,
    };
    const savedData = addTemplate(dataModel);
    savedData.then((result) => {
      if (result) {
        navigate("/settings/templates/consultation");
      } else {
        showError("Consultation could not be added.", true, false);
      }
    });
  };

  function handleDeleteSection(objectToDelete) {
    const updatedSection = customTemplates?.filter(
      (control) => control !== objectToDelete
    );
    setCustomTemplates(updatedSection);
  }

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = [...customTemplates];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setCustomTemplates(items);
  };

  const { appData } = useLoaderData() || {};

  return (
    <AsyncRendererRouter
      resolve={appData}
      isLoading={isConsultationLoading}
      loadingElement={<ConsultationAddNewLoadingComponent />}
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <SettingsSidebar />
          <div className="settings_content_wrapper">
            <div className="settings_heading">
              <h1>Consultation</h1>
            </div>
            <div>
              <div>
                <div
                  className="back_to_SettingsListView"
                  onClick={() => {
                    navigate("/settings/templates/consultation");
                  }}
                >
                  <GoArrowLeft className="icons-color" />
                  <p>Back</p>
                </div>
                <>
                  <div className="medicine-card customcard">
                    <div className="temp_content">
                      <div className="temp_head">
                        <h4>General Information</h4>
                        <p className="sec_line"></p>
                      </div>

                      <div className="temp_body">
                        <div className="temp_body_a">
                          <div className="template_control sec_name">
                            <TextField
                              className="text_input_field"
                              required
                              id="outlined-required"
                              value={templateName}
                              label="Name"
                              onChange={(e) =>
                                setTemplateName(e?.target?.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="temp_body_b"></div>
                      </div>
                    </div>
                  </div>

                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable
                      droppableId="customTemplateSections"
                      direction="vertical"
                    >
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {customTemplates?.map((temp, index) => (
                            <Draggable
                              key={temp.groupId}
                              draggableId={temp.groupId}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <CustomTemplateSection
                                    AddTemplateSection={AddTemplateSection}
                                    handleDeleteSection={handleDeleteSection}
                                    template={temp}
                                    handleUpdateSection={handleUpdateSection}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  <div className="add_section">
                    <CustomButton
                      label="Add Section"
                      action={() => {
                        AddTemplateSection(false);
                      }}
                    />
                  </div>
                </>
                <div className="footer_btn">
                  <CustomButton label="Save Changes" action={handleSave} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}

export default AddNewCustomTemplate;
