export const dispenseItemsArray = [
  {
    label: "",
    value: "",
  },
  {
    label: "Not required as instructed by the patient",
    value: "0001",
  },
  {
    label: "Clinically unsuitable",
    value: "0002",
  },
  {
    label: "Prescription cancellation",
    value: "0004",
  },
  {
    label: "Prescription cancellation due to death",
    value: "0005",
  },
  {
    label: "Illegal NHS prescription",
    value: "0006",
  },
  {
    label: "Prescribed out of scope item",
    value: "0007",
  },
  {
    label: "Item cancelled by prescriber",
    value: "0008",
  },
  {
    label: "Not allowed on FP10",
    value: "0009",
  },
  {
    label: "Patient did not collect medication",
    value: "0010",
  },
  {
    label: "Patient purchased medication over the counter",
    value: "0011",
  },
  {
    label: "Item cancelled by prescriber",
    value: "0099",
  },
];
