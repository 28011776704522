const randomNumber = Math.floor(Math.random() * 9000) + 1000;
class setinviteTeam {
  constructor(response, teamId, company_Id, role) {
    this.obj = response?.map((value) => {
      return {
        teamMemberID: teamId,
        companyID: company_Id,
        userID: "A91F5AD6-7E14-745F-C40F-601670410C23",
        userFullName: `Guest User_${randomNumber.toString()}`,
        userFirstName: "Guest",
        userLastName: `User_${randomNumber.toString()}`,
        teamMemberAccessType: 0,
        teamJoiningStatus: 0,
        joiningDate: new Date().toISOString(),
        gender: 0,
        email: value.trim(),
        roleId: role?._id,
      };
    });
  }
}
export default setinviteTeam;
