import React, { useState } from "react";
import "./../../assets/css/NotesControl.scss";
import { v4 as uuidv4 } from "uuid";
import AddedPrescriptionNotes from "./AddedPrescriptionNotes";
import { HiPaperAirplane } from "react-icons/hi";
import useTitanApp from "../../hooks/useTitanApp";
import setCheckNow from "../../Model/CheckNow/setCheckNow";
import {
  setCheckNowModel,
  setCurrentClinicalChecksNotes,
} from "../../dispatchers/clinicalChecksDispatchers";
function PrescriptionNotesControl() {
  const { clincicalChecksState, appState } = useTitanApp();

  const { currentUser } = appState;

  const { prescriptions, checkNowModel, clinicalChecksNotes } =
    clincicalChecksState || {};
  const { prescriptionIncomingId } = prescriptions || {};
  const [addedNotes, setAddedNotes] = useState([]);
  const [notesValue, setNotesValue] = useState("");
  function ChangeNotes(e) {
    setNotesValue(e?.target?.value);
  }

  const addNewNote = () => {
    if (notesValue !== "") {
      const newNote = {
        ProfessionalName: currentUser?.fullName,
        PrescriptionIncomingNoteId: uuidv4(),
        PrescriptionIncomingId: prescriptionIncomingId,
        Note: notesValue,
        AwaitingQuery: false,
        ArchiveDate: null,
        PinNote: false,
        ShowOnHandout: false,
        CreatedOn: new Date(),
      };
      const updatedNotesArray = [...clinicalChecksNotes];
      updatedNotesArray.push(newNote);
      setNotesValue("");
      setAddedNotes(updatedNotesArray);
      setCurrentClinicalChecksNotes(updatedNotesArray);
      const newCheckNowModel = new setCheckNow(
        checkNowModel,
        "",
        "",
        "",
        0,
        "",
        false,
        "",
        false,
        0,
        false,
        "",
        false,
        "",
        true,
        clinicalChecksNotes,
        ""
      );
      setAddedNotes(updatedNotesArray);
      setCheckNowModel(newCheckNowModel);
    }
  };
  function UpdateNotesModel(Notesmodel) {
    setCurrentClinicalChecksNotes(Notesmodel);
    const newCheckNowModel = new setCheckNow(
      checkNowModel,
      "",
      "",
      "",
      0,
      "",
      false,
      "",
      false,
      0,
      false,
      "",
      false,
      "",
      true,
      clinicalChecksNotes,
      ""
    );
    setCheckNowModel(newCheckNowModel);
  }

  const ChangeAddedNotes = (notes) => {
    setAddedNotes(notes);
    UpdateNotesModel(notes);
  };
  return (
    <>
      {addedNotes?.map((item, index) => {
        return (
          <AddedPrescriptionNotes
            key={item.PrescriptionIncomingNoteId}
            item={item}
            handleAddedNotes={ChangeAddedNotes}
          />
        );
      })}

      <div className="note-box">
        <input
          type="text"
          placeholder="Write your comment here"
          value={notesValue}
          onChange={ChangeNotes}
          className="input-field"
        />
        <button className="note-box-add" onClick={addNewNote}>
          <HiPaperAirplane size={20} />
        </button>
      </div>
    </>
  );
}

export default PrescriptionNotesControl;
