import * as React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./../../assets/css/AuthorizedModal.scss";
import { ImWarning } from "react-icons/im";

function AuthorizedModal(props) {
  const { isOpen } = props || {};
  const [show, setShow] = useState(
    isOpen !== undefined && isOpen !== null ? isOpen : true
  );
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      <Modal show={show} backdrop="static" centered>
        <Modal.Header>
          <ImWarning size={18} color={"red"} />
          <Modal.Title>Not Authorized</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You are not authorized to access the application
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AuthorizedModal;
