function getClinicalChecksValue(theObject, keyName) {
  var result = null;
  if (theObject instanceof Array) {
    for (var i = 0; i < theObject?.length; i++) {
      result = getClinicalChecksValue(theObject[i], keyName);
      if (result) {
        break;
      }
    }
  } else {
    for (var prop in theObject) {
      if (prop?.toLowerCase() === keyName?.toLowerCase()) {
        return theObject[prop];
      }
      if (
        theObject[prop] instanceof Object ||
        theObject[prop] instanceof Array
      ) {
        result = getClinicalChecksValue(theObject[prop], keyName);
        if (result) {
          break;
        }
      }
    }
  }
  return result;
}

export const getObject = getClinicalChecksValue;
