export const colorArray = [
  {
    label: "Green",
    value: "Green",
  },
  {
    label: "Amber",
    value: "Amber",
  },
  {
    label: "Red",
    value: "Red",
  },
];
