import React, { useState, useRef } from "react";
import { GoArrowDown, GoArrowRight, GoPencil } from "react-icons/go";
import { IoIosCheckmarkCircle } from "react-icons/io";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Switch from "@mui/material/Switch";
import AccordionSummary from "@mui/material/AccordionSummary";
import useTitanApp from "../../../hooks/useTitanApp";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Waveform from "./Waveform";

export default function NMSPhoneCall({ element, createdbyName, isExpanded }) {
  const { appState } = useTitanApp();
  const { darkMode } = appState;
  const [expanded, setExpanded] = useState(false);
  const handleChange = () => {
    setExpanded(!expanded);
  };
  const [expandedTranscript, setExpandedTranscript] = useState(false);
  const handleChangeTranscript = () => {
    setExpandedTranscript(!expandedTranscript);
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "& .MuiSwitch-thumb": {
      width: 15,
      backgroundColor: "yellow", // Change to the color you desire for active state
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
      "& .MuiSwitch-thumb": {
        backgroundColor: "white",
      },
    },

    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
      "&.Mui-disabled": {
        "& .MuiSwitch-thumb": {
          backgroundColor: "grey", // Change to the color you desire for disabled state
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,0.05)",
      boxSizing: "border-box",
    },
  }));

  return (
    <div>
      {Array.isArray(element?.listItems) && element?.listItems?.length > 0 ? (
        <Accordion
          expanded={isExpanded || expanded}
          onChange={() => handleChange()}
          className="nms_info_accordion"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content"
            id="panel-header"
            className="heading"
          >
            <div className="header">
              <IoIosCheckmarkCircle color="#00CC6A" size={20} />
              <p>{element?.title}</p>
            </div>
          </AccordionSummary>
          {element?.listItems?.map((listItems, index) => (
            <AccordionDetails key={index} className="accordion_content">
              <div
                className={`list_box ${
                  listItems?.type !== "Radio" ? "list_box_alt" : ""
                }`}
              >
                {listItems?.type === "Radio" ? (
                  <p>{listItems?.label}</p>
                ) : (
                  <div className="d-flex">
                    <GoPencil size={12} color="#00ca69" />
                    <p>{createdbyName}</p>
                  </div>
                )}

                {listItems?.type == "Radio" ? (
                  <AntSwitch checked={listItems?.value} />
                ) : (
                  <TextField
                    multiline
                    rows={4}
                    id="outlined-required"
                    disabled={true}
                    defaultValue={listItems?.value}
                    className="textfield"
                  />
                )}
              </div>
            </AccordionDetails>
          ))}
        </Accordion>
      ) : element.elementType === 9 ? (
        <Accordion
          expanded={expandedTranscript}
          onChange={() => handleChangeTranscript()}
          className="nms_info_accordion phone_child"
        >
          <AccordionSummary
            expandIcon={
              <GoArrowDown size={15} color={darkMode ? "white" : "black"} />
            }
            aria-controls="panel-content"
            id="panel-header"
            className="heading"
          >
            <div className="header transcript">
              <p>View Transcript</p>
            </div>
          </AccordionSummary>
          <AccordionDetails className="accordion_content">
            <div className="list_box transcript">
              <TextField
                multiline
                rows={4}
                id="outlined-required"
                disabled={true}
                defaultValue={element?.value}
                className="textfield"
              />
            </div>
          </AccordionDetails>
        </Accordion>
      ) : (
        <div className="list_box transcript">
          <TextField
            multiline
            rows={4}
            id="outlined-required"
            disabled={true}
            defaultValue={element?.value}
            className="textfield"
          />
        </div>
      )}
    </div>
  );
}
