import React from "react";
import { ReactComponent as AddNewSvg } from "./../../../PatientDashboardIcons/addNew.svg";

export default function Subscriptions() {
  return (
    <div className="card-shaped-box">
      <div className="tasks_header summary_header">
        <h3 className="pharmacy_header_name">Subscriptions</h3>
        <AddNewSvg />
      </div>
      <div className="card-shaped-content">
        <p>This patient has no subscriptions</p>
      </div>
    </div>
  );
}
