import React, { useState } from "react";
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import useTitanApp from "../../../../../../../../hooks/useTitanApp";

export default function MembersServicesProvided({
  handleChangeServices,
  addedService,
}) {
  const { appState, memberState } = useTitanApp();
  const { servicesData } = memberState || {};
  const [selectAll, setSelectAll] = useState(false);

  const handleToggle = (item) => () => {
    const currentIndex = addedService?.some((items) => items._id === item._id);
    const newChecked = [...addedService];
    if (!currentIndex) {
      newChecked.push(item);
      handleChangeServices(newChecked);
    } else {
      const updated = newChecked.filter((items) => items._id !== item._id);
      handleChangeServices(updated);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      handleChangeServices([]);
    } else {
      const newChecked = [...servicesData];
      handleChangeServices(newChecked);
    }
    setSelectAll(!selectAll);
  };

  const checkboxTheme = createTheme({
    overrides: {
      MuiCheckbox: {
        root: {
          "&:hover": {
            backgroundColor: "transparent", // Disable hover background effect
          },
        },
        checked: {
          "&:hover": {
            backgroundColor: "transparent", // Disable hover background effect when checked
          },
        },
      },
    },
  });

  return (
    <>
      <div className="members_services_provided settings_box">
        <ThemeProvider theme={checkboxTheme}>
          <div>
            <div className="checkAll_btnBox">
              <Button onClick={handleSelectAll}>
                {selectAll ? "Uncheck All" : "Check All"}
              </Button>
            </div>

            <List className="services_list">
              {servicesData?.map((item, index) => (
                <ListItem key={index} button onClick={handleToggle(item)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={addedService?.some(
                        (items) => items._id === item._id
                      )}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={item?.serviceName} />
                </ListItem>
              ))}
            </List>
          </div>
        </ThemeProvider>
      </div>
    </>
  );
}
