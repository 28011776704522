import React from "react";
import "./../../../../assets/css/BottomActionBar.scss";
import HandleCount from "./../HandleCount";
import Lottie from "lottie-react";
import Sparkles from "./../../../../assets/Sparkles.json";
import financeImg from "./../../../../assets/images/finance.png";

export default function Finance({
  onMouseEnter,
  onMouseLeave,
  active,
  toggleFinance,
  disabled,
  totalFinanceCount,
  onDarkModeChange,
  setIsListViewVisible,
  isActive,
  onClick,
}) {
  return (
    <div
      className={`bottomBar_image-box ${active ? "active" : ""} ${
        isActive ? "fadeBottomActionIcons" : ""
      }`}
      onMouseEnter={() => onMouseEnter("Finance")}
      onMouseLeave={() => onMouseLeave("")}
      onClick={onClick}
      style={{ pointerEvents: disabled ? "none" : "auto" }}
    >
      <HandleCount
        countValue={totalFinanceCount >= 0 ? totalFinanceCount : 0}
        active={active}
      />
      <div
        className={`bottomBar_image-container ${
          active ? "overflow-visible" : ""
        }`}
      >
        <img
          className={`bottomBar_img financeImg ${active ? "rotate" : ""}`}
          src={financeImg}
        />
        {active ? (
          <div className="sparklesAnimation">
            <Lottie animationData={Sparkles} />
          </div>
        ) : (
          ""
        )}
      </div>

      <p className="bottomBar_image-box-title">Finance</p>
    </div>
  );
}
