export const inputTypes = {
  0: "text",

  1: "label",

  2: "number",

  3: "date",

  4: "paragraph",

  5: "Radio",

  6: "checkbox",

  7: "dropdown",
  8: "followup",
  9: "radioText",
  10: "textarea",
  11: "multiSelect",
  12: "multiText",
};
