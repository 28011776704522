import React, { useState } from "react";
import { ReactComponent as NotCheckedSVG } from "./../../../assets/images/signupnotChecked.svg";
import { ReactComponent as CheckedSVG } from "./../../../assets/images/signupChecked.svg";
import { ReactComponent as StepOneSVG } from "./../../../assets/images/gphcOne.svg";
import { ReactComponent as StepTwoSVG } from "./../../../assets/images/gphcTwo.svg";
import { ReactComponent as StepThreeSVG } from "./../../../assets/images/gphcThree.svg";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { RxCaretRight } from "react-icons/rx";
import useTitanApp from "../../../hooks/useTitanApp";
import {
  getGphcNumber,
  setUser,
  setOrbitGPHC,
  setYotiSteps,
} from "../../../dispatchers/onboardingVerificationDsipatchers";
import { showError } from "../../ToastedMessage/ToastedMessage";
import { getIdentityVerification } from "../../../dispatchers/pharmacyServiceDispatchers";

const validationSchema = Yup.object({
  gphc: Yup.string().required("GPHC number is required"),
});

export default function OrbitGPHC({ root }) {
  const { onboardingVerificationState } = useTitanApp();
  const { userData } = onboardingVerificationState || {};
  const [gphcNumber, setGphcNumber] = useState("");
  const [has7Digits, setHas7Digits] = useState(false);
  const [hasNumerics, setHasNumerics] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const handleGPHCNumberChange = (event) => {
    const inputValue = event.target.value;
    const { name, value } = event.target;

    setGphcNumber(inputValue);

    // Check if the input value has 7 digits
    setHas7Digits(inputValue.length === 7);

    // Check if the input value contains only numeric characters
    setHasNumerics(/^\d+$/.test(inputValue));
  };
  const handleGphcDetails = async () => {
    try {
      await validationSchema.validate(
        { gphc: gphcNumber },
        { abortEarly: false }
      );
      var result = await getGphcNumber(gphcNumber);
      if (result === true) {
        setUser({
          ...userData,
          gphcNumber: gphcNumber,
        });
        getIdentityVerification().then((data) => {
          setOrbitGPHC(false);
          setYotiSteps(true);
          return data;
        });
      } else {
        showError(
          "The GPHC number is invalid or already associated with another user.",
          true,
          false
        );
      }
    } catch (errors) {
      const newValidationErrors = {};
      errors.inner.forEach((error) => {
        newValidationErrors[error.path] = error.message;
      });
      setValidationErrors(newValidationErrors);
    }
  };

  return (
    <div className="user_signup orbit_gphc_container">
      <h4>
        We’re excited to show you Titan Verse! First, what’s your GPHC number?
      </h4>
      <p>
        As part of your onboarding to Titan Verse, we require your GPHC (General
        Pharmaceutical Council) number to confirm your qualifications as a
        pharmacist and to integrate this with your verified identity for a
        secure and professional community.
      </p>
      <div className="gphc_container">
        <label htmlFor="gphc">GPHC number</label>
        <InputText
          id="gphc"
          name="gphcNumber"
          value={gphcNumber}
          onChange={handleGPHCNumberChange}
          maxLength={7}
        />
        {validationErrors.gphc && (
          <div className="validation">{validationErrors.gphc}</div>
        )}
      </div>
      <div className="validations">
        <div className="d-flex">
          {has7Digits ? <CheckedSVG /> : <NotCheckedSVG />}
          <p>7 Digits</p>
        </div>
        <div className="d-flex">
          {hasNumerics ? <CheckedSVG /> : <NotCheckedSVG />}
          <p>All Numeric</p>
        </div>
      </div>
      <h4>
        Why we’re asking for GPHC and what’s happening with this information?
      </h4>
      <p>
        This process might take a few seconds, but it's essential for
        maintaining the integrity and professionalism of our platform. We
        appreciate your patience and are committed to providing you with a
        secure and trusted environment.
      </p>
      <p className="mt-10">
        <strong>For transparency, here’s what we’re checking:</strong>
      </p>
      <div className="gphc_checking_container">
        <div className="cont">
          <StepOneSVG />
          <p className="label">Checking Registration</p>
          <p>
            We're verifying your GPHC number against the official register to
            confirm it's valid and active.
          </p>
        </div>
        <div className="cont">
          <StepTwoSVG />
          <p className="label">Matching Details</p>
          <p>
            We're ensuring that the GPHC number provided matches with your
            verified identity.
          </p>
        </div>
        <div className="cont">
          <StepThreeSVG />
          <p className="label">Active Status</p>
          <p>
            We're checking to confirm that you are currently an active
            pharmacist and not struck off the register.
          </p>
        </div>
      </div>
      <button className="success d-flex" onClick={handleGphcDetails}>
        <div></div>
        Verify
        <RxCaretRight color="#fff" size={20} />
      </button>
    </div>
  );
}
