import { configureStore } from "@reduxjs/toolkit";
import appReducer from "../features/appSlice/appSlice";
import pharmacyServiceReducer from "../features/pharmacyServiceSlice/pharmacyServiceSlice";
import documentReducer from "../features/documentServiceSlice/documentServiceSlice";
import teamReducer from "../features/teamSlice/teamSlice";
import clinicalChecksReducer from "../features/clinicalChecksSlice/clinicalChecksSlice";
import generalsReducer from "../features/generalsSlice/generalsSlice";
import billingReducer from "../features/billingSlice/billingSlice";
import locationReducer from "../features/locationSlice/locationSlice";
import templateReducer from "../features/templateSlice/templateSlice";
import orbitReducer from "../features/orbitSlice/orbitSlice";
import nmsReducer from "../features/NmsServiceSlice/NmsServiceSlice";
import memberReducer from "../features/memberSlice/memberSlice";
import typeReducer from "../features/typeSlice/typeSlice";
import consentReducer from "../features/consentSlice/consentSlice";
import onboardingVerificationReducer from "../features/onboardingVerificationSlice/onboardingVerificationSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    pharmacyService: pharmacyServiceReducer,
    documentService: documentReducer,
    team: teamReducer,
    clinicalChecks: clinicalChecksReducer,
    general: generalsReducer,
    billing: billingReducer,
    location: locationReducer,
    template: templateReducer,
    orbit: orbitReducer,
    nmsService: nmsReducer,
    member: memberReducer,
    type: typeReducer,
    consent: consentReducer,
    onboardingVerification: onboardingVerificationReducer,
  },
});

export const { dispatch } = store || {};

export const getDataFromStore = (reducerName, key) => {
  let result = null;
  if (reducerName && key) {
    const state = store.getState();
    if (state[reducerName] !== undefined && state[reducerName] !== null) {
      const reducer = state[reducerName];
      if (reducer[key] !== undefined) {
        result = reducer[key];
      }
    }
  }
  return result;
};
