import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";

import { styled } from "@mui/material/styles";
function AccountDetails({
  paymentDetails,
  handleChangeDetails,
  handleSwitchChange,
}) {
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "& .MuiSwitch-thumb": {
      width: 15,
      backgroundColor: "yellow", // Change to the color you desire for active state
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
      "& .MuiSwitch-thumb": {
        backgroundColor: "#18a329",
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },

    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
      },
      "&.Mui-disabled": {
        "& .MuiSwitch-thumb": {
          backgroundColor: "grey", // Change to the color you desire for disabled state
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,0.05)",
      boxSizing: "border-box",
    },
  }));
  return (
    <div className="acc_container">
      <div className="acc_fields">
        <TextField
          id="outlined-basic"
          label="Account Number"
          variant="outlined"
          fullwidth
          name="accountNumber"
          value={paymentDetails?.accountNumber || ""}
          onChange={handleChangeDetails}
        />
        <TextField
          id="outlined-basic"
          label="Sort Code"
          variant="outlined"
          fullwidth
          name="sortCode"
          value={paymentDetails?.sortCode || ""}
          onChange={handleChangeDetails}
        />
      </div>
      <div className="acc_info">
        <TextField
          id="outlined-basic"
          label="Account Holder Name"
          variant="outlined"
          fullwidth
          name="accountHolderName"
          value={paymentDetails?.accountHolderName || ""}
          onChange={handleChangeDetails}
        />
        <div className="user_clinician">
          <p>Are you registered as a limited company?</p>
          <AntSwitch
            name="isLimitedCompany"
            checked={paymentDetails?.isLimitedCompany}
            onChange={handleSwitchChange}
            sx={{
              "& .MuiSwitch-thumb": {
                backgroundColor: paymentDetails?.isLimitedCompany
                  ? "#12e912"
                  : "black",
              },
            }}
          />
        </div>
        <div className="user_clinician">
          <p>Are you VAT registered?</p>
          <AntSwitch
            name="isVatRegistered"
            checked={paymentDetails?.isVatRegistered}
            onChange={handleSwitchChange}
            sx={{
              "& .MuiSwitch-thumb": {
                backgroundColor: paymentDetails?.isLimitedCompany
                  ? "#12e912"
                  : "black",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default AccountDetails;
