import React from "react";
import "./../../assets/css/CallerControl.scss";
import { PiPhoneFill } from "react-icons/pi";
import useTitanApp from "../../hooks/useTitanApp";
import { FaPlay } from "react-icons/fa";
import DPIcon from "./../../assets/images/dp-icon.png";
import { RxOpenInNewWindow } from "react-icons/rx";
import { FaCircle } from "react-icons/fa";

export default function CallerControl({
  handleClickOpenOptionsDialog,
  handleClickCallScreenDialog,
  handleHangUpCall,
  nexmoCallStatus,
  minutes,
  seconds,
  isConsultation,
  formattedConsultationMinutes,
  formattedConsultationSeconds,
  handleConsultationStopClick,
  handleTopbarIconConsultationClick,
}) {
  const { nmsServiceState, appState } = useTitanApp();
  const { darkMode } = appState || {};
  const { service, isCalling } = nmsServiceState || {};

  const formattedCallMinutes = String(minutes).padStart(2, "0");
  const formattedCallSeconds = String(seconds).padStart(2, "0");

  return service && service.telephoneNumber ? (
    isCalling ? (
      nexmoCallStatus === "initializing" ||
      nexmoCallStatus === "started" ||
      nexmoCallStatus === "ringing" ? (
        <div className="caller_control caller_control_end">
          <div className="patient-info">
            <div className="patient-img">
              <img src={DPIcon} alt="Patient Icon" />
            </div>
            <p>{service?.patientName}</p>
          </div>

          <div className="icons d-flex">
            <div className="call_icon phone-fill" onClick={handleHangUpCall}>
              <PiPhoneFill size={18} color="black" />
            </div>
          </div>

          <div className="d-flex calling-label">
            <p className="caller_control_label">calling...</p>
            <RxOpenInNewWindow
              color={darkMode ? "#fff" : "#0a0a0a"}
              size={16}
              className="collapse-icon"
              onClick={handleClickCallScreenDialog}
            />
          </div>
        </div>
      ) : nexmoCallStatus === "answered" || isConsultation ? (
        <div className="caller_control caller_control_end answered">
          <div className="patient-info">
            <div className="patient-img">
              <img src={DPIcon} alt="Patient Icon" />
            </div>
            <p>{service?.patientName}</p>
          </div>

          <div className="icons d-flex">
            <div>
              <FaCircle color="#00CC6A" size={8} />
            </div>
            {isConsultation ? (
              <div className="stop-label" onClick={handleConsultationStopClick}>
                <p>Stop</p>
              </div>
            ) : (
              <>
                <div
                  className="call_icon phone-fill"
                  onClick={handleHangUpCall}
                >
                  <PiPhoneFill size={18} color="black" />
                </div>
              </>
            )}
          </div>

          <div className="d-flex calling-label">
            <p className="caller_control_label">
              {isConsultation
                ? formattedConsultationMinutes +
                  ":" +
                  formattedConsultationSeconds
                : formattedCallMinutes + ":" + formattedCallSeconds}
            </p>
            {isConsultation ? (
              <RxOpenInNewWindow
                color={darkMode ? "#fff" : "#0a0a0a"}
                size={16}
                className="collapse-icon"
                onClick={handleTopbarIconConsultationClick}
              />
            ) : (
              <RxOpenInNewWindow
                color={darkMode ? "#fff" : "#0a0a0a"}
                size={16}
                className="collapse-icon"
                onClick={handleClickCallScreenDialog}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="caller_control">
          <div
            className="d-flex start-nms"
            onClick={handleClickOpenOptionsDialog}
          >
            <div className="call_icon">
              <FaPlay size={15} color={darkMode ? "#fff" : "#0a0a0a"} />
            </div>
            <p className="caller_control_label">Start</p>
          </div>
        </div>
      )
    ) : (
      <div className="caller_control">
        <div
          className="d-flex start-nms"
          onClick={handleClickOpenOptionsDialog}
        >
          <div className="call_icon">
            <FaPlay size={15} color={darkMode ? "#fff" : "#0a0a0a"} />
          </div>
          <p className="caller_control_label">Start</p>
        </div>
      </div>
    )
  ) : null;
}
