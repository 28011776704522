import React from "react";
import TextField from "@mui/material/TextField";

function YearsExperience({ professionalDetails, handleChangeDetails }) {
  return (
    <div className="AddNew_sectionbox">
      <div className="prof_detail legal">
        <p className="orbit_card-title">Years of Experience</p>
      </div>
      <div>
        <TextField
          id="outlined-basic"
          label="No. of years"
          variant="outlined"
          fullwidth
          name="yearOfExperience"
          value={professionalDetails?.yearOfExperience || ""}
          onChange={handleChangeDetails}
        />
      </div>
    </div>
  );
}

export default YearsExperience;
