import { useSelector } from "react-redux";

const useTitanTeam = () => {
  // #region Hook Constants
  const teamState = useSelector((state) => state.team);
  // #endregion

  // #region App State Setters

  // #endregion

  // #region API getter/caller functions for components usage to dispatch REDUX extra reducers

  // #endregion

  // #region Loader Handlers

  // #endregion

  // #region Loaders for APIs call

  // #endregion

  // #region Return Object
  return {
    teamState,
  };
  // #endregion
};

export default useTitanTeam;
