import { dispatch, getDataFromStore } from "../store/store";

import {
  setIsAppLoading,
  setIsTabLoading,
  setIsChatVisible,
  setIsOrbitSessionData,
  setPharmacy,
  setTeam,
  setDarkModeTheme,
  setAuthToken,
  setOrbitUser,
  getAppData,
  setPharmaciesData,
  resetAppState,
  replaceAppState,
  removeAuthToken,
  setIsSelectionScreenData,
  userChangeCompanyData,
  getCompanyNamesData,
  updateLastLoginByEmailData,
  updateUserCurrentStatusData,
  updateLogoutByEmailData,
  setIsTeamUpdatedData,
  setIsSelectedTeamIndexData,
} from "../features/appSlice/appSlice";
import { logError } from "../services/logService";

const reducerName = "app";

// #region State Setters
export const setDarkMode = (isDarkMode) => {
  const darkMode = getDataFromStore(reducerName, "darkMode");
  if (isDarkMode !== darkMode) {
    dispatch(setDarkModeTheme(isDarkMode));
  }
};

export const toggleTheme = () => {
  const darkMode = getDataFromStore(reducerName, "darkMode") || false;
  setDarkMode(!darkMode);
};

export const setChatVisible = (isVisible) => {
  dispatch(setIsChatVisible(isVisible));
};

export const setIsOrbitSession = (isVisible) => {
  dispatch(setIsOrbitSessionData(isVisible));
};
export const setIsOrbitUser = (isOrbitUser) => {
  dispatch(setOrbitUser(isOrbitUser));
};
export const setIsSelectionScreen = (isSelectionScreen) => {
  dispatch(setIsSelectionScreenData(isSelectionScreen));
};

export const toggleChat = () => {
  const isChatVisible = getDataFromStore(reducerName, "isChatVisible") || false;
  setChatVisible(!isChatVisible);
};

export const setAppLoading = (isLoading) => {
  dispatch(setIsAppLoading(isLoading));
};

export const setTabLoading = (isLoading) => {
  dispatch(setIsTabLoading(isLoading));
};

export const setCurrentPharmacy = (pharmacy) => {
  dispatch(setPharmacy(pharmacy));
};

export const setCurrentTeam = (team) => {
  dispatch(setTeam(team));
};
export const setIsTeamUpdated = (value) => {
  dispatch(setIsTeamUpdatedData(value));
};
export const setSelectedTeamIndex = (value) => {
  dispatch(setIsSelectedTeamIndexData(value));
};
export const replaceState = (appData) => {
  dispatch(replaceAppState(appData));
};

export const resetApp = () => {
  dispatch(resetAppState());
};

export const setAuth = (newAuthToken) => {
  dispatch(setAuthToken(newAuthToken));
};

export const setPharmacies = (pharmacies) => {
  dispatch(setPharmaciesData(pharmacies));
};

export const removeAuth = () => {
  dispatch(removeAuthToken());
};

// #endregion

// #region API getter/caller functions for components usage to dispatch REDUX extra reducers
export const getApplicationData = async (featureSearchType = 6) => {
  let result = null;
  try {
    result = dispatch(getAppData(featureSearchType)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};
export const userChangeCompany = async (params) => {
  let result = null;
  try {
    result = dispatch(userChangeCompanyData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};
export const getCompanyNames = async (params) => {
  let result = null;
  try {
    result = dispatch(getCompanyNamesData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};
export const updateLastLoginByEmail = async () => {
  let result = null;

  try {
    result = await dispatch(updateLastLoginByEmailData()).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};
export const updateUserCurrentStatus = async (statusType) => {
  let result = null;
  try {
    result = await dispatch(updateUserCurrentStatusData(statusType)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};
export const updateLogoutByEmail = async () => {
  let result = null;

  try {
    result = await dispatch(updateLogoutByEmailData()).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};


// #endregion
