import React from "react";
import "./../../assets/css/NmsIntervention/NmsIntervention.scss";
import "./../../assets/clinicalcheck.css";
import { Skeleton } from "@mui/material";
import useTitanApp from "../../hooks/useTitanApp";

export default function LoadingComponentEarnings() {
  const { appState } = useTitanApp();
  const { darkMode } = appState;
  return (
    <div className="loading-card-orbit">
      <Skeleton
        sx={{ bgcolor: darkMode ? "grey.800" : "" }}
        height={50}
        animation="wave"
        variant="rounded"
      />
      <Skeleton
        sx={{ bgcolor: darkMode ? "grey.800" : "" }}
        height={50}
        animation="wave"
        variant="rounded"
      />
      <Skeleton
        sx={{ bgcolor: darkMode ? "grey.800" : "" }}
        height={50}
        animation="wave"
        variant="rounded"
      />
      <Skeleton
        sx={{ bgcolor: darkMode ? "grey.800" : "" }}
        height={50}
        animation="wave"
        variant="rounded"
      />
    </div>
  );
}
