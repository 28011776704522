import React, { useEffect, useRef, useState } from "react";
import useTitanApp from "../../hooks/useTitanApp";
import {
  setCurrentPharmacyServices,
  setFilterNameData,
  setScrollPosition,
} from "../../dispatchers/pharmacyServiceDispatchers";
import { LiaSearchSolid } from "react-icons/lia";

function ServicesNameFilter() {
  const { pharmacyServiceState } = useTitanApp();
  const {
    filterPharmacyService,
    pharmacyServices,
    filterNameValues,
    scrollPosition,
    originalPharmacyServices,
  } = pharmacyServiceState || {};
  const inputRef = useRef(null);

  // State to store scroll positions

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  // Restore scroll position when scrollPosition changes

  function handleSearchName(e) {
    const { value } = e?.target || {};
    const stagesWrapper = document.querySelector(".stages-wrapper");
    setScrollPosition(stagesWrapper.scrollLeft);
    setFilterNameData(value);

    if (value !== "") {
      const updatedPharmacyService = originalPharmacyServices?.map((stage) => ({
        ...stage,
        sortedServices: stage?.sortedServices?.map((service) => ({
          ...service,
          clinicalServices: service?.clinicalServices?.filter((patient) =>
            patient?.patientName.toLowerCase().includes(value.toLowerCase())
          ),
        })),
      }));
      setCurrentPharmacyServices(updatedPharmacyService);
    } else {
      // Reset to the original full dataset when the search field is cleared
      setCurrentPharmacyServices(originalPharmacyServices);
    }
  }

  return (
    <div>
      <div className="inputDiv">
        <span className="searchIcon">
          <LiaSearchSolid size={15} className="icons-color" />
        </span>
        <input
          id="searchname"
          name="searchname"
          type="text"
          className="searchPatient"
          placeholder="Filter on patient name"
          value={filterNameValues}
          onChange={handleSearchName}
          autoComplete="off"
          ref={inputRef}
        />
      </div>
    </div>
  );
}

export default ServicesNameFilter;
