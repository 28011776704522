class AddStage {
  constructor() {
    this.obj = {
      stageName: "",
      templateId: "",
      order: 0,
      stageType: 1,
      metaDataList: [],
    };
  }
}
export default AddStage;
