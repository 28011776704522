import React from "react";
import "../assets/patientprofile.css";
import PropTypes from "prop-types";
import CustomLabel from "./controls/CustomLabel";
import { FaRegUser } from "react-icons/fa";
import { getFormattedDate } from "../utils/FormattedDate";
import { genderTypes } from "../enum/genderTypes";
import useTitanApp from "../hooks/useTitanApp";
function PatientProfile() {
  const { clincicalChecksState } = useTitanApp();
  const { prescriptions } = clincicalChecksState || {};
  const { patient } = prescriptions || {};
  const patientName = `${patient?.title} ${patient?.forename} ${patient?.middleName} ${patient?.surname}`,
    dob = getFormattedDate(patient?.dob),
    sex = genderTypes[patient?.sex],
    nhsNumber = patient?.nhsNumber,
    patientAddress = `${patient?.preferredAddress?.address?.houseName} ${
      patient?.preferredAddress?.address?.streetName
    }, ${
      patient?.preferredAddress?.address?.locality
        ? patient?.preferredAddress?.address?.locality + ", "
        : ""
    }${patient?.preferredAddress?.address?.town}, ${
      patient?.preferredAddress?.address?.county
    }, ${patient?.preferredAddress?.address?.postcode}`,
    patientAllergy = "Unknown",
    patientSurgery = `${patient?.surgery?.surgery?.name}, ${patient?.surgery?.surgery?.address?.houseName}, ${patient?.surgery?.surgery?.address?.streetName}, ${patient?.surgery?.surgery?.address?.postcode}`;
  return (
    <div className="user-profile-card customcard">
      <div className="card-step-number">
        <h2>2</h2>
      </div>
      <h2 className="card-heading">
        <FaRegUser />
        {patientName}
      </h2>
      <div className="card-inner-boxes">
        <div className="patient-profile">
          <span>
            <CustomLabel name="Date of Birth" />
            <p className="card-inner-value"> {dob}</p>
          </span>
        </div>
        <div className="patient-profile">
          <span>
            <CustomLabel name="Sex" />
            <p className="card-inner-value">{sex}</p>
          </span>
        </div>
        <div className="patient-profile">
          <span>
            <CustomLabel name="NHS Number" />
            <p className="card-inner-value">{nhsNumber}</p>
          </span>
        </div>
      </div>
      <div className="card-inner-boxes">
        <div className="patient-profile">
          <span>
            <CustomLabel name="Address" />
            <p className="card-inner-value"> {patientAddress}</p>
          </span>
        </div>
        <div className="patient-profile">
          <span>
            <CustomLabel name="Allergy" />
            <p className="card-inner-value">{patientAllergy}</p>
          </span>
        </div>
        <div className="patient-profile">
          <span>
            <CustomLabel name="Surgery" />
            <p className="card-inner-value">{patientSurgery}</p>
          </span>
        </div>
      </div>
    </div>
  );
}

export default PatientProfile;
