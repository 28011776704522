import React from "react";
import "../assets/nopatientpopup.css";
import CustomButton from "./controls/CustomButton";
import { useNavigate } from "react-router-dom";
import useTitanApp from "../hooks/useTitanApp";

function NopatientPopup({ screen }) {
  const { appState } = useTitanApp();
  const { isOrbitUser } = appState || {};

  const navigate = useNavigate();

  function routeToDashboard() {
    isOrbitUser ? navigate(`/orbit`) : navigate("/");
  }
  return (
    <div className="row" style={{ marginTop: " 200px" }}>
      <div className=" col-4 "></div>
      <div className="nopatient-card popup-styles col-4">
        <div>
          <p>{`There are no more ${screen} to process.`}</p>

          <CustomButton label="Go to Dashboard" action={routeToDashboard} />
        </div>
      </div>
      <div className=" col-4 "></div>
    </div>
  );
}

export default NopatientPopup;
