import React from "react";
import useTitanApp from "../../../../hooks/useTitanApp";
import { getInvoiceUrl } from "../../../../dispatchers/billingDispatchers";
import { showSuccess } from "../../../ToastedMessage/ToastedMessage";
function Invoices() {
  const { billingState } = useTitanApp();
  const { invoiceData } = billingState || {};

  const handleViewInvoice = (invoiceId) => {
    const response = getInvoiceUrl(invoiceId);
    response.then((result) => {
      if (result?.onlineInvoiceUrl) {
        showSuccess("Invoice generated successfully.");
        window.open(result?.onlineInvoiceUrl, "_blank");
      }
    });
  };

  return (
    <div className="invoice_wrapper">
      {invoiceData?.length > 0 ? (
        <>
          <div className="invoice_header">
            <div className="date-col">
              <p>Date</p>
            </div>
            <div className="d-flex invoice-col">
              <p>Invoice</p>
            </div>
            <div className="status-col">
              <p>Status</p>
            </div>
            <div className="amount-due-col">
              <p>Amount Due</p>
            </div>
          </div>
          {invoiceData?.map((invoice, index) => {
            return (
              <div className="invoice_container" key={index}>
                <div className="date-col">
                  <p>{invoice.dateFormatted}</p>
                </div>
                <div className="d-flex invoice-col">
                  <p>{invoice.invoiceNumber || invoice.invoiceID}</p>
                  <div className="d-flex invoice-link">
                    <p onClick={() => handleViewInvoice(invoice.invoiceID)}>View invoice</p>
                  </div>
                </div>
                <div className="status-col">
                  <p>{invoice.statusFormatted}</p>
                </div>
                <div className="amount-due-col">
                  <p>£ {invoice.amountDue || 0}</p>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <>No invoices generated yet. </>
      )}
    </div>
  );
}

export default Invoices;
