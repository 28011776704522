import React, { useState } from "react";
import { useEffect } from "react";
import "./../../../../../assets/css/Dashboard/ServicesKanban.scss";
import useTitanApp from "../../../../../hooks/useTitanApp";
import AsyncRendererRouter from "../../../../../components/AsyncRendererRouter/AsyncRendererRouter";
import { Skeleton } from "@mui/material";
import NmsProgressModal from "../../../../../components/NmsProgressModal/NmsProgressModal";
import PatientContactModal from "../../../../../components/PatientContactModal/PatientContactModal";
import ServicesFilter from "../../../../../components/ServicesFilter/ServicesFilter";
import ServicesNameFilter from "../../../../../components/ServicesFilter/ServicesNameFilter";
import SortingServices from "../SortingServices";
import ServicesKanbanAccordian from "../ServicesKanban/ServiceKanbanAccordian";
import { getTotalServicesCount } from "../../../../../data/getStartDateForStages";
import OrbitQueuePopup from "../../../../../components/OrbitQueuePopup/OrbitQueuePopup";
import ServicesContainerFooter from "../ServicesListView/ServicesContainerFooter";
import ServicesAutomation from "../../../../../components/ServicesAutomation/ServicesAutomation";
import { ReactComponent as AutomateIcon } from "./../../../../../assets/images/automate-icon.svg";
import moment from "moment";
import {
  sendToOrbit,
  setPharmacyServiceLoading,
  sendSMS,
} from "../../../../../dispatchers/pharmacyServiceDispatchers";
import { useRevalidator } from "react-router";
import RescheduleNms from "../RescheduleNms";

function ServicesKanban({ handleChange }) {
  const { pharmacyServiceState, appState } = useTitanApp();
  const { features } = appState || {};
  const { ifPharmacyAction } = features || {};
  const { darkMode, currentUser, currentPharmacy } = appState || {};
  const revalidator = useRevalidator();
  const {
    isPharmacyServiceLoading,
    pharmacyServices,
    isNmsProgress,
    isPatientContact,
    currentServiceId,
    scrollPosition,
    isSentToOrbit,
    sendSMSAutomation,
    sendOrbitAutomation,
    interventionStageScrollPosition,
    eligibilityStageScrollPosition,
  } = pharmacyServiceState;

  useEffect(() => {
    const stagesWrapper = document.getElementById("stages-wrapper-id");
    if (stagesWrapper) {
      stagesWrapper.scrollLeft = scrollPosition;
    }
  }, [scrollPosition]);
  useEffect(() => {
    const stageIntervention = document.getElementById("stage_Intervention");
    if (stageIntervention) {
      stageIntervention.scrollTop = interventionStageScrollPosition;
    }
  }, [interventionStageScrollPosition]);
  useEffect(() => {
    const stageEligibility = document.getElementById("stage_Eligible");
    if (stageEligibility) {
      stageEligibility.scrollTop = eligibilityStageScrollPosition;
    }
  }, [eligibilityStageScrollPosition]);

  const getKanbanWidth = () => {
    return pharmacyServices?.length === 1
      ? "100%"
      : pharmacyServices?.length === 2
      ? "45%"
      : pharmacyServices?.length > 2
      ? "430px"
      : "";
  };

  const [smsQueue, setCurrentSmsQueue] = useState([]);
  const [reSmsQueue, setCurrentReSmsQueue] = useState([]);
  const [interventionQueue, setCurrentInterventionQueue] = useState([]);
  const [declineQueue, setCurrentDeclineQueue] = useState([]);
  const [rescheduleQueue, setCurrentRescheduleQueue] = useState([]);
  const [pdsQueue, setCurrentPdsQueue] = useState([]);
  const [orbitQueue, setCurrentOrbitQueue] = useState([]);
  const [showReschedule, setShowReschedule] = useState(false);
  const [checksCount, setChecksCount] = useState(0);
  const [servicesUpdatedData, setServicesUpdatedData] = useState([]);

  return (
    <>
      <div className="pharmacy_header_modal">
        <AsyncRendererRouter resolve={pharmacyServices}>
          <div className="services_content-bg services_kanban">
            {pharmacyServices?.length !== 0 ? (
              <>
                {isPharmacyServiceLoading ? (
                  <div className="service_filter_skeleton">
                    <Skeleton
                      sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                      height={40}
                      animation="wave"
                      variant="rounded"
                    />
                  </div>
                ) : (
                  <div className="search_nfilter">
                    <div className="d-flex">
                      <ServicesFilter />
                      <ServicesNameFilter />
                    </div>
                    {ifPharmacyAction && <ServicesAutomation />}
                  </div>
                )}
                {/* {isPharmacyServiceLoading ? (
                <div className="service_filter_skeleton">
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                    height={40}
                  />
                </div>
              ) : (
                <>
                  <NMSPercentageCompleted />
                </>
              )} */}

                <div
                  id="stages-wrapper-id"
                  className="stages-wrapper"
                  style={{ overflow: "scroll", scrollLeft: scrollPosition }}
                >
                  {isNmsProgress && <NmsProgressModal />}
                  {isSentToOrbit && <OrbitQueuePopup />}
                  {isPatientContact && <PatientContactModal />}
                  {pharmacyServices?.map((stage, index) => {
                    return (
                      <div
                        id={"stage_" + stage?.stageName}
                        className="stage"
                        key={index}
                        style={{
                          minWidth: getKanbanWidth(),
                        }}
                      >
                        <div className="stage_options">
                          <p className="service_title">
                            {isPharmacyServiceLoading ? (
                              <Skeleton
                                sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                                width={100}
                                height={40}
                                animation="wave"
                              />
                            ) : currentServiceId == 0 ? (
                              `${stage?.stageName} ${getTotalServicesCount(
                                stage?.sortedServices,
                                stage?.stageName
                              )}`
                            ) : (
                              `${stage?.stageName} ${getTotalServicesCount(
                                stage?.sortedServices,
                                stage?.stageName
                              )}`
                            )}

                            {isPharmacyServiceLoading ? (
                              ""
                            ) : sendSMSAutomation &&
                              stage.stageName === "Eligible" ? (
                              <div className="automate-btn">
                                <AutomateIcon />
                              </div>
                            ) : (
                              ""
                            )}

                            {isPharmacyServiceLoading ? (
                              ""
                            ) : sendOrbitAutomation &&
                              stage.stageName === "Intervention" ? (
                              <div className="automate-btn">
                                <AutomateIcon />
                              </div>
                            ) : (
                              ""
                            )}
                          </p>

                          {stage?.sortedServices?.length > 0 ? (
                            <SortingServices stageType={stage?.stageType} />
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className="data-box-container">
                          {stage?.sortedServices?.map((service, index) => {
                            if (
                              service?.dueType === 1 &&
                              service?.clinicalServices?.length !== 0
                            ) {
                              return (
                                <ServicesKanbanAccordian
                                  key={index}
                                  service={service}
                                  currentIndex={index}
                                  stage={stage}
                                  smsQueue={smsQueue}
                                  reSmsQueue={reSmsQueue}
                                  interventionQueue={interventionQueue}
                                  declineQueue={declineQueue}
                                  rescheduleQueue={rescheduleQueue}
                                  pdsQueue={pdsQueue}
                                  orbitQueue={orbitQueue}
                                  setCurrentSmsQueue={setCurrentSmsQueue}
                                  setCurrentReSmsQueue={setCurrentReSmsQueue}
                                  setCurrentInterventionQueue={
                                    setCurrentInterventionQueue
                                  }
                                  setCurrentDeclineQueue={
                                    setCurrentDeclineQueue
                                  }
                                  setCurrentRescheduleQueue={
                                    setCurrentRescheduleQueue
                                  }
                                  setCurrentPdsQueue={setCurrentPdsQueue}
                                  setCurrentOrbitQueue={setCurrentOrbitQueue}
                                  checksCount={checksCount}
                                  setChecksCount={setChecksCount}
                                  servicesUpdatedData={servicesUpdatedData}
                                />
                              );
                            }
                            if (
                              service?.dueType === 2 &&
                              service?.clinicalServices?.length !== 0
                            ) {
                              return (
                                <ServicesKanbanAccordian
                                  service={service}
                                  currentIndex={index}
                                  stage={stage}
                                  smsQueue={smsQueue}
                                  reSmsQueue={reSmsQueue}
                                  interventionQueue={interventionQueue}
                                  declineQueue={declineQueue}
                                  rescheduleQueue={rescheduleQueue}
                                  pdsQueue={pdsQueue}
                                  orbitQueue={orbitQueue}
                                  setCurrentSmsQueue={setCurrentSmsQueue}
                                  setCurrentReSmsQueue={setCurrentReSmsQueue}
                                  setCurrentInterventionQueue={
                                    setCurrentInterventionQueue
                                  }
                                  setCurrentDeclineQueue={
                                    setCurrentDeclineQueue
                                  }
                                  setCurrentRescheduleQueue={
                                    setCurrentRescheduleQueue
                                  }
                                  setCurrentPdsQueue={setCurrentPdsQueue}
                                  setCurrentOrbitQueue={setCurrentOrbitQueue}
                                  checksCount={checksCount}
                                  setChecksCount={setChecksCount}
                                  servicesUpdatedData={servicesUpdatedData}
                                />
                              );
                            }
                            if (
                              service?.dueType === 3 &&
                              service?.clinicalServices?.length !== 0
                            ) {
                              return (
                                <ServicesKanbanAccordian
                                  service={service}
                                  currentIndex={index}
                                  stage={stage}
                                  smsQueue={smsQueue}
                                  reSmsQueue={reSmsQueue}
                                  interventionQueue={interventionQueue}
                                  declineQueue={declineQueue}
                                  rescheduleQueue={rescheduleQueue}
                                  pdsQueue={pdsQueue}
                                  orbitQueue={orbitQueue}
                                  setCurrentSmsQueue={setCurrentSmsQueue}
                                  setCurrentReSmsQueue={setCurrentReSmsQueue}
                                  setCurrentInterventionQueue={
                                    setCurrentInterventionQueue
                                  }
                                  setCurrentDeclineQueue={
                                    setCurrentDeclineQueue
                                  }
                                  setCurrentRescheduleQueue={
                                    setCurrentRescheduleQueue
                                  }
                                  setCurrentPdsQueue={setCurrentPdsQueue}
                                  setCurrentOrbitQueue={setCurrentOrbitQueue}
                                  checksCount={checksCount}
                                  setChecksCount={setChecksCount}
                                  servicesUpdatedData={servicesUpdatedData}
                                />
                              );
                            }
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <div className="pharmacy-error-message">
                <p className="para_color">Data not available!</p>
              </div>
            )}
          </div>
        </AsyncRendererRouter>
      </div>
      <ServicesContainerFooter
        smsQueue={smsQueue}
        interventionQueue={interventionQueue}
        declineQueue={declineQueue}
        rescheduleQueue={rescheduleQueue}
        pdsQueue={pdsQueue}
        orbitQueue={orbitQueue}
        setCurrentSmsQueue={setCurrentSmsQueue}
        setCurrentReSmsQueue={setCurrentReSmsQueue}
        setCurrentInterventionQueue={setCurrentInterventionQueue}
        setCurrentDeclineQueue={setCurrentDeclineQueue}
        setCurrentRescheduleQueue={setCurrentRescheduleQueue}
        setCurrentPdsQueue={setCurrentPdsQueue}
        setCurrentOrbitQueue={setCurrentOrbitQueue}
        handleChange={handleChange}
        reSmsQueue={reSmsQueue}
        view="kanban"
        setShowReschedule={setShowReschedule}
        checksCount={checksCount}
        setChecksCount={setChecksCount}
        setServicesUpdatedData={setServicesUpdatedData}
      />
      {showReschedule && (
        <RescheduleNms
          view="kanban"
          setShowReschedule={setShowReschedule}
          rescheduleQueue={rescheduleQueue}
          handleChange={handleChange}
          setCurrentSmsQueue={setCurrentSmsQueue}
          setCurrentReSmsQueue={setCurrentReSmsQueue}
          setCurrentInterventionQueue={setCurrentInterventionQueue}
          setCurrentDeclineQueue={setCurrentDeclineQueue}
          setCurrentRescheduleQueue={setCurrentRescheduleQueue}
          setCurrentPdsQueue={setCurrentPdsQueue}
          setCurrentOrbitQueue={setCurrentOrbitQueue}
          setChecksCount={setChecksCount}
        />
      )}
    </>
  );
}
export default React.memo(ServicesKanban);
