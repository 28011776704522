import { getObject } from "../getObjectValue";

class unitsOfMeasure {
  constructor(response) {
    this.obj = response?.map((value) => {
      return {
        code: getObject(value, "code"),
        description: getObject(value, "description"),
      };
    });
  }
}

export default unitsOfMeasure;
