import { dispatch, getDataFromStore } from "../store/store";
import {
  setCurrentSmsQueue,
  setCurrentOrbitQueue,
  setCurrentInterventionQueue,
  setScrollPosition,
  setInterventionStageScrollPosition,
  setEligibilityStageScrollPosition,
  setListViewGridScrollPosition,
  setCurrentRescheduleQueue,
  setCurrentPdsQueue,
  setCurrentDeclineQueue,
} from "../dispatchers/pharmacyServiceDispatchers";
import moment from "moment";
const reducerName = "pharmacyService";
const reducerNameApp = "app";

export const addServiceForOrbit = (event, addedService, view) => {
  if (view === "kanban") {
    const stagesWrapper = document.querySelector(".stages-wrapper");
    setScrollPosition(stagesWrapper.scrollLeft);
    const stageIntervention = document.getElementById("stage_Intervention");
    setInterventionStageScrollPosition(stageIntervention.scrollTop);
  }

  if (view === "listview") {
    const agGridWrapperScroll = document.querySelector(".ag-body-viewport");

    setListViewGridScrollPosition(agGridWrapperScroll.scrollTop);
  }

  const orbitQueue = getDataFromStore(reducerName, "orbitQueue");
  const currentPharmacy = getDataFromStore(reducerNameApp, "currentPharmacy");
  const currentUser = getDataFromStore(reducerNameApp, "currentUser");
  const { checked } = event?.target || {};
  if (checked) {
    const serviceDueDateTime = moment(
      addedService?.stages.find((stage) => stage?.stageType === 3)?.startDate
    ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    const obj = {
      pharmacyId: addedService?.pharmacyID,
      serviceId: addedService?.serviceID,
      clinicalServiceID: addedService?._id,
      serviceType: 1,
      serviceCode: 1,
      patientId: addedService?.patientId,
      patientTelNumber: addedService?.telephoneNumber,
      patientName: addedService?.patientName,
      priorityWeighting: "0",
      rate: "",
      serviceDueDateTime: serviceDueDateTime,
      addedToQueueDateTime: new Date(),
      status: 1,
      bookingType: 1,
      sentToOrbitUser: currentUser?.fullName,
      userPickedBy: "",
      stageType: 3,
    };
    setCurrentOrbitQueue([...orbitQueue, obj]);
  } else {
    const newArray = orbitQueue?.filter(
      (obj) => obj?.clinicalServiceID !== addedService?._id
    );
    setCurrentOrbitQueue(newArray);
  }
};

export const addServiceInQueues = (event, data, view) => {
  const { service, stageType } = data;
  const addedService = service;
  if (view === "kanban") {
    const stagesWrapper = document.querySelector(".stages-wrapper");
    setScrollPosition(stagesWrapper.scrollLeft);
    const stageEligible = document.getElementById("stage_Eligible");
    setEligibilityStageScrollPosition(stageEligible.scrollTop);
  }

  if (view === "listview") {
    const agGridWrapperScroll = document.querySelector(".ag-body-viewport");
    setListViewGridScrollPosition(agGridWrapperScroll.scrollTop);
  }
  const { checked } = event?.target || {};
  const smsQueue = getDataFromStore(reducerName, "smsQueue");
  const interventionQueue = getDataFromStore(reducerName, "interventionQueue");
  const declineQueue = getDataFromStore(reducerName, "declineQueue");
  const rescheduleQueue = getDataFromStore(reducerName, "rescheduleQueue");
  const pdsQueue = getDataFromStore(reducerName, "pdsQueue");
  const orbitQueue = getDataFromStore(reducerName, "orbitQueue");
  const currentUser = getDataFromStore(reducerNameApp, "currentUser");
  const currentPharmacy = getDataFromStore(reducerNameApp, "currentPharmacy");
  switch (stageType) {
    case 1:
      if (checked) {
        const obj = {
          patientID: addedService?.patientId,
          patientName: addedService?.patientName,
          pharmacyID: addedService?.pharmacyID,
          message: "",
          clinicalServiceID: addedService?._id,
          pharmacyName: currentPharmacy?.teamPharmacyName,
          ods: "",
        };
        setCurrentSmsQueue([...smsQueue, obj]);
        if (addedService?.isConsent) {
          setCurrentInterventionQueue([
            ...interventionQueue,
            addedService?._id,
          ]);
        }
        setCurrentRescheduleQueue([...rescheduleQueue, addedService?._id]);
        setCurrentPdsQueue([...pdsQueue, addedService?._id]);
        setCurrentDeclineQueue([...declineQueue, addedService?._id]);
      } else {
        const newArray = smsQueue?.filter(
          (obj) => obj?.clinicalServiceID !== addedService?._id
        );
        const newArrayIntervention = interventionQueue?.filter(
          (obj) => obj !== addedService?._id
        );
        const newArrayDecline = declineQueue?.filter(
          (obj) => obj !== addedService?._id
        );
        const newArrayReschedule = rescheduleQueue?.filter(
          (obj) => obj !== addedService?._id
        );
        const newArrayPds = pdsQueue?.filter(
          (obj) => obj !== addedService?._id
        );
        setCurrentSmsQueue(newArray);
        setCurrentInterventionQueue(newArrayIntervention);
        setCurrentDeclineQueue(newArrayDecline);
        setCurrentRescheduleQueue(newArrayReschedule);
        setCurrentPdsQueue(newArrayPds);
      }
      break;
    case 2:
      if (checked) {
        setCurrentRescheduleQueue([...rescheduleQueue, addedService?._id]);
        setCurrentDeclineQueue([...declineQueue, addedService?._id]);
        setCurrentPdsQueue([...pdsQueue, addedService?._id]);
      } else {
        const newArrayDecline = declineQueue?.filter(
          (obj) => obj !== addedService?._id
        );
        const newArrayReschedule = rescheduleQueue?.filter(
          (obj) => obj !== addedService?._id
        );
        const newArrayPds = pdsQueue?.filter(
          (obj) => obj !== addedService?._id
        );
        setCurrentDeclineQueue(newArrayDecline);
        setCurrentRescheduleQueue(newArrayReschedule);
        setCurrentPdsQueue(newArrayPds);
      }
      break;
    case 3:
      if (checked) {
        const serviceDueDateTime = moment(
          addedService?.stages.find((stage) => stage?.stageType === 3)
            ?.startDate
        ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        const obj = {
          pharmacyId: addedService?.pharmacyID,
          serviceId: addedService?.serviceID,
          clinicalServiceID: addedService?._id,
          serviceType: 1,
          serviceCode: 1,
          patientId: addedService?.patientId,
          patientTelNumber: addedService?.telephoneNumber,
          patientName: addedService?.patientName,
          priorityWeighting: "0",
          rate: "",
          serviceDueDateTime: serviceDueDateTime,
          addedToQueueDateTime: new Date(),
          status: 1,
          bookingType: 1,
          sentToOrbitUser: currentUser?.fullName,
          userPickedBy: "",
          stageType: 3,
        };
        setCurrentOrbitQueue([...orbitQueue, obj]);
        setCurrentRescheduleQueue([...rescheduleQueue, addedService?._id]);
        setCurrentPdsQueue([...pdsQueue, addedService?._id]);
        setCurrentDeclineQueue([...declineQueue, addedService?._id]);
      } else {
        const newArray = orbitQueue?.filter(
          (obj) => obj?.clinicalServiceID !== addedService?._id
        );
        const newArrayDecline = declineQueue?.filter(
          (obj) => obj !== addedService?._id
        );
        const newArrayReschedule = rescheduleQueue?.filter(
          (obj) => obj !== addedService?._id
        );
        const newArrayPds = pdsQueue?.filter(
          (obj) => obj !== addedService?._id
        );
        setCurrentDeclineQueue(newArrayDecline);
        setCurrentRescheduleQueue(newArrayReschedule);
        setCurrentOrbitQueue(newArray);
        setCurrentPdsQueue(newArrayPds);
      }
      break;
    case 4:
      if (checked) {
        setCurrentRescheduleQueue([...rescheduleQueue, addedService?._id]);
        setCurrentDeclineQueue([...declineQueue, addedService?._id]);
        setCurrentPdsQueue([...pdsQueue, addedService?._id]);
      } else {
        const newArrayDecline = declineQueue?.filter(
          (obj) => obj !== addedService?._id
        );
        const newArrayReschedule = rescheduleQueue?.filter(
          (obj) => obj !== addedService?._id
        );
        const newArrayPds = pdsQueue?.filter(
          (obj) => obj !== addedService?._id
        );
        setCurrentDeclineQueue(newArrayDecline);
        setCurrentRescheduleQueue(newArrayReschedule);
        setCurrentPdsQueue(newArrayPds);
      }
      break;
  }
};

export const isSmsSent = (serviceId) => {
  const pharmacyServicesSms = getDataFromStore(
    reducerName,
    "pharmacyServicesSms"
  );

  const hasClinicalService = pharmacyServicesSms.some(
    (item) => item?.clinicalServiceID === serviceId
  );
  return hasClinicalService;
};

export const getSmsDate = (clinicalServiceID) => {
  const pharmacyServicesSms = getDataFromStore(
    reducerName,
    "pharmacyServicesSms"
  );

  const foundItem = pharmacyServicesSms.find(
    (item) => item.clinicalServiceID === clinicalServiceID
  );

  if (foundItem) {
    let momentdate = moment(foundItem.smsDate);
    let date = momentdate?.format("DD/MM");
    return date;
  } else {
    return null;
  }
};
