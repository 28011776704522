import React from "react";
import PropTypes from "prop-types";
import "./../../../../../../assets/css/Dashboard/DeleteEventPopup.scss";

const DeleteEventPopup = ({ event, onCancel, onDelete }) => {
  return (
    <div className="delete-event-popup">
      <h2>Delete Event</h2>
      <p>
        Are you sure you want to delete the event "
        <i className="event_title">{event.title}</i>"?
      </p>
      <div className="delete-event-popup-buttons">
        <button onClick={onDelete}>Delete</button>
        <button onClick={onCancel}>Cancel</button>
      </div>
    </div>
  );
};

DeleteEventPopup.propTypes = {
  event: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteEventPopup;
