import React from "react";
import OrbitSettingsSidebar from "../../OrbitSettingsSidebar/OrbitSettingsSidebar";
import EnhancedDBSPoliceCheck from "./components/EnhancedDBSPoliceCheck";
import IndemnityInsurance from "./components/IndemnityInsurance";
import PhotographicID from "./components/PhotographicID";
import "./../../../assets/css/OrbitSettings/OrbitSettings.scss";
import CustomButton from "../../controls/CustomButton";
import AsyncRendererRouter from "../../AsyncRendererRouter/AsyncRendererRouter";
import { useLoaderData } from "react-router-dom";
import useTitanApp from "../../../hooks/useTitanApp";
import CustomLoader from "../../controls/CustomLoader";
import {
  saveOrbitProfile,
  setCurrentCompDocData,
  setCurrentOrbitProfile,
} from "../../../dispatchers/orbitDispatchers";
import { showError } from "../../ToastedMessage/ToastedMessage";
import useTimer from "../../../hooks/useTimer";
import { ElectricalServicesOutlined } from "@mui/icons-material";

export default function ComplianceDocuments() {
  const { timer, isActive, handleStart, handleReset } = useTimer();

  const { orbitState } = useTitanApp();
  const { isOrbitLoading, orbitProfile, compDocDetails } = orbitState;
  const { appData } = useLoaderData();
  const [isDateError, setDateError] = React.useState(false);
  const [expanded, setExpanded] = React.useState({
    policeCheck: false,
    indemnityInsurance: false,
    photographicID: false,
  });

  const handleChange = (panel) => {
    setExpanded((prev) => ({
      ...prev,
      [panel]: !prev[panel],
    }));
  };
  function handleChangeDocument(event, field, type) {
    const { name, value } = event || {};
    setCurrentCompDocData({
      ...compDocDetails,
      [field]: {
        ...compDocDetails[field],
        [name]: value,
        ["fileType"]: type,
        ["isUploaded"]: value != "" ? true : false,
      },
    });
  }
  function handleChangeDetails(event, field) {
    const { name, value } = event?.target || {};
    if (name === "issueDate" || name === "expiryDate") {
      const updatedDate = new Date(value);
      const otherDate = compDocDetails[field]?.expiryDate
        ? new Date(compDocDetails[field]?.expiryDate)
        : null;
      if (name === "issueDate" && otherDate && updatedDate > otherDate) {
        showError("Issue Date cannot be greater than Expiry Date", true, false);
        setDateError(true);
        return;
      } else if (
        name === "expiryDate" &&
        otherDate &&
        updatedDate < otherDate
      ) {
        showError("Expiry Date cannot be less than Issue Date", true, false);
        setDateError(true);
        return;
      } else {
        setDateError(false);
      }
    }

    setCurrentCompDocData({
      ...compDocDetails,
      [field]: { ...compDocDetails[field], [name]: value },
    });
  }
  function handleSave() {
    if (!isDateError) {
      const obj = {
        ...orbitProfile,
        complianceDocuments: compDocDetails,
      };
      saveOrbitProfile(obj)
        .then((result) => {
          setCurrentOrbitProfile(result);
        })
        .catch(() =>
          showError("Compliance Documents could not be added.", true, false)
        );
    } else {
      showError(
        "Compliance Documents could not be added becuase of date issue",
        true,
        false
      );
    }
  }
  return (
    <AsyncRendererRouter
      isLoading={isOrbitLoading}
      resolve={appData}
      loadingElement={
        <div className="settings_page">
          <div className="settings_wrapper">
            <OrbitSettingsSidebar />
            <div className="settings_content_wrapper orbit_settings_content_wrapper">
              <CustomLoader />
            </div>
          </div>
        </div>
      }
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <OrbitSettingsSidebar />

          <div className="settings_content_wrapper">
            <div className="settings_heading">
              <h5>Compliance Documents</h5>
              <p>Please upload the following mandatory documents.</p>
            </div>
            <div className="complianceDoc_sections">
              <EnhancedDBSPoliceCheck
                expanded={expanded?.policeCheck}
                handleChange={() => handleChange("policeCheck")}
                handleChangeDetails={handleChangeDetails}
                Details={compDocDetails?.enhancedDB}
                handleChangeDocument={handleChangeDocument}
              />
              <IndemnityInsurance
                expanded={expanded?.indemnityInsurance}
                handleChange={() => handleChange("indemnityInsurance")}
                handleChangeDetails={handleChangeDetails}
                Details={compDocDetails?.indemnityEnsurance}
                handleChangeDocument={handleChangeDocument}
              />
              <PhotographicID
                expanded={expanded?.photographicID}
                handleChange={() => handleChange("photographicID")}
                handleChangeDetails={handleChangeDetails}
                Details={compDocDetails?.photographicId}
                handleChangeDocument={handleChangeDocument}
              />
            </div>
            <div className="footer_btn">
              <CustomButton label="Save" action={handleSave} />
            </div>
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
