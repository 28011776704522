import React, { useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "ag-grid-enterprise";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { ModuleRegistry } from "@ag-grid-community/core";
import useTitanApp from "../../../../../hooks/useTitanApp";
import PatientTag from "../PatientTag";
import CustomPatientRenderer from "./CustomPatientRenderer";
import CustomTagsFilterInput from "./CustomTagsFilterInput";
import TagsFilter from "./TagsFilter";
import { CollectionsBookmarkRounded } from "@mui/icons-material";

ModuleRegistry.registerModules([ClientSideRowModelModule, RowGroupingModule]);

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
  minValidYear: 2000,

  inRangeFloatingFilterDateFormat: "Do MMM YYYY",
};

function ServiceListViewGrid({
  smsQueue,
  reSmsQueue,
  interventionQueue,
  declineQueue,
  rescheduleQueue,
  pdsQueue,
  orbitQueue,
  setCurrentSmsQueue,
  setCurrentInterventionQueue,
  setCurrentDeclineQueue,
  setCurrentRescheduleQueue,
  setCurrentPdsQueue,
  setCurrentOrbitQueue,
  checkedAllEligible,
  setCheckedAllEligible,
  checkedAllConsent,
  setCheckedAllConsent,
  checkedAllIntervention,
  setCheckedAllIntervention,
  checkedAllFollowUp,
  setCheckedAllFollowUp,
  setCurrentReSmsQueue,
  setListviewOrbitClinicalServiceId,
  setShowOrbitQueuePopup,
  checksCount,
  setChecksCount,
  rowData,
  servicesUpdatedData,
}) {
  const { appState, pharmacyServiceState } = useTitanApp();
  const { darkMode } = appState || {};
  const gridRef = useRef(null);
  const columnDefs = [
    {
      headerName: "Stage Name",
      field: "stageName",
      rowGroup: true,
      hide: true,
    },
    {
      headerName: "Patient Name",
      field: "patientName",
      suppressFilter: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      filter: "agTextColumnFilter",
      rowGroup: true,
      hide: true,
    },
    {
      headerName: "Filter by Patient Name",
      field: "patientNameFilter",
      suppressMenu: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      hide: false,
      cellRenderer: (params) => {
        return null;
      },
    },
    {
      headerName: "Drug",
      field: "drugName",
      filter: "agTextColumnFilter",
      minWidth: 300,
    },
    { headerName: "Dosage", field: "dosage", filter: "agTextColumnFilter" },
    {
      headerName: "Due",
      field: "due",
      maxWidth: 100,

      cellRenderer: (params) => {
        if (params.value) {
          return (
            <>
              <span className="listview-due-tag">{params.value}</span>
            </>
          );
        } else {
          <></>;
        }
      },
    },
    {
      headerName: "Tags",
      field: "patientTags",
      filter: TagsFilter,
      floatingFilter: true,
      floatingFilterComponent: CustomTagsFilterInput,
      cellRenderer: (params) => {
        if (params.value || params.value !== null) {
          return (
            <div className="patient-tags">
              {params?.value?.map((tag, index) => (
                <PatientTag key={index} tag={tag} index={index} />
              ))}
            </div>
          );
        } else {
          <></>;
        }
      },
    },
    {
      headerName: "Handed Out Date",
      field: "handedoutDate",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
    },
    {
      headerName: "Consented",
      field: "consented",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Has Telephone Number",
      field: "hasTelephoneNumber",
      filter: "agTextColumnFilter",
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      floatingFilter: true,
      sortable: false,
    };
  }, []);

  const getRowId = useCallback((params) => {
    return params.data.rowIndex;
  });

  const groupRowRendererParams = useMemo(() => {
    return {
      suppressCount: true,
      innerRenderer: CustomPatientRenderer,
      innerRendererParams: {
        smsQueue,
        reSmsQueue,
        interventionQueue,
        declineQueue,
        rescheduleQueue,
        pdsQueue,
        orbitQueue,
        setCurrentSmsQueue,
        setCurrentReSmsQueue,
        setCurrentInterventionQueue,
        setCurrentDeclineQueue,
        setCurrentRescheduleQueue,
        setCurrentPdsQueue,
        setCurrentOrbitQueue,
        checkedAllEligible,
        setCheckedAllEligible,
        checkedAllConsent,
        setCheckedAllConsent,
        checkedAllIntervention,
        setCheckedAllIntervention,
        checkedAllFollowUp,
        setCheckedAllFollowUp,
        setListviewOrbitClinicalServiceId,
        setShowOrbitQueuePopup,
        checksCount,
        setChecksCount,
        servicesUpdatedData,
      },
    };
  });

  return (
    <div
      className={`listview-grid ${
        darkMode ? "ag-theme-quartz-dark" : "ag-theme-quartz"
      }`}
    >
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        getRowId={getRowId}
        groupDefaultExpanded={-1}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={true}
        reactiveCustomComponents
        rowSelection="single"
        groupRowRendererParams={groupRowRendererParams}
        groupDisplayType={"groupRows"}
        frameworkComponents={{
          customPatientRenderer: CustomPatientRenderer,
        }}
      />
    </div>
  );
}

export default ServiceListViewGrid;
