import { getObject } from "../getObjectValue";
import { v4 as uuidv4 } from "uuid";
class Medicationtime {
  constructor(response, patientMedicationId) {
    this.obj = response?.map((value) => {
      return {
        patientMedicationTimeId:
          value?.patientMedicationTimeId ===
          "00000000-0000-0000-0000-000000000000"
            ? uuidv4()
            : value?.patientMedicationTimeId,

        patientMedicationId:
          value?.patientMedicationId === "00000000-0000-0000-0000-000000000000"
            ? patientMedicationId
            : value?.patientMedicationId,

        dosageTime: value.dosageTime,

        dosageQuantity: value.dosageQuantity,
        dosageTimeColour: value.dosageTimeColour,
        patientMedicationOrder: value.patientMedicationOrder,
      };
    });
  }
}

export default Medicationtime;
