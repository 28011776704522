import React, { useState } from "react";

import Lottie from "lottie-react";
import Sparkles from "./../../../assets/Sparkles.json";
import { checksBase64Data } from "./../../../assets/images/checksBase64.js";
import { nmsBase64Data } from "./../../../assets/images/nmsBase64.js";
import { useNavigate } from "react-router-dom";
import useIdleTimeout from "../../../hooks/useIdleTimeout";

export default function OrbitActionGroup() {
  const { idleTimer } = useIdleTimeout({});
  const [activeIcon, setActiveIcon] = useState("");
  const navigate = useNavigate();

  const handleMouseEnter = (componentName) => {
    setActiveIcon(componentName);
  };
  const handleMouseLeave = () => {
    setActiveIcon("");
  };
  const handleNMSClick = () => {
    idleTimer.reset();
    navigate(`/nmsintervention/0`, {
      state: {
        serviceType: "",
      },
    });
  };
  const handleChecksClick = () => {
    idleTimer.reset();
    navigate("/clinicalchecks/00000000-0000-0000-0000-000000000000");
  };
  return (
    <div className="bottomActionBar_wrapper_left orbit_actionGroup_wrapper">
      {/* <div
        className={`bottomBar_image-box ${
          activeIcon === "Checks" ? "active" : ""
        }`}
        onMouseEnter={() => handleMouseEnter("Checks")}
        onMouseLeave={() => handleMouseLeave("")}
        onClick={handleChecksClick}
      >
        <div
          className={`bottomBar_countContainer ${
            activeIcon === "Checks" ? "shrinkRotate" : ""
          }`}
        ></div>
        <div
          className={`bottomBar_image-container ${
            activeIcon === "Checks" ? "overflow-visible" : ""
          }`}
        >
          <img
            className={`bottomBar_img checksImg ${
              activeIcon === "Checks" ? "rotate" : ""
            }`}
            src={checksBase64Data}
          />
          {activeIcon === "Checks" ? (
            <div className="sparklesAnimation">
              <Lottie animationData={Sparkles} />
            </div>
          ) : (
            ""
          )}
        </div>

        <p className="bottomBar_image-box-title">Checks</p>
      </div> */}
      <div
        className={`bottomBar_image-box ${
          activeIcon === "NMS" ? "active" : ""
        }`}
        onMouseEnter={() => handleMouseEnter("NMS")}
        onMouseLeave={() => handleMouseLeave("")}
        onClick={() => handleNMSClick()}
      >
        <div
          className={`bottomBar_countContainer ${
            activeIcon === "NMS" ? "shrinkRotate" : ""
          }`}
        >
          {/* <p>23</p> */}
        </div>
        <div
          className={`bottomBar_image-container ${
            activeIcon === "NMS" ? "overflow-visible" : ""
          }`}
        >
          <img
            className={`bottomBar_img NMSImg ${
              activeIcon === "NMS" ? "rotate" : ""
            }`}
            src={nmsBase64Data}
          />
          {activeIcon === "NMS" ? (
            <div className="sparklesAnimation">
              <Lottie animationData={Sparkles} />
            </div>
          ) : (
            ""
          )}
        </div>

        <p className="bottomBar_image-box-title">Nms</p>
      </div>
    </div>
  );
}
