import { dispatch, getDataFromStore } from "../store/store";
import { logError } from "../services/logService";
import { 
  getTemplatesData,
  addTemplateData,
  setIsConsultationLoading,
  setTemplatesData, 
} from "../features/templateSlice/templateSlice";

const reducerName = "template";

// #region State Setters
export const setConsultationLoading = (isLoading) => {
  dispatch(setIsConsultationLoading(isLoading));
};

export const setConsultation = (newTemplates) => {
  dispatch(setTemplatesData(newTemplates));
};

// #endregion

// #region API getter/caller functions for components usage to dispatch REDUX extra reducers
export const getAllTemplates = async (companyId) => {
  let result = [];
  try {
    result = await dispatch(
      getTemplatesData(companyId)
    ).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};
export const addTemplate = async (data) => {
  let result = null;

  try {
    result = await dispatch(addTemplateData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};
// #endregion
