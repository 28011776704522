export const generateObjectId = () => {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16); // Timestamp
  const objectId =
    timestamp +
    "xxxxxxxxxxxxxxxx"
      .replace(/[x]/g, () => {
        return ((Math.random() * 16) | 0).toString(16); // Random value
      })
      .toLowerCase();
  return objectId;
};
