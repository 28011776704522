import { useState, React } from "react";
import "./../../assets/css/MapDropdown.scss";

export default function MapDropdown({ handleCalback }) {
  const options = ["14 Days", "16 Days", "17 Days", "18 Days", "40 Days"];

  const [isOpen, setIsOpen] = useState(false);
  const [haveText, setHaveText] = useState("");

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleText = (day) => {
    setHaveText(day);
    const number = parseInt(day.match(/\d+/)[0]);

    handleCalback(number);
  };

  const itemList = (props) => {
    const list = props?.map((item) => (
      <div
        onClick={() => {
          handleText(item);
        }}
        className="dropdown__item"
        key={item?.toString()}
      >
        {item}
      </div>
    ));

    return <div className="dropdown__items"> {list} </div>;
  };

  return (
    <div
      className={isOpen ? "map_dropdown map_dd active" : "map_dropdown map_dd"}
      onClick={handleClick}
    >
      <div className="dropdown__text">
        {!haveText ? "Select option" : haveText}
      </div>
      {itemList(options)}
    </div>
  );
}
