import React, { useCallback } from "react";


export default ({ model, onModelChange }) => {
    const handleCheckboxChange = useCallback((event) => {
    const newValue = event?.target?.checked
      onModelChange(newValue ? true : null);
    });

  return (
    <div className="orbit_grid_filter">
    <label>
      <input
          type="checkbox"
          checked={model}
          onChange={handleCheckboxChange}
      />
      Orbit Users
    </label>
  </div>
  );
};
