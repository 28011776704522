import React, { useState, useEffect } from "react";
import {
  useNavigate,
  useLocation,
  useParams,
  Await,
  useLoaderData,
} from "react-router-dom";
import PatientTab from "../Dashboard/PatientListView/PatientTab";
import PharmacyTabs from "../../components/PharmacyTabs/PharmacyTabs";
import PharmacyHeaderDetails from "../../components/PharmacyHeaderDetails/PharmacyHeaderDetails";
import CustomLoader from "../../components/controls/CustomLoader";
import useTitanApp from "../../hooks/useTitanApp";
import Header from "../../components/Header/Header";
import DarkThemeButton from "../../components/DarkThemeButton";
import AsyncRendererRouter from "../../components/AsyncRendererRouter/AsyncRendererRouter";

const LoadingComponent = () => {
  const { appState } = useTitanApp();
  const { darkMode } = appState || {};
  return (
    <div className="pharmacy_header_modal">
      <div className="modal-content map-blur">
        <div className="tabs_box top-tabs">
          <PharmacyTabs value={false} />
          <PharmacyHeaderDetails />
        </div>

        <div
          className={`pharmacy-details-area pharmacy-patient-wrapper ${
            darkMode === false ? "" : "map-blur-black"
          }`}
        >
          <div className="patient_tab">
            <div className="patient_tab_patient_list">
              <div className="patient_list_no_data_found_message">
                <CustomLoader />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function PharmacyPatient() {
  const { appState } = useTitanApp();

  const {
    currentPharmacy,
    features,
    isValidOrg,
    currentPharmacyId,
    isAppLoading,
    isTabLoading,
    darkMode,
  } = appState || {};
  const navigate = useNavigate();
  const { pharmacyId } = useParams() || { pharmacyId : "00000000-0000-0000-0000-000000000000" };
  const [getServicesCount] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      // Update the screenWidth state on window resize
      setScreenWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const location = useLocation();
  const { ifMapEnabled, ifPharmacyOverviewEnabled, ifPatientListEnabled } =
    features || {};
  const pharmacyAvgVal = location?.state && location?.state?.pharmacyAvgVal;
  const [value, setValue] = React.useState(
    ifMapEnabled === true && ifPharmacyOverviewEnabled === true
      ? 2
      : ifMapEnabled === true || ifPharmacyOverviewEnabled === true
      ? 1
      : 0
  );
  const handleChange = (event, newValue) => {
    if (value !== newValue) {
      setValue(newValue);
    }
  };

  function setupNavigations() {
    const navigations = [];

    if (ifMapEnabled === true) {
      navigations.push(`/`);
    }

    if (ifPharmacyOverviewEnabled === true) {
      navigations.push(`/pharmacyoverview/${pharmacyId}`);
    }

    if (ifPatientListEnabled === true) {
      navigations.push(`/pharmacypatient/${pharmacyId}`);
    }

    navigations.push(`/pharmacyservices/${pharmacyId}`);

    return navigations;
  }

  useEffect(() => {
    document.body.className = `${darkMode ? "dark-theme" : "light-theme"}`;
  }, []);

  useEffect(() => {
    const navigations = setupNavigations();
    if (value >= 0 && value < navigations?.length) {
      const toNavigate = navigations[value];
      const currentPath = location.pathname;
      toNavigate !== currentPath &&
        navigate(toNavigate, {
          state: {
            onDarkModeChange: darkMode,
            pharmacyAvgVal: pharmacyAvgVal,
          },
        });
    }
  }, [value]);

  const { appData } = useLoaderData() || {};
  appData?.then((data) => {
    handleChange(
      null,
      ifMapEnabled === true && ifPharmacyOverviewEnabled === true
        ? 2
        : ifMapEnabled === true || ifPharmacyOverviewEnabled === true
        ? 1
        : 0
    );
    return data;
  });

  const handleSettingsClick = () => {
    navigate(`/settings/general`);
  };

  return (
    <AsyncRendererRouter
      isLoading={isTabLoading}
      resolve={appData}
      loadingElement={<LoadingComponent />}
    >
      <div className="pharmacy_header_modal">
        <div className="modal-content map-blur">
          <div className="tabs_box top-tabs">
            <PharmacyTabs
              value={value}
              handleChange={handleChange}
              getServicesCount={getServicesCount}
            />
            <PharmacyHeaderDetails />
            {screenWidth > 600 && (
              <Header
                onDarkModeChange={darkMode}
                handleSettingsClick={handleSettingsClick}
                isPharmacyMergeEnabled={false}
                isToggleBottombarEnabled={false}
                isSettingsEnabled={true}
                isChatEnabled={true}
                isListViewEnabled={false}
              />
            )}
          </div>

          <div
            className={`pharmacy-details-area pharmacy-patient-wrapper ${
              darkMode === false ? "" : "map-blur-black"
            }`}
          >
            {isAppLoading === false &&
            isValidOrg &&
            currentPharmacy &&
            pharmacyId === currentPharmacyId ? (
              <PatientTab />
            ) : isAppLoading ? (
              <div className="patient_tab">
                <div className="patient_tab_patient_list">
                  <div className="patient_list_no_data_found_message">
                    <CustomLoader />
                  </div>
                </div>
              </div>
            ) : (
              <div className="overview_tab">
                <div className="overview_col-1">
                  Pharmacy patients do not exist.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
