import React from "react";

export default function ChecksButton({
  checksCount,
  handleChecksClick,
  pharmacy,
}) {
  return (
    <div
      className="pharmacy_listview-btn"
      onClick={() => handleChecksClick(pharmacy)}
    >
      <div className="count-box">
        <div className="count">
          <p>{checksCount}</p>
        </div>
        <p>Checks</p>
      </div>

      <svg
        width="6"
        height="9"
        viewBox="0 0 6 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.920552 8.73857C0.592156 8.41017 0.592156 7.87774 0.920552 7.54934L3.96988 4.50001L0.920552 1.45069C0.592156 1.12229 0.592156 0.589858 0.920552 0.261463C1.24895 -0.0669327 1.78138 -0.0669327 2.10978 0.261463L5.75372 3.9054C6.08211 4.2338 6.08211 4.76623 5.75372 5.09463L2.10978 8.73857C1.78138 9.06696 1.24895 9.06696 0.920552 8.73857Z"
          fill="#999999"
        />
      </svg>
    </div>
  );
}
