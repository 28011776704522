import { React, useState } from "react";
import "./../../assets/css/BottomActionBar.scss";
import Checks from "./components/Checks";
import NMS from "./components/NMS";
import CustomerExperience from "./components/CustomerExperience";
import ActionIconGroup from "./components/ActionIconGroup/ActionIconGroup";
import Clinical from "./components/FunctionalGroup/Clinical";
import Operations from "./components/FunctionalGroup/Operations";
import Finance from "./components/FunctionalGroup/Finance";
import Efficiency from "./components/FunctionalGroup/Efficiency";
import useTitanApp from "../../hooks/useTitanApp";

export default function BottomActionBar({
  toggleSidebar,
  isSidebarOpen,
  toggleChecks,
  toggleNMS,
  toggleCX,
  isChecks,
  isNMS,
  isCX,
  isClinical,
  isOperations,
  isFinance,
  isEfficiency,
  totalChecksCount,
  totalNMSCount,
  totalPharmaciesCount,
  animationVisible,
  setIsListViewVisible,
  toggleBottombar,
  totalClinicalCount,
  totalOperationsCount,
  totalEfficiencyCount,
  totalFinanceCount,
  bottomBarDivClick,
  onDivClick,
  zIndex,
}) {
  const { appState } = useTitanApp();
  const { darkMode } = appState || {};
  const [activeIcon, setActiveIcon] = useState("");

  const handleMouseEnter = (componentName) => {
    setActiveIcon(componentName);
  };
  const handleMouseLeave = () => {
    setActiveIcon("");
  };

  return (
    <div
      className={`bottomActionBar_container ${
        animationVisible === true ? "hidden" : ""
      }`}
      style={{ zIndex: zIndex }}
    >
      <div className="bottomActionBar_wrapper">
        {toggleBottombar ? (
          <>
            <div className="bottomActionBar_wrapper_left_alt">
              <Clinical
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                active={activeIcon === "Clinical"}
                disabled={isOperations || isFinance || isEfficiency}
                totalClinicalCount={totalClinicalCount}
                onDarkModeChange={darkMode}
                setIsListViewVisible={setIsListViewVisible}
                isActive={bottomBarDivClick.Clinical}
                onClick={() => onDivClick("Clinical")}
              />
              <Operations
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                active={activeIcon === "Operations"}
                disabled={isClinical || isFinance || isEfficiency}
                totalOperationsCount={totalOperationsCount}
                onDarkModeChange={darkMode}
                setIsListViewVisible={setIsListViewVisible}
                isActive={bottomBarDivClick.Operations}
                onClick={() => onDivClick("Operations")}
              />
              <Finance
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                active={activeIcon === "Finance"}
                disabled={isOperations || isClinical || isEfficiency}
                totalFinanceCount={totalFinanceCount}
                onDarkModeChange={darkMode}
                setIsListViewVisible={setIsListViewVisible}
                isActive={bottomBarDivClick.Finance}
                onClick={() => onDivClick("Finance")}
              />
              <Efficiency
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                active={activeIcon === "Efficiency"}
                disabled={isOperations || isFinance || isClinical}
                totalEfficiencyCount={totalEfficiencyCount}
                onDarkModeChange={darkMode}
                setIsListViewVisible={setIsListViewVisible}
                isActive={bottomBarDivClick.Efficiency}
                onClick={() => onDivClick("Efficiency")}
              />
            </div>
          </>
        ) : (
          <>
            <div className="bottomActionBar_wrapper_left">
              <Checks
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                active={activeIcon === "Checks"}
                toggleChecks={toggleChecks}
                disabled={isNMS || isCX}
                totalChecksCount={totalChecksCount}
                onDarkModeChange={darkMode}
                setIsListViewVisible={setIsListViewVisible}
              />
              <NMS
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                active={activeIcon === "NMS"}
                toggleNMS={toggleNMS}
                disabled={isChecks || isCX}
                totalNMSCount={totalNMSCount}
                onDarkModeChange={darkMode}
                setIsListViewVisible={setIsListViewVisible}
              />
              <CustomerExperience
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                active={activeIcon === "CustomerExperience"}
                toggleCX={toggleCX}
                disabled={isNMS || isChecks}
                totalPharmaciesCount={totalPharmaciesCount}
                onDarkModeChange={darkMode}
                setIsListViewVisible={setIsListViewVisible}
              />
            </div>
            <div className="bottomActionBar_wrapper_right">
              <ActionIconGroup
                toggleSidebar={toggleSidebar}
                isSidebarOpen={isSidebarOpen}
                onDarkModeChange={darkMode}
                setIsListViewVisible={setIsListViewVisible}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
