import React from "react";
import "../../assets/nopatientpopup.css";
import { ImWarning } from "react-icons/im";
function VerificationEmail() {
  return (
    <div className="email_verification_wrapper">
      <div className="text">
        <ImWarning size={50} color={"red"} />
        <p>Please verify your email to continue in Titan Verse</p>
      </div>
    </div>
  );
}

export default VerificationEmail;
