import templateSections from "./templateSections";
class metaDataList {
  constructor(response) {
    this.obj = response?.map((value) => {
      return {
        name: value.name,
        templateSections: value.templateSections
          ? new templateSections(value.templateSections).obj
          : [],
      };
    });
  }
}

export default metaDataList;
