import React, { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "./../../assets/css/Settings/SettingsSidebar.scss";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { GoArrowLeft } from "react-icons/go";
import {
  TbBulb,
  TbFileDots,
  TbUserStar,
  TbCalendarDollar,
  TbUserQuestion,
  TbPassword,
} from "react-icons/tb";
import { FaAlignJustify, FaTimes } from "react-icons/fa";
import useTitanApp from "../../hooks/useTitanApp";

export default function OrbitSettingsSidebar() {
  const { appState } = useTitanApp();
  const { features, currentPharmacyId, isOrbitUser } = appState || {};
  const {
    ifSettingMinimal,
    ifMapEnabled,
    ifPharmacyOverviewEnabled,
    ifPatientListEnabled,
  } = features || {};

  const location = useLocation();

  const isMenuItemActive = (link) => {
    return location.pathname === link || location.pathname.startsWith(link);
  };

  const navigate = useNavigate();

  const [showResponsiveSidebar, setShowResponsiveSidebar] = useState(false);

  const handleShowSidebar = () => {
    if (window.innerWidth < 800) {
      setShowResponsiveSidebar(!showResponsiveSidebar);
    }
  };
  const handleGoBack = () => {
    if (isOrbitUser) {
      navigate(`/orbit`);
    } else {
      navigate(`/`);
    }
  };
  return (
    <div>
      <div
        className={`sidebarSettings_wrapper ${showResponsiveSidebar ? "show-sidebar" : ""
          }`}
      >
        <div className="settings_breadcrumb" onClick={handleGoBack}>
          <GoArrowLeft className="icons-color" />

          <p className="breadCrumb_title">Back to Dashboard</p>
        </div>
        <Sidebar className="settings_sidebar">
          <Menu>
            <MenuItem
              component={<Link to="/orbitsettings/professionaldetails"></Link>}
              className={`no-submenu ${isMenuItemActive("/orbitsettings/professionaldetails")
                ? "settings-sidebar-selected"
                : ""
                }`}
              icon={<TbBulb size={20} />}
            >
              Professional Details
            </MenuItem>
            <MenuItem
              component={<Link to="/orbitsettings/compliancedocuments"></Link>}
              className={`no-submenu ${isMenuItemActive("/orbitsettings/compliancedocuments")
                ? "settings-sidebar-selected"
                : ""
                }`}
              icon={<TbFileDots size={20} />}
            >
              Compliance Documents
            </MenuItem>
            <MenuItem
              component={<Link to="/orbitsettings/accreditationsskills"></Link>}
              className={`no-submenu ${isMenuItemActive("/settings/locations")
                ? "settings-sidebar-selected"
                : ""
                }`}
              icon={<TbUserStar size={20} />}
            >
              Accreditations and Skills
            </MenuItem>
            <MenuItem
              component={<Link to="/orbitsettings/availability"></Link>}
              className={`no-submenu ${isMenuItemActive("/orbitsettings/availability")
                ? "settings-sidebar-selected"
                : ""
                }`}
              icon={<TbUserQuestion size={20} />}
            >
              Availability
            </MenuItem>
            <MenuItem
              component={<Link to="/orbitsettings/paymentdetails"></Link>}
              className={`no-submenu ${isMenuItemActive("/orbitsettings/paymentdetails")
                ? "settings-sidebar-selected"
                : ""
                }`}
              icon={<TbUserQuestion size={20} />}
            >
              Payment Details
            </MenuItem>
            <MenuItem
              component={<Link to="/orbitsettings/changepassword"></Link>}
              className={`no-submenu ${isMenuItemActive("/orbitsettings/changepassword")
                ? "settings-sidebar-selected"
                : ""
                }`}
              icon={<TbPassword size={20} />}
            >
              Change Password
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
      <div className="sidebar-hamburger" onClick={handleShowSidebar}>
        {showResponsiveSidebar ? <FaTimes /> : <FaAlignJustify />}
      </div>
    </div>
  );
}
