import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpGet, httpPost, httpPut } from "../../services/httpService";
import {
  getServicesUrl,
  saveServiceUrl,
  updateServiceUrl,
  getServiceTypeByIdUrl,
} from "../../apiUrls/apiUrls";
import { logError } from "../../services/logService";
const initialState = {
  isTypeLoading: false,
  servicesTypeData: [],
  currentServiceType: null,
  currentServiceModel: null,
  currentClinicalServiceBuilder: null,
  currentStages: [],
  currentPharmacyIds: [],
};
export const addServiceData = createAsyncThunk(
  "type/addServiceData",
  async (data, thunkAPI) => {
    const respData = await httpPost(saveServiceUrl(), data);
    return respData;
  }
);
export const getServicesData = createAsyncThunk(
  "type/getServicesData",
  async (params, thunkAPI) => {
    const respData = await httpGet(getServicesUrl());
    return respData?.services;
  }
);
export const updateServiceData = createAsyncThunk(
  "type/updateServiceData",
  async (data, thunkAPI) => {
    const respData = await httpPut(updateServiceUrl(), data);
    return respData;
  }
);
export const getServiceTypeByIdData = createAsyncThunk(
  "type/getServiceTypeByIdData",
  async (serviceTypeId, thunkAPI) => {
    const urlToGet = getServiceTypeByIdUrl(serviceTypeId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

const typeSlice = createSlice({
  name: "type",
  initialState,
  reducers: {
    setIsTypeLoading: (state, action) => {
      state.isTypeLoading = action?.payload || false;
    },
    setCurrentTypeData: (state, action) => {
      state.currentServiceType = action?.payload || null;
      state.currentServiceModel =
        state.currentServiceType?.serviceModel || null;
      state.currentClinicalServiceBuilder =
        state.currentServiceType?.clinicalServiceBuilder || null;
      state.currentStages =
        state.currentServiceType?.clinicalServiceBuilder?.stages || [];
      state.currentPharmacyIds = state.currentServiceType?.pharmacyIds || [];
    },
    setCurrentServiceModelData: (state, action) => {
      state.currentServiceModel = action?.payload || null;
      state.currentServiceType.serviceModel = state.currentServiceModel || null;
    },
    setCurrentClinicalServiceBuilderData: (state, action) => {
      state.currentClinicalServiceBuilder = action?.payload || null;
      state.currentServiceType.clinicalServiceBuilder =
        state.currentClinicalServiceBuilder || null;
    },
    setCurrentStagesData: (state, action) => {
      state.currentStages = action?.payload || [];
      state.currentServiceType.clinicalServiceBuilder.stages =
        state.currentStages || [];
    },
    setCurrentPharmacyIdsData: (state, action) => {
      state.currentPharmacyIds = action?.payload || [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServicesData.pending, (state) => {
        state.isTypeLoading = true;
        state.servicesTypeData = [];
        state.currentServiceType = null;
        state.currentServiceModel = null;
        state.currentClinicalServiceBuilder = null;
        state.currentStages = null;
        state.currentPharmacyIds = [];
      })
      .addCase(getServicesData.fulfilled, (state, action) => {
        state.isTypeLoading = false;
        state.servicesTypeData = action?.payload;
      })
      .addCase(getServicesData.rejected, (state, action) => {
        logError(action?.error);
        state.isTypeLoading = false;
        state.servicesTypeData = [];
        state.currentServiceType = null;
        state.currentServiceModel = null;
        state.currentClinicalServiceBuilder = null;
        state.currentStages = null;
        state.currentPharmacyIds = [];
      })
      .addCase(addServiceData.pending, (state, action) => {
        state.isTypeLoading = true;
      })
      .addCase(addServiceData.fulfilled, (state, action) => {
        state.isTypeLoading = false;
        state.currentServiceType = action?.payload;
        state.currentServiceModel =
          state.currentServiceType?.serviceModel || null;
        state.currentClinicalServiceBuilder =
          state.currentServiceType?.clinicalServiceBuilder || null;
        state.currentStages = state.currentClinicalServiceBuilder?.stages || [];
        state.currentPharmacyIds = state.currentServiceType?.pharmacyIds || [];
      })
      .addCase(addServiceData.rejected, (state, action) => {
        logError(action?.error);
        state.isTypeLoading = false;
      })
      .addCase(updateServiceData.pending, (state) => {
        state.isTypeLoading = true;
      })
      .addCase(updateServiceData.fulfilled, (state, action) => {
        state.isTypeLoading = false;
        state.currentServiceType = action?.payload;
        state.currentServiceModel =
          state.currentServiceType?.serviceModel || null;
        state.currentClinicalServiceBuilder =
          state.currentServiceType?.clinicalServiceBuilder || null;
        state.currentStages = state.currentClinicalServiceBuilder?.stages || [];
        state.currentPharmacyIds = state.currentServiceType?.pharmacyIds || [];
      })
      .addCase(updateServiceData.rejected, (state, action) => {
        logError(action?.error);
        state.isTypeLoading = false;
      })
      .addCase(getServiceTypeByIdData.pending, (state) => {
        state.isTypeLoading = true;
      })
      .addCase(getServiceTypeByIdData.fulfilled, (state, action) => {
        state.isTypeLoading = false;
        state.currentServiceType = action?.payload || null;
        state.currentServiceModel =
          state.currentServiceType?.serviceModel || null;
        state.currentClinicalServiceBuilder =
          state.currentServiceType?.clinicalServiceBuilder || null;
        state.currentStages = state.currentClinicalServiceBuilder?.stages || [];
        state.currentPharmacyIds = state.currentServiceType?.pharmacyIds || [];
      })
      .addCase(getServiceTypeByIdData.rejected, (state, action) => {
        logError(action?.error);
        state.isTypeLoading = false;
        state.currentServiceType = null;
        state.currentServiceModel = null;
        state.currentClinicalServiceBuilder = null;
        state.currentStages = null;
        state.currentPharmacyIds = [];
      });
  },
});
export const {
  setIsTypeLoading,
  setCurrentTypeData,
  setCurrentServiceModelData,
  setCurrentClinicalServiceBuilderData,
  setCurrentStagesData,
  setCurrentPharmacyIdsData,
} = typeSlice.actions;
export default typeSlice.reducer;
