import React from "react";
import { FaChevronRight } from "react-icons/fa";

export default function AddNewButtons(props) {
  return (
    <button type="submit" className="addNew-button" onClick={props.onClick}>
      <div>{props.text}</div>
      <div className="addNew-button-icon">
        <FaChevronRight />
      </div>
    </button>
  );
}
