import React, { useState } from "react";
import { Map, Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import useTitanApp from "../../hooks/useTitanApp";
import InviteModal from "../../pages/UserOnboarding/components/InviteModal";

export default function InviteMembers({ root, showModal, check, closeModal }) {
  const { appState } = useTitanApp();
  const { darkMode } = appState || {};

  const viewport = {
    latitude: 52.4862,
    longitude: -1.8904,
    zoom: 7,
  };
  return (
    <div className="inviteMembers_wrapper">
      <div className="inviteMembers_mapbox">
        <Map
          initialViewState={viewport}
          scrollZoom={true}
          mapStyle={
            darkMode
              ? "mapbox://styles/mapbox/dark-v11"
              : "mapbox://styles/mapbox/light-v11"
          }
          style={{
            width: "100vw",
            height: "100vh",
          }}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          attributionControl={false}
        ></Map>
      </div>

      <InviteModal
        check={check}
        showModal={showModal}
        closeModal={closeModal}
        root={root}
      />
    </div>
  );
}
