import { queryByAltText } from "@testing-library/react";

class PrecriptionItemNotes {
  constructor(response, Type, PrescriptionItemNoteId, TypeValue) {
    this.obj = response?.map((value) => {
      return {
        ProfessionalName: "Titan Admin",
        PrescriptionItemNoteId: value.PrescriptionItemNoteId,
        PrescriptionItemId: value.PrescriptionItemId,
        DispenseItemId: value.DispenseItemId,
        Note: value.Note,
        ArchiveDate: value.ArchiveDate,

        AwaitingQuery:
          Type === "AwaitingQuery"
            ? value.PrescriptionItemNoteId === PrescriptionItemNoteId
              ? TypeValue
              : value.AwaitingQuery
            : value.AwaitingQuery,
        PinNote:
          Type === "PinNote"
            ? value.PrescriptionItemNoteId === PrescriptionItemNoteId
              ? TypeValue
              : value.PinNote
            : value.PinNote,
        ShowOnPicking:
          Type === "ShowOnPicking"
            ? value.PrescriptionItemNoteId === PrescriptionItemNoteId
              ? TypeValue
              : value.ShowOnPicking
            : value.ShowOnPicking,
        ShowOnLabelling:
          Type === "ShowOnLabelling"
            ? value.PrescriptionItemNoteId === PrescriptionItemNoteId
              ? TypeValue
              : value.ShowOnLabelling
            : value.ShowOnLabelling,
        ShowOnHandout:
          Type === "ShowOnHandout"
            ? value.PrescriptionItemNoteId === PrescriptionItemNoteId
              ? TypeValue
              : value.ShowOnHandout
            : value.ShowOnHandout,
        NeedCheck:
          Type === "NeedCheck"
            ? value.PrescriptionItemNoteId === PrescriptionItemNoteId
              ? TypeValue
              : value.NeedCheck
            : value.NeedCheck,
        CreatedOn: value.CreatedOn,
      };
    });
  }
}

export default PrecriptionItemNotes;
