class setKpiValues {
  constructor(response, serviceCounts) {
    this.obj = response?.map((value) => {
      let checksCount = 0;
      let NMSCount = 0;
      let CXColor = "";

      // CustomerExperience = 2;
      // Clinical = 3;
      // Efficiency = 4;
      // Operations = 5;
      // Finance = 6;

      const matchingServiceCount = serviceCounts.find(
        (service) => service.pharmacyId === value.teamPharmacyID
      );
      if (matchingServiceCount) {
        NMSCount = matchingServiceCount.serviceCount;
      }

      return {
        pharmacyName: value.teamPharmacyName,
        checksCount,
        NMSCount,
        CXColor,
      };
    });
  }
}
export default setKpiValues;
