import { Skeleton } from "@mui/material";
import React from "react";
import useTitanApp from "../../hooks/useTitanApp";
import SettingsSidebar from "../SettingsSidebar/SettingsSidebar";

export default function LocationsAddNewLoadingComponent() {
  const { appState } = useTitanApp();
  const { darkMode } = appState;
  return (
    <div className="settings_page">
      <div className="settings_wrapper">
        <SettingsSidebar onDarkModeChange={darkMode} />
        <div className="settings_content_wrapper">
          <div className="back_to_SettingsListView">
            <Skeleton
              sx={{
                bgcolor: darkMode ? "grey.800" : "",
              }}
              height={60}
              width={100}
              animation="wave"
            />
          </div>
          <div className="locations_addNew_wrapper">
            <div className="locations_general_info AddNew_sectionbox">
              <div>
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={60}
                  width={100}
                  animation="wave"
                />
              </div>
              <div className="width-100">
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={120}
                  animation="wave"
                />
              </div>
            </div>
            <div className="locations_select_services AddNew_sectionbox">
              <div>
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={60}
                  width={100}
                  animation="wave"
                />
              </div>
              <div className="width-100">
                <Skeleton
                  sx={{
                    bgcolor: darkMode ? "grey.800" : "",
                  }}
                  height={200}
                  animation="wave"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
