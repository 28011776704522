import { getObject } from "../getObjectValue";
class dispensingWindow {
  constructor(response) {
    this.obj = {
      isInsideDispensingWindow: response.isInsideDispensingWindow
        ? getObject(response, "isInsideDispensingWindow")
        : false,
      windowStart: response.windowStart
        ? getObject(response, "windowStart")
        : "",
      windowEnd: response.windowEnd ? getObject(response, "windowEnd") : "",
    };
  }
}

export default dispensingWindow;
