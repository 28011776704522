import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import WaveSurfer from "wavesurfer.js";
import styled from "styled-components";
import { FaPlayCircle, FaPauseCircle } from "react-icons/fa";
import config from "../../../config";
import axios from "axios";

const Waveform = ({ element, darkMode }) => {
  const { localurl } = config;

  let audio;
  const containerRef = useRef();
  const waveSurferRef = useRef({
    isPlaying: () => false,
  });
  const [isPlaying, toggleIsPlaying] = useState(false);

  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      container: containerRef.current,
      responsive: true,
      barWidth: 1,
      barHeight: 1,
      cursorWidth: 0,
      height: 80,
    });
    try {
      const authToken = localStorage.getItem("authToken");

      axios
        ?.get(`${localurl}/Voice/get-recording?conversationID=${element}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "audio/mpeg",
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          if (response !== null) {
            const audioBlob = new Blob([response?.data], {
              type: "audio/mpeg",
            });
            const audioUrl = URL.createObjectURL(audioBlob);
            audio = response?.data;
            waveSurfer.load(audioUrl);
            waveSurfer.on("ready", () => {
              waveSurferRef.current = waveSurfer;
            });

            return () => {
              waveSurfer.destroy();
            };
          }
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    } catch {}
  }, [audio]);

  return (
    <div className="wavesurfer-wrapper phone_child">
      <div className="header">
        <button
          onClick={() => {
            toggleIsPlaying(waveSurferRef.current.isPlaying());
            waveSurferRef.current.playPause();
          }}
          type="button"
        >
          {isPlaying ? (
            <FaPauseCircle size={30} color={darkMode ? "white" : "#0a0a0a"} />
          ) : (
            <FaPlayCircle size={30} color={darkMode ? "white" : "#0a0a0a"} />
          )}
        </button>
        <p>Call Recording</p>
      </div>

      <div className="wave" ref={containerRef} />
    </div>
  );
};

export default Waveform;
