import React from "react";
import ServicesThisWeek from "./ServicesThisWeek";
import ServicesTasks from "./ServicesTasks";

export default function ServicesAndTasks({
  tabsServices,
  handleTabClick,
  activeTab,
  onDarkModeChange,
}) {
  return (
    <React.Fragment>
      <div className="week-tabs">
        <h4>This week</h4>
        <br></br>
        <div>
          <div className="tab-container">
            <div
              className={`tab ${activeTab === "M" ? "active" : ""}`}
              onClick={() => handleTabClick("M")}
            >
              M
            </div>
            <div
              className={`tab ${activeTab === "T" ? "active" : ""}`}
              onClick={() => handleTabClick("T")}
            >
              T
            </div>
            <div
              className={`tab ${activeTab === "W" ? "active" : ""}`}
              onClick={() => handleTabClick("W")}
            >
              W
            </div>
            <div
              className={`tab ${activeTab === "R" ? "active" : ""}`}
              onClick={() => handleTabClick("R")}
            >
              T
            </div>
            <div
              className={`tab ${activeTab === "F" ? "active" : ""}`}
              onClick={() => handleTabClick("F")}
            >
              F
            </div>
            <div
              className={`tab ${activeTab === "A" ? "active" : ""}`}
              onClick={() => handleTabClick("A")}
            >
              S
            </div>
            <div
              className={`tab ${activeTab === "U" ? "active" : ""}`}
              onClick={() => handleTabClick("U")}
            >
              S
            </div>
          </div>

          <div className="content">
            <ServicesThisWeek
              tabsServices={tabsServices}
              activeTab={activeTab}
              onDarkModeChange={onDarkModeChange}
            />
            <ServicesTasks
              tabsServices={tabsServices}
              activeTab={activeTab}
              onDarkModeChange={onDarkModeChange}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
