import React from "react";
import CustomButton from "../../controls/CustomButton";
import useTitanApp from "../../../hooks/useTitanApp";
import { updateIsVerifyByUserEmail } from "../../../dispatchers/onboardingVerificationDsipatchers";
import { useNavigate } from "react-router-dom";
import { showError } from "../../ToastedMessage/ToastedMessage";
import { initApp } from "../../../index";
export default function OnboardingTermsConditions({ root }) {
  let navigate = useNavigate();
  const { onboardingVerificationState, auth, getAuthToken } = useTitanApp();
  const { userData } = onboardingVerificationState || {};
  const { user } = auth || {};
  const userEmail = user?.email;
  const handleFinishSteps = async () => {
    try {
      var data = await updateIsVerifyByUserEmail(userEmail);
      if (data != null) {
        if (data?.isVerified === true && data?.accountType === 2) {
          setTimeout(
            (initApp) => {
              initApp();
            },
            100,
            initApp
          );
          root.unmount();
        }
        navigate("/");
      } else {
        showError("User Details could not be updated.", true, false);
      }
    } catch (errors) {
      showError("User Details could not be updated.", true, false);
    }
  };
  return (
    <div className="terms">
      <div>
        <h1>Terms</h1>
        <h5>1. Our Service</h5>
        <p>
          The information provided when using the Services is not intended for
          distribution to or use by any person or entity in any jurisdiction or
          country where such distribution or use would be contrary to law or
          regulation or which would subject us to any registration requirement
          within such jurisdiction or country. Accordingly, those persons who
          choose to access the Services from other locations do so on their own
          initiative and are solely responsible for compliance with local laws,
          if and to the extent local laws are applicable.
        </p>
        <h5>2. Intellectual Property</h5>
        <h6>Our intellectual property</h6>
        <p>
          We are the owner or the licensee of all intellectual property rights
          in our Services, including all source code, databases, functionality,
          software, website designs, audio, video, text, photographs, and
          graphics in the Services (collectively, the 'Content'), as well as the
          trademarks, service marks, and logos contained therein (the 'Marks'").
          Our Content and Marks are protected by copyright and trademark laws
          (and various other intellectual property rights and unfair competition
          laws) and treaties in the United States and around the world. The
          Content and Marks are provided in or through the Services 'AS IS' for
          your personal, non-commercial use or internal business purpose only.
        </p>
        <h6>Your use of our services</h6>
        <p>
          Subject to your compliance with these Legal Terms, including the
          'PROHIBITED ACTIVITIES' section below, we grant you a non-exclusive,
          non-transferable, revocable licence to: access the Services; and
          download or print a copy of any portion of the Content to which you
          have properly gained access. solelv for vour personal, non-commercial
          use or internal business purpose. Except as set out in this section or
          elsewhere in our Legal Terms, no part of the Services and no Content
          or Marks may be copied, reproduced, aggregated, republished, uploaded,
          posted, publicly displayed, encoded, translated, transmitted,
          distributed, sold, licensed, or otherwise exploited for any commercial
          purpose whatsoever, without our express prior written permission.
        </p>
        <h6>Our intellectual property</h6>
        <p>
          We are the owner or the licensee of all intellectual property rights
          in our Services, including all source code, databases, functionality,
          software, website designs, audio, video, text, photographs, and
          graphics in the Services (collectively, the 'Content'), as well as the
          trademarks, service marks, and logos contained therein (the 'Marks'").
          Our Content and Marks are protected by copyright and trademark laws
          (and various other intellectual property rights and unfair competition
          laws) and treaties in the United States and around the world. The
          Content and Marks are provided in or through the Services 'AS IS' for
          your personal, non-commercial use or internal business purpose only.
        </p>
        <div className="otc_footer">
          <CustomButton label="Finish" action={handleFinishSteps} />
        </div>
      </div>
    </div>
  );
}
