import React, { useState } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Switch from "@mui/material/Switch";
import AccordionSummary from "@mui/material/AccordionSummary";
import useTitanApp from "../../../hooks/useTitanApp";
import moment from "moment";

export default function NMSFollowupTemplate({ element, isExpanded }) {
  const [expanded, setExpanded] = useState(false);
  const handleChange = () => {
    setExpanded(!expanded);
  };

  const { appState } = useTitanApp();
  const { darkMode } = appState || {};
  const formatDate = (dateString) => {
    let date = "";
    if (dateString !== "") {
      let momentdate = moment(dateString);
      date = momentdate?.format("DD/MM/yyyy hh:mm a");
    }
    return date;
  };
  return (
    <div className="followup_container">
      <Accordion
        expanded={expanded}
        onChange={() => handleChange()}
        className="nms_info_accordion"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content"
          id="panel-header"
          className="heading"
        >
          <div className="followup_header">
            <IoIosCheckmarkCircle color="#00CC6A" size={20} />
            <p>{element?.name}</p>
          </div>
        </AccordionSummary>

        <AccordionDetails className="accordion_content">
          <div className="list_box">
            <p>{`${element?.title} - ${formatDate(element?.value)}`}</p>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
