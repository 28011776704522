export const ethnicityTypes = {
  0: "Not Specified",

  1: "White - British",

  2: "White - Irish",

  3: "White - Any other White background",

  4: "Mixed - White and Black Caribbean",

  5: "Mixed - White and Black African",

  6: "Mixed - White and Asian",

  7: "Mixed - Any other mixed background",
  8: "Asian or Asian British - Indian",

  9: "Asian or Asian British - Pakistani",

  10: "Asian or Asian British - Bangladeshi",

  11: "Asian or Asian British - Any other Asian background",

  12: "Black or Black British - Caribbean",

  13: "Black or Black British - African",

  14: "Black or Black British - Any other Black background",

  15: "Other Ethnic Groups - Chinese",
  16: "Any other ethnic group",

  17: "Not stated",

  18: "Not known",
};
