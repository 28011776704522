import React from "react";
import { ReactComponent as AddNewSvg } from "./../../../PatientDashboardIcons/addNew.svg";
import { ReactComponent as UserSvg } from "./../../../PatientDashboardIcons/user.svg";
import { ReactComponent as MenuSvg } from "./../../../../../pages/Dashboard/OverviewTab/OverviewIcons/menu.svg";
import { ReactComponent as CalenderSvg } from "./../../../../../pages/Dashboard/OverviewTab/OverviewIcons/calendar.svg";

export default function FamilyHistory() {
  return (
    <div className="card-shaped-box">
      <div className="tasks_header summary_header">
        <h3 className="pharmacy_header_name">Family History</h3>
        <AddNewSvg />
      </div>
      <div className="card-shaped-content">
        <p className="description">Noteworthy family health history</p>
        <div className="inner_container">
          <div className="title family_title">
            <UserSvg />
            <p>Mother</p>
          </div>
          <div className="input_wrapper">
            <div className="top">
              <div>
                <p className="label">Name</p>
                <p className="text">Jane Aubree</p>
              </div>
              <div>
                <p className="label">DOB</p>
                <p className="text">13/08/1964 (59)</p>
              </div>
            </div>
            <div className="bottom">
              <div>
                <p className="label">Condition(s)</p>
                <p className="text">Hypertension, myocardial infarction</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
