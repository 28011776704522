export const preparationArray = [
  {
    label: "Not Specified",
    value: 0,
  },
  {
    label: "Standard",
    value: 1,
  },
  {
    label: "Special Order",
    value: 2,
  },
  {
    label: "Supplement Drink",
    value: 3,
  },
  {
    label: "Controlled Drug",
    value: 4,
  },
  {
    label: "Fridge Line",
    value: 5,
  },
  {
    label: "Cytotoxic",
    value: 6,
  },
];
