import React from "react";
import OrbitRxRate from "./components/OrbitRxRate.js";
import OrbitLeaderboardRank from "./components/OrbitLeaderboardRank.js";
import OrbitTodayEarning from "./components/OrbitTodayEarning.js";
import OrbitMonthlyReport from "./components/OrbitMonthlyReport.js";
import OrbitEarnings from "./components/OrbitEarnings.js";
import OrbitEstimatedEarnings from "./components/OrbitEstimatedEarnings.js";
import OrbitSessions from "./components/OrbitSessions.js";
import OrbitActiveServices from "./components/OrbitActiveServices.js";
import OrbitMissingBankInfo from "./components/OrbitMissingBankInfo.js";
import OrbitEarningPotential from "./components/OrbitEarningPotential.js";
import useTitanApp from "../../../../hooks/useTitanApp.js";

export default function OrbitGrid() {
  const { orbitState } = useTitanApp();
  const { bankPaymentDetails } = orbitState;
  return (
    <div className="orbit_grid_wrapper">
      <div className="orbit_grid_new orbit_grid_top_panel">
        {bankPaymentDetails ? null : <OrbitMissingBankInfo />}
        <OrbitRxRate />
        <OrbitLeaderboardRank />
        <OrbitTodayEarning />
      </div>
      <div className="orbit_grid_new">
        <div className="orbit_grid_col">
          <OrbitMonthlyReport />
          <OrbitEarningPotential />
        </div>
        <OrbitEarnings />
        <OrbitActiveServices />
      </div>
    </div>
  );
}
