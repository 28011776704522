import React from "react";

import { ReactComponent as TickSvg } from "./../OverviewIcons/tick.svg";
import { ReactComponent as PharmacistSvg } from "./../OverviewIcons/pharmacist.svg";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import { RxCaretRight } from "react-icons/rx";
export default function ServicesStats({
  dataSetOne,
  dataSetTwo,
  onDarkModeChange,
}) {
  return (
    <React.Fragment>
      <div className="two-col-wrapper">
        <div className="wrapper-alt clinical-checks">
          <div className="heading">
            <TickSvg />
            <h5>Clinical Checks</h5>
          </div>
          <div className="content">
            <p>
              You averaged 234 clinical checks per day this week. 3.4% higher
              than last week. Keep going!
            </p>
          </div>
          <ComposedChart
            width={500}
            height={400}
            data={dataSetOne}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" scale="band" />

            <Tooltip />
            <Legend />
            <Bar dataKey="uv" barSize={20} fill="#aaa" />
            <Line type="monotone" dataKey="uv" stroke="#000" />
          </ComposedChart>
          <div className="single-set-actions">
            <div className="action">
              <div className="heading">
                <p className="count">23</p>
                <p>
                  You have 23 clinical checks to process today. Start processing
                  now!
                </p>
              </div>
              <RxCaretRight size={28} color="#999" />
            </div>
          </div>
        </div>

        <div className="wrapper-alt pharmacists">
          <div className="heading">
            <PharmacistSvg />
            <h5>Pharmacists</h5>
          </div>
          <div className="content">
            <p>
              This week, on average, you processed 223 prescriptions per day
              with 3 active pharmacists. Down 1.3% compared to last week
            </p>
          </div>
          <ComposedChart
            width={500}
            height={400}
            data={dataSetTwo}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" scale="band" />

            <Tooltip />
            <Legend />
            <Bar dataKey="uv" barSize={20} fill="#aaa" />
            <Line type="monotone" dataKey="uv" stroke="#000" />
          </ComposedChart>
          <div className="double-set-actions">
            <div className="action">
              <div className="heading">
                <p className="count">3</p>
                <p>Active pharmacists</p>
              </div>
              <RxCaretRight size={25} color="#999" />
            </div>
            <div className="action">
              <div className="heading">
                <p className="count count-alt">87</p>
                <p>Prescriptions per day</p>
              </div>
              <RxCaretRight size={25} color="#999" />
            </div>
          </div>
        </div>
        <div className="wrapper-alt packagers">
          <div className="heading">
            <TickSvg />
            <h5>Packagers</h5>
          </div>
          <div className="content">
            <p>
              You averaged 234 clinical checks per day this week. 3.4% higher
              than last week. Keep going!
            </p>
          </div>
          <ComposedChart
            width={500}
            height={400}
            data={dataSetTwo}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" scale="band" />

            <Tooltip />
            <Legend />
            <Bar dataKey="uv" barSize={20} fill="#aaa" />
            <Line type="monotone" dataKey="uv" stroke="#000" />
          </ComposedChart>
          <div className="double-set-actions">
            <div className="action">
              <div className="heading">
                <p className="count">3</p>
                <p>Active pharmacists</p>
              </div>
              <RxCaretRight size={25} color="#999" />
            </div>
            <div className="action">
              <div className="heading">
                <p className="count count-alt">87</p>
                <p>Prescriptions per day</p>
              </div>
              <RxCaretRight size={25} color="#999" />
            </div>
          </div>
        </div>

        <div className="wrapper-alt labellers">
          <div className="heading">
            <PharmacistSvg color="red" />
            <h5>Labellers</h5>
          </div>
          <div className="content">
            <p>
              This week, on average, you processed 223 prescriptions per day
              with 3 active pharmacists. Down 1.3% compared to last week
            </p>
          </div>
          <ComposedChart
            width={500}
            height={400}
            data={dataSetOne}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" scale="band" />

            <Tooltip />
            <Legend />
            <Bar dataKey="uv" barSize={20} fill="#aaa" />
            <Line type="monotone" dataKey="uv" stroke="#000" />
          </ComposedChart>
          <div className="double-set-actions">
            <div className="action">
              <div className="heading">
                <p className="count">3</p>
                <p>Active pharmacists</p>
              </div>
              <RxCaretRight size={25} color="#999" />
            </div>
            <div className="action">
              <div className="heading">
                <p className="count count-alt">87</p>
                <p>Prescriptions per day</p>
              </div>
              <RxCaretRight size={25} color="#999" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
