class setAddTimelineItem {
  constructor(userEmail, userFullName, elements, event, clinicalServiceId) {
    this.createdOn = new Date().toISOString();
    this.createdByEmailAddress = userEmail;
    this.createdByName = userFullName;
    this.elements = elements;
    this.event = event;
    this.clinicalServiceID = clinicalServiceId;
  }
}

export default setAddTimelineItem;
