import React, { useEffect } from "react";
import orbitUserImage from "./../../../assets/images/orbit_user.png";
import { PiPlayBold, PiStopBold } from "react-icons/pi";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import Sparkles from "./../../../assets/Sparkles.json";
import useTitanApp from "../../../hooks/useTitanApp";
import useTimer from "../../../hooks/useTimer";
import { formatTime } from "../../../utils/formatTime";
import {
  setCurrentStart,
  setCurrentStopTimer,
  setCurrentOpenSuccessDialog,
  setCurrentTotalCount,
  setCurrentTotalPrice,
  setCurrentOrbitAnimation,
  setCurrentAnimationPlaying,
  addOrbitSession,
  updateOrbitSession,
  setCurrentTimer,
  setCurrentTimeActive,
} from "./../../../dispatchers/orbitDispatchers";
import setAddSession from "../../../Model/Orbit/setAddSession";
import { showError } from "../../../components/ToastedMessage/ToastedMessage";
import Avatar from "react-initials-avatar";

export default function OrbitUserImage() {
  const { auth, appState, orbitState } = useTitanApp();
  const { darkMode } = appState || {};
  const { user } = auth || {};
  const userFullName = user?.name || "";
  const userEmail = user?.email || "";
  const { startTimeObj, isStopTimer, orbitAnimation } = orbitState || {};
  const { timer, isActive, handleStart, handleReset } = useTimer();

  async function handleAddSession() {
    const startTimeModel = new setAddSession(userFullName, userEmail).obj;

    addOrbitSession(startTimeModel).then((data) => {
      const sessionDataString = JSON.stringify(data);
      localStorage.setItem("sessionData", sessionDataString);
      const session = localStorage.getItem("sessionData");
      return data;
    });
  }

  async function handleUpdateSession() {
    const formattedDateEndDate = new Date().toISOString();
    const session = localStorage.getItem("sessionData");
    const sessionData = JSON.parse(session);
    if (sessionData !== null) {
      const endTimeObj = {
        _id: startTimeObj !== null ? startTimeObj?._id : sessionData?._id,
        userId:
          startTimeObj !== null ? startTimeObj?.userId : sessionData?.userId,
        userFullName:
          startTimeObj !== null
            ? startTimeObj?.userFullName
            : sessionData?.userFullName,
        userEmailAddress:
          startTimeObj !== null
            ? startTimeObj?.userEmailAddress
            : sessionData?.userEmailAddress,
        totalNMS:
          startTimeObj !== null
            ? startTimeObj?.totalNMS
            : sessionData?.totalNMS,
        price: startTimeObj !== null ? startTimeObj?.price : sessionData?.price,
        startTime:
          startTimeObj !== null
            ? startTimeObj?.startTime
            : sessionData?.startTime,
        endTime: formattedDateEndDate,
        actions: [],
        totalSkips: sessionData?.totalSkips ? sessionData?.totalSkips : 0,
      };
      if (endTimeObj) {
        updateOrbitSession(endTimeObj).then((data) => {
          if (data) {
            setCurrentTotalCount(data?.totalProcessed);
            setCurrentTotalPrice(data?.totalEarning);
            const sessionDataString = JSON.stringify(data);
            localStorage.setItem("sessionData", sessionDataString);
            setCurrentOpenSuccessDialog(true);
          }
          return data;
        });
      } else {
        showError("Something went wrong!", false, true);
      }
    }
  }
  useEffect(() => {
    const storedTimerOn = localStorage.getItem("timerOn");
    const storedTime = localStorage.getItem("time");
    const num = Number(storedTime);
    if (storedTimerOn) {
      setCurrentTimeActive(storedTimerOn === "true");
      setCurrentTimer(num);
    }
  }, []);
  return (
    <div className="orbit_user_image">
      {orbitAnimation && (
        <div className="sparklesAnimation">
          <Lottie animationData={Sparkles} />
        </div>
      )}

      <div className={`img_box${darkMode ? "_dark" : ""}`}>
        <Avatar name={userFullName} />
      </div>
    </div>
  );
}
