export const ClinicalCheckIdColor = Object.freeze({
  1: "orange",

  2: "orange",

  3: "orange",

  4: "orange",

  5: "orange",

  6: "orange",

  7: "orange",

  8: "orange",

  9: "orange",

  10: "orange",

  11: "orange",

  12: "orange",

  13: "orange",
  14: "orange",

  15: "orange",

  16: "orange",

  17: "orange",

  18: "orange",

  19: "orange",

  20: "orange",

  21: "orange",

  22: "orange",

  23: "orange",

  24: "orange",

  25: "orange",

  26: "risk", //red

  27: "risk", //red

  28: "orange",

  29: "orange",

  30: "orange",

  31: "orange",

  32: "risk", //red

  33: "risk", //red

  34: "risk", //red

  35: "orange",

  36: "orange",

  37: "orange",

  39: "orange",

  40: "orange",

  41: "orange",

  42: "orange",

  43: "orange",

  44: "orange",

  45: "orange",

  46: "orange",

  47: "orange",

  48: "orange",

  49: "orange",

  50: "orange",

  51: "orange",

  52: "orange",

  53: "orange",

  54: "orange",

  55: "orange",

  56: "orange",

  57: "orange",

  58: "orange",

  59: "orange",

  60: "orange",

  61: "orange",

  62: "orange",

  63: "orange",

  65: "orange",

  1000: "orange",

  1001: "orange",

  1002: "orange",
});
