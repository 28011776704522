import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useLoaderData, useLocation } from "react-router-dom";
import { Map, Marker, NavigationControl } from "react-map-gl";
import Team from "../../components/Team/Team";
import AddTeam from "../../components/Team/AddTeam";
import StartChecks from "../../components/StartChecks/StartChecks";
import StartNMS from "../../components/StartNMS/StartNMS";
import BottomActionBar from "../../components/BottomActionBar/BottomActionBar";
import Header from "../../components/Header/Header";
import { BsChevronRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { responseErrorMessage } from "../../data/constantStrings";
import Supercluster from "supercluster";
import mapboxgl from "mapbox-gl";
import { Source, Layer, LngLat } from "react-map-gl";
import CreateCompaign from "../../components/CreateCompaign/CreateCompaign";
import setKpiValues from "../../Model/KpiValues/setKpiValues";
import CreateReport from "../../components/CreateReport/CreateReport";
import PharmacyDetails from "./PharmacyDetails";
import { motion, AnimatePresence } from "framer-motion";
import PharmacyTooltip from "./../../components/PharmacyTooltip/PharmacyTooltip";

import config from "../../config";
import TeamName from "../../components/Team/TeamName";
import PatientDashboard from "../../components/PatientDashboard/PatientDashboard";
import DashboardListing from "../../components/DashboardListing/DashboardListing";
import useTitanApp from "../../hooks/useTitanApp";
import "mapbox-gl/dist/mapbox-gl.css";
import "./../../assets/css/Dashboard/Dashboard.scss";
import "@sendbird/uikit-react/dist/index.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import {
  showSuccess,
  showError,
  showInfo,
} from "../../components/ToastedMessage/ToastedMessage";
import { logError } from "../../services/logService";
import {
  setChatVisible,
  setCurrentPharmacy,
  setIsTeamUpdated,
} from "../../dispatchers/appDispatchers";
import AuthorizedModal from "../../components/AuthorizedModal/AuthorizedModal";
import AsyncRendererRouter from "../../components/AsyncRendererRouter/AsyncRendererRouter";
import InviteModal from "../UserOnboarding/components/InviteModal";
import InviteMembers from "../../components/InviteMembers/InviteMembers";
import {
  setAddTeamModal,
  setAddTeamTitle,
  setAddTeamImage,
  setTeamFormMode,
  setAddTeamPharmacyName,
} from "../../dispatchers/teamDispatchers";
import useTitanTeam from "../../hooks/useTitanTeam";
import { checkUserPermissions } from "../../utils/checkUserPermissions";
import { accessAddTeams } from "../../data/constantStrings";
import DifferentOrganization from "../../components/DifferentOrganization/DifferentOrganization";

let kpiValues = [];
let totalChecks = 0;
let totalNMS = 0;
let totalPharmacies = 0;
let percentageArr = [];
let isClinicalClick = false,
  isOperationClick = false,
  isFinanceClick = false,
  isEfficiencyClick = false;

export default function Dashboard({ root }) {
  const mapRef = useRef(null);
  const { auth, appState, onboardingVerificationState } = useTitanApp();
  const { userData } = onboardingVerificationState || {};
  const { isActive } = userData || false;
  const {
    features,
    authToken,
    isValidOrg,
    pharmacies,
    serviceCounts,
    teams,
    currentPharmacy,
    isAppLoading,
    darkMode,
    userPermissions,
  } = appState || {};
  const { ifPharmacyOverviewEnabled, ifPatientListEnabled } = features || {};
  const { isAuthenticated } = auth || {};
  const location = useLocation();
  const showModalAgain = location.state && location?.state?.showModal;
  const [serviceType, setserviceType] = useState(
    location.state && location.state.serviceType
  );
  const [isChecks, setIsChecks] = useState(false);
  const [isNMS, setIsNMS] = useState(false);
  const [isCX, setIsCX] = useState(false);
  const [isClinical, setIsClinical] = useState(false);
  const [isEfficiency, setIsEfficiency] = useState(false);
  const [isFinance, setIsFinance] = useState(false);
  const [isOperations, setIsOperations] = useState(false);
  const [pharmacyData, setPharmacyData] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [teamId, setTeamId] = useState("");
  const [headerTeamMembers, setHeaderTeamMembers] = useState("");
  const [coordinatesArray, setCoordinatesArray] = useState([]);
  const [allPatientDataArr, setAllPatientDataArr] = useState([]);
  const [totalChecksCount, setTotalChecksCount] = useState(0);
  const [totalNMSCount, setTotalNMSCount] = useState(0);
  const [totalPharmaciesCount, setTotalPharmaciesCount] = useState(0);
  const [showPatientModal, setShowPatientModal] = useState(false);
  const [showModal, setShowModal] = useState(showModalAgain);
  const [selectedName, setSelectedName] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [patientCount, setPatientCount] = useState("");
  const [isListViewVisible, setIsListViewVisible] = useState(false);
  const [toggleBottombar, setToggleBottomBar] = React.useState(false);
  const [dashboardKPIs, setDashboardKPIs] = React.useState(0);
  const [totalClinicalCount, setTotalClinicalCount] = useState(0);
  const [totalOperationsCount, setTotalOperationsCount] = useState(0);
  const [totalEfficiencyCount, setTotalEfficiencyCount] = useState(0);
  const [totalFinanceCount, setTotalFinanceCount] = useState(0);
  const [foundPharmacyCoordinates, setFoundPharmacyCoordinates] = useState([]);
  const [isPharmacyMergeEnabled, setIsPharmacyMergeEnabled] =
    React.useState(false);
  const [isLine, setIsLine] = useState(false);
  const [bottomActionBarZIndex, setBottomActionBarZIndex] = useState(2);
  const { teamState } = useTitanTeam();
  const { addTeamModal } = teamState || "";
  const [clickedTeamIndex, setClickedTeamIndex] = useState("");

  const teamsValidationSchema = Yup.object().shape({
    teamName: Yup.string().required("Please enter a team name"),
  });
  const teamsFormik = useFormik({
    initialValues: {
      teamName: "",
      displayName: "",
      teamPharmacyName: "",
    },
    validationSchema: teamsValidationSchema,
    onSubmit: (values) => {
      setAddTeamModal(false); // Close the dialog after submission
    },
  });

  const toggleTeamsDialog = (mode, initialValues = {}) => {
    setTeamFormMode(mode);
    if (mode === "add") {
      setAddTeamTitle("Global View");
      setAddTeamImage("");
      setAddTeamPharmacyName("");
      teamsFormik.setValues({
        teamName: "",
        displayName: "",
        teamPharmacyName: "",
      });
    } else {
      if (Object.keys(initialValues).length !== 0) {
        setAddTeamTitle(initialValues.teamName);
        setAddTeamPharmacyName(initialValues.teamPharmacyName);
      }
      teamsFormik.setValues({
        teamName: initialValues.teamName || "",
        displayName: initialValues.displayName || "",
        teamPharmacyName: initialValues.teamPharmacyName || "",
      });
    }
    setAddTeamModal(!addTeamModal);
  };

  //Will need the following code for Filteration of Patients

  // let displayedPatients =
  //   filteredPatients.length > 0 ? filteredPatients : allPatientDataArr;

  const [clusterOpacity, setClusterOpacity] = useState(false);
  const [animationVisible, setAnimationVisible] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isInviteMembersVisible, setInviteMembersVisible] = useState(false);
  const showInviteMembers = () => {
    setInviteMembersVisible(true);
  };

  const closeInviteMembers = () => {
    setInviteMembersVisible(false);
  };
  const handlePharmacyClick = (pharmacy) => {
    if (pharmacy) {
      setCurrentPharmacy(pharmacy);
      const { teamPharmacyID } = pharmacy || {};
      if (ifPharmacyOverviewEnabled === true) {
        navigate(`/pharmacyoverview/${teamPharmacyID}`, {
          state: {
            moveFromDashboard: true,
          },
        });
      } else if (ifPatientListEnabled === true) {
        navigate(`/pharmacypatient/${teamPharmacyID}`, {
          state: {
            moveFromDashboard: true,
          },
        });
      } else {
        navigate(`/pharmacyservices/${teamPharmacyID}`, {
          state: {
            moveFromDashboard: true,
          },
        });
      }
    }
  };

  const closeModal = () => {
    setTimeout(() => {
      setAnimationVisible(false);
    }, 600);
    setShowModal(false);
  };

  const handlePatientIconClick = () => {
    setShowPatientModal(true);
    setShowModal(false);
  };

  const closePatientModal = () => {
    setTimeout(() => {
      window.history.replaceState(null, null, location.pathname);
      setserviceType(null);
      setAnimationVisible(false);
    }, 600);
    setShowPatientModal(false);
  };

  const [teamNamesArr, setTeamNamesArr] = useState([]);

  const fetchData = async () => {
    try {
      if (isActive === false) {
        setInviteMembersVisible(true);
      } else {
        setInviteMembersVisible(false);
      }
      if (teams?.length > 0) {
        let index = teams.findIndex(
          (eachTeam) => eachTeam.isGlobalView === true
        );
        index = index === -1 ? 0 : index;
        const teamMembersArr = teams[index].teamMembers;
        setHeaderTeamMembers(teamMembersArr);

        setTeamNamesArr(
          teams?.map(({ teamName }) => ({
            teamName,
          }))
        );

        setCoordinatesArray(
          pharmacies?.map(
            ({
              teamPharmacyID,
              teamPharmacyName,
              teamPharmacyLatitude,
              teamPharmacyLongitude,
              teamPharmacyAddress,
              pharmacyImage,
              clinicalKPI,
              operationsKPI,
              financeKPI,
              efficiencyKPI,
            }) => ({
              teamPharmacyID,
              teamPharmacyName,
              teamPharmacyLatitude,
              teamPharmacyLongitude,
              teamPharmacyAddress,
              pharmacyImage,
              clinicalKPI,
              operationsKPI,
              financeKPI,
              efficiencyKPI,
            })
          )
        );

        setDashboardKPIs(
          pharmacies?.map(
            ({
              teamPharmacyName,
              clinicalKPI,
              operationsKPI,
              efficiencyKPI,
              financeKPI,
            }) => ({
              teamPharmacyName,
              clinicalKPI,
              operationsKPI,
              efficiencyKPI,
              financeKPI,
            })
          )
        );

        let allPatients = [];

        pharmacies.forEach((pharmacies) => {
          pharmacies?.patients?.forEach((patients) => {
            allPatients.push(patients);
          });
        });

        setAllPatientDataArr(allPatients);

        kpiValues = new setKpiValues(pharmacies, serviceCounts).obj;
        setPharmacyData(kpiValues);

        totalChecks = 0;
        totalNMS = 0;

        kpiValues?.map((val) => {
          totalChecks = (totalChecks || 0) + parseInt(val?.checksCount || "0");
          totalNMS = (totalNMS || 0) + parseInt(val?.NMSCount || "0");
          totalPharmacies = kpiValues?.length || 0;
        });
        setTotalChecksCount(totalChecks);
        setTotalNMSCount(totalNMS);
        setTotalPharmaciesCount(totalPharmacies);

        return Promise.resolve(index);
      }
    } catch (error) {
      logError(error);
      showError(responseErrorMessage, true);
      return Promise.reject(-1);
    }

    return Promise.resolve(-1);
  };

  const supercluster = new Supercluster({
    radius: 75,
    maxZoom: 20,
  });

  const [points, setPoints] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [patientClusters, setPatientClusters] = useState([]);
  const [patientPoint, setPatientPoint] = useState([]);
  const [bounds, setBounds] = useState([-180, -85, 180, 85]);
  const [zoom, setZoom] = useState(0);

  useEffect(() => {
    if (dashboardKPIs) {
      let totalClinical = 0;
      let totalOperations = 0;
      let totalEfficiency = 0;
      let totalFinance = 0;
      let subTotalClinical = 0;
      let subTotalOperations = 0;
      let subTotalFinance = 0;
      let subTotalEfficiency = 0;

      for (const val of dashboardKPIs) {
        if (val?.clinicalKPI != null) {
          subTotalClinical =
            (val?.clinicalKPI?.totalKPIs || 0) * (dashboardKPIs?.length || 0);
          break;
        }
      }

      for (const val of dashboardKPIs) {
        if (val?.operationsKPI != null) {
          subTotalOperations =
            (val?.operationsKPI?.totalKPIs || 0) * (dashboardKPIs?.length || 0);
          break;
        }
      }

      for (const val of dashboardKPIs) {
        if (val?.financeKPI != null) {
          subTotalFinance =
            (val?.financeKPI?.totalKPIs || 0) * (dashboardKPIs?.length || 0);
          break;
        }
      }

      for (const val of dashboardKPIs) {
        if (val?.efficiencyKPI != null) {
          subTotalEfficiency =
            (val?.efficiencyKPI?.totalKPIs || 0) * (dashboardKPIs?.length || 0);
          break;
        }
      }

      dashboardKPIs.forEach((val) => {
        if (val?.clinicalKPI) {
          totalClinical += val?.clinicalKPI?.kpiScore || 0;
        }
        if (val?.operationsKPI) {
          totalOperations += val?.operationsKPI?.kpiScore || 0;
        }
        if (val?.efficiencyKPI) {
          totalEfficiency += val?.efficiencyKPI?.kpiScore || 0;
        }
        if (val?.financeKPI) {
          totalFinance += val?.financeKPI?.kpiScore || 0;
        }
      });

      setTotalClinicalCount(
        Number(((totalClinical / subTotalClinical) * 100).toFixed(2))
      );
      setTotalOperationsCount(
        Number(((totalOperations / subTotalOperations) * 100).toFixed(2))
      );
      setTotalFinanceCount(
        Number(((totalFinance / subTotalFinance) * 100).toFixed(2))
      );
      setTotalEfficiencyCount(
        Number(((totalEfficiency / subTotalEfficiency) * 100).toFixed(2))
      );
    }
  }, [dashboardKPIs]);

  useEffect(() => {
    const { ifMapEnabled } = features || {};
    if (
      isAppLoading === false &&
      isValidOrg === true &&
      ifMapEnabled === true
    ) {
      fetchData()
        .then(async (index) => {
          if (index >= 0 && teams?.length > 0 && pharmacies?.length > 0) {
            const timeoutID = await new Promise((r) => setTimeout(r, 1000));
            if (timeoutID) {
              clearTimeout(timeoutID);
            }
            await handleTeamClick(pharmacies);

            handleTeamClickTeamName(teams[index]);

            if (showModalAgain === true && currentPharmacy) {
              handlePharmacyClick(currentPharmacy);
              window.history.replaceState(null, null, location.pathname);
            }
          }
          return index;
        })
        .catch((error) => logError(error));
    }
  }, [isValidOrg, isAppLoading]);

  const [totalKPIsPercentages, setTotalKPIsPercentage] = useState("");

  useEffect(() => {
    const points = coordinatesArray?.map((pharmacy) => {
      let totalKPIsPercentage = 0;
      let clinicalScore = 0;
      let operationsScore = 0;
      let financeScore = 0;
      let efficiencyScore = 0;

      if (pharmacy.clinicalKPI) {
        totalKPIsPercentage += pharmacy.clinicalKPI?.kpiPercentage;
        clinicalScore += pharmacy.clinicalKPI?.kpiPercentage ?? 0;
      }

      if (pharmacy.operationsKPI) {
        totalKPIsPercentage += pharmacy.operationsKPI?.kpiPercentage;
        operationsScore += pharmacy.operationsKPI?.kpiPercentage ?? 0;
      }

      if (pharmacy.financeKPI) {
        totalKPIsPercentage += pharmacy.financeKPI?.kpiPercentage;
        financeScore += pharmacy.financeKPI?.kpiPercentage ?? 0;
      }

      if (pharmacy.efficiencyKPI) {
        totalKPIsPercentage += pharmacy.efficiencyKPI?.kpiPercentage;
        efficiencyScore += pharmacy.efficiencyKPI?.kpiPercentage ?? 0;
      }

      setTotalKPIsPercentage(totalKPIsPercentage);

      return {
        type: "Features",
        properties: {
          cluster: false,
          pharmacy: pharmacy,
          pharmacyId: pharmacy.teamPharmacyID,
          pharmacyName: pharmacy.teamPharmacyName,
          pharmacyAddress: pharmacy.teamPharmacyAddress,
          pharmacyImage: pharmacy.pharmacyImage,
          totalKPIPercentage: totalKPIsPercentages / 4,
          clinicalScore: clinicalScore,
          operationsScore: operationsScore,
          financeScore: financeScore,
          efficiencyScore: efficiencyScore,
        },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(pharmacy.teamPharmacyLongitude),
            parseFloat(pharmacy.teamPharmacyLatitude),
          ],
        },
      };
    });

    setPoints(points);
    setPatientCount(allPatientDataArr?.length);
  }, [coordinatesArray]);

  useEffect(() => {
    supercluster.load(points);
    setClusters(supercluster.getClusters(bounds, zoom));
  }, [points, zoom, bounds]);

  useEffect(() => {
    const patientsPoints = allPatientDataArr?.map((patients) => ({
      type: "Features",
      properties: {
        cluster: false,
      },
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(patients.logitude),
          parseFloat(patients.latitude),
        ],
      },
    }));
    setPatientPoint(patientsPoints);
  }, [allPatientDataArr]);

  useEffect(() => {
    supercluster.load(patientPoint);
    setPatientClusters(supercluster.getClusters(bounds, zoom));
  }, [patientPoint, zoom, bounds]);

  useEffect(() => {
    if (mapRef.current) {
      setBounds(mapRef.current.getMap().getBounds().toArray().flat());
    }
  }, [mapRef?.current]);

  function updateCoordinatesOnTeamClick(pharmacies) {
    const coordinatesArray = pharmacies?.map(
      ({
        teamPharmacyID,
        teamPharmacyName,
        teamPharmacyLatitude,
        teamPharmacyLongitude,
        teamPharmacyAddress,
        pharmacyImage,
        clinicalKPI,
        operationsKPI,
        financeKPI,
        efficiencyKPI,
      }) => ({
        teamPharmacyID,
        teamPharmacyName,
        teamPharmacyLatitude,
        teamPharmacyLongitude,
        teamPharmacyAddress,
        pharmacyImage,
        clinicalKPI,
        operationsKPI,
        financeKPI,
        efficiencyKPI,
      })
    );

    const points = coordinatesArray?.map((pharmacy) => {
      let totalKPIsPercentage = 0;
      let clinicalScore = 0;
      let operationsScore = 0;
      let financeScore = 0;
      let efficiencyScore = 0;

      if (pharmacy.clinicalKPI) {
        totalKPIsPercentage += pharmacy.clinicalKPI?.kpiPercentage;
        clinicalScore += pharmacy.clinicalKPI?.kpiPercentage ?? 0;
      }

      if (pharmacy.operationsKPI) {
        totalKPIsPercentage += pharmacy.operationsKPI?.kpiPercentage;
        operationsScore += pharmacy.operationsKPI?.kpiPercentage ?? 0;
      }

      if (pharmacy.financeKPI) {
        totalKPIsPercentage += pharmacy.financeKPI?.kpiPercentage;
        financeScore += pharmacy.financeKPI?.kpiPercentage ?? 0;
      }

      if (pharmacy.efficiencyKPI) {
        totalKPIsPercentage += pharmacy.efficiencyKPI?.kpiPercentage;
        efficiencyScore += pharmacy.efficiencyKPI?.kpiPercentage ?? 0;
      }

      return {
        type: "Features",
        properties: {
          cluster: false,
          pharmacy: pharmacy,
          pharmacyId: pharmacy.teamPharmacyID,
          pharmacyName: pharmacy.teamPharmacyName,
          pharmacyAddress: pharmacy.teamPharmacyAddress,
          pharmacyImage: pharmacy.pharmacyImage,
          totalKPIPercentage: totalKPIsPercentage / 4,
          clinicalScore: clinicalScore,
          operationsScore: operationsScore,
          financeScore: financeScore,
          efficiencyScore: efficiencyScore,
        },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(pharmacy.teamPharmacyLongitude),
            parseFloat(pharmacy.teamPharmacyLatitude),
          ],
        },
      };
    });

    setPoints(points);

    const bounds = new mapboxgl.LngLatBounds();
    coordinatesArray.forEach((obj) => {
      bounds.extend([obj.teamPharmacyLongitude, obj.teamPharmacyLatitude]);
    });

    const zoomLevel = 14;
    mapRef?.current?.fitBounds(bounds, {
      duration: 1000,
      maxZoom: zoomLevel,
      minZoom: zoomLevel,
    });

    setDashboardKPIs(
      pharmacies?.map(
        ({
          teamPharmacyName,
          clinicalKPI,
          operationsKPI,
          efficiencyKPI,
          financeKPI,
        }) => ({
          teamPharmacyName,
          clinicalKPI,
          operationsKPI,
          efficiencyKPI,
          financeKPI,
        })
      )
    );

    let allPatients = [];

    pharmacies.forEach((pharmacies) => {
      pharmacies?.patients?.forEach((patients) => {
        allPatients.push(patients);
      });
    });

    setAllPatientDataArr(allPatients);
  }

  function updateTeamMembersonTeamClick(teamMembers) {
    const teamMembersArr = teamMembers;
    setHeaderTeamMembers(teamMembersArr);
  }

  const navigate = useNavigate();
  const routeChangechecks = () => {
    showInfo("Not implemented");
  };

  async function handleTeamClick(pharmacies) {
    updateCoordinatesOnTeamClick(pharmacies);
    setIsTeamUpdated(false);
  }

  async function handleTeamClickTeamMembers(teamMembers) {
    updateTeamMembersonTeamClick(teamMembers);
  }

  function handleTeamClickTeamName(selectedTeam) {
    if (selectedTeam) {
      const { teamID, teamName, teamDisplayName, teamImage } =
        selectedTeam || {};
      setTeamName(teamDisplayName || teamName || "");
      setAddTeamImage(teamImage);
      setTeamId(teamID || "");
    } else {
      setTeamName("");
      setTeamId("");
    }
  }

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const [viewport, setViewport] = useState({
    latitude: 54.7023545,
    longitude: -3.2765753,
    zoom: 5,
  });

  const [selectedCluster, setSelectedCluster] = useState(null);

  // Handle cluster marker hover
  const handleClusterHover = (cluster) => {
    setSelectedCluster(cluster);
  };

  // Handle cluster marker mouse leave
  const handleClusterMouseLeave = () => {
    setSelectedCluster(null);
  };

  const toggleChecks = () => {
    setIsNMS(false);
    setIsCX(false);
    setIsChecks((prevState) => !prevState);
    setIsChecks ? setPharmacyData(kpiValues) : setPharmacyData([]);
  };

  const toggleNMS = () => {
    setIsChecks(false);
    setIsCX(false);

    setIsNMS((prevState) => !prevState);
    setIsNMS ? setPharmacyData(kpiValues) : setPharmacyData([]);
  };

  const toggleCX = () => {
    setIsChecks(false);
    setIsNMS(false);
    setIsCX((prevState) => !prevState);
    setIsCX ? setPharmacyData(kpiValues) : setPharmacyData([]);
  };

  const handleListViewClick = () => {
    setIsListViewVisible(!isListViewVisible);
    setChatVisible(false);
    if (screenWidth <= 600 || screenWidth <= 768) {
      // Toggle the zIndex
      setBottomActionBarZIndex((prevZIndex) => (prevZIndex === 2 ? 0 : 2));
    }
  };

  useEffect(() => {
    const handleResize = () => {
      // Update the screenWidth state on window resize
      setScreenWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChatClick = () => {
    setIsListViewVisible(false);
  };

  const [hoveredMarker, setHoveredMarker] = useState(null);

  const handleBottomBarToggleClick = () => {
    setToggleBottomBar(!toggleBottombar);
    setSidebarOpen(false);
  };

  const [bottomBarDivClick, setBottomBarDivClick] = useState({
    Clinical: false,
    Operations: false,
    Finance: false,
    Efficiency: false,
  });

  const handleBottomBarDivClick = (id) => {
    percentageArr.includes(id) ? percentageArr.pop(id) : percentageArr.push(id);
    const updatedPoints = clusters?.map((pharmacy, index) => {
      let totalKPIPercentage = 0;

      if (id === "Clinical") {
        isClinicalClick = !isClinicalClick;
        percentageArr.includes("Clinical")
          ? (totalKPIPercentage += dashboardKPIs[index].clinicalKPI
              ? dashboardKPIs[index].clinicalKPI.kpiPercentage
              : 0)
          : (totalKPIPercentage = 0);

        if (percentageArr.includes("Operations")) {
          totalKPIPercentage += dashboardKPIs[index].operationsKPI
            ? dashboardKPIs[index].operationsKPI.kpiPercentage
            : 0;
        }
        if (percentageArr.includes("Efficiency")) {
          totalKPIPercentage += dashboardKPIs[index].efficiencyKPI
            ? dashboardKPIs[index].efficiencyKPI.kpiPercentage
            : 0;
        }
        if (percentageArr.includes("Finance")) {
          totalKPIPercentage += dashboardKPIs[index].financeKPI
            ? dashboardKPIs[index].financeKPI.kpiPercentage
            : 0;
        }
      } else if (id === "Operations") {
        isOperationClick = !isOperationClick;
        percentageArr.includes("Operations")
          ? (totalKPIPercentage += dashboardKPIs[index].operationsKPI
              ? dashboardKPIs[index].operationsKPI.kpiPercentage
              : 0)
          : (totalKPIPercentage = 0);

        if (percentageArr.includes("Clinical")) {
          totalKPIPercentage += dashboardKPIs[index].clinicalKPI
            ? dashboardKPIs[index].clinicalKPI.kpiPercentage
            : 0;
        }
        if (percentageArr.includes("Finance")) {
          totalKPIPercentage += dashboardKPIs[index].financeKPI
            ? dashboardKPIs[index].financeKPI.kpiPercentage
            : 0;
        }
        if (percentageArr.includes("Efficiency")) {
          totalKPIPercentage += dashboardKPIs[index].efficiencyKPI
            ? dashboardKPIs[index].efficiencyKPI.kpiPercentage
            : 0;
        }
      } else if (id === "Finance") {
        isFinanceClick = !isFinanceClick;
        percentageArr.includes("Finance")
          ? (totalKPIPercentage += dashboardKPIs[index].financeKPI
              ? dashboardKPIs[index].financeKPI.kpiPercentage
              : 0)
          : (totalKPIPercentage = 0);
        if (percentageArr.includes("Clinical")) {
          totalKPIPercentage += dashboardKPIs[index].clinicalKPI
            ? dashboardKPIs[index].clinicalKPI.kpiPercentage
            : 0;
        }
        if (percentageArr.includes("Operations")) {
          totalKPIPercentage += dashboardKPIs[index].operationsKPI
            ? dashboardKPIs[index].operationsKPI.kpiPercentage
            : 0;
        }
        if (percentageArr.includes("Efficiency")) {
          totalKPIPercentage += dashboardKPIs[index].efficiencyKPI
            ? dashboardKPIs[index].efficiencyKPI.kpiPercentage
            : 0;
        }
      } else if (id === "Efficiency") {
        isEfficiencyClick = !isEfficiencyClick;
        percentageArr.includes("Efficiency")
          ? (totalKPIPercentage += dashboardKPIs[index].efficiencyKPI
              ? dashboardKPIs[index].efficiencyKPI.kpiPercentage
              : 0)
          : (totalKPIPercentage = 0);
        if (percentageArr.includes("Clinical")) {
          totalKPIPercentage += dashboardKPIs[index].clinicalKPI
            ? dashboardKPIs[index].clinicalKPI.kpiPercentage
            : 0;
        }
        if (percentageArr.includes("Operations")) {
          totalKPIPercentage += dashboardKPIs[index].operationsKPI
            ? dashboardKPIs[index].operationsKPI.kpiPercentage
            : 0;
        }
        if (percentageArr.includes("Finance")) {
          totalKPIPercentage += dashboardKPIs[index].financeKPI
            ? dashboardKPIs[index].financeKPI.kpiPercentage
            : 0;
        }
      }

      return {
        ...pharmacy,
        properties: {
          ...pharmacy.properties,
          totalKPIPercentage: parseFloat(
            ((totalKPIPercentage || 0) / (percentageArr?.length || 1)).toFixed(
              2
            )
          ),
        },
      };
    });

    setBottomBarDivClick((prevActiveDivs) => {
      const toggledState = !prevActiveDivs[id];

      // if (toggledState) {
      setPoints(updatedPoints);
      if (percentageArr?.length === 0) {
        const points = coordinatesArray?.map((pharmacy) => {
          let totalKPIsPercentage = 0;
          let clinicalScore = 0;
          let operationsScore = 0;
          let financeScore = 0;
          let efficiencyScore = 0;

          if (pharmacy.clinicalKPI) {
            totalKPIsPercentage += pharmacy.clinicalKPI?.kpiPercentage;
            clinicalScore += pharmacy.clinicalKPI?.kpiPercentage ?? 0;
          }

          if (pharmacy.operationsKPI) {
            totalKPIsPercentage += pharmacy.operationsKPI?.kpiPercentage;
            operationsScore += pharmacy.operationsKPI?.kpiPercentage ?? 0;
          }

          if (pharmacy.financeKPI) {
            totalKPIsPercentage += pharmacy.financeKPI?.kpiPercentage;
            financeScore += pharmacy.financeKPI?.kpiPercentage ?? 0;
          }

          if (pharmacy.efficiencyKPI) {
            totalKPIsPercentage += pharmacy.efficiencyKPI?.kpiPercentage;
            efficiencyScore += pharmacy.efficiencyKPI?.kpiPercentage ?? 0;
          }

          return {
            type: "Features",
            properties: {
              cluster: false,
              pharmacy: pharmacy,
              pharmacyId: pharmacy.teamPharmacyID,
              pharmacyName: pharmacy.teamPharmacyName,
              pharmacyAddress: pharmacy.teamPharmacyAddress,
              pharmacyImage: pharmacy.pharmacyImage,
              totalKPIPercentage: totalKPIsPercentage / 4,
              clinicalScore: clinicalScore,
              operationsScore: operationsScore,
              financeScore: financeScore,
              efficiencyScore: efficiencyScore,
            },
            geometry: {
              type: "Point",
              coordinates: [
                parseFloat(pharmacy.teamPharmacyLongitude),
                parseFloat(pharmacy.teamPharmacyLatitude),
              ],
            },
          };
        });

        setPoints(points);
      }

      return {
        ...prevActiveDivs,
        [id]: toggledState,
      };
    });
  };

  const [isDragging, setIsDragging] = useState(false);
  const [lineCoordinates, setLineCoordinates] = useState([]);

  const onMarkerDragStart = (event) => {
    setIsDragging(true);
    setLineCoordinates([[event.lngLat.lng, event.lngLat.lat]]);
  };

  const onMarkerDrag = (event) => {
    if (isDragging) {
    }
  };
  const enablePharmacyMergeMessage = () => {
    showSuccess("Pharmacy Merging has been enabled");
  };
  const disablePharmacyMergeErrorMessage = () => {
    showInfo("Pharmacy Merging has been disabled");
  };

  const onMarkerDragEnd = (event) => {
    setIsDragging(false);
    setLineCoordinates([
      ...lineCoordinates,
      [event.lngLat.lng, event.lngLat.lat],
    ]);

    const searchLatLng = [event?.lngLat?.lng, event?.lngLat?.lat];

    for (const pharmacy of pharmacies) {
      const pharmacyLongitude = parseFloat(
        pharmacy?.teamPharmacyLongitude
      ).toFixed(3);
      const pharmacyLatitude = parseFloat(
        pharmacy?.teamPharmacyLatitude
      ).toFixed(3);

      const searchLongitude = searchLatLng[0]?.toFixed(3);
      const searchLatitude = searchLatLng[1]?.toFixed(3);

      if (
        pharmacyLongitude === searchLongitude &&
        pharmacyLatitude === searchLatitude
      ) {
        setFoundPharmacyCoordinates({
          longitude: parseFloat(pharmacy?.teamPharmacyLongitude),
          latitude: parseFloat(pharmacy?.teamPharmacyLatitude),
        });
        setIsLine(true);
        return;
      } else {
        setIsLine(false);
      }
    }

    setFoundPharmacyCoordinates([]);
  };

  const handlePharmacyMergeClick = () => {
    setIsPharmacyMergeEnabled(!isPharmacyMergeEnabled);

    if (isPharmacyMergeEnabled === false) {
      enablePharmacyMergeMessage();
    } else {
      disablePharmacyMergeErrorMessage();
    }

    setLineCoordinates([]);
    setFoundPharmacyCoordinates([]);
  };

  const handleSettingsClick = () => {
    navigate(`/settings/general`);
  };

  const loaderAppData = useLoaderData();

  return (
    <AsyncRendererRouter resolve={loaderAppData?.appData}>
      {isAuthenticated === true && isValidOrg === true ? (
        <div>
          <InviteMembers
            check={isActive === false ? "" : "addPharmacy"}
            showModal={isInviteMembersVisible}
            closeModal={closeInviteMembers}
            root={root}
          />
          <Team
            handleCallback={handleTeamClick}
            handleCallbackTeamMembers={handleTeamClickTeamMembers}
            handleCallbackTeamNames={handleTeamClickTeamName}
            setClickedTeamIndex={setClickedTeamIndex}
            animationVisible={animationVisible}
          />
          <TeamName
            clickedTeamIndex={clickedTeamIndex}
            teamName={teamName}
            toggleTeamsDialog={toggleTeamsDialog}
          />
          <Header
            headerTeamMembers={headerTeamMembers}
            animationVisible={animationVisible}
            handleListViewClick={handleListViewClick}
            isListViewVisible={isListViewVisible}
            handleChatClick={handleChatClick}
            handleBottomBarToggleClick={handleBottomBarToggleClick}
            handlePharmacyMergeClick={handlePharmacyMergeClick}
            selectedTeamId={teamId}
            handleSettingsClick={handleSettingsClick}
            isPharmacyMergeEnabled={true}
            isToggleBottombarEnabled={true}
            isSettingsEnabled={true}
            isChatEnabled={true}
            isListViewEnabled={true}
            handleInviteMembersClick={showInviteMembers}
          />

          {/* <Messages /> */}
          {(isChecks && (
            <StartChecks
              totalChecksCount={totalChecksCount}
              headerTeamMembers={headerTeamMembers}
            />
          )) ||
            (isCX && <CreateReport />) ||
            (isNMS && (
              <StartNMS
                totalNMSCount={totalNMSCount}
                headerTeamMembers={headerTeamMembers}
              />
            )) ||
            (isSidebarOpen ? <CreateCompaign /> : <></>)}
          <BottomActionBar
            toggleSidebar={toggleSidebar}
            isSidebarOpen={isSidebarOpen}
            toggleChecks={toggleChecks}
            toggleNMS={toggleNMS}
            toggleCX={toggleCX}
            isChecks={isChecks}
            isNMS={isNMS}
            isCX={isCX}
            isClinical={isClinical}
            isOperations={isOperations}
            isFinance={isFinance}
            isEfficiency={isEfficiency}
            totalChecksCount={totalChecksCount}
            totalNMSCount={totalNMSCount}
            totalPharmaciesCount={totalPharmaciesCount}
            animationVisible={animationVisible}
            setIsListViewVisible={setIsListViewVisible}
            toggleBottombar={toggleBottombar}
            totalClinicalCount={totalClinicalCount}
            totalOperationsCount={totalOperationsCount}
            totalEfficiencyCount={totalEfficiencyCount}
            totalFinanceCount={totalFinanceCount}
            bottomBarDivClick={bottomBarDivClick}
            onDivClick={handleBottomBarDivClick}
            zIndex={bottomActionBarZIndex}
          />
          {checkUserPermissions(accessAddTeams) && (
            <AddTeam
              toggleTeamsDialog={toggleTeamsDialog}
              teamsFormik={teamsFormik}
              handleCallback={handleTeamClick}
              clickedTeamIndex = {clickedTeamIndex}
            />
          )}
          <AddTeam
            toggleTeamsDialog={toggleTeamsDialog}
            teamsFormik={teamsFormik}
            handleCallback={handleTeamClick}
            clickedTeamIndex = {clickedTeamIndex}
          />

          {/* <PatientsFilter
    patientCount={patientCount}
    isSidebarOpen={isSidebarOpen}
    handlePatientAgeFilter={handlePatientAgeFilter}
    handleFilterSubmit={handleFilterSubmit}
    patientAgeNotFound={patientAgeNotFound}
    handleFilterReset={handleFilterReset}
    resetAgeRange={resetAgeRange}
    resetFilterList={resetFilterList}
    setResetAgeRange={setResetAgeRange}
    setResetFilterList={setResetFilterList}
    selectedFilter={selectedFilter}
    handleFilterClickHandler={handleFilterClickHandler}
  /> */}

          <Map
            initialViewState={viewport}
            scrollZoom={true}
            mapStyle={
              darkMode
                ? "mapbox://styles/mapbox/dark-v11"
                : "mapbox://styles/mapbox/light-v11"
            }
            style={{
              width: "100vw",
              height: "100vh",
            }}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            ref={mapRef}
            onZoomEnd={(e) => setZoom(Math.round(e.viewState.zoom))}
            attributionControl={false}
          >
            <NavigationControl position="bottom-right" />
            {clusters?.map((cluster, index) => {
              const [longitude, latitude] = cluster?.geometry?.coordinates;
              const name = cluster?.properties?.pharmacyName;
              const address = cluster?.properties?.pharmacyAddress;
              const pharmacyImg = cluster?.properties?.pharmacyImage;

              const totalKPIsPercentage =
                cluster?.properties?.totalKPIPercentage;
              const clinicalScore = cluster?.properties?.clinicalScore;
              const operationsScore = cluster?.properties?.operationsScore;
              const financeScore = cluster?.properties?.financeScore;
              const efficiencyScore = cluster?.properties?.efficiencyScore;

              const { cluster: isCluster, point_count: pointCount } =
                cluster?.properties || {};

              if (isCluster) {
                return (
                  <Marker
                    key={`cluster-${cluster.id}`}
                    longitude={longitude}
                    latitude={latitude}
                  >
                    <div
                      className={`cluster-marker ${
                        isSidebarOpen && clusterOpacity ? "clusterFade" : ""
                      }`}
                      onMouseEnter={() => handleClusterHover(cluster)}
                      onMouseLeave={handleClusterMouseLeave}
                    >
                      {pointCount}
                    </div>
                  </Marker>
                );
              }
              const matchingPharmacy = pharmacyData.find(
                (pharmacy) =>
                  pharmacy.pharmacyName === cluster.properties.pharmacyName
              );

              const checksCount = matchingPharmacy
                ? matchingPharmacy.checksCount
                : "";

              const NMSCount = matchingPharmacy
                ? matchingPharmacy.NMSCount
                : "";

              const isHovered = hoveredMarker === index;

              const kpiData = [
                { kpiName: "Clinical", kpiVal: clinicalScore },
                { kpiName: "Operations", kpiVal: operationsScore },
                { kpiName: "Finance", kpiVal: financeScore },
                { kpiName: "Efficiency", kpiVal: efficiencyScore },
              ];

              return (
                <Marker
                  key={index}
                  longitude={longitude}
                  latitude={latitude}
                  style={{ zIndex: `${isHovered ? "2" : ""}` }}
                  draggable={isPharmacyMergeEnabled ? true : false}
                  onDragStart={onMarkerDragStart}
                  //onDrag={onMarkerDrag}
                  onDragEnd={onMarkerDragEnd}
                >
                  <div
                    className="single-marker"
                    onClick={() => {
                      handlePharmacyClick(cluster.properties?.pharmacy);
                      // handlePharmacyClick(
                      //   cluster.properties.pharmacyName,
                      //   cluster.properties.pharmacyAddress,
                      //   cluster.properties.pharmacyId,
                      //   longitude,
                      //   latitude,
                      //   totalKPIsPercentage
                      // );
                    }}
                    onMouseEnter={() => {
                      //patient lines appear on below hover
                      //hoverOverMarker(cluster);
                      setHoveredMarker(index);
                    }}
                    onMouseLeave={() => {
                      //patient lines disappear on below hover
                      //hoverOverMarker(null);
                      setClusterOpacity(false);
                      setHoveredMarker(null);
                    }}
                  >
                    <div
                      className={`percentage-marker ${
                        isPharmacyMergeEnabled &&
                        foundPharmacyCoordinates.latitude === latitude &&
                        foundPharmacyCoordinates.longitude === longitude
                          ? isLine
                            ? "rippleMarker"
                            : ""
                          : ""
                      }`}
                      onMouseEnter={() => {}}
                      style={{
                        background: `radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(${
                          totalKPIsPercentage <= 60
                            ? "#e55542"
                            : totalKPIsPercentage > 60 &&
                              totalKPIsPercentage <= 80
                            ? "#f3e73d"
                            : "#51c198"
                        } ${totalKPIsPercentage}%, #c7c7c7 0)`,
                      }}
                    >
                      {/* <p className="perc-val">{totalKPIsPercentage}%</p> */}

                      <p className="perc-val">{NMSCount > 0 ? NMSCount : 0}</p>
                    </div>
                    <div>
                      {(isChecks && (
                        <p className="pharmacy-icon-counts">
                          ({checksCount || 0})
                        </p>
                      )) ||
                        (isNMS && (
                          <></>
                          // <p className="pharmacy-icon-counts">
                          //   ({NMSCount || 0}){" "}
                          // </p>
                        )) ||
                        (isCX && "")}
                    </div>
                    <p className="pharmacy-name">{name}</p>
                  </div>

                  <AnimatePresence>
                    {isHovered && (
                      <motion.div
                        className="dashboardtool"
                        initial={{
                          opacity: 0,
                          scale: 0, // Start with a scale of 0 (hidden)
                          transition: {
                            duration: 0.2,
                          },
                        }}
                        animate={{
                          opacity: 1,
                          scale: 1, // Grow to full size
                          transition: {
                            duration: 0.2,
                          },
                        }}
                        exit={{
                          opacity: 0,
                          scale: 0, // Shrink and disappear
                          transition: {
                            duration: 0.2,
                          },
                        }}
                      >
                        <PharmacyTooltip
                          pharmacyName={name}
                          pharmacyAddress={address}
                          pharmacyImg={pharmacyImg}
                          setHoveredMarker={setHoveredMarker}
                          kpiData={kpiData}
                        />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Marker>
              );
            })}
            {isPharmacyMergeEnabled && isLine ? (
              <Source
                id="polylineLayer"
                type="geojson"
                data={{
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "LineString",
                    coordinates: lineCoordinates,
                  },
                }}
              >
                <Layer
                  id="lineLayer"
                  type="line"
                  source="my-data"
                  layout={{
                    "line-join": "round",
                    "line-cap": "round",
                  }}
                  paint={{
                    "line-color": "#000",
                    "line-width": 2,
                    "line-dasharray": [2, 2], // Gap width between dots
                    "line-opacity": 0.8, // Opacity of the line
                    "line-translate": [0, 0], // Adjust as needed
                    "line-translate-anchor": "map",
                    "line-color": "#FF0000", // Red border color
                  }}
                />
              </Source>
            ) : (
              ""
            )}

            {isSidebarOpen ? (
              patientClusters?.map((cluster, index) => {
                // patientConn.map((patientMatch) => {
                //   if (
                //     patient[0] === patientMatch[0] &&
                //     patient[1] === patientMatch[1]
                //   ) {
                //     patient[2] = "black";
                //     patient[3] = "white";
                //   }
                // });
                const [longitude, latitude] = cluster?.geometry?.coordinates;
                const name = cluster?.properties?.pharmacyName;
                const { cluster: isCluster, point_count: pointCount } =
                  cluster?.properties || {};

                if (isCluster) {
                  return (
                    <Marker
                      key={`patient-clusters patient-cluster-${cluster.id}`}
                      longitude={longitude}
                      latitude={latitude}
                    >
                      <div
                        className={`patient-cluster-marker ${
                          isSidebarOpen && clusterOpacity ? "clusterFade" : ""
                        }`}
                      >
                        {pointCount}
                      </div>
                    </Marker>
                  );
                }

                return (
                  <Marker
                    key={`patient-${index}`}
                    longitude={longitude}
                    latitude={latitude}
                  >
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill={"none"}
                      stroke={"#00000080"}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="17"
                        height="17"
                        rx="8.5"
                        transform="matrix(-1 0 0 1 21 0)"
                        fill="white"
                      />
                      <rect
                        x="11"
                        y="7"
                        width="3"
                        height="3"
                        rx="0.5"
                        fill="black"
                      />
                      <rect
                        x="-0.5"
                        y="0.5"
                        width="16"
                        height="16"
                        rx="8"
                        transform="matrix(-1 0 0 1 20 0)"
                        stroke="black"
                      />
                    </svg>
                  </Marker>
                );
              })
            ) : (
              <></>
            )}
            <AnimatePresence>
              {showModal && (
                <motion.div
                  key="modal"
                  initial={{
                    opacity: 0,
                    transition: {
                      duration: 1.5,
                    },
                  }}
                  animate={{
                    opacity: 1,
                    transition: {
                      duration: 1.5,
                    },
                  }}
                  exit={{
                    opacity: 0,
                    transition: {
                      duration: 1.0,
                    },
                  }}
                  className="animated-modal"
                >
                  <PharmacyDetails
                    closeModal={closeModal}
                    headerTeamMembers={headerTeamMembers}
                    selectedName={selectedName}
                    selectedAddress={selectedAddress}
                    totalNMSCount={totalNMSCount}
                    animationVisible={animationVisible}
                    serviceType={serviceType ? serviceType : null}
                    // onSearchClick={handleSearchClick}
                    handlePatientIconClick={handlePatientIconClick}
                    ifPharmacyOverviewEnabled={ifPharmacyOverviewEnabled}
                    ifPatientListEnabled={ifPatientListEnabled}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {showPatientModal && (
                <motion.div
                  key="modal"
                  initial={{
                    opacity: 0,
                    transition: {
                      duration: 1.5,
                    },
                  }}
                  animate={{
                    opacity: 1,
                    transition: {
                      duration: 1.5,
                      type: "swing",
                    },
                  }}
                  exit={{
                    opacity: 0,
                    transition: {
                      duration: 1.0,
                    },
                  }}
                  className="animated-modal"
                >
                  <PatientDashboard
                    closePatientModal={closePatientModal}
                    headerTeamMembers={headerTeamMembers}
                    handlePatientIconClick={handlePatientIconClick}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </Map>

          <AnimatePresence>
            {isListViewVisible && <DashboardListing pharmacies={pharmacies} />}
          </AnimatePresence>
        </div>
      ) : isValidOrg === false && authToken ? (
        <AuthorizedModal />
      ) : (
        <></>
      )}
    </AsyncRendererRouter>
  );
}
