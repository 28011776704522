import React from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  XAxis,
  Tooltip,
  Bar,
  YAxis,
} from "recharts";
import { monthsOrder } from "../ServiceClaimStatus/components/MonthsOrder";

const NMSProcessed = ({ dataSetOne, ServicesData, servicesDataAPI }) => {
  const servicesProcessed = ServicesData
    ? ServicesData?.servicesProcessed?.slice()?.sort(
          (a, b) => monthsOrder.indexOf(a.month) - monthsOrder.indexOf(b.month)
        )
    : servicesDataAPI?.servicesProcessed?.slice()?.sort(
          (a, b) => monthsOrder.indexOf(a.month) - monthsOrder.indexOf(b.month)
        );

  return (
    <ResponsiveContainer height={400}>
      <ComposedChart data={servicesProcessed}>
        <CartesianGrid
          stroke="#aaa"
          horizontal={true}
          vertical={false}
          strokeDasharray="3 3"
          strokeDashoffset={2}
          strokeWidth={2}
        />

        <defs>
          <linearGradient id="gradientFill" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#f1c4f2" />
            <stop offset="100%" stopColor="#be7bed" />
          </linearGradient>
          <linearGradient id="gradientFillDark" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#b8b8b8" />
            <stop offset="100%" stopColor="#757474" />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="month"
          scale="band"
          axisLine={false}
          tickMargin={15}
          tickLine={false}
          dx={20}
          className="x_axis_chart title-heading"
        />
        <YAxis
          domain={[0, 10]}
          axisLine={false}
          tickMargin={20}
          tickLine={false}
          className="y_axis_chart title-heading"
        />
        <Tooltip />
        <Bar
          dataKey="servicesCompleted"
          barSize={10}
          fill="url(#gradientFillDark)"
          // className='graph_bars'
          radius={[10, 10, 0, 0]}
          base={0}
        />
        <Bar
          dataKey="servicesDeclined"
          barSize={10}
          fill="url(#gradientFill)"
          radius={[10, 10, 0, 0]}
          base={0}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default NMSProcessed;
