import React from "react";
import { ReactComponent as CalendarSVG } from "./../../../../../assets/images/orbitCalendar.svg";
import useTitanApp from "../../../../../hooks/useTitanApp";
import LoadingComponentRank from "../../../../../components/LoadingComponentOrbit/LoadingComponentRank";
export default function OrbitTodayEarning() {
  const { orbitState } = useTitanApp();
  const { isOtherDetailsLoading, otherDetails } = orbitState || {};
  const { todayEarning } = otherDetails || {};
  return (
    <div className="orbit_box">
      {isOtherDetailsLoading ? (
        <LoadingComponentRank />
      ) : (
        <>
          {" "}
          <div className="orbit_label">
            <CalendarSVG />
            <p>Today's Earnings</p>
          </div>
          <p className="orbit_value">£{todayEarning}</p>
        </>
      )}
    </div>
  );
}
