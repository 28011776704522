export const isStringBase64 = (value) => {
  let result = false;
  try {
    result = btoa(atob(value)) === value;
  } catch (err) {
    result = false;
  }
  return result;
};

export const convertBase64ToText = (value) => {
  let result = "";
  try {
    result = atob(value);
  } catch (err) {
    result = "";
  }
  return result;
};
