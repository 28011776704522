import React from "react";
import ReactDOM from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from "react-redux";
import { store } from "./hooks/useTitanApp";
import ToastedMessage from "./components/ToastedMessage/ToastedMessage";
import App from "./App";
import history from "./utils/history";
import config from "./config";
import { init } from "./services/logService";
const { domain, clientId, audience } = config;

export const initApp = () => {
  if (config?.environmentName !== "development") {
    try {
      init();
    } catch (error) {}
  }

  const root = ReactDOM.createRoot(document.getElementById("root"));

  const onRedirectCallback = (appState) => {
    history.push(
      appState && appState.returnTo
        ? appState.returnTo
        : window.location.pathname
    );
  };

  const providerConfig = {
    domain: domain,
    clientId: clientId,
    onRedirectCallback,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: audience,
    },
  };
  root.render(
    <Provider store={store}>
      <Auth0Provider {...providerConfig}>
        <div className="App font-face-sf-pro">
          <ToastedMessage />
          <App root={root} />
        </div>
      </Auth0Provider>
    </Provider>
  );
};

initApp();
