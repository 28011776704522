import React from "react";
import "./../../../assets/css/Dashboard/PatientListView.scss";
import { Tag } from "primereact/tag";

const PatientPricingTags = ({ value }) => {
  const tags = Array.isArray(value) ? value : [value];

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {tags?.map((tag, index) => (
        <div key={index} className="patient-tag-container">
          <Tag
            key={index}
            severity="custom"
            className={tag === 1 ? "custom_tag_nhs" : "custom_tag_subscription"}
            value={tag === 1 ? "Subscription" : "NHS only"}
          />
        </div>
      ))}
    </div>
  );
};

export default PatientPricingTags;
