import React, { useState } from "react";
import ServicesAccordion from "./ServicesAccordion/ServicesAccordion";
import "./../../../../assets/css/Dashboard/ServicesSidebar.scss";
import { FaTimes } from "react-icons/fa";
import { PiCaretRightBold } from "react-icons/pi";
import { setIsNewClinicComponent } from "../../../../dispatchers/pharmacyServiceDispatchers";

export default function ServicesSidebar() {
  const [showResponsiveSidebar, setShowResponsiveSidebar] = useState(false);

  const handleShowSidebar = () => {
    if (window.innerWidth < 1025) {
      setShowResponsiveSidebar(!showResponsiveSidebar);
    }
  };

  return (
    <>
      <div
        className={`services_sidebar ${
          showResponsiveSidebar ? "show-sidebar" : ""
        }`}
      >
        <div className="services_accordion_details">
          <ServicesAccordion />
        </div>
      </div>

      <div
        className="sidebar-hamburger services-ham"
        onClick={handleShowSidebar}
      >
        {showResponsiveSidebar ? (
          <div className="nms-ham">
            <FaTimes />
            <span> Close Services</span>
          </div>
        ) : (
          <div className="nms-ham">
            <PiCaretRightBold />
            <span> Show Services</span>
          </div>
        )}
      </div>
    </>
  );
}
