import React, { useState, useEffect } from "react";
import useTitanApp from "../../hooks/useTitanApp";
import { Skeleton } from "@mui/material";
import {
  setCurrentPatientName,
  setCurrentIsNmsPatientLoading,
  setCurrentIsNmsTimelineLoading,
  setCurrentIsNmsPrescriptionLoading,
  setCurrentIsServiceNotesLoading,
  setCurrentArrayImg,
  getClinicalServiceByClinicalServiceId,
  getTitanPatient,
  getTimeline,
  getServiceNotes,
  getFollowUpByServiceIdAndServiceType,
  getPatientByTelephoneAndStageIndexResult,
  setCurrentIsPatientNmsTelephoneLoading,
  setCurrentPatientNMSData,
  setCurrentGroupedPatientWarning,
  setCurrentTempClinicalServiceId,
} from "../../dispatchers/nmsDispatchers";
import {
  getPrescriptionByIncomingId,
  getPatientImage,
} from "../../dispatchers/clinicalChecksDispatchers";
import { useStore } from "react-redux";
import { stageTypes } from "../../enum/stageType";
export default function GroupedPatients() {
  const store = useStore();
  const currentSessionId = localStorage.getItem("currentSessionId");
  const { nmsServiceState, appState } = useTitanApp();
  const { darkMode } = appState;
  const { isNmsPatientTelephoneLoading, patientNmsData, clinicalServiceId } =
    nmsServiceState || {};
  const currentYear = new Date().getFullYear();
  const [activePatient, setActivePatient] = useState(null);
  useEffect(() => {
    const currentIndex = patientNmsData.findIndex(
      (patient) => patient._id == clinicalServiceId
    );
    if (currentIndex !== -1) {
      setActivePatient(currentIndex);
    }
  }, []);

  async function handlePatientClick(serviceId, index) {
    setActivePatient(index);
    var service = await getClinicalServiceByClinicalServiceId(serviceId);
    if (service?.clinicalServiceBuilderResponseDTO) {
      getClinicalServiceResult(service, serviceId);
    } else {
      return null;
    }
  }

  const getClinicalServiceResult = (service, serviceId) => {
    const { clinicalServiceBuilderResponseDTO, pharmacy } = service || {};
    const { pharmacyID } = pharmacy || {
      pharmacyID: "00000000-0000-0000-0000-000000000000",
    };
    const { patientId, prescriptionIncomingId, telephoneNumber } =
      clinicalServiceBuilderResponseDTO || {
        patientId: "00000000-0000-0000-0000-000000000000",
        prescriptionIncomingId: "00000000-0000-0000-0000-000000000000",
        telephoneNumber: null,
      };
    getFollowupDetailByClinicalServiceId(serviceId);
    getServiceNotesByClinicalServiceId(serviceId);
    getTimelineDataForClinicalServiceId(serviceId);
    if (telephoneNumber !== null || telephoneNumber !== "") {
      getPatientByTelephoneAndStageIndex(telephoneNumber, serviceId);
    } else {
      setCurrentPatientNMSData([]);
    }

    if (prescriptionIncomingId !== null) {
      getPrescriptionByIncomingId({
        prescriptionIncomingId,
        pharmacyID,
      }).then((data) => {
        prescriptionImageData(data, pharmacyID);
      });
    }
    if (
      patientId !== null &&
      patientId !== "00000000-0000-0000-0000-000000000000"
    ) {
      const clinicalServiceId = serviceId;
      getTitanPatient({ pharmacyID, patientId, clinicalServiceId }).then(
        (data) => {
          prescriptionPatientImage(data);
        }
      );
    }
    setCurrentIsServiceNotesLoading(false);
    setCurrentIsNmsTimelineLoading(false);
  };

  const getFollowupDetailByClinicalServiceId = (clinicalServiceId) => {
    const currentNmsState = store.getState().nmsService;
    const { value } = currentNmsState || {};
    const stageType = stageTypes[value];
    getFollowUpByServiceIdAndServiceType(clinicalServiceId, "1", stageType);
  };

  const getPatientByTelephoneAndStageIndex = (telephone, serviceId) => {
    const currentNmsState = store.getState().nmsService;
    const { value } = currentNmsState || {};
    let currentAppState = store.getState().app;
    const { isOrbitUser } = currentAppState || {};
    getPatientByTelephoneAndStageIndexResult(
      telephone,
      value,
      serviceId,
      currentSessionId,
      isOrbitUser
    );
    setCurrentIsPatientNmsTelephoneLoading(false);
  };

  const getServiceNotesByClinicalServiceId = (clinicalServiceId) => {
    const currentNmsState = store.getState().nmsService;
    const { value } = currentNmsState || {};
    const stageNo = value;
    const stageType = stageTypes[stageNo];

    getServiceNotes({ clinicalServiceId, stageType });
  };

  const getTimelineDataForClinicalServiceId = (serviceId) => {
    setCurrentIsNmsTimelineLoading(true);
    let currentAppState = store.getState().app;
    const { isOrbitUser, isOrbitSwitch } = currentAppState;
    const currentNmsState = store.getState().nmsService;
    const { clinicalServiceId } = currentNmsState || {};
    getTimeline(isOrbitUser || isOrbitSwitch ? clinicalServiceId : serviceId);
  };
  const prescriptionImageData = async (data, pharmacyID) => {
    if (data) {
      const { prescriptionGetModel } = data || {};
      const { prescriptionImages } = prescriptionGetModel ?? [];
      const resultArray = [];

      if (prescriptionImages) {
        for (const imageValue of prescriptionImages) {
          const result = await getPatientImage({
            imageValue,
            pharmacyID,
          });
          resultArray.push(`data:image/png;base64, ${result}`);
        }
        if (resultArray?.length > 0) {
          setCurrentArrayImg(resultArray);
        } else {
          setCurrentArrayImg([]);
        }
      }
    }
    setCurrentIsNmsPrescriptionLoading(false);
  };

  const prescriptionPatientImage = async (patientData) => {
    if (patientData) {
      const currentNmsState = store.getState().nmsService;
      const { prescriptionImages, pharmacyID } = currentNmsState || {};
      const { patientGetModel } = patientData || {};
      const patientName = `${patientGetModel.title} ${patientGetModel.forename} ${patientGetModel.middleName} ${patientGetModel.surname}`;
      setCurrentPatientName(patientName);
    }
    setCurrentIsNmsPatientLoading(false);
  };

  return (
    <div>
      {patientNmsData.length > 1 ? (
        <div className="grouped_patient_wrapper">
          <p className="label">Patients with the same tel number</p>
          <div className="grouped_patient_container">
            {patientNmsData.map((patient, index) =>
              isNmsPatientTelephoneLoading ? (
                <Skeleton
                  sx={{ bgcolor: darkMode ? "grey.800" : "" }}
                  height={60}
                  width={150}
                  animation="wave"
                  variant="rounded"
                />
              ) : (
                <div
                  className={`grouped_patient ${
                    clinicalServiceId === patient?._id ? "active" : ""
                  }`}
                  key={patient._id}
                  onClick={() => {
                    setCurrentTempClinicalServiceId(patient?._id);
                    setCurrentGroupedPatientWarning(true);
                  }}
                >
                  <p className="label">Name</p>
                  <p className="name">
                    {patient.patientName} (
                    {new Date().getFullYear() -
                      new Date(patient.dob).getFullYear()}
                    )
                  </p>
                </div>
              )
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
