import React from "react";
import { ReactComponent as AddNewSvg } from "./../../../PatientDashboardIcons/addNew.svg";
import { ReactComponent as MenuSvg } from "./../../../../../pages/Dashboard/OverviewTab/OverviewIcons/menu.svg";
import { ReactComponent as CalenderSvg } from "./../../../../../pages/Dashboard/OverviewTab/OverviewIcons/calendar.svg";

export default function Problems() {
  return (
    <div className="card-shaped-box">
      <div className="tasks_header summary_header">
        <h3 className="pharmacy_header_name">Problems</h3>
        <AddNewSvg />
      </div>
      <div className="card-shaped-content">
        <p className="description">
          There have been 2 self-reported problems reported to a pharmacist
          since 2021
        </p>
        <div className="inner_container">
          <div className="title">
            <p>Anxiety about work</p>
            <MenuSvg />
          </div>
          <p className="pain">Chronic | Significant</p>
          <div className="calender">
            <CalenderSvg />
            <p>7th June 2023 (11:02am)</p>
          </div>
        </div>
      </div>
    </div>
  );
}
