import React, { useState } from "react";
import { ReactComponent as CheckmarkSvg } from "./../OverviewIcons/checkmark.svg";
import { ReactComponent as MenuSvg } from "./../OverviewIcons/menu.svg";
import { BsCircle } from "react-icons/bs";

export default function ServicesTasks({
  tabsServices,
  activeTab,
  onDarkModeChange,
}) {
  const [completedTasks, setCompletedTasks] = useState([]);

  const markTaskComplete = (task) => {
    if (!completedTasks.includes(task)) {
      setCompletedTasks((prevCompleted) => [...prevCompleted, task]);
    }
  };

  const filteredTasks = tabsServices?.map((tab) =>
    activeTab === tab.day
      ? {
          ...tab,
          task: tab.task?.filter((task) => !completedTasks.includes(task)),
        }
      : tab
  );

  return (
    <React.Fragment>
      <div className="week-tasks">
        <h6 className="para_color">Tasks</h6>
        {filteredTasks?.map(
          (tab, index) =>
            activeTab === tab.day && (
              <React.Fragment key={index}>
                {tab.task?.map((task,index) => (
                  <div
                    key={task.id+index}
                    className={`task-item ${
                      completedTasks.includes(task) ? "completed" : ""
                    }`}
                    onClick={() => markTaskComplete(task)}
                  >
                    <div className="task-item-top">
                      <div>
                        <BsCircle size={16} className="icons-color" />

                        <p>{task.title}</p>
                      </div>
                      <div>
                        {task.priority ? (
                          <p className="priority">{task.priority}</p>
                        ) : (
                          ""
                        )}
                        <MenuSvg />
                      </div>
                    </div>
                    <div className="task-item-desc">
                      <p>{task.description}</p>
                    </div>
                  </div>
                ))}
              </React.Fragment>
            )
        )}
      </div>

      <div className="completed-tasks">
        {completedTasks?.map((task, index) => (
          <div key={task.id+index} className="completed-task">
            <div className="task-item-top">
              <div>
                <CheckmarkSvg />
                <p className="completed-text">{task.title}</p>
              </div>
              <div>
                {task.priority ? (
                  <p className="priority">{task.priority}</p>
                ) : (
                  ""
                )}
                <MenuSvg />
              </div>
            </div>
            <div className="task-item-desc">
              <p className="completed-text">{task.description}</p>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}
