import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

export default function MembersNotificationSettings({
  addedNotification,
  setAddedNotification,
}) {
  const [isTaskAssigned, setIsTaskAssigned] = useState(true);
  const [isTaskUpdated, setIsTaskUpdated] = useState(true);
  const [labResultsReceived, setLabResultsReceived] = useState(true);
  const [isBookingMade, setIsBookingMade] = useState(true);

  const handleChange = (event) => {
    const { name, checked } = event?.target || {};
    setAddedNotification({
      ...addedNotification,
      [name]: checked,
    });
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  return (
    <div className="notification_setting_wrapper">
      <div className="switch_row">
        <p>Receive notification when task is assigned to you</p>
        <AntSwitch
          name="taskAssignNotification"
          checked={addedNotification?.taskAssignNotification}
          onChange={handleChange}
        />
      </div>
      <div className="switch_row">
        <p>Receive notification when task assigned to you is updated</p>
        <AntSwitch
          name="taskUpdateNotification"
          checked={addedNotification?.taskUpdateNotification}
          onChange={handleChange}
        />
      </div>
      <div className="switch_row">
        <p>Receive notification when lab results are received</p>
        <AntSwitch
          name="labResultNotification"
          checked={addedNotification?.labResultNotification}
          onChange={handleChange}
        />
      </div>
      <div className="switch_row">
        <p>Receive notification when booking has been made</p>
        <AntSwitch
          name="newBookingNotification"
          checked={addedNotification?.newBookingNotification}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
