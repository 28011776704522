import React, { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import ImageUploading from "react-images-uploading";
import { IoCloudUploadSharp } from "react-icons/io5";
import { GrUpdate, GrTrash } from "react-icons/gr";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";

export default function MembersUserDetails({
  onDarkModeChange,
  addedMember,
  handleChangeMember,
}) {
  const [images, setImages] = React.useState(
    addedMember?.userImage !== ""
      ? [
          {
            data_url: addedMember?.userImage,
          },
        ]
      : []
  );
  useEffect(() => {
    setImages(
      addedMember?.userImage !== ""
        ? [
            {
              data_url: addedMember?.userImage,
            },
          ]
        : []
    );
  }, []);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    if (imageList?.length > 0) {
      handleChangeMember({
        ...addedMember,
        userImage: imageList[0]?.data_url,
      });
    } else {
      handleChangeMember({
        ...addedMember,
        userImage: "",
      });
    }
  };

  const [isClinician, setIsClinician] = useState(true);

  const handleIsClinicianChange = (event) => {
    const { name, checked } = event?.target || {};
    setIsClinician(checked);
    handleChangeMember({
      ...addedMember,
      [name]: checked,
    });
  };
  function handleChangeDetail(event) {
    const { name, value } = event?.target || {};
    handleChangeMember({
      ...addedMember,
      [name]: value,
    });
  }

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  return (
    <>
      <div className="title_field">
        <FormControl fullwidth>
          <InputLabel id="demo-simple-select-filled-label">Title</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            name="title"
            value={addedMember?.title}
            onChange={handleChangeDetail}
            className="title-select"
          >
            <MenuItem value={0}>None</MenuItem>
            <MenuItem value={1}>Mr</MenuItem>
            <MenuItem value={2}>Mrs</MenuItem>
            <MenuItem value={3}>Miss</MenuItem>
            <MenuItem value={4}>Ms</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="settings_formFields">
        <TextField
          id="outlined-basic"
          label="First Name"
          variant="outlined"
          fullwidth
          name="firstName"
          value={addedMember?.firstName || ""}
          onChange={handleChangeDetail}
        />

        <TextField
          id="outlined-basic"
          label="Last Name"
          variant="outlined"
          fullwidth
          name="lastName"
          value={addedMember?.lastName || ""}
          onChange={handleChangeDetail}
        />
      </div>
      <div className="userDetails_fields">
        <div>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            fullwidth
            name="emailAddress"
            value={addedMember?.emailAddress || ""}
            onChange={handleChangeDetail}
          />

          <div className="user_clinician">
            <p>This user is a clinician</p>
            <AntSwitch
              name="isClinician"
              checked={addedMember?.isClinician}
              onChange={handleIsClinicianChange}
            />
          </div>
          <TextField
            id="outlined-basic"
            label="Qualifications"
            variant="outlined"
            fullwidth
            name="qualification"
            value={addedMember?.qualification || ""}
            onChange={handleChangeDetail}
          />

          <TextField
            id="outlined-basic"
            label="Registrations"
            variant="outlined"
            fullwidth
            name="registrations"
            value={addedMember?.registrations || ""}
            onChange={handleChangeDetail}
          />
        </div>
        <ImageUploading
          multiple
          value={images}
          onChange={onChange}
          maxNumber={maxNumber}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            // write your building UI
            <div className="upload__image-wrapper">
              {addedMember?.userImage !== "" ? (
                <div className="image-item">
                  <div className="uploaded-img">
                    <img src={addedMember?.userImage} alt="" />
                  </div>

                  <div className="image-item__btn-wrapper">
                    <button onClick={() => onImageUpdate(images[0])}>
                      <GrUpdate
                        size={20}
                        color={onDarkModeChange ? "#fff" : "#000"}
                      />
                    </button>
                    <button onClick={() => onImageRemove(images[0])}>
                      <GrTrash size={20} className="icons-color" />
                    </button>
                  </div>
                </div>
              ) : (
                <div className="controls" onClick={onImageUpload}>
                  <IoCloudUploadSharp size={80} className="icons-color" />
                  <button className="upload-btn">
                    <p>Upload your image here</p>
                  </button>
                </div>
              )}
            </div>
          )}
        </ImageUploading>
      </div>
    </>
  );
}
