import React, { useState, useRef } from "react";
import "./../../../../../assets/css/Dashboard/ServicesDocuments.scss";
import setAddDocument from "../../../../../Model/Documents/setAddDocument";
import "react-toastify/dist/ReactToastify.css";
import useTitanApp from "../../../../../hooks/useTitanApp";
import { showError } from "../../../../../components/ToastedMessage/ToastedMessage";
import { addDocument } from "../../../../../dispatchers/documentDispatchers";

const UploadFile = ({ handleAddDocument }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [validationError, setValidationError] = useState("");
  const { appState, pharmacyServiceState, auth } = useTitanApp();
  const { user } = auth || {};
  const userFullName = user?.name;
  const { currentPharmacyId } = appState || {};
  const { currentServiceId } = pharmacyServiceState || {};

  const errorMessage = () => {
    showError("Something went wrong", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  async function addDocumentResponse(obj) {
    addDocument(obj)
      .then((data) => {
        if (data?.responseContent !== "Document created") {
          showError(data);
        } else {
          handleAddDocument();
        }

        return data;
      })
      .catch(() => {
        errorMessage();
      });
  }

  const addDocumentData = async (obj) => {
    try {
      if (!navigator.onLine) {
        errorMessage();
        return;
      }

      await addDocumentResponse(obj);
    } catch (error) {}
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = e?.dataTransfer?.files;
    if (files[0]) {
      const fileSizeInMB = files[0].size / (1024 * 1024);
      if (fileSizeInMB > 10) {
        setValidationError(
          "File size exceeds 10MB. Please choose a smaller file."
        );
        return;
      }
      try {
        const DocumentTitle = files[0].name.replace(/\.[^/.]+$/, "");
        setSelectedFile(files[0]);
        const base64String = await convertFileToBase64(files[0]);
        const obj = new setAddDocument(
          files[0].name,
          DocumentTitle,
          currentPharmacyId,
          files[0].type,
          base64String,
          currentServiceId,
          userFullName
        ).obj;
        await addDocumentData(obj);
        setSelectedFile(null);
      } catch (error) {
        console.error("Error converting file to base64:", error);
      }
    }
  };

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = async (e) => {
    const files = e?.target?.files;
    if (files[0]) {
      const fileSizeInMB = files[0].size / (1024 * 1024);
      if (fileSizeInMB > 10) {
        setValidationError(
          "File size exceeds 10MB. Please choose a smaller file."
        );
        return;
      }
      const DocumentTitle = files[0].name.replace(/\.[^/.]+$/, "");
      setSelectedFile(files[0]);
      try {
        const base64String = await convertFileToBase64(files[0]);
        const obj = new setAddDocument(
          files[0].name,
          DocumentTitle,
          currentPharmacyId,
          files[0].type,
          base64String,
          currentServiceId,
          userFullName
        ).obj;
        await addDocumentData(obj);
        setSelectedFile(null);
      } catch (error) {
        console.error("Error converting file to base64:", error);
      }
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result.split(",")[1]; // Extract base64 part after comma
        resolve(base64Data);
      };
      reader.onerror = () => reject(new Error("Failed to read the file"));
      reader.readAsDataURL(file);
    });
  };

  const uploadIcon = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 13 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 7.75C12.7455 7.75 12.9496 7.92688 12.9919 8.16012L13 8.25V14.25C13 15.5755 11.9685 16.66 10.6644 16.7447L10.5 16.75H2.5C1.17452 16.75 0.0899613 15.7185 0.00531769 14.4144L0 14.25V8.25C0 7.97386 0.223858 7.75 0.5 7.75C0.74546 7.75 0.949608 7.92688 0.991944 8.16012L1 8.25V14.25C1 15.0297 1.59489 15.6704 2.35554 15.7431L2.5 15.75H10.5C11.2797 15.75 11.9204 15.1551 11.9931 14.3945L12 14.25V8.25C12 7.97386 12.2239 7.75 12.5 7.75ZM1.90381 4.63909L6.14645 0.396447C6.32001 0.22288 6.58944 0.203595 6.78431 0.338591L6.85355 0.396447L11.0962 4.63909C11.2915 4.83435 11.2915 5.15093 11.0962 5.34619C10.9226 5.51976 10.6532 5.53905 10.4583 5.40405L10.3891 5.34619L7 1.957V11.25C7 11.4955 6.82312 11.6996 6.58988 11.7419L6.5 11.75C6.25454 11.75 6.05039 11.5731 6.00806 11.3399L6 11.25V1.957L2.61091 5.34619C2.43735 5.51976 2.16792 5.53905 1.97305 5.40405L1.90381 5.34619C1.73024 5.17263 1.71095 4.9032 1.84595 4.70834L1.90381 4.63909L6.14645 0.396447L1.90381 4.63909Z"
        className="icons-color"
      />
    </svg>
  );

  return (
    <div className="upload_file_Wrapper">
      <div
        className={`file-drop-zone ${isDragging ? "dragging" : ""}`}
        onDragEnter={handleDragEnter}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleFileInputClick}
      >
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          multiple
          onChange={handleFileInputChange}
        />
        {selectedFile ? (
          <p>{selectedFile.name}</p>
        ) : (
          <div className="files_details">
            <div>
              {isDragging ? (
                <i>Drop files here</i>
              ) : (
                <i>{uploadIcon} Drop files here to attach or browse</i>
              )}
            </div>
            {validationError ? (
              <div className="file_validation_error">{validationError}</div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadFile;
