import React from "react";
import { AgGridReact } from "ag-grid-react";
import { Skeleton } from "@mui/material";
import "@mui/icons-material/QueryBuilder";
import useTitanApp from "../../../../../hooks/useTitanApp";

const SkeletonTable = () => {
  const { appState } = useTitanApp();
  const { darkMode } = appState || {};

  const gridOptions = {
    columnDefs: [
      {
        headerName: "Drug",
        field: "drug",
        cellRenderer: () => <SkeletonCellRenderer />,
      },
      {
        headerName: "Dosage",
        field: "dosage",
        cellRenderer: () => <SkeletonCellRenderer />,
      },
      {
        headerName: "Due",
        field: "due",
        cellRenderer: () => <SkeletonCellRenderer />,
      },
      {
        headerName: "Tags",
        field: "patientTags",
        cellRenderer: () => <SkeletonCellRenderer />,
      },
      {
        headerName: "Handed Out Date",
        field: "handedOutDate",
        cellRenderer: () => <SkeletonCellRenderer />,
      },
      {
        headerName: "Consented",
        field: "consented",
        cellRenderer: () => <SkeletonCellRenderer />,
      },
      {
        headerName: "Has Telephone Number",
        field: "hasTelephoneNumber",
        cellRenderer: () => <SkeletonCellRenderer />,
      },
    ],
    rowData: new Array(20).fill(null), // Creates 15 empty rows
    defaultColDef: {
      resizable: true,
    },
  };

  return (
    <div
      className={`listview-grid listview-skeleton-grid ${
        darkMode ? "ag-theme-quartz-dark" : "ag-theme-quartz"
      }`}
    >
      <AgGridReact gridOptions={gridOptions} />
    </div>
  );
};

const SkeletonCellRenderer = ({ value }) => {
  const { appState } = useTitanApp();
  const { darkMode } = appState || {};

  return (
    <Skeleton animation="wave" sx={{ bgcolor: darkMode ? "grey.800" : "" }} />
  );
};

export default SkeletonTable;
