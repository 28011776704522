import React, { useState } from "react";
import { Checkbox } from "primereact/checkbox";
import "./../../../../../../../../assets/css/Dashboard/Claim.scss";

const CustomCheckBox = ({ value }) => {
  const [checked, setChecked] = useState();

  return (
    <div className="CustomCheckBox">
      <Checkbox
        className={checked ? "custom-checked" : "custom-unchecked"}
        onChange={(e) => setChecked(e.checked)}
        checked={checked}
      ></Checkbox>
    </div>
  );
};

export default CustomCheckBox;
