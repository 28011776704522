import React, { useState } from "react";
import "./../../assets/css/AddTeam.scss";
import { FaPlus } from "react-icons/fa";
import { BsCommand } from "react-icons/bs";
import AddTeamModal from "./AddTeamModal";
import useTitanApp from "../../hooks/useTitanApp";

export default function AddTeam(props) {
  const { appState } = useTitanApp();
  const { darkMode } = appState || {};

  return (
    <div>
      <div className="addTeam_icon-box">
        <div
          className="addTeam_icon label-btn"
          onClick={() => props.toggleTeamsDialog("add")}
        >
          <FaPlus size={10} className="icons-box" />
        </div>

        <div className="addTeam_text-box label-btn">
          <p>Add Team</p>
        </div>
      </div>
      <AddTeamModal
        teamsFormik={props.teamsFormik}
        onDarkModeChange={darkMode}
        handleCallback={props.handleCallback}
        clickedTeamIndex = {props.clickedTeamIndex}
      />
    </div>
  );
}
