import React from "react";
import { ReactComponent as ProcessingSVG } from "./../../../assets/images/processApp.svg";
import { useNavigate } from "react-router-dom";
import { showError } from "../../ToastedMessage/ToastedMessage";
import {
  setYotiSteps,
  setProcessingApp,
  setOrbitGPHC,
  setOnboardingSuccess,
} from "../../../dispatchers/onboardingVerificationDsipatchers";
import useTitanApp from "../../../hooks/useTitanApp";
import OnboardingSuccess from "../../OnboardingSuccess/OnboardingSuccess";
import { checkYotiDataBySessionId } from "../../../dispatchers/pharmacyServiceDispatchers";

export default function ProcessingApp({ root }) {
  let navigate = useNavigate();
  const { onboardingVerificationState, pharmacyServiceState } = useTitanApp();
  const { linkLoading } = onboardingVerificationState || {};
  const { isYotiSuccess, yotiSessionDetail } = pharmacyServiceState || {};
  const { sessionId } = yotiSessionDetail || {};

  const handleTryAgain = async () => {
    try {
      var data = await checkYotiDataBySessionId(sessionId);
      if (data === true) {
        setProcessingApp(false);
        setYotiSteps(false);
        setOnboardingSuccess(true);
        setOrbitGPHC(false);
      } else {
        setProcessingApp(false);
        setYotiSteps(false);
        setOnboardingSuccess(false);
        setOrbitGPHC(true);
      }
    } catch (errors) {
      showError("User Details could not be updated.", true, false);
    }
  };

  return (
    <div className={`user_signup ${isYotiSuccess ? "" : "processing_app"}`}>
      {isYotiSuccess ? (
        <OnboardingSuccess root={root} />
      ) : (
        <div className="text_wrapper">
          <ProcessingSVG className={linkLoading ? "rotating_cog" : ""} />
          <h4>We’re processing your application...</h4>
          <p>
            We’ve received your application to join the TITAN VERSE. We're
            validating your registration and your documents. This normally takes
            a few minutes
          </p>
          {linkLoading ? (
            <p className="loading-dots">Please wait</p>
          ) : (
            <a href="#" onClick={handleTryAgain}>
              Try Again
            </a>
          )}
        </div>
      )}
    </div>
  );
}
