import { getObject } from "../getObjectValue";
class selectedUnit {
  constructor(response) {
    this.obj = {
      code: getObject(response, "code"),
      description: getObject(response, "description"),
    };
  }
}

export default selectedUnit;
