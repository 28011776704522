import { dispatch } from "../store/store";
import { logError } from "../services/logService";
import {
  setUserData,
  setIsOnboardingVerificationLoading,
  getUserData,
  setImage,
  updateUserByUserEmailData,
  updateIsActiveByUserEmailData,
  updateIsVerifyByUserEmailData,
  updateIsActiveByUserEmailDataCode,
  getGphcNumberData,
  getEmailByInvitationCodeData,
  getUserForOrbitData,
  resendEmailToUserData,
  userChangePasswordData,
  setOrbitGPHCData,
  setLinkLoadingData,
  setProcessingAppData,
  setYotiStepsData,
  setYotiToolsNeededData,
  setEmailVerificationData,
  setSignupFormData,
  setOnboardingSuccessData,
} from "../features/onboardingVerificationSlice/onboardingVerificationSlice";

const reducerName = "onboardingVerification";

export const setUser = (data) => {
  dispatch(setUserData(data));
};
export const setProfileImage = ([profileImage]) => {
  dispatch(setImage(profileImage));
};
export const setOnboardingVerificationLoading = (isLoading) => {
  dispatch(setIsOnboardingVerificationLoading(isLoading));
};
export const setOrbitGPHC = (data) => {
  dispatch(setOrbitGPHCData(data));
};
export const setLinkLoading = (data) => {
  dispatch(setLinkLoadingData(data));
};
export const setProcessingApp = (data) => {
  dispatch(setProcessingAppData(data));
};
export const setYotiSteps = (data) => {
  dispatch(setYotiStepsData(data));
};
export const setEmailVerification = (data) => {
  dispatch(setEmailVerificationData(data));
};
export const setSignupForm = (data) => {
  dispatch(setSignupFormData(data));
};
export const setOnboardingSuccess = (data) => {
  dispatch(setOnboardingSuccessData(data));
};

export const getUser = async (userEmail) => {
  let result = [];
  try {
    result = await dispatch(getUserData(userEmail)).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const getEmailByInvitationCode = async (invitationCode) => {
  let result = [];
  try {
    result = await dispatch(
      getEmailByInvitationCodeData(invitationCode)
    ).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};
export const getUserForOrbit = async () => {
  let result = [];
  try {
    result = await dispatch(getUserForOrbitData()).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};
export const getGphcNumber = async (gphcNumber) => {
  let result = [];
  try {
    result = await dispatch(getGphcNumberData(gphcNumber)).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const updateUserByUserEmail = async (userData, userEmail) => {
  let result = [];
  try {
    result = await dispatch(
      updateUserByUserEmailData({ userData, userEmail })
    ).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};
export const resendEmailToUser = async (userEmail) => {
  let result = [];
  try {
    result = await dispatch(resendEmailToUserData(userEmail)).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};
export const userChangePassword = async (userEmail) => {
  let result = [];
  try {
    result = await dispatch(userChangePasswordData(userEmail)).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const updateIsActiveByUserEmail = async (userEmail, code) => {
  let result = [];
  try {
    result = await dispatch(
      updateIsActiveByUserEmailData({ userEmail, code })
    ).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const updateIsActiveByUserEmailCode = async (code) => {
  let result = [];
  try {
    result = await dispatch(
      updateIsActiveByUserEmailDataCode({ code })
    ).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const updateIsVerifyByUserEmail = async (userEmail, userData) => {
  let result = [];
  try {
    result = await dispatch(
      updateIsVerifyByUserEmailData({ userEmail, userData })
    ).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};
