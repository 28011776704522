import React, { useEffect } from "react";
import "./../../../../assets/css/Dashboard/ServicesKanban.scss";
import { ImWarning } from "react-icons/im";
import {
  setCurrentPatientContact,
  setCurrentContactServiceId,
} from "../../../../dispatchers/pharmacyServiceDispatchers";

export default function PatientTelephone({ id, index }) {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setCurrentPatientContact(false);
        setCurrentContactServiceId("");
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    <div
      key={index}
      className="service_contact_no"
      onClick={(e) => {
        e.stopPropagation();
        setCurrentPatientContact(true);
        setCurrentContactServiceId(id);
      }}
    >
      <ImWarning size={16} color={"red"} />
      <p>Patient doesn't have mobile number</p>
    </div>
  );
}
