import { getObject } from "../getObjectValue";

class notesprescriptionItems {
  constructor(response) {
    this.obj = response?.map((value) => {
      return {
        prescriptionItemNoteId: getObject(value, "prescriptionItemNoteId"),
        prescriptionItemId: getObject(value, "prescriptionItemId"),
        note: getObject(value, "note"),
        awaitingQuery: getObject(value, "awaitingQuery"),
        showOnEntry: getObject(value, "showOnEntry"),
        showOnPicking: getObject(value, "showOnPicking"),
        showOnLabelling: getObject(value, "showOnLabelling"),
        showOnHandout: getObject(value, "showOnHandout"),
        archiveDate: getObject(value, "archiveDate"),
        pinNote: getObject(value, "pinNote"),
        createdOn: getObject(value, "createdOn"),
        needCheck: getObject(value, "needCheck"),
      };
    });
  }
}

export default notesprescriptionItems;
