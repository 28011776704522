import React, { useState } from "react";
import { GoArrowLeft } from "react-icons/go";
import MembersUserDetails from "./components/MembersUserDetails";
import MembersUserAccessRole from "./components/MembersUserAccessRole";
import MembersServicesProvided from "./components/MembersServicesProvided";
import MembersNotificationSettings from "./components/MembersNotificationSettings";
import CustomButton from "./../../../../../../controls/CustomButton";
import { v4 as uuidv4 } from "uuid";
import useTitanApp from "../../../../../../../hooks/useTitanApp";
import SettingsSidebar from "../../../../../../SettingsSidebar/SettingsSidebar";
import { useNavigate, useLoaderData } from "react-router-dom";
import CustomLoader from "../../../../../../controls/CustomLoader";
import AsyncRendererRouter from "../../../../../../AsyncRendererRouter/AsyncRendererRouter";
import { addMember } from "../../../../../../../dispatchers/memberDispatchers";
import { showError } from "../../../../../../ToastedMessage/ToastedMessage";
import Schedule from "../../../../../../OrbitSettings/Availability/components/Schedule";
import MembersAddNewLoadingComponent from "../../../../../../SettingsLoadingComponents/MembersAddNewLoadingComponent";
export default function MembersAddNew() {
  const [addedRole, setAddedRole] = useState();
  const [addedService, setAddedSevice] = useState([]);
  const [addedSlots, setAddedSlots] = useState([]);
  const [addedNotification, setAddedNotification] = useState({
    taskAssignNotification: false,
    taskUpdateNotification: false,
    labResultNotification: false,
    newBookingNotification: false,
  });

  const { appState, memberState } = useTitanApp();
  const { isMemberLoading } = memberState || {};
  const { companyId, darkMode, features } = appState || {
    companyId: "00000000-0000-0000-0000-000000000000",
  };
  const { ifSettingMinimal } = features || {};
  const navigate = useNavigate();
  const [addedMember, setAddedMember] = useState({
    userId: uuidv4(),
    companyID: companyId,
    emailAddress: "",
    firstName: "",
    gender: 0,
    lastName: "",
    userImage: "",
    isNew: true,
    title: 0,
    isClinician: false,
    qualification: "",
    registrations: "",
    accountType: 1,
  });

  function handleChangeMember(member) {
    setAddedMember(member);
  }

  function handleChangeServices(services) {
    setAddedSevice(services);
  }

  const handleAddRole = () => {
    const dataModel = {
      ...addedMember,
      role: addedRole,
      services: addedService,
      ...addedNotification,
      userWeekAvailability: addedSlots,
    };
    const savedMember = addMember(dataModel);
    savedMember.then((result) => {
      if (result) {
        navigate("/settings/teams/members");
      } else {
        showError("Member could not be added.", true, false);
      }
    });
  };
  function handleAddedSlots(data) {
    setAddedSlots(data);
  }
  const { appData } = useLoaderData() || {};

  return (
    <AsyncRendererRouter
      isLoading={isMemberLoading}
      resolve={appData}
      loadingElement={<MembersAddNewLoadingComponent />}
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <SettingsSidebar onDarkModeChange={darkMode} />
          <div className="settings_content_wrapper">
            <div className="settings_heading">
              <div>
                <div
                  className="back_to_SettingsListView"
                  onClick={() => {
                    navigate("/settings/teams/members");
                  }}
                >
                  <GoArrowLeft className="icons-color" />
                  <p>Back</p>
                </div>
                <div className="addNewMember_wrapper">
                  <h3></h3>
                  <p></p>
                </div>
                <div className="addMember_formFields">
                  <div className="inner-panels">
                    <div className="AddNew_sectionbox">
                      <h4>User Detail</h4>
                      <MembersUserDetails
                        onDarkModeChange={darkMode}
                        addedMember={addedMember}
                        handleChangeMember={handleChangeMember}
                      />
                    </div>
                    {!ifSettingMinimal && (
                      <div className="AddNew_sectionbox">
                        <h4>User Access Role</h4>
                        <MembersUserAccessRole setAddedRole={setAddedRole} />
                      </div>
                    )}
                    {!ifSettingMinimal && (
                      <div className="AddNew_sectionbox">
                        <h4>Services Provided</h4>
                        <MembersServicesProvided
                          addedService={addedService}
                          handleChangeServices={handleChangeServices}
                        />
                      </div>
                    )}
                    {!ifSettingMinimal && (
                      <div className="AddNew_sectionbox">
                        <h4>Notification Settings</h4>
                        <MembersNotificationSettings
                          addedNotification={addedNotification}
                          setAddedNotification={setAddedNotification}
                        />
                      </div>
                    )}
                    {!ifSettingMinimal && (
                      <div className="AddNew_sectionbox">
                        <h4>Availability</h4>
                        <Schedule
                          addedSlots={addedSlots}
                          handleAddedSlots={handleAddedSlots}
                        />
                      </div>
                    )}
                    <div className="footer_btn">
                      <CustomButton
                        label="Save Changes"
                        action={handleAddRole}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
