import React from "react";
import OrbitSettingsSidebar from "../../OrbitSettingsSidebar/OrbitSettingsSidebar";
import AccountDetails from "./components/AccountDetails";
import AsyncRendererRouter from "../../AsyncRendererRouter/AsyncRendererRouter";
import { useLoaderData } from "react-router-dom";
import useTitanApp from "../../../hooks/useTitanApp";
import CustomLoader from "../../controls/CustomLoader";
import {
  saveOrbitProfile,
  setCurrentPayData,
  setCurrentOrbitProfile,
} from "../../../dispatchers/orbitDispatchers";
import { showError } from "../../ToastedMessage/ToastedMessage";
import CustomButton from "../../controls/CustomButton";
import useTimer from "../../../hooks/useTimer";
function PaymentDetails() {
  const { timer, isActive, handleStart, handleReset } = useTimer();
  const { orbitState } = useTitanApp();
  const { isOrbitLoading, orbitProfile, payDetails } = orbitState || {};
  const { appData } = useLoaderData();
  function handleChangeDetails(event) {
    const { name, value } = event?.target || {};
    setCurrentPayData({
      ...payDetails,
      [name]: value,
    });
  }

  const handleSwitchChange = (event) => {
    const { name, checked } = event?.target || {};
    setCurrentPayData({
      ...payDetails,
      [name]: checked,
    });
  };
  function handleSave() {
    const obj = {
      ...orbitProfile,
      paymentDetails: payDetails,
    };
    saveOrbitProfile(obj)
      .then((result) => {
        setCurrentOrbitProfile(result);
      })
      .catch(() =>
        showError("Payment Details could not be added.", true, false)
      );
  }
  return (
    <AsyncRendererRouter
      isLoading={isOrbitLoading}
      resolve={appData}
      loadingElement={
        <div className="settings_page">
          <div className="settings_wrapper">
            <OrbitSettingsSidebar />
            <div className="settings_content_wrapper orbit_settings_content_wrapper">
              <CustomLoader />
            </div>
          </div>
        </div>
      }
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <OrbitSettingsSidebar />
          <div className="settings_content_wrapper">
            <div className="settings_heading">
              <h5>Payment Details</h5>
            </div>
            <div className="AddNew_sectionbox">
              <AccountDetails
                paymentDetails={payDetails}
                handleChangeDetails={handleChangeDetails}
                handleSwitchChange={handleSwitchChange}
              />
            </div>
            <div className="footer_btn">
              <CustomButton label="Save" action={handleSave} />
            </div>
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}

export default PaymentDetails;
