import React, { useEffect } from "react";
import { HiClock } from "react-icons/hi";
import { RxCaretRight } from "react-icons/rx";

export default function NextupAppointment({
  nextupAppointments,
  onDarkModeChange,
}) {
  return (
    <div className="next-up-today">
      <h3 className="para_color">Next up today</h3>
      <div className="calender-wrapper">
        <div className="vertical-line"></div>
        <div className="next-up-top">
          <HiClock size={16} color="#999" />
          <p className="time">12:45pm</p>
          <p className="apt-type">Next appointment</p>
        </div>
        <div className="appointments">
          {nextupAppointments?.map((appointment, index) => (
            <div className="appointment" key={index}>
              <p className="name">{appointment.name}</p>
              <p className="service">{appointment.service}</p>
              <p className="parentService">{appointment.parentService}</p>
            </div>
          ))}

          <div className="total-appointments">
            <div className="circle"></div>
            <p>{nextupAppointments?.length} appointments on the Calender</p>
            <RxCaretRight color="#b3b3b3" />
          </div>
        </div>

        <div className="next-up-bottom">
          <HiClock size={16} color="#999" />
          <p className="time">08:00pm</p>
          <p className="apt-type">Last appointment</p>
        </div>
      </div>
    </div>
  );
}
