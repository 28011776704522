import React from "react";

export default function Hearing() {
  return (
    <div className="card-shaped-box">
      <div className="tasks_header">
        <h3 className="pharmacy_header_name">Hearing</h3>
      </div>
      <div className="card-shaped-content">
        <p className="description">Jessicas hearing health is okay.</p>

        <div className="heart-boxes">
          <div className="heart_box">
            <div className="heart_count">
              <p>17</p>
              <p>dB</p>
            </div>
            <div className="heart_desc">
              <p>Environmental Sound</p>
              <p>17 Decibels</p>
            </div>
          </div>
          <div className="heart_box">
            <div className="heart_count green">
              <p>OK</p>
            </div>
            <div className="heart_desc">
              <p>Environmental Sound Level</p>
              <p>7-Day Exposure</p>
            </div>
          </div>
          <div className="heart_box">
            <div className="heart_count yellow">
              <p>Loud</p>
            </div>
            <div className="heart_desc">
              <p>Headphone Audio Levels</p>
              <p>7-Day Exposure</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
