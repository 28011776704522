import React from "react";

function CustomButton({ className, isdisable, action, label }) {
  return (
    <button
      type="submit"
      className={`btn-class ${className}${
        isdisable === true ? " custom-disable" : ""
      }`}
      onClick={action}
      disabled={isdisable}
    >
      {label}
    </button>
  );
}

export default CustomButton;
