import React, { useState } from "react";
import { HiPaperAirplane } from "react-icons/hi2";
import { CgFileDocument } from "react-icons/cg";

import useTitanApp from "../../hooks/useTitanApp";
import {
  addServiceNotes,
  getServiceNotes,
} from "../../dispatchers/nmsDispatchers";
import { getFormattedDate } from "../../utils/FormattedDate";
import moment from "moment/moment";
import LoadingComponentNMSTimeline from "../LoadingComponentNMS/LoadingComponentNMSTimeline";
import { stageTypes } from "../../enum/stageType";
export default function NMSNotes({ count, stageNo }) {
  const { appState, nmsServiceState } = useTitanApp();
  const { darkMode, currentUser } = appState;
  const { clinicalServiceId, isServiceNotesLoading, serviceNotesData } =
    nmsServiceState || {};
  const [notes, setNotes] = useState([]);
  const [comment, setComment] = useState("");
  const [isExceeded, setIsExceeded] = useState(false);

  const handleInputChange = (event) => {
    setIsExceeded(false);
    const inputValue = event.target.value;

    // Check if the length is greater than or equal to the maxLength
    if (inputValue.length <= 999) {
      setComment(inputValue);
    } else {
      setIsExceeded(true);
    }
  };

  const addNewNote = () => {
    const stageType = stageTypes[stageNo];

    const obj = {
      clinicalServiceID: clinicalServiceId,
      notes: comment,
      userName: currentUser.fullName,
      date: new Date(),
      stageType: stageType,
    };
    addServiceNotes(obj).then(() => {
      getServiceNotes({ clinicalServiceId, stageType }).then((data) => {
        if (data) {
          setNotes(data);
          setComment("");
        }
      });
    });
  };

  return isServiceNotesLoading ? (
    <div className="medicine-card customcard">
      <LoadingComponentNMSTimeline />
    </div>
  ) : (
    <div className="parent_template">
      <div className="medicine-card customcard">
        <div className="card-step-number">
          <h2>{count + 1}</h2>
        </div>

        <div className="card-content">
          <div className="card-actions nms-title notes">
            <CgFileDocument size={20} color={darkMode ? "#fff" : "#000"} />

            <h2 className="medicine-card-heading">Notes for this stage</h2>
          </div>
          <div className="notes_container">
            <div className="mapped_notes">
              {serviceNotesData.map((note, index) => {
                let momentdate = moment(note.date);

                let date = momentdate?.format("DD.MM.YY HH:mm");
                return (
                  <div className="note" key={index}>
                    <div className="header">
                      <p>{note.userName}</p>
                      <div className="end">
                        <p>{date}</p>
                      </div>
                    </div>
                    <div className="note-text">
                      <p>{note.notes}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="write_note">
              <textarea
                value={comment}
                onChange={handleInputChange}
                placeholder="Write your comment..."
                maxLength={1000}
                rows={1}
              />
              <div className="post_btn" onClick={addNewNote}>
                <HiPaperAirplane color={darkMode ? "#000" : "#fff"} />
              </div>
            </div>
          </div>
          {isExceeded && (
            <p className="max-word-limit">
              *Your character limit has been reached
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
