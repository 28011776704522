import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpGet, httpPost } from "../../services/httpService";
import {
  getTemplatesUrl,
  saveTemplateUrl,
} from "../../apiUrls/apiUrls";
import { logError } from "../../services/logService";

const initialState = {
  isConsultationLoading: false,
  currentTemplates: [],
  currentTemplate: null,
};

export const getTemplatesData = createAsyncThunk(
  "template/getTemplatesData",
  async (companyId, thunkAPI) => {
    const urlToGet = getTemplatesUrl(companyId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);


export const addTemplateData = createAsyncThunk(
  "template/addTemplateData",
  async (data, thunkAPI) => {
    const respData = await httpPost(saveTemplateUrl(), data);
    return respData;
  }
);

export const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    setIsConsultationLoading: (state, action) => {
      state.isConsultationLoading = action?.payload || false;
    },
    setTemplatesData: (state, action) => {
      state.currentTemplates = action?.payload || [];
    },
    resetConsultationState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTemplatesData.pending, (state) => {
        state.isConsultationLoading = true;
      })
      .addCase(getTemplatesData.fulfilled, (state, action) => {
        state.isConsultationLoading = false;
        state.currentTemplates = action?.payload || [];
      })
      .addCase(getTemplatesData.rejected, (state, action) => {
        state.isConsultationLoading = false;
        state.currentTemplates = [];
      })

      .addCase(addTemplateData.pending, (state) => {
        state.isConsultationLoading = true;
      })
      .addCase(addTemplateData.fulfilled, (state, action) => {
        state.isConsultationLoading = false;
        state.currentTemplate= action?.payload;
      })
      .addCase(addTemplateData.rejected, (state, action) => {
        logError(action?.error);
        state.isConsultationLoading = false;
        state.currentTemplate= null;
      })
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsConsultationLoading,
  setTemplatesData,
  resetConsultationState,
} = templateSlice.actions;

export default templateSlice.reducer;
