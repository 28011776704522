class setAddTeam {
  constructor(
    teamID,
    teamName,
    company_Id,
    displayName,
    pharmacyArray,
    teamImage,
    userFullName,
    userFirstName,
    userLastName,
    userEmail
  ) {
    this.obj = {
      teamID: teamID,

      teamName: teamName,
      companyID: company_Id,

      TeamDisplayName: teamName,
      createdBy: "A91F5AD6-7E14-745F-C40F-601670410C23",

      createdDate: new Date(),

      isActive: true,

      isDeleted: false,

      isGlobalView: false,

      updatedBy: "A91F5AD6-7E14-745F-C40F-601670410C23",

      updatedDate: new Date(),

      ownerName: userFullName,

      ownerId: "A91F5AD6-7E14-745F-C40F-601670410C23",

      teamImage: teamImage,

      pharmacies: pharmacyArray,

      teamMembers: [
        {
          teamMemberID: teamID,
          companyID: company_Id,
          userFullName: userFullName,
          userFirstName: userFirstName,
          userLastName: userLastName,
          teamMemberAccessType: 0,
          teamJoiningStatus: 0,
          joiningDate: new Date(),
          gender: 1,
          email: userEmail,
          isOwner: true,
        },
      ],
    };
  }
}

export default setAddTeam;
