export const ClinicalCheckActionTypes = Object.freeze({
  0: "Not Specified",

  1: "Change prescription type",

  2: "Skip check",

  3: "Change quantity",

  4: "Remove",

  5: "Accept",

  6: "Do not dispense",

  7: "Add patient",

  8: "Edit patient",

  9: "Find patient",

  10: "Enter exemption",

  11: "Find drug",

  12: "Do not dispense",

  13: "Fix",

  14: "Do not dispense",

  15: "View",

  16: "Do not dispense",

  17: "Accept & Add to Dosage",

  18: "Reconcile emergency supplies",

  19: "Add as interim",

  20: "Do not dispense",

  21: "Update patient therapy",

  22: "Do not update patient therapy",

  23: "Restart",

  24: "Add as regular",

  25: "Update repeat quantity",

  26: "Treat as new prescription",

  27: "Create Nms Alert",

  28: "Ignore",

  29: "Add as regular",

  30: "Add as MDS",

  100: "Return to Spine",
});
