import React from "react";
import { ReactComponent as AddNewSvg } from "./../../../PatientDashboardIcons/addNew.svg";

export default function InStorePurchases() {
  return (
    <div className="card-shaped-box">
      <div className="tasks_header summary_header">
        <h3 className="pharmacy_header_name">In-Store Purchases</h3>
        <div className="in-store-money">
          <p>£34.89</p>
        </div>
      </div>
      <div className="card-shaped-content">
        <p className="description">
          There have been 6 purchases in store with average order value of
          £12.32
        </p>
        <div className="in-store-tabs-wrapper">
          <div className="first">
            <p className="day-date">Wednesday, 5 June</p>
            <div className="medicine-box">
              <div className="medicine-title">
                <p className="medicine-name">20x Ibuprofen 100mg</p>
                <p className="medicine-price">£4.89</p>
              </div>
              <p className="medicine-type">Pain reliever</p>
            </div>
            <div className="medicine-box">
              <div className="medicine-title">
                <p className="medicine-name">Trident Sugar Free Chewing Gum</p>
                <p className="medicine-price">£0.99</p>
              </div>
              <p className="medicine-type">Oral Health</p>
            </div>
          </div>
          <div className="second">
            <p className="day-date">Monday, 22 June</p>
            <div className="medicine-box">
              <div className="medicine-title">
                <p className="medicine-name">Vitamin D3</p>
                <p className="medicine-price">£9.00</p>
              </div>
              <p className="medicine-type">Vitamins</p>
            </div>
            <div className="medicine-box">
              <div className="medicine-title">
                <p className="medicine-name">20x Ibuprofen 100mg</p>
                <p className="medicine-price">£4.89</p>
              </div>
              <p className="medicine-type">Pain reliever</p>
            </div>
            <div className="medicine-box">
              <div className="medicine-title">
                <p className="medicine-name">Melatonin 10mg tablets</p>
                <p className="medicine-price">£15.12</p>
              </div>
              <p className="medicine-type">Pain reliever</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
