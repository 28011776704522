import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { CgDanger } from "react-icons/cg";
import { MdOutlineUploadFile } from "react-icons/md";
import TextField from "@mui/material/TextField";
import { Calendar } from "primereact/calendar";
import { FileUpload } from "primereact/fileupload";
import moment from "moment";

export default function IndemnityInsurance({
  expanded,
  handleChange,
  handleChangeDetails,
  Details,
  handleChangeDocument,
}) {
  const [uploadedFile, setUploadedFile] = useState(
    Details
      ? {
          uploadedFiles: Details?.document,
          fileType: Details?.fileType,
          content: Details?.document,
        }
      : null
  );
  const onUpload = (event) => {
    const uploadedFiles = event?.files[0];
    if (uploadedFiles) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader?.result;
        setUploadedFile({
          uploadedFiles,
          fileType: getFileType(uploadedFiles?.name),
          content: reader?.result,
        });
        handleChangeDocument(
          { value: base64Data, name: "document" },
          "indemnityEnsurance",
          getFileType(uploadedFiles?.name)
        );
      };
      reader?.readAsDataURL(uploadedFiles);
    }
  };
  const getFileType = (fileName) => {
    const extension = fileName?.split(".").pop().toLowerCase();
    if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
      return "image";
    } else if (["pdf"].includes(extension)) {
      return "pdf";
    } else if (["doc", "docx"].includes(extension)) {
      return "word";
    } else {
      return "unknown";
    }
  };
  function handleRemoveFiles() {
    handleChangeDocument({ value: "", name: "document" }, "indemnityEnsurance");
    setUploadedFile(null);
  }
  const handleCardClick = async (base64Data) => {
    const response = await fetch(base64Data);
    const blob = await response?.blob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
    URL.revokeObjectURL(url);
  };
  const FileViewer = ({ file }) => {
    if (!file) {
      return null;
    }
    const fileType = file?.fileType;
    if (fileType === "image") {
      return (
        <div onClick={() => handleCardClick(file?.content)}>
          {renderDocumentIcon(2)}
        </div>
      );
    } else if (fileType === "pdf") {
      return (
        <div onClick={() => handleCardClick(file?.content)}>
          {renderDocumentIcon(0)}
        </div>
      );
    } else if (fileType === "word") {
      return (
        <div onClick={() => handleCardClick(file?.content)}>
          {renderDocumentIcon(1)}
        </div>
      );
    } else {
      return <p>{fileType !== "" ? "Unsupported file type" : ""}</p>;
    }
  };
  const renderDocumentIcon = (type) => {
    switch (type) {
      case 0:
        return (
          <img
            src="https://www.svgrepo.com/download/144578/pdf.svg"
            className="pdf-icon"
            width={40}
            height={40}
          />
        );
      case 1:
        return (
          <img
            src="https://img.freepik.com/free-icon/word_318-674258.jpg?w=360"
            className="pdf-icon"
            width={40}
            height={40}
          />
        );
      case 2:
        return (
          <img
            src="https://winaero.com/blog/wp-content/uploads/2019/11/Photos-new-icon.png"
            className="pdf-icon"
            width={40}
            height={40}
          />
        );
      case 3:
        return (
          <img
            src="https://cdn-icons-png.flaticon.com/512/9159/9159105.png"
            className="pdf-icon"
            width={40}
            height={40}
          />
        );
      default:
        return (
          <img
            src="https://www.freeiconspng.com/uploads/text-file-icon-clip-art--royalty--32.png"
            className="pdf-icon"
            width={40}
            height={40}
          />
        );
    }
  };
  const formatDate = (dateString) => {
    let date = "";
    if (dateString !== "") {
      let momentdate = moment(dateString);
      date = momentdate?.format("DD MMM YYYY");
    }
    return date;
  };
  return (
    <div className="AddNew_sectionbox">
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        className="orbitSettings_accordion"
      >
        <AccordionSummary
          expandIcon={
            <IconButton>{expanded ? <RemoveIcon /> : <AddIcon />}</IconButton>
          }
        >
          <div className="accordion-titleBox">
            <div>
              <MdOutlineUploadFile size={30} />
              <p className="orbitSettings_accordionTitle">
                Indemnity Insurance
              </p>
            </div>
            <div className="title_meta">
              {/* Don't remove commented code */}
              {/* <p>
                <strong>Required by: </strong> {Details?.requiredBy || ""}
              </p> */}
              <p>
                <strong
                  className={
                    Details?.isUploaded ? "uploadedLabel" : "notUploadedLabel"
                  }
                >
                  Status: {Details?.isUploaded ? "Uploaded" : "Not uploaded"}
                </strong>
              </p>
              <p>
                {Details?.issueDate && Details?.expiryDate
                  ? `${formatDate(Details?.issueDate || "")} -
                  ${formatDate(Details?.expiryDate || "")}`
                  : ""}
              </p>
              {/* Don't remove commented code */}
              {/* <p>
                <strong>Notes: </strong>
                {Details?.notes || ""}
              </p> */}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="settings_formFields">
            <div className="orbitSettings_calendarBox">
              <p>Issue Date</p>
              <Calendar
                dateFormat="dd/mm/yy"
                name="issueDate"
                className="orbitSettings_calendar"
                value={
                  Details?.issueDate == null
                    ? Details?.issueDate
                    : new Date(Details?.issueDate)
                }
                onChange={(e) => handleChangeDetails(e, "indemnityEnsurance")}
                showIcon
                yearNavigator
                yearRange="1900:2100"
              />
            </div>

            <div className="orbitSettings_calendarBox">
              <p>Expiry Date</p>
              <Calendar
                dateFormat="dd/mm/yy"
                name="expiryDate"
                className="orbitSettings_calendar"
                value={
                  Details?.expiryDate ? new Date(Details?.expiryDate) : null
                }
                onChange={(e) => handleChangeDetails(e, "indemnityEnsurance")}
                showIcon
                yearNavigator
                yearRange="1900:2100"
                disabled={Details?.issueDate === null ? true : false}
                minDate={
                  Details?.issueDate === null
                    ? new Date()
                    : new Date(Details?.issueDate)
                }
              />
            </div>
          </div>
          <div className="orbitSettings_uploadBox">
            <p>Upload Document</p>
            <div className="upload__image-wrapper upload_zone">
              {uploadedFile != null ? (
                <div className="file_view_doc">
                  <FileViewer file={uploadedFile} />
                </div>
              ) : (
                <></>
              )}
              <FileUpload
                mode="basic"
                name="document"
                maxFileSize={1000000}
                accept="image/*,.pdf,.doc,.docx"
                onSelect={(e) => onUpload(e)}
                onClear={handleRemoveFiles}
                chooseLabel="Upload your file/image here"
              />
            </div>
            <div className="disclaimer">
              <CgDanger />
              <p>
                By submitting this information, you are confirming that all the
                information supplied is accurate and you can provide physicl
                evidence relating to this should you be required to in future
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
