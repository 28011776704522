import { useSelector } from "react-redux";

const useTitanTemplate = () => {
  // #region Hook Constants
  const templateState = useSelector((state) => state.template);
  // #endregion

  // #region App State Setters

  // #endregion

  // #region API getter/caller functions for components usage to dispatch REDUX extra reducers

  // #endregion

  // #region Loader Handlers

  // #endregion

  // #region Loaders for APIs call

  // #endregion

  // #region Return Object
  return {
    templateState: templateState,
  };
  // #endregion
};

export default useTitanTemplate;
