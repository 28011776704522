import React, { useRef, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";

const CampaignPopup = ({ onClose }) => {
  const modalRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState("");

  const handleSelectChange = (event) => {
    setSelectedValue(event?.target?.value);
  };

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };
  const handleSubmit = (e) => {
    onClose();
  };

  return (
    <div className="patient_compaign_modal" onClick={handleOutsideClick}>
      <div className="patient_compaign_modal-content" ref={modalRef}>
        <h5>Create Campaign</h5>

        <div className="patient_campaign_services_dropdown">
          <select value={selectedValue} onChange={handleSelectChange}>
            <option value="option1">
              <span>New Medicine Service</span>
            </option>
            <option value="option2">
              <span>Earwax Removal</span>
            </option>
            <option value="option3">
              <span>Travel Vaccine</span>
            </option>
            <option value="option2">
              <span>Flu Vaccination</span>
            </option>
          </select>
        </div>

        <div className="date-range">
          <TextField
            className="date_range_input"
            id="start-date"
            label="Start Date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            className="date_range_input"
            id="end-date"
            label="End Date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="add_campaign_button">
          <button onClick={(e) => handleSubmit(e)}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default CampaignPopup;
