import React, { useEffect } from "react";

import { FaHandshake } from "react-icons/fa";

export default function ServicesOffered(onDarkModeChange) {
  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <h4>Services offered at this pharmacy</h4>
      <div className="wrapper">
        <div className="service">
          <div className="image">
            <p className="para_color">New Medicine Service</p>
          </div>
          <div className="bottom">
            <div className="icon">
              <FaHandshake className="icons-box" />
            </div>
            <div>
              <p className="created-by">Created by</p>
              <p className="author">Titan PMR</p>
            </div>
          </div>
        </div>
        <div className="service">
          <div className="image image-two">
            <p className="para_color">Ear Wax Removal</p>
          </div>
          <div className="bottom">
            <div className="icon">
              <FaHandshake className="icons-box" />
            </div>
            <div>
              <p className="created-by">Created by</p>
              <p className="author">Titan PMR</p>
            </div>
          </div>
        </div>
        <div className="service">
          <div className="image image-three">
            <p className="para_color">Viagra</p>
          </div>
          <div className="bottom">
            <div className="icon">
              <FaHandshake className="icons-box" />
            </div>
            <div>
              <p className="created-by">Created by</p>
              <p className="author">Titan PMR</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
