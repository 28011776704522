import React from "react";
import NmsIntervention from "../NmsIntervention/NmsIntervention";
import { useLocation } from "react-router-dom";
import useTitanApp from "../../hooks/useTitanApp";

function ClinicalServices() {
  const { appState } = useTitanApp();
  const { darkMode } = appState || {};
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const clinicalServiceIdParam = searchParams.get("clinicalServiceId");
  const serviceType = location.state && location?.state?.serviceType;
  const clincalServiceType =
    location.state && location?.state?.clincalServiceType;
  const headerTeamMembers =
    location.state && location?.state?.headerTeamMembers;
  return (
    <div>
      <NmsIntervention
        headerTeamMembers={headerTeamMembers}
        serviceType={serviceType}
        clinicalServiceIdParam={clinicalServiceIdParam}
        darkModeChange={darkMode}
        clincalServiceType={clincalServiceType}
      />
    </div>
  );
}

export default ClinicalServices;
