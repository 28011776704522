import React, { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
function RightToWork({ professionalDetails, handleChangeDropdown, darkMode }) {
  const [nationalityValue, setNationalityValue] = useState(
    professionalDetails?.nationality
  );
  const nationalities = [
    {
      value: "American",
      label: "American",
      name: "nationality",
    },
    {
      value: "British",
      label: "British",
      name: "nationality",
    },
    {
      value: "Canadian",
      label: "Canadian",
      name: "nationality",
    },
    {
      value: "French",
      label: "French",
      name: "nationality",
    },
    {
      value: "German",
      label: "German",
      name: "nationality",
    },
    {
      value: "Japanese",
      label: "Japanese",
    },
    {
      value: "Mexican",
      label: "Mexican",
      name: "nationality",
    },
    {
      value: "Russian",
      label: "Russian",
      name: "nationality",
    },
    {
      value: "Spanish",
      label: "Spanish",
      name: "nationality",
    },
    {
      value: "Australian",
      label: "Australian",
      name: "nationality",
    },
  ];

  return (
    <div className="AddNew_sectionbox">
      <div className="prof_details">
        <p className="orbit_card-title">Right To Work</p>
        <div className="select_docType">
          <p>Nationality</p>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            name="nationality"
            value={professionalDetails?.nationality}
            onChange={handleChangeDropdown}
            className="title-select"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? "grey" : "grey",
                boxShadow: state.isFocused ? "grey" : "grey",
              }),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: darkMode ? "#222" : "#aaa",
                primary: darkMode ? "#222" : "#aaa",
              },
            })}
          >
            <MenuItem name={"nationality"} value={"American"}>
              American
            </MenuItem>
            <MenuItem name={"nationality"} value={"British"}>
              British
            </MenuItem>
            <MenuItem name={"nationality"} value={"Canadian"}>
              Canadian
            </MenuItem>
            <MenuItem name={"nationality"} value={"French"}>
              French
            </MenuItem>
            <MenuItem name={"nationality"} value={"German"}>
              German
            </MenuItem>
            <MenuItem name={"nationality"} value={"Japanese"}>
              Japanese
            </MenuItem>
            <MenuItem name={"nationality"} value={"Mexican"}>
              Mexican
            </MenuItem>
            <MenuItem name={"nationality"} value={"Russian"}>
              Russian
            </MenuItem>
            <MenuItem name={"nationality"} value={"Spanish"}>
              Spanish
            </MenuItem>
            <MenuItem name={"nationality"} value={"Australian"}>
              Australian
            </MenuItem>
          </Select>
        </div>
      </div>
    </div>
  );
}

export default RightToWork;
