import React from "react";
import orbitLogo from "./../../assets/images/Verse_Orbit_Onboarding.png";
import SignupForm from "./components/SignupForm";
import ProcessingApp from "./components/ProcessingApp";
import YotiSteps from "./components/YotiSteps";
import OnboardingEmailVerification from "../../components/OnboardingEmailVerification/OnboardingEmailVerification";
import useTitanApp from "../../hooks/useTitanApp";
import OrbitGPHC from "./components/OrbitGPHC";
import CustomBackdrop from "../controls/CustomBackdrop";
import OnboardingSuccess from "../OnboardingSuccess/OnboardingSuccess";

export default function UserSignup({ root }) {
  const { onboardingVerificationState } = useTitanApp();
  const {
    isonboardingVerificationLoading,
    signupForm,
    emailVerification,
    yotiSteps,
    processingApp,
    orbitGPHC,
    onboardringSuccess,
  } = onboardingVerificationState || {};

  return (
    <>
      {isonboardingVerificationLoading && (
        <CustomBackdrop open={isonboardingVerificationLoading} />
      )}
      <div className="user_signup_wrapper">
        <img className="logo" src={orbitLogo} alt="titanverse-logo" />

        {orbitGPHC ? (
          <div className="orbit_invitation_wrapper">
            <div className="user_signup_container">
              <OrbitGPHC root={root} />
            </div>
          </div>
        ) : (
          <div className="user_signup_container">
            {signupForm && <SignupForm />}
            {emailVerification && <OnboardingEmailVerification />}
            {yotiSteps && <YotiSteps root={root} />}
            {processingApp && <ProcessingApp root={root} />}
            {onboardringSuccess && <OnboardingSuccess root={root} />}
          </div>
        )}
      </div>
    </>
  );
}
