import React from "react";
import '../../assets/ControlsStyles/customInputLabel.css'

function CustomInputLabel(props) {
  return (
    <label className="custom-label" name={props?.name}>
      {props?.name}
    </label>
  );
}

export default CustomInputLabel;
