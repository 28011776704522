export const Nmsobj = {
  TemplateId: "guid",
  TemplateName: "string",
  TemplateSections: [
    {
      SectionTitle: "Healthy living advice",
      UserControls: [
        {
          Name: "string",
          Type: "text",
          Label: "Dose",
          Inline: false,
          Required: true,
          CheckRadioValues: [
            {
              Label: "string",
              Value: "string",
              type: "",
            },
          ],
        },
        {
          Name: "string",
          Type: "date",
          Label: "Start date",
          Inline: false,
          Required: true,
          CheckRadioValues: [
            {
              Label: "string",
              Value: "string",
              type: "",
            },
          ],
        },
        {
          Name: "string",
          Type: "date",
          Label: "End date",
          Inline: false,
          Required: true,
          CheckRadioValues: [
            {
              Label: "string",
              Value: "string",
              Selected: "",
            },
          ],
        },
      ],
    },
    {
      SectionTitle: "Matters identified",
      UserControls: [
        {
          Name: "string",
          Type: "number",
          Label: "Quantity",
          Inline: false,
          Required: true,
          CheckRadioValues: [
            {
              Label: "string",
              Value: "string",
              Selected: "",
            },
          ],
        },
        {
          Name: "string",
          Type: "date",
          Label: "Date",
          Inline: false,
          Required: true,
          CheckRadioValues: [
            {
              Label: "string",
              Value: "string",
              Selected: "",
            },
          ],
        },
      ],
    },
  ],
  TemplateMedicationSections: [
    {
      SectionTitle: "Lisdexemfetamine 30mg tablets",
      UserControls: [
        {
          Name: "string",
          Type: "text",
          Label: "Dose",
          Inline: false,
          Required: true,
          CheckRadioValues: [],
        },
        {
          Name: "string",
          Type: "date",
          Label: "Date first supplied",
          Inline: false,
          Required: true,
          CheckRadioValues: [],
        },
        {
          Name: "string",
          Type: "accordion",
          Label: "Conditions related",
          Inline: false,
          Required: true,
          CheckRadioValues: [],
        },
        {
          Name: "string",
          Type: "accordion",
          Label: "Matters identified",
          Inline: false,
          Required: true,
          CheckRadioValues: [],
        },
        {
          Name: "string",
          Type: "accordion",
          Label: "Discussion outcomes",
          Inline: false,
          Required: true,
          CheckRadioValues: [
            {
              Label: "A) Patient reports using medicine as prescribed",
              Value: "string",
              Selected: false,
            },
          ],
        },
        {
          Name: "string",
          Type: "accordion",
          Label: "Matters identified",
          Inline: false,
          Required: true,
          CheckRadioValues: [
            {
              Label: "A) Patient reports using medicine as prescribed",
              Value: "string",
              Selected: false,
            },
            {
              Label: "B) Patient reports NOT using medicine as prescribed",
              Value: "string",
              Selected: "",
            },
            {
              Label: "C) Patient reports need for information",
              Value: "string",
              Selected: false,
            },
            {
              Label: "D) Patient reports side effects",
              Value: "string",
              Selected: false,
            },
            {
              Label: "E) Patient reports negative feelings about medicine",
              Value: "string",
              Selected: false,
            },
            {
              Label:
                "E) Patient reports uncertainty on whether the medicine is working",
              Value: "string",
              Selected: false,
            },
          ],
        },
      ],
    },
  ],
};
export const metaDataobj = {
  MetaDataId: "guid",
  MetaDataName: "string",
  MetaDataSections: [
    {
      icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAQCAYAAAAmlE46AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEUSURBVHgBnZPhUcJAEIVzJwWcHZikgViB2kE6iFagdJAO9J8/xQqQCoQKoAOgAq6ChO8xl5kMJBB4Mzt32d23+7J3Z6IjPABjzA/bDHPs/6qqGm9AO892kJZ1XS+wGLuHtJBPsagPSZJMiX90qCjjOP7v7UiHnGXSUfMrSO8mAh/1w50jrrATqdbaAjWztm/U/iD4FgbhG8mQ3vGr2GNvR42cJCU8UWCHrZnqi3zHx3EzTJqmBVU1NTeQs0F+OYJUXiNFFwHO0oZOPhqI0MCJqCPIhhJp+Koreeec2+pSs848uEDKyP1mOzbBkeP4xObo/5UKFPnmn7RgBfGcbnopE9OqpuBzSDg8qRDakqxJTlW0KbgHg32LYTqem3EAAAAASUVORK5CYII=",
      SectionTitle: "Pharmacist",
      UserControls: [
        {
          Name: "string",
          Type: "text",
          Label: "Name",
          Inline: false,
          Required: true,
          Value: "Tas Bhatti",
        },
        {
          Name: "string",
          Type: "text",
          Label: "ID Number",
          Inline: false,
          Required: true,
          Value: "129-1029-1830",
        },
      ],
    },
    {
      icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAQCAYAAAAiYZ4HAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACXSURBVHgB7ZHRDYMwEEPvIgZghEsySSdpOwIjdAM2qDpJN0nZoEyQq1FBCidBGABL+XH8lFjmGOM159wTUUtGzNynlLqV573/qOplgMoL+LD15ZxTQPfFdzhiw4tg3/A6hxCeJbArC+0CItJaqNkK4/8PlP6iS+n9y9FBTdlqB6sTOAqMWFRqwTkzNFivw6JvjFKDxin7A2xlSKbkknZfAAAAAElFTkSuQmCC",
      SectionTitle: "Consultation",
      UserControls: [
        {
          Name: "string",
          Type: "text",
          Label: "Date",
          Inline: false,
          Required: true,
          Value: "18/10/2017",
        },
        {
          Name: "string",
          Type: "text",
          Label: "Patient location",
          Inline: false,
          Required: true,
          Value: "In the pharmacy",
        },
        {
          Name: "string",
          Type: "text",
          Label: "Consent",
          Inline: false,
          Required: true,
          Value: "Signature via Edo",
        },
        {
          Name: "string",
          Type: "text",
          Label: "Pharmacist location",
          Inline: false,
          Required: true,
          Value: "In the pharmacy",
        },
        {
          Name: "string",
          Type: "text",
          Label: "Chaperone",
          Inline: false,
          Required: true,
          Value: "None",
        },
      ],
    },
  ],
};
export const Nmsdetails = {
  Patient: {
    General: {
      Name: "Alan Jones",

      Dob: "18/10/2022",

      Gender: "Male",

      NhsNo: "932-290-2937",

      Telephone: "+44 (0) 777 649 55 86",

      Ethnicity: "White-Irish",

      Address:
        "123 street ,Stapleton rd, Eastville,Bristol BS5 6NR, United Kingdom",
    },

    Allergies: [
      {
        Label: "Hayfever",
      },
    ],
    Therapies: [
      {
        Label: "medicine one",
        Value: ["Last dispensed 3 days ago", "Last dispensed 2 days ago"],
      },
      {
        Label: "medicine two",
        Value: ["Last dispensed 3 days ago", "Last dispensed 2 days ago"],
      },
      {
        Label: "medicine three",
        Value: ["Last dispensed 3 days ago", "Last dispensed 2 days ago"],
      },
    ],
    History: "30 days",
    Drugs: [
      {
        Label: "Albuterol 20mg tablets",
        dispensed: "Last dispensed on 11/12/2022",
        dosage: "Take TWO a day",
        quantity: "56 tablets",
        manage: "Managed",
      },
      {
        Label: "Albuterol 20mg tablets",
        dispensed: "Last dispensed on 11/12/2022",
        dosage: "Take TWO a day",
        quantity: "56 tablets",
        manage: "Managed",
      },
    ],
  },
  Prescription: {},
  Audit: {},
};
