import * as React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import "./../../../../../../../../assets/css/Dashboard/Claim.scss";
import "./../../../../../../../../assets/css/NmsIntervention/NmsIntervention.scss";
import { FaRegUser } from "react-icons/fa";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import useTitanApp from "../../../../../../../../hooks/useTitanApp";
import NmsDetails from "../../../../../../../../components/NmsDetails/NmsDetails";
import { setSidebarVisible } from "../../../../../../../../dispatchers/pharmacyServiceDispatchers";
export default function ServiceDetails() {
  const { pharmacyServiceState, nmsServiceState } = useTitanApp();
  const { patientName, patientData, arrayImg, service, isNmsPatientLoading } =
    nmsServiceState || {};
  const { sidebarVisible, selectedRowData } = pharmacyServiceState || {};
  const { name } = selectedRowData;

  let count = 0;
  let currentIndex = 0;
  let stageName = "",
    previousStageName = "";

  const getStageNameAndCurrentIndex = () => {
    {
      service?.stages?.map((stage, index) => {
        if (currentIndex === 0 && stage?.clinicalServiceStageStatus !== 2) {
          currentIndex = index;
        }
        if (stage?.clinicalServiceStageStatus !== 2) {
          if (stageName === "") {
            stageName = stage?.stageName;
          }
          if (index !== 0 && previousStageName === "") {
            switch (index) {
              case 1:
                previousStageName = service?.stages[index - 1]?.stageName;
                break;
              case 2:
                previousStageName = service?.stages[index - 2]?.stageName;
                break;
              case 3:
                previousStageName = service?.stages[index - 1]?.stageName;
                break;
              default:
                previousStageName = service?.stages[index - 1]?.stageName;
            }
          }
        }
      });
    }
  };
  const toggleClose = () => {
    setSidebarVisible(false);
  };

  const list = (anchor) => (
    <div className="services_details_main_wrapper">
      <div className="drawer_wrapper">
        <div className="drawer_header">
          <div>
            <h2 className="img-heading para_color">
              <FaRegUser />
              {name}
            </h2>
          </div>
          <div>
            <IconButton onClick={toggleClose}>
              <Close />
            </IconButton>
          </div>
        </div>
        <div>
          <NmsDetails
            Nmsdetails={patientData}
            prescriptionImages={arrayImg}
            stageName={previousStageName}
          />
        </div>
      </div>
    </div>
  );
  return (
    <div>
      {getStageNameAndCurrentIndex()}
      <SwipeableDrawer
        anchor="right"
        open={sidebarVisible}
        onClose={toggleClose}
        className="completed_services_drawer"
      >
        {list("right")}
      </SwipeableDrawer>
    </div>
  );
}
