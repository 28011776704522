import { dispatch, getDataFromStore } from "../store/store";
import { logError } from "../services/logService";
import {
  getAllDocumentsData,
  setDocumentID,
  setDocumentName,
  setDocumentData,
  addDocumentData,
  getDocumenteByIdData,
} from "../features/documentServiceSlice/documentServiceSlice";

const reducerName = "documentService";

// #region State Setters
export const setCurrentDocumentID = (documentId) => {
  dispatch(setDocumentID(documentId));
};

export const setCurrentDocumentName = (documentName) => {
  dispatch(setDocumentName(documentName));
};

export const setCurrentDocumentData = (documentData) => {
  dispatch(setDocumentData(documentData));
};
// #endregion

// #region API getter/caller functions for components usage to dispatch REDUX extra reducers
export const getAllDocuments = async (pharmacyId, serviceId) => {
  let result = [];
  try {
    result = await dispatch(
      getAllDocumentsData({
        pharmacyId,
        serviceId,
      })
    ).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const addDocument = async (data) => {
  let result = null;

  try {
    result = await dispatch(addDocumentData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const getDocumentById = async (documentId) => {
  let result = null;

  try {
    result = await dispatch(getDocumenteByIdData(documentId)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};
// #endregion
