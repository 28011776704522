import React, { useEffect } from "react";
import CustomButton from "../../../../components/controls/CustomButton";

export default function OverviewFooter(onDarkModeChange) {
  useEffect(() => {}, []);

  return (
    <div className="overview_footer">
      <div className="">
        <CustomButton label="Create Note" />
        <CustomButton label="Add new task" />
        <CustomButton label="Create Service" />
        <CustomButton label="Call to action" />
      </div>
    </div>
  );
}
