//method to get patient data and prescribed medicines
import config from "../config";

const { localurl } = config;

const getClinicalServiceList = () => {
  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    `Bearer ${localStorage.getItem("authToken")}`
  );
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  const data = fetch(
    `${localurl}/ClinicalService/get-clinical-service-list`,
    requestOptions
  );

  return data;
};

const getClinicalServiceBuilderById = (ClinicalServiceId) => {
  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    `Bearer ${localStorage.getItem("authToken")}`
  );
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  const data = fetch(
    `${localurl}/ClinicalServiceBuilder/get-clinical-service-by-id?ID=${ClinicalServiceId}`,
    requestOptions
  );

  return data;
};
async function postClinicalServiceBuilder(result) {
  var myHeaders = new Headers();
  myHeaders.append("ODS", "1");
  myHeaders.append("Token", localStorage.getItem("Titan_Token"));
  myHeaders.append(
    "Authorization",
    `Bearer ${localStorage.getItem("authToken")}`
  );
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(result);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = fetch(
    `${localurl}/ClinicalService/add-clinical-service`,
    requestOptions
  );
  return response;
}

export {
  getClinicalServiceList,
  getClinicalServiceBuilderById,
  postClinicalServiceBuilder,
};
