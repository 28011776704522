import { v4 as uuidv4 } from "uuid";
class customTemplate {
  constructor(isDuplicate, section) {
    this.obj = {
      groupId: uuidv4(),
      order: isDuplicate ? section?.order : 0,
      sectionTitle: isDuplicate ? section?.sectionTitle : "",
      isParent: isDuplicate ? section?.isParent : false,
      userControls: isDuplicate ? section?.userControls : [],
      templateSectionType: isDuplicate ? section?.templateSectionType : 2,
      isRequired: section?.isRequired,
    };
  }
}
export default customTemplate;
