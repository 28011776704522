import React from "react";
import Tasks from "./../components/Tasks";
import Appointments from "./../components/Appointments";
import PatientDetails from "../components/PatientDetails";
import OverviewFooter from "../../../pages/Dashboard/OverviewTab/components/OverviewFooter";
import NominatedPharmacy from "../components/NominatedPharmacy";
import Contact from "../components/Contact";
import LifetimeValue from "../components/LifetimeValue";
import Summary from "../components/Summary/Summary";
import AppleHealth from "../components/AppleHealth/AppleHealth";

export default function PatientDashboardPage(onDarkModeChange) {
  const patientDetails = [
    {
      label: "Date of Birth",
      value: "18/10/1968 (51)",
    },
    {
      label: "Sex",
      value: "Female",
    },
    {
      label: "NHSNo",
      value: "630 783 7632",
    },
    {
      label: "Address",
      value: "154 Albany St, London, NW1 4DH, UK",
    },
    {
      label: "Ethnicity",
      value: "White - Irish",
    },
    {
      label: "Surgery",
      value: "Name of surgery",
    },
    {
      label: "Allergies",
      value: ["Hayfever", "Pennicilin", "Aspirin"],
    },
  ];

  const appointments = [
    {
      name: "Collection",
      date: "Mon 26 Jun",
      time: "11:00am - 12:00pm (1hr)",
      status: "ready",
    },
    {
      name: "Hypertension case-finding service",
      date: "Mon 26 Jun",
      time: "11:00am - 12:00pm (1hr)",
      status: "ready",
    },
    {
      name: "New Medicine Service",
      date: "Mon 26 Jun",
      time: "11:00am - 12:00pm (1hr)",
      status: "confirmed",
    },
    {
      name: "Earwax Removal",
      date: "Mon 26 Jun",
      time: "11:00am - 12:00pm (1hr)",
      status: "confirmed",
    },
  ];

  const contactDetails = [
    {
      label: "Email",
      value: "jess.aubree@gmail .com",
    },
    {
      label: "Phone Number",
      value: "+44 (0) 7776 495586",
    },
    {
      label: "Home Number",
      value: "+44 (0) 7776 495586",
    },
  ];

  const dataSetOne = [
    {
      name: "JAN",
      uv: 590,
      pv: 800,
      amt: 1400,
      cnt: 490,
    },
    {
      name: "FEB",
      uv: 868,
      pv: 967,
      amt: 1506,
      cnt: 590,
    },
    {
      name: "MAR",
      uv: 1397,
      pv: 1098,
      amt: 989,
      cnt: 350,
    },
    {
      name: "APR",
      uv: 868,
      pv: 967,
      amt: 1506,
      cnt: 590,
    },
    {
      name: "MAY",
      uv: 590,
      pv: 800,
      amt: 1400,
      cnt: 490,
    },
    {
      name: "JUN",
      uv: 1520,
      pv: 1108,
      amt: 1100,
      cnt: 460,
    },
    {
      name: "JULY",
      uv: 590,
      pv: 800,
      amt: 1400,
      cnt: 490,
    },
    {
      name: "AUG",
      uv: 1397,
      pv: 1098,
      amt: 989,
      cnt: 350,
    },
    {
      name: "SEP",
      uv: 1520,
      pv: 1108,
      amt: 1100,
      cnt: 460,
    },
    {
      name: "OCT",
      uv: 868,
      pv: 967,
      amt: 1506,
      cnt: 590,
    },
    {
      name: "NOV",
      uv: 590,
      pv: 800,
      amt: 1400,
      cnt: 490,
    },
    {
      name: "DEC",
      uv: 1520,
      pv: 1108,
      amt: 1100,
      cnt: 460,
    },
  ];

  return (
    <div className="overview_tab">
      <div className="overview_col-1">
        <Tasks onDarkModeChange={onDarkModeChange} />
      </div>
      <div className="overview_col-2 patient-dashboard-col-2">
        <PatientDetails
          onDarkModeChange={onDarkModeChange}
          patientDetails={patientDetails}
        />
        <Appointments appointments={appointments} />
        <LifetimeValue dataSetOne={dataSetOne} />
        <Summary />
        <AppleHealth />
      </div>
      <div className="overview_col-3">
        <NominatedPharmacy />
        <Contact contactDetails={contactDetails} />
      </div>

      <OverviewFooter onDarkModeChange={onDarkModeChange} />
    </div>
  );
}
