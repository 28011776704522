import React, { useState, useEffect } from "react";
import { GoArrowLeft } from "react-icons/go";
import CustomButton from "../../../../../../controls/CustomButton";
import CustomLoader from "../../../../../../controls/CustomLoader";
import TextField from "@mui/material/TextField";
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import useTitanTeam from "../../../../../../../hooks/useTitanTeam";
import useTitanApp from "../../../../../../../hooks/useTitanApp";
import {
  setCurrentRole,
  addRole,
} from "../../../../../../../dispatchers/teamDispatchers";
import { useLoaderData, useNavigate } from "react-router";
import { showError } from "../../../../../../ToastedMessage/ToastedMessage";
import SettingsSidebar from "../../../../../../SettingsSidebar/SettingsSidebar";
import AsyncRendererRouter from "../../../../../../AsyncRendererRouter/AsyncRendererRouter";
import RolesAddNewLoadingComponent from "../../../../../../SettingsLoadingComponents/RolesAddNewLoadingComponent";

const getCheckAll = (permissions, checkedItems) => {
  return checkedItems?.length === permissions?.length
    ? "Uncheck all"
    : "Check all";
};

export default function AddRole() {
  const navigate = useNavigate();
  const { appState } = useTitanApp();
  const { teamState } = useTitanTeam();
  const { teamPermissions, currentRole, isTeamLoading } = teamState || {};
  const { darkMode, companyId } = appState || {};
  const [checkedItems, setCheckedItems] = useState([]);

  const handleToggle = (permission) => () => {
    const currentIndex = checkedItems.findIndex(
      (item) => item._id === permission._id
    );
    const newCheckedItems = [...checkedItems];

    if (currentIndex === -1) {
      newCheckedItems.push(permission);
    } else {
      newCheckedItems.splice(currentIndex, 1);
    }
    setCheckedItems(newCheckedItems);
  };

  function handleSelectAll() {
    if (getCheckAll(teamPermissions, checkedItems) === "Check all") {
      setCheckedItems(teamPermissions);
    } else {
      setCheckedItems([]);
    }
  }
  const checkboxTheme = createTheme({
    overrides: {
      MuiCheckbox: {
        root: {
          "&:hover": {
            backgroundColor: "transparent", // Disable hover background effect
          },
        },
        checked: {
          "&:hover": {
            backgroundColor: "transparent", // Disable hover background effect when checked
          },
        },
      },
    },
  });
  useEffect(() => {
    setCurrentRole({ roleName: "", permissions: [], companyID: companyId });
  }, []);

  const handleAddRole = () => {
    if (checkedItems?.length === 0) {
      showError("Please select atleast one permission", true, false);
    } else {
      const dataModel = {
        roleName: currentRole?.roleName,
        permissions: checkedItems,
        companyID: companyId,
      };
      const savedRole = addRole(dataModel);
      savedRole.then((result) => {
        if (result._id) {
          navigate("/settings/teams/roles");
        } else {
          showError("Role could not be added.", true, false);
        }
      });
    }
  };

  const handleRoleNameChange = (event) => {
    setCurrentRole({ ...currentRole, roleName: event?.target?.value });
  };

  const handleGoBack = () => {
    navigate("/");
  };

  const { appData } = useLoaderData() || {};

  return (
    <AsyncRendererRouter
      isLoading={isTeamLoading}
      resolve={appData}
      loadingElement={<RolesAddNewLoadingComponent />}
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <SettingsSidebar
            handleGoBack={handleGoBack}
            onDarkModeChange={darkMode}
          />
          <div className="settings_content_wrapper">
            <div className="settings_heading">
              <h1>Roles</h1>
            </div>
            <div>
              <div>
                <div
                  className="back_to_SettingsListView"
                  onClick={() => {
                    navigate("/settings/teams/roles");
                  }}
                >
                  <GoArrowLeft className="icons-color" />
                  <p>Back</p>
                </div>
                <div className="addMember_formFields">
                  <div className="inner-panels">
                    <div className="AddNew_sectionbox">
                      <h4>General Information</h4>
                      <div className="rolesGeneralInfo_wrapper">
                        <TextField
                          id="outlined-basic-add-role"
                          label="Role Name"
                          variant="outlined"
                          value={currentRole?.roleName}
                          onChange={handleRoleNameChange}
                          fullwidth={true}
                          autoComplete={"off"}
                        />
                      </div>
                    </div>

                    <div className="AddNew_sectionbox multiple-checks">
                      {teamPermissions?.length !== 0 ? (
                        <div>
                          <h4>Permissions</h4>
                          <div className="settings_box">
                            <div>
                              {" "}
                              {teamPermissions?.map((permission, index) => {
                                return (
                                  <div key={index}>
                                    <ThemeProvider theme={checkboxTheme}>
                                      <div>
                                        <List className="services_list">
                                          <ListItem
                                            button
                                            onClick={handleToggle(permission)}
                                          >
                                            <ListItemIcon>
                                              <Checkbox
                                                edge="start"
                                                checked={
                                                  checkedItems?.findIndex(
                                                    (item) =>
                                                      item._id ===
                                                      permission._id
                                                  ) !== -1
                                                }
                                                tabIndex={-1}
                                                disableRipple
                                              />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary={
                                                permission?.permissionName
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                      </div>
                                    </ThemeProvider>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="checkAll_btnBox">
                              <button onClick={handleSelectAll}>
                                {getCheckAll(teamPermissions, checkedItems)}
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>No permission found</div>
                      )}
                    </div>

                    <div className="footer_btn">
                      <CustomButton
                        label="Save Changes"
                        action={handleAddRole}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
