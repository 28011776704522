import React, { useState } from "react";
import TextField from "@mui/material/TextField";
function Education({ professionalDetails, handleChangeDetails }) {
  return (
    <div className="AddNew_sectionbox">
      <div className="prof_details">
        <p className="orbit_card-title">Education</p>
        <div className="gp_ps_no">
          <TextField
            id="outlined-basic"
            label="University / Educational Organisation"
            variant="outlined"
            fullwidth
            name="educationalOrganization"
            value={professionalDetails?.educationalOrganization || ""}
            onChange={handleChangeDetails}
          />
          <TextField
            id="outlined-basic"
            label="Year of Graduation"
            variant="outlined"
            fullwidth
            name="yearOfGraduation"
            value={professionalDetails?.yearOfGraduation || ""}
            onChange={handleChangeDetails}
          />
        </div>
      </div>
    </div>
  );
}

export default Education;
