import * as React from "react";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomLabel from "../controls/CustomLabel";
import useTitanApp from "../../hooks/useTitanApp";
import { setMedicationTime } from "../../dispatchers/clinicalChecksDispatchers";
function ScheduleAccordion({ PatientMedicationTime }) {
  const { clincicalChecksState } = useTitanApp();

  const { isEditable } = clincicalChecksState || {};

  const [expanded, setExpanded] = React.useState(false);
  const [inputclass, setInputClass] = useState("disable");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [inputValues, setInputValues] = useState(PatientMedicationTime);
  const handleFormChange = async (index, event) => {
    let value;

    value = event?.target?.value;
    await setUserControl(index, value);

    const MedicationTime = inputValues;
    setMedicationTime(MedicationTime);
  };
  const setUserControl = async (index, value) => {
    setInputValues((prevInputValues) => {
      const newInputValues = [...prevInputValues];
      newInputValues[index].dosageQuantity = value;
      return newInputValues;
    });
  };
  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        style={{ marginBottom: "15px" }}
        className="clinical-accordion"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className="card-accordion-cont"
        >
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="16" height="16" rx="8" fill="#00CC6A" />
              <path
                d="M6.48857 9.51115L4.99581 7.97574C4.768 7.74143 4.39866 7.74143 4.17085 7.97574C3.94305 8.21006 3.94305 8.58995 4.17085 8.82427L6.11529 10.8243C6.35784 11.0737 6.75631 11.0551 6.9759 10.7841L10.8648 5.98412C11.071 5.72956 11.0376 5.35122 10.7901 5.13908C10.5426 4.92694 10.1748 4.96134 9.96852 5.2159L6.48857 9.51115Z"
                fill="white"
              />
            </svg>
            <p>Schedule</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dosagetime-inner-boxes">
            {inputValues?.map((item, index) => {
              return (
                <div className="dose dose-element" key={index}>
                  <CustomLabel name={item.dosageTime} />
                  <input
                    className={inputclass + " card-inner-value"}
                    type="2"
                    disabled={isEditable}
                    value={item.dosageQuantity}
                    onChange={(event) => handleFormChange(index, event)}
                  />
                </div>
              );
            })}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default ScheduleAccordion;
