import React from "react";

export default function HandleCount(props) {
  return (
    <div
      className={`bottomBar_countContainer ${
        props.active ? "shrinkRotate" : ""
      }`}
    >
      <p>{props.countValue}</p>
    </div>
  );
}
