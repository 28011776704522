import React from "react";
import "../../assets/css/NmsConsent.scss";
import { useNavigate } from "react-router-dom";
import useTitanApp from "../../hooks/useTitanApp";
import AsyncRendererRouter from "../../components/AsyncRendererRouter/AsyncRendererRouter";
import CustomLoader from "../../components/controls/CustomLoader";
import { useLoaderData } from "react-router-dom";
function NmsConsent() {
  const { consentState } = useTitanApp();
  const { isConsentLoading } = consentState || {};
  const navigate = useNavigate();

  function handleStepNo() {
    navigate("/NewMedicineService");
  }

  const { appData } = useLoaderData() || {};
  return (
    <AsyncRendererRouter
      isLoading={isConsentLoading}
      resolve={appData}
      loadingElement={
        <>
          <div className="consent_page_header">
            <svg
              className="nhsuk-logo"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 16"
              height="40"
              width="100"
            >
              <path
                className="nhsuk-logo__background"
                fill="#005eb8"
                d="M0 0h40v16H0z"
              ></path>
              <path
                className="nhsuk-logo__text"
                fill="#fff"
                d="M3.9 1.5h4.4l2.6 9h.1l1.8-9h3.3l-2.8 13H9l-2.7-9h-.1l-1.8 9H1.1M17.3 1.5h3.6l-1 4.9h4L25 1.5h3.5l-2.7 13h-3.5l1.1-5.6h-4.1l-1.2 5.6h-3.4M37.7 4.4c-.7-.3-1.6-.6-2.9-.6-1.4 0-2.5.2-2.5 1.3 0 1.8 5.1 1.2 5.1 5.1 0 3.6-3.3 4.5-6.4 4.5-1.3 0-2.9-.3-4-.7l.8-2.7c.7.4 2.1.7 3.2.7s2.8-.2 2.8-1.5c0-2.1-5.1-1.3-5.1-5 0-3.4 2.9-4.4 5.8-4.4 1.6 0 3.1.2 4 .6"
              ></path>
            </svg>
          </div>
          <CustomLoader />
        </>
      }
    >
      <div className="nhsuk_container">
        <div className="nhsuk-width-container ">
          <main className="nhsuk-main-wrapper " id="maincontent" role="main">
            <div className="nhsuk-grid-row">
              <div className="nhsuk-grid-column-two-thirds">
                <h1>New Medicine Service</h1>

                <p>
                  You are eligible for a FREE Medicines Review by your local
                  pharmacy ...show pharmacy name
                </p>
                <p>
                  Your local pharmacist will call you back in 7 days to see how
                  you're getting on with your new medicine
                </p>
                <a className="nhsuk-button" onClick={handleStepNo}>
                  Start now
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}

export default NmsConsent;
