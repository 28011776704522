import { getObject } from "../getObjectValue";
let arr = [];
class prescriptionItemCheckActions {
  constructor(
    response,
    previousPrescriptionItemCheckActions,
    PrescriptionItemId
  ) {
    arr.push(response);
    previousPrescriptionItemCheckActions?.map((item) => {
      if (item.prescriptionItemId === PrescriptionItemId) {
        arr.push(item);
      }
    });
    this.obj = arr;
    arr = [];
  }
}

export default prescriptionItemCheckActions;
