import React from "react";
import "./../PatientDashboard.css";
import { ReactComponent as AddNewSvg } from "./../PatientDashboardIcons/addNew.svg";
import { ReactComponent as CalenderSvg } from "./../../../pages/Dashboard/OverviewTab/OverviewIcons/calendar.svg";
import { RxCrossCircled, RxCheckCircled } from "react-icons/rx";

export default function Tasks({ onDarkModeChange }) {
  const theme = {
    color: `${onDarkModeChange.onDarkModeChange === false ? "#000" : "#fff"}`,
  };

  return (
    <React.Fragment>
      <div className="tasks_header">
        <h3 className="pharmacy_header_name">Tasks</h3>
        <AddNewSvg />
      </div>
      <div className="overview-boxes">
        <div className="overview-headings">
          <div className="overview-iconBox">
            <h5>Pink Capsules</h5>
          </div>
        </div>
        <div className="overview-content">
          <p>
            Jessica requested to change yellow Simvistatin tablets to pink
            capsules. Needs to be completed in PMR dispensing settings
          </p>
        </div>

        <div className="cal-date">
          <CalenderSvg />
          <p>7th June 2023 (11:02am)</p>
        </div>
        <div className="patient-detail-buttons">
          <button className="patient-detail-button tasks-remove">
            <p>Remove</p>
            <RxCrossCircled />
          </button>
          <button className="patient-detail-button tasks-done">
            <p>Done</p>
            <RxCheckCircled />
          </button>
        </div>
      </div>
      <div className="overview-boxes">
        <div className="overview-headings">
          <div className="overview-iconBox">
            <h5>Follow up email</h5>
          </div>
        </div>
        <div className="overview-content">
          <p>
            Write a personalised email to Jessica thanking her for choosing
            Kings Pharmacy for Hypertension case-finding service
          </p>
        </div>

        <div className="cal-date">
          <CalenderSvg />
          <p>7th June 2023 (11:02am)</p>
        </div>
        <div className="patient-detail-buttons">
          <button className="patient-detail-button tasks-remove">
            <p>Remove</p>
            <RxCrossCircled />
          </button>
          <button className="patient-detail-button tasks-done">
            <p>Done</p>
            <RxCheckCircled />
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
