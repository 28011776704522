import React, { useRef, useState } from "react";
import Card from "@mui/material/Card";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { CampaignServicesData } from "./CampaignData";

const Campaign = () => {
  const currentDate = new Date();

  // Format the date as dd/mm/yyyy
  const formattedDate = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`;

  const calculateColor = (percentageValue) => {
    if (percentageValue > 80) {
      return "green";
    } else if (percentageValue > 60 && percentageValue <= 80) {
      return "rgb(243, 231, 61)";
    } else {
      return "red";
    }
  };

  return (
    <div className="popularServices campaign_list_container">
      <div className="innerServices">
        <div>
          <div className="campaign_list_serviceCard">
            {CampaignServicesData?.map((campaign, index) => (
              <div key={index} className="campaign_list_cardWrapper">
                <Card className="myCard" sx={{ minWidth: 495 }}>
                  <div className="card_service_main_wrapper">
                    <div className="cardHeading title-heading campaign_service_title">
                      {campaign.serviceName}
                    </div>
                  </div>
                  <div className="progress-bar-container">
                    <CircularProgressbar
                      value={campaign.percentageValue}
                      text={`${campaign.percentageValue}%`}
                      styles={buildStyles({
                        pathColor: calculateColor(campaign.percentageValue),
                        textcolor: "black",
                      })}
                    />
                  </div>
                  <div className="cardHeading title-heading total_patient_div">
                    {campaign.totalPatients} patients
                  </div>
                  <div className="card_service_main_wrapper">
                    <div className="cardHeading title-heading"></div>
                    <div className="created_on_date title-heading">
                      {formattedDate}
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Campaign;
