import React, { useRef, useState } from "react";
import CustomLabel from "../controls/CustomLabel";
import useTitanApp from "../../hooks/useTitanApp";
import { Map, Marker, NavigationControl } from "react-map-gl";
import { LuMapPin } from "react-icons/lu";

function NmsPharmacyInfo() {
  const { nmsServiceState, appState } = useTitanApp();
  const { darkMode } = appState || {};
  const { pharmacy } = nmsServiceState || {};
  const { pharmacyName, ods, pharmacyAddress, longitude, latitude } =
    pharmacy || "";
  const mapRef = useRef(null);
  const [viewport, setViewport] = useState({
    latitude: latitude,
    longitude: longitude,
    zoom: 15,
  });

  return (
    <div className="content-div patient-info-container pharmacy-info-container">
      <div className="general-inner-boxes">
        <div className="genaral_pds">
          <h2 className="card-heading">General</h2>
        </div>
        <div className="general-info">
          <div className="info-box">
            <span>
              <CustomLabel name="Pharmacy" />
              <p className="card-inner-value">{pharmacyName}</p>
            </span>
          </div>
        </div>
        <div className="general-info">
          <div className="info-box">
            <span>
              <CustomLabel name="ODS Code" />
              <p className="card-inner-value">{ods}</p>
            </span>
          </div>
          <div className="info-box">
            <span>
              <CustomLabel name="Tel" />
              <p className="card-inner-value">Tel No</p>
            </span>
          </div>
        </div>

        <div className="general-info">
          <div className="info-box">
            <span>
              <CustomLabel name="Address" />
              <p className="card-inner-value">{pharmacyAddress}</p>
            </span>
          </div>
        </div>
        <h2 className="card-heading">Map</h2>
        <div className="general-info">
          <div className="map-container">
            <Map
              initialViewState={viewport}
              scrollZoom={true}
              mapStyle={
                darkMode
                  ? "mapbox://styles/mapbox/dark-v11"
                  : "mapbox://styles/mapbox/light-v11"
              }
              style={{
                width: "100%",
                height: "40vh",
              }}
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              ref={mapRef}
              attributionControl={false}
            >
              <Marker longitude={longitude} latitude={latitude}>
                <div className="single-marker">
                  <LuMapPin color="#fff" size={20} />
                  <p className="pharmacy-name">{pharmacyName}</p>
                </div>
              </Marker>
            </Map>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NmsPharmacyInfo;
