import React, { Suspense, useState } from "react";
import { Await, Navigate } from "react-router";
import CustomLoader from "../controls/CustomLoader";
import { showError } from "../ToastedMessage/ToastedMessage";
import useTitanApp from "../../hooks/useTitanApp";
import AuthorizedModal from "../AuthorizedModal/AuthorizedModal";

const AsyncRendererRouter = ({
  loadingElement,
  isLoading,
  resolve,
  errorElement,
  children,
}) => {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const { appState } = useTitanApp();

  try {
    if (typeof resolve?.then === "function") {
      resolve?.then((data) => {
        if (data instanceof Response) {
          const toUrl = data?.headers?.get("location") || "/NotFound";
          setRedirectUrl(toUrl);
        }
      });
    }
  } catch (error) {}

  return (
    <Suspense fallback={loadingElement || <CustomLoader />}>
      <Await
        resolve={resolve}
        errorElement={() => {
          if (errorElement) {
            return errorElement;
          }
          showError("Something went wrong.");
          return <></>;
        }}
      >
        {redirectUrl !== null ? (
          <Navigate replace={true} to={redirectUrl} />
        ) : isLoading ? (
          loadingElement || <CustomLoader />
        ) : appState?.isAppLoading === true ? (
          <CustomLoader />
        ) : isLoading === false && appState?.isValidOrg === false ? (
          <AuthorizedModal />
        ) : (
          children
        )}
      </Await>
    </Suspense>
  );
};

export default AsyncRendererRouter;
