import React, { useState } from "react";
import "../assets/prescriptionheader.css";
import CustomLabel from "./controls/CustomLabel";
import { IoDocumentOutline } from "react-icons/io5";
import { getFormattedDate } from "../utils/FormattedDate";
import { treatmentTypes } from "../enum/treatmentType";
import { PrescriptionSourceTypes } from "../enum/prescriptionSourcetype";
import chatIcon from "./chaticon.svg";
import PrescriptionNotesControl from "../components/NotesControl/PrescriptionNotesControl";
import useTitanApp from "../hooks/useTitanApp";

function PrescriptionHeader() {
  const { clincicalChecksState } = useTitanApp();

  const { prescriptions, clinicalChecksNotes } = clincicalChecksState || {};
  const [isNotes, setIsNotes] = useState(false);
  const {
    prescriptionDate,
    numberofItems,
    prescriptionTreatmentType,
    prescriptionSourceType,
    externalSourceBarcode,
  } = prescriptions || {};

  const dateOfrx = getFormattedDate(prescriptionDate),
    sourceType =
      PrescriptionSourceTypes[
        prescriptionSourceType ? prescriptionSourceType : 0
      ],
    title = `General Prescription- ${sourceType}`,
    treatmenttype = treatmentTypes[prescriptionTreatmentType];

  function setNotes() {
    setIsNotes(!isNotes);
  }

  return (
    <div className="prescription-card customcard">
      <div className="card-step-number">
        <h2>1</h2>
      </div>
      <div className="card-actions clinical-checks-first">
        <h2 className="card-heading pres-card">
          <div>
            <IoDocumentOutline />
            {title}
          </div>
        </h2>
        <div className="card-right-actions">
          <p>{clinicalChecksNotes?.length}</p>
          <img src={chatIcon} onClick={setNotes} />
        </div>
      </div>
      <div className="card-inner-boxes">
        <div className="titan-prescription">
          <span>
            <CustomLabel name="Date of Rx" />
            <p className="card-inner-value"> {dateOfrx}</p>
          </span>
        </div>
        <div className="titan-prescription">
          <span>
            <CustomLabel name="Prescription Id" />
            <p className="card-inner-value"> {externalSourceBarcode}</p>
          </span>
        </div>
        <div className="titan-prescription">
          {" "}
          <span>
            <CustomLabel name="Number of items" />
            <p className="card-inner-value">{numberofItems}</p>
          </span>
        </div>
        <div className="titan-prescription">
          <span>
            <CustomLabel name="Treatment Type" />
            <p className="card-inner-value">{treatmenttype}</p>
          </span>
        </div>
      </div>
      {isNotes ? <PrescriptionNotesControl /> : <></>}
    </div>
  );
}

export default PrescriptionHeader;
