import React from "react";
import QRCode from "react-qr-code";
import config from "../../config";
import Modal from "react-bootstrap/Modal";
import useTitanApp from "../../hooks/useTitanApp";

function TravelVaccineModal({ show, setShow }) {
  const { appState, pharmacyServiceState } = useTitanApp();
  const { currentServiceId } = pharmacyServiceState || {};
  const { externalurl } = config;
  return (
    <div>
      <Modal
        show={show}
        backdrop="static"
        centered
        dialogClassName="addNewModal"
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton></Modal.Header>

        <div className="invite_desc">
          <p>Scan QR code with phone to fill travel vaccine form</p>
        </div>

        <div className="travel_qr_code">
          <QRCode value={`${externalurl}${currentServiceId}`} />
        </div>
      </Modal>
    </div>
  );
}

export default TravelVaccineModal;
