import React, { useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import useTitanApp from "./hooks/useTitanApp";
import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/Settings/Settings.scss";
import { setSentryUser } from "./services/logService";
import config from "./config";
import Chat from "./components/Chat/Chat";
import useIdleTimeout from "./hooks/useIdleTimeout";
import useTimer from "./hooks/useTimer";
import { handleUpdateSession } from "./data/handleUpdateSession";
const { idleTime } = config;
export default function App({ root }) {
  const { auth, loadRoutes, appState, signout } = useTitanApp();
  const { isOrbitUser } = appState;
  const handleIdle = async () => {
    if (isOrbitUser) {
      var result = await handleUpdateSession();
      if (result === true) {
        handleReset();
        signout();
      }
    } else {
      signout();
    }
  };

  const { handleReset } = useTimer();
  const { idleTimer } = useIdleTimeout({
    onIdle: handleIdle,
    idleTime: idleTime * 60,
  });

  const [router, setRouter] = useState(null);

  const identifyUser = (userId, userAttributes) => {
    if (window.hj) {
      window.hj("identify", userId, userAttributes);
    }
  };
  useEffect(() => {
    // Ensure the zE function is available
    if (typeof window.zE !== "undefined") {
      window.zE(function () {
        window.zE.hide();
      });
    }
  }, []); //

  useEffect(() => {
    if (config?.environmentName !== "development") {
      setSentryUser(auth?.user);
    }
  }, [auth?.user]);

  useEffect(() => {
    const routes = loadRoutes(root);
    const routerDefinations = createBrowserRouter(routes);
    setRouter(routerDefinations);
  }, []);

  return (
    <>
      <Chat />
      {router && <RouterProvider router={router} />}
    </>
  );
}
