import React, { Suspense } from "react";
import useTitanApp from "../../hooks/useTitanApp";
import CustomLoader from "../controls/CustomLoader";
import { Navigate } from "react-router";
import AuthorizedModal from "../AuthorizedModal/AuthorizedModal";

const states = {
  pending: "pending",
  fulfilled: "fulfilled",
  rejected: "rejected",
};

export const usePromiseHandler = (promise) => {
  if (promise instanceof Promise) {
    switch (promise?.status) {
      case states.fulfilled:
        return promise?.value;
      case states.rejected:
        throw promise?.reason;
      case states.pending:
        throw promise;
      default:
        promise.status = states.pending;
        promise?.then(
          (result) => {
            promise.status = states.fulfilled;
            promise.value = result;
          },
          (reason) => {
            promise.status = states.rejected;
            promise.reason = reason;
          }
        );
        throw promise;
    }
  } else {
    return promise;
  }
};

const Awaiter = ({
  resolve,
  successElement,
  loadingElement,
  errorElement,
  isLoading,
}) => {
  usePromiseHandler(resolve);
  if (
    resolve?.status === undefined ||
    resolve?.status === null ||
    resolve?.status === states.pending ||
    isLoading === true
  ) {
    return loadingElement || null;
  } else if (resolve?.status === states.rejected) {
    return errorElement || <>Something went wrong.</>;
  } else if (
    resolve?.value instanceof Response &&
    resolve?.value?.status === 302 &&
    resolve?.value?.redirected === false
  ) {
    const toUrl = resolve?.value?.headers?.get("location") || "/";
    return <Navigate replace={true} to={toUrl} />;
  }

  return successElement || <>Provide a component in success element</>;
};

const AsyncRenderer = ({
  resolve,
  isLoading,
  loadingElement,
  successElement,
  errorElement,
}) => {
  const { appState } = useTitanApp();

  switch (resolve?.status) {
    case states.fulfilled:
    case states.rejected:
    default:
      if (appState?.isAppLoading === true) {
        return <CustomLoader />;
      }
    // else if (isLoading === false && appState?.isValidOrg === false) {
    //   return <AuthorizedModal />;
    // }
  }

  return (
    <Suspense fallback={loadingElement || <CustomLoader />}>
      <Awaiter
        isLoading={isLoading}
        resolve={resolve}
        loadingElement={loadingElement}
        errorElement={errorElement}
        successElement={successElement}
      />
    </Suspense>
  );
};

export default AsyncRenderer;
