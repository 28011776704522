import React from "react";
import { HiOutlineCog8Tooth } from "react-icons/hi2";
import DarkThemeButton from "../../../components/DarkThemeButton";
import useTitanApp from "../../../hooks/useTitanApp";
import { useNavigate } from "react-router-dom";
import ProfileButton from "../../../components/ProfileButton";
import { setIsOrbitUser } from "../../../dispatchers/appDispatchers";
import { ReactComponent as TitanOrbitLightSVG } from "../../../assets/images/Titan_Orbit_Light.svg";
import { ReactComponent as TitanOrbitDarkSVG } from "../../../assets/images/Titan_Orbit_Dark.svg";
import { BsCardChecklist } from "react-icons/bs";
import {
  getOrbitActiveServices,
  setIsTestMode,
  setshowTestModeDialog,
} from "../../../dispatchers/orbitDispatchers";

export default function OrbitHeader() {
  const navigate = useNavigate();

  const { appState, orbitState } = useTitanApp();
  const { darkMode, isOrbitSwitch } = appState || {};
  const { isTestMode } = orbitState || {};
  function navigateOrbitUser() {
    setIsOrbitUser(!isOrbitSwitch);
    navigate("/");
  }

  const currentSessionId = localStorage.getItem("currentSessionId");

  const handleTestModeIconClick = () => {
    if (isTestMode) {
      setIsTestMode(false);
      getOrbitActiveServices({ currentSessionId, isTestMode: false });
    } else {
      setshowTestModeDialog(true);
    }
  };
  return (
    <div className="orbit_header">
      <div className="top-block">
        {darkMode ? <TitanOrbitDarkSVG /> : <TitanOrbitLightSVG />}
      </div>
      <div className="icons_wrapper">
        <div className="orbit_themeSwitch">
          <DarkThemeButton onDarkMode={darkMode} />
        </div>
        <div>
          <div
            className="header_messages header-icons"
            onClick={handleTestModeIconClick}
          >
            <div
              className={`icon-only label-btn ${
                darkMode ? "chat-icon-dark" : "chat-icon-light"
              } ${isTestMode ? "test-mode-active" : ""}`}
            >
              <BsCardChecklist />
            </div>
            <div className="icon-wtext">
              <p>{isTestMode ? "Disable Test Mode" : "Enable Test Mode"}</p>
            </div>
          </div>
        </div>
        <div>
          <ProfileButton />
        </div>
      </div>
    </div>
  );
}
