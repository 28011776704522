import React, { useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import useTitanApp from "../../../../../hooks/useTitanApp";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { getTimeLessDate } from "../../../../Dashboard/ServicesTab/ServicesContainer/ServicesAnalytics/components/DateRangePicker";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { getOrbitEarnings } from "../../../../../dispatchers/orbitDispatchers";
import LoadingComponentEarnings from "../../../../../components/LoadingComponentOrbit/LoadingComponentEarnings";
import { ReactComponent as CalendarSVG } from "./../../../../../assets/images/orbitCalendar.svg";
import { ReactComponent as CreditCardSVG } from "./../../../../../assets/images/orbitCreditcard.svg";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingComponentSession from "../../../../../components/LoadingComponentOrbit/LoadingComponentSession";
import OrbitSessions from "./OrbitSessions";

const CurrencyFormatter = (value) => `£${value}`;

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload?.length) {
    const tooltipStyle = {
      backgroundColor: "rgba(0, 0, 0, 0.8)", // Custom hover color
      padding: "10px",
      borderRadius: "5px",
      color: "#fff", // Text color
    };

    return (
      <div className="custom-tooltip" style={tooltipStyle}>
        <p className="label">{`Day: ${label}`}</p>
        <p className="value">{`Earning: ${CurrencyFormatter(
          payload[0].value
        )}`}</p>
      </div>
    );
  }

  return null;
};
export default function OrbitEarnings() {
  const customYAxisTick = (props) => {
    const { x, y, payload } = props || {};
    return (
      <text
        x={x}
        y={y}
        dy={0}
        fill={darkMode ? "#fff" : "#0a0a0a"}
        fontSize={12}
        textAnchor="end"
      >
        {CurrencyFormatter(payload?.value)}
      </text>
    );
  };

  const customXAxisTick = (props) => {
    const { x, y, payload } = props || {};
    return (
      <text
        x={x}
        y={y}
        dy={4}
        fill={darkMode ? "#fff" : "#0a0a0a"}
        fontSize={12}
        textAnchor="end"
      >
        {payload?.value}
      </text>
    );
  };
  const { orbitState, appState } = useTitanApp();

  const {
    orbitEarnings,
    isOrbitEarningLoading,
    orbitUserSessions,
    isUserSessionLoading,
  } = orbitState || {};
  const { darkMode } = appState || {};
  const yAxisValues = orbitEarnings?.map((entry) => entry?.earning);
  const currentDate = moment();
  const firstDayOfMonth = moment(currentDate).startOf("month");
  const lastDayOfMonth = moment(currentDate).endOf("month");
  const startDate = firstDayOfMonth.format("YYYY-MM-DDTHH:mm:ss");
  const endDate = lastDayOfMonth.format("YYYY-MM-DDTHH:mm:ss");
  const [dates, setDates] = useState([
    getTimeLessDate(startDate),
    getTimeLessDate(endDate),
  ]);
  const handleCalendarChange = async (event) => {
    const selectedDates = event?.value;
    if (selectedDates?.length === 2) {
      setDates(selectedDates);
      if (selectedDates[0] !== null && selectedDates[1] !== null) {
        const tzoffset = new Date().getTimezoneOffset() * 60000;
        let localISOStartTime = new Date(selectedDates[0] - tzoffset);
        let localISOEndTime = new Date(selectedDates[1] - tzoffset);
        localISOStartTime = moment(localISOStartTime).format(
          "YYYY-MM-DDTHH:mm:ss"
        );
        localISOEndTime = moment(localISOEndTime).format("YYYY-MM-DDTHH:mm:ss");
        getOrbitEarnings({
          startDate: localISOStartTime,
          endDate: localISOEndTime,
        });
      }
    }
  };
  return (
    <div className="orbit_box orbit_box_earnings">
      {isOrbitEarningLoading ? (
        <LoadingComponentEarnings />
      ) : (
        <div className="orbit_box-container">
          <div className="orbit_label_alt">
            <p>Earnings</p>
            <div className="date-range-picker-container">
              <div className="date-range-picker analytics-tab-date-picker">
                <Calendar
                  value={dates}
                  onChange={handleCalendarChange}
                  selectionMode="range"
                  dateFormat="dd/mm/yy"
                  readOnlyInput
                  showButtonBar
                  showIcon
                />
              </div>
            </div>
          </div>
          <ResponsiveContainer
            width="100%"
            height="100%"
            className="orbit_chart_wrapper"
          >
            <BarChart
              data={orbitEarnings}
              width="100%"
              height="100%"
              margin={{
                top: 30,
                left: -20,
                bottom: 5,
              }}
            >
              <XAxis
                dataKey="days"
                axisLine={false}
                tickLine={false}
                tick={customXAxisTick}
              />
              <YAxis
                dataKey="earning"
                axisLine={false}
                tickLine={false}
                tickFormatter={CurrencyFormatter}
                tick={customYAxisTick}
              />
              {yAxisValues?.map((earning, index) => (
                <ReferenceLine
                  key={index}
                  y={earning}
                  stroke="#0a0a0a"
                  strokeWidth={0.5}
                />
              ))}
              <Bar
                dataKey="earning"
                stackId="a"
                fill={darkMode ? "#fff" : "#0a0a0a"}
                barSize={8}
                radius={[10, 10, 0, 0]}
              />
              <Tooltip
                cursor={{ fill: "none" }}
                content={<CustomTooltip />}
                wrapperStyle={{ background: "transparent", border: "none" }}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
      <OrbitSessions />
    </div>
  );
}
