import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import useTitanTeam from "../../../../../../../../hooks/useTitanTeam";

export default function MembersUserAccessRole({ addedRole, setAddedRole }) {
  const { teamState } = useTitanTeam();
  const { teamRolesAll } = teamState || {};

  const handleRoleChange = (event) => {
    setAddedRole(event?.target?.value);
  };

  return (
    <div className="membersAddNew_box">
      <FormControl fullwidth>
        <InputLabel id="demo-simple-select-filled-label">Role</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={addedRole}
          onChange={handleRoleChange}
          className="role-select"
          renderValue={(selected) => selected?.roleName}
        >
          {teamRolesAll?.map((role, index) => (
            <MenuItem key={role?._id} value={role}>
              {role?.roleName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
