
class setUpdateGeneralDetails {
  constructor(
    companyID, 
    companyName, 
    companyAddress, 
    companyOwnerUserId, 
    companyOwnerEmailAddress, 
    companyTheme,
    contactNumber,

    ) {
    this.obj = {
      companyID: companyID,
      companyName: companyName,
      companyAddress: companyAddress,
      companyOwnerUserId: companyOwnerUserId,
      companyOwnerEmailAddress: companyOwnerEmailAddress,
      companyTheme: companyTheme,
      contactNumber: contactNumber,
      companyOwnerFirstName: "Invatech",
      gender: 0,
      companyOwnerLastName: "User",
      userImage: null,
      companyLogo: null,
    };
  }
}

export default setUpdateGeneralDetails;
