import { getObject } from "../getObjectValue";
let arr = [];
class prescriptionCheckActions {
  constructor(response) {
    arr.push(response);
    this.obj = arr;
  }
}

export default prescriptionCheckActions;
