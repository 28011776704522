import React from "react";
import orbitLogo from "./../../assets/images/Verse_Orbit_Onboarding.png";
import { ReactComponent as MailIcon } from "./../../assets/images/mail-icon.svg";
import useTitanApp from "../../hooks/useTitanApp";
import { resendEmailToUser } from "../../dispatchers/onboardingVerificationDsipatchers";
import { showError } from "../ToastedMessage/ToastedMessage";

function OnboardingEmailVerification() {
  const { onboardingVerificationState } = useTitanApp();
  const { userData } = onboardingVerificationState || {};

  const handleResendEmail = async () => {
    try {
      resendEmailToUser(userData?.emailAddress)
        .then((data) => {
          if (data === false) {
            showError(
              "There is some issue. Please resend email again",
              true,
              false
            );
          }
        })
        .catch(() =>
          showError(
            "There is some issue. Please resend email again",
            true,
            false
          )
        );
    } catch (errors) {}
  };

  return (
    <div className="email_verifying_container">
      <div className="inner ">
        <div>
          <MailIcon />
          <h2>Verify your email address</h2>
          <p>
            You’ve entered <strong>{userData?.emailAddress}</strong> as the
            email address for your account.
          </p>
          <p>Check your email and click link to activate your account</p>
          <button onClick={handleResendEmail}>Resend Email</button>
        </div>
      </div>
    </div>
  );
}

export default OnboardingEmailVerification;
