import React from "react";
import "./../../../assets/css/Dashboard/PatientListView.scss";
import { Tag } from "primereact/tag";

const PatientTags = ({ value }) => {
  const tags = Array.isArray(value) ? value : [value];

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {tags?.map((tag, index) => (
        <div key={index} className="patient-tag-container">
          <Tag
            key={index}
            severity="custom"
            className={tag ? "custom_tag_succes" : "custom_tag_warning"}
            value={tag ? "Yes" : "No"}
          />
        </div>
      ))}
    </div>
  );
};

export default PatientTags;
