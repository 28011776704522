import React from "react";
import "../../assets/nopatientpopup.css";
import CustomButton from "../../components/controls/CustomButton";
import useTitanApp from "../../hooks/useTitanApp";
import { useNavigate } from "react-router-dom";
import { getIdentityVerificationDataBySessionId } from "../../dispatchers/pharmacyServiceDispatchers";
function Success() {
  const { pharmacyServiceState } = useTitanApp();
  const { yotiSessionDetail } = pharmacyServiceState || {};
  const { sessionId } = yotiSessionDetail || {};
  const navigate = useNavigate();
  function routeToDashboard() {
    getIdentityVerificationDataBySessionId(sessionId).then((data) => {
      return data;
    });
    navigate("/");
  }
  return (
    <div className="row" style={{ marginTop: " 200px" }}>
      <div className=" col-4 "></div>
      <div className="nopatient-card popup-styles col-4">
        <div>
          <p>"Verified your identity"</p>
          <CustomButton label="Go to Dashboard" action={routeToDashboard} />
        </div>
      </div>
      <div className=" col-4 "></div>
    </div>
  );
}

export default Success;
