import * as React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import CustomButton from "../controls/CustomButton";
import "./../../assets/css/PatientModal.css";
import { ImWarning } from "react-icons/im";
import useTitanApp from "../../hooks/useTitanApp";

export default function PatientModal(props) {
  const { clincicalChecksState } = useTitanApp();

  const { isSkip } = clincicalChecksState || {};

  const { submit } = props || {};

  const navigate = useNavigate();
  const [show, setShow] = useState(isSkip);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(isOpen);

  const routeChangedashboard = () => {
    handleClose();
    navigate("/");
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        className="NMS-modal"
      >
        <Modal.Header>
          <ImWarning size={18} color={"red"} />
          <Modal.Title>Patient not found</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please clinically check this prescrtiption in Titan.</p>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton label="Skip" action={submit} />
        </Modal.Footer>
      </Modal>
    </>
  );
}
