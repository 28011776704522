import { dispatch, getDataFromStore } from "../store/store";
import {
  setCurrentPatientName,
  setCurrentIsNmsPatientLoading,
  setCurrentIsNmsTimelineLoading,
  setCurrentIsNmsPrescriptionLoading,
  setCurrentIsServiceNotesLoading,
  setCurrentArrayImg,
  getClinicalServiceByClinicalServiceId,
  getTitanPatient,
  getTimeline,
  getServiceNotes,
  getFollowUpByServiceIdAndServiceType,
  getPatientByTelephoneAndStageIndexResult,
  setCurrentIsPatientNmsTelephoneLoading,
  setCurrentPatientNMSData,
  getTotalSkips,
} from "../dispatchers/nmsDispatchers";
import {
  getPrescriptionByIncomingId,
  getPatientImage,
} from "../dispatchers/clinicalChecksDispatchers";
import { stageTypes } from "../enum/stageType";
const reducerName = "nmsService";
const reducerNameApp = "app";

export const getUpdatedDetailForNMS = async (serviceId) => {
  var service = await getClinicalServiceByClinicalServiceId(serviceId);
  if (service?.clinicalServiceBuilderResponseDTO) {
    getClinicalServiceResult(service, serviceId);
  } else {
    return null;
  }
};

const getClinicalServiceResult = (service, serviceId) => {
  const { clinicalServiceBuilderResponseDTO, pharmacy } = service || {};
  const { pharmacyID } = pharmacy || {
    pharmacyID: "00000000-0000-0000-0000-000000000000",
  };
  const { patientId, prescriptionIncomingId, telephoneNumber } =
    clinicalServiceBuilderResponseDTO || {
      patientId: "00000000-0000-0000-0000-000000000000",
      prescriptionIncomingId: "00000000-0000-0000-0000-000000000000",
      telephoneNumber: null,
    };
  const isOrbitUser = getDataFromStore(reducerNameApp, "isOrbitUser");
  const currentSessionId = localStorage.getItem("currentSessionId");
  if (isOrbitUser) {
    getTotalSkips({
      clinicalServiceId: serviceId,
      sessionId: currentSessionId,
    });
  }
  getFollowupDetailByClinicalServiceId(serviceId);
  getServiceNotesByClinicalServiceId(serviceId);
  getTimelineDataForClinicalServiceId(serviceId);
  if (telephoneNumber !== "") {
    getPatientByTelephoneAndStageIndex(telephoneNumber, serviceId);
  } else {
    setCurrentPatientNMSData([]);
  }
  if (prescriptionIncomingId !== null) {
    getPrescriptionByIncomingId({
      prescriptionIncomingId,
      pharmacyID,
    }).then((data) => {
      prescriptionImageData(data, pharmacyID);
    });
  }
  if (
    patientId !== null &&
    patientId !== "00000000-0000-0000-0000-000000000000"
  ) {
    const clinicalServiceId = serviceId;
    getTitanPatient({ pharmacyID, patientId, clinicalServiceId }).then(
      (data) => {
        prescriptionPatientImage(data);
      }
    );
  }
  setCurrentIsServiceNotesLoading(false);
  setCurrentIsNmsTimelineLoading(false);
};

const getFollowupDetailByClinicalServiceId = (clinicalServiceId) => {
  const value = getDataFromStore(reducerName, "value");
  const stageType = stageTypes[value];
  getFollowUpByServiceIdAndServiceType(clinicalServiceId, "1", stageType);
};

const getPatientByTelephoneAndStageIndex = (telephone, serviceId) => {
  const value = getDataFromStore(reducerName, "value");
  const isOrbitUser = getDataFromStore(reducerNameApp, "isOrbitUser");
  const currentSessionId = localStorage.getItem("currentSessionId");
  getPatientByTelephoneAndStageIndexResult(
    telephone,
    value,
    serviceId,
    currentSessionId,
    isOrbitUser
  );
  setCurrentIsPatientNmsTelephoneLoading(false);
};

const getServiceNotesByClinicalServiceId = (clinicalServiceId) => {
  const value = getDataFromStore(reducerName, "value");
  const stageNo = value;
  const stageType = stageTypes[stageNo];

  getServiceNotes({ clinicalServiceId, stageType });
};

const getTimelineDataForClinicalServiceId = (serviceId) => {
  setCurrentIsNmsTimelineLoading(true);
  const isOrbitUser = getDataFromStore(reducerNameApp, "isOrbitUser");
  const isOrbitSwitch = getDataFromStore(reducerNameApp, "isOrbitSwitch");
  const clinicalServiceId = getDataFromStore(reducerName, "clinicalServiceId");
  getTimeline(isOrbitUser || isOrbitSwitch ? clinicalServiceId : serviceId);
};
const prescriptionImageData = async (data, pharmacyID) => {
  if (data) {
    const { prescriptionGetModel } = data || {};
    const { prescriptionImages } = prescriptionGetModel ?? [];
    const resultArray = [];

    if (prescriptionImages) {
      for (const imageValue of prescriptionImages) {
        const result = await getPatientImage({
          imageValue,
          pharmacyID,
        });
        resultArray.push(`data:image/png;base64, ${result}`);
      }
      if (resultArray?.length > 0) {
        setCurrentArrayImg(resultArray);
      } else {
        setCurrentArrayImg([]);
      }
    }
  }
  setCurrentIsNmsPrescriptionLoading(false);
};

const prescriptionPatientImage = async (patientData) => {
  if (patientData) {
    const { patientGetModel } = patientData || {};
    const patientName = `${patientGetModel.title} ${patientGetModel.forename} ${patientGetModel.middleName} ${patientGetModel.surname}`;
    setCurrentPatientName(patientName);
  }
  setCurrentIsNmsPatientLoading(false);
};
