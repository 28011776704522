import { getObject } from "../getObjectValue";
import stages from "../Nms/stages";

class setPatientModel {
  constructor(
    response,
    stageNo,
    title,
    userControls,
    isCompleted,
    PatientName,
    Pharmacy_ID
  ) {
    this._id = response._id ? response._id : "";
    this.patientId = response.patientId
      ? response.patientId
      : "00000000-0000-0000-0000-000000000000";
    this.patientName = PatientName === "" ? response.patientName : PatientName;
    this.serviceName = response.serviceName ? response.serviceName : "";
    this.stages = response.stages
      ? new stages(response.stages, stageNo, title, userControls, isCompleted)
          .obj
      : [];
    this.numberOfStages = response.numberOfStages
      ? response.numberOfStages
      : "";
    this.serviceType = response.serviceType;
    this.clinicalServiceStatus = 1;
    this.clinicalServiceDate = new Date();
    this.pharmacyID = Pharmacy_ID;
    this.serviceCode = response.serviceCode;
    this.serviceID = response.serviceID;
  }
}

export default setPatientModel;
