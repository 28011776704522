import React, { useState } from "react";
import "../assets/imageslider.css";
import useTitanApp from "../hooks/useTitanApp";
import noimage from "../assets/images/No-Image-Placeholder.svg.png";
function ImageSlider({ navigateFrom }) {
  const { clincicalChecksState, nmsServiceState } = useTitanApp();
  const { patientImage, isNmsPrescriptionLoading } = clincicalChecksState || {};
  const { arrayImg } = nmsServiceState || {};
  const [activeIndex, setActiveIndex] = useState(0);
  const next = () => {
    setActiveIndex((prevState) =>
      navigateFrom === "NMS"
        ? prevState === (arrayImg?.length || 0) - 1
          ? 0
          : prevState + 1
        : prevState === (patientImage?.length || 0) - 1
        ? 0
        : prevState + 1
    );
  };

  const prev = () => {
    setActiveIndex((prevState) =>
      navigateFrom === "NMS"
        ? prevState === 0
          ? arrayImg?.length - 1
          : prevState - 1
        : prevState === 0
        ? patientImage?.length - 1
        : prevState - 1
    );
  };

  return (
    <div className="nms-presc-slider">
      {navigateFrom === "NMS" ? (
        arrayImg?.length >= 1 ? (
          <div className="image-buttons">
            <button onClick={prev}>
              <i className="fa fa-solid fa-angle-left image-button"></i>
            </button>
            <button onClick={next}>
              <i className="fa fa-solid fa-angle-right image-button"></i>
            </button>
          </div>
        ) : (
          <></>
        )
      ) : patientImage?.length >= 1 ? (
        <div className="image-buttons">
          <button onClick={prev}>
            <i className="fa fa-solid fa-angle-left image-button"></i>
          </button>
          <button onClick={next}>
            <i className="fa fa-solid fa-angle-right image-button"></i>
          </button>
        </div>
      ) : (
        <></>
      )}
      {navigateFrom === "NMS" ? (
        arrayImg?.length === 0 ? (
          <img src={noimage} alt="" className="img-div" />
        ) : (
          <img src={arrayImg[activeIndex]} alt="" className="img-div" />
        )
      ) : patientImage?.length === 0 ? (
        <img src={noimage} alt="" className="img-div" />
      ) : (
        <img src={patientImage[activeIndex]} alt="" className="img-div" />
      )}
    </div>
  );
}

export default ImageSlider;
