import React from "react";

export default function AddNewInputField(props) {
  return (
    <div className="addNew-input-container">
      <input
        id={props.id}
        name={props.name}
        type={props.type}
        className="custom-input"
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
    </div>
  );
}
