export const formatPhone = (phone) => {
  let result = "";
  try {
    if (phone) {
      const countryCode = phone?.substr(0, 3);
      const areaCode = phone?.substr(3, 4);
      const phoneNumber = phone?.substr(phone?.length - 6);
      result = `${countryCode} ${areaCode} ${phoneNumber}`;
    }
  } catch (error) {}
  return result;
};
