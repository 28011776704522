import React from "react";
import "../../assets/ControlsStyles/customlabel.css";
function CustomLabel(props) {
  return (
    <label className="custom-label" name={props?.name}>
      {props?.name}
    </label>
  );
}

export default CustomLabel;
