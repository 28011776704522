import React, { useState } from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSpring, animated } from "@react-spring/web";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import CustomLabel from "../../../../../controls/CustomLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { v4 as uuidv4 } from "uuid";
import CustomButton from "../../../../../controls/CustomButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props || {};
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,

  boxShadow: 24,
  p: 4,
};
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
function AddQuestion({ isOpen, handleCloseModal, handleAddQuestion }) {
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [responseType, setResponseType] = useState(0);
  const [listItems, setListItems] = useState([]);
  const [name, setName] = useState("");
  const [isInline, setIsInline] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const handleChangeResponse = (event) => {
    setResponseType(event?.target?.value);
  };
  const handleChangeValue = (event) => {
    setSelectedQuestion(event?.target?.value);
  };
  const questionType = [
    {
      label: "None",
      value: 0,
    },
    {
      label: "Custom question",
      value: 1,
    },
  ];
  const responseArr = [
    {
      label: "Free text",
      value: 0,
    },
    {
      label: "Single choice",
      value: 5,
    },
    {
      label: "Multiple choice",
      value: 3,
    },
  ];
  function AddQuestionCallback() {
    const obj = {
      userControlId: uuidv4(),
      name: name,
      type: responseType,
      label: name,
      inline: isInline,
      required: isRequired,
      value: "",
      listItems: responseType === 3 ? listItems : [],
      order: 1,
      parentUserControlId: null,
      isChildControl: null,
    };

    handleAddQuestion(obj);
    setSelectedQuestion(0);
    setResponseType(0);
    setName("");
    setIsRequired(false);
    setIsInline(false);
    setListItems([]);
  }
  function addListItem() {
    const newItem = {
      label: "",
      value: "",
      selected: false,
    };

    setListItems((prevItems) => [...prevItems, newItem]);
  }
  const handleInputListChange = (index, event) => {
    const updatedListItems = [...listItems];
    updatedListItems[index].value = event?.target?.value;
    updatedListItems[index].label = event?.target?.value;
    setListItems(updatedListItems);
  };
  function deleteListItem(objectToDelete) {
    const updatedlistItems = listItems?.filter(
      (control) => control !== objectToDelete
    );
    setListItems(updatedlistItems);
  }
  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={isOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style} className="endorsement-box">
            <div className="question_box">
              <div className="template_control">
                <TextField
                  className="template_input text_input_field"
                  required
                  id="outlined-required"
                  value={name}
                  label="Name"
                  onChange={(event) => setName(event?.target?.value)}
                />
              </div>
              <div className="custom-dropdown">
                <FormControl variant="filled" style={{ minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-filled-label">
                    Response Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={responseType}
                    onChange={handleChangeResponse}
                    className="custom-select"
                    IconComponent={() => <ExpandMoreIcon />}
                  >
                    {responseArr?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              {responseType === 3 ? (
                <div className="q_list_items">
                  {listItems?.map((item, index) => {
                    return (
                      <div className="option_box" key={index}>
                        <TextField
                          className="template_input text_input_field"
                          required
                          id="outlined-required"
                          value={item.value}
                          label="Option"
                          onChange={(event) =>
                            handleInputListChange(index, event)
                          }
                        />
                        <DeleteIcon
                          onClick={() => {
                            deleteListItem(item);
                          }}
                        />
                      </div>
                    );
                  })}
                  <CustomButton label="Add option" action={addListItem} />
                </div>
              ) : (
                <></>
              )}

              <div className="switch-content-reports-outer">
                <CustomLabel name="Is control value required?" />
                <div>
                  <AntSwitch
                    inputProps={{ "aria-label": "ant design" }}
                    onChange={(event) => setIsRequired(event?.target?.checked)}
                    // onChange={(event) =>
                    //   handleFormChange(
                    //     index,
                    //     event,
                    //     inputTypes[item.type]
                    //   )
                    // }
                    // checked={
                    //   item.value === ""
                    //     ? "false"
                    //     : JSON.parse(item.value)
                    // }
                  />
                </div>
              </div>
              <div className="switch-content-reports-outer">
                <CustomLabel name="Is control display in inline?" />
                <div>
                  <AntSwitch
                    inputProps={{ "aria-label": "ant design" }}
                    onChange={(event) => setIsInline(event?.target?.checked)}
                    // checked={
                    //   item.value === ""
                    //     ? "false"
                    //     : JSON.parse(item.value)
                    // }
                  />
                </div>
              </div>
            </div>

            <div className="q_modal_footer">
              <button className="question_cancel" onClick={handleCloseModal}>
                Cancel
              </button>

              <button className="question_ok" onClick={AddQuestionCallback}>
                Ok
              </button>
              {/* <CustomButton label="Cancel" action={handleCloseModal} />
              <CustomButton label="Ok" action={AddQuestionCallback} /> */}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default AddQuestion;
