import React, { useState } from "react";
import { ReactComponent as ResetPasswordIcon } from "./../../assets/images/reset-password.svg";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { userChangePassword } from "../../dispatchers/onboardingVerificationDsipatchers";
import { showError } from "../ToastedMessage/ToastedMessage";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object({
  emailAddress: Yup.string().required("Email address is required"),
  // currentPassword: Yup.string().required("Current Password is required"),
  // newPassword: Yup.string().required("New password is required"),
});

function OnboardingResetPassword() {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    emailAddress: "",
    newPassword: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(values, { abortEarly: false });
      var data = await userChangePassword(values?.emailAddress);
      if (data === true) {
        navigate("/");
      } else {
        showError("There is some issue. Please try again", true, false);
      }
      setErrors({});
    } catch (validationErrors) {
      const newErrors = {};
      validationErrors.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
    }
  };

  return (
    <div className="reset_password_container">
      <div className="inner">
        <div>
          <div className="reset-pw-icon">
            <ResetPasswordIcon />
          </div>
          <h2>Reset Password</h2>
          <div className="form_fields">
            <div>
              <label htmlFor="emailAddress">Email Address</label>
              <input
                type="text"
                id="emailAddress"
                name="emailAddress"
                value={values.emailAddress}
                onChange={handleChange}
                placeholder="Enter your email"
              />
              {errors.emailAddress && (
                <div className="validation">{errors.emailAddress}</div>
              )}
            </div>
            {/* <div>
              <label htmlFor="currentPassword">Current Password</label>
              <input
                type="password"
                id="currentPassword"
                name="currentPassword"
                value={values.currentPassword}
                onChange={handleChange}
                placeholder="Enter your current password"
              />
              {errors.currentPassword && (
                <div className="validation">{errors.currentPassword}</div>
              )}
            </div> */}
            {/* <div>
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                id="newPassword"
                name="newPassword"
                value={values.newPassword}
                onChange={handleChange}
                placeholder="Enter your new password"
              />
              {errors.newPassword && (
                <div className="validation">{errors.newPassword}</div>
              )}
            </div> */}
          </div>

          <button onClick={handleSubmit}>Reset Password</button>
        </div>
      </div>
    </div>
  );
}

export default OnboardingResetPassword;
