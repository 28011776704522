import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./../../assets/css/NotesControl.scss";
import { v4 as uuidv4 } from "uuid";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { ImWarning } from "react-icons/im";
import { BsPinAngleFill } from "react-icons/bs";
import PrecriptionItemNotes from "../../Model/ClinicalCheckNotes/PrecriptionItemNotes";
function AddedNotes({ item, addedNotes, handleAddedNotes }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openElem, setOpenElem] = React.useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [noteValue, setNotevalue] = useState(item.Note);
  const [isPicking, setIsPicking] = useState(false);
  const [isPinNote, setIsPinNote] = useState(false);
  const [isAwaitingQuery, setIsAwaitingQuery] = useState(false);
  const [isLabelling, setIsLabelling] = useState(false);
  const [isHandout, setIsHandout] = useState(false);
  const [isNeedCheck, setIsNeedCheck] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (ItemNoteId) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenElem(ItemNoteId);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = (noteId) => {
    const updatedNotes = addedNotes.filter(
      (note) => note.PrescriptionItemNoteId !== openElem
    );
    handleAddedNotes([...updatedNotes]);

    setAnchorEl(null);
    setOpenElem(null);
  };

  const handlePin = async () => {
    setIsPinNote(!isPinNote);
    await setPrescriptionItemNotes(
      "PinNote",
      isPinNote === false ? true : false
    );
  };
  const handleResolved = async () => {
    setIsAwaitingQuery(false);
    await setPrescriptionItemNotes("AwaitingQuery", false);
  };
  const handleAdditionalCheck = async () => {
    setIsNeedCheck(!isNeedCheck);

    await setPrescriptionItemNotes(
      "NeedCheck",
      isNeedCheck === false ? true : false
    );
  };

  const handleAwaitingQuery = async () => {
    setIsAwaitingQuery(true);
    await setPrescriptionItemNotes("AwaitingQuery", true);
  };
  const handleEdit = async () => {
    setIsEdit(true);
    setAnchorEl(null);
  };
  const handleHangout = async () => {
    setIsHandout(!isHandout);
    await setPrescriptionItemNotes(
      "ShowOnHandout",
      isHandout === false ? true : false
    );
  };

  const handleLabelling = async () => {
    setIsLabelling(!isLabelling);
    await setPrescriptionItemNotes("ShowOnLabelling", isLabelling);
  };

  const handlePicking = async () => {
    setIsPicking(!isPicking);
    await setPrescriptionItemNotes(
      "ShowOnPicking",
      isPicking === false ? true : false
    );
  };

  const setPrescriptionItemNotes = async (TypeValue, isChecked) => {
    const updatedNotes = new PrecriptionItemNotes(
      addedNotes,
      TypeValue,
      openElem,
      isChecked
    ).obj;
    handleAddedNotes([...updatedNotes]);

    setAnchorEl(null);
    setOpenElem(null);
  };
  function ChangeEditNote(e) {
    setNotevalue(e?.target?.value);
  }
  function ConfirmEdit() {
    const updatedNotes = addedNotes?.map((note) => {
      if (note.PrescriptionItemNoteId === openElem) {
        return { ...note, Note: noteValue };
      }
      return note;
    });
    handleAddedNotes([...updatedNotes]);
    setIsEdit(false);
  }
  function CloseEdit() {
    setNotevalue(item.Note);
    setIsEdit(false);
  }
  return (
    <div
      className={`note-item-container ${
        isPinNote
          ? "pinned-message"
          : isAwaitingQuery
          ? "awaiting-query-message"
          : ""
      }`}
    >
      {isAwaitingQuery ? (
        !isPinNote ? (
          <div className="note-item-message awaiting-query">
            <ImWarning />
            <p>Awaiting query</p>
          </div>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      {isPinNote ? (
        <div className="note-item-message pinned">
          <p>
            {isAwaitingQuery ? (
              <>
                <ImWarning />
                Awaiting query
              </>
            ) : (
              <></>
            )}
            <BsPinAngleFill className="pin-icon" />
            Pinned
          </p>
        </div>
      ) : (
        <></>
      )}
      <div className="note-item" key={item.PrescriptionItemNoteId}>
        <div className="note-item-wrapper">
          <div className="note-content"></div>
          <p className="title-note">{item.ProfessionalName}</p>
          {isEdit ? (
            <div className="edit-field">
              <input type="text" value={noteValue} onChange={ChangeEditNote} />
              <div className="note-confirm-close">
                <CheckIcon onClick={ConfirmEdit} />
                <CloseIcon onClick={CloseEdit} />
              </div>
            </div>
          ) : (
            <p>{noteValue}</p>
          )}

          <div className="notes-footer">
            <p className="note-date">{item.CreatedOn.toLocaleString()}</p>
            <div>
              {isNeedCheck ? <p>Needs Check</p> : <></>}
              {isHandout ? <p>Handout</p> : <></>}
              {isLabelling ? <p>Labelling & Packaging</p> : <></>}
              {isPicking ? <p>Picking</p> : <></>}
            </div>
          </div>
        </div>
        <div className="note-content">
          <MoreHorizIcon onClick={handleClick(item.PrescriptionItemNoteId)} />
        </div>
        <Menu
          key={item.PrescriptionItemNoteId}
          id="basic-menu"
          className="notes-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={handlePin}>
            {isPinNote ? (
              <div className="notes-check-icon">
                <CheckIcon />
              </div>
            ) : (
              <></>
            )}
            Pin comment
          </MenuItem>
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          <MenuItem
            onClick={() => {
              handleDelete(item.PrescriptionItemNoteId);
            }}
            value={item.PrescriptionItemNoteId}
          >
            Delete
          </MenuItem>
          <hr />
          <MenuItem onClick={handleAdditionalCheck}>
            {isNeedCheck ? (
              <div className="notes-check-icon">
                <CheckIcon />
              </div>
            ) : (
              <></>
            )}
            Require additional check
          </MenuItem>
          <MenuItem onClick={handleAwaitingQuery}>
            {isAwaitingQuery ? (
              <div className="notes-check-icon">
                <CheckIcon />
              </div>
            ) : (
              <></>
            )}
            Mark as awaiting query
          </MenuItem>
          <MenuItem onClick={handleResolved}>Mark as resolved</MenuItem>
          <MenuItem onClick={handleHangout}>
            {isHandout ? (
              <div className="notes-check-icon">
                <CheckIcon />
              </div>
            ) : (
              <></>
            )}
            Show on handout
          </MenuItem>
          <MenuItem onClick={handleLabelling}>
            {isLabelling ? (
              <div className="notes-check-icon">
                <CheckIcon />
              </div>
            ) : (
              <></>
            )}
            Show on labelling & packaging
          </MenuItem>
          <MenuItem onClick={handlePicking}>
            {isPicking ? (
              <div className="notes-check-icon">
                <CheckIcon />
              </div>
            ) : (
              <></>
            )}
            Show on picking
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}

export default AddedNotes;
