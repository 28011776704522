import React from "react";
import "./../../assets/css/DashboardListing.scss";
import ChecksButton from "./components/ChecksButton";
import ServicesButton from "./components/ServicesButton";
import pharmacyShop from "./../../assets/images/pharmacy-shop-new.jpg";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import pharmacyImagePlaceholder from "./assets/pharmacyImagePlaceholder";
import useTitanApp from "../../hooks/useTitanApp";
import { setCurrentPharmacy } from "../../dispatchers/appDispatchers";

export default function DashboardListing({ pharmacies }) {
  const transition = { duration: 0.8, ease: [0.6, 0.01, -0.05, 0.9] };

  const animEffectMain = {
    initial: {
      x: 0,
    },
    animate: {
      x: 0,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.1,
        staggerDirection: 1,
      },
    },
    exit: {
      x: 0,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.1,
        staggerDirection: -1,
      },
    },
  };

  const animEffect = {
    initial: {
      x: 500,
    },
    animate: {
      x: 0,
      transition: {
        duration: 1,
        ...transition,
      },
    },
    exit: {
      x: 500,
      transition: {
        duration: 1,
        ...transition,
      },
    },
  };

  const navigate = useNavigate();

  const handleChecksClick = (pharmacy) => {
    setCurrentPharmacy(pharmacy);
    navigate(`/clinicalchecks/${pharmacy.teamPharmacyID}`);
  };

  const handleImageBoxClick = (pharmacy) => {
    setCurrentPharmacy(pharmacy);
    navigate(`/pharmacyoverview/${pharmacy.teamPharmacyID}`);
  };

  return (
    <motion.div initial="initial" animate="animate" exit="exit">
      <motion.div
        variants={animEffectMain}
        className="dashboardListing_container"
      >
        {pharmacies?.map((pharmacy, index) => (
          <motion.div
            variants={animEffect}
            key={index}
            className="pharmacy_listview"
          >
            <div
              className="pharmacy-info"
              style={{
                backgroundImage: `url(${
                  pharmacy?.pharmacyImage !== null
                    ? pharmacy.pharmacyImage
                    : pharmacyImagePlaceholder
                })`,
                backgroundSize: `${
                  pharmacy.pharmacyImage !== null ? "cover" : "unset"
                }`,
              }}
              onClick={() =>
                handleImageBoxClick(
                  pharmacy,
                  pharmacy.teamPharmacyName,
                  pharmacy.teamPharmacyAddress,
                  pharmacy.teamPharmacyLongitude,
                  pharmacy.teamPharmacyLatitude
                )
              }
            >
              <div className="overlay"></div>
              <h6>{pharmacy.teamPharmacyName}</h6>
              <p>{pharmacy.teamPharmacyAddress}</p>
            </div>
            <div className="pharmacy-buttons">
              <ChecksButton
                handleChecksClick={handleChecksClick}
                checksCount={pharmacy?.pharmacyKPIs[0]?.kpiValue || 0}
                pharmacy={pharmacy}
              />
              <ServicesButton
                nmsCount={pharmacy?.pharmacyKPIs[1]?.kpiValue || 0}
              />
            </div>
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
}
