import React from "react";
import { LiaCopy } from "react-icons/lia";

export default function PatientDetails({ onDarkModeChange, patientDetails }) {
  const theme = {
    color: `${onDarkModeChange.onDarkModeChange === false ? "#000" : "#fff"}`,
  };
  return (
    <React.Fragment>
      <div className="tasks_header header_appointment">
        <h3 className="pharmacy_header_name">Patient Details</h3>
      </div>
      <div className="wrapper">
        {patientDetails?.map((detail) => (
          <div className="col-box patient-detail-box">
            <div className="box-label">
              <p className="patient-detail-label">{detail?.label || ""}</p>
              {Array.isArray(detail?.value) ? (
                <div className="value allergies">
                  {detail?.value?.map((allergy, allergyIndex) => (
                    <p key={allergyIndex}>{allergy}</p>
                  ))}
                </div>
              ) : (
                <p className="value">{detail?.value || ""}</p>
              )}
            </div>
            <LiaCopy
              size={22}
              className="icons-color"
              // color={
              //   onDarkModeChange.onDarkModeChange === false ? "#999" : "#fff"
              // }
            />
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}
