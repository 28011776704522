import React, { useState } from "react";
import TemplateSection from "../../components/TemplateSection/TemplateSection";
import NopatientPopup from "../../components/NopatientPopup";
import MetaDataSection from "../../components/MetaDataSection/MetaDataSection";
import { FaRegUser } from "react-icons/fa";
import NmsDetails from "../../components/NmsDetails/NmsDetails";
import "../../assets/clinicalcheck.css";
import "./../../assets/css/NmsIntervention/NmsIntervention.scss";
import setServiceNms from "../../Model/Nms/setServiceNms";
import setAddTemplate from "../../Model/AddTemplateSection/setAddTemplate";
import LoadingComponentNMS from "../../components/LoadingComponentNMS/LoadingComponentNMS";
import CustomButton from "../../components/controls/CustomButton";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import addedTemplate from "../../Model/AddTemplateSection/addedTemplate";
import setTemplateModel from "../../Model/TemplateModel/setTemplateModel";
import { useStore } from "react-redux";
import OrbitLightBG from "./../../assets/OrbitLightBG.json";
import OrbitDarkBGNew from "./../../assets/OrbitDarkBGNew.json";
import Lottie from "lottie-react";

import {
  setCurrentIsNmsLoading,
  addFollowUp,
  addNms,
  setCurrentNmsModel,
  addTimelineItem,
  setCurrentTimelineElements,
  saveTotalAttempts,
  setCurrentPatientName,
  setCurrentIsNmsPatientLoading,
  setCurrentIsNmsTimelineLoading,
  setCurrentIsNmsPrescriptionLoading,
  setCurrentIsServiceNotesLoading,
  setCurrentArrayImg,
  getClinicalServiceByClinicalServiceId,
  getTitanPatient,
  getTimeline,
  getServiceNotes,
  getFollowUpByServiceIdAndServiceType,
  getPatientByTelephoneAndStageIndexResult,
  setCurrentIsPatientNmsTelephoneLoading,
  setCurrentPatientNMSData,
  skipOrbitByClinicalServiceId,
  parkOrbitByClinicalServiceId,
  backOrbitByClinicalServiceId,
  addServiceNotes,
  setCurrentPatientCountData,
  getTotalSkips,
  setCurrentSkipCountData,
  setCurrentDeclineComments,
  setIsFollowUpControl,
} from "../../dispatchers/nmsDispatchers";
import {
  getPrescriptionByIncomingId,
  getPatientImage,
} from "../../dispatchers/clinicalChecksDispatchers";
import { addOrbitAudit } from "../../dispatchers/orbitDispatchers";
import ConsentModal from "../../components/ConsentModal/ConsentModal";
import FollowUpModal from "../../components/FollowUpModal/FollowUpModal";
import { FaTimes } from "react-icons/fa";
import { PiCaretRightBold } from "react-icons/pi";
import useTitanApp from "../../hooks/useTitanApp";
import useTimer from "../../hooks/useTimer";
import {
  showError,
  showInfo,
} from "../../components/ToastedMessage/ToastedMessage";
import setAddTimelineItem from "../../Model/TimelineItem/setAddTimelineItem";
import ServicesHeader from "./ServicesHeader";
import { setIsNmsLoading } from "../../features/NmsServiceSlice/NmsServiceSlice";
import { Skeleton } from "@mui/material";
import { ActionTrackForServices } from "../../data/actionTrack";
import NMSNotes from "../../components/NMSNotes/NMSNotes";
import consentSlice from "../../features/consentSlice/consentSlice";
import GroupedPatients from "../../components/GroupedPatients/GroupedPatients";
import { stageTypes } from "../../enum/stageType";
import { getUpdatedDetailForNMS as getNextNmsRecord } from "../../data/getNmsInterventionData";
import GroupedPatientsPopup from "../../components/GroupedPatients/GroupedPatientsPopup";
function NmsIntervention() {
  const store = useStore();
  const location = useLocation();
  const { timer, isActive, handleStart, handleReset } = useTimer();
  const serviceType = location.state && location?.state?.serviceType;
  const { clinicalServiceType } = useParams();
  let navigate = useNavigate();
  const [patientNames, setPatientName] = useState(null);
  let count = 0;
  let currentIndex = 0;
  let stageName = "",
    previousStageName = "";
  const [isError, setError] = useState(false);
  const [telPatientCurrentIndex, setTelPatientCurrentIndex] = useState(-1);
  const [isConsent, setIsConsent] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [followupModel, setFollowupModel] = useState(null);
  const [MedicineTemplates, setMedicineTemplates] = useState([]);
  let isCompleted = false;
  const { appState, orbitState, nmsServiceState, NmsLoader, auth } =
    useTitanApp();
  const {
    isNmsLoading,
    timelineElements,
    patientNmsData,
    groupedPatientWarning,
  } = nmsServiceState || {};
  const { user } = auth || {};
  const { isOrbitUser, isOrbitSwitch, darkMode, currentUser } = appState || {};
  const { startTimeObj } = orbitState || {};
  const currentSessionId = localStorage.getItem("currentSessionId");
  const userFullName = user?.name || "";
  const userEmail = user?.email || "";
  const {
    patientName,
    patientData,
    service,
    pharmacyID,
    clinicalpatientId,
    clinicalServiceId,
    pharmacy,
    NmsModel,
    value,
    stageNo,
    arrayImg,
    declineComments,
    patientCount,
    skipCounts,
    callStartTime,
    callEndTime,
    followUpcomments,
    isFollowUpControl,
  } = nmsServiceState || {};

  const boxStyling = (totalElements, position) => {
    if (totalElements === 1) {
      return { gridColumn: "1 / -1" };
    } else if (totalElements === 2) {
      return { gridColumn: "span 6" };
    } else {
      const alternatingGroupSize = 5;
      const alternatingSpan = position % alternatingGroupSize < 3 ? 4 : 6;

      return { gridColumn: `span ${alternatingSpan}` };
    }
  };

  async function CheckCompleted(indexNo) {
    NmsModel?.stages[indexNo]?.template?.templateSections?.map((template) => {
      template?.userControls?.map((item) => {
        if (item?.required === true) {
          if (item?.value === "" || item?.value === null) {
            if (item?.type === 0) {
              if (item?.type === 3) {
                isCompleted = true;
              }
            }
          }
        }
      });
    });
  }
  async function addAuditForSkipAndAttempt(type) {
    const session = localStorage.getItem("sessionData");
    const sessionData = JSON.parse(session);

    const setOrbitAudit = {
      userEmail: userEmail,
      sessionId: startTimeObj !== null ? startTimeObj?._id : sessionData?._id,
      pharmacyID: pharmacy?.pharmacyID,
      entityId: NmsModel?._id,
      consultationRate: 0,
      callStartTime: new Date().toISOString(),
      callEndTime: new Date().toISOString(),
      createdOn: new Date().toISOString(),
      consultationType: 1,
      auditStatus: value == 2 ? 1 : 2,
    };
    if (setOrbitAudit) {
      const body = {
        data: setOrbitAudit,
        actionType: type,
        actionDate: new Date().toISOString(),
      };
      addOrbitAudit(body).then((data) => {
        return data;
      });
    }
  }
  async function SkipClincialService(additionalComments) {
    const stageType = stageTypes[value];
    const obj = {
      clinicalServiceID: clinicalServiceId,
      notes:
        additionalComments === null || additionalComments === ""
          ? "skip"
          : additionalComments,
      userName: userFullName,
      date: new Date(),
      stageType: stageType,
    };
    const data = await skipOrbitByClinicalServiceId({
      clinicalServiceId: clinicalServiceId,
      sessionId: currentSessionId,
      data: obj,
    });
    if (data !== null) {
      addAuditForSkipAndAttempt(1);
      getNextNmsRecord(data);
      setCurrentSkipCountData(skipCounts - 1);
    } else {
      isOrbitUser || isOrbitSwitch
        ? navigate(`/orbit`)
        : navigate(`/pharmacyservices/${pharmacyID}`, {
            state: {
              serviceType: serviceType,
            },
          });
    }
  }
  async function parkClinicalService() {
    const data = await parkOrbitByClinicalServiceId(clinicalServiceId);
    if (data === true) {
      navigate(`/orbit`);
    }
  }
  async function exitClinicalService() {
    isOrbitUser || isOrbitSwitch
      ? backOrbitByClinicalServiceId(clinicalServiceId).then((data) => {
          navigate(`/orbit`);
        })
      : navigate(`/pharmacyservices/${pharmacyID}`, {
          state: {
            serviceType: serviceType,
          },
        });
  }

  const setCompletedNms = async (res) => {
    setCurrentNmsModel(res);
  };

  const orbitAuditAdd = async (indexNo) => {
    const session = localStorage.getItem("sessionData");
    const sessionData = JSON.parse(session);

    const setOrbitAudit = {
      userEmail: userEmail,
      sessionId: startTimeObj !== null ? startTimeObj?._id : sessionData?._id,
      pharmacyID: pharmacy?.pharmacyID,
      entityId: NmsModel?._id,
      consultationRate: 0,
      callStartTime:
        callStartTime !== "" ? callStartTime : new Date().toISOString(),
      callEndTime: callEndTime !== "" ? callEndTime : new Date().toISOString(),
      createdOn: new Date().toISOString(),
      consultationType: 1,
      auditStatus: indexNo == 2 ? 1 : 2,
    };
    if (setOrbitAudit) {
      const body = {
        data: setOrbitAudit,
        actionType: 2,
        actionDate: new Date().toISOString(),
      };
      addOrbitAudit(body).then((data) => {
        return data;
      });
    }
  };
  async function declineNms() {
    if (value === 3) {
      setIsFollowUpControl(true);
    } else {
      setIsConsent(true);
    }
  }
  async function submitNms(indexNo) {
    try {
      var consent = false;
      setCurrentIsNmsLoading(true);
      isCompleted = false;
      setIsRequired(true);
      const startDate = followupModel?.Start;
      const res = new setServiceNms(
        NmsModel,
        indexNo,
        null,
        null,
        true,
        clinicalServiceType,
        null,
        null,
        null,
        "",
        userFullName,
        "00000000-0000-0000-0000-000000000000",
        userEmail
      );
      await setCompletedNms(res);
      await CheckCompleted(indexNo);
      let lstElement = [];
      let resfinal;
      if (isCompleted === false) {
        // Dont reomve this commented code
        // const respAdded = addNewTemplateSections(res);

        resfinal = new setServiceNms(
          res,
          indexNo,
          null,
          null,
          true,
          clinicalServiceType,
          null,
          null,
          followupModel?.Description === "Follow up decline" ? 2 : null,
          startDate,
          userFullName,
          "00000000-0000-0000-0000-000000000000",
          userEmail
        );

        if (
          indexNo === 0 ||
          indexNo === 1 ||
          followupModel?.Description === "Follow up decline"
        ) {
          if (
            resfinal?.stages[indexNo]?.template?.templateSections[0]
              ?.sectionTitle === "Consent"
          ) {
            if (
              resfinal?.stages[indexNo]?.template?.templateSections[0]
                ?.userControls[0]?.value === "false"
            ) {
              consent = true;
              setIsConsent(true);
            }
          }
        }
        if (!consent) {
          let resTimeline;
          if (
            (indexNo === 0 || indexNo === 1) &&
            followupModel?.Description === "Follow up decline"
          ) {
            const timelineElementsObj = [
              {
                name: "declines",
                title: "declines Nms on ",
                value: "",
                listItems: [],
                elementType: 1,
              },
            ];
            resTimeline = new setAddTimelineItem(
              userEmail,
              userFullName,
              timelineElementsObj,
              8,
              clinicalServiceId
            );
          } else {
            resTimeline = new setAddTimelineItem(
              userEmail,
              userFullName,
              lstElement?.length > 0 ? lstElement : timelineElements,
              indexNo === 0 ? 5 : indexNo == 1 ? 15 : indexNo == 2 ? 6 : 16,
              clinicalServiceId
            );
          }

          const clinicalServiceWtihTimeline = {
            timeline: resTimeline,
            clinicalServiceBuilderResponseDTO: resfinal,
          };
          const res1 = await addNms(clinicalServiceWtihTimeline, isOrbitUser);
          if (service?.isTest !== true) {
            if (
              followupModel !== null &&
              followupModel?.Description !== "Follow up decline"
            ) {
              const timelineElementsObj = {
                name: followupModel?.title,
                title: followupModel?.Description,
                value: followupModel?.Start,
                listItems: [],
                elementType: 10,
              };

              const updatedTimelineElements = [
                ...timelineElements,
                timelineElementsObj,
              ];
              lstElement = updatedTimelineElements;
              setCurrentTimelineElements(updatedTimelineElements);
              await addFollowUp(followupModel);
            }
            setCurrentTimelineElements([]);
            await ActionTrackForServices(
              indexNo,
              false,
              "Nms Services Stage Complete",
              currentUser
            );
            if (isOrbitUser || isOrbitSwitch) {
              await orbitAuditAdd(indexNo);
            }
          }
          setCurrentPatientCountData(patientCount + 1);
          localStorage.removeItem(clinicalServiceId);
          patientNmsData?.length === 1 || patientNmsData?.length === 0
            ? navigateToRoot()
            : getNextRecordId();
        }
      }

      setCurrentIsNmsLoading(false);
    } catch (error) {
      console.log(error);
      setCurrentIsNmsLoading(false);
      setIsRequired(false);
      showInfo("Nms not updated due to some reason");
    }
  }
  function navigateToRoot() {
    if (isOrbitUser || isOrbitSwitch) {
      setCurrentPatientCountData(0);
      navigate(`/orbit`);
    } else {
      setCurrentPatientCountData(0);
      navigate(`/pharmacyservices/${pharmacyID}`, {
        state: {
          serviceType: serviceType,
        },
      });
    }
  }

  const getNextRecordId = () => {
    const nextIndex = telPatientCurrentIndex + 1;
    if (nextIndex < patientNmsData.length) {
      const nextId = patientNmsData[nextIndex]._id;
      setTelPatientCurrentIndex(nextIndex);
      getUpdatedDetailForNMS(nextId);
    }
  };
  const getUpdatedDetailForNMS = async (serviceId) => {
    var service = await getClinicalServiceByClinicalServiceId(serviceId);
    if (service?.clinicalServiceBuilderResponseDTO) {
      getClinicalServiceResult(service, serviceId);
    } else {
      return null;
    }
  };
  async function handleAcceptConsent() {
    setCurrentIsNmsLoading(true);
    const res = new setServiceNms(
      NmsModel,
      value,
      null,
      null,
      true,
      clinicalServiceType,
      null,
      null,
      null,
      "",
      userFullName,
      "00000000-0000-0000-0000-000000000000",
      userEmail
    );
    // const respAdded = addNewTemplateSections(res);
    const resfinal = new setServiceNms(
      res,
      value,
      null,
      null,
      true,
      clinicalServiceType,
      null,
      null,
      3,
      "",
      userFullName,
      "00000000-0000-0000-0000-000000000000",
      userEmail
    );
    const timelineElementsObj = [
      {
        name: currentIndex !== 3 ? "declines" : "Follow up declined",
        title: currentIndex !== 3 ? "declines Nms on " : "Follow up declined",
        value: currentIndex !== 3 ? "" : "Follow up declined",
        listItems:
          currentIndex !== 3
            ? []
            : [
                {
                  label:
                    value === 3
                      ? followUpcomments
                      : "Patient did not part take in follow up",
                  value: "true",
                  type: "Radio",
                },
              ],
        elementType: currentIndex !== 3 ? 1 : 8,
      },
    ];
    const resTimeline = new setAddTimelineItem(
      userEmail,
      userFullName,
      timelineElementsObj,
      currentIndex !== 3 ? 8 : 24,
      clinicalServiceId
    );

    const clinicalServiceWtihTimeline = {
      timeline: resTimeline,
      clinicalServiceBuilderResponseDTO: resfinal,
    };
    const res1 = await addNms(clinicalServiceWtihTimeline, isOrbitUser);
    setCurrentIsNmsLoading(false);
    if (isOrbitUser === true) {
      const obj = {
        clinicalServiceID: clinicalServiceId,
        notes: value === 3 ? followUpcomments : declineComments,
        userName: userFullName,
        date: new Date(),
        stageType: value,
      };
      await addServiceNotes(obj);
      await orbitAuditAdd(value);
      setCurrentDeclineComments("");
    }
    isOrbitUser || isOrbitSwitch
      ? navigate(`/orbit`)
      : navigate(`/pharmacyservices/${pharmacyID}`, {
          state: {
            serviceType: serviceType,
          },
        });
  }
  async function addTimelineItemService(elementType, elementEvent) {
    const elements = [
      {
        name: userFullName,
        title: " calling patient ",
        value: " calling patient ",
        listItems: [],
        elementType: elementType,
      },
    ];
    const res = new setAddTimelineItem(
      userEmail,
      userFullName,
      elements,
      elementEvent,
      clinicalServiceId
    );

    const savedTimelineItemData = addTimelineItem(res);
    savedTimelineItemData.then((result) => {
      if (!result) {
        showError("Timeline Item could not be added.", true, false);
      }
    });
  }

  function addNewTemplateSections(model) {
    const newTemplateSection = model?.stages[value]?.template?.templateSections;
    const updatedSections = [...newTemplateSection, ...MedicineTemplates];

    const res = new setAddTemplate(model, value, updatedSections, userFullName);
    setCurrentNmsModel(res);
    return res;
  }

  function ChangeFollowUpModel(model) {
    setFollowupModel(model);
  }
  async function UpdateNmsModel(title, userControls, groupId) {
    const res = new setServiceNms(
      NmsModel,
      value,
      title,
      userControls,
      false,
      clinicalServiceType,
      null,
      null,
      null,
      "",
      userFullName,
      groupId,
      userEmail
    );
    if (isOrbitUser === true) {
      const NmsModelString = JSON.stringify(res);
      localStorage.setItem(clinicalServiceId, NmsModelString);
    }
    setCurrentNmsModel(res);
  }
  function ChangeSearchPatient(patientId, patientName) {
    const res = new setServiceNms(
      NmsModel,
      value,
      null,
      null,
      false,
      clinicalServiceType,
      patientId,
      patientName,
      null,
      "",
      userFullName,
      "00000000-0000-0000-0000-000000000000",
      userEmail
    );
    setPatientName(patientName);

    setCurrentNmsModel(res);
  }
  const AddTemplateSection = async () => {
    const newSection = new addedTemplate(
      `${MedicineTemplates?.length + 1}) Add Medicine`
    ).obj;

    setMedicineTemplates((prevSections) => [...prevSections, newSection]);
    //add in nms
  };
  const UpdateTemplateModel = async (title, UserControls, active) => {
    const resp = new setTemplateModel(
      MedicineTemplates,
      title,
      UserControls,
      active
    ).obj;
    setMedicineTemplates(resp);
  };
  function removeTemplateItem(title) {
    const updatedData = MedicineTemplates.filter(
      (item) => item.sectionTitle !== title
    );
    setMedicineTemplates(updatedData);
  }

  function handleCloseConsent() {
    setIsConsent(false);
    setIsFollowUpControl(false);
  }

  const [showResponsiveSidebar, setShowResponsiveSidebar] = useState(false);

  const handleNMSSidebar = () => {
    if (window.innerWidth < 1025) {
      setShowResponsiveSidebar(!showResponsiveSidebar);
    }
  };

  const getStageNameAndCurrentIndex = () => {
    {
      service?.stages?.map((stage, index) => {
        if (currentIndex === 0 && stage?.clinicalServiceStageStatus !== 2) {
          currentIndex = index;
        }
        if (stage?.clinicalServiceStageStatus !== 2) {
          if (stageName === "") {
            stageName = stage?.stageName;
          }
          if (index !== 0 && previousStageName === "") {
            switch (index) {
              case 1:
                previousStageName = service?.stages[index - 1]?.stageName;
                break;
              case 2:
                previousStageName = service?.stages[index - 2]?.stageName;
                break;
              case 3:
                previousStageName = service?.stages[index - 1]?.stageName;
                break;
              default:
                previousStageName = service?.stages[index - 1]?.stageName;
            }
          }
        }
      });
    }
  };
  function validateComplete(indexNo) {
    let isValidate = false;
    let isFollowup = false;
    let filledControlArray = [];
    if (indexNo === 3) {
      NmsModel?.stages[indexNo]?.template?.templateSections?.forEach((item) => {
        if (
          item.sectionTitle === "Follow up" ||
          item.sectionTitle === "Follow Up Rejection"
        ) {
          const hasFilledControl = item?.userControls.some(
            (control) => control.value === "true"
          );
          if (hasFilledControl === true) {
            filledControlArray.push(hasFilledControl);
          }
        }
      });
      if (filledControlArray.includes(true)) {
        isFollowup = true;
      }
    }

    if (isFollowup === false) {
      NmsModel?.stages[indexNo]?.template?.templateSections?.forEach((item) => {
        if (
          item.isRequired &&
          item.sectionTitle !== "Follow up" &&
          item.sectionTitle !== "Follow Up Rejection"
        ) {
          const hasFilledControl = item?.userControls.some(
            (control) => control.value === "true"
          );
          filledControlArray.push(hasFilledControl);
        }
      });
    }
    if (filledControlArray.includes(false)) {
      isValidate = true;
    }
    if (isOrbitUser === true) {
      if (NmsModel?.stages[indexNo]?.TotalAttempts === "0") {
        isValidate = true;
      }
    }
    return isValidate;
  }
  function setCountColor(indexNo, groupId, title) {
    let isValidate = false;
    let filledControlArray = [];
    NmsModel?.stages[indexNo]?.template?.templateSections?.forEach((item) => {
      if (groupId === "00000000-0000-0000-0000-000000000000") {
        if (
          item?.order !== 99 &&
          item.sectionTitle === title &&
          item?.isRequired === true
        ) {
          const hasFilledControl = item?.userControls?.some(
            (control) => control.value === "true"
          );
          filledControlArray.push(hasFilledControl);
        }
      } else {
        if (
          item?.isParent === false &&
          groupId === item.groupId &&
          item?.isRequired === true
        ) {
          const hasFilledControl = item?.userControls?.some(
            (control) => control.value === "true"
          );
          filledControlArray.push(hasFilledControl);
        }
      }
    });
    if (filledControlArray.includes(false)) {
      isValidate = true;
    }
    return isValidate;
  }

  const getCompleteButtonLabel = (indexNo) => {
    {
      var label =
        indexNo === 0
          ? "Complete Engagement"
          : indexNo === 1
          ? "Complete Engagement"
          : indexNo === 2
          ? "Complete Intervention"
          : "Complete Follow Up";

      return label;
    }
  };

  const getClinicalServiceResult = (service, serviceId) => {
    const { clinicalServiceBuilderResponseDTO, pharmacy } = service || {};
    const { pharmacyID } = pharmacy || {
      pharmacyID: "00000000-0000-0000-0000-000000000000",
    };
    const { patientId, prescriptionIncomingId, telephoneNumber } =
      clinicalServiceBuilderResponseDTO || {
        patientId: "00000000-0000-0000-0000-000000000000",
        prescriptionIncomingId: "00000000-0000-0000-0000-000000000000",
        telephoneNumber: null,
      };
    if (isOrbitUser) {
      getTotalSkips({
        clinicalServiceId: serviceId,
        sessionId: currentSessionId,
      });
    }
    getFollowupDetailByClinicalServiceId(serviceId);
    getServiceNotesByClinicalServiceId(serviceId);
    getTimelineDataForClinicalServiceId(serviceId);
    if (telephoneNumber !== null || telephoneNumber !== "") {
      getPatientByTelephoneAndStageIndex(telephoneNumber, serviceId);
    } else {
      setCurrentPatientNMSData([]);
    }
    if (prescriptionIncomingId !== null) {
      getPrescriptionByIncomingId({
        prescriptionIncomingId,
        pharmacyID,
      }).then((data) => {
        prescriptionImageData(data, pharmacyID);
      });
    }
    if (
      patientId !== null &&
      patientId !== "00000000-0000-0000-0000-000000000000"
    ) {
      const clinicalServiceId = serviceId;
      getTitanPatient({ pharmacyID, patientId, clinicalServiceId }).then(
        (data) => {
          prescriptionPatientImage(data);
        }
      );
    }
    setCurrentIsServiceNotesLoading(false);
    setCurrentIsNmsTimelineLoading(false);
  };

  const getFollowupDetailByClinicalServiceId = (clinicalServiceId) => {
    const currentNmsState = store.getState().nmsService;
    const { value } = currentNmsState || {};
    const stageType = stageTypes[value];
    getFollowUpByServiceIdAndServiceType(clinicalServiceId, "1", stageType);
  };

  const getPatientByTelephoneAndStageIndex = (telephone, serviceId) => {
    const currentNmsState = store.getState().nmsService;
    const { value } = currentNmsState || {};
    getPatientByTelephoneAndStageIndexResult(
      telephone,
      value,
      serviceId,
      currentSessionId,
      isOrbitUser
    );
    setCurrentIsPatientNmsTelephoneLoading(false);
  };

  const getServiceNotesByClinicalServiceId = (clinicalServiceId) => {
    const currentNmsState = store.getState().nmsService;
    const { value } = currentNmsState || {};
    const stageNo = value;
    const stageType = stageTypes[stageNo];

    getServiceNotes({ clinicalServiceId, stageType });
  };

  const getTimelineDataForClinicalServiceId = (serviceId) => {
    setCurrentIsNmsTimelineLoading(true);
    let currentAppState = store.getState().app;
    const { isOrbitUser, isOrbitSwitch } = currentAppState;
    const currentNmsState = store.getState().nmsService;
    const { clinicalServiceId } = currentNmsState || {};
    getTimeline(isOrbitUser || isOrbitSwitch ? clinicalServiceId : serviceId);
  };
  const prescriptionImageData = async (data, pharmacyID) => {
    if (data) {
      const { prescriptionGetModel } = data || {};
      const { prescriptionImages } = prescriptionGetModel ?? [];
      const resultArray = [];

      if (prescriptionImages) {
        for (const imageValue of prescriptionImages) {
          const result = await getPatientImage({
            imageValue,
            pharmacyID,
          });
          resultArray.push(`data:image/png;base64, ${result}`);
        }
        if (resultArray?.length > 0) {
          setCurrentArrayImg(resultArray);
        } else {
          setCurrentArrayImg([]);
        }
      }
    }
    setCurrentIsNmsPrescriptionLoading(false);
  };

  const prescriptionPatientImage = async (patientData) => {
    if (patientData) {
      const currentNmsState = store.getState().nmsService;
      const { prescriptionImages, pharmacyID } = currentNmsState || {};
      const { patientGetModel } = patientData || {};
      const patientName = `${patientGetModel.title} ${patientGetModel.forename} ${patientGetModel.middleName} ${patientGetModel.surname}`;
      setCurrentPatientName(patientName);
    }
    setCurrentIsNmsPatientLoading(false);
  };

  return (
    <div>
      {isNmsLoading === false && service === null ? (
        <NopatientPopup screen="Nms" />
      ) : (
        <div>
          {/* {isActive && (
            <div className="orbit_bg">
              <Lottie
                animationData={darkMode ? OrbitDarkBGNew : OrbitLightBG}
              />
            </div>
          )} */}
          <div
            className="page-content nms"
            style={{
              background: isActive ? "unset" : darkMode ? "#111" : "#fff",
            }}
          >
            {getStageNameAndCurrentIndex()}
            <ServicesHeader
              serviceType={serviceType}
              currentIndex={currentIndex}
              stageName={stageName}
              stageNo={value}
              SkipClincialService={SkipClincialService}
              parkClinicalService={parkClinicalService}
              validateComplete={validateComplete}
              declineNms={handleAcceptConsent}
              submitNms={submitNms}
              addAuditForSkipAndAttempt={addAuditForSkipAndAttempt}
            />

            <div className="main-div">
              <ConsentModal
                isOpen={isConsent}
                handleCloseConsent={handleCloseConsent}
                handleAcceptConsent={handleAcceptConsent}
              />
              <GroupedPatients />
              <div className="main-div-inner">
                <div
                  className={`nms-tabs-container nms ${
                    showResponsiveSidebar ? "show-nms-sidebar" : ""
                  }`}
                >
                  <h2 className="img-heading para_color">
                    <FaRegUser />
                    {isNmsLoading === true ? (
                      <Skeleton
                        sx={{ bgcolor: darkMode ? "grey.800" : "" }}
                        height={30}
                        width={100}
                        animation="wave"
                        variant="rounded"
                      />
                    ) : (
                      service?.patientName
                    )}
                  </h2>
                  <NmsDetails
                    prescriptionImages={arrayImg}
                    Nmsdetails={patientData}
                    darkModeChange={darkMode}
                    stageName={previousStageName}
                  />
                </div>
                <div
                  className="sidebar-hamburger nms-hamburger"
                  onClick={handleNMSSidebar}
                >
                  {showResponsiveSidebar ? (
                    <div className="nms-ham">
                      <FaTimes />
                      <span> Close Patient Details</span>
                    </div>
                  ) : (
                    <div className="nms-ham">
                      <PiCaretRightBold />
                      <span> Patient Details</span>
                    </div>
                  )}
                </div>
                <div className="content-div">
                  <div className="custom-staging-tabs-container nms-content-area">
                    {isNmsLoading ? (
                      <LoadingComponentNMS />
                    ) : (
                      service?.stages[value].template?.templateSections?.map(
                        (item, index) => {
                          if (
                            item.isParent ||
                            item.groupId ===
                              "00000000-0000-0000-0000-000000000000"
                          ) {
                            count++;
                            return (
                              <>
                                <TemplateSection
                                  key={index}
                                  UserControls={item.userControls}
                                  number={count}
                                  SectionTitle={item.sectionTitle}
                                  groupId={item.groupId}
                                  templateSection={
                                    service?.stages[value].template
                                      .templateSections
                                  }
                                  stageStatus={
                                    service?.stages[value]
                                      ?.clinicalServiceStageStatus === 2
                                      ? true
                                      : false
                                  }
                                  boxStyling={boxStyling}
                                  order={item.order}
                                  Type={item.templateSectionType}
                                  handleChangeModel={UpdateNmsModel}
                                  isCompleted={isRequired}
                                  pharmacyId={pharmacyID}
                                  ClinicalpatientId={clinicalpatientId}
                                  clinicalServiceId={clinicalServiceId}
                                  darkModeChange={darkMode}
                                  stageNo={value}
                                  handleFollowUpModel={ChangeFollowUpModel}
                                  patientName={patientName}
                                  patientNameChange={null}
                                  serviceID={service?.serviceID}
                                  templateRequired={setCountColor(
                                    value,
                                    item?.groupId,
                                    item?.sectionTitle
                                  )}
                                />
                                {isFollowUpControl && (
                                  <FollowUpModal
                                    isOpen={isFollowUpControl}
                                    handleAcceptConsent={handleAcceptConsent}
                                  />
                                )}
                              </>
                            );
                          }
                        }
                      )
                    )}

                    <NMSNotes count={count} stageNo={value} />
                  </div>
                </div>
              </div>
            </div>
            {!isOrbitUser && (
              <div className="action-footer">
                <div className="action-footer-cont">
                  {isNmsLoading ? (
                    <Skeleton
                      sx={{ bgcolor: darkMode ? "grey.800" : "" }}
                      height={30}
                      width={150}
                      animation="wave"
                      variant="rounded"
                    />
                  ) : (
                    <>
                      <CustomButton label="Exit" action={exitClinicalService} />
                      {isOrbitUser && (
                        <CustomButton
                          className="nms-do-another"
                          label="Skip"
                          action={SkipClincialService}
                        />
                      )}
                      {isOrbitUser && (
                        <CustomButton
                          className="nms-do-another"
                          label="Park"
                          action={parkClinicalService}
                        />
                      )}
                      <CustomButton
                        className="nms-do-another"
                        label={
                          currentIndex === 3
                            ? `Decline ${stageName}`
                            : "Decline"
                        }
                        action={() => {
                          declineNms();
                        }}
                      />

                      <CustomButton
                        className="nms-do-another complete-button"
                        label={getCompleteButtonLabel(value)}
                        action={() => {
                          submitNms(value);
                        }}
                        isdisable={validateComplete(value)}
                      />
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {isOrbitUser && groupedPatientWarning && (
        <GroupedPatientsPopup getUpdatedDetailForNMS={getUpdatedDetailForNMS} />
      )}
    </div>
  );
}

export default NmsIntervention;
