import React from "react";
import pharmacyImagePlaceholder from "../DashboardListing/assets/pharmacyImagePlaceholder";
import { AiFillCloseCircle } from "react-icons/ai";
import { motion } from "framer-motion";

const PharmacyTooltip = ({
  pharmacyName,
  pharmacyAddress,
  pharmacyImg,
  setHoveredMarker,
  kpiData,
}) => {
  const handleCloseClick = () => {
    setHoveredMarker(false);
  };

  return (
    <motion.div className="tooltip-container">
      <div className="tooltip-pharmacy-info">
        <div
          className="pharmacy-image"
          style={{
            borderBottom: `${
              pharmacyImg !== null ? "1px solid #eee" : "2px solid #aaa"
            }`,
          }}
        >
          <img
            style={{
              objectFit: `${
                pharmacyImg !== null || pharmacyImg ? "cover" : ""
              }`,
              height: `${pharmacyImg !== null || pharmacyImg ? "100%" : ""}`,
              width: `${pharmacyImg !== null || pharmacyImg ? "100%" : ""}`,
            }}
            src={pharmacyImg !== null ? pharmacyImg : pharmacyImagePlaceholder}
          />
        </div>
        <div className="tooltip-close" onClick={handleCloseClick}>
          <AiFillCloseCircle size={30} />
        </div>
        <div className="tooltip-content">
          <h6>{pharmacyName}</h6>
          <p>{pharmacyAddress}</p>

          {/* <div className="tooltip-progressbar-wrapper">
            {kpiData?.map((kpi, index) => (
              <div key={index} className="progress-bar-wrapper">
                <div className="progress-bar-flex">
                  <p>{kpi?.kpiName}</p>
                  <p
                    className="progress-bar-rating"
                    style={{
                      background: `${
                        kpi?.kpiVal <= 60
                          ? "#e55542"
                          : kpi?.kpiVal > 60 && kpi?.kpiVal <= 80
                          ? "#eddd00"
                          : "#51c198"
                      }`,
                    }}
                  >
                    {kpi?.kpiVal != null ? kpi?.kpiVal : 0}
                  </p>
                </div>
                <div className="progress-bar">
                  <div
                    className="progress-bar-filled"
                    style={{
                      width: `${
                        kpi?.kpiVal || kpi?.kpiVal !== 0 ? kpi?.kpiVal : "0"
                      }%`,
                      background: `${
                        kpi?.kpiVal <= 60
                          ? "#e55542"
                          : kpi?.kpiVal > 60 && kpi?.kpiVal <= 80
                          ? "#eddd00"
                          : "#51c198"
                      }`,
                    }}
                  ></div>
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </motion.div>
  );
};

export default PharmacyTooltip;
