import { v4 as uuidv4 } from "uuid";

let _id = uuidv4();
class setAddDocument {
  constructor(
    documentName,
    documentTitle,
    Pharmacy_ID,
    documentType,
    base64File,
    serviceID,
    createdBy
  ) {
    this.obj = {
      documentName: documentName,
      documentTitle: documentTitle,
      pharmacyID: Pharmacy_ID,
      ods: "Ods",
      createdAt: new Date().toISOString().slice(0, 10),
      createdBy: createdBy,
      documentStatus: 1,
      docuemnt: base64File,
      serviceID: serviceID,
    };

    switch (documentType?.toLowerCase()) {
      case "application/pdf":
        this.obj.documentType = 0;
        break;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        this.obj.documentType = 1;
        break;
      case "image/jpeg":
        this.obj.documentType = 2;
        break;
      case "text/csv":
        this.obj.documentType = 3;
        break;
      default:
        this.obj.documentType = 4;
        break;
    }
  }
}

export default setAddDocument;
