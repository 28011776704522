import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { FaPlus } from "react-icons/fa";
import CloseIcon from "@mui/icons-material/Close";
import useTitanType from "../../../../../../../../hooks/useTitanType";
import { setCurrentStages } from "../../../../../../../../dispatchers/typeDispatchers";
import useTitanTemplate from "../../../../../../../../hooks/useTitanTemplate";
import AddStage from "../../../../../../../../Model/AddStage/AddStage";

export default function TypesStages() {
  const { templateState } = useTitanTemplate();
  const { typeState } = useTitanType();
  const { currentStages } = typeState || {};
  const { currentTemplates } = templateState || {};

  function handleAddStage() {
    const newStage = new AddStage().obj;
    const updatedStages = [...currentStages, newStage];
    setCurrentStages(updatedStages);
  }

  function handleDeleteStage(objectToDelete) {
    const updatedStages = currentStages.filter(
      (control) => control !== objectToDelete
    );
    setCurrentStages(updatedStages);
  }

  function handleChangeStage(index, event) {
    const { name, value } = event?.target || {};
    const updatedStages = [...currentStages];
    updatedStages[index] = { ...updatedStages[index] };
    updatedStages[index][name] = value;
    setCurrentStages(updatedStages);
  }

  return (
    <>
      {currentStages?.map((stage, index) => {
        return (
          <div key={index}>
            <div className="close_icon">
              <CloseIcon onClick={() => handleDeleteStage(stage)} />
            </div>
            <div className="title_field">
              <TextField
                className="template_input"
                id="outlined-basic"
                label="Name"
                variant="outlined"
                fullwidth
                name="stageName"
                onChange={(event) => handleChangeStage(index, event)}
                value={stage?.stageName}
              />
            </div>

            <div className="settings_formFields">
              <div className="title_field">
                <FormControl fullwidth className="template_input">
                  <InputLabel id="demo-simple-select-filled-label">
                    Template
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={
                      currentTemplates?.length > 0 ? stage?.templateId : ""
                    }
                    name="templateId"
                    onChange={(event) => handleChangeStage(index, event)}
                    className="title-select"
                  >
                    {currentTemplates?.map((temp, index) => {
                      return (
                        <MenuItem value={temp._id} key={index}>
                          {temp.templateName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="title_field">
                <FormControl fullwidth className="template_input">
                  <InputLabel id="demo-simple-select-filled-label">
                    Order
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={stage?.order}
                    name="order"
                    onChange={(event) => handleChangeStage(index, event)}
                    className="title-select"
                  >
                    <MenuItem value={0}>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        );
      })}
      {currentStages?.length > 3 ? (
        <></>
      ) : (
        <div
          className="addTeam_icon label-btn plus_icon"
          onClick={handleAddStage}
        >
          <FaPlus size={10} className="icons-box" />
        </div>
      )}
    </>
  );
}
