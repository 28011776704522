import React from "react";
import { Skeleton } from "@mui/material";
import useTitanApp from "../../hooks/useTitanApp";

export default function LoadingComponentCC() {
  const { appState } = useTitanApp();
  const { darkMode } = appState;
  return (
    <div className="ccSkeleton">
      <div className="left">
        <div className="medicine-card customcard loading-card-one">
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.800" : "" }}
            height={30}
            width={200}
            animation="wave"
            variant="rounded"
          />
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.800" : "" }}
            height={700}
            animation="wave"
            variant="rounded"
          />
        </div>
      </div>
      <div className="right">
        <div className="medicine-card customcard loading-card-one">
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.800" : "" }}
            height={35}
            width={100}
            animation="wave"
            variant="rounded"
          />
          <div className="flex">
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", width: "100%" }}
              height={65}
              animation="wave"
              variant="rounded"
            />
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", width: "100%" }}
              height={65}
              animation="wave"
              variant="rounded"
            />
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", width: "100%" }}
              height={65}
              animation="wave"
              variant="rounded"
            />
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", width: "100%" }}
              height={65}
              animation="wave"
              variant="rounded"
            />
          </div>
        </div>
        <div className="medicine-card customcard loading-card-one">
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.800" : "" }}
            height={35}
            width={100}
            animation="wave"
            variant="rounded"
          />
          <div className="flex">
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", width: "100%" }}
              height={65}
              animation="wave"
              variant="rounded"
            />
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", width: "100%" }}
              height={65}
              animation="wave"
              variant="rounded"
            />
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", width: "100%" }}
              height={65}
              animation="wave"
              variant="rounded"
            />
          </div>
          <div className="flex">
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", width: "100%" }}
              height={65}
              animation="wave"
              variant="rounded"
            />
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", width: "100%" }}
              height={65}
              animation="wave"
              variant="rounded"
            />
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", width: "100%" }}
              height={65}
              animation="wave"
              variant="rounded"
            />
          </div>
        </div>
        <div className="medicine-card customcard loading-card-one">
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.800" : "" }}
            height={35}
            width={100}
            animation="wave"
            variant="rounded"
          />
          <div className="flex">
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", width: "100%" }}
              height={65}
              animation="wave"
              variant="rounded"
            />
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", width: "100%" }}
              height={65}
              animation="wave"
              variant="rounded"
            />
          </div>
          <div className="flex">
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", width: "100%" }}
              height={65}
              animation="wave"
              variant="rounded"
            />
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", width: "100%" }}
              height={65}
              animation="wave"
              variant="rounded"
            />
          </div>
        </div>
        <div className="medicine-card customcard loading-card-one">
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.800" : "" }}
            height={35}
            width={100}
            animation="wave"
            variant="rounded"
          />
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.800" : "" }}
            height={35}
            animation="wave"
            variant="rounded"
          />
        </div>
      </div>
    </div>
  );
}
