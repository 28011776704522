import { v4 as uuidv4 } from "uuid";
import audit from "./audit";
import Medicationtime from "./Medicationtime";
let patientMedicationId = uuidv4();
class setAddMedication {
  constructor(response, medicationobj, MedicationTimeModel, isInterim) {
    this.obj = {
      PatientMedicationTime: new Medicationtime(
        MedicationTimeModel,
        patientMedicationId
      ).obj,
      Audit: response?.audit
        ? response?.audit
        : [
            {
              PatientMedicationAuditId: uuidv4(),
              PatientMedicationId: response?.patientMedicationId
                ? response?.patientMedicationId
                : patientMedicationId,
              PatientMedicationAuditTypeId: 0,
              Value: "string",
            },
          ],
      times: response?.times ? response?.times : "",
      patientMedicationId: response?.patientMedicationId
        ? response?.patientMedicationId
        : patientMedicationId,
      patientId: medicationobj?.patientId ? medicationobj?.patientId : "",
      prescribedItemId: medicationobj.prescribedItemId
        ? medicationobj.prescribedItemId
        : "",
      dosageText: medicationobj.dose,
      medicationStatus:
        medicationobj.archive === false
          ? 1
          : response?.medicationStatus
          ? response?.medicationStatus
          : 0,
      preparationType: medicationobj.preparationType,
      repeatQuantity: medicationobj.quantity,
      numberOfTrays: response?.numberOfTrays ? response?.numberOfTrays : 0,
      allowPrintLabel: response?.allowPrintLabel,
      showOnReport: medicationobj.report,
      colour: medicationobj.color ? medicationobj.color : "Green",
      marking: medicationobj.marking ? medicationobj.marking : "",
      isInterim: isInterim === "" ? response?.isInterim : isInterim,
      isPrn: response?.isPrn ? response?.isPrn : false,
      managedBy: response?.managedBy ? response?.managedBy : 0,
      cycleDays: response?.cycleDays ? response?.cycleDays : 0,
      uom: response?.uom ? response?.uom : "",
      lastStartDate: medicationobj.startDate,
      needsInterimReport: response?.needsInterimReport
        ? response?.needsInterimReport
        : false,
      preferredProductId: response?.preferredProductId
        ? response?.preferredProductId
        : uuidv4(),
      therapyType: medicationobj.therapyType,
      neverAutoArchive: medicationobj.archive,
      transactionId: response?.transactionId ? response.transactionId : "",
    };
  }
}

export default setAddMedication;
