import React, { useEffect } from "react";
import { ReactComponent as PinSvg } from "./../OverviewIcons/pin.svg";
import { ReactComponent as MenuSvg } from "./../OverviewIcons/menu.svg";
import { ReactComponent as CalendarSvg } from "./../OverviewIcons/calendar.svg";
import { color } from "framer-motion";

export default function PharmacyNotes({ seenMembers, onDarkModeChange }) {
  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <h3 className="pharmacy_header_name">Pharmacy Notes</h3>
      <div className="overview-boxes">
        <div className="overview-headings">
          <div className="overview-iconBox">
            <PinSvg />
            <h5>Pinned</h5>
          </div>
          <div className="menu-icon">
            <MenuSvg />
          </div>
        </div>
        <div className="overview-content">
          <p>
            Dennis Dingleberry will arrive back at 2:30pm. He is expecting 12
            packs of Simvistatin (100mg) to be ready for collection.
          </p>
        </div>
        <div className="seenBox">
          <p>Seen (6)</p>
          <div className="seenMembers">
            {seenMembers?.map((member, index) => (
              <div className="team-img-text" key={index}>
                <div className="team-img label-btn">
                  <img src={member.image} />
                </div>
                <div className="team-text">
                  <p>{member.name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="cal-date">
          <CalendarSvg />
          <p>7th June 2023 (11:02am)</p>
        </div>
      </div>
      <div className="overview-boxes">
        <div className="overview-headings">
          <div className="overview-iconBox">
            <h5>General Notice</h5>
          </div>
          <div>
            <MenuSvg />
          </div>
        </div>
        <div className="overview-content">
          <p>
            James Phillips will arrive back at 2:30pm. He is expecting 12 packs
            of Simvistatin (100mg) to be ready for collection.
          </p>
        </div>
        <div className="seenBox">
          <p>Seen (6)</p>
          <div className="seenMembers">
            {seenMembers?.map((member, index) => (
              <div className="team-img-text" key={index}>
                <div className="team-img label-btn">
                  <img src={member.image} />
                </div>
                <div className="team-text">
                  <p>{member.name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="cal-date">
          <CalendarSvg />
          <p>7th June 2023 (11:02am)</p>
        </div>
      </div>
    </React.Fragment>
  );
}
