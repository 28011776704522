import React, { useState, useRef } from "react";
import OrbitSettingsSidebar from "../../OrbitSettingsSidebar/OrbitSettingsSidebar";
import Select from "react-select";
import { CgDanger } from "react-icons/cg";
import { Calendar } from "primereact/calendar";
import { FileUpload } from "primereact/fileupload";
import CustomButton from "../../controls/CustomButton";
import { HiMiniXMark } from "react-icons/hi2";
import AsyncRendererRouter from "../../AsyncRendererRouter/AsyncRendererRouter";
import { useLoaderData } from "react-router-dom";
import useTitanApp from "../../../hooks/useTitanApp";
import useTimer from "../../../hooks/useTimer";
import CustomLoader from "../../controls/CustomLoader";
import {
  saveOrbitProfile,
  setCurrentOrbitProfile,
} from "../../../dispatchers/orbitDispatchers";
import { showError } from "../../ToastedMessage/ToastedMessage";
import AccreditationsSkillsFiles from "../AccreditationsSkills/AccreditationsSkillsFiles";
export default function AccreditationsSkills() {
  const fileRef = useRef(null);
  const { timer, isActive, handleStart, handleReset } = useTimer();
  const { orbitState, appState } = useTitanApp();
  const { darkMode } = appState || {};
  const { isOrbitLoading, orbitProfile, accredationsDetails } =
    orbitState || {};
  const { appData } = useLoaderData();
  const options = [
    { value: "", label: "Select", name: "documentType" },
    {
      value: "Accuracy Checking Technician",
      label: "Accuracy Checking Technician",
      name: "documentType",
    },
    { value: "Audiologist", label: "Audiologist", name: "documentType" },
    {
      value: "Contact Lens Optician",
      label: "Contact Lens Optician",
      name: "documentType",
    },
    {
      value: "Dispensing Optician",
      label: "Dispensing Optician",
      name: "documentType",
    },
    { value: "Driver", label: "Driver", name: "documentType" },
    {
      value: "Health Care Assistant",
      label: "Health Care Assistant",
      name: "documentType",
    },
    { value: "Lab Tech", label: "Lab Tech", name: "documentType" },
    { value: "Nurse", label: "Nurse", name: "documentType" },
    {
      value: "Ophthalmic Medical Practitioner",
      label: "Ophthalmic Medical Practitioner",
      name: "documentType",
    },
    {
      value: "Optical Assistant",
      label: "Optical Assistant",
      name: "documentType",
    },
    { value: "Optometrist", label: "Optometrist", name: "documentType" },
    { value: "Pharmacist", label: "Pharmacist", name: "documentType" },
    {
      value: "Pharmacy Dispenser",
      label: "Pharmacy Dispenser",
      name: "documentType",
    },
    {
      value: "Pharmacy Technician",
      label: "Pharmacy Technician",
      name: "documentType",
    },
    { value: "Senior Carer", label: "Senior Carer", name: "documentType" },
  ];
  const [accreditationsArray, setAccredationsArray] = useState(
    accredationsDetails?.documentType !== "" ? accredationsDetails : []
  );
  const [selectExpiryDate, setExpiryDate] = useState(null);
  const [selectIssueDate, setIssueDate] = useState(null);
  const [selectDocumentType, setDocumentType] = useState("");

  const [selectedType, setSelectedType] = useState({
    documentType: "",
    documentDetails: {
      document: "",
      issueDate: null,
      expiryDate: null,
      requiredBy: "",
      notes: "",
      fileType: "",
      isUploaded: false,
    },
  });

  function handleChangeDocument(event, field, type) {
    const { name, value } = event || {};

    setSelectedType({
      ...selectedType,
      [field]: {
        ...selectedType[field],
        [name]: value,
        ["fileType"]: type,
        ["isUploaded"]: value != "" ? true : false,
      },
    });
  }
  function handleChangeDetails(event, field) {
    const { name, value } = event?.target || {};
    setSelectedType({
      ...selectedType,
      [field]: { ...selectedType[field], [name]: value },
    });
    setIssueDate(
      selectIssueDate ? selectIssueDate : name === "issueDate" ? value : null
    );
    setExpiryDate(
      selectExpiryDate ? selectExpiryDate : name === "expiryDate" ? value : null
    );
  }
  const onUpload = (event) => {
    const uploadedFiles = event?.files[0];
    if (uploadedFiles) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader?.result;
        handleChangeDocument(
          { value: base64Data, name: "document" },
          "documentDetails",
          getFileType(uploadedFiles?.name)
        );
      };
      reader?.readAsDataURL(uploadedFiles);
    }
  };
  const getFileType = (fileName) => {
    const extension = fileName?.split(".").pop().toLowerCase();
    if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
      return "image";
    } else if (["pdf"].includes(extension)) {
      return "pdf";
    } else if (["doc", "docx"].includes(extension)) {
      return "word";
    } else {
      return "unknown";
    }
  };
  function handleRemoveFiles() {
    handleChangeDocument({ value: "", name: "document" }, "documentDetails");
  }
  function handleChangeDropdown(event) {
    const { name, value } = event || {};
    setSelectedType({
      ...selectedType,
      [name]: value,
    });
    setDocumentType(name === "documentType" ? value : "");
  }
  function handleSave() {
    const obj = {
      ...orbitProfile,
      accreditations: accreditationsArray,
    };
    saveOrbitProfile(obj)
      .then((result) => {
        setCurrentOrbitProfile(result);
      })
      .catch(() =>
        showError("Accredations and Skills could not be added.", true, false)
      );
  }

  function handleAddDocuments() {
    const indexToUpdate = accreditationsArray?.findIndex(
      (item) => item?.documentType === selectedType?.documentType
    );
    if (indexToUpdate !== -1) {
      // If found, replace the element at that index with newSelectedType
      const newArray = [...accreditationsArray];
      newArray[indexToUpdate] = selectedType;
      setAccredationsArray(newArray);
    } else {
      // If not found, add newSelectedType to the array
      setAccredationsArray([...accreditationsArray, selectedType]);
    }

    setSelectedType({
      documentType: "",
      documentDetails: {
        document: "",
        issueDate: null,
        expiryDate: null,
        requiredBy: "",
        notes: "",
        fileType: "",
        isUploaded: false,
      },
    });
    setExpiryDate(null);
    setIssueDate(null);
    setDocumentType("");
    fileRef?.current?.clear();
  }
  function handleRemoveDocuments(docType) {
    const newArray = accreditationsArray.filter(
      (item) => item.documentType !== docType
    );

    setAccredationsArray(newArray);
  }

  return (
    <AsyncRendererRouter
      isLoading={isOrbitLoading}
      resolve={appData}
      loadingElement={
        <div className="settings_page">
          <div className="settings_wrapper">
            <OrbitSettingsSidebar />
            <div className="settings_content_wrapper orbit_settings_content_wrapper">
              <CustomLoader />
            </div>
          </div>
        </div>
      }
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <OrbitSettingsSidebar />

          <div className="settings_content_wrapper">
            <div className="settings_heading">
              <h5>Accreditations and Skills</h5>
              <p>
                Add as many accreditations and skills as possible to build up
                your profile.
              </p>
            </div>
            <div className="AddNew_sectionbox accSkills_sections">
              <div className="select_docType">
                <p>Select Document Type</p>
                <Select
                  name="document"
                  options={options}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  defaultValue={selectDocumentType}
                  onChange={(e) => {
                    handleChangeDropdown(e);
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? "grey" : "grey",
                      boxShadow: state.isFocused ? "grey" : "grey",
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: darkMode ? "#222" : "#aaa",
                      primary: darkMode ? "#222" : "#aaa",
                    },
                  })}
                />
              </div>
              <div className="input_area">
                <div className="settings_formFields">
                  <div className="orbitSettings_calendarBox">
                    <p>Issue Date</p>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      name="issueDate"
                      className="orbitSettings_calendar"
                      value={
                        selectIssueDate
                          ? new Date(selectedType?.documentDetails?.issueDate)
                          : null
                      }
                      onChange={(e) =>
                        handleChangeDetails(e, "documentDetails")
                      }
                      showIcon
                    />
                  </div>
                  <div className="orbitSettings_calendarBox">
                    <p>Expiry Date</p>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      name="expiryDate"
                      className="orbitSettings_calendar"
                      value={
                        selectExpiryDate
                          ? new Date(selectedType?.documentDetails?.expiryDate)
                          : null
                      }
                      onChange={(e) =>
                        handleChangeDetails(e, "documentDetails")
                      }
                      showIcon
                      disabled={
                        selectedType?.documentDetails?.issueDate === null
                          ? true
                          : false
                      }
                      minDate={
                        selectedType?.documentDetails?.issueDate === null
                          ? new Date()
                          : new Date(selectedType?.documentDetails?.issueDate)
                      }
                    />
                  </div>
                </div>
                <div className="orbitSettings_uploadBox">
                  <p>Upload Document</p>
                  <div className="upload__image-wrapper upload_zone">
                    <FileUpload
                      ref={fileRef}
                      mode="basic"
                      name="document"
                      maxFileSize={1000000}
                      accept="image/*,.pdf,.doc,.docx"
                      onSelect={(e) => onUpload(e)}
                      onClear={handleRemoveFiles}
                      chooseLabel="Upload your file/image here"
                    />
                  </div>
                  <div className="add_button_doc_box">
                    <button
                      className="add_document"
                      onClick={handleAddDocuments}
                    >
                      Add
                    </button>
                  </div>

                  <div className="disclaimer">
                    <CgDanger />
                    <p>
                      By submitting this information, you are confirming that
                      all the information supplied is accurate and you can
                      provide physical evidence relating to this should you be
                      required to in future
                    </p>
                  </div>
                  {accreditationsArray?.length > 0 ? (
                    <div>
                      {accreditationsArray?.map((doc, index) => {
                        return (
                          <AccreditationsSkillsFiles
                            key={index}
                            Details={doc}
                            handleDocuments={handleRemoveDocuments}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="footer_btn">
              <CustomButton label="Save" action={handleSave} />
            </div>
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
