import { useSelector } from "react-redux";

const useTitanType = () => {
  // #region Hook Constants
  const typeState = useSelector((state) => state.type);
  // #endregion

  // #region App State Setters

  // #endregion

  // #region API getter/caller functions for components usage to dispatch REDUX extra reducers

  // #endregion

  // #region Loader Handlers

  // #endregion

  // #region Loaders for APIs call

  // #endregion

  // #region Return Object
  return {
    typeState: typeState,
  };
  // #endregion
};

export default useTitanType;
