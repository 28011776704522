import React from "react";
import "./../../assets/css/NmsIntervention/NmsIntervention.scss";
import "./../../assets/clinicalcheck.css";
import { Skeleton } from "@mui/material";
import useTitanApp from "../../hooks/useTitanApp";

export default function LoadingComponentNMSPatient() {
  const { appState } = useTitanApp();
  const { darkMode } = appState;
  return (
    <div className="loading-component-first loading-patient-tab">
      <div className="header">
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={35}
          width={100}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={45}
          width={160}
          animation="wave"
          variant="rounded"
        />
      </div>
      <div className="grid">
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={55}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={55}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={55}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={55}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={55}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={55}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={55}
          animation="wave"
          variant="rounded"
        />
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={55}
          animation="wave"
          variant="rounded"
        />
      </div>
      <div className="single">
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={35}
          animation="wave"
          variant="rounded"
        />
      </div>
      <Skeleton
        sx={{ bgcolor: darkMode ? "grey.800" : "" }}
        height={35}
        width={100}
        animation="wave"
        variant="rounded"
      />
      <div className="single">
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={35}
          animation="wave"
          variant="rounded"
        />
      </div>
    </div>
  );
}
