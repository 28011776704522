import React, { useState } from "react";
import "./../../../../assets/css/Dashboard/ServicesContainer.scss";
import { Tabs, Tab } from "@mui/material";
import ServicesListView from "./ServicesListView/ServicesListView";
import ServicesKanban from "./ServicesKanban/ServicesKanban";
import ServicesCalendar from "./ServicesCalendar/ServicesCalendar";
import ServicesAnalytics from "./ServicesAnalytics/ServicesAnalytics";
import ServicesDocuments from "./ServicesDocuments/ServicesDocuments";
import ServicesNewClinic from "./ServicesNewClinic/ServicesNewClinic";
import useTitanApp from "../../../../hooks/useTitanApp";
import commonData from "../../../../data/commonData";
import { setFilterTagsData } from "../../../../dispatchers/pharmacyServiceDispatchers";
import { getAllDocuments } from "../../../../dispatchers/documentDispatchers";
import {
  getEvents,
  getServiceAnalytics,
  getPharmacyServices,
  setServiceTab,
  setCurrentCalendarDates,
} from "../../../../dispatchers/pharmacyServiceDispatchers";
import TravelVaccineModal from "../../../../components/TravelVaccineModal/TravelVaccineModal";
import ServicesContainerFooter from "./ServicesListView/ServicesContainerFooter";

function ServicesContainer({ handleUpdateServices }) {
  const {
    kanbanIcon,
    listviewIcon,
    analyticsIcon,
    documentIcon,
    calendarIcon,
  } = commonData || {};

  const { appState, pharmacyServiceState } = useTitanApp();
  const { currentPharmacyId } = appState || {};

  const {
    isCalenderShow,
    serviceName,
    serviceIcon,
    newClinicComponent,
    currentServiceId,
    currentServiceTab,
  } = pharmacyServiceState || {};

  const [isPatientFormModal, setIsPatientFormModal] = useState(false);

  const handleChange = async (event, newValue) => {
    setServiceTab(newValue);
    switch (newValue) {
      case 2:
        getEvents(currentPharmacyId, currentServiceId);
        break;
      case 3:
        setCurrentCalendarDates([null, null]);
        getServiceAnalytics(currentPharmacyId, currentServiceId);
        break;
      case 4:
        getAllDocuments(currentPharmacyId, currentServiceId);
        break;
      case 1:
      default:
        setFilterTagsData([]);
        getPharmacyServices(
          currentPharmacyId,
          0,
          currentServiceId,
          isCalenderShow
        );

        break;
    }
  };

  function shortenText(text, maxLength) {
    if (text?.length > maxLength) {
      return text?.substring(0, maxLength) + "...";
    }
    return text;
  }

  const RenderSubTabs = () => {
    switch (currentServiceTab) {
      case 1:
        return (
          <ServicesListView
            setIsPatientFormModal={setIsPatientFormModal}
            handleChange={handleChange}
          />
        );
      case 2:
        return <ServicesCalendar />;
      case 3:
        return <ServicesAnalytics />;
      case 4:
        return <ServicesDocuments />;
      default:
        return (
          <ServicesKanban
            setIsPatientFormModal={setIsPatientFormModal}
            handleChange={handleChange}
          />
        );
    }
  };

  function shortenText(text, maxLength) {
    if (text?.length > maxLength) {
      return text?.substring(0, maxLength) + "...";
    }
    return text;
  }

  return (
    <div className="services_container">
      {isPatientFormModal && (
        <TravelVaccineModal
          show={isPatientFormModal}
          setShow={setIsPatientFormModal}
        />
      )}
      {newClinicComponent ? (
        <ServicesNewClinic handleUpdateServices={handleUpdateServices} />
      ) : (
        <>
          <div className="services_container_wrapper">
            <div className="tabs_box services_container-tabs">
              {serviceName ? (
                <div className="service-title">
                  <div className="services_icon">{serviceIcon}</div>

                  <p>{shortenText(serviceName, 50)}</p>
                </div>
              ) : (
                <></>
              )}

              <Tabs
                value={currentServiceTab}
                onChange={handleChange}
                aria-label="Tabs"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "black",
                  },
                }}
              >
                <Tab
                  icon={kanbanIcon}
                  iconPosition="start"
                  label={<span className={`tabs_text`}>Kanban</span>}
                />

                <Tab
                  icon={listviewIcon}
                  iconPosition="start"
                  label={<span className={`tabs_text`}>List</span>}
                />

                {isCalenderShow && (
                  <Tab
                    icon={calendarIcon}
                    iconPosition="start"
                    label={<span className={`tabs_text`}>Calendar</span>}
                  />
                )}

                {isCalenderShow && (
                  <Tab
                    icon={analyticsIcon}
                    iconPosition="start"
                    label={<span className={`tabs_text`}>Analytics</span>}
                  />
                )}

                {isCalenderShow && (
                  <Tab
                    icon={documentIcon}
                    iconPosition="start"
                    label={<span className={`tabs_text`}>Documents</span>}
                  />
                )}
              </Tabs>
            </div>
          </div>

          <div className="services_container-content-area">
            <RenderSubTabs />
          </div>
        </>
      )}
    </div>
  );
}
export default React.memo(ServicesContainer);
