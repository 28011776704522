import React, { useEffect, useRef, useState } from "react";
import "./../../../assets/css/Dashboard/OverviewTab.scss";
import videoFile from "./../../../assets/videos/pharmacy-video.mp4";
import PharmacyNotes from "./components/PharmacyNotes";
import PrescriptionsAndServicesToday from "./components/PrescriptionsAndServicesToday";
import ServicesStats from "./components/ServicesStats";
import img1 from "./../../../assets/images/team1.jpg";
import img2 from "./../../../assets/images/team2.jpg";
import img3 from "./../../../assets/images/team3.jpg";
import img4 from "./../../../assets/images/team4.jpg";
import ServicesOffered from "./components/ServicesOffered";
import LiveFeed from "./components/LiveFeed";
import ResponsiblePharmacist from "./components/ResponsiblePharmacist";
import NextupAppointment from "./components/NextupAppointment";
import OverviewFooter from "./components/OverviewFooter";
import ServicesAndTasks from "./components/ServicesAndTasks";
import { Map, Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import useTitanApp from "../../../hooks/useTitanApp";

const OverviewTab = () => {
  const [selectedButton, setSelectedButton] = useState("");
  const { appState } = useTitanApp();
  const { currentPharmacy: pharmacy, darkMode: onDarkModeChange } =
    appState || {};

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  function handleChange(event) {
    const value = event?.target?.value;
    const input = event.target;

    const percentage = ((value - input.min) / (input.max - input.min)) * 100;

    input.style.setProperty(
      "--range-color",
      `linear-gradient(to right, black ${percentage}%, white ${percentage}%`
    );
  }

  const seenMembers = [
    {
      id: "1000",
      name: "Scott Pearce",
      image: img1,
    },
    {
      id: "1001",
      name: "Eva Bates",
      image: img3,
    },
    {
      id: "1002",
      name: "Shaun Rowe",
      image: img2,
    },
    {
      id: "1003",
      name: "Maya Cobb",
      image: img4,
    },
  ];

  const prescriptionsToday = [
    {
      count: "21",
      prescriptions: "To be clinically checked",
    },
    {
      count: "29",
      prescriptions: "To be labelled",
    },
    {
      count: "08",
      prescriptions: "To be packaged",
    },
  ];

  const servicesToday = [
    {
      count: "21",
      services: "💬 (CPCS) Community pharmacist consultation service",
    },
    {
      count: "29",
      services: "💉 Flu vaccination service",
    },
    {
      count: "11",
      services: "Ear Wax Removal",
    },
    {
      count: "19",
      services: "Vitamins",
    },
    {
      count: "32",
      services: "Aspirin",
    },
    {
      count: "04",
      services: "Weight Management",
    },
  ];

  const dataSetOne = [
    {
      name: "MON",
      uv: 590,
      pv: 800,
      amt: 1400,
      cnt: 490,
    },
    {
      name: "TUE",
      uv: 868,
      pv: 967,
      amt: 1506,
      cnt: 590,
    },
    {
      name: "WED",
      uv: 1397,
      pv: 1098,
      amt: 989,
      cnt: 350,
    },
    {
      name: "THU",
      uv: 868,
      pv: 967,
      amt: 1506,
      cnt: 590,
    },
    {
      name: "FRI",
      uv: 590,
      pv: 800,
      amt: 1400,
      cnt: 490,
    },
  ];

  const dataSetTwo = [
    {
      name: "Monday",
      uv: 190,
      pv: 800,
      amt: 1400,
      cnt: 490,
    },
    {
      name: "Tuesday",
      uv: 968,
      pv: 967,
      amt: 1506,
      cnt: 590,
    },
    {
      name: "Wednesday",
      uv: 300,
      pv: 1098,
      amt: 989,
      cnt: 350,
    },
    {
      name: "Thursday",
      uv: 700,
      pv: 1200,
      amt: 1228,
      cnt: 480,
    },
    {
      name: "Friday",
      uv: 200,
      pv: 1108,
      amt: 1100,
      cnt: 460,
    },
  ];

  const [activeTab, setActiveTab] = useState("M");

  const handleTabClick = (day) => {
    setActiveTab(day);
  };

  const tabsServices = [
    {
      day: "M",
      content: [
        {
          name: "Mary Johnson",
          time: "12:40pm",
          service: "💬 (CPCS) Community pharmacist consultation service",
        },
      ],
      task: [
        {
          title: "Inventory Management",
          assignedToImg: "",
          priority: "High Priority",
          description:
            "Check the stock levels of commonly prescribed medicines, and make a list of those that need to be reordered",
        },
      ],
    },
    {
      day: "M",
      content: [
        {
          name: "Mark Wells",
          time: "13:10pm",
          service: "Ear Wax Removal",
        },
      ],
      task: [
        {
          title: "Stock Management",
          assignedToImg: "",
          priority: "",
          description:
            "Check the stock levels of commonly prescribed medicines, and make a list of those that need to be reordered",
        },
      ],
    },
    {
      day: "T",
      content: [
        {
          name: "Jennifer Mark",
          time: "14:40pm",
          service: "💬 (CPCS) Community pharmacist consultation service",
        },
      ],
    },
    {
      day: "W",
      content: [
        {
          name: "Sarah Anderson",
          time: "12:40pm",
          service: "Mens Hair Loss",
        },
      ],
    },
    {
      day: "F",
      content: [
        {
          name: "William Jackson",
          time: "12:40pm",
          service: "💬 (CPCS) Community pharmacist consultation service",
        },
      ],
    },
    {
      day: "M",
      content: [
        {
          name: "Nancy Der",
          time: "12:40pm",
          service: "💉 Flu vaccination service",
        },
      ],
      task: [
        {
          title: "Delivery at 13:00",
          assignedToImg: "",
          priority: "",
          description: "Receive stock at 13:00",
        },
      ],
    },
  ];

  const nextupAppointments = [
    {
      name: "James Smith",
      service: "💬 (CPCS) Community pharmacist consultation service",
      parentService: "NHS Service",
    },
    {
      name: "Lily Dableu",
      service: "Ear Wax Removal",
      parentService: "Private Service",
    },
  ];

  const [viewport, setViewport] = useState({
    latitude: pharmacy?.teamPharmacyLatitude || 0,
    longitude: pharmacy?.teamPharmacyLongitude || 0,
    zoom: 16,
  });

  return (
    <div className="overview_tab">
      <div className="overview_col-1">
        <PharmacyNotes
          seenMembers={seenMembers}
          onDarkModeChange={onDarkModeChange}
        />
      </div>
      <div className="overview_col-2">
        <PrescriptionsAndServicesToday
          prescriptionsToday={prescriptionsToday}
          servicesToday={servicesToday}
          onDarkModeChange={onDarkModeChange}
        />
        <ServicesAndTasks
          tabsServices={tabsServices}
          handleTabClick={handleTabClick}
          activeTab={activeTab}
          onDarkModeChange={onDarkModeChange}
        />
        <ServicesStats
          dataSetOne={dataSetOne}
          dataSetTwo={dataSetTwo}
          onDarkModeChange={onDarkModeChange}
        />

        <div className="services-offered">
          <ServicesOffered onDarkModeChange={onDarkModeChange} />
        </div>
      </div>
      <div className="overview_col-3">
        <LiveFeed videoFile={videoFile} onDarkModeChange={onDarkModeChange} />
        <ResponsiblePharmacist onDarkModeChange={onDarkModeChange} />
        <NextupAppointment
          nextupAppointments={nextupAppointments}
          onDarkModeChange={onDarkModeChange}
        />
      </div>

      <div className="overview_mapbox">
        <Map
          initialViewState={viewport}
          scrollZoom={true}
          mapStyle={
            onDarkModeChange
              ? "mapbox://styles/mapbox/dark-v11"
              : "mapbox://styles/mapbox/light-v11"
          }
          style={{
            width: "100vw",
            height: "40vh",
          }}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          attributionControl={false}
        >
          <Marker
            longitude={pharmacy?.teamPharmacyLongitude || 0}
            latitude={pharmacy?.teamPharmacyLatitude || 0}
          >
            <div className="single-marker">
              <div
                className="percentage-marker"
                style={{
                  background: `radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(${
                    pharmacy?.totalKPIPercentage <= 60
                      ? "#e55542"
                      : pharmacy?.totalKPIPercentage > 60 &&
                        pharmacy?.totalKPIPercentage <= 80
                      ? "#f3e73d"
                      : "#51c198"
                  } ${pharmacy?.totalKPIPercentage || 0}%, #c7c7c7 0)`,
                }}
              >
                <p className="perc-val">{pharmacy?.totalKPIPercentage || 0}%</p>
              </div>
              <p className="pharmacy-name">
                {pharmacy?.teamPharmacyName || ""}
              </p>
            </div>
          </Marker>
        </Map>
      </div>

      <OverviewFooter onDarkModeChange={onDarkModeChange} />
    </div>
  );
};

export default OverviewTab;
