import React, { useEffect, useState } from "react";
import "./../../../../../assets/css/Dashboard/ServicesListView.scss";
import useTitanApp from "../../../../../hooks/useTitanApp";
import AsyncRendererRouter from "../../../../../components/AsyncRendererRouter/AsyncRendererRouter";
import PatientContactModal from "../../../../../components/PatientContactModal/PatientContactModal";
import ServiceListViewAccordian from "../ServicesListView/ServiceListViewAccordian";
import NmsProgressModal from "../../../../../components/NmsProgressModal/NmsProgressModal";
import ServiceListViewGrid from "./ServiceListViewGrid";
import SkeletonTable from "./SkeletonTable";
import ServicesContainerFooter from "./ServicesContainerFooter";
import RescheduleNms from "../RescheduleNms";
import OrbitQueuePopup from "../../../../../components/OrbitQueuePopup/OrbitQueuePopup";
export default function ServicesListView({ handleChange }) {
  const { pharmacyServiceState } = useTitanApp();

  const {
    isPharmacyServiceLoading,
    pharmacyServices,
    isPatientContact,
    isNmsProgress,
    listViewGridScrollPosition,
    transformedPharmacyData,
  } = pharmacyServiceState || {};

  const [smsQueue, setCurrentSmsQueue] = useState([]);
  const [reSmsQueue, setCurrentReSmsQueue] = useState([]);
  const [interventionQueue, setCurrentInterventionQueue] = useState([]);
  const [declineQueue, setCurrentDeclineQueue] = useState([]);
  const [rescheduleQueue, setCurrentRescheduleQueue] = useState([]);
  const [pdsQueue, setCurrentPdsQueue] = useState([]);
  const [orbitQueue, setCurrentOrbitQueue] = useState([]);
  const [checkedAllEligible, setCheckedAllEligible] = useState(false);
  const [checkedAllConsent, setCheckedAllConsent] = useState(false);
  const [checkedAllIntervention, setCheckedAllIntervention] = useState(false);
  const [checkedAllFollowUp, setCheckedAllFollowUp] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [showOrbitQueuePopup, setShowOrbitQueuePopup] = useState(false);
  const [listviewOrbitClinicalServiceId, setListviewOrbitClinicalServiceId] =
    useState("");
  const [checksCount, setChecksCount] = useState(0);
  const [rowData, setRowData] = useState(transformedPharmacyData);
  const [servicesUpdatedData, setServicesUpdatedData] = useState([]);

  function setPdsData(data) {
    setServicesUpdatedData(data);
  }
  return (
    <>
      <div className="pharmacy_header_modal">
        <AsyncRendererRouter resolve={pharmacyServices}>
          <div className="services_content-bg services_listview">
            {pharmacyServices?.length !== 0 ? (
              <>
                <div id="stages-wrapper-id" className="stages-wrapper">
                  {isNmsProgress && <NmsProgressModal />}
                  {showOrbitQueuePopup && (
                    <OrbitQueuePopup
                      view="listview"
                      handleChange={handleChange}
                      listviewOrbitClinicalServiceId={
                        listviewOrbitClinicalServiceId
                      }
                      setShowOrbitQueuePopup={setShowOrbitQueuePopup}
                    />
                  )}
                  {isPatientContact && <PatientContactModal />}
                  {isPharmacyServiceLoading ? (
                    <SkeletonTable />
                  ) : (
                    <ServiceListViewGrid
                      smsQueue={smsQueue}
                      reSmsQueue={reSmsQueue}
                      interventionQueue={interventionQueue}
                      declineQueue={declineQueue}
                      rescheduleQueue={rescheduleQueue}
                      pdsQueue={pdsQueue}
                      orbitQueue={orbitQueue}
                      setCurrentSmsQueue={setCurrentSmsQueue}
                      setCurrentReSmsQueue={setCurrentReSmsQueue}
                      setCurrentInterventionQueue={setCurrentInterventionQueue}
                      setCurrentDeclineQueue={setCurrentDeclineQueue}
                      setCurrentRescheduleQueue={setCurrentRescheduleQueue}
                      setCurrentPdsQueue={setCurrentPdsQueue}
                      setCurrentOrbitQueue={setCurrentOrbitQueue}
                      checkedAllEligible={checkedAllEligible}
                      setCheckedAllEligible={setCheckedAllEligible}
                      checkedAllConsent={checkedAllConsent}
                      setCheckedAllConsent={setCheckedAllConsent}
                      checkedAllIntervention={checkedAllIntervention}
                      setCheckedAllIntervention={setCheckedAllIntervention}
                      checkedAllFollowUp={checkedAllFollowUp}
                      setCheckedAllFollowUp={setCheckedAllFollowUp}
                      setListviewOrbitClinicalServiceId={
                        setListviewOrbitClinicalServiceId
                      }
                      setShowOrbitQueuePopup={setShowOrbitQueuePopup}
                      checksCount={checksCount}
                      setChecksCount={setChecksCount}
                      rowData={rowData}
                      servicesUpdatedData={servicesUpdatedData}
                    />
                  )}

                  {/* {pharmacyServices?.map((stage, index) => (
              <div className="stage" key={index}>
                <ServiceListViewAccordian key={index} stage={stage} />
              </div>
            ))} */}
                </div>
              </>
            ) : (
              <div className="pharmacy-error-message">
                <p className="para_color">Data not available!</p>
              </div>
            )}
          </div>
        </AsyncRendererRouter>
      </div>
      <ServicesContainerFooter
        smsQueue={smsQueue}
        interventionQueue={interventionQueue}
        declineQueue={declineQueue}
        rescheduleQueue={rescheduleQueue}
        pdsQueue={pdsQueue}
        orbitQueue={orbitQueue}
        setCurrentSmsQueue={setCurrentSmsQueue}
        setCurrentReSmsQueue={setCurrentReSmsQueue}
        setCurrentInterventionQueue={setCurrentInterventionQueue}
        setCurrentDeclineQueue={setCurrentDeclineQueue}
        setCurrentRescheduleQueue={setCurrentRescheduleQueue}
        setCurrentPdsQueue={setCurrentPdsQueue}
        setCurrentOrbitQueue={setCurrentOrbitQueue}
        setCheckedAllEligible={setCheckedAllEligible}
        setCheckedAllConsent={setCheckedAllConsent}
        setCheckedAllIntervention={setCheckedAllIntervention}
        setCheckedAllFollowUp={setCheckedAllFollowUp}
        handleChange={handleChange}
        reSmsQueue={reSmsQueue}
        setShowReschedule={setShowReschedule}
        checksCount={checksCount}
        setChecksCount={setChecksCount}
        rowData={rowData}
        setRowData={setRowData}
        setServicesUpdatedData={setPdsData}
      />
      {showReschedule && (
        <RescheduleNms
          setShowReschedule={setShowReschedule}
          rescheduleQueue={rescheduleQueue}
          handleChange={handleChange}
          setCurrentSmsQueue={setCurrentSmsQueue}
          setCurrentReSmsQueue={setCurrentReSmsQueue}
          setCurrentInterventionQueue={setCurrentInterventionQueue}
          setCurrentDeclineQueue={setCurrentDeclineQueue}
          setCurrentRescheduleQueue={setCurrentRescheduleQueue}
          setCurrentPdsQueue={setCurrentPdsQueue}
          setCurrentOrbitQueue={setCurrentOrbitQueue}
          setChecksCount={setChecksCount}
        />
      )}
    </>
  );
}
