import React from "react";
import "./../../../../assets/css/Dashboard/ServicesKanban.scss";
import { Tag } from "primereact/tag";
import { getClassName } from "../../../../data/getColorType";

export default function PatientTag({ tag, index }) {
  return (
    <Tag
      key={index}
      severity="custom"
      style={{
        background: tag?.tagColour ? tag?.tagColour : "salmon",
      }}
      className="color-tag"
      value={
        tag ? (
          <span className={getClassName(tag?.tagColour)}>{tag?.tagName}</span>
        ) : (
          ""
        )
      }
    />
  );
}
