import { useState } from "react";
import React from "react";
import CustomButton from "../../../../../controls/CustomButton";
import AddQuestion from "./AddQuestion";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import TextField from "@mui/material/TextField";
function CustomTemplateSection({
  template,
  handleUpdateSection,
  handleDeleteSection,
  AddTemplateSection,
}) {
  const [templateObj, setTemplateObj] = useState(template);
  const [isQuestion, setIsQuestion] = useState(false);
  function ChangeSectionTitle(e) {
    const newValue = e?.target?.value;
    const updatedTemplate = {
      ...templateObj,
      sectionTitle: newValue,
    };
    setTemplateObj(updatedTemplate);
    handleUpdateSection(updatedTemplate);
  }
  function openQuestionModal() {
    setIsQuestion(true);
  }
  function handleCloseModal() {
    setIsQuestion(false);
  }
  function handleDeleteControl(objectToDelete) {
    const updatedUserControls = templateObj?.userControls.filter(
      (control) => control !== objectToDelete
    );
    const updatedTemplate = {
      ...templateObj,
      userControls: updatedUserControls,
    };
    setTemplateObj(updatedTemplate);
    handleUpdateSection(updatedTemplate);
  }
  function handleAddQuestion(obj) {
    const updatedTemplate = {
      ...templateObj,
      userControls: [...templateObj.userControls, obj],
    };
    setTemplateObj(updatedTemplate);
    handleUpdateSection(updatedTemplate);
    setIsQuestion(false);
  }
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = [...templateObj?.userControls];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const updatedTemplate = {
      ...templateObj,
      userControls: items,
    };

    setTemplateObj(updatedTemplate);
  };
  return (
    <div className="medicine-card customcard drag_card">
      <AddQuestion
        isOpen={isQuestion}
        handleCloseModal={handleCloseModal}
        handleAddQuestion={handleAddQuestion}
      />
      <div className="temp_content">
        <div className="temp_head">
          <h4>Section</h4>
          <p className="sec_line">
            Use sections to break up the main part of your templates, and add
            questions to each section.
          </p>
        </div>
        <div className="temp_body">
          <div className="temp_body_a">
            <div className="template_control sec_name">
              <TextField
                className="text_input_field"
                required
                id="outlined-required"
                value={templateObj.sectionTitle}
                label="Name"
                onChange={ChangeSectionTitle}
              />
            </div>

            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="customSections" direction="vertical">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {templateObj?.userControls?.map((control, index) => (
                      <Draggable
                        key={control.userControlId}
                        draggableId={control.userControlId}
                        index={index}
                      >
                        {(provided) => (
                          <>
                            <div
                              key={index}
                              className="control_field"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <span className="control_box">
                                {control.label}
                              </span>
                              <DeleteIcon
                                onClick={() => {
                                  handleDeleteControl(control);
                                }}
                              />
                            </div>
                          </>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <div className="sec_footer">
              <CustomButton action={openQuestionModal} label="Add Question" />
            </div>
          </div>
          <div className="temp_body_b">
            <ContentCopyIcon
              onClick={() => {
                AddTemplateSection(true, templateObj);
              }}
            />

            <CloseIcon
              onClick={() => {
                handleDeleteSection(templateObj);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomTemplateSection;
