import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { RxOpenInNewWindow, RxCross2 } from "react-icons/rx";
import DPIcon from "./../../../assets/images/dp-icon.png";
import Dialog from "@mui/material/Dialog";
import { FaArrowRight, FaCircle } from "react-icons/fa";
import useTitanApp from "../../../hooks/useTitanApp";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DraggablePaper(props) {
  return (
    <Draggable bounds="parent" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function InPersonConsultation({
  openInPersonConsultationDialog,
  handleCloseInPersonConsultation,
  stopConsultation,
  stopConsultationMinutes,
  stopConsultationSeconds,
  handleConsultationStopClick,
  handleDoneClick,
  formattedConsultationMinutes,
  formattedConsultationSeconds,
  handleContinueSessionClick,
  handleConsultationCancelClick,
}) {
  const { nmsServiceState, appState } = useTitanApp();
  const { darkMode } = appState || {};
  const { patientName } = nmsServiceState || {};

  const [startDate, setStartDate] = useState(new Date());

  return openInPersonConsultationDialog === true ? (
    <DraggablePaper className="inpersonDialog">
      {stopConsultation ? (
        <div
          className="inperson_dialog_wrapper ended"
          id="inperson-dialog-title"
        >
          <div className="top-bar">
            <div></div>
            <div className="patient-info">
              <div className="d-flex">
                <p>{patientName}</p>
                <FaCircle color="#D0021B" size={6} />
              </div>

              <p className="phone-number">Nms Ended</p>
            </div>
          </div>
          <div className="body">
            <div className="desc">
              <p>
                This consultation lasted {stopConsultationMinutes} minutes and{" "}
                {stopConsultationSeconds} seconds
              </p>
            </div>
            <div className="options-wrapper">
              <div className="option">
                <div className="flex">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="24" height="24" rx="12" fill="black" />
                    <path
                      d="M9.85714 12.7143C10.2516 12.7143 10.5714 12.3945 10.5714 12C10.5714 11.6055 10.2516 11.2857 9.85714 11.2857C9.46265 11.2857 9.14286 11.6055 9.14286 12C9.14286 12.3945 9.46265 12.7143 9.85714 12.7143Z"
                      fill="white"
                    />
                    <path
                      d="M10.5714 14.1429C10.5714 14.5373 10.2516 14.8571 9.85714 14.8571C9.46265 14.8571 9.14286 14.5373 9.14286 14.1429C9.14286 13.7484 9.46265 13.4286 9.85714 13.4286C10.2516 13.4286 10.5714 13.7484 10.5714 14.1429Z"
                      fill="white"
                    />
                    <path
                      d="M12 12.7143C12.3945 12.7143 12.7143 12.3945 12.7143 12C12.7143 11.6055 12.3945 11.2857 12 11.2857C11.6055 11.2857 11.2857 11.6055 11.2857 12C11.2857 12.3945 11.6055 12.7143 12 12.7143Z"
                      fill="white"
                    />
                    <path
                      d="M12.7143 14.1429C12.7143 14.5373 12.3945 14.8571 12 14.8571C11.6055 14.8571 11.2857 14.5373 11.2857 14.1429C11.2857 13.7484 11.6055 13.4286 12 13.4286C12.3945 13.4286 12.7143 13.7484 12.7143 14.1429Z"
                      fill="white"
                    />
                    <path
                      d="M14.1429 12.7143C14.5373 12.7143 14.8571 12.3945 14.8571 12C14.8571 11.6055 14.5373 11.2857 14.1429 11.2857C13.7484 11.2857 13.4286 11.6055 13.4286 12C13.4286 12.3945 13.7484 12.7143 14.1429 12.7143Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17 8.78571C17 7.79949 16.2005 7 15.2143 7H8.78571C7.79949 7 7 7.79949 7 8.78571V15.2143C7 16.2005 7.79949 17 8.78571 17H15.2143C16.2005 17 17 16.2005 17 15.2143V8.78571ZM7.71429 9.85714H16.2857V15.2143C16.2857 15.806 15.806 16.2857 15.2143 16.2857H8.78571C8.19398 16.2857 7.71429 15.806 7.71429 15.2143V9.85714ZM8.78571 7.71429H15.2143C15.806 7.71429 16.2857 8.19398 16.2857 8.78571V9.14286H7.71429V8.78571C7.71429 8.19398 8.19398 7.71429 8.78571 7.71429Z"
                      fill="white"
                    />
                  </svg>

                  <p>Re-schedule</p>
                </div>

                <DatePicker
                  className="widgetDatePicker"
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  showTimeSelect
                  selected={startDate}
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="dd/MM/yyyy h:mm aa"
                />
              </div>
              <div className="date-picker"></div>

              <div className="option">
                <div className="flex">
                  <svg
                    width="24"
                    height="22"
                    viewBox="0 0 24 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="24" height="22" rx="11" fill="black" />
                    <path
                      d="M8.625 7H15.375C16.2415 7 16.9496 7.67823 16.9974 8.53279L17 8.625V13.375C17 14.2415 16.3218 14.9496 15.4672 14.9974L15.375 15H8.625C7.75848 15 7.05038 14.3218 7.00257 13.4672L7 13.375V8.625C7 7.75848 7.67823 7.05038 8.53279 7.00257L8.625 7H15.375H8.625ZM16.25 9.6865L12.1747 11.8318C12.0809 11.8812 11.9716 11.8882 11.8734 11.853L11.8253 11.8318L7.75 9.687V13.375C7.75 13.8341 8.10356 14.2106 8.55324 14.2471L8.625 14.25H15.375C15.8341 14.25 16.2106 13.8964 16.2471 13.4468L16.25 13.375V9.6865ZM15.375 7.75H8.625C8.16591 7.75 7.7894 8.10356 7.7529 8.55324L7.75 8.625V8.8395L12 11.0762L16.25 8.839V8.625C16.25 8.16591 15.8964 7.7894 15.4468 7.7529L15.375 7.75Z"
                      fill="white"
                    />
                  </svg>

                  <p>Send follow up email</p>
                </div>

                <div className="action flex">
                  <FaArrowRight
                    color={darkMode ? "#fff" : "#0a0a0a"}
                    size={12}
                  />
                </div>
              </div>
            </div>

            <div className="additional-comments">
              <p>Additional Comments</p>
              <input
                type="text"
                placeholder="Note any patient feedback or concerns"
              />
            </div>

            <div className="calling-footer">
              <button
                className="continue-session"
                onClick={handleContinueSessionClick}
              >
                <p>Continue session</p>
              </button>
              <button className="done" onClick={handleDoneClick}>
                <p>Done</p>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div id="inperson-dialog-title">
          <div className="top-bar">
            <div className="patient-img">
              <img src={DPIcon} alt="Patient Icon" />
            </div>
            <div className="patient-info">
              <div className="d-flex">
                <p>{patientName}</p>
                <FaCircle color="#00cc6a" size={6} />
              </div>
            </div>

            <RxOpenInNewWindow
              color={darkMode ? "#fff" : "#0a0a0a"}
              size={20}
              className="collapse-icon"
              onClick={handleCloseInPersonConsultation}
            />
          </div>
          <div className="body">
            <h4>
              {formattedConsultationMinutes +
                ":" +
                formattedConsultationSeconds}
            </h4>
            <div className="calling-footer">
              <button
                className="cancel"
                onClick={handleConsultationCancelClick}
              >
                <p>Cancel</p>
              </button>
              <button className="stop" onClick={handleConsultationStopClick}>
                <p>Stop</p>
              </button>
            </div>
          </div>
        </div>
      )}
    </DraggablePaper>
  ) : null;
}
