import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpGet, httpPost } from "../../services/httpService";
import { getServicesByPharmacyIdUrl, addRooms } from "../../apiUrls/apiUrls";
import { logError } from "../../services/logService";

const initialState = {
  isLocationLoading: false,
  locationPharmacyData: [],
  lastLocationAddedUpdated: null,
  generalById: [],
};

export const getServicesByPharmacyIdData = createAsyncThunk(
  "setting/getServicesByPharmacyIdData",
  async (pharmacyId, thunkAPI) => {
    const urlToGet = getServicesByPharmacyIdUrl(pharmacyId);
    const respData = await httpGet(urlToGet);
    return respData?.services;
  }
);

export const addRoomtData = createAsyncThunk(
  "setting/addRoomtData",
  async (data, thunkAPI) => {
    const respData = await httpPost(addRooms(), data);
    return respData;
  }
);

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setIsLocationLoading: (state, action) => {
      state.isLocationLoading = action?.payload || false;
    },
    setCompanyPharmaciesData: (state, action) => {
      state.companyPharmaciesData = action?.payload || [];
    },
    resetLocationState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServicesByPharmacyIdData.pending, (state) => {
        state.isLocationLoading = true;
        state.locationPharmacyData = null;
      })
      .addCase(getServicesByPharmacyIdData.fulfilled, (state, action) => {
        state.isLocationLoading = false;
        state.locationPharmacyData = action?.payload;
      })
      .addCase(getServicesByPharmacyIdData.rejected, (state, action) => {
        logError(action?.error);
        state.isLocationLoading = false;
        state.locationPharmacyData = null;
      })
      .addCase(addRoomtData.pending, (state) => {
        state.isLocationLoading = true;
        state.lastLocationAddedUpdated = null;
      })
      .addCase(addRoomtData.fulfilled, (state, action) => {
        state.isLocationLoading = false;
        state.lastLocationAddedUpdated = action?.payload;
      })
      .addCase(addRoomtData.rejected, (state, action) => {
        logError(action?.error);
        state.isLocationLoading = false;
        state.lastLocationAddedUpdated = null;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsLocationLoading,
  setCompanyPharmaciesData,
  resetLocationState,
} = locationSlice.actions;

export default locationSlice.reducer;
