import { AnalyticsBrowser } from "@segment/analytics-next";
import Intercom from "react-intercom";
import config from "../config";
const { intercomId } = config;

export const ActionTrackForServices = async (
  indexNo,
  isPhoneCall,
  actionDescription,
  currentUser
) => {
  var titleVal =
    isPhoneCall === true
      ? "Phone call made"
      : indexNo === 0
      ? "Nms Engagement completed"
      : indexNo === 1
      ? "Nms Engagement completed"
      : indexNo === 2
      ? "Nms Intervention completed"
      : "Nms Follow Up completed";
  <Intercom
    appID={intercomId}
    email={currentUser?.emailAddress}
    userId={currentUser?.fullName}
    title={titleVal}
  />;
};
