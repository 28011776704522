import { getObject } from "../getObjectValue";
let arr = [];
class prescriptionItemCheckActions {
  constructor(response, valueprescribedId, prescribedId) {
    if (prescribedId === valueprescribedId) {
      arr.push(response);
    } else {
      arr = [];
    }

    this.obj = arr;
  }
}

export default prescriptionItemCheckActions;
