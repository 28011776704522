import Lottie from "lottie-react";
import React from "react";
import OnboardingConfetti from "./../../assets/OnboardingConfetti.json";
import { useNavigate } from "react-router";
import useTitanApp from "../../hooks/useTitanApp";
import { updateIsVerifyByUserEmail } from "../../dispatchers/onboardingVerificationDsipatchers";
import { initApp } from "../../index";
import { showError } from "../ToastedMessage/ToastedMessage";

function OnboardingSuccess({ root }) {
  let navigate = useNavigate();
  const { onboardingVerificationState, auth } = useTitanApp();
  const { userData } = onboardingVerificationState || {};

  const { user } = auth || {};
  const userEmail = user?.email;

  const handleFinishSteps = async () => {
    try {
      var data = await updateIsVerifyByUserEmail(userEmail, userData);
      if (data != null) {
        if (data?.isVerified === true) {
          setTimeout(
            (initApp) => {
              initApp();
            },
            100,
            initApp
          );
          root.unmount();
        }
        navigate("/");
      } else {
        showError("User Details could not be updated.", true, false);
      }
    } catch (errors) {
      showError("User Details could not be updated.", true, false);
    }
  };
  return (
    <div className="success_container">
      <div className="inner">
        <div className="confetti">
          <Lottie animationData={OnboardingConfetti} />
        </div>

        <h4>You're all set</h4>
        <p>
          Welcome to the TITAN VERSE! You've successfully embarked on a journey
          where convenience meets innovation in pharmacy services.
        </p>
        <button className="btn_success" onClick={handleFinishSteps}>
          <p>Go to Dashboard</p>
        </button>
      </div>
    </div>
  );
}

export default OnboardingSuccess;
