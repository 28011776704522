import React from "react";
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import useTitanType from "../../../../../../../../hooks/useTitanType";
import { setCurrentPharmacyIds } from "../../../../../../../../dispatchers/typeDispatchers";
import useTitanApp from "../../../../../../../../hooks/useTitanApp";

export default function TypesLocations() {
  const { pharmacyServiceState } = useTitanApp();
  const { typeState } = useTitanType();
  const { currentPharmacyIds } = typeState || {};

  const handleToggle = (item) => () => {
    const currentIndex = currentPharmacyIds?.indexOf(item);
    const newChecked = [...currentPharmacyIds];

    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCurrentPharmacyIds(newChecked);
  };

  return (
    <div className="settings_box">
      <List className="services_list">
        {pharmacyServiceState?.allPharmacies?.map((item, index) => (
          <ListItem key={index} button onClick={handleToggle(item?.pharmacyID)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={currentPharmacyIds.indexOf(item?.pharmacyID) !== -1}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={item.pharmacyName} />
          </ListItem>
        ))}
      </List>
    </div>
  );
}
