import React, { useState } from "react";
import OrbitSettingsSidebar from "../../OrbitSettingsSidebar/OrbitSettingsSidebar";
import Schedule from "./components/Schedule";
import AsyncRendererRouter from "../../AsyncRendererRouter/AsyncRendererRouter";
import { useLoaderData } from "react-router-dom";
import useTitanApp from "../../../hooks/useTitanApp";
import CustomLoader from "../../controls/CustomLoader";
import {
  saveOrbitProfile,
  setCurrentAvbData,
  setCurrentOrbitProfile,
} from "../../../dispatchers/orbitDispatchers";
import { showError } from "../../ToastedMessage/ToastedMessage";
import CustomButton from "../../controls/CustomButton";
import useTimer from "../../../hooks/useTimer";
export default function Availability() {
  const { timer, isActive, handleStart, handleReset } = useTimer();
  const { orbitState } = useTitanApp();
  const { isOrbitLoading, orbitProfile, avbDetails } = orbitState || {};
  const { appData } = useLoaderData();
  const [addedSlots, setAddedSlotes] = useState(avbDetails ? avbDetails : []);
  function handleSave() {
    const obj = {
      ...orbitProfile,
      availability: addedSlots,
    };
    saveOrbitProfile(obj)
      .then((result) => {
        setCurrentOrbitProfile(result);
      })
      .catch(() =>
        showError("Availability Details could not be added.", true, false)
      );
  }
  function handleAddedSlots(data) {
    setAddedSlotes(data);
  }
  return (
    <AsyncRendererRouter
      isLoading={isOrbitLoading}
      resolve={appData}
      loadingElement={
        <div className="settings_page">
          <div className="settings_wrapper">
            <OrbitSettingsSidebar />
            <div className="settings_content_wrapper orbit_settings_content_wrapper">
              <CustomLoader />
            </div>
          </div>
        </div>
      }
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <OrbitSettingsSidebar />

          <div className="settings_content_wrapper">
            <div className="settings_heading">
              <h5>Availability</h5>
              <p>
                You will only recieve notifications for shifts that fall within
                the days and times you have selected below.
              </p>
            </div>
            <div className="AddNew_sectionbox">
              <Schedule
                addedSlots={addedSlots}
                handleAddedSlots={handleAddedSlots}
              />
            </div>
            <div className="footer_btn">
              <CustomButton label="Save" action={handleSave} />
            </div>
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
