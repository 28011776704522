import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpGet, httpPost, httpPut } from "../../services/httpService";
import { logError } from "../../services/logService";
import { getConsentDataUrl, saveConsentFormUrl } from "../../apiUrls/apiUrls";

const initialState = {
  isConsentLoading: false,
  consentData: [],
  healthyAdvice: [],
  nhsFormData: {
    isPatientWantsHelp: "",
    patientWeight: "",
    patientHeight: "",
    doPatientSmoke: "",
    doPatientDrink: "",
    doPatientDoExercise: "",
    bookingDate: "",
    bookingTimeSlot: "",
  },
  nhsConsentData: {
    patientId: "00000000-0000-0000-0000-000000000000",
    consent: false,
    consentSignature: "base64",
    patientName: "Dori's",
    prescriptionItemId: "00000000-0000-0000-0000-000000000000",
    PrescriptionId: "00000000-0000-0000-0000-000000000000",
    ethnicity: 1,
    contactType: 1,
    contactNumber: "03025992195",
    dateOfIntervention: "2023-11-16T12:00:00Z",
    sendSms: false,
    healthyLivingAdvice: [],
    medications: [
      {
        drugName: "name",
        dosage: "name",
        prescriptionItemId: "00000000-0000-0000-0000-000000000000",
        createdOn: "2023-11-16T12:00:00Z",
        relatedConditions: [],
      },
    ],
    isProcessedNMS: false,
    patientMedicationId: [],
  },
  smsQueue: [],
};

export const getConsentData = createAsyncThunk(
  "SMS/getConsentData",
  async (param) => {
    const searchType = 3;
    const respData = await httpGet(getConsentDataUrl(searchType, param));
    return respData;
  }
);

export const saveConsentFormData = createAsyncThunk(
  "SMS/saveConsentFormData",
  async (params, thunkAPI) => {
    const { data, serviceId } = params || {};
    const respData = await httpPost(saveConsentFormUrl(serviceId), data);
    return respData;
  }
);

export const consentSlice = createSlice({
  name: "consent",
  initialState,
  reducers: {
    setIsConsentLoading: (state, action) => {
      state.isConsentLoading = action?.payload || false;
    },
    setNhsFormData: (state, action) => {
      state.nhsFormData = action?.payload || null;
    },
    setHealthyAdviceData: (state, action) => {
      state.healthyAdvice = action?.payload || null;
    },
    setSmsQueue: (state, action) => {
      state.smsQueue = action?.payload;
    },
    resetOrbitState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConsentData.pending, (state) => {
        state.isConsentLoading = true;
        state.consentData = [];
      })
      .addCase(getConsentData.fulfilled, (state, action) => {
        state.isConsentLoading = false;
        state.consentData = action?.payload;
      })
      .addCase(getConsentData.rejected, (state, action) => {
        state.isConsentLoading = false;
        state.consentData = [];
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setNhsFormData,
  setHealthyAdviceData,
  resetOrbitState,
  setIsConsentLoading,
  setSmsQueue,
} = consentSlice.actions;

export default consentSlice.reducer;
