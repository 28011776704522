import React from "react";

export default function Heart() {
  return (
    <div className="card-shaped-box">
      <div className="tasks_header">
        <h3 className="pharmacy_header_name">Heart</h3>
      </div>
      <div className="card-shaped-content">
        <p className="description">
          There have been 2 self-reported problems reported to a pharmacist
          since 2021
        </p>

        <div className="heart-boxes">
          <div className="heart_box">
            <div className="heart_count">
              <p>69</p>
              <p>BPM</p>
            </div>
            <div className="heart_desc">
              <p>Resting Heart Rate</p>
              <p>69 beats per minute</p>
            </div>
          </div>
          <div className="heart_box">
            <div className="heart_count">
              <p>64</p>
              <p>MS</p>
            </div>
            <div className="heart_desc">
              <p>Heart Rate Variability</p>
              <p>64 milliseconds</p>
            </div>
          </div>
          <div className="heart_box">
            <div className="heart_count">
              <p>71</p>
              <p>BPM</p>
            </div>
            <div className="heart_desc">
              <p>Heart Rate</p>
              <p>71 beats per minute</p>
            </div>
          </div>
          <div className="heart_box">
            <div className="heart_count">
              <p>34.7</p>
              <p>VO2 max</p>
            </div>
            <div className="heart_desc">
              <p>Cardio fitness</p>
              <p>Low</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
