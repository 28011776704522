import React, { useEffect, useState } from "react";
import useTitanApp from "../../../hooks/useTitanApp";
import { useNavigate } from "react-router";
import config from "./../../../config";
import { getIdentityVerificationDataBySessionId } from "../../../dispatchers/pharmacyServiceDispatchers";
import {
  setProcessingApp,
  setYotiSteps,
  setLinkLoading,
  setSignupForm,
} from "../../../dispatchers/onboardingVerificationDsipatchers";
import { showError } from "../../ToastedMessage/ToastedMessage";
import { initApp } from "../../../index";
import { RxCaretRight } from "react-icons/rx";
import CustomButton from "../../controls/CustomButton";

export default function YotiSteps({ root }) {
  let navigate = useNavigate();
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  const handleVerificationSectionNext = () => {
    setSignupForm(false);
    setYotiSteps(false);
    setProcessingApp(true);
  };
  const { pharmacyServiceState, onboardingVerificationState } = useTitanApp();
  const { userData } = onboardingVerificationState || {};
  const { yotiSessionDetail, isYotiSuccess } = pharmacyServiceState || {};
  const { clientSessionToken, sessionId } = yotiSessionDetail || {};
  const { yotiUrl } = config;
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const iframeUrl =
    sessionId !== ""
      ? `${yotiUrl}sessionID=${sessionId}&sessionToken=${clientSessionToken}`
      : "";
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.eventType === "SUCCESS") {
        setLinkLoading(true);
        setYotiSteps(false);
        setProcessingApp(true);
        getIdentityVerificationDataBySessionId(
          sessionId,
          userData?.gphcNumber
        ).then((data) => {
          setLinkLoading(false);
          if (data?.length === 0) {
            setIsSuccess(true);
          } else {
            setIsError(true);
            showError(`"${data[0]?.key}"`, true, false);
          }
          return data;
        });
      } else if (event.data.eventType === "ERROR") {
        const errorCode = event?.data?.eventCode;
        showError(
          `Something went wrong in "${errorCode}" Please try again`,
          true,
          false
        );
      }
    };
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleTryAgain = async () => {
    try {
      // setProcessingApp(false);
      // setYotiSteps(false);
      // setOrbitGPHC(true);
      navigate("/");
    } catch (errors) {
      showError("User Details could not be updated.", true, false);
    }
  };
  return (
    <div>
      <iframe
        title="Yoti Identity Verification"
        src={iframeUrl}
        width="100%"
        height="600px"
        allow="camera"
      />
      <div className="signup-footer">
        {isSuccess && (
          <button
            className="btn_success"
            onClick={handleVerificationSectionNext}
          >
            <div></div>
            <p>Next</p>
            <RxCaretRight color="#fff" size={25} />
          </button>
        )}
        {isError && <CustomButton label="Try Again" action={handleTryAgain} />}
      </div>
    </div>
  );
}
