import React, { useState } from "react";
import "./../../../../../../assets/css/Dashboard/AddEventPopup.scss";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import {
  getEvents,
  updateEvent,
} from "../../../../../../dispatchers/pharmacyServiceDispatchers";
import useTitanApp from "../../../../../../hooks/useTitanApp";
const AddEventPopup = ({ selectInfo, onCancel, onAdd, selectedEvent }) => {
  const { start } = selectedEvent || {};
  const [appDate, setAppDate] = useState(new Date(start));
  const navigate = useNavigate();
  const { appState, pharmacyServiceState } = useTitanApp();
  const { currentPharmacyId } = appState || {};
  const { currentServiceId } = pharmacyServiceState || {};
  const handleClick = async () => {
    const { clinicalServiceID } = selectedEvent || {};
    navigate(`/nmsintervention/${clinicalServiceID}`, {
      state: {
        serviceType: "Calendar",
      },
    });
  };

  const handleAddEvent = () => {};

  const formatDate = (dateTimeString) => {
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return new Date(dateTimeString).toLocaleString(undefined, options);
  };

  const formatTime = (dateTimeString) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
    };
    return new Date(dateTimeString).toLocaleTimeString(undefined, options);
  };
  function handleChangeDate(e) {
    setAppDate(new Date(e?.target?.value));
  }
  function handleUpdate() {
    const updatedDate = new Date(appDate);
    updatedDate.setMinutes(updatedDate.getMinutes() + 30);
    const obj = {
      ...selectedEvent,
      start: appDate,
      end: updatedDate,
    };

    if (obj["stageType"] === null || obj["stageType"] === undefined) {
      delete obj["stageType"];
    }

    updateEvent(obj).then(() => {
      getEvents(currentPharmacyId, currentServiceId).then((data) => {
        return data;
      });
    });
  }
  return (
    <div className="event_modal_container">
      <h4 className="title">Event Details</h4>
      <div className="add-event-popup">
        <label className="custom_labels">Title:</label>
        <div className="input_field">
          <span>
            <p className="card-inner-value">{selectedEvent?.title}</p>
          </span>
        </div>
        <label className="custom_labels">Description:</label>
        <div className="input_field">
          <span>
            <p className="card-inner-value">
              {selectedEvent?.description
                ? selectedEvent?.description
                : "No description for this event"}
            </p>
          </span>
        </div>
        <label className="custom_labels">Appointment date:</label>
        <div className="input_field">
          <input
            className="input-text"
            type="datetime-local"
            name="bookingDate"
            value={appDate ? appDate.toISOString().slice(0, 16) : ""}
            onChange={handleChangeDate}
            // min={new Date().toISOString().slice(0, 16)}
          />
        </div>
        <div className="add-event-popup-buttons">
          <button onClick={handleClick}>View</button>
          <button onClick={onCancel}>Cancel</button>
          <button onClick={handleUpdate}>Update</button>
        </div>
      </div>
    </div>
  );
};

export default AddEventPopup;
