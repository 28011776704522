import { getObject } from "../getObjectValue";
class spineApiRequest {
  constructor(response) {
    this.obj = {
      iaToken: response.iaToken ? getObject(response, "iaToken") : "",
      testMode: response.testMode ? getObject(response, "testMode") : false,
    };
  }
}

export default spineApiRequest;
