import React, { useState } from "react";
import { useNavigate, useLoaderData } from "react-router-dom";
import SettingsSidebar from "../../SettingsSidebar/SettingsSidebar";
import { GoArrowLeft } from "react-icons/go";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MdAdd } from "react-icons/md";
import { BsDash } from "react-icons/bs";
import CustomLoader from "../../controls/CustomLoader";
import useTitanApp from "../../../hooks/useTitanApp";
import AsyncRendererRouter from "../../AsyncRendererRouter/AsyncRendererRouter";
import LocationsLoadingComponent from "../../SettingsLoadingComponents/LocationsLoadingComponent";

export default function Locations() {
  const { appState, locationState, pharmacyServiceState } = useTitanApp();
  const { isLocationLoading } = locationState || {};
  const { allPharmacies } = pharmacyServiceState || {};
  const { features } = appState || {};
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState("Locations");
  const [addNewSection, setAddNewSection] = useState(false);

  const handleGoBack = () => {
    navigate("/");
  };

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };

  const allServices = [];

  // Iterate through the pharmacies and their rooms to extract services
  allPharmacies?.forEach((pharmacy) => {
    if (pharmacy?.pharmacyRooms !== null) {
      pharmacy?.pharmacyRooms?.forEach((room) => {
        allServices.push(...room?.services);
      });
    }
  });

  const handleAddNewLocation = (pharmacy) => {
    navigate(`/settings/locations/new/${pharmacy?.pharmacyID}`);
  };

  const { appData } = useLoaderData() || {};

  return (
    <AsyncRendererRouter
      isLoading={isLocationLoading}
      resolve={appData}
      loadingElement={
        <LocationsLoadingComponent
          addNewSection={addNewSection}
          setAddNewSection={setAddNewSection}
          handleGoBack={handleGoBack}
          selectedMenuItem={selectedMenuItem}
          handleMenuItemClick={handleMenuItemClick}
        />
      }
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <SettingsSidebar
            handleGoBack={handleGoBack}
            selectedMenuItem={selectedMenuItem}
            handleMenuItemClick={handleMenuItemClick}
            features={features}
          />

          <div className="settings_content_wrapper">
            <div className="settings_heading">
              <h5>Locations</h5>
            </div>
            {addNewSection ? (
              <div
                className="back_to_SettingsListView"
                onClick={() => setAddNewSection(false)}
              >
                <GoArrowLeft className="icons-color" />
                <p>Back</p>
              </div>
            ) : (
              <></>
            )}

            <div className={addNewSection ? "" : "settings_content_area"}>
              <div>
                <div className="locations_list_area accordion-group">
                  {allPharmacies?.map((pharmacy, index) => (
                    <Accordion key={index}>
                      <AccordionSummary
                        className="services_accordion-header allView_container"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        key={index}
                      >
                        <div className="pharmacy_titleBox">
                          <div>
                            <p className="pharmacy_title">
                              {pharmacy?.pharmacyName}
                            </p>
                            <span className="pharmacy_room_count">
                              {pharmacy?.pharmacyRooms
                                ? pharmacy?.pharmacyRooms?.length
                                : "No"}{" "}
                              rooms in this pharmacy
                            </span>
                          </div>
                          <div>
                            <div
                              className="addNew_cta"
                              onClick={() => handleAddNewLocation(pharmacy)}
                            >
                              <span>Add New Room</span>
                              <MdAdd />
                            </div>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails className="pharmacies-box-container">
                        {pharmacy?.pharmacyRooms?.map((room, index) => (
                          <div className="rooms_listBox" key={index}>
                            <div>
                              <p className="room_name">
                                <BsDash /> {room.roomName}
                              </p>
                              <span className="services_count">
                                {room?.services?.length}{" "}
                                {room?.services?.length === 1
                                  ? "service"
                                  : "services"}{" "}
                                are active in this room
                              </span>
                            </div>
                          </div>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
