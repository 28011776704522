import React from "react";
import { ReactComponent as AddNewSvg } from "./../../../PatientDashboardIcons/addNew.svg";

export default function Medication() {
  return (
    <div className="card-shaped-box">
      <div className="tasks_header summary_header">
        <h3 className="pharmacy_header_name">Medication</h3>
        <AddNewSvg />
      </div>
      <div className="card-shaped-content">
        <p className="description">
          List of both active and inactive medications
        </p>
        <div className="medications-wrapper">
          <div className="first">
            <div className="status">
              <div className="circle green"></div>
              <p>Active</p>
            </div>
            <div className="inner_container">
              <div className="title medication-title">
                <svg
                  width="10"
                  height="15"
                  viewBox="0 0 12 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.2497 15.5237H1.52274C1.03261 15.5237 0.631728 15.1724 0.571922 14.7207H2.52215V6.72198H0.579398C0.621449 6.35468 0.745266 5.99977 0.953184 5.68375L1.65684 4.61192C1.85635 4.30828 1.96147 3.9591 1.96147 3.60109V2.98496H9.81146V3.60109C9.81146 3.9591 9.91612 4.30828 10.1156 4.61192L10.8198 5.68375C11.0272 5.99977 11.1515 6.35468 11.1935 6.72198H6.72772V14.7207H11.2005C11.1407 15.1724 10.7399 15.5237 10.2497 15.5237ZM0.562547 14.1896H1.96471V7.25215H0.562547V14.1896ZM1.6813 2.45424H2.24198V0.58418H1.6813V2.45424ZM2.80317 2.45424H3.36432V0.58418H2.80317V2.45424ZM3.92505 2.45424H4.48572V0.58418H3.92505V2.45424ZM5.0438 2.45424H5.60495V0.58418H5.0438V2.45424ZM6.1688 2.45424H6.72994V0.58418H6.1688V2.45424ZM7.28755 2.45424H7.84823V0.58418H7.28755V2.45424ZM8.40942 2.45424H8.97057V0.58418H8.40942V2.45424ZM9.5313 2.45424H10.092V0.58418H9.5313V2.45424ZM7.28755 14.1896V7.25215H11.2128V8.32176H8.40937V8.85215H11.2128V9.38872H8.40937V9.91911H11.2128V10.4557H8.40937V10.9861H11.2128V11.5222H8.40937V12.0526H11.2128V12.5892H8.40937V13.12H11.2128V14.1896H7.28755ZM11.2963 5.40355V5.4031L10.5922 4.33128C10.4483 4.1125 10.3721 3.85968 10.3721 3.60067V2.98454C10.5268 2.98454 10.652 2.86609 10.652 2.71935V0.318904C10.652 0.172606 10.5268 0.0537109 10.3721 0.0537109H1.4017C1.24658 0.0537109 1.12136 0.172606 1.12136 0.318904V2.71935C1.12136 2.86609 1.24658 2.98454 1.4017 2.98454V3.60067C1.4017 3.85968 1.32507 4.1125 1.18117 4.33128L0.477045 5.40355C0.164466 5.87913 0 6.42675 0 6.98719V7.06454V14.3772V14.6053C0 15.404 0.683095 16.0537 1.52318 16.0537H10.2502C11.0903 16.0537 11.7738 15.404 11.7738 14.6053V14.3772V7.06454V6.98719C11.7738 6.42675 11.6089 5.87913 11.2963 5.40355Z"
                    fill="black"
                  />
                </svg>
                <p>Lisdexamfetamine 30mg tablets</p>
              </div>
              <p className="last-dispensed">Last dispensed 3 days ago</p>
              <div className="input_wrapper">
                <div className="top">
                  <div>
                    <p className="label">Dose</p>
                    <p className="text">ONE to be taken daily</p>
                  </div>
                </div>
                <div className="bottom three-cols">
                  <div>
                    <p className="label">Quantity</p>
                    <p className="text">28 tablets</p>
                  </div>
                  <div>
                    <p className="label">Measure</p>
                    <p className="text">30mg</p>
                  </div>
                  <div>
                    <p className="label">Therapy</p>
                    <p className="text">Chronic</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="second">
            <div className="status">
              <div className="circle red"></div>
              <p>Inactive</p>
            </div>
            <div className="inner_container">
              <div className="title medication-title">
                <svg
                  width="10"
                  height="15"
                  viewBox="0 0 12 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.2497 15.5237H1.52274C1.03261 15.5237 0.631728 15.1724 0.571922 14.7207H2.52215V6.72198H0.579398C0.621449 6.35468 0.745266 5.99977 0.953184 5.68375L1.65684 4.61192C1.85635 4.30828 1.96147 3.9591 1.96147 3.60109V2.98496H9.81146V3.60109C9.81146 3.9591 9.91612 4.30828 10.1156 4.61192L10.8198 5.68375C11.0272 5.99977 11.1515 6.35468 11.1935 6.72198H6.72772V14.7207H11.2005C11.1407 15.1724 10.7399 15.5237 10.2497 15.5237ZM0.562547 14.1896H1.96471V7.25215H0.562547V14.1896ZM1.6813 2.45424H2.24198V0.58418H1.6813V2.45424ZM2.80317 2.45424H3.36432V0.58418H2.80317V2.45424ZM3.92505 2.45424H4.48572V0.58418H3.92505V2.45424ZM5.0438 2.45424H5.60495V0.58418H5.0438V2.45424ZM6.1688 2.45424H6.72994V0.58418H6.1688V2.45424ZM7.28755 2.45424H7.84823V0.58418H7.28755V2.45424ZM8.40942 2.45424H8.97057V0.58418H8.40942V2.45424ZM9.5313 2.45424H10.092V0.58418H9.5313V2.45424ZM7.28755 14.1896V7.25215H11.2128V8.32176H8.40937V8.85215H11.2128V9.38872H8.40937V9.91911H11.2128V10.4557H8.40937V10.9861H11.2128V11.5222H8.40937V12.0526H11.2128V12.5892H8.40937V13.12H11.2128V14.1896H7.28755ZM11.2963 5.40355V5.4031L10.5922 4.33128C10.4483 4.1125 10.3721 3.85968 10.3721 3.60067V2.98454C10.5268 2.98454 10.652 2.86609 10.652 2.71935V0.318904C10.652 0.172606 10.5268 0.0537109 10.3721 0.0537109H1.4017C1.24658 0.0537109 1.12136 0.172606 1.12136 0.318904V2.71935C1.12136 2.86609 1.24658 2.98454 1.4017 2.98454V3.60067C1.4017 3.85968 1.32507 4.1125 1.18117 4.33128L0.477045 5.40355C0.164466 5.87913 0 6.42675 0 6.98719V7.06454V14.3772V14.6053C0 15.404 0.683095 16.0537 1.52318 16.0537H10.2502C11.0903 16.0537 11.7738 15.404 11.7738 14.6053V14.3772V7.06454V6.98719C11.7738 6.42675 11.6089 5.87913 11.2963 5.40355Z"
                    fill="black"
                  />
                </svg>
                <p>Lisdexamfetamine 30mg tablets</p>
              </div>
              <p className="last-dispensed">Last dispensed 3 days ago</p>
              <div className="input_wrapper">
                <div className="top">
                  <div>
                    <p className="label">Dose</p>
                    <p className="text">ONE to be taken daily</p>
                  </div>
                </div>
                <div className="bottom three-cols">
                  <div>
                    <p className="label">Quantity</p>
                    <p className="text">28 tablets</p>
                  </div>
                  <div>
                    <p className="label">Measure</p>
                    <p className="text">30mg</p>
                  </div>
                  <div>
                    <p className="label">Therapy</p>
                    <p className="text">Chronic</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="inner_container">
              <div className="title medication-title">
                <svg
                  width="10"
                  height="15"
                  viewBox="0 0 12 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.2497 15.5237H1.52274C1.03261 15.5237 0.631728 15.1724 0.571922 14.7207H2.52215V6.72198H0.579398C0.621449 6.35468 0.745266 5.99977 0.953184 5.68375L1.65684 4.61192C1.85635 4.30828 1.96147 3.9591 1.96147 3.60109V2.98496H9.81146V3.60109C9.81146 3.9591 9.91612 4.30828 10.1156 4.61192L10.8198 5.68375C11.0272 5.99977 11.1515 6.35468 11.1935 6.72198H6.72772V14.7207H11.2005C11.1407 15.1724 10.7399 15.5237 10.2497 15.5237ZM0.562547 14.1896H1.96471V7.25215H0.562547V14.1896ZM1.6813 2.45424H2.24198V0.58418H1.6813V2.45424ZM2.80317 2.45424H3.36432V0.58418H2.80317V2.45424ZM3.92505 2.45424H4.48572V0.58418H3.92505V2.45424ZM5.0438 2.45424H5.60495V0.58418H5.0438V2.45424ZM6.1688 2.45424H6.72994V0.58418H6.1688V2.45424ZM7.28755 2.45424H7.84823V0.58418H7.28755V2.45424ZM8.40942 2.45424H8.97057V0.58418H8.40942V2.45424ZM9.5313 2.45424H10.092V0.58418H9.5313V2.45424ZM7.28755 14.1896V7.25215H11.2128V8.32176H8.40937V8.85215H11.2128V9.38872H8.40937V9.91911H11.2128V10.4557H8.40937V10.9861H11.2128V11.5222H8.40937V12.0526H11.2128V12.5892H8.40937V13.12H11.2128V14.1896H7.28755ZM11.2963 5.40355V5.4031L10.5922 4.33128C10.4483 4.1125 10.3721 3.85968 10.3721 3.60067V2.98454C10.5268 2.98454 10.652 2.86609 10.652 2.71935V0.318904C10.652 0.172606 10.5268 0.0537109 10.3721 0.0537109H1.4017C1.24658 0.0537109 1.12136 0.172606 1.12136 0.318904V2.71935C1.12136 2.86609 1.24658 2.98454 1.4017 2.98454V3.60067C1.4017 3.85968 1.32507 4.1125 1.18117 4.33128L0.477045 5.40355C0.164466 5.87913 0 6.42675 0 6.98719V7.06454V14.3772V14.6053C0 15.404 0.683095 16.0537 1.52318 16.0537H10.2502C11.0903 16.0537 11.7738 15.404 11.7738 14.6053V14.3772V7.06454V6.98719C11.7738 6.42675 11.6089 5.87913 11.2963 5.40355Z"
                    fill="black"
                  />
                </svg>
                <p>Lisdexamfetamine 30mg tablets</p>
              </div>
              <p className="last-dispensed">Last dispensed 3 days ago</p>
              <div className="input_wrapper">
                <div className="top">
                  <div>
                    <p className="label">Dose</p>
                    <p className="text">ONE to be taken daily</p>
                  </div>
                </div>
                <div className="bottom three-cols">
                  <div>
                    <p className="label">Quantity</p>
                    <p className="text">28 tablets</p>
                  </div>
                  <div>
                    <p className="label">Measure</p>
                    <p className="text">30mg</p>
                  </div>
                  <div>
                    <p className="label">Therapy</p>
                    <p className="text">Chronic</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
