import * as React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import CustomButton from "../controls/CustomButton";
import "./../../assets/css/PatientModal.css";
import { ImWarning } from "react-icons/im";
import { setCurrentNmsProgress } from "../../dispatchers/pharmacyServiceDispatchers";
import useTitanApp from "../../hooks/useTitanApp";
import moment from "moment";
export default function NmsProgressModal() {
  const { pharmacyServiceState } = useTitanApp();
  const { userName, userLog } = pharmacyServiceState || {};
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const navigate = useNavigate();
  function proceedNms() {
    setCurrentNmsProgress(false);
    navigate(`/nmsintervention/${userLog?.clinicalServiceId}`, {
      state: {
        serviceType: "Kanban",
      },
    });
  }
  const formatDate = (dateString) => {
    let momentdate = moment(dateString);
    let date = momentdate?.format("DD/MM/yyyy HH:mma");
    return date;
  };
  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <div className="clinical_modal">
          <Modal.Header>
            <ImWarning size={12} color={"red"} />
            <Modal.Title>Prescription in progress</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="progressmodal_body">
              <p>{`${userName} started looking at this prescription at ${formatDate(
                userLog?.createdOn
              )}. Do you want to continue?`}</p>
            </div>
          </Modal.Body>
          <div className="progressmodal_footer">
            <CustomButton
              label="No"
              action={() => setCurrentNmsProgress(false)}
            />
            <CustomButton label="Yes" action={proceedNms} />
          </div>
        </div>
      </Modal>
    </>
  );
}
