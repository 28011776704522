import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpGet, httpPost } from "../../services/httpService";
import { getAllDocumentsServiceUrl, documentSaveUrl, getDocumentById } from "../../apiUrls/apiUrls";
import { logError } from "../../services/logService";

const initialState = {
  isDocumentLoading: false,
  documentsData: [],
  currentDocumentData: [],
  currentDocumentId: "0",
  currentDocumentName: "",
  lastDocumentAddedUpdated: null,
  documentById: [],
};

export const getAllDocumentsData = createAsyncThunk(
  "document/getAllDocumentsData",
  async (params) => {
    const { pharmacyId, serviceId } = params || { pharmacyId : "00000000-0000-0000-0000-000000000000" };
    const urlToGet = getAllDocumentsServiceUrl(pharmacyId, serviceId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const addDocumentData = createAsyncThunk(
  "document/addDocumentData",
  async (data, thunkAPI) => {
    const respData = await httpPost(documentSaveUrl(), data);
    return respData;
  }
);

export const getDocumenteByIdData = createAsyncThunk(
  "document/getDocumenteByIdData",
  async (documentId, thunkAPI) => {
    const respData = await httpGet(getDocumentById(documentId));
    return respData;
  }
);

export const documentServiceSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    setIsDocumentLoading: (state, action) => {
      state.isDocumentLoading = action?.payload || false;
    },
    setDocumentID: (state, action) => {
      state.currentDocumentId = action?.payload || "0";
    },
    setDocumentName: (state, action) => {
      state.currentDocumentName = action?.payload || "";
    },
    setDocumentData: (state, action) => {
      state.documentsData = action?.payload || [];
    },
    resetDocumentState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDocumentsData.pending, (state) => {
        state.isDocumentLoading = true;
      })
      .addCase(getAllDocumentsData.fulfilled, (state, action) => {
        state.isDocumentLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.documentsData = appDataFromResponse || [];
          } catch (error) { }
        }
      })
      .addCase(getAllDocumentsData.rejected, (state, action) => {
        state.isDocumentLoading = false;
        state.documentsData = [];
      })
      .addCase(addDocumentData.pending, (state) => {
        state.isDocumentLoading = true;
        state.lastDocumentAddedUpdated = null;
      })
      .addCase(addDocumentData.fulfilled, (state, action) => {
        state.isDocumentLoading = false;
        state.lastDocumentAddedUpdated = action?.payload;
      })
      .addCase(addDocumentData.rejected, (state, action) => {
        logError(action?.error);
        state.isDocumentLoading = false;
        state.lastDocumentAddedUpdated = null;
      })
      .addCase(getDocumenteByIdData.pending, (state) => {
        state.isDocumentLoading = true;
        state.documentById = [];
      })
      .addCase(getDocumenteByIdData.fulfilled, (state, action) => {
        state.isDocumentLoading = false;
        state.documentById = action?.payload || [];
      })
      .addCase(getDocumenteByIdData.rejected, (state, action) => {
        logError(action?.error);
        state.isDocumentLoading = false;
        state.documentById = [];
      })
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsDocumentLoading,
  setDocumentID,
  setDocumentName,
  setDocumentData,
  resetDocumentState,
} = documentServiceSlice.actions;

export default documentServiceSlice.reducer;
