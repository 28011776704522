import React, { useEffect, useState } from "react";
import "./../../../../../assets/css/Dashboard/ServicesDocuments.scss";
import CardItem from "./PdfViewer";
import UploadFile from "./UploadFile";
import { responseErrorMessage } from "../../../../../data/constantStrings";
import { showError } from "../../../../../components/ToastedMessage/ToastedMessage";
import useTitanApp, { store } from "../../../../../hooks/useTitanApp";
import { useSelector, useDispatch } from "react-redux";
import CustomLoader from "../../../../../components/controls/CustomLoader";
import { getAllDocuments } from "../../../../../dispatchers/documentDispatchers.js";
import { Skeleton } from "@mui/material";

export default function ServicesDocuments() {
  const { documentState, appState, pharmacyServiceState } = useTitanApp();
  const { isDocumentLoading, documentsData } = documentState || {};
  const { currentPharmacyId, darkMode } = appState || {};
  const { currentServiceId } = pharmacyServiceState || {};
  const refreshDocuments = async () => {
    getAllDocuments(currentPharmacyId, currentServiceId);
  };

  const ResultComponent = () => {
    return (
      <div className="services_content-bg services_analytics">
        <div className="upload_file">
          {isDocumentLoading ? (
            <Skeleton
              sx={{
                bgcolor: darkMode ? "grey.900" : "",
              }}
              height={80}
              width={220}
              animation="wave"
              className="documents-upload-skeleton"
            />
          ) : (
            <UploadFile handleAddDocument={refreshDocuments} />
          )}
        </div>

        <div className="card_row">
          {documentsData?.documents?.map((document, index) => (
            <CardItem
              key={index}
              title={document.documentTitle}
              documentType={document.documentType}
              DocumentId={document._id}
            />
          ))}
        </div>
      </div>
    );
  };

  return <ResultComponent />;
}
