import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpGet, httpPost, httpPut } from "../../services/httpService";
import { logError } from "../../services/logService";
import {
  getUserDataUrl,
  updateUserByUserEmailUrl,
  updateIsActiveByUserEmailUrl,
  updateIsVerifyByUserEmailUrl,
  updateIsActiveByUserEmailUrlCode,
  getGphcNumberUrl,
  getEmailByInvitationCodeUrl,
  getUserForOrbitUrl,
  resendEmailToUserUrl,
  userChangePasswordUrl,
} from "../../apiUrls/apiUrls";

const initialState = {
  isonboardingVerificationLoading: false,
  userData: {
    userId: "00000000-0000-0000-0000-000000000000",
    companyID: "00000000-0000-0000-0000-000000000000",
    emailAddress: "",
    gender: 0,
    isNew: false,
    firstName: "",
    lastName: "",
    userImage: "",
    gphcNumber: "",
    phoneNumber: "",
    accountType: 1,
    isVerified: false,
    isActive: false,
    password: "",
    role: null,
    newCompanyID: null,
  },
  isGphcNumber: false,
  profileImage: null,
  invitationEmailAddress: "",
  isEmailResend: false,
  isChangePassword: false,
  signupForm: false,
  emailVerification: false,
  yotiSteps: false,
  processingApp: false,
  linkLoading: false,
  orbitGPHC: false,
  onboardringSuccess: false,
};

export const getUserData = createAsyncThunk(
  "onboardingVerification/getUserData",
  async (userEmail) => {
    const respData = await httpGet(getUserDataUrl(userEmail));
    return respData;
  }
);

export const getEmailByInvitationCodeData = createAsyncThunk(
  "onboardingVerification/getEmailByInvitationCodeData",
  async (invitationCode) => {
    const respData = await httpGet(getEmailByInvitationCodeUrl(invitationCode));
    return respData;
  }
);
export const getUserForOrbitData = createAsyncThunk(
  "onboardingVerification/getUserForOrbitData",
  async () => {
    const respData = await httpGet(getUserForOrbitUrl());
    return respData;
  }
);
export const getGphcNumberData = createAsyncThunk(
  "onboardingVerification/getGphcNumberData",
  async (gphcNumber) => {
    const respData = await httpGet(getGphcNumberUrl(gphcNumber));
    return respData;
  }
);

export const updateUserByUserEmailData = createAsyncThunk(
  "onboardingVerification/updateUserByUserEmailData",
  async (params) => {
    const { userData, userEmail } = params || {};
    const respData = await httpPost(
      updateUserByUserEmailUrl(userEmail),
      userData
    );
    return respData;
  }
);
export const resendEmailToUserData = createAsyncThunk(
  "onboardingVerification/resendEmailToUserData",
  async (userEmail) => {
    const respData = await httpGet(resendEmailToUserUrl(userEmail));
    return respData;
  }
);
export const userChangePasswordData = createAsyncThunk(
  "onboardingVerification/userChangePasswordData",
  async (userEmail) => {
    const respData = await httpGet(userChangePasswordUrl(userEmail));
    return respData;
  }
);

export const updateIsActiveByUserEmailData = createAsyncThunk(
  "onboardingVerification/updateIsActiveByUserEmailData",
  async (params) => {
    const { userEmail, code } = params || {};
    const respData = await httpPost(
      updateIsActiveByUserEmailUrl(userEmail, code)
    );
    return respData;
  }
);

export const updateIsActiveByUserEmailDataCode = createAsyncThunk(
  "onboardingVerification/updateIsActiveByUserEmailDataCode",
  async (params) => {
    const { code } = params || {};
    const respData = await httpPost(updateIsActiveByUserEmailUrlCode(code));
    return respData;
  }
);

export const updateIsVerifyByUserEmailData = createAsyncThunk(
  "onboardingVerification/updateIsVerifyByUserEmailData",
  async (params) => {
    const { userEmail, userData } = params;
    const respData = await httpPost(
      updateIsVerifyByUserEmailUrl(userEmail),
      userData
    );
    return respData;
  }
);

export const onboardingVerificationSlice = createSlice({
  name: "onboardingVerification",
  initialState,
  reducers: {
    setIsOnboardingVerificationLoading: (state, action) => {
      state.isonboardingVerificationLoading = action?.payload || false;
    },
    setUserData: (state, action) => {
      state.userData = action?.payload || null;
    },
    setImage: (state, action) => {
      state.profileImage = action?.payload || null;
    },
    setSignupFormData: (state, action) => {
      state.signupForm = action?.payload || false;
    },
    setEmailVerificationData: (state, action) => {
      state.emailVerification = action?.payload || false;
    },
    setYotiStepsData: (state, action) => {
      state.yotiSteps = action?.payload || false;
    },
    setProcessingAppData: (state, action) => {
      state.processingApp = action?.payload || false;
    },
    setLinkLoadingData: (state, action) => {
      state.linkLoading = action?.payload || false;
    },
    setOrbitGPHCData: (state, action) => {
      state.orbitGPHC = action?.payload || false;
    },
    setOnboardingSuccessData: (state, action) => {
      state.onboardringSuccess = action?.payload || false;
    },
    resetOnboardingVerificationState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserData.pending, (state) => {
        state.isonboardingVerificationLoading = true;
        state.userData = {
          userId: "00000000-0000-0000-0000-000000000000",
          companyID: "00000000-0000-0000-0000-000000000000",
          emailAddress: "",
          gender: 0,
          isNew: false,
          firstName: "",
          lastName: "",
          userImage: "",
          gphcNumber: "",
          phoneNumber: "",
          accountType: 1,
          isVerified: false,
          isActive: false,
          password: "",
          role: null,
          newCompanyID: null,
        };
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.isonboardingVerificationLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse != null) {
          const {
            firstName,
            lastName,
            userImage,
            gphcNumber,
            phoneNumber,
            accountType,
            isVerified,
            isActive,
            userId,
            companyID,
            emailAddress,
            gender,
            isNew,
            role,
            newCompanyID,
          } = appDataFromResponse || {};

          const user = {
            firstName: firstName,
            lastName: lastName,
            userImage: userImage,
            gphcNumber: gphcNumber,
            phoneNumber: phoneNumber,
            accountType: accountType,
            isVerified: isVerified ? isVerified : false,
            isActive: isActive,
            userId: userId,
            companyID: companyID,
            emailAddress: emailAddress,
            gender: gender,
            isNew: isNew,
            password: "",
            role: role,
            newCompanyID: newCompanyID,
          };
          state.profileImage = userImage;
          state.userData = user;
        } else {
          state.userData = {
            userId: "00000000-0000-0000-0000-000000000000",
            companyID: "00000000-0000-0000-0000-000000000000",
            emailAddress: "",
            gender: 0,
            isNew: false,
            firstName: "",
            lastName: "",
            userImage: "",
            gphcNumber: "",
            phoneNumber: "",
            accountType: 1,
            isVerified: false,
            isActive: false,
            password: "",
            role: null,
            newCompanyID: null,
          };
        }
      })
      .addCase(getUserData.rejected, (state, action) => {
        state.isonboardingVerificationLoading = false;
        state.userData = {
          userId: "00000000-0000-0000-0000-000000000000",
          companyID: "00000000-0000-0000-0000-000000000000",
          emailAddress: "",
          gender: 0,
          isNew: false,
          firstName: "",
          lastName: "",
          userImage: "",
          gphcNumber: "",
          phoneNumber: "",
          accountType: 1,
          isVerified: false,
          isActive: false,
          password: "",
          role: null,
          newCompanyID: null,
        };
      })
      .addCase(getGphcNumberData.pending, (state) => {
        state.isonboardingVerificationLoading = true;
        state.isGphcNumber = false;
      })
      .addCase(getGphcNumberData.fulfilled, (state, action) => {
        state.isonboardingVerificationLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse != null) {
          state.isGphcNumber = true;
        }
      })
      .addCase(getGphcNumberData.rejected, (state, action) => {
        state.isonboardingVerificationLoading = false;
        state.isGphcNumber = false;
      })
      .addCase(resendEmailToUserData.pending, (state) => {
        state.isonboardingVerificationLoading = true;
        state.isEmailResend = false;
      })
      .addCase(resendEmailToUserData.fulfilled, (state, action) => {
        state.isonboardingVerificationLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse != null) {
          state.isEmailResend = true;
        }
      })
      .addCase(resendEmailToUserData.rejected, (state, action) => {
        state.isonboardingVerificationLoading = false;
        state.isEmailResend = false;
      })
      .addCase(userChangePasswordData.pending, (state) => {
        state.isonboardingVerificationLoading = true;
        state.isChangePassword = false;
      })
      .addCase(userChangePasswordData.fulfilled, (state, action) => {
        state.isonboardingVerificationLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse != null) {
          state.isChangePassword = true;
        }
      })
      .addCase(userChangePasswordData.rejected, (state, action) => {
        state.isonboardingVerificationLoading = false;
        state.isChangePassword = false;
      })
      .addCase(getEmailByInvitationCodeData.pending, (state) => {
        state.isonboardingVerificationLoading = true;
        state.userData = {
          userId: "00000000-0000-0000-0000-000000000000",
          companyID: "00000000-0000-0000-0000-000000000000",
          emailAddress: "",
          gender: 0,
          isNew: false,
          firstName: "",
          lastName: "",
          userImage: "",
          gphcNumber: "",
          phoneNumber: "",
          accountType: 1,
          isVerified: false,
          isActive: false,
          password: "",
          role: null,
          newCompanyID: null,
        };
      })
      .addCase(getEmailByInvitationCodeData.fulfilled, (state, action) => {
        state.isonboardingVerificationLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse != null) {
          if (appDataFromResponse != null) {
            const {
              firstName,
              lastName,
              userImage,
              gphcNumber,
              phoneNumber,
              accountType,
              isVerified,
              isActive,
              userId,
              companyID,
              emailAddress,
              gender,
              isNew,
              role,
              newCompanyID,
            } = appDataFromResponse || {};

            const user = {
              firstName: firstName,
              lastName: lastName,
              userImage: userImage,
              gphcNumber: gphcNumber,
              phoneNumber: phoneNumber,
              accountType: accountType,
              isVerified: isVerified ? isVerified : false,
              isActive: isActive,
              userId: userId,
              companyID: companyID,
              emailAddress: emailAddress,
              gender: gender,
              isNew: isNew,
              password: "",
              role: role,
              newCompanyID: newCompanyID,
            };
            state.profileImage = userImage;
            state.userData = user;
          } else {
            state.userData = {
              userId: "00000000-0000-0000-0000-000000000000",
              companyID: "00000000-0000-0000-0000-000000000000",
              emailAddress: "",
              gender: 0,
              isNew: false,
              firstName: "",
              lastName: "",
              userImage: "",
              gphcNumber: "",
              phoneNumber: "",
              accountType: 1,
              isVerified: false,
              isActive: false,
              password: "",
              role: null,
              newCompanyID: null,
            };
          }
        }
      })
      .addCase(getEmailByInvitationCodeData.rejected, (state, action) => {
        state.isonboardingVerificationLoading = false;
        state.userData = {
          userId: "00000000-0000-0000-0000-000000000000",
          companyID: "00000000-0000-0000-0000-000000000000",
          emailAddress: "",
          gender: 0,
          isNew: false,
          firstName: "",
          lastName: "",
          userImage: "",
          gphcNumber: "",
          phoneNumber: "",
          accountType: 1,
          isVerified: false,
          isActive: false,
          password: "",
          role: null,
          newCompanyID: null,
        };
      })
      .addCase(getUserForOrbitData.pending, (state) => {
        state.isonboardingVerificationLoading = true;
        state.userData = {
          userId: "00000000-0000-0000-0000-000000000000",
          companyID: "00000000-0000-0000-0000-000000000000",
          emailAddress: "",
          gender: 0,
          isNew: false,
          firstName: "",
          lastName: "",
          userImage: "",
          gphcNumber: "",
          phoneNumber: "",
          accountType: 1,
          isVerified: false,
          isActive: false,
          password: "",
          role: null,
          newCompanyID: null,
        };
      })
      .addCase(getUserForOrbitData.fulfilled, (state, action) => {
        state.isonboardingVerificationLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse != null) {
          state.userData = {
            userId: "00000000-0000-0000-0000-000000000000",
            companyID: "00000000-0000-0000-0000-000000000000",
            emailAddress: appDataFromResponse.emailAddress,
            gender: 0,
            isNew: false,
            firstName: appDataFromResponse?.firstName ?? "",
            lastName: appDataFromResponse?.lastName ?? "",
            userImage: "",
            gphcNumber: appDataFromResponse?.gphcNumber ?? "",
            phoneNumber: appDataFromResponse?.phoneNumber ?? "",
            accountType: appDataFromResponse.accountType ?? 1,
            isVerified: false,
            isActive: false,
            password: "",
            role: appDataFromResponse?.role ?? null,
            newCompanyID: null,
          };
        }
      })
      .addCase(getUserForOrbitData.rejected, (state, action) => {
        state.isonboardingVerificationLoading = false;
        state.userData = {
          userId: "00000000-0000-0000-0000-000000000000",
          companyID: "00000000-0000-0000-0000-000000000000",
          emailAddress: "",
          gender: 0,
          isNew: false,
          firstName: "",
          lastName: "",
          userImage: "",
          gphcNumber: "",
          phoneNumber: "",
          accountType: 1,
          isVerified: false,
          isActive: false,
          password: "",
          role: null,
          newCompanyID: null,
        };
      })
      .addCase(updateUserByUserEmailData.pending, (state) => {
        state.isonboardingVerificationLoading = true;
        state.userData = {
          userId: "00000000-0000-0000-0000-000000000000",
          companyID: "00000000-0000-0000-0000-000000000000",
          emailAddress: "",
          gender: 0,
          isNew: false,
          firstName: "",
          lastName: "",
          userImage: "",
          gphcNumber: "",
          phoneNumber: "",
          accountType: 1,
          isVerified: false,
          isActive: false,
          password: "",
          role: null,
          newCompanyID: null,
        };
      })
      .addCase(updateUserByUserEmailData.fulfilled, (state, action) => {
        state.isonboardingVerificationLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse != null) {
          const {
            firstName,
            lastName,
            userImage,
            gphcNumber,
            phoneNumber,
            accountType,
            isVerified,
            isActive,
            userId,
            companyID,
            emailAddress,
            gender,
            isNew,
            role,
            newCompanyID,
          } = appDataFromResponse || {};
          const user = {
            firstName: firstName,
            lastName: lastName,
            userImage: userImage,
            gphcNumber: gphcNumber,
            phoneNumber: phoneNumber,
            accountType: accountType,
            isVerified: isVerified ? isVerified : false,
            isActive: isActive,
            userId: userId,
            companyID: companyID,
            emailAddress: emailAddress,
            gender: gender,
            isNew: isNew,
            password: "",
            role: role,
            newCompanyID: newCompanyID,
          };
          state.profileImage = userImage;
          state.userData = user;
        }
      })
      .addCase(updateUserByUserEmailData.rejected, (state, action) => {
        state.isonboardingVerificationLoading = false;
        state.userData = {
          userId: "00000000-0000-0000-0000-000000000000",
          companyID: "00000000-0000-0000-0000-000000000000",
          emailAddress: "",
          gender: 0,
          isNew: false,
          firstName: "",
          lastName: "",
          userImage: "",
          gphcNumber: "",
          phoneNumber: "",
          accountType: 1,
          isVerified: false,
          isActive: false,
          password: "",
          role: null,
          newCompanyID: null,
        };
      })
      .addCase(updateIsActiveByUserEmailData.pending, (state) => {
        state.isonboardingVerificationLoading = true;
        state.userData = {
          userId: "00000000-0000-0000-0000-000000000000",
          companyID: "00000000-0000-0000-0000-000000000000",
          emailAddress: "",
          gender: 0,
          isNew: false,
          firstName: "",
          lastName: "",
          userImage: "",
          gphcNumber: "",
          phoneNumber: "",
          accountType: 1,
          isVerified: false,
          isActive: false,
          password: "",
          role: null,
          newCompanyID: null,
        };
      })
      .addCase(updateIsActiveByUserEmailData.fulfilled, (state, action) => {
        state.isonboardingVerificationLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse != null) {
          const {
            firstName,
            lastName,
            userImage,
            gphcNumber,
            phoneNumber,
            accountType,
            isVerified,
            isActive,
            userId,
            companyID,
            emailAddress,
            gender,
            isNew,
            role,
            newCompanyID,
          } = appDataFromResponse || {};
          const user = {
            firstName: firstName,
            lastName: lastName,
            userImage: userImage,
            gphcNumber: gphcNumber,
            phoneNumber: phoneNumber,
            accountType: accountType,
            isVerified: isVerified ? isVerified : false,
            isActive: isActive,
            userId: userId,
            companyID: companyID,
            emailAddress: emailAddress,
            gender: gender,
            isNew: isNew,
            password: "",
            role: null,
            newCompanyID: newCompanyID,
          };
          state.profileImage = userImage;
          state.userData = user;
        }
      })
      .addCase(updateIsActiveByUserEmailData.rejected, (state, action) => {
        state.isonboardingVerificationLoading = false;
        state.userData = {
          userId: "00000000-0000-0000-0000-000000000000",
          companyID: "00000000-0000-0000-0000-000000000000",
          emailAddress: "",
          gender: 0,
          isNew: false,
          firstName: "",
          lastName: "",
          userImage: "",
          gphcNumber: "",
          phoneNumber: "",
          accountType: 1,
          isVerified: false,
          isActive: false,
          password: "",
          role: null,
          newCompanyID: null,
        };
      })
      .addCase(updateIsVerifyByUserEmailData.pending, (state) => {
        state.isonboardingVerificationLoading = true;
        state.userData = {
          userId: "00000000-0000-0000-0000-000000000000",
          companyID: "00000000-0000-0000-0000-000000000000",
          emailAddress: "",
          gender: 0,
          isNew: false,
          firstName: "",
          lastName: "",
          userImage: "",
          gphcNumber: "",
          phoneNumber: "",
          accountType: 1,
          isVerified: false,
          isActive: false,
          password: "",
          role: null,
          newCompanyID: null,
        };
      })
      .addCase(updateIsVerifyByUserEmailData.fulfilled, (state, action) => {
        state.isonboardingVerificationLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse != null) {
          const {
            firstName,
            lastName,
            userImage,
            gphcNumber,
            phoneNumber,
            accountType,
            isVerified,
            isActive,
            userId,
            companyID,
            emailAddress,
            gender,
            isNew,
            role,
            newCompanyID,
          } = appDataFromResponse || {};
          const user = {
            firstName: firstName,
            lastName: lastName,
            userImage: userImage,
            gphcNumber: gphcNumber,
            phoneNumber: phoneNumber,
            accountType: accountType,
            isVerified: isVerified ? isVerified : false,
            isActive: isActive,
            userId: userId,
            companyID: companyID,
            emailAddress: emailAddress,
            gender: gender,
            isNew: isNew,
            password: "",
            role: role,
            newCompanyID: newCompanyID,
          };
          state.profileImage = userImage;
          state.userData = user;
        }
      })
      .addCase(updateIsVerifyByUserEmailData.rejected, (state, action) => {
        state.isonboardingVerificationLoading = false;
        state.userData = {
          userId: "00000000-0000-0000-0000-000000000000",
          companyID: "00000000-0000-0000-0000-000000000000",
          emailAddress: "",
          gender: 0,
          isNew: false,
          firstName: "",
          lastName: "",
          userImage: "",
          gphcNumber: "",
          phoneNumber: "",
          accountType: 1,
          isVerified: false,
          isActive: false,
          password: "",
          role: null,
          newCompanyID: null,
        };
      });
  },
});

export const {
  setUserData,
  setIsOnboardingVerificationLoading,
  setImage,
  setOrbitGPHCData,
  setLinkLoadingData,
  setProcessingAppData,
  setYotiStepsData,
  setYotiToolsNeededData,
  setEmailVerificationData,
  setSignupFormData,
  setOnboardingSuccessData,
  resetOnboardingVerificationState,
} = onboardingVerificationSlice.actions;

export default onboardingVerificationSlice.reducer;
